<template>
  <v-app-bar color="white" v-if="showAppBar">
    <!-- <v-app-bar-nav-icon v-if="!xs" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
    <v-app-bar-title>
      <!-- <span v-if="mdAndUp" class="mr-4">{{ appBarTitle }} </span> -->
      <DomainSelection></DomainSelection>
    </v-app-bar-title>

    <v-menu location="bottom" v-if="signedIn=='true'">
      <template v-slot:activator="{ props }">
        <v-btn color="grey" variant="text" v-bind="props">
          {{ userName }}
          <v-icon small end class="my-3">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <my-profile />

        <v-list-item link>
          <v-list-item-title @click="signOut()"> Sign out </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
  
<script>
//import { mapState } from "vuex";
import { useDisplay } from 'vuetify'
import DomainSelection from './DomainSelection.vue';
import { getAppBarTitle } from '@/flavors';
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { mapState } from "vuex";
import MyProfile from "../profile/MyProfile.vue";

export default {
  name: "AppBar",
  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp, xs } = useDisplay();

    return { mdAndUp, xs };
  },
  props: {
    show: Boolean,
  },
  created() {},
  data: () => ({}),
  components: {
    DomainSelection,
    MyProfile,
  },
  computed: {
    ...mapState(["appBarVisible", "userState"]),

    userName: {
      get() {
        return this.userState.name
      }
    },
    // drawer: {
    //   get() {
    //     return this.$store.state.navDrawer;
    //   },
    //   set(val) {
    //     console.log("val", val);
    //     this.$store.commit("updateNavDrawer", val);
    //   },
    // },
    profile: {
      get() {
        return this.$store.state.proileDrawer;
      },
      set(val) {
        console.log("val", val);
        this.$store.commit("updateProfileDrawer", val);
      },
    },
    showAppBar: {
      get() {
        return this.appBarVisible & this.show;
      },
    },
    signedIn: {
      get() {
        return this.$store.state.userLoggedIn;
      }
    },
    appBarTitle: {
      get() {
        return getAppBarTitle();
      },
    },
  },
  watch: {},

  methods: {

    signOut() {
      recordAnalyticsEvent(AnalyticsEvents.v2Signout);
      this.$store.dispatch("logout").then(() => {
        this.$router.push('/');
        this.$store.commit("getLoggedInUserDetails")

      });
    },
    showProfile() {
      this.profile = !this.profile;
    },
  },
};
</script>
  
<style  scoped></style>
  