<template>
    <div class="">

        <section>
            <div v-html="
                latex
            " class="message-text"></div>
        </section>
    </div>
</template>
    
<script>

import { mapState } from "vuex";
const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
    name: 'QuestionComponent',
    props: {
        latex: String
    },
    mounted() {

    },
    created() {

    },
    data: () => ({

    }),
    components:
    {
    },
    computed: {
        ...mapState(["loginError", "currentTestJson"]),
    },

    watch: {


    },

    methods: {

    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
    