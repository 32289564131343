<template>
  <v-layout class="mb-0 pb-0" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
      <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">
          <v-row class="mt-0 pt-0">
              <v-col class="d-flex flex-column mt-0 pt-0 justify-center align-center">
                  <v-container fluid="" class="px-2 mt-0 pt-0">
                      <v-row justify="center" class="mb-0" align="center">
                          <v-col cols="12" class="mt-3">
                              <v-row justify="center">
                                  <v-col cols="12" xs="8" sm="9" md="9" lg="7" xl="6">
                                      <v-card elevation=0 class="feedbackCard start-bg pa-4">
                                          <v-card-text>
                                            <v-row class="mb-4" justify="start">
                                              <v-col cols="12" >
                                                <span class="text-white t-h125">Please share your feedback !</span>
                                              </v-col>
                                            </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-textarea
                                                  class="feedback-input rounded-lg"
                                                  v-model="review"
                                                  label="Feedback"
                                                  :counter="1000"
                                                  @input="updateCharacterCount"
                                                  variant="outlined"
                                                  rows="7"
                                                  >
                                                </v-textarea>
                                                </v-col>
                                              </v-row>
                                              <v-row justify="end">
                                                <v-col cols="auto">
                                                 
                                                  <v-btn
                                                    class="t-btn text-white feedback-submit-button text-none px-3"
                                                    :class="{'feedback-submit-button-inactive': characterCount == 0 || characterCount > 1000}"
                                                    density="default"
                                                    :disabled="characterCount == 0 || characterCount > 1000"
                                                    variant="flat"
                                                    @click="submitFeedback"
                                                    >
                                                    Submit Feedback
                                                  </v-btn>
                                                </v-col>
                                              </v-row>
                                          </v-card-text>
                                      </v-card>
                                  </v-col>
                              </v-row>
                              </v-col>
                      </v-row>
                  </v-container>
              </v-col>
          </v-row>
      </v-container>
      <v-snackbar
        v-model="showSubmissionMessage"
        :color="snackbarColor"
        :timeout="timeout"
      >
        <span class="submit-message-text">{{ feedbackSubmissionInfoLoadedFromServer.message }}</span>
        <template v-slot:actions>
          <v-btn density="compact" color="#8e8ea7" @click="showSubmissionMessage = false" icon="mdi-close"></v-btn>
        </template>
      </v-snackbar>
  </v-layout>
</template>

<script>
import {AnalyticsEvents, recordAnalyticsEvent} from "@/services/awsPinpointAnalyticsService";
import { getLoggedInUserInfo } from "@/services/authService";
import { mapState } from "vuex";

export default {
  name: 'FeedBack',
  async created() {
   recordAnalyticsEvent(AnalyticsEvents.v2FeedbackScreenOpen);
  },
  data() {
    return {
      review: "",
      showPlaceholder: true,
      characterCount: 0,
      timeout: 3000,
      showSubmissionMessage: false,
      snackbarColor: "rgb(var(--v-theme-cardBackgroundColor)",
      rules: [v => v.length <= 1000 || 'Max 1000 characters'],
    };
  },
  computed: {
  ...mapState([
    "appVersion",
    "appBuildNumber",
    "feedbackSubmissionInfoLoadedFromServer"
  ]),
  },
  methods: {
    updateCharacterCount() {
      this.characterCount = this.review.length;
      this.showPlaceholder = this.characterCount === 0;
    },
    submitFeedback() {
      if(this.review.length <= 1000 && this.review.length > 0){
        var userInfo = getLoggedInUserInfo();
        let payload = {
          userId: userInfo.userId,
          deviceId: userInfo.deviceId,
          feedback: this.review
        };
        recordAnalyticsEvent(AnalyticsEvents.v2FeedbackSubmitButtonClicked,{
          userId: userInfo.userId,
          deviceId: userInfo.deviceId,
          feedback: this.review
        });
        this.$store.dispatch('submitFeedback',payload).then((data) => {
          this.showSubmissionMessage = true;
          this.review = "";
          this.characterCount = 0;
        });
        
      }
    },
    
  },
};
</script>

<style scoped>

.feedbackCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}
.feedback-input{
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  color: white;
  border-color: rgb(var(--v-theme-primaryPurple));
}

.feedback-input::v-deep .v-counter {
  color: white !important;
}
.submit-message-text {
  font-family: "Inter", sans-serif !important;
  color: #8e8ea7;
  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 600 ;
}

.layoutLarge {
height: 100%;
}

.feedback-submit-button {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.feedback-submit-button-inactive{
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  border: none !important;
  border-radius: 12px;
  background: #4b4b5f !important;
}

.feedback-submit-button:hover:not([disabled]) {
  cursor: pointer;
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}
</style>
