<template>
  <div class="h-100">

    <v-row class="ma-0 pt-4 px-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }">
      <v-col cols="12" class="ma-0 pa-0">

        <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template v-slot:prepend>
            <v-btn v-if="breadCrumbs.length > 1" color="breadCrumbBackColor" class="ma-0 mr-2 text-primaryTextColor"
              size="x-small" @click="back()" icon="mdi-chevron-left">
            </v-btn>
            <v-icon icon="mdi-trophy-outline" color="primaryPurple"></v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-divider></v-divider>

    </v-row>
    <v-layout class="mb-0 pb-0" v-if="true" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
      <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

        <v-row class="mt-0 pt-0">

          <v-col class=" mt-0 pt-0 justify-center align-center text-center"
            :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
            <v-container fluid="" class="px-2 mt-0 pt-0">
              <v-row justify="center" class="mb-0" align="center">
                <v-col cols="12 mb-5">
                  <v-row class="t-h125 text-center mb-1 text-primaryTextColor" justify="center">
                    Join a challenge !


                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row justify="center">

                    <v-col cols="12" sm="9" md="8" lg="5" xl="4">
                      <v-hover v-slot="{ isHovering, props }">

                        <v-card elevation=0 :class="{ 'on-hover': isHovering }" class=" py-4 testCard " v-bind="props">
                          <v-card-text>


                            <v-row justify="center" class="my-3 px-1">
                              <v-col cols="auto" class="text-center my-0 py-0">

                                <span class="t-1rem text-primaryTextColor">
                                  Provide the challenge code shared by your friend

                                </span>
                              </v-col>
                            </v-row>


                            <!-- <v-row justify="center" class="my-3 px-1">
                              <v-col cols="auto" class="text-center my-0 py-0">

                                <span class="t-subtitle text-center text-primaryTextColor">


                                </span>
                              </v-col>
                            </v-row> -->


                            <v-row justify="center" class="my-3 px-1">
                              <v-col cols="10" sm="10" md="7" lg="7">
                                <v-text-field placeholder="Enter challenge code" variant="solo" no-resize rounded
                                  bg-color="white" :center-affix="true" type="number" color="transparent"
                                  base-color="transparent" v-model="roomCode"></v-text-field>
                              </v-col>

                            </v-row>

                            <v-row justify="center" class=" px-1">
                              <v-col cols="auto" class="text-center my-0 py-0">
                                <v-btn width="160" variant="flat"
                                  class="text-none t-btn btn-color text-primaryTextColor" size="large"
                                  @click="acceptChallenge">Join</v-btn>

                              </v-col>

                            </v-row>
                          </v-card-text>

                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>

            </v-container>
          </v-col>

        </v-row>
      </v-container>
    </v-layout>

  </div>
</template>
          
<script>
import { mapState } from "vuex";

import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";


export default {
  name: 'JoinRoomPage',
  data: () => ({
    dataLoaded: false,
    roomCode: "",
    breadCrumbs: []


  }),
  components:
  {


  },
  async created() {
    recordAnalyticsEvent(AnalyticsEvents.v2ChallengeJoinChallengeScreenOpen, {
        });
    this.createNodes()
  },
  computed: {
    ...mapState(["navDrawer"]),
    displayBreadCrumbs: {
      get() {
        return [this.breadCrumbs[this.breadCrumbs.length - 1]]
      }
    }
  },

  watch: {

  },

  methods: {
    async acceptChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeJoinChallengeScreenJoinButtonClicked, {
        challengeCode : this.roomCode
        });
      this.$router.push(`/challenge/participantwaitingroom?code=${this.roomCode}&type=room`)
    },
    createNodes() {
      this.breadCrumbs = [];

      this.breadCrumbs.push(
        {
          title: "Challenge",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: "challenge",
          pageRoute: `/challenge`

        });
      this.breadCrumbs.push(
        {
          title: "Join",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: "join",
          pageRoute: `/challenge/joinchallenge`

        });
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
    }
  }
}
</script>
          
<style scoped>
.testCard {
  border-radius: 12px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}



.join-bg {
  background: #FFE3D5;
}

.heightBread {
  height: 80px;
}

.layoutLarge {
  height: calc(100% - 160px);
}

.btn-color {
  background: #A174FD;
  border: 1.5px solid #A174FD;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
}
</style>
          
<style>
.v-text-field--outlined fieldset {
  color: red !important;
}

.v-text-field--outlined fieldset {
  color: red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
</style>