<template>
  <v-overlay persistent :model-value="downloadingTest" class="align-center justify-center">

    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="text-h6 font-weight-bold text-center text-white" cols="12">
        Downloading Test
      </v-col>
      <v-col cols="12">
        <v-progress-linear color="deep-purple-accent-4" indeterminate rounded height="6"
          style="width: 500px;"></v-progress-linear>
      </v-col>
    </v-row>

  </v-overlay>
  <div v-if="!downloadingTest" class="">




    <v-app-bar color="navbar">

      <v-toolbar-title class="ml-10 fontClass">{{ currentTest.testmetadata.testName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="backToTests" class="mr-4"> mdi-close</v-icon>

    </v-app-bar>

    <v-alert type="error" title="Test Cannot Be Started" v-if="currentTest.error != null && currentTest.error.length > 0"
      class="mx-8 mt-4" :text="currentTest.error"></v-alert>


    <section v-if="downloadingTest == false" class="mx-5 mx-sm-10 my-5 text-white">
      <v-row justify="space-around" class="mt-3 ml-2 align-center">
        <v-col cols="12">
          <v-row class="align-center">
            <v-icon class="mr-3">
              mdi-note-multiple-outline

            </v-icon>
            <span class="t-h5 
                "> {{ currentTest.testmetadata.testName }}
            </span>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-8  mb-6">
        <v-divider></v-divider>
      </v-row>
      <section class="mx-4 mt-10">

        <v-row class=" t-h5 ">
          Test Details
        </v-row>
        <v-row class="mt-8 align-center" justify="space-between">
          <v-col cols="auto" class="t-h6Reg ">

            <v-icon class="mr-1">
              mdi-dots-vertical-circle-outline
            </v-icon>
            {{ currentTest.testmetadata.totalQuestion }}
            questions
            <v-icon class="mr-1 ml-3">
              mdi-clock-outline
            </v-icon>
            {{ currentTest.testmetadata.testDurationInMinutes }} mins
          </v-col>



        </v-row>
        <v-row class="mt-10">
          <v-col cols="auto" class="t-h6Reg">

            <v-row justify="center">

              <v-btn color="primary" class="text-none t-btn btn-rd" :loading="startTestButtonLoading"
                :disabled="startButtonDisabled" @click="openTestQuestion()" elevation="0" prepend-icon="mdi-play-outline">
                {{
                  startTestText(currentTest) }}</v-btn>
            </v-row>

          </v-col>
        </v-row>
      </section>
      <v-row class="mt-8  mb-6">
        <v-divider></v-divider>
      </v-row>
      <v-row class=" mt-0 pt-0 mb-4 px-4  t-h5 ">
        Test Instructions
      </v-row>
      <v-row class=" ml-3 px-4">
        <HtmlRender class="text-instruction" :html-string="currentTest.testmetadata.instructions"></HtmlRender>
      </v-row>
      <v-row class="mt-8  mb-6">
        <v-divider></v-divider>
      </v-row>


      <v-row class="mb-4 px-4 t-h5">
        Test Syllabus
      </v-row>
      <v-row class=" ml-3 px-4">
        <HtmlRender class="text-instruction" :html-string="currentTest.testmetadata.syllabus"></HtmlRender>
      </v-row>
      <v-row class="mt-8  mb-6">
        <v-divider></v-divider>
      </v-row>


    </section>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import HtmlRender from "@/components/Test/HtmlRender";
import axios from "axios";
import { getLocalForageItem, setLocalForageItem } from "@/services/localforageService";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: 'StartTestPage',

  async created() {
    this.$store.commit("clearCurrentTestState")

    this.$store.commit('appbarVisible', false);
    this.$store.commit('navbarVisible', false);
    this.$store.commit("showNotification", false);
    this.$store.commit('startTestLoadedFromServer', false)

    console.log(this.$route.params);
    if ('testId' in this.$route.params) {
      const test = this.$route.params.testId
      this.currentTestId = test
      let practice = 'false'
      let testType = '';
      if('testType' in this.$route.query){
        testType = this.$route.query.testType;
        console.log("TestType", testType);
        if(testType == 'practice'){
          this.isPractice = true;
        }
      }
      // if ('practice' in this.$route.query) {
      //   practice = this.$route.query.practice
      //   this.isPractice = practice === "true" ? true : false
      // }
      this.$store.commit("updateCurrentTestId", test);

      this.downloadingTest = true;
      let qResponses = await getLocalForageItem('qResponses') ?? []
      let qDurations = await getLocalForageItem('qDurations') ?? []
      let serverLastAttemptSavedTimeStamp = await getLocalForageItem('serverLastAttemptSavedTimeStamp') ?? ''
      let body = {
        qDurations: qDurations, qResponses: qResponses, serverLastAttemptSavedTimeStamp: serverLastAttemptSavedTimeStamp,
        isPractice: practice === "true" ? true : false,
        testType: testType
      }

      this.$store.dispatch("startTest", { testId: test, ...body }).then(async () => {

        this.currentTest = this.startTestInfo
        if (this.currentTest.success == false) {
          this.downloadingTest = false
        }
        else {
          const testJson = await axios.get(this.currentTest.testurl)
          // console.log(testJson);
          this.$store.commit("setTestJson", testJson.data)
          this.$store.commit("updateQResponsesState")

          setLocalForageItem('qDurations', [])
          setLocalForageItem('qResponses', [])
          setLocalForageItem('serverLastAttemptSavedTimeStamp', '')
          this.downloadingTest = false
          this.startButtonDisabled = false
        }
      });
    }
  },
  data: () => ({
    downloadingTest: false,
    startButtonDisabled: true,
    startTestButtonLoading: false,
    currentTestId: "",
    isPractice: null,
    currentTest: {
      "testurl": "",
      "testmetadata": {
        "allowSkippingQuestion": null,
        "randomizedQuestionOrder": null,
        "testDurationInMinutes": null,
        "testRemainingDurationInMinutes": null,
        "totalQuestion": null,
        "instructions": "",
        "syllabus": "",
        "sections": [
        ]

      },

    }
  }),
  components:
  {
    HtmlRender,
  },
  computed: {
    ...mapState(["syncTimerData"]),
    startTestInfo: {
      get() {
        const data = this.$store.state.startTestInfo
        // console.log(data);
        return data
      }
    }
  },

  watch: {

  },

  methods: {
    async openTestQuestion() {
      //call sync timer api 
      this.startTestButtonLoading = true
      let status = "start"
      if (this.currentTest.alreadyInProgress == true) {
        status = "resume"
      }

      if (this.isPractice == true) {
        recordAnalyticsEvent(AnalyticsEvents.v2TestBeginPracticeTest, {
          testId: this.currentTestId,
          testName: this.startTestInfo.testmetadata.testName,
          testInstanceId: this.startTestInfo.testInstanceId
        });
      }
      else if (this.currentTest.alreadyInProgress == true) {
        recordAnalyticsEvent(AnalyticsEvents.v2TestResumeTest, {
          testId: this.currentTestId,
          testName: this.startTestInfo.testmetadata.testName,
          testInstanceId: this.startTestInfo.testInstanceId
        });
      }
      else {
        recordAnalyticsEvent(AnalyticsEvents.v2TestBeginTest, {
          testId: this.currentTestId,
          testName: this.startTestInfo.testmetadata.testName,
          testInstanceId: this.startTestInfo.testInstanceId
        });
      }


      this.$store.dispatch("updateSyncTimer", { state: status }).then(() => {
        this.$store.commit('updateCurrentTestState', {
          sectionIndex: 0,
          qIndex: 0
        })
        let remainingTime = this.$store.state.syncTimerData.testRemainingDurationInSeconds
        if (remainingTime == 0) {
          this.startButtonDisabled = true
          if ("message" in this.syncTimerData) {
            this.currentTest.error = this.syncTimerData.message
          }
          else {
            this.currentTest.error = "Test Time is Over"
          }
        }
        else {
          this.$store.commit("updateTestRoutingAllowed", true)
          this.$router.replace(`/testquestionpage`)

        }
        this.startTestButtonLoading = false
      })
    },
    startTestText(item) {
      let text = "Begin test"
      if (item.alreadyInProgress == true) {
        text = "Resume test"
      }
      return text
    },
    backToTests() {
      recordAnalyticsEvent(AnalyticsEvents.v2TestExitTestFromStartTestScreen, {
          testId: this.currentTestId,
          testName: this.startTestInfo.testmetadata.testName,
          testInstanceId: this.startTestInfo.testInstanceId
        });
      this.$router.back()
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-pad {
  padding-top: 15px;
  padding-bottom: 30px;
}

.fontH6 {
  font-family: 'Inter', sans-serif !important;

}

.text-instruction{
  color: white !important;
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
}
</style>
  