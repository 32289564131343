<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems" class="no-new-line t-h6Reg text-white text-primaryTextColor">
      <template v-slot:prepend>
        <v-btn v-if="breadcrumbItems.length > 0" color="#26253A" class="ma-0 mr-2 text-white" size="x-small"
          icon="mdi-chevron-left" @click="$router.back()"></v-btn>
      </template>
      <template v-slot:divider>
        <v-icon icon="mdi-chevron-right"></v-icon>
      </template>
    </v-breadcrumbs>
  </div>
  <v-layout class="mb-0 pb-0" v-if="true" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
    <v-container v-if="quizReports.length > 0" fluid class="mt-3 mt-md-0 mb-8 pb-0">

      <v-row class="mx-md-6 pt-0" justify="center" align="center">

        <v-col v-for="(item, i) in quizReports" :key="i" class=" justify-center align-center"
          :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }" cols="12" sm="6" lg="4">
          <v-hover v-slot="{ isHovering, props }">
            <v-card elevation=0 :class="{ 'on-hover': isHovering }" class=" report-container" v-bind="props">
              <v-card-text class="pa-6">
                <v-row class="pa-4">

                  <v-col cols="12">
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">Attempt time</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2 align-center">
                          <v-icon icon="mdi-clock-time-three-outline" color="#536DFE"></v-icon>
                          <span class="text-subtitle-2 pl-4">{{ getDateTimeFromTimeStamp(item.quizReport.startTimeStamp) }}</span>

                        </div>
                      </v-col>
                    </v-row>
                  
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">Attempted</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2">
                          <v-icon icon="mdi-file-question" color="#90CAF9"></v-icon>
                          <span class="text-subtitle-2 px-4">{{ item.quizReport.attemptedQuestions }}/{{
                            item.quizReport.totalQuestionInTest }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">Correct</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2">
                          <v-icon icon="mdi-check-circle" color="#55B56A"></v-icon>
                          <span class="text-subtitle-2 px-4">{{ item.quizReport.correctQuestions }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">Incorrect</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2">
                          <v-icon icon="mdi-close-circle" color="#F44336"></v-icon>
                          <span class="text-subtitle-2 px-4">{{ item.quizReport.incorrectQuestions }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">Quiz time</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2">
                          <v-icon icon="mdi-clock-time-three-outline" color="#536DFE"></v-icon>
                          <span class="text-subtitle-2 px-4">{{ formatTime(item.quizReport.quizTimeInMinutes) }}</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" align="center" class="t-h6 text-white mb-1">
                      <v-col class="pa-0 ma-0" cols="5" md="6">
                        <span class="text-caption text-md-subtitle-1 font-weight-bold text-start">My time</span>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="7" md="6">
                        <div class="d-flex px-2">
                          <v-icon icon="mdi-clock-time-three-outline" color="#536DFE"></v-icon>
                          <span class="text-subtitle-2 px-4">{{
                            convertTimeInSecondsToMinutes(item.quizReport.timespentInSeconds) }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="ma-0 text-center" align="center" justify="center">
                  <v-col class="py-0" cols="auto">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-sheet @click="onViewSolution(item)" class="view-past-solutions t-subtitle"
                        :class="{ 'on-hover': isHovering }" v-bind="props">
                        <span class="text-font-8rem text-white">View solutions</span>
                      </v-sheet>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>

      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment'

export default {
  name: 'QuizReports',
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  async created() {
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);

    if ('activityName' in this.$route.params) {
      this.activityName = this.$route.params.activityName;
    }
    if ('activityId' in this.$route.params) {
      this.activityId = this.$route.params.activityId;
    }

    if (!this.currentTopicId) {
      console.log("No topicId")
      this.$router.back();
    } else {

      if (this.currentTopicId && this.newLearnNodes) {
        let current = {};
        this.newLearnNodes.forEach(item => {
          if (item["entityId"] == this.currentTopicId) {
            current = item;
          }
        });
        this.currentTopic = current;
        this.breadcrumbItems.push({
          title: this.newLearnNodeActivity.topicName + " - Quiz Reports",
          disabled: false,
          href: this.currentTopicId,
        });
      }
      this.$store.dispatch("loadQuizReports", { activityId: this.activityId }).then(res => {
        this.quizReports = res;
      });
    }
    if (this.newLearnNodeActivity && this.newLearnNodeActivity.skillUpgradeQuiz) {
      this.newLearnNodeActivity.skillUpgradeQuiz.forEach(item => {
        if (item["activityId"] == this.currentActivityId) {
          this.currentActivity = item;
        }
      });
    }
    recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityQuizAttemptOpen, {
      topicId: this.currentTopicId,
      topicName: this.newLearnNodeActivity.topicName,
      currentSkill: this.newLearnNodeActivity.skill,
      isReady: this.currentTopic.isReady,
      activityId: this.activityId,
      activityType: "skillUpgradeQuiz",
      attempted: this.currentActivity.attempted,
      recommended: this.currentActivity.recommended
    });
  },
  data: () => ({
    currentTopic: {},
    currentActivity: {},
    activityId: "",
    activityName: "",
    breadcrumbItems: [],
    quizReports: []
  }),
  methods: {
    getIconSrc(url) {
      return window.location.protocol.replace("quizreports/", "") + "/" + url;
    },
    convertTimeInSecondsToMinutes(timeInSeconds) {
      if(timeInSeconds < 0) return '-- : --';
      console.log("result", timeInSeconds < 0);
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    formatTime(minutes) {
      const formattedMinutes = String(Math.floor(minutes)).padStart(2, '0');
      const formattedSeconds = '00';

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    getDateTimeFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM, hh:mm A");
      return dateString
    },

    onViewSolution(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityQuizAttemptOpen, {
        topicId: this.currentTopicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
        isReady: this.currentTopic.isReady,
        activityId: this.currentActivityId,
        activityType: "skillUpgradeQuiz",
        attempted: this.currentActivity.attempted,
        recommended: this.currentActivity.recommended,
        testId: item.testId,
        testInstanceId: item.testInstanceId,
        //remainning date
      });
      this.$router.push("/testresult/" + item.testId + "?instance=" + item.testInstanceId + "&report=" + false + "&quiz=" + true);
    },
    getCapitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    },
  },
  computed: {
    ...mapState(["newLearnNodes", "newLearnNodeActivity", "currentActivityId", "currentTopicId", "currentQuizReport"])
  },
}
</script>

<style scoped>
.skill-container {
  border: 0.5px solid rgba(231, 231, 231, 0.25);
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  border-radius: 14px;
  box-sizing: border-box;
}

.view-solution {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 10px 14px;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: black;
  background-color: rgb(var(--v-theme-navColor));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.report-container {
  border-color: transparent;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  width: 100%;
  background-color: #26253A;
}

.view-past-solutions {
  padding: 10px 25px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 1.5px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.view-past-solutions.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}
</style>
<style></style>