<template>
  <v-dialog style="backdrop-filter: blur(12.5px)" :model-value="dialog" width="900">
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-human-male-board" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.name" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>


          <v-row dense>
            <v-col cols="12">
              <v-autocomplete :items="activeTutorList" item-title="name" item-value="tutorId"
                v-model="editedItem.tutorIds" label="Assigned To Tutors" density="compact" variant="outlined"
                :rules="requiredRule" multiple dense>
                <template v-slot:selection="{ item, index }">
                  <span v-if="editedItem.tutorIds.length == this.tutorList.length && index == 0">All</span>
                  <span v-else-if="editedItem.tutorIds.length > 1 && index == 0">
                    {{ item.title }} + {{ editedItem.tutorIds.length - 1 }}
                  </span>
                  <span v-else-if="editedItem.tutorIds.length == 1 && index == 0">
                    {{ item.title }}
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.notes" label="Notes" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="isUserSuperAdmin && editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default" variant="flat" height="40" @click.stop="opendeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="save"> {{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Class -->
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent :model-value="dialogDelete" max-width="400px">
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0">
        <span class="assignment-form-title">Are you sure you want to delete
          ' {{ editedItem.name }}' ?</span>
      </v-card-text>
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="closeDeleteDialog()">No</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="deleteItem()">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container fluid v-if="showClassScreen" class="assignment-card">
    <v-row class="mb-6 ma-0" justify="space-between" align="center">
      <v-col class="pa-0 ma-0 mr-5">
        <v-row class="pa-1 card-bg pa-0 ma-0" justify="space-between" align="center" ref="AdminClassesScreen__classesComponent--searchBox">
          <v-col cols="auto" class="heading pa-0 ma-0 ml-3"> Classes </v-col>
          <v-divider vertical class="ml-5 mr-3" :thickness="3"></v-divider>
          <v-col class="ma-0 pa-0">
            <v-text-field density="compact" variant="solo" flat label="Search by Name" append-inner-icon="mdi-magnify"
              class="search-bar" bg-color="report-subCardBg" hide-details="true" v-model="searchFilter"
              v-on:input="debounceSearch" single-line></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="pa-0 ma-0">
        <v-btn class="t-btn text-white activity-btn text-none t-1remReg" density="default" variant="flat" height="46"
          @click="createNewClass" ref="AdminClassesScreen__classesComponent--addClass">Add class</v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-6 ma-0" align="center">
      <v-col cols="auto" class="pa-0 pr-3">
        <span class="t-1remReg text-bold">Filter</span>
      </v-col>

      <v-col cols="auto" class="pa-0 pr-3">
        <div class="filter-dropdown">
          <v-select class="filter-box" base-color="#ffffff" color="#ffffff" item-color="black" flat density="compact"
            :items="tutorListWithNoSet" v-model="filterClasses.tutors" label="Tutor" item-title="name"
            item-value="tutorId" multiple variant="outlined" hide-details prepend-inner-icon="mdi-account-settings" ref="AdminClassesScreen__classesComponent--filter0">
            <template v-slot:prepend-item>
              <v-list-item title="All" @click="toggleTutorList">
                <template v-slot:prepend>
                  <v-checkbox-btn :indeterminate="filterClasses.tutors.length > 0 &&
                    !(filterClasses.tutors.length == tutorListWithNoSet.length)
                    " :model-value="filterClasses.tutors.length == tutorListWithNoSet.length
    "></v-checkbox-btn>
                </template>
              </v-list-item>

              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ item, index }">
              <span v-if="filterClasses.tutors.length == tutorList.length &&
                index == 0
                ">All</span>
              <span v-else-if="filterClasses.tutors.length > 1 && index == 0">
                +{{ filterClasses.tutors.length }}
              </span>
              <span v-else-if="index == 0 && item.title.length <= 8">
                {{ item.title }}
              </span>
              <span v-else-if="index == 0 && item.title.length > 8">
                {{ item.title.substring(0, 5) }}...
              </span>
            </template>
          </v-select>
        </div>
      </v-col>

      <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer>

      <v-col cols="auto" class="pa-0">
        <div class="filter-dropdown">
          <v-select class="filter-box" density="compact" :items="sortList" v-model="sortCategory" label="Sort by"
            item-title="sortName" item-value="sortId" variant="outlined" hide-details prepend-inner-icon="mdi-sort" ref="AdminClassesScreen__classesComponent--filter1">
          </v-select>
        </div>
      </v-col>
    </v-row>

    <template v-for="(item, i) in classes" :key="i">
      <v-card class="card-bg card-border px-2 mb-3 py-2" @click="editClassDetails(item)" elevation="0" :ref="i==0? `AdminClassesScreen__classesComponent__classesList--card${i}` : ''">
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <!-- name -->
            <v-col cols="3" class="d-flex align-center justify-start pa-1">
              <div class="mr-4">
                <v-avatar size="34" color="report-subCardBg" class="icon-bg">
                  <v-icon icon="mdi-human-male-board" color="#ffffff" class="" size="20"></v-icon>
                </v-avatar>
              </div>
              <div class="pb-0">
                <span v-if="item.name != 'null'" class="t-1remReg">{{
                  item.name
                }}</span>
                <span v-else class="t-1remReg"> - </span>
              </div>
            </v-col>

            <!-- Assigned to -->
            <v-col cols="5" class="align-start pa-1">
              <v-tooltip location="top" max-width="400px">
                <template #activator="{ props }">
                  <div class="pb-0" v-bind="props" style="width: fit-content">
                    <span class="t-h6Reg">{{ getTutorNames(item.tutorIds) }}</span>
                  </div>
                </template>
                <span>{{ getAssignedToComplete(item) }}</span>
              </v-tooltip>
              <div v-if="item.tutorIds?.length > 0" class="">
                <span class="t-h6Reg parameter-style">Assigned To</span>
              </div>
            </v-col>

            <!-- Edit -->
            <v-col cols="auto">
              <v-row justify="end" align="center">
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      prepend-icon="mdi-pencil"
                      style="text-transform: none;"
                      size="small"
                      density="comfortable"
                      rounded="xl"
                      stacked
                      variant="text"
                      @click.stop="editClassDetails(item)" v-bind="props">
                      Edit
                    </v-btn>
                  </template>
                  <span>Edit Class</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-card v-if="classes.length == 0" class="card-bg card-border px-2 mb-3 py-2" elevation="0" ref="AdminClassesScreen__classesComponent__classesList--card0">
      <v-card-text class="text-primaryTextColor pa-0">
        <v-row justify="space-between" align="center" class="ma-0 px-1">
          <v-col cols="12" class="pa-3 text-center t-h6Reg">
            <span>No Classes Found</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-snackbar v-model="showClassMessage" :timeout="-1" color="cardBackgroundColor" location="top">
    <span class="submit-message-text text-report-red">{{ classErrorMessage }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";

export default {
  name: "AdminClassesComponent",
  emits: ['updateClassNumber'],
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  created() {
    recordAnalyticsEvent(AnalyticsEvents.v2AdminClassScreenOpen, {
      courseId: this.courseId,
    });
    this.$store.dispatch("getTutorList", { courseId: this.courseId }).then(() => { this.tutorListLoaded = true });
    this.$store.dispatch("getClassList", { courseId: this.courseId }).then(() => { this.classDetailLoaded = true });
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: 'AdminClassesScreen',
    formValue: false,
    classDetailLoaded: false,
    tutorListLoaded: false,
    searchFilter: "",
    filterClasses: {
      tutors: [],
    },
    sortCategory: null,
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
    ],
    dialog: false,
    dialogDelete: false,
    defaultItem: {
      name: "",
      notes: "",
      active: true,
      tutorIds: [],
    },
    editedItem: {
      name: "",
      notes: "",
      active: true,
      tutorIds: [],
    },
    editedIndex: -1,
    requiredRule: [(v) => !!v || "Required"],
    dummyData: [
      {
        "batchId": "12",
        "name": "Class A",
        "notes": "",
        "tutorIds": ['1', '2', '1', '1', '1'],
        "active": true
      },
      {
        "batchId": "12",
        "name": "Class B",
        "notes": "",
        "tutorIds": ['2', '1', '1', '1'],
        "active": true
      }
    ],
    classErrorMessage: '',
    showClassMessage: false,
  }),
  methods: {
    debounceSearch() {

      if (this.searchFilter.trim() == "") {
        this.$store.commit("clearSearchedClassList");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        recordAnalyticsEvent(AnalyticsEvents.v2AdminClassScreenClassSearched, {
          courseId: this.courseId,
          searchedKeyword: this.searchFilter.trim()
        });
        // your action
        if (this.searchFilter.trim() != "") {
          this.$store.dispatch("loadSearchedAdminClassList", {
            courseId: this.courseId,
            search: this.searchFilter.trim(),
          });
        }
      }, 700); // delay
    },
    getTime(time) {
      if (time == 0) {
        return "-";
      }
      let vardata = moment(time).fromNow();
      return vardata;
    },
    getTutorNames(tutorIds) {
      if (tutorIds.length > 0) {
        const foundTutors = _.filter(this.tutorList, (tutor) =>
          tutorIds?.includes(tutor.tutorId)
        );
        const foundNames = _.map(foundTutors, (tutor) => tutor.name);
        const num = 1;
        return foundNames.slice(0, num).join(", ") + `${foundNames.length > num ? ( ', + ' + (foundNames.length - num)) : ''}`;
      }
      return "-";

    },
    // ------------
    getAssignedToComplete(item) {
      let namesList = [];
      if (item.tutorIds.length == 0) {
        return 'Class is not assigned any tutor';
      }
      if (item.tutorIds.length > 0) {
        namesList = item.tutorIds.map((e) => this.getTutorNames(e));
      }

      return namesList.join(", ")
    },
    toggleTutorList() {
      if (this.filterClasses.tutors.length == this.tutorListWithNoSet.length) {
        this.filterClasses.tutors = [];
      } else {
        this.filterClasses.tutors = this.tutorListWithNoSet
          .slice()
          .map((e) => e.tutorId);
      }
    },
    editClassDetails(item) {
      this.editedIndex = this.classes.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.dialog = true;
    },
    createNewClass() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editedItem.courseId = this.courseId;
        if (this.editedIndex > -1) {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminClassScreenEditButtonClicked, {
            courseId: this.courseId,
            batchId: this.editedItem.batchId
          });
          this.$store.dispatch("updateClass", this.editedItem).then((res) => {
            if (res.success) {
              this.close();
            } else {
              this.classErrorMessage = res.message;
              this.showClassMessage = true;
            }
          });
        } else {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminClassScreenAddButtonClicked, {
            courseId: this.courseId,
            batchId: this.editedItem.batchId
          });
          this.$store.dispatch("addClass", this.editedItem).then((res) => {
            if (res.success) {
              this.close();
            } else {
              this.classErrorMessage = res.message;
              this.showClassMessage = true;
            }
          });
        }
      }
    },
    closeSnackBar() {
      this.classErrorMessage = "";
      this.showClassMessage = false;
    },
    opendeleteDialog() {
      this.dialog = false;
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.dialog = true;
    },
    deleteItem() {
      recordAnalyticsEvent(AnalyticsEvents.v2AdminClassScreenDeleteButtonClicked, {
        courseId: this.courseId,
        batchId: this.editedItem.batchId
      });
      const reqData = {
        courseId: this.courseId,
        batchId: this.editedItem.batchId
      };
      this.$store.dispatch("deleteClass", reqData).then((res) => {
        if (res.success) {
          this.close();
          this.$store.commit("deleteClass", reqData);
          this.dialogDelete = false;
          this.editedItem = _.cloneDeep(this.defaultItem);
        } else {
          this.classErrorMessage = res.message;
          this.showClassMessage = true;
        }
      });
    },
    isClassActive(item) {
      return item.active ? 'Class is active' : 'Class is deactivated';
    },
  },
  computed: {
    ...mapState([
      "adminStudentList",
      "searchedClassList",
      "classList",
      "tutorList",
      "packageList",
      "courseId",
      "isUserSuperAdmin"
    ]),
    ...mapGetters(['currentHighlightedElements']),
    showClassScreen: {
      get() {
        if (this.classDetailLoaded && this.tutorListLoaded) {
          return true;
        }
        else return false;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Class" : "Edit Class";
    },
    activeTutorList: {
      get() {
        let list = _.cloneDeep(this.tutorList);
        list = _.orderBy(list, [tutor => tutor?.name.toLowerCase()], "asc");
        return list;
      }
    },
    tutorListWithNoSet: {
      get() {
        const noData = { tutorId: 'empty', name: 'Not Assigned' };
        return _.concat(noData, this.activeTutorList)
      }
    },
    classes: {
      get() {
        let classes = [];
        if (this.searchFilter.trim() != "") {
          classes = this.searchedClassList;
        } else {
          classes = this.classList;
        }

        switch (this.sortCategory) {
          case "nameAsc":
            classes = _.orderBy(classes, [batch => batch?.name?.toLowerCase()], "asc");
            break;
          case "nameDesc":
            classes = _.orderBy(classes, [batch => batch?.name?.toLowerCase()], "desc");
            break;

          default:
            classes = _.orderBy(classes, [batch => batch?.name?.toLowerCase()], "asc");
        }

        let check = false;
        let modifiedClassesData = classes;

        if (this.filterClasses.tutors.length > 0) {
          check = true;
          let notAssignTutor = this.filterClasses.tutors.includes('empty');
          modifiedClassesData = _.filter(modifiedClassesData, (classData) => {
            return _.some(classData.tutorIds, (tutor) =>
              _.includes(this.filterClasses.tutors, tutor)
            ) || (notAssignTutor && classData.tutorIds.length == 0);
          });
        }
        if (check) {
          return modifiedClassesData;
        } else return classes;
      },
    },

  },
  watch: {
    classes: {
      handler(val){
        this.$emit('updateClassNumber', val.length);
      }
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
/* ---------------- */

.assignment-form-main {
  overflow: auto !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.delete-btn {
  border: 2px solid red !important;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

/* ------------------- */
.assignment-card {
  border-radius: 12px !important;
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.card-bg {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.icon-bg-disabled {
  background: rgb(var(--v-theme-domain-button-color)) !important;
}


.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

/* ----------------- */
.filter-dropdown {
  width: 200px !important;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word;
  /* or word-break: break-all; */
}
</style>

<style>
.search-bar .v-field__input,
.search-bar .v-field-label,
.filter-box .v-label,
.filter-box .v-field__input {
  color: #BFBEC6 !important;
}
</style>