<template>
  <v-row v-if="dataLoaded" class="ma-0" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end" align="center">
    <v-col cols="auto" class="pa-0">
      <SelfHelpGuideTogglerComponent class="mt-6 mr-8"/>
    </v-col>
  </v-row>
  <v-container fluid class="mt-md-0 mb-0 mt-2 pb-4 main-lg">
    <v-container fluid class="container-width">
      <v-row>
        <v-col>
          <v-card class="main-card text-white mb-4">
            <v-card-title class="px-5 pt-4 pb-0">
              <span class="main-heading">Admin Dashboard</span>
              <v-divider class="mt-2"></v-divider>
            </v-card-title>
            <v-card-text class="pa-5 pt-4">
              <v-row class="ma-0">
                <v-col
                  cols="auto"
                  class="mr-1 tab t-1remReg text-white"
                  :class="{ 'selected-tab': tab.id == tabIndex }"
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click.stop="changeTab(tab)"
                  :ref="`AdminStudentsScreen__tabs--${tab.uniqueId}`"
                >
                  {{ getTabName(tab.name) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      
          <v-card class="main-card text-white">
            <v-card-text class="pa-0">
              <v-row class="ma-0" v-if="tabIndex == '1'"> 
                <AdminStudentsComponent @updateStudentNumber="updateStudentsNumber"></AdminStudentsComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '2'">
                <AdminTutorsComponent @updateTutorNumber="updateTutorNumber"></AdminTutorsComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '3'"> 
                <AdminClassesComponent @updateClassNumber="updateClassNumber"></AdminClassesComponent>
                
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '4'"> 
                <OrganizationDetailComponent ></OrganizationDetailComponent>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  
</template>
<script>
import { useDisplay } from "vuetify";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import OrganizationDetailComponent from "./OrganizationDetailComponent.vue";
import AdminStudentsComponent from "./AdminStudentsComponent.vue";
import AdminTutorsComponent from "./AdminTutorsComponent.vue";
import AdminClassesComponent from "./AdminClassesComponent.vue";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
export default {
  name: "ClassDetailPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  components: {
    OrganizationDetailComponent,
    AdminStudentsComponent,
    AdminTutorsComponent,
    AdminClassesComponent,
    SelfHelpGuideTogglerComponent
  },
  data: () => ({
    dataLoaded: false,
    selectedTab: null,
    tabs: [
      {
        name: "Students",
        id: "1",
        uniqueId: 'students'
      },
      {
        name: "Tutors",
        id: "2",
        uniqueId: 'tutors'
      },
      {
        name: "Classes",
        id: "3",
        uniqueId: 'classes'
      },
      {
        name: "Organization Details",
        id: "4",
        uniqueId: 'organizationDetails'
      },
    ],
    tabIndex: "",
    tutorListLoaded: false,
    classListLoaded: false,
    packageListLoaded: false,
    studentsDetailLoaded: false,
    studentNumber: 0,
    tutorNumber: 0,
    classNumber: 0,
  }),
  async beforeRouteUpdate(to, from) {
  },
  async created() {
    this.$store.commit('appbarVisible', true);
		this.$store.commit('navbarVisible', true);
    
    if(!this.isUserAdmin ){
      if(this.isUserTeacher){
        this.$router.replace('/teacher/students');
      }
      else{
        this.$router.replace('/learn');
      }
    }
    else{
      this.tabIndex = this.$route.params.adminTab;
      if(this.tabIndex > this.tabs.length){
        this.$router.replace('1');
        this.tabIndex = 1;
      }
      if(this.tabIndex == 2){
        this.$store.dispatch("getClassList", { courseId: this.courseId })
        this.$store.dispatch("loadAdminStudentList", { courseId: this.courseId })
      }
      else if(this.tabIndex == 3){
        this.$store.dispatch("loadAdminStudentList", { courseId: this.courseId })
      }
      else if(this.tabIndex == 4){
        this.$store.dispatch("getTutorList", { courseId: this.courseId })
        this.$store.dispatch("getClassList", { courseId: this.courseId })
        this.$store.dispatch("loadAdminStudentList", { courseId: this.courseId })
      }
      this.selectedTab = _.find(this.tabs, {id: this.tabIndex});
      this.dataLoaded = true;
    }
    
  },
  computed: {
    ...mapState([
      "courseId", "adminStudentList", "tutorList", "classList", "isUserAdmin","isUserTeacher"
    ]),
    ...mapGetters(['currentHighlightedElements']),
  },

  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    changeTab(tab) {
      this.tabIndex = tab.id;
      this.selectedTab = tab;
      recordAnalyticsEvent(AnalyticsEvents.v2AdminDashboardTabSwitched, {
        courseId: this.$store.state.courseId,
        tabName: tab.name,
      });
      this.$router.replace(`${tab.id}`);
      if(this.tabIndex != 1){
        this.updateStudentsNumber(0);
      }
      if(this.tabIndex != 2){
        this.updateTutorNumber(0);
      }
      if(this.tabIndex != 3){
        this.updateClassNumber(0);
      }
    },
    getTabName(name){
      if(name == "Students"){
        return name + `${(this.tabIndex!=1 && this.adminStudentList?.length>0)? ' (' + this.adminStudentList.length +')' : this.studentNumber > 0 ? ( ' (' + this.studentNumber +')') : ''}`
      }
      else if(name == "Tutors"){
        return name + `${(this.tabIndex!=2 && this.tutorList?.length>0)? ' (' + this.tutorList.length +')' : this.tutorNumber > 0 ? ( ' (' + this.tutorNumber +')') : ''}`
      }
      else if(name == "Classes"){
        return name + `${(this.tabIndex!=3 && this.classList?.length>0)? ' (' + this.classList.length +')' : this.classNumber > 0 ? ( ' (' + this.classNumber +')') : ''}`
      }
      else return name
    },
    updateStudentsNumber(studentNumber){
      this.studentNumber = studentNumber;
    },
    updateTutorNumber(tutorNumber){
      this.tutorNumber = tutorNumber;
    },
    updateClassNumber(classNumber){
      this.classNumber = classNumber;
    },
    
  },
};
</script>
<style scoped>
.container-width{
  max-width: 1200px;
}

.heightBread {
  height: 80px;
}


.main-lg {
  padding-top: 32px;
}

.main-md {
  padding-top: 60px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.tab-name{
  font-weight: 600;
}

.tab {
  background: rgb(var(--v-theme-report-subCardBg));
  padding: 10px 15px;
  margin-right: 12px !important;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 12px;
}

.selected-tab {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
  border: 2px solid transparent;
}
</style>
