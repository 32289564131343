const Matrix_KEYBOARD_LAYER = {
  "matrix-layer": {
    styles: "",
    rows: [
      [
        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR1C1.png'></span>",
          insert: "$$\\begin{bmatrix} {#0} \\ \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[1x2]</span>",
              insert: "$$\\begin{bmatrix} {#0} & {#0}  \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[1x3]</span>",
              insert: "$$\\begin{bmatrix} {#0} & {#0} & {#0}  \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[1x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[1x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0}  \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[1x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR2C1.png'></span>",
          insert: "$$\\begin{bmatrix} {#0} \\\\ {#0} \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[2x2]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[2x3]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[2x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[2x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[2x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR3C1.png'></span>",
          insert: "$$\\begin{bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[3x2]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[3x3]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[3x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[3x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[3x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR4C1.png'></span>",
          insert:
            "$$\\begin{bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0}  \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[4x2]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[4x3]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[4x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[4x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[4x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR5C1.png'></span>",
          insert:
            "$$\\begin{bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0}  \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[5x2]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[5x3]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[5x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[5x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[5x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/standard_matrix/MSR6C1.png'></span>",
          insert:
            "$$\\begin{bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0} \\\\ {#0} \\end{bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>[6x2]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[6x3]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[6x4]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[6x5]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>[6x6]</span>",
              insert:
                "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
            }
          ]
        },

        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        {
          class: "small",
          label: "<span>[1x1]</span>",
          insert: "$$\\begin{bmatrix} {#0}  \\end{bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>[2x2]</span>",
          insert:
            "$$\\begin{bmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>[3x3]</span>",
          insert:
            "$$\\begin{bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>[4x4]</span>",
          insert:
            "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>[5x5]</span>",
          insert:
            "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>[6x6]</span>",
          insert:
            "$$\\begin{bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{bmatrix}$$"
        }
      ],
      [
        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR1C1.png'></span>",
          insert: "$$\\begin{pmatrix} {#0} \\ \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(1x2)</span>",
              insert: "$$\\begin{pmatrix} {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(1x3)</span>",
              insert: "$$\\begin{pmatrix} {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(1x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(1x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(1x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR2C1.png'></span>",
          insert: "$$\\begin{pmatrix} {#0} \\\\ {#0} \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(2x2)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(2x3)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(2x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(2x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(2x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR3C1.png'></span>",
          insert: "$$\\begin{pmatrix} {#0} \\\\ {#0}\\\\ {#0} \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(3x2)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(3x3)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(3x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(3x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(3x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR4C1.png'></span>",
          insert:
            "$$\\begin{pmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0}  \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(4x2)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(4x3)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(4x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(4x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(4x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR5C1.png'></span>",
          insert:
            "$$\\begin{pmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0}  \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(5x2)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(5x3)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(5x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(5x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(5x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/round_matrix/MRR6C1.png'></span>",
          insert:
            "$$\\begin{pmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0} \\\\ {#0} \\end{pmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>(6x2)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(6x3)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(6x4)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(6x5)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            },
            {
              class: "small",
              label: "<span>(6x6)</span>",
              insert:
                "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{pmatrix}$$"
            }
          ]
        },

        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        {
          class: "small",
          label: "<span>(1x1)</span>",
          insert: "$$\\begin{pmatrix} {#0}  \\end{pmatrix}$$"
        },
        {
          class: "small",
          label: "<span>(2x2)</span>",
          insert:
            "$$\\begin{pmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{pmatrix}$$"
        },
        {
          class: "small",
          label: "<span>(3x3)</span>",
          insert:
            "$$\\begin{pmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{pmatrix}$$"
        },
        {
          class: "small",
          label: "<span>(4x4)</span>",
          insert:
            "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
        },
        {
          class: "small",
          label: "<span>(5x5)</span>",
          insert:
            "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
        },
        {
          class: "small",
          label: "<span>(6x6)</span>",
          insert:
            "$$\\begin{pmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{pmatrix}$$"
        }
      ],

      [
        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR1C1.png'></span>",
          insert: "$$\\begin{vmatrix} {#0} \\ \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|1x2|</span>",
              insert: "$$\\begin{vmatrix} {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|1x3|</span>",
              insert: "$$\\begin{vmatrix} {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|1x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|1x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|1x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR2C1.png'></span>",
          insert: "$$\\begin{vmatrix} {#0} \\\\ {#0} \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|2x2|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|2x3|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|2x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|2x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|2x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR3C1.png'></span>",
          insert: "$$\\begin{vmatrix} {#0} \\\\ {#0}\\\\ {#0} \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|3x2|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|3x3|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|3x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|3x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|3x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR4C1.png'></span>",
          insert:
            "$$\\begin{vmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0}  \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|4x2|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|4x3|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|4x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|4x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|4x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR5C1.png'></span>",
          insert:
            "$$\\begin{vmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0}  \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|5x2|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|5x3|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|5x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|5x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|5x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/straight_matrix/MSTR6C1.png'></span>",
          insert:
            "$$\\begin{vmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0} \\\\ {#0} \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>|6x2|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|6x3|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|6x4|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|6x5|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            },
            {
              class: "small",
              label: "<span>|6x6|</span>",
              insert:
                "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{vmatrix}$$"
            }
          ]
        },

        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        {
          class: "small",
          label: "<span>|1x1|</span>",
          insert: "$$\\begin{vmatrix} {#0}  \\end{vmatrix}$$"
        },
        {
          class: "small",
          label: "<span>|2x2|</span>",
          insert:
            "$$\\begin{vmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{vmatrix}$$"
        },
        {
          class: "small",
          label: "<span>|3x3|</span>",
          insert:
            "$$\\begin{vmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{vmatrix}$$"
        },
        {
          class: "small",
          label: "<span>|4x4|</span>",
          insert:
            "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
        },
        {
          class: "small",
          label: "<span>|5x5|</span>",
          insert:
            "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
        },
        {
          class: "small",
          label: "<span>|6x6|</span>",
          insert:
            "$$\\begin{vmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{vmatrix}$$"
        }
      ],

      [
        {
          class: "small",
          label:
            "<span><img  class ='plainmatrix'   src='/assets/plain_matrix/MPR1C1.png'></span>",
          insert: "$$\\begin{matrix*} {#0} \\ \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>1x2</span>",
              insert: "$$\\begin{matrix*} {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>1x3</span>",
              insert: "$$\\begin{matrix*} {#0} & {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>1x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>1x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>1x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='plainmatrix'   src='/assets/plain_matrix/MPR2C1.png'></span>",
          insert: "$$\\begin{matrix*} {#0} \\\\ {#0} \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>2x2</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>2x3</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>2x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>2x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>2x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/plain_matrix/MPR3C1.png'></span>",
          insert: "$$\\begin{matrix*} {#0} \\\\ {#0}\\\\ {#0} \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>3x2</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>3x3</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>3x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>3x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>3x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/plain_matrix/MPR4C1.png'></span>",
          insert:
            "$$\\begin{matrix*} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0}  \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>4x2</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>4x3</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>4x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>4x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>4x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/plain_matrix/MPR5C1.png'></span>",
          insert:
            "$$\\begin{matrix*} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0}  \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>5x2</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>5x3</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>5x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>5x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>5x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/plain_matrix/MPR6C1.png'></span>",
          insert:
            "$$\\begin{matrix*} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0} \\\\ {#0} \\end{matrix*}$$",
          variants: [
            {
              class: "small",
              label: "<span>6x2</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>6x3</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>6x4</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>6x5</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            },
            {
              class: "small",
              label: "<span>6x6</span>",
              insert:
                "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{matrix*}$$"
            }
          ]
        },

        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        {
          class: "small",
          label: "<span>1x1</span>",
          insert: "$$\\begin{matrix*} {#0}  \\end{matrix*}$$"
        },
        {
          class: "small",
          label: "<span>2x2</span>",
          insert:
            "$$\\begin{matrix*} {#0} & {#0} \\\\ {#0} & {#0} \\end{matrix*}$$"
        },
        {
          class: "small",
          label: "<span>3x3</span>",
          insert:
            "$$\\begin{matrix*} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{matrix*}$$"
        },
        {
          class: "small",
          label: "<span>4x4</span>",
          insert:
            "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
        },
        {
          class: "small",
          label: "<span>5x5</span>",
          insert:
            "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
        },
        {
          class: "small",
          label: "<span>6x6</span>",
          insert:
            "$$\\begin{matrix*} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{matrix*}$$"
        }
      ],

      [
        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR1C1.png'></span>",
          insert: "$$\\begin{Bmatrix} {#0} \\ \\end{Bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{1x2}</span>",
              insert: "$$\\begin{Bmatrix} {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{1x3}</span>",
              insert: "$$\\begin{Bmatrix} {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{1x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{1x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{1x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR2C1.png'></span>",
          insert: "$$\\begin{Bmatrix} {#0} \\\\ {#0} \\end{Bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{2x2}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{2x3}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{2x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{2x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{2x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR3C1.png'></span>",
          insert: "$$\\begin{Bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\end{Bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{3x2}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{3x3}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{3x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{3x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{3x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR4C1.png'></span>",
          insert:
            "$$\\begin{Bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0}  \\end{Bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{4x2}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{4x3}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{4x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{4x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{4x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR5C1.png'></span>",
          insert:
            "$$\\begin{Bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0}  \\end{Bmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{5x2}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{5x3}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{5x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{5x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{5x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            }
          ]
        },

        {
          class: "small",
          label:
            "<span><img  class ='matrix'   src='/assets/curly_matrix/MCR6C1.png'></span>",
          insert:
            "$$\\begin{Bmatrix} {#0} \\\\ {#0}\\\\ {#0} \\\\ {#0} \\\\ {#0} \\\\ {#0} \\end{vmatrix}$$",
          variants: [
            {
              class: "small",
              label: "<span>{6x2}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0}\\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\\\ {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{6x3}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{6x4}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{6x5}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            },
            {
              class: "small",
              label: "<span>{6x6}</span>",
              insert:
                "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\end{Bmatrix}$$"
            }
          ]
        },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        { class: "separator w5" },
        {
          class: "small",
          label: "<span>{1x1}</span>",
          insert: "$$\\begin{Bmatrix} {#0}  \\end{Bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>{2x2}</span>",
          insert:
            "$$\\begin{Bmatrix} {#0} & {#0} \\\\ {#0} & {#0} \\end{Bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>{3x3}</span>",
          insert:
            "$$\\begin{Bmatrix} {#0} & {#0} & {#0}\\\\ {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} \\end{Bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>{4x4}</span>",
          insert:
            "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>{5x5}</span>",
          insert:
            "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\\\ {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
        },
        {
          class: "small",
          label: "<span>{6x6}</span>",
          insert:
            "$$\\begin{Bmatrix} {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0}  \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\\\  {#0} & {#0} & {#0} & {#0} & {#0} & {#0} \\end{Bmatrix}$$"
        }
      ]
    ]
  }
};

const Matrix_KEYBOARD = {
  "matrices-keyboard": {
    label: "<span><i>M11</i></span>", // Label displayed in the Virtual Keyboard Switcher
    tooltip: "MATRIX", // Tooltip when hovering over the label
    layer: "matrix-layer"
  }
};
export { Matrix_KEYBOARD_LAYER, Matrix_KEYBOARD };
