<template>
    <div :class="`chatbot-box ${chatbot ? chatboxClass : ''}`"  v-if="config.practiceChatBotEnabled && showchatbot">
        <v-dialog v-model="tokenInfoDialog" width="auto" contained content-class="dround" class="dialog-rounded">
            <div style=" position: absolute; left: 40%;top: -30px; z-index: 1005;">

                <v-avatar size="70">
                    <v-img src="@/assets/token.png"></v-img>

                </v-avatar>

            </div>
            <v-card class="dround" persistent style="position: relative;">


                <v-card-text class="fill-height d-flex flex-column ">
                    <v-row justify="center" class="mb-0 " align="center">
                        <v-col cols="12">
                            <v-row class="t-h6 pa-0 ma-0 " justify="end">
                                <v-col class="pa-0 ma-0 text-end" style="color:rgba(35, 34, 56, 0.7);">
                                    <v-icon size="22" @click="tokenInfoDialog = false" color="#00000066">
                                        mdi-close-thick
                                    </v-icon> </v-col>

                            </v-row>
                            <v-row class="t-h6 pa-0 ma-0 mt-4" justify="center">
                                <v-col class="pa-0 ma-0 text-center" cols="9" style="color:rgba(35, 34, 56, 0.7);">
                                    You have {{ aiTokensLeft }} Mento Tokens to Chat with Mento AI
                                </v-col>

                            </v-row>
                            <v-row class="mt-6 mb-3" justify="center">
                                <v-col class="pa-0 ma-0 text-center" cols="11">
                                    <v-progress-linear height="10" bg-color="grey" v-model="aiTokensUsedForBar" rounded
                                        :color="aiTokensUsedForBarColorComputed">
                                    </v-progress-linear>
                                </v-col>

                            </v-row>

                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
        <v-dialog v-model="limitReachedDialog" width="auto" contained content-class="dround" class="dialog-rounded">
            <v-card class="dround" persistent>
                <v-card-text class="py-6">
                    <v-row align="center">
                        <v-col cols="auto" class="pa-0 ma-0 mr-3">
                            <v-avatar size="35">
                                <v-img src="@/assets/mentochat.png"></v-img>

                            </v-avatar>

                        </v-col>

                        <v-col class="pa-0 ma-0">
                            <v-row class="t-h6 pa-0 ma-0 mb-1">
                                <v-col class="pa-0 ma-0" cols="10">
                                    Daily Limit Reached
                                </v-col>
                                <v-spacer>

                                </v-spacer>
                                <v-col cols="auto" class="pa-0 ma-0">
                                    <v-icon size="22" color="#00000066" @click="limitReachedDialog = false">
                                        mdi-close-thick
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row class="t-h6Reg pa-0 ma-0">
                                <v-col class="pa-0 ma-0 text-black" cols="10">
                                    {{ limitReachedDialogMsg }}
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
        <v-dialog v-model="annotateDialog" width="auto" contained content-class="dround" class="dialog-rounded">
            <v-card class="dround" persistent>
                <v-card-text class="py-6">
                    <v-row align="center">
                        <v-col cols="auto" class="pa-0 ma-0 mr-3">
                            <v-avatar size="35">
                                <v-img src="@/assets/mentochat.png"></v-img>

                            </v-avatar>

                        </v-col>

                        <v-col class="pa-0 ma-0">
                            <v-row class="t-h6 pa-0 ma-0 mb-1">
                                <v-col class="pa-0 ma-0" cols="10">
                                    Make a Selection First
                                </v-col>
                                <v-spacer>

                                </v-spacer>
                                <v-col cols="auto" class="pa-0 ma-0">
                                    <v-icon size="22" color="#00000066" @click="annotateDialog = false">
                                        mdi-close-thick
                                    </v-icon>
                                </v-col>
                            </v-row>
                            <v-row class="t-h6Reg pa-0 ma-0">
                                <v-col class="pa-0 ma-0 text-black" cols="10">
                                    Make a Selection First
                                    Please select some text from question and try again
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
        <Transition name="slide-fade">

            <v-card class="chatbot-card h-100" elevation="0" v-if="chatbot">
                <v-card-text class="pa-0 ma-0 h-100" style="position: relative;">


                    <v-row justify="end" align="center" class="chatbot-header pa-0 ma-0 px-4 py-3">
                        <v-col cols="auto" class="pa-0 ma-0 mr-4">
                            <v-avatar size="35">
                                <v-img src="@/assets/mentochat.png"></v-img>

                            </v-avatar>

                        </v-col>

                        <v-col cols="auto" class="">
                            <v-row>
									<span class="mento">Mento
									</span>
									<v-badge class="mento-sup text-white" content="BETA" color="transparent" offset-x="-5" :dot="false">
										
									</v-badge>
								</v-row>
                            <v-row>
                                <span class="t-subtitleGrey mt-1">Your Personal AI Companion</span>

                            </v-row>
                        </v-col>
                        <v-spacer>

                        </v-spacer>



                        <v-col cols="auto" class="pa-0 ma-0	mr-4">


                            <v-progress-circular bg-color="grey" :size="40" :width="3" v-model="aiTokensUsedForBar"
                                @click="tokenInfoDialog = true" :color="aiTokensUsedForBarColorComputed">
                                <v-icon size="22" color="white">
                                    mdi-lightning-bolt
                                </v-icon>
                            </v-progress-circular>
                        </v-col>
                        <v-col cols="auto" class="pa-0 ma-0">


                            <v-icon size="22" color="#fdfdff99" @click="closeChatbot">
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-sheet class=" pt-2 pb-2 chatbox-main">
                        <v-row class="my-0 py-0 px-4 mb-1 t-subtitle  text-center text-secondaryTextColor"
								justify="center" align="center">
								Mento makes mistakes sometimes.

								<span class="text-primary ml-1" @click="openHeresWHy" style="cursor: pointer;">
									Here's why
									<span>
										<v-icon color="primary" size="15">
											mdi-open-in-new
										</v-icon>
										.
									</span>
								</span>
							</v-row>
                        <v-row class="my-0 py-0 px-4" id="dynamic-btns">
                            <v-divider class="mb-1"></v-divider> <v-col cols="auto"
                                v-for="(btn, index) in  getButtonMessage(null)" :key="index" class="pa-1">
                                <v-sheet class=" text-white">
                                    <v-btn variant="outlined" class="btn-rd " @click="chatbotButtonClicked(btn)"
                                        :disabled="chatButtonDisabled(btn)">
                                        <span class="text-decoration-none text-none t-h6Reg text-black">
                                            {{ btn.text }}

                                        </span>
                                    </v-btn> </v-sheet>
                            </v-col>
                        </v-row>



                        <v-row class="my-2">
                                <v-divider>

                                </v-divider>
                            </v-row>
                            <v-sheet class="chatbot-bg msg_container_base h-100 pt-2" :style="{ height: 'calc(100% - ' + buttonsheight + 'px - 40px) !important' }" >
                                <v-sheet v-for="( msg, msgIndex ) in  currentMessagesList " :key="msg.messageId">

                                    <v-row v-if="msg.type == 'nudge'" class="">

                                        <v-col cols="12" class="t-h6Reg">

                                            <v-sheet class="bot-msg px-3 py-4">
                                                <v-row class="my-0 py-0 ">
                                                    <v-col cols="auto " class="my-0 py-0 mr-0 pr-0">
                                                        <v-avatar size="30">
                                                            <v-img src="@/assets/mentochat.png"></v-img>

                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col class="my-0 py-0 chatbotText">
                                                        <div v-html="getMarkDownHtmlForQestionText(msg.message)"
                                                            style="white-space: pre-line;">

                                                        </div>

                                                    </v-col>


                                                </v-row>


                                                <v-row class="my-0 py-0 px-2 mt-2"
                                                    v-if="getButtonMessage(msg).length > 0 && msgIndex > 0">
                                                    <v-col cols="auto" v-for="(btn, index) in  getButtonMessage(msg)"
                                                        :key="index" class="pa-1">
                                                        <v-btn variant="outlined" class="btn-rd "
                                                            @click="chatbotButtonClicked(btn)"
                                                            :disabled="chatButtonDisabled(btn)">
                                                            <span class="text-decoration-none text-none t-h6Reg text-black">
                                                                {{ btn.text }}

                                                            </span>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>


                                                <v-row justify="end" class="mr-4 my-0 py-0 mt-2"
                                                    v-if="('aimessage' in msg) && msg.aimessage">
                                                    <v-icon color="primaryGrey" size="22"
                                                        @click="feedbackAIMessage(null, msgIndex)"
                                                        v-if="('feedback' in msg) && (msg.feedback != null && msg.feedback)">
                                                        mdi-thumb-up
                                                    </v-icon>
                                                    <v-icon color="primaryGrey" size="22"
                                                        @click="feedbackAIMessage(true, msgIndex)" v-else>
                                                        mdi-thumb-up-outline
                                                    </v-icon>
                                                    <v-icon color="primaryGrey" class="ml-3" size="22"
                                                        @click="feedbackAIMessage(null, msgIndex)"
                                                        v-if="('feedback' in msg) && (msg.feedback != null && msg.feedback == false)">
                                                        mdi-thumb-down
                                                    </v-icon>
                                                    <v-icon color="primaryGrey" class="ml-3" size="22"
                                                        @click="feedbackAIMessage(false, msgIndex)" v-else>
                                                        mdi-thumb-down-outline
                                                    </v-icon>
                                                </v-row>
                                            </v-sheet>


                                        </v-col>


                                    </v-row>

                                    <!-- <v-row v-if="msg.type == 'nudge' && msg.responseCard != null" class="my-0">
                        <v-col cols="12" v-for="(attatchment, index) in msg.responseCard.genericAttachments"
                            :key="index">
                            <div v-if="'buttons' in attatchment">
                                <v-row v-for="(btn, index) in attatchment.buttons" :key="index">
                                    <v-sheet class=" py-2 px-3 text-white">
                                        <v-btn variant="outlined" color="primaryPurple"
                                            class="btn-rd text-decoration-none text-none t-h6Reg"
                                            @click="chatbotButtonClicked(btn)"
                                            :disabled="chatButtonDisabled(btn)">
                                            <span class="text-decoration-none text-none t-h6Reg">
                                                {{ btn.text }}

                                            </span>
                                        </v-btn> </v-sheet>

                                </v-row>
                            </div>
                        </v-col>
                    </v-row> -->
                                    <v-row v-if="msg.type == 'user'" class="">
                                        <v-col cols="auto" class="">
                                            <v-sheet class="user-msg py-2 px-3">
                                                <v-row class="my-0 py-0">
                                                    <v-col cols="auto " class="my-0 py-0 mr-0 pr-0">
                                                        <v-avatar size="30" color="primaryGreen ">
                                                            {{ getInitials(userName) }}
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col class="my-0 py-0 chatbotText  ">
                                                        <div v-html="getMarkDownHtmlForQestionText(msg.message)">

                                                        </div>

                                                    </v-col>
                                                </v-row>
                                            </v-sheet>

                                        </v-col>
                                    </v-row>

                                    <v-row v-if="msg.type == 'ai'" class="" align="center">
                                        <v-col cols="auto" class="">
                                            <v-sheet class="bot-msg py-2 px-3">
                                                <v-row class="my-0 py-0">
                                                    <v-col cols="auto " class="my-0 py-0 mr-0 pr-0">
                                                        <v-avatar size="30">
                                                            <v-img src="@/assets/mentochat.png"></v-img>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col class="my-0 py-0 chatbotText  ">
                                                        <div v-html="getMarkDownHtml(msg.message)" style="white-space: pre-line; text-overflow: clip;">

                                                        </div>

                                                    </v-col>
                                                </v-row>
                                                
                                            </v-sheet>
                                        </v-col>
                                    </v-row>

                                </v-sheet>

                                <v-row v-if="botTyping" class=" mt-2" align="center">

                                    <v-col cols="auto" class="">
                                        <v-sheet class="user-msg py-2 px-3">
                                            <v-row class="my-0 py-0">
                                                <v-col cols="auto " class="my-0 py-0 mr-0 pr-0">
                                                    <v-avatar size="30">
                                                        <v-img src="@/assets/mentochat.png"></v-img>

                                                    </v-avatar>
                                                </v-col>
                                                <v-col class="my-0 py-0">
                                                    <ChatBotTyping></ChatBotTyping>


                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-col>



                                </v-row>

                                <v-row v-if="streaming" class="mt-2" align="center">
                                    <v-col cols="auto">
                                        <v-sheet class="user-msg py-2 px-3">
                                            <v-row class="my-0 py-0">
                                                <v-col cols="auto " class="my-0 py-0 mr-0 pr-0">
                                                    <v-avatar size="30">
                                                        <v-img src="@/assets/mentochat.png"></v-img>
                                                    </v-avatar>
                                                </v-col>
                                                <v-col class="t-h6Reg text-lh chatbotText">
                                                    <div v-html="getMarkDownHtml(streamingContent)" style="white-space: pre-line; text-overflow: clip;"> </div>
                                                    <span ref="lastIcon">
                                                        <v-icon class="ic" size="17" color="primaryPurple">mdi-circle</v-icon>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-sheet>

                    </v-sheet>

                    <!-- <v-row justify="center" align="end" class="ma-0 pa-0">

                <v-col cols="12" class="ma-0 pa-0">

                    <v-sheet class="chatbot-bottom px-4 py-3 pt-4">
                        <v-row align="center">

                            <v-col class="mr-2" cols="">
                                <v-row align="center">
                                    <v-textarea v-model="chatbotInputText"
                                        placeholder="Type your message here   " hide-details autofocus
                                        density="compact" auto-grow variant="solo" bg-color="primaryPurple"
                                        color="white" flat base-color="primaryPurple" rows="1" row-height="15"
                                        class="text-white custom-label-color custom-placeholer-color"
                                        @keydown.enter.exact.prevent="chatbotSendHandler"
                                        @keydown.enter.shift.exact.prevent="chatbotInputText += '\n'"></v-textarea>
                                </v-row>
                            </v-col>
                            <v-col cols="auto" class="">
                                <v-icon color="white" @click="chatbotSendHandler" :class="{
                                    'disabledOpacity chatbotsendbuttondisabled': chatbotInputText.trim().replace(' ', '').length == 0
                                }">
                                    mdi-send-variant-outline
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>

            </v-row> -->

                    <v-row justify="center" class="mb-2 px-4 pt-2">

                        <v-col cols="12" class="">


                            <v-textarea v-model="chatbotInputText" placeholder="Ask. Type. Learn. We're here to assist!"
                                hide-details autofocus density="compact" auto-grow variant="outlined" rows="1"
                                class="  custom-placeholer-color practiceField" row-height="0px" color="#5c5f624d"
                                base-color="#5c5f624d" @keydown.enter.exact.prevent="chatbotSendHandler"
                                @keydown.enter.shift.exact.prevent="chatbotInputText += '\n'">
                                <template v-slot:append-inner>
                                    <v-icon color="#6B6B6B" @click="chatbotSendHandler" :class="{
                                        'disabledOpacity chatbotsendbuttondisabled': chatbotInputText.trim().replace(' ', '').length == 0 || botTyping
                                    }" icon="mdi-send-variant-outline"></v-icon>
                                </template>
                            </v-textarea>


                        </v-col>
                        <v-col cols="12">
								<v-row justify="center" class="t-subtitle text-secondaryTextColor">
									<v-divider class="mb-1">

									</v-divider>
									DO NOT share any personal data.
								</v-row>
							</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </Transition>
    </div>
    <div class="chatbot" v-if="config.practiceChatBotEnabled && showchatbot">
        <!-- <v-card class="px-8 py-10 " v-if="chatbot">
    <v-card-text>
        <v-row justify="end">
            <v-icon @click="chatbot = false">
                mdi-close
            </v-icon>
        </v-row>
        <v-row>
            hello
        </v-row>
        <v-row class="my-10">
            hi
        </v-row>
    </v-card-text>
</v-card> -->
        <Transition name="slide-fade" v-if="showIcon">

            <v-avatar size="40" @click="openChatbot" v-if="!chatbot">
                <v-img src="@/assets/mentochat.png"></v-img>

            </v-avatar>
        </Transition>

    </div>
</template>
    
<script>
import { mapState } from "vuex";
import _ from "lodash";
import ChatBotTyping from "@/components/practice/ChatBotTyping.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

import { securePostAPI, securePostAPIWithoutOverlay } from "@/services/apiService";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { getHeaders } from "@/services/authService";
import * as htmlToImage from "html-to-image";
import { uploadFiles, dataURLtoFile } from "@/services/storageService";
import {
  getCourseId,
    getLoggedInUserCurrentDomain,
    getLoggedInUserId,
    getLoggedInUserOrganization,
    getLoggedInUserName
} from "@/services/authService";
import axios from "axios";
import { nextTick } from "vue";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
    name: 'ChatBotComponent',
    emits: ['closeChatbot'],
    setup() {

    },
    props: {
        testId: String,
        testInstanceId: String,
        showIcon: {
            type: Boolean,
            default: true
        },
        chatboxClass: {
            type: String,
            default: ''
        },
        screenshotElement: {
            type: Object,
            required: true,
            default: () => ({})
        },
        currentQResponseProp:{
            type: Object,
			default: () => ({})
        },
        updateCrossedOptionsBot: Function

    },
    unmounted() {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('resize', this.getButtonsHeight);

    },
    mounted() {
        this.$nextTick(() => {
            
            window.addEventListener('resize', this.onResize);
            window.addEventListener('resize', this.getButtonsHeight);
        })
    },
    updated() {
        this.getButtonsHeight();
    },

    async created() {
        this.onResize()

        this.getButtonsHeight();
        if (window.document.getSelection) {
            document.addEventListener('selectionchange', (e) => {
                this.annotatedText = window.getSelection().toString().trim().replace(" ", "")

            });
        }
    },
    components: {
        ChatBotTyping

    },
    data: () => ({
        dataReady: false,
        annotateDialog: false,
        tokenInfoDialog: false,
        botTyping: false,
        chatbot: false,
        aiTokensUsed: null,
        chatbotInputText: "",
        currentMessagesList: [],
        chatbotMode: "",
        controller: new AbortController(),
        chatbotheight: null,
        annotatedText: "",
        currentQ: null,
        windowHeight: (window.innerHeight) * 80 / 100,

        aiTokensAllowed: 0,
        limitReachedDialog: false,
        limitReachedDialogMsg: "",
        currentMode: "",
        conversation: [],
        disableChatbot: false,
        showchatbot: true,
        aiTokensUsedForBarColor: {
            green: 'green',
            orange: 'orange',
            red: 'red'
        },
        imgExists: false,
        buttonsheight: 50,
        currentImagePath: "",
        chatbotInput: {},
        aiToolId: "",
        streaming: false,
        streamingContent: ""
    }),
    watch: {
        async streamingContent(newValue, oldValue) {
            await nextTick();
            window.MathJax.typesetPromise();
        }
    },
    methods: {
        hideChatbot() {
            this.showchatbot = false
        },
        showChatbot() {
            console.log("show chatbot");
            this.showchatbot = true
        },
        feedbackAIMessage(val, index) {
            let previousVal = this.currentMessagesList[index].feedback ?? null
            this.currentMessagesList[index].feedback = val
            securePostAPI({
                feedback: val,
                messageId: this.currentMessagesList[index].messageId
            }, `mentoapp/mentopracticenudge/feedback`, false).then((res) => {
                console.log("feedback", res);
                if ("error" in res) {
                    this.currentMessagesList[index].feedback = previousVal
                }
            })
        },
        onResize() {
            // console.log("resize");
            // console.log("height", ((window.innerHeight) * (45 / 100)) / window.devicePixelRatio);
            this.chatbotheight = ((window.innerHeight) * (47 / 100)) / window.devicePixelRatio
        },
        async getNudgeInfoForCurrentQuestion(currentMode, chatbotMode) {

            if (!this.config.practiceChatBotEnabled) {
                return
            }
            this.chatbotMode = chatbotMode
            this.botTyping = true
            this.currentMode = currentMode
            this.conversation = []
            this.imgExists = false
            console.log("currentQuestion", this.currentQ);
            let q = _.cloneDeep(this.currentQ)
            q.questionTypeId = this.currentQ.questionTypeId || this.currentQ.qType
            q.questionDesc = this.currentQ.questionDesc || this.currentQ.qText
            let headers = getHeaders()
            let msg = await securePostAPI({
                qType: this.currentQ.questionTypeId || this.currentQ.qType,
                mentoHelpMode: currentMode,
                subjectId: this.currentQ.subjectId,
                question: q,
                getNudge: true,
                getTokens: this.aiTokensUsed == null ? true : false,
                checkImage: true
            }, `mentoapp/mentopracticenudge/organization/${headers.organizationid}/user/${headers.userid}`, true)

            this.imgExists = msg.imgExists
            if ('limitReachedDialogMsg' in msg) {
                this.limitReachedDialogMsg = msg.limitReachedDialogMsg
            }
            if ('aiTokensUsed' in msg) {
                this.aiTokensUsed = msg.aiTokensUsed
            }
            if ('aiTokensAllowed' in msg) {
                this.aiTokensAllowed = msg.aiTokensAllowed
            }
            console.log("play");
            console.log(msg);
            this.currentMessagesList = msg.messages
            this.chatbotInput = {}
            // this.currentMessagesList.push({
            // 	message: "Option c is incorrect because it states that critically endangered birds only make up 10 percent of the IUCN Red List in the Philippines. However, the text does not provide any information about the proportion of critically endangered birds in the Philippines compared to the overall IUCN Red List. Therefore, option c cannot be supported by the data from the graph."
            // 	, type: "nudge", messageId: uuidv4()
            // })
            // this.currentMessagesList.push({
            // 	message: "I want a hint"
            // 	, type: "user", messageId: uuidv4()
            // })

            this.botTyping = false

        },
        setCurrentQuestion(currentQ) {
            console.log(this.controller);
            this.controller.abort()
            this.currentQ = currentQ
        },
        async chatbotButtonClicked(info) {
            this.controller = new AbortController()
            console.log('Info => ',info);
            if (this.disableChatbot) {
                this.limitReachedDialog = true
                return
            }
            if(this.isDevEnvironment && this.currentQ.subjectType == "math"){
                await this.chatbotButtonClickedV2(info);
                return;
            }
            this.scrollChatBotToBottom()
            this.getButtonsHeight()
            if (info.tag != 'customText') {
                let annotatedPrompt = ""
                if (info.tag == 'annotate') {

                    if (this.annotate().replace(' ', '').length == 0) {
                        this.annotateDialog = true
                        return
                    }

                    annotatedPrompt = info.text + ` : <b>'${this.annotate()}'</b>`
                    this.currentMessagesList.push({ message: annotatedPrompt, type: "user", messageId: uuidv4() })

                }
                else {
                    this.currentMessagesList.push({ message: info.text, type: "user", messageId: uuidv4() })
                }

                recordAnalyticsEvent(
                    AnalyticsEvents.v2ChatbotButtonClicked, {
                    testId: this.testId,
                    testInstanceId: this.testInstanceId,
                    qId: this.currentQ.qId,
                    mode: this.chatbotMode,
                    tag: info.tag ?? '',
                    buttonLabel: info.text,
                    buttonType: info.type == "CUSTOM_ACTION_TAG" ? "ai" : "nudge",
                    userMessage: info.tag == "annotate" ? annotatedPrompt : info.text,
                    annotatedWord: this.annotate(),
                });
            }
            else {
                recordAnalyticsEvent(
                    AnalyticsEvents.v2ChatbotCustomMessage, {
                    testId: this.testId,
                    testInstanceId: this.testInstanceId,
                    qId: this.currentQ.qId,
                    mode: this.chatbotMode,
                    userMessage: info.userPrompt ?? ''
                });
            }
            this.botTyping = true
            let userPrompt = info.userPrompt ?? ''
            if (info.tag == "optionExplanation") {
                userPrompt = info.value
            }


            console.log("currentQuestion", this.currentQ);
            let q = _.cloneDeep(this.currentQ)
            q.questionTypeId = this.currentQ.questionTypeId || this.currentQ.qType
            q.questionDesc = this.currentQ.questionDesc || this.currentQ.qText

            let res = await securePostAPIWithoutOverlay({
                question: q, tag: info.tag, session: {
                    conversation: this.conversation,
                },
                userPrompt: info.tag == 'annotate' ? this.annotate() : userPrompt,
                testId: this.testId,
                testInstanceId: this.testInstanceId,
                userNudgeText: info.text,
                systemTag: this.currentMode,
                subjectId: this.currentQ.subjectId,
                imgExists: this.imgExists
            }, info.handlerUrl, this.controller.signal)
            console.log(res);

            if (!("error" in res)) {

                this.currentMessagesList.push(...res.messages)
                console.log(this.currentMessagesList);


                if ("aiTokensUsed" in res) {
                    this.aiTokensUsed = res.aiTokensUsed
                }

                if (this.aiTokensUsed >= this.aiTokensAllowed) {
                    this.limitReachedDialog = true
                    this.disableChatbot = true
                }
                // this.limitReachedDialog = true
                // this.disableChatbot = true

                if (res.type == "aiframework") {
                    this.conversation = res.conversation
                }
                // this.notificationSound.play()

            }

            if (info.tag == 'crossout') {
                console.log(res)
                this.updateCrossedOptionsBot(res);
                // this.currentQResponse.crossedOutOptions = true

                // this.updateCrossedOptions(res.aiCrossedOutOption, true)
            }
            this.botTyping = false
            this.scrollChatBotToBottom()
            this.getButtonsHeight()

        },
        async chatbotButtonClickedV2(info){
            try{
                this.aiToolId = "mathpracticechatbot"
                if(info.tag !== 'customText'){
                    this.currentMessagesList.push({ message: info.text, type: "user", messageId: uuidv4() });
                }else{
                    info.tag = info.tag + "_" + this.currentMode;
                }
                this.scrollChatBotToBottom()
                this.getButtonsHeight()
                
                if(this.chatbotInput.sessionId){
                    this.botTyping = true;
                    this.currentImagePath = "";
                }
                else{
                    const images = this.screenshotElement.querySelectorAll("img");

                    for (let i = 0; i < images.length; i++) {
                        const image = images[i];
                        const imageUrl = image.src + "?ver=" + this.$store.state.appVersion;
                        if (imageUrl.includes("base64")) {
                            continue;
                        }
                        console.log("imgg", imageUrl);
                        const base64Url = await this.convertImageToBase64(imageUrl);
                        if (base64Url) {
                            image.src = base64Url;
                            console.log("base64", base64Url);
                        }
                        // image.src = image.src + "?test1";
                    }
                    let capturedImageUrl = await htmlToImage.toPng(this.screenshotElement, {
                        pixelRatio: 1,
                    });

                    console.log("Captured Image", capturedImageUrl);
                    
                    this.botTyping = true;
                    if(capturedImageUrl){
                        let file = await  dataURLtoFile(capturedImageUrl, "snapAndSolveMath"+ this.currentQ.testId +"_"+this.currentQ.qId +".png");
                        console.log("Converted File", file);
                        if (file) {
                            let storageImage = await uploadFiles(file, "snapAndSolve");
                            
                            console.log(storageImage);
                            this.currentImagePath = storageImage.key
                        }
                    }
                }
                
                if(info.tag.includes('customText') && this.chatbotInput.sessionId){
                    console.log(this.currentMessagesList)
                    this.chatbotInput = {
                        ...this.chatbotInput,
                        newSession: false,
                        aiToolId: this.aiToolId,
                        domainId: getLoggedInUserCurrentDomain(),
                        userId: getLoggedInUserId(),
                        organizationId: getLoggedInUserOrganization(),
                        username: getLoggedInUserName(),
                        //s3ImagePath: this.currentImagePath,
                        customerId: getCourseId(),
                        userTextInput: info.userPrompt ?? "",
                        tag: info.tag
                    };
                }else if(info.tag.includes('customText') && !this.chatbotInput.sessionId){
                    this.chatbotInput = {
                    newSession: true,
                    aiToolId: this.aiToolId,
                    domainId: getLoggedInUserCurrentDomain(),
                    sessionId: uuidv4(),
                    userId: getLoggedInUserId(),
                    organizationId: getLoggedInUserOrganization(),
                    username: getLoggedInUserName(),
                    s3ImagePath: this.currentImagePath,
                    customerId: getCourseId(),
                    userTextInput: info.userPrompt ?? "",
                    tag: info.tag
                    }
                }
                else if(!info.tag.includes('customText') && this.chatbotInput.sessionId){
                    this.chatbotInput = {
                        ...this.chatbotInput,
                        newSession: false,
                        aiToolId: this.aiToolId,
                        domainId: getLoggedInUserCurrentDomain(),
                        userId: getLoggedInUserId(),
                        organizationId: getLoggedInUserOrganization(),
                        username: getLoggedInUserName(),
                        s3ImagePath: "",
                        customerId: getCourseId(),
                        userTextInput: "",
                        tag: info.tag
                    };
                }
                else{
                    this.chatbotInput = {
                    newSession: true,
                    aiToolId: this.aiToolId,
                    domainId: getLoggedInUserCurrentDomain(),
                    sessionId: uuidv4(),
                    userId: getLoggedInUserId(),
                    organizationId: getLoggedInUserOrganization(),
                    username: getLoggedInUserName(),
                    s3ImagePath: this.currentImagePath,
                    customerId: getCourseId(),
                    userTextInput: "",
                    tag: info.tag
                };
                }

                const response = await axios.post(
                    "https://aifunctions.devtest.thestudypod.com/mentoaichatbot",
                    this.chatbotInput,
                    {
                        responseType: "stream",
                        adapter: "fetch"
                    }
                ).catch(err => {
                    this.messages.push({
                    rawResponse: "Something went wrong, Please try again.",
                    type: "error"
                    });
                    this.showGenerate = false;
                    this.enableClearResponseButton = true;
                });
                console.log(response);
                const stream = response.data;
                this.streaming = true;
                this.botTyping = false;

                for await (const chunk of stream) {
                    if (chunk) {
                        const textChunk = new TextDecoder().decode(chunk);
                        // console.log(textChunk);
                        const regex = /<chunk>(.*?)<\/chunk>/gs;
                        const matches = textChunk.matchAll(regex);
                        // console.log(matches);
                        const jsonChunks = [];
                        const matchArray = Array.from(matches);
                        if (matchArray.length == 0) {

                            // if (!this.userScroll) {
                            //     $(".main-body")
                            //         .stop()
                            //         .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 0);
                            // }
                        } else {
                            for (const match of matchArray) {
                                let jsonData = JSON.parse(match[1]);

                                if (
                                    "aiError" in jsonData &&
                                    jsonData.aiError != null &&
                                    jsonData.aiError.length > 0
                                ) {
                                    if (jsonData.aiError == "token_limit_exceeded") {
                                        this.limitReachedDialogMsg =
                                            jsonData.limitReachedDialogMsg ??
                                            "Daily limit has reached. You can try tomorrow";
                                        this.limitReachedDialog = true;
                                    } else {
                                        this.limitReachedDialogMsg = jsonData.aiError;
                                        this.aiErrorDialog = true;
                                    }

                                    // this.showStreamingPanel = [];
                                    this.streaming = false;

                                    return;
                                }

                                jsonChunks.push(JSON.parse(match[1]));
                                let msg = this.createMessage(JSON.parse(match[1]));
                                // this.messages.push(msg);

                                if (msg.isLast && msg.totalTokens) {
                                    console.log("Updating tokens", msg.totalTokens);
                                    this.aiTokensUsed = msg.totalTokens;
                                }

                                if (this.aiTokensUsed >= this.aiTokensAllowed) {
                                    this.limitReachedDialog = true
                                    this.disableChatbot = true
                                }

                                this.streamingContent += msg.rawResponse;
                                
                                
                                // let max = 10;
                                // this.showStreamingPanel.push(Math.floor(Math.random() * max));
                                // let id = "content-container";
                                
                                setTimeout(() => {
                                    const lastIcon = this.$refs.lastIcon;
                                    if (lastIcon) {
                                        lastIcon.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }, 10);
                            }
                        }
                    }
                
                }
                this.streaming = false;
                this.currentMessagesList.push({ message: this.streamingContent, type: "ai", messageId: uuidv4() });
                this.streamingContent = "";
                setTimeout(() => {
                    window.MathJax.typesetPromise();
                }, 0);
                this.scrollChatBotToBottom()
                this.getButtonsHeight()
            }catch(err){
                console.log("Error while taking screenshot", err);
                this.botTyping = false;
                this.streaming = false;
                this.streamingContent = "";
                this.currentMessagesList.push({ message: "Something went wrong, Please try again!", type: "ai", messageId: uuidv4() });
                return;
            }
            return;
        },
        createMessage(msg) {
            let message = {};
            //type - error, question, user
            if (msg.type == "ai") {
                if(msg.isLast && msg.totalTokens){
                    message.rawResponse = msg.rawResponse;
                    message.isLast = true;
                    message.totalTokens = msg.totalTokens;
                    message.type = "aiConversation";
                } 
                else if (msg.error != null) {
                    message.rawResponse = msg.rawResponse;
                    message.type = "error";
                }
                else {
                    message.rawResponse = msg.rawResponse;
                    message.type = "aiConversation";
                }
            }
            if (msg.type == "user") {
                message.content = msg.content;
                message.type = "user";
            }
            return message;
        },
        async convertImageToBase64(imageUrl) {
            try {
                const response = await axios.get(imageUrl, {
                responseType: "arraybuffer",
                });
                console.log("imggg", response);
                const arrayBuffer = response.data;
                const byteString = Array.from(new Uint8Array(arrayBuffer))
                .map((x) => String.fromCharCode(x))
                .join("");
                const base64 = btoa(byteString);
                console.log(base64);
                const mimeType = response.headers["content-type"];
                let event = {
                testInstanceId: this.testInstanceId,
                questionId: this.questionId,
                successfullyConverted: true,
                };
                recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);
                return `data:${mimeType};base64,${base64}`;
            } catch (error) {
                console.error("Error fetching image:", imageUrl, error);
                this.$store.commit("resetLoader");
                this.errorDialog = true;
                let event = {
                testInstanceId: this.testInstanceId,
                questionId: this.questionId,
                successfullyConverted: false,
                errorMessage: error,
                };
                recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);

                return null; // Or set a default image on error
            }
        },
        getMarkDownHtmlForQestionText(markDownText) {
            let out = false;
            // console.log('markDownText 1: ' + markDownText);
            const regex = /\\/gm;
            const escapedString = markDownText.replace(regex, "\\\\");

            out = marked.parseInline(escapedString, { renderer });
            // out = unescape(marked(escape(markDownText), { renderer }));
            // console.log('markDownText 2: ' + out);
            /* eslint-disable prefer-template */
            // return '(' + optionKey + ') ' + out;
            return out;
        },
        getMarkDownHtml(markdown) {
            console.log("md");

            try {
                let out = markdown
                const regex = /\\/gm;
                const escapedString = markdown.replace(regex, "\\\\");

                out = marked.parse(escapedString, {
                    "async": false,
                    "breaks": false,
                    "extensions": null,
                    "gfm": true,
                    "hooks": null,
                    "pedantic": false,
                    "silent": false,
                    "tokenizer": null,
                    "walkTokens": null
                });

                out = out.replace(/\\\\+/g, '\\');
                out = out.replace(/\\n/g, '<br>');

                return out;
            } catch (error) {
                return ""
            }

        },
        getButtonMessage(currentMessage) {
            if (this.currentMessagesList.length > 0) {
                let firstMessage = {}
                if (currentMessage == null) {
                    firstMessage = this.currentMessagesList[0]
                }
                else {
                    firstMessage = currentMessage
                }


                console.log(firstMessage);
                if (firstMessage.type == 'nudge' && "responseCard" in firstMessage
                    && firstMessage.responseCard != null
                    && "genericAttachments" in firstMessage.responseCard) {
                    console.log(firstMessage.responseCard.genericAttachments[0].buttons);
                    return firstMessage.responseCard.genericAttachments[0].buttons
                }
            }

            return []
            // currentMessagesList[0].type == 'nudge' && currentMessagesList[0].responseCard != null
        },
        annotate() {
            if (window.document.getSelection) {
                let selected = window.document.getSelection().toString()
                return selected
            }
            return ''
        },
        chatButtonDisabled(info) {
            if (this.botTyping) {
                return true
            }
            if (info.tag == 'crossout' && !_.isEmpty(this.currentQResponseProp)) {
                console.log("this.currentQResponseProp", this.currentQResponseProp);
                if (this.currentQResponseProp.crossedOutOptionsList.length >= 2) {
                    return true
                }

            }
            // // if (info.tag == 'annotate') {
            // // 	if (this.annotatedText.length <= 0) {
            // // 		return true
            // // 	}
            // // }

            return false
        },
        async chatbotSendHandler() {
            if (this.botTyping) {
                return
            }
            if (this.disableChatbot) {
                this.limitReachedDialog = true
                return
            }
            this
            if (this.chatbotInputText.trim().replace(' ', '').length == 0) {
                return ''
            }
            let val = this.chatbotInputText

            this.currentMessagesList.push({ message: val, type: "user", messageId: uuidv4() })
            this.chatbotInputText = ''

            this.chatbotButtonClicked({ tag: "customText", userPrompt: val, text: val, handlerUrl: 'mentoapp/mentopracticenudge/aimessage/customtext' })
        },
        scrollChatBotToBottom() {
            try {
                $(".msg_container_base").stop().animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 1000);

            } catch (error) {
                console.log(error);
            }

        },
        openChatbot() {
            this.chatbot = true
            recordAnalyticsEvent(
                AnalyticsEvents.v2ChatbotOpen, {
                testId: this.testId,
                testInstanceId: this.testInstanceId,
                qId: this.currentQ.qId,
                mode: this.chatbotMode
            });

            setTimeout(() => {
                try {

                    $(".msg_container_base").stop().animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 0);

                } catch (error) {
                    console.log(error);
                }
            }, 1);


        },
        closeChatbot() {
            this.chatbot = false
            recordAnalyticsEvent(
                AnalyticsEvents.v2ChatbotClose, {
                testId: this.testId,
                testInstanceId: this.testInstanceId,
                qId: this.currentQ.qId,
                mode: this.chatbotMode
            });
            this.$emit("closeChatbot");
            this.showchatbot = false
        },

        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials[0];
        },openHeresWHy() {
			window.open("https://mentomind.ai/how-do-large-language-models-power-mento/", '_blank');

		},
        isSameQuestion(question){
            return (this.currentQ?.qId == question.qId);
        },
        getButtonsHeight(){
            try {
                var elementOuterHeightWithMargin = $('#dynamic-btns').outerHeight(true);
                this.buttonsheight = elementOuterHeightWithMargin?elementOuterHeightWithMargin:50;
            } catch (error) {
                console.log(error);
            }
        }
    },
    computed: {
        ...mapState(["config"]),
        userName: {
            get() {
                return this.$store.state.userState.name
            }
        },

        aiTokensUsedForBar: {
            get() {
                return (this.aiTokensUsed / this.aiTokensAllowed) * 100;
            }
        }, aiTokensLeft: {
            get() {
                return this.aiTokensAllowed - this.aiTokensUsed;
            }
        },
        aiTokensUsedForBarColorComputed: {
            get() {
                if (this.aiTokensUsedForBar > 75) {
                    return this.aiTokensUsedForBarColor.red
                }
                else if (this.aiTokensUsedForBar > 50) {
                    return this.aiTokensUsedForBarColor.orange
                }
                else {
                    return this.aiTokensUsedForBarColor.green
                }
            }
        },
        isDevEnvironment: {
            get(){
                return this.config.environment=="dev";
            }
        },
        // chatbotheight:{
        // 	get()
        // 	{
        // 		console.log("heighjt",((window.innerHeight)*(45/100))/window.devicePixelRatio);
        // 		return ((window.innerHeight)*(45/100))/window.devicePixelRatio

        // 	}
        // }
    },

}
</script>


<style scoped>
.v-textarea input::placeholder {
    color: red;
    /* Your desired color */
}


.btn-rd {
    border-radius: 100px !important;
    border: 1.5px solid #966DEC;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12)
}

.bot-msg {
    background: rgba(230, 228, 233, 0.46);
}

.user-msg {
    /* background-color: rgb(var(--v-theme-primary)) !important;
	border-radius: 30px; */

}

.chatbot-card {
    border-radius: 12px;
    border-color: transparent;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.chatbot-bg {
    background-color: white !important;
    overflow-y: auto;
    /* -ms-overflow-style: none; */
    /* IE and Edge */
    /* scrollbar-width: none; */
    /* Firefox */
    overflow-x: hidden;
    scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #D3D3D3;
    scrollbar-width: thin;


}

.chatbot-bg::-webkit-scrollbar {
    /* display: none; */
}

.chatbot-bg::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.chatbot-bg::-webkit-scrollbar-track {
    background-color: #D3D3D3;
}

/* Handle */
.chatbot-bg::-webkit-scrollbar-thumb {
    background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
    cursor: pointer;

}

/* Handle on hover */
.chatbot-bg::-webkit-scrollbar-thumb:hover {
    cursor: pointer;

}

.dround {
    border-radius: 10px !important;
}

.chatbotText {
    color: rgba(0, 0, 0, 0.80);
    font-family: Inter;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    white-space: pre-wrap; /* Allows the text to wrap */
    word-wrap: break-word; /* Breaks long words if necessary */
    overflow-wrap: break-word;
    max-width: 100%;
}

.htmlClassTest {
    font-size: 0.9rem !important;
    white-space: pre-line !important;
    color: black !important;
}
/* Hide scrollbar for IE, Edge and Firefox */


.chatbot {
    position: fixed;
    right: 30px;
    bottom: 10%;
    z-index: 1005;
}

.chatbot-box {
    position: fixed;
    right: 20px;
    bottom: 10%;
    z-index: 1010;

}

.chatbot-header {
    background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
}

.chatbox-main{
    height: calc(100% - 165px) !important;
}

.chatbot-bottom {
    background-color: rgb(var(--v-theme-primaryPurple)) !important;
}

.chatbotsendbuttondisabled {
    pointer-events: none;

}

.dialog-rounded .overlay-scrim {
    border-radius: 10px !important;
}

.noClick {
    pointer-events: none;
}

.w450px {
    width: 450px;
}

.w240px {
    width: 370px;
}

.slide-fade-enter-active {
    transition: all 0.3s linear;
}

.slide-fade-leave-active {
    transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translatey(100px);
    opacity: 0;
}


.mento {
    color: #FEFEFE;
    font-family: Prompt;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    /* 24px */
}

.mind {
    color: #A174FD;
    font-family: Prompt;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 24px */
    letter-spacing: 0.48px;
}





.disabledOpacity {
    opacity: var(--v-disabled-opacity) !important;
}
</style>
<style>
.inputText {
    color: black !important;

    /* 114.286% */
}



.practiceField .v-field__outline__start {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    opacity: 1 !important;
}

.practiceField .practiceField .v-field__outline__end {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    opacity: 1 !important;

}
.mento-sup {
	color: #FEFEFE;
	font-family: Prompt;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	/* 24px */
}


.practiceField .v-field__append-inner>.v-icon {
    opacity: 1;
}

.practiceField .v-field__input {
    color: rgba(92, 95, 98, 1) !important;
}

.v-text-field input::placeholder {
    color: green !important;
}

.dialog-rounded .v-overlay__scrim {
    border-radius: 10px !important;
}
</style>