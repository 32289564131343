<template>
    <!-- Overlay for Total Tokens-->
    <v-overlay style="backdrop-filter: blur(12.5px)" persistent v-model="limitReachedDialog"
        class="align-center justify-center px-2 mx-auto">
        <v-card elevation="0" class="decision-box d-flex justify-center align-center pa-2 flex-column"
            :width="smAndUp ? 350 : '95%'" height="100%">
            <v-card-text>
                <v-row justify="center" align="center">
                    <v-col class="decision-box-img-col" cols="12">
                        <v-img class="mx-auto" aspect-ratio="1" width="70" src="@/assets/token.png"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end align-center mb-0 pb-0">
                        <v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-0" density="compact"
                            variant="text" color="primaryTextColor" @click="limitReachedDialog = false">
                        </v-btn>
                    </v-col>
                    <v-col cols="12" color="primaryTextColor" class="decision-box-title text-center pb-0">
                        Daily Limit Reached
                    </v-col>
                    <v-col cols="12" class="decision-box-subtitle text-center">
                        {{ limitReachedDialogMsg }}
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear height="10" bg-color="grey" model-value="100%" rounded
                            color="red"></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-overlay>

    <v-dialog style="backdrop-filter: blur(12.5px)" v-model="aiErrorDialog" width="fit-content">
        <v-card elevation="0" class="assignment-form text-white" max-width="400px">
            <v-card-text class="text-white">
                <v-row dense class="t-1remReg mb-1 mx-1" justify="center">
                    <v-col class="text-center"> Mento AI Hub Error </v-col>
                    <v-col class="text-right" cols="auto">
                        <v-icon @click.stop="aiErrorDialog = false" size="20">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <v-row dense class="t-subtitle text-center mx-2 mb-3 mx-sm-auto" justify="center">
                    {{ limitReachedDialogMsg }}
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-layout v-if="dataReady" class="mb-0 pb-0 layoutLarge d-flex flex-column">
        <!-- breadcrumb -->
        <v-row class="ma-0 pt-4 px-4 breadcrumb-row" align="center" justify="space-between"
            :class="{ heightBread: $vuetify.display.mdAndUp }">
            <v-col class="ma-0 pa-0">
                <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
                    <template v-slot:divider>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </template>
                    <template v-slot:prepend>
                        <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
                            size="x-small" @click="back()" icon="mdi-chevron-left">
                        </v-btn>
                    </template>
                    <template v-slot:title="{ item }">
                        <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{
                            item.title
                            }}</span>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="auto">
                <MentoAITokensComponent />
            </v-col>
            <v-divider></v-divider>
        </v-row>

        <v-container fluid class="py-5 ma-0 pa-0 main-body focusable-div" v-scroll.self="onScroll"
            @scroll="handleScroll2" @wheel.passive="handleScroll" v-touch="{ down: () => handleSwipe() }"
            @keydown.up="handleKeyDown" tabIndex="0" :class="{ 'main-body-scroll': smAndUp }">
            <v-container fluid class="container-width py-0">
                <v-row align="center" justify="center" dense>
                    <v-col cols="12">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" class="pb-0 px-0">
                                <span class="t-h125 text-center mb-1 text-primaryTextColor">Solve Question From
                                    Image</span>
                            </v-col>
                            <v-col cols="12" class="px-0">
                                <span class="t-1remReg text-center mb-1 test-subtitle">Upload a Question Image and Get
                                    Step-by-Step
                                    Solution</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center" dense>
                    <v-col cols="">
                        <v-theme-provider theme="textfieldtheme">
                            <v-form ref="form">
                                <v-card class="assignment-card px-6 py-4 assignment-card fill-height card-border ">
                                    <v-card-text class="text-white">
                                        <v-row>
                                            <v-col cols="12" sm="auto" class="mr-sm-3 pl-0">
                                                <v-row dense>
                                                    <v-avatar size="40" color="transparent">
                                                        <v-img height="40" :src="require('@/assets/epq.svg')">
                                                        </v-img>
                                                    </v-avatar>
                                                </v-row>
                                            </v-col>

                                            <v-col cols>
                                                <v-row class="t-1remReg text-white"> Upload a Question Image</v-row>
                                                <v-row class="test-subtitle">
                                                    Image should contain a question
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="10" class="mr-n3">
                                                        <v-file-input class="" density="compact" :clearable="true"
                                                            ref="imgInput" v-model="imageFiles"
                                                            accept=".png, .jpeg, .jpg" variant="outlined"
                                                            :rules=fileRules prepend-icon=""
                                                            prepend-inner-icon="mdi-image"></v-file-input>
                                                    </v-col>

                                                </v-row>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="showGenerate" class="text-white mt-8">
                                            <v-col cols="12" class="text-right py-0 pr-0 mr-0">
                                                <v-btn class="t-btn text-white activity-btn text-none" density="default"
                                                    variant="flat" width="150" @click="getSolutionFromImage" :disabled="imageFiles.length <= 0"
                                                    :loading="loadingSolution">
                                                    Solve
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else class="text-white mt-8">
                                            <v-col cols="12" class="text-right py-0 pr-0 mr-0">
                                                <v-btn class="t-btn text-white activity-btn text-none"
                                                    density="default" variant="flat" width="150" @click="clearResponse"
                                                    :disabled="!enableClearResponseButton">
                                                    Reset response
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-form>
                        </v-theme-provider>
                    </v-col>

                    <v-col cols="12">
                        <v-expansion-panels varaint="accordion" multiple v-model="panels">
                            <template v-for="(msg, index) in messages" :key="index">
                                <v-expansion-panel  elevation="0" bg-color="#ffffff"
                                    class="panel-style mt-2" v-if="msg.type == 'user'"
                                    style="border-radius: 12px !important"  hide-actions :id="msg.id">
                                    <template v-slot:title>
                                        <v-row align="center" class="">
                                            <v-col class="t-h6Reg text-lh mr-0 pr-0 text-right">
                                                <div v-html="getMarkDownHtml(msg.content)">

                                                </div>
                                            </v-col>

                                            <v-col align-self="start" cols="auto">
                                                <v-avatar size="40" color="primaryGreen ">
                                                    {{ getInitials(userName) }}
                                                </v-avatar>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-expansion-panel>

                                <v-expansion-panel elevation="0"  bg-color="#ffffff"
                                    class="panel-style mt-2" v-if="msg.type == 'error' || msg.type == 'aiConversation'"
                                    style="border-radius: 12px !important" hide-actions>
                                    <template v-slot:title>
                                        <v-row align="center">
                                            <v-col align-self="start" cols="auto">
                                                <v-avatar size="40">
                                                    <v-img src="@/assets/mentochat.png"></v-img>
                                                </v-avatar>
                                            </v-col>
                                            <v-col class="t-h6Reg text-lh ml-0 pl-0 htmlClassTest">
                                                <div v-html="getMarkDownHtml(msg.rawResponse)"></div>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-expansion-panel>
                            </template>
                        </v-expansion-panels>

                        <section class="pb-4" v-if="streaming">
                            <v-expansion-panels @update:modelValue="panelsUpdate" varaint="accordion" multiple
                                v-model="showStreamingPanel">
                                <v-expansion-panel elevation="0" bg-color="#ffffff" ripple
                                    class="panel-style mt-2" style="border-radius: 12px !important" hide-actions>
                                    <template v-slot:title>
                                        <v-col align-self="start" cols="auto">
                                            <v-avatar size="40">
                                                <v-img src="@/assets/mentochat.png"></v-img>
                                            </v-avatar>
                                        </v-col>
                                        <v-col class="t-h6Reg text-lh ml-0 pl-0 htmlClassTest">
                                            <span v-html="getMarkDownHtml(streamingContent)"> </span>
                                            <span ref="lastIcon">
                                                <v-icon class="ic" size="17" color="primaryPurple">mdi-circle</v-icon>
                                            </span>
                                        </v-col>
                                    </template>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </section>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <v-row v-if="messages.length > 0" color="#35354E" app class="footer-color ma-0 pa-0 w-100 px-0">
        <v-container fluid class="container-width py-0">
          <v-row dense class="footer-card pb-5 pt-1 px-0" justify="center">
            <v-col cols="12">
              
              <v-row dense justify="space-between" align="end">
                <v-col class="mr-2 textArea-col">
                  <v-textarea ref="myTextarea" autofocus flat rows="1" auto-grow variant="solo" density="compact"
                    rounded="lg" hide-details class="aiChatField" :disabled="showActionsRow"
                    @keydown.enter.prevent="sendMessage" v-model="message"
                    placeholder="Please enter here what you want to improve in this or next question." bg-color="white">
                    <template v-slot:append-inner>
                      <v-btn height="32" width="32" color="primaryPurple" @click="sendMessage"
                        variant="flat" :disabled="sendDisabled()" icon="mdi-arrow-up-thin" class="btn-radius">
                      </v-btn>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-layout>
</template>

<script>
import {
    AnalyticsEvents,
    recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import _ from "lodash";
import {
  getCourseId,
    getLoggedInUserCurrentDomain,
    getLoggedInUserId,
    getLoggedInUserOrganization,
} from "@/services/authService";
import $ from "jquery";

import { breadcrumbHandler } from "@/services/breadcrumbHandler";
import { v4 as uuidv4 } from "uuid";
import { getLoggedInUserName } from "@/services/authService";
import MentoAITokensComponent from "@/components/ai/MentoAITokensComponent.vue";
import { uploadFiles } from "@/services/storageService";
import axios from "axios";
import * as pdfjsLib from 'pdfvuer/node_modules/pdfjs-dist';
import { nextTick } from "vue";

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
    name: "PDFQuestions",
    data: () => ({
        imageFiles: [],
        showImageError: false,
        showActionsRow: false,
        imageError: null,
        currentImagePath: null,
        requiredRule: [(v) => !!v || "Field is required"],
        fileRules: [
            value => value.length > 0 || 'At least one file is required.',
            value => value.every(file => file.type.startsWith('image/')) || 'Only Image files are allowed.',
            value => value.every(file => file.size < 10000000) || 'Each file size should be less than 2 MB!',
        ],
        messages: [],
        message: "",
        panels: [],
        loadingSolution: false,
        streaming: false,
        streamingContent: "",
        showStreamingPanel: [],
        toolId: "",
        limitReachedDialog: false,
        initialPrompt: "",
        aiErrorDialog: false,
        limitReachedDialogMsg: "",
        showCollectionSnackBar: false,
        autoScrolling: true,
        selectedQuestions: [],
        showGenerate: true,
        userScroll: false,
        enableClearResponseButton: false,
        prevScrollTop: 0,
    }),
    setup() {
        const {
            xs,
            smAndUp,
            smAndDown,
            mdAndDown,
            mdAndUp,
            lgAndUp,
            lg,
            xl,
            xxl,
        } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async mounted() {
        console.log("mounted");
        this.dataReady = true;
        breadcrumbHandler(this);
    },
    async created() {
        await this.$store.dispatch('getCollectionsList', {});
        this.$store.dispatch("loadEntitiesWithoutLoader");
        this.toolId = this.$route.path.includes("mathSnapAndSolve") ? "mathSnapAndSolve" : "readingAndWritingSnapAndSolve";
    },
    components: {
        MentoAITokensComponent
    },

    computed: {
        ...mapState([
            "newLearnNodes",
            "breadCrumbs",
            "topicsList",
            "config",
            "maxAITokens",
            "totalAITokens",
            "aiPromptsList",
            "isUserTeacher",
            "isUserAdmin",
            "passageTypes",
            "genres",
        ]),
        displayBreadCrumbs: {
            get() {
                let breadCrumbs = this.breadCrumbs;
                // breadCrumbs.pop();
                breadCrumbs[breadCrumbs.length - 1].disabled = true;
                return breadCrumbs;
            },
        },
        userName: {
            get() {
                return this.$store.state.userState.name;
            },
        },
    },
    methods: {
        panelsUpdate(val) {
            console.log("panel update ", val);
            setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 0);
        },
        
        async getSolutionFromImage() {
            this.showImageError = false
            this.imageError = null
            this.messages = [];
            this.loadingSolution = true;
            const { valid } = await this.$refs.form.validate();
            if (!valid) {
                return;
            }
            if (this.maxAITokens <= this.totalAITokens) {
                this.limitReachedDialogMsg = "Daily limit has reached. You can try tomorrow";
                this.showStreamingPanel = false;

                this.limitReachedDialog = true;

                return;
            }
            const reader = new FileReader();
            try {
              reader.onload = async (e) => {
                // this.showGenerate = false;

                //upload image to s3
                await this.uploadToS3();


                this.aiQuestionsInput = {
                    newSession: true,
                    toolId: this.toolId,
                    domainId: getLoggedInUserCurrentDomain(),
                    sessionId: uuidv4(),
                    userId: getLoggedInUserId(),
                    organizationId: getLoggedInUserOrganization(),
                    username: getLoggedInUserName(),
                    s3ImagePath: this.currentImagePath,
                    customerId: getCourseId()
                };
                const response = await axios.post(
                    "https://aifunctions.devtest.thestudypod.com/imagequestions",
                    this.aiQuestionsInput,
                    {
                        responseType: "stream",
                        adapter: "fetch",
                    }
                ).catch(err => {
                  this.messages.push({
                    rawResponse: "Something went wrong, Please try again.",
                    type: "error"
                  });
                  this.showGenerate = false;
                  this.enableClearResponseButton = true;
                });
                console.log(response);
                const decoder = new TextDecoder();
                const stream = response.data;

                this.streaming = true;
                this.showStreamingPanel.push("*");
                this.streamingContent = "";
                this.userScroll = false;

                let url = URL.createObjectURL(this.imageFiles[0]);
                this.streamingContent += `<img class="user-image" src="${url}" alt="Selected Image" /> \n`;
                
                this.userScroll = false;
                for await (const chunk of stream) {
                    
                    if (chunk) {
                        const textChunk = new TextDecoder().decode(chunk);
                        // console.log(textChunk);
                        const regex = /<chunk>(.*?)<\/chunk>/gs;
                        const matches = textChunk.matchAll(regex);
                        // console.log(matches);
                        const jsonChunks = [];
                        const matchArray = Array.from(matches);
                        if (matchArray.length == 0) {

                            if (!this.userScroll) {
                                $(".main-body")
                                    .stop()
                                    .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 0);
                            }
                        } else {
                            for (const match of matchArray) {
                                let jsonData = JSON.parse(match[1]);

                                if (
                                    "aiError" in jsonData &&
                                    jsonData.aiError != null &&
                                    jsonData.aiError.length > 0
                                ) {
                                    if (jsonData.aiError == "token_limit_exceeded") {
                                        this.limitReachedDialogMsg =
                                            jsonData.limitReachedDialogMsg ??
                                            "Daily limit has reached. You can try tomorrow";
                                        this.limitReachedDialog = true;
                                    } else {
                                        this.limitReachedDialogMsg = jsonData.aiError;
                                        this.aiErrorDialog = true;
                                    }

                                    this.showStreamingPanel = [];
                                    this.streaming = false;

                                    return;
                                }

                                jsonChunks.push(JSON.parse(match[1]));
                                let msg = this.createMessage(JSON.parse(match[1]));
                                // this.messages.push(msg);
                                this.streamingContent += msg.rawResponse;
                                
                                
                                let max = 10;
                                this.showStreamingPanel.push(Math.floor(Math.random() * max));
                                let id = "content-container";
                                
                                setTimeout(() => {
                                    const lastIcon = this.$refs.lastIcon;
                                    if (lastIcon) {
                                        lastIcon.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }, 10);
                            }
                        }
                    }
                }
                
                let msg = this.createMessage({ rawResponse: this.streamingContent, type: 'ai' });
                setTimeout(() => {
                    window.MathJax.typesetPromise();
                }, 0);
                this.messages.push(msg);
                console.log("Pushing this message", msg);
                this.$store.commit("aiTokensDataLoadedFromServer", false);
                this.$store.dispatch("getTokens", { maxTokens: false });
                this.streamingContent = "";
                this.showStreamingPanel = [];
                this.streaming = false;
                this.loadingSolution = false;
                this.showGenerate = false;
                this.enableClearResponseButton = true;
               
                this.loadingSolution = false;
                this.enableClearResponseButton = true;
                //   console.log(this.messages);
            };
            } catch(error) {
              this.messages.push({
                rawResponse: "Something went wrong, Please try again.",
                type: "error"
              });
              this.enableClearResponseButton = true;
            }
            reader.readAsArrayBuffer(this.imageFiles[0]);
        },
        async sendMessage(){
            if (this.maxAITokens <= this.totalAITokens) {
                this.limitReachedDialogMsg = "Daily limit has reached. You can try tomorrow";
                this.showStreamingPanel = false;

                this.limitReachedDialog = true;

                return;
            }
          
            try {
                this.messages.push({
                  type: "user",
                  content: this.message
                });
                this.aiQuestionsInput = {
                  ... this.aiQuestionsInput,
                  newSession: false,
                  userInputText: this.message
                };
                this.message = "";
                const response = await axios.post(
                    "https://aifunctions.devtest.thestudypod.com/imagequestions",
                    this.aiQuestionsInput,
                    {
                        responseType: "stream",
                        adapter: "fetch",
                    }
                ).catch(err => {
                  this.messages.push({
                    rawResponse: "Something went wrong, Please try again.",
                    type: "error"
                  });
                  this.enableClearResponseButton = true;
                });
                console.log(response);
                const decoder = new TextDecoder();
                const stream = response.data;

                //   setTimeout(() => {
                //       window.MathJax.typesetPromise();
                //     }, 0);
                this.streaming = true;
                this.showStreamingPanel.push("*");
                this.streamingContent = "";
                this.userScroll = false;

                for await (const chunk of stream) {
                    
                    if (chunk) {
                        const textChunk = new TextDecoder().decode(chunk);
                        // console.log(textChunk);
                        const regex = /<chunk>(.*?)<\/chunk>/gs;
                        const matches = textChunk.matchAll(regex);
                        // console.log(matches);
                        const jsonChunks = [];
                        const matchArray = Array.from(matches);
                        if (matchArray.length == 0) {
                          

                            if (!this.userScroll) {
                                $(".main-body")
                                    .stop()
                                    .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 0);
                            }
                        } else {
                            for (const match of matchArray) {
                                let jsonData = JSON.parse(match[1]);

                                if (
                                    "aiError" in jsonData &&
                                    jsonData.aiError != null &&
                                    jsonData.aiError.length > 0
                                ) {
                                    if (jsonData.aiError == "token_limit_exceeded") {
                                        this.limitReachedDialogMsg =
                                            jsonData.limitReachedDialogMsg ??
                                            "Daily limit has reached. You can try tomorrow";
                                        this.limitReachedDialog = true;
                                    } else {
                                        this.limitReachedDialogMsg = jsonData.aiError;
                                        this.aiErrorDialog = true;
                                    }

                                    this.showStreamingPanel = [];
                                    this.streaming = false;

                                    // show dialog
                                    return;
                                }

                                jsonChunks.push(JSON.parse(match[1]));
                                let msg = this.createMessage(JSON.parse(match[1]));
                                
                                this.streamingContent += msg.rawResponse;
                                
                                let max = 10;
                                this.showStreamingPanel.push(Math.floor(Math.random() * max));
                               
                                let id = "streaming";
                                setTimeout(() => {
                                    const lastIcon = this.$refs.lastIcon;
                                    if (lastIcon) {
                                        lastIcon.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }, 200);

                                // }
                            }
                        }
                    }
                }
                let msg = this.createMessage({ rawResponse: this.streamingContent, type: 'ai' });
                setTimeout(() => {
                    window.MathJax.typesetPromise();
                }, 0);
                this.messages.push(msg);
                console.log("Pushing this message", msg);
                this.$store.commit("aiTokensDataLoadedFromServer", false);
                this.$store.dispatch("getTokens", { maxTokens: false });
                this.streamingContent = "";
                this.showStreamingPanel = [];
                this.streaming = false;
                this.showGenerate = false;
                this.enableClearResponseButton = true;
                this.loadingSolution = false;
                this.enableClearResponseButton = true;
            } catch(error) {
              this.messages.push({
                rawResponse: "Something went wrong, Please try again.",
                type: "error"
              });
              this.enableClearResponseButton = true;
            }
            
        },
        
        async uploadToS3() {
            console.log("loader");
            if (this.imageFiles.length > 0) {
                let storageImage = await uploadFiles(this.imageFiles[0], "snapAndSolve");
                
                console.log(storageImage);
                this.currentImagePath = storageImage.key
            }
        },
        handleScroll(event) {
            // Your scroll handling code here
            if (event.deltaY < 0) {
                this.userScroll = true;
            }
        },
        
        handleScroll2(event) {
            const currentScrollTop = event.target.scrollTop;

            if (currentScrollTop < this.prevScrollTop) {
                this.userScroll = true;
            }
            this.prevScrollTop = currentScrollTop;
        },
        isScrollbarDragging(event) {
            const target = event.target;
            return target.clientHeight + target.scrollTop >= target.scrollHeight;
        },
        handleSwipe() {
            this.userScroll = true;
        },
        onScroll(event) {
            let calcValue = Math.round(event.target.scrollTop + event.target.offsetHeight);
            if (
                Math.abs(calcValue - event.target.scrollHeight) <= 1 &&
                this.userScroll == true
            ) {
                this.userScroll = false;
            }
        },
        handleKeyDown(event) {
            this.userScroll = true;
        },
        
        sendDisabled() {
            return this.loadingSolution || this.message.length == 0 || this.streaming;
        },
        
        breadCrumbRoute(item) {
            this.$router.replace(item.pageRoute);
        },
        back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute;
            this.$router.replace(page);
        },
        
        getMarkDownHtml(markdown) {
            console.log("md");

            try {
                let out = markdown
                const regex = /\\/gm;
                const escapedString = markdown.replace(regex, "\\\\");

                out = marked.parse(escapedString, {
                    "async": false,
                    "breaks": false,
                    "extensions": null,
                    "gfm": true,
                    "hooks": null,
                    "pedantic": false,
                    "silent": false,
                    "tokenizer": null,
                    "walkTokens": null
                });

                out = out.replace(/\\\\+/g, '\\');
                out = out.replace(/\\n/g, '<br>');

                return out;
            } catch (error) {
                return ""
            }

        },
        createMessage(msg) {
            let message = {};
            //type - error, question, user
            if (msg.type == "ai") {
                if (msg.error != null) {
                    message.rawResponse = msg.rawResponse;
                    message.type = "error";
                } else {
                    message.rawResponse = msg.rawResponse;
                    message.type = "aiConversation";
                }
            }
            if (msg.type == "user") {
                message.content = msg.content;
                message.type = "user";
            }
            return message;
        },
        createMessages(msg) {
            let messages = [];
            let message = {};
            //type - error, question, user
            if (msg.type == "ai") {
                if (msg.error != null) {
                    this.messages.push({
                        rawResponse: msg.rawResponse,
                        type: "error",
                    });

                    return;
                } else {
                    if (msg.conversationStart != null) {
                        if (this.messages.length == 0) {
                            this.messages.push({
                                rawResponse: msg.conversationStart,
                                type: "aiConversation",
                            });
                        } else {
                            this.messages[0].rawResponse += msg.conversationStart;
                        }
                        this.streamingContent += msg.conversationStart;
                    }
                    
                    return;
                }
            }
        },
        
        getInitials(fullName) {
            const allNames = fullName.trim().split(" ");
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, "");
            return initials[0];
        },
        openActions() {
            this.showActions = !this.showActions;
            this.selectedQuestions = [];
            if (this.showActions == true) {
                recordAnalyticsEvent(AnalyticsEvents.v2AIToolActionsOpened, {
                    toolId: this.toolId,
                    isOpened: true,
                });
                this.showActionsRow = true;
            } else {
                recordAnalyticsEvent(AnalyticsEvents.v2AIToolActionsOpened, {
                    toolId: this.toolId,
                    isOpened: false,
                });
                this.showActionsRow = false;
            }
        },
        closeActions() {
            this.showActionsRow = false;
            this.showActions = false;
        },
        
        clicked() {
            return;
        },
        clearResponse() {
            this.messages = [];
            this.imageFiles = [];
            this.showGenerate = true;
            this.enableClearResponseButton = false;
            this.message = "";
        },
    },
    watch: {
        async streamingContent(newValue, oldValue) {
            await nextTick();
            window.MathJax.typesetPromise();
        }
    },
};
</script>

<style scoped>
.focusable-div {
    outline: none;
}

.text-clickable {
    cursor: pointer;
}

.breadcrumb-row {
    flex: 0 0 auto;
}

.container-width {
    max-width: 900px;
}

.htmlClassTest {
    font-size: 0.9rem !important;
    white-space: pre-line !important;
    color: black !important;
}

.ic {
    animation: grow 2s ease-in-out infinite alternate;
    font-size: 30px;
}

@keyframes grow {
    0% {
        transform: scale(1);
    }

    /* Initial state */
    50% {
        transform: scale(1.2);
    }

    /* Slightly larger */
    100% {
        transform: scale(1);
    }

    /* Back to original */
}

.aiChatField .v-field__append-inner>.v-icon {
    opacity: 1;
}

.selected {
    border-color: rgb(var(--v-theme-primaryPurple));
}

.assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border-radius: 10px;
    white-space: pre-line !important;
}

.footer-card {
    background-color: #00000000;
}

.click {
    cursor: pointer;
}


.cancel-btn {
    border: 2px solid rgba(255, 255, 255, 0.22);
    color: white !important;
    background: rgb(var(--v-theme-cardBackgroundColor));
    border-radius: 29.4079px;
    /* width: 100px; */
}

.save-btn {
    color: white !important;
    border-radius: 29.4079px;
    background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.heightBread {
    height: 80px;
}

.activity-btn {
    background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
    border-radius: 12px;
}

.btn-radius {
    /* background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%); */
    border-radius: 12px;
}

.cardDisable {
    pointer-events: none;
    opacity: 0.8;
}

/* .layoutLarge {
    overflow-y: hidden !important; */
/* -ms-overflow-style: none; */
/* IE and Edge */
/* scrollbar-width: none; */
/* Firefox
  } */
/* Here */
.layoutLarge {
    overflow-y: hidden !important;
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .layoutLarge {
        height: calc(100vh - 75px);
    }
}

/* .main-body::-webkit-scrollbar {
    display: none;
  } */

.main-body-scroll::-webkit-scrollbar {
    /* display: none; */
    width: 12px;
    border-radius: 12px !important;
    height: 8px !important;
}

/* Track */
.main-body-scroll::-webkit-scrollbar-track {
    background: rgb(var(--v-theme-report-subCardBg));
    border-radius: 12px !important;
    height: 8px !important;
}

/* Handle */
.main-body-scroll::-webkit-scrollbar-thumb {
    /* background: rgb(var(--v-theme-)); */
    background-color: rgba(156, 156, 156, 0.4);

    border-radius: 20px !important;

    cursor: pointer;
    height: 8px !important;
}

.hide-overflow {
    max-height: 100vh;
    overflow: hidden !important;
}

.layout-main {
    height: calc(100vh - 200px) !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
}

.main-lg {
    padding-top: 40px;
    padding-left: 15% !important;
    padding-right: 15% !important;
}

.main-md {
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.text-field-icon {
    cursor: pointer;
}

.dround {
    border-radius: 10px !important;
}

.dialog-rounded .overlay-scrim {
    border-radius: 10px !important;
}

.report-btn {
    background: rgb(var(--v-theme-practice-prompts-background)) !important;
    border: 2px solid rgb(var(--v-theme-primaryPurple));
    letter-spacing: -0.15px;
}

.textArea-col {
    background: white !important;
    border-radius: 12px !important;
}

.decision-box {
    border-radius: 12px;
    border: 3px solid rgb(var(--v-theme-practice-prompts-border));
    background: rgb(var(--v-theme-practice-prompts-background));
    margin: 0 auto;
    overflow: visible !important;
}

.decision-box-img-col {
    position: absolute;
    top: -45px;
}

.decision-box-title {
    color: rgb(var(--v-theme-primaryTextColor));
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 25px */
    letter-spacing: -0.25px;
}

.decision-box-subtitle {
    color: rgb(var(--v-theme-practice-decision-box-subtitle));
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.decision-box-options {
    width: 221px;
    height: 142px;
    border-radius: 12px;
    background: rgb(var(--v-theme-practice-prompts-option-box));
}

.decision-box-options-row {
    min-width: 125px;
}

.decision-box-options-title {
    color: rgb(var(--v-theme-primaryTextColor));
    text-align: center;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.17px;
}
</style>

<style>
.panel-style {
    white-space: pre-line !important;
}

.panel-shadow {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

.panel-style .v-expansion-panel-title__overlay {
    display: none !important;
}

.panel-style .v-expansion-panel-text {
    /* background-color: #e0e1e4 !important; */
    background-color: white;
    font-size: 0.9rem;
    border-bottom-right-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
}

/* .custom-panel>.v-expansion-panel-text {
      background-color: rgb(var(--v-theme-surface)) !important;
      color: black !important;
      border-bottom-right-radius: inherit !important;
      border-bottom-left-radius: inherit !important;
  }
  
  .testOption .v-label {
  
      width: 100% !important;
      opacity: 1 !important;
  }
  } */
.testOption .v-label {
    width: 100% !important;
    opacity: 1 !important;
}

.text-lh {
    line-height: 1.5 !important;
}

.footer-color {
    /* background: linear-gradient(360deg, rgba(53, 53, 78, 1) 84%, rgba(53, 53, 78, 0) 100%) !important;  */
    background: #35354e !important;
    box-shadow: 0 -8px 7px #35354e !important;
    position: sticky;
    flex: 0 0 auto;
    bottom: 0;
    z-index: 200;
    overflow: hidden;
}

.footer-width {
    transition: height 2s ease;
}

.main-body {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto !important;
}

.actionBox-border {
    border-radius: 12px;
    border: 2px solid #55556a;
}

.aiChatField .v-input__control>.v-field--disabled {
    opacity: 0.8 !important;
    /* background: #aaaaaa !important;  */
    color: white !important;
}

.slide-fade-enter-active {
    transition: all 0.3s linear;
}

.slide-fade-leave-active {
    transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translatey(50px);
    opacity: 0;
}

.dialog-rounded .v-overlay__scrim {
    border-radius: 10px !important;
}

.assignment-form {
    /* background: rgb(var(--v-theme-primaryGrey)); */
    border-radius: 12px !important;
    /* border: 2px solid #c3c3c3; */
    border: 2px solid rgba(255, 255, 255, 0.22);
    /* background: #232238; */
    background-color: rgb(var(--v-theme-reportBgColor));
    height: 100%;
}
.user-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
}
.scroll-content{
  overflow-y: auto; /* Enable scrolling when content overflows */
  max-height: 100%; /* Allow expansion to full panel height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 10px;
}
</style>