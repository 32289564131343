import { Analytics } from '@aws-amplify/analytics';
import { Auth } from '@aws-amplify/auth';
import verFlavor from "@/appFlavorAndVersion";
import { getCognitoAuthConfigForAmplify, getPinPointConfig } from "@/flavors";
import { getHeaders, getReferralId, getCourseId, getCourseName } from './authService';
import store from "../store";
import moment from "moment-timezone";

export const AnalyticsEvents = {
  v2AppLoaded: 'v2AppLoaded', //done
  v2AppLoadedFromReferral : 'v2AppLoadedFromReferral',
  v2SignInPageOpen: 'v2SignInPageOpen', //done
  v2SignInAttempt: 'v2SignInAttempt', //done
  v2SignInSuccess: 'v2SignInSuccess', //done
  v2SignInFailure: 'v2SignInFailure', //done
  v2Signout: 'v2Signout', //done
  //Domain
  v2DomainChanged: 'v2DomainChanged', //done
  //Profile
  v2ViewProfile: 'v2ViewProfile',
  //Learn
  v2LearnOpen: 'v2LearnOpen', //done
  v2LearnSearch: 'v2LearnSearch', //done //searchtext
  v2LearnNodeDrilldown: 'v2LearnNodeDrilldown', //done // nodeid and nodename
  v2LearnPlayVideo: 'v2LearnPlayVideo', //done // videoname videoid
  v2LearnOpenPdf: 'v2LearnOpenPdf', //done //pdfname pdfid
  v2LearnDownloadPdf: 'v2LearnDownloadPdf', //done //pdfname pdfid
  v2LearnopenLink: 'v2LearnOpenLink', //done //linkurl
  //MyClasses
  v2MyClassesPageOpen: 'v2MyClassesPageOpen', //done
  v2MyClassesSearch: 'v2MyClassesSearch', //done //searchtext
  v2MyClassesJoinZoomMeeting: 'v2MyClassesJoinZoomMeeting', //done //meetingid
  //MyNotifications
  v2MyNotifPageOpen: 'v2MyNotifPageOpen', //done
  v2MyNotifSearch: 'v2MyNotifSearch', //searchtext
  v2MyNotifViewDetails: 'v2MyNotifViewDetails', //done //notificationid
  v2MyNotifMarkAsStarred: 'v2MyNotifMarkAsStarred', //done //noptificationid
  v2MyNotifUnMarkAsStarred: 'v2MyNotifUnMarkAsStarred',//done //noptificationid
  v2MyNotifMarkAsRead: 'v2MyNotifMarkAsRead', //done //noptificationid
  v2MyNotifMarkAsUnRead: 'v2MyNotifMarkAsUnRead',//done //noptificationid
  //Tests
  v2TestSeriesPageOpen: 'v2TestSeriesPageOpen',
  v2TestSeriesNodeOpen: 'v2TestSeriesNodeOpen', // testseriescollectionid, testseriesname
  v2TestStatusGroupOpen: 'v2TestStatusGroupOpen', // testseriescollectionid, testseriesname, testseriesgroupname (active, attempted, upcoming, missed)
  v2TestListTestDetailOpen: 'v2TestListTestDetailOpen', // testId, testName
  v2TestListStartTest: 'v2TestListStartTest', // testId, testName
  v2TestListResumeTest: 'v2TestListResumeTest', // testId, testName
  v2TestListStartPracticeTest: 'v2TestListStartPracticeTest', // testId, testName

  v2TestBeginTest: 'v2TestBeginTest', // testId, testName
  v2TestResumeTest: 'v2TestResumeTest', // testId, testName
  v2TestBeginPracticeTest: 'v2TestBeginPracticeTest', // testId, testName
  v2TestExitTestFromStartTestScreen: 'v2TestExitTestFromStartTestScreen', // testId, testName

  v2TestExitInProgressTest: 'v2TestExitInProgressTest', // testId, testName
  v2TestSubmitTest: 'v2TestSubmitTest', // testId, testName
  v2TestSectionInstructionsDuringTest: 'v2TestSectionInstructionsDuringTest',// testId, testName
  v2TestInstructionsDuringTest: 'v2TestInstructionsDuringTest', // testId, testName

  v2TestQuestionListPopupOpen: 'v2TestQuestionListPopupOpen',

  v2TestListTestReportsOpen: 'v2TestListTestReportsOpen',
  v2TestInstanceViewSolutionsOpen: 'v2TestInstanceViewSolutionsOpen',

  //TestErrorPinpointAnylitics
  v2TestUserAttemptMethodInStoreFailed: 'v2TestUserAttemptMethodInStoreFailed',
  v2TestUserAttemptMethodInTestQuestionPageFailed: 'v2TestUserAttemptMethodInTestQuestionPageFailed',
  v2TestRecordErrorAPIFailed: 'v2TestRecordErrorAPIFailed',
  
  //LocalForage API fail analytics for test

  v2ErrorSetInLocalForageFailed: 'v2ErrorSetInLocalForageFailed',
  v2ErrorSetInLocalStorageFailed: 'v2ErrorSetInLocalStorageFailed',
  v2ErrorSetInMemoryStorageFailed: 'v2ErrorSetInMemoryStorageFailed',

  v2ErrorGetInLocalForageFailed: 'v2ErrorGetInLocalForageFailed',
  v2ErrorGetInLocalStorageFailed: 'v2ErrorGetInLocalStorageFailed',
  v2ErrorGetInMemoryStorageFailed: 'v2ErrorGetInMemoryStorageFailed',

  // Onboarding screens
  v2OnboardingHome: 'v2OnboardingHome',
  v2OnboardingHomeGetStartedClicked: 'v2OnboardingHomeGetStartedClicked',
  v2OnboardingHomeExistingAccountClicked: 'v2OnboardingHomeExistingAccountClicked',

  v2OnboardingDomainScreen: 'v2OnboardingDomainScreen',
  v2OnboardingDomainScreenDomainClicked: 'v2OnboardingDomainScreenDomainClicked', // domainId
  v2OnboardingQuestionScreenLoaded: 'v2OnboardingQuestionScreenLoaded', // questionId
  v2OnboardingQuestionScreenAnswerClicked: 'v2OnboardingQuestionScreenAnswerClicked', // questionId, answerId

  v2OnboardingRegistrationScreenOpen: 'v2OnboardingRegistrationScreenOpen',
  v2OnboardingRegistrationScreenNextButton: 'v2OnboardingRegistrationScreenNextButton', //name , phonenumber, countrycode
  v2OnboardingRegistrationScreenNextButtonError: 'v2OnboardingRegistrationScreenNextButtonError', //error, name , phonenumber, countrycode

  v2OnboardingRegistrationOTPScreenOpen: 'v2OnboardingRegistrationOTPScreenOpen', // tempUserId
  v2OnboardingRegistrationOTPScreenNextButton: 'v2OnboardingRegistrationOTPScreenNextButton', //tempUserId
  v2OnboardingRegistrationOTPScreenResendButton: 'v2OnboardingRegistrationOTPScreenResendButton', //tempUserId
  v2OnboardingRegistrationOTPScreenNextButtonError: 'v2OnboardingRegistrationOTPScreenNextButtonError', //tempUserId
  v2OnboardingRegistrationOTPScreenNextButtonSuccess: 'v2OnboardingRegistrationOTPScreenNextButtonSuccess', //tempUserId

  v2ExistingAccountLoginScreenOpen: 'v2ExistingAccountLoginScreenOpen',
  v2ExistingAccountLoginScreenNextButton: 'v2ExistingAccountLoginScreenNextButton', // phonenumber, countrycode
  v2ExistingAccountLoginScreenCreateNewButton: 'v2ExistingAccountLoginScreenCreateNewButton',
  v2ExistingAccountLoginScreenNextButtonError: 'v2ExistingAccountLoginScreenNextButtonError', ////error, phonenumber, countrycode

  v2ExistingAccountOTPScreenOpen: 'v2ExistingAccountOTPScreenOpen', // userId
  v2ExistingAccountOTPScreenNextButton: 'v2ExistingAccountOTPScreenNextButton', // userId
  v2ExistingAccountOTPScreenResendButton: 'v2ExistingAccountOTPScreenResendButton', // userId
  v2ExistingAccountOTPScreenNextButtonError: 'v2ExistingAccountOTPScreenNextButtonError', //userId
  v2ExistingAccountOTPScreenNextButtonSuccess: 'v2ExistingAccountOTPScreenNextButtonSuccess', //userId

  //Google Sign-in
  v2SignInWithGoogleNewUser: 'v2SignInWithGoogleNewUser', // tempUserId
  v2SignInWithGoogleNewUserCodeReceived: 'v2SignInWithGoogleNewUserCodeReceived', // tempUserId
  v2SignInWithGoogleNewUserSSOSuccess: 'v2SignInWithGoogleNewUserSSOSuccess', // tempUserId, userId

  v2SignInWithGoogleExistingUser: 'v2SignInWithGoogleExistingUser', // tempUserId
  v2SignInWithGoogleExistingUserCodeReceived: 'v2SignInWithGoogleExistingUserCodeReceived', // tempUserId
  v2SignInWithGoogleExistingUserSSOSuccess: 'v2SignInWithGoogleExistingUserSSOSuccess', // tempUserId, userId

  //Learn B2c
  v2LearnScreenOpen: 'v2LearnScreenOpen',
  v2LearnScreenSubjectChanged: 'v2LearnScreenSubjectChanged', //subjectId
  v2LearnScreenTopicClicked: 'v2LearnScreenTopicClicked', //subjectId, categoryId, topicId, topicName, currentSkill, isReady
  v2LearnTopicActivityScreenOpen: 'v2LearnTopicActivityScreenOpen', //topicId, topicName, currentSkill, isReady

  v2LearnTopicActivityQuizStart: 'v2LearnTopicActivityQuizStart', //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended
  v2LearnTopicActivityQuizAttemptOpen: 'v2LearnTopicActivityQuizAttemptOpen', //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended
  v2LearnTopicQuizAttemptReportViewSolution: 'v2LearnTopicQuizAttemptReportViewSolution',// //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended, testId, testInstanceId, date
  v2LearnTopicQuizAttemptReportViewSolutionClosed: 'v2LearnTopicQuizAttemptReportViewSolutionClosed',// //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended, testId, testInstanceId, date

  v2LearnTopicActivityBackButtonClicked: 'v2LearnTopicActivityBackButtonClicked', //topicId, topicName, currentSkill, isReady

  //*** Here */
  v2LearnTopicActivityPracticeStart: 'v2LearnTopicActivityPracticeStart', //topicId, topicName, currentPracticeSkill, currentPracticeSkillScore, recommended, 

  // Practice and Learn Reports
  v2LearnTopicActivityAllReportsOpen: 'v2LearnTopicActivityAllReportsOpen', //topicId, topicName, currentSkill
  v2LearnTopicActivityReportOpen: 'v2LearnTopicActivityReportOpen', //topicId, topicName, currentSkill, acitivityType, testId, testInstanceId

  // Practice Screen
  v2PracticeScreenOpen: 'v2PracticeScreenOpen', //testId, testType, testInstanceId, questionId
  v2PracticeScreenSkipButtonClicked: 'v2PracticeScreenSkipButtonClicked', //testId, testInstanceId, testName, questionNo, questionId, difficultyLevel
  v2PracticeScreenCheckAnswerButtonClicked: 'v2PracticeScreenCheckAnswerButtonClicked', //testId, testInstanceId, testName, questionNo, questionId, difficultyLevel
  v2PracticeScreenNextQuestionButtonClicked: 'v2PracticeScreenNextQuestionButtonClicked', //testId, testInstanceId, testName, questionNo, questionId, difficultyLevel
  v2PracticeScreenFinalSubmitButtonClicked: 'v2PracticeScreenFinalSubmitButtonClicked', // testId, testInstanceId, testName, oldSkillName, oldPraciceSkillScore, newSkillName, newPracticeSkillScore
  v2PracticeScreenLeaveButtonClicked: 'v2PracticeScreenLeaveButtonClicked', //testId, testInstanceId, testName, questionNo, questionId
  v2PracticeSummaryStartNewPracticeButtonClicked: 'v2PracticeSummaryStartNewPracticeButtonClicked', 
  v2PracticeSummaryStartQuizButtonClicked: 'v2PracticeSummaryStartQuizButtonClicked',
  v2PracticeSummaryCloseButtonClicked: 'v2PracticeSummaryCloseButtonClicked',

  // Feedback Form
  v2QuizAndPracticeFeedbackFormOpened: 'v2QuizAndPracticeFeedbackFormOpened', //testId, testType, testInstanceId, questionId
  v2QuizAndPracticeFeedbackFormSubmitButtonClicked: 'v2QuizAndPracticeFeedbackFormSubmitButtonClicked', //testId, testType, testInstanceId, questionId
  v2QuizAndPracticeFeedbackFormCloseButtonClicked: 'v2QuizAndPracticeFeedbackFormCloseButtonClicked', //testId, testType, testInstanceId, questionId

  
  //Learn B2c activity quiz report
  v2LearnTopicQuizReportOpenAfterSubmit: 'v2LearnTopicQuizReportOpenAfterSubmit',// //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended, testId, testInstanceId, skillStatus, oldSKill, newSkill
  v2LearnTopicQuizReportViewSolutionAfterSubmit: 'v2LearnTopicQuizReportViewSolutionAfterSubmit',// //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended, testId, testInstanceId
  v2LearnTopicQuizReportViewSolutionClosedAfterSubmit: 'v2LearnTopicQuizReportViewSolutionClosedAfterSubmit',// //topicId, topicName, currentSkill, isReady, activityId, activityType, attempted, recommended, testId, testInstanceId

  // Challenge Screens
  v2ChallengeScreenOpen: 'v2ChallengeScreenOpen', //userId
  v2ChallengeCreateChallenge: 'v2ChallengeCreateChallenge', //userId
  v2ChallengeJoinChallenge: 'v2ChallengeJoinChallenge', //userId
  v2ChallengeAcceptAIChallenge: 'v2ChallengeAcceptAIChallenge', //userId, challengeId
  v2ChallengeDismissAIChallenge: 'v2ChallengeDismissAIChallenge', //userId, challengeId
  v2ChallengeViewPastChallenges: 'v2ChallengeViewPastChallenges', //userId
  v2PastChallengesScreenOpen: 'v2PastChallengesScreenOpen', //userId
  v2ChallengeMentoChallenge: 'v2ChallengeMentoChallenge', //challengeId

  v2PastChallengesViewChallengeReport: 'v2PastChallengesViewChallengeReport', //userId, challengeId

  v2ChallengesChallengeReportOpen: 'v2ChallengesChallengeReportOpen', //userId, challengeId
  v2AIChallengesChallengeReportOpen: 'v2AIChallengesChallengeReportOpen', //userId, challengeId

  v2PastChallengesChallengeReportViewSolutions: 'v2PastChallengesChallengeReportViewSolutions', //userId, challengeId
  v2AIPastChallengesChallengeReportViewSolutions: 'v2AIPastChallengesChallengeReportViewSolutions', //userId, challengeId

  v2ChallengeTypeScreenOpen: 'v2ChallengeTypeScreenOpen', //userId
  v2ChallengeTypeScreenSelectChallenge: 'v2ChallengeTypeScreenSelectChallenge', //userId, quizTemplateId, challengeId

  v2ChallengeHostWaitingRoomScreenOpen: 'v2ChallengeHostWaitingRoomScreenOpen', //userId, challengeId
  v2ChallengeHostWaitingRoomScreenStartChallenge: 'v2ChallengeHostWaitingRoomScreenStartChallenge', //userId, challengeCode, challengeId, testId, testInstanceId

  v2ChallengeJoinChallengeScreenOpen: 'v2ChallengeJoinChallengeScreenOpen', //userId
  v2ChallengeJoinChallengeScreenJoinButtonClicked: 'v2ChallengeJoinChallengeScreenJoinButtonClicked', //userId, challengeId

  v2ChallengeParticipantWaitingRoomScreenOpen: 'v2ChallengeParticipantWaitingRoomScreenOpen', //userId, challengeCode, challengeType

  v2ChallengeParticipantChallengeStarted: 'v2ChallengeParticipantChallengeStarted', //userId, challengeId, testId, testInstanceId,

  v2ChallengeCompleted: 'v2ChallengeCompleted',  ///challengeType, challengeId, testId, testInstanceId
  v2ChallengeLeftChallengeWithoutCompleting: 'v2ChallengeLeftChallengeWithoutCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant

  v2ChallengeExitChallengeButtonClickedAfterCompleting: 'v2ChallengeExitChallengeButtonClickedAfterCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant
  v2ChallengeExitChallengeButtonClickedBeforeCompleting: 'v2ChallengeExitChallengeButtonClickedBeforeCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant

  v2ChallengeReportPageOpenAfterCompletingChallenge: 'v2ChallengeReportPageOpenAfterCompletingChallenge', //userId, challengeId, challengeType

  v2ChallengeReportViewSolutionsAfterCompletingChallenge: 'v2ChallengeReportViewSolutionsAfterCompletingChallenge', //userId, challengeId, challengeType

  v2AIChallengeWaitingRoomScreenOpen: 'v2AIChallengeWaitingRoomScreenOpen', //userId, challengeType

  v2AIChallengeStarted: 'v2AIChallengeStarted', //userId, challengeId, testId, testInstanceId,

  v2AIChallengeCompleted: 'v2AIChallengeCompleted',  ///challengeType, challengeId, testId, testInstanceId
  v2AIMentoChallengeCompleted: 'v2AIMentoChallengeCompleted',  ///challengeType, challengeId, testId, testInstanceId

  v2AIChallengeLeftChallengeWithoutCompleting: 'v2AIChallengeLeftChallengeWithoutCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant

  v2AIChallengeExitChallengeButtonClickedAfterCompleting: 'v2AIChallengeExitChallengeButtonClickedAfterCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant
  v2AIChallengeExitChallengeButtonClickedBeforeCompleting: 'v2AIChallengeExitChallengeButtonClickedBeforeCompleting',  /// challengeType, challengeId, testId, testInstanceId, userType : host or participant

  v2AIChallengeReportPageOpenAfterCompletingChallenge: 'v2AIChallengeReportPageOpenAfterCompletingChallenge', //userId, challengeId, challengeType

  v2AIChallengeReportViewSolutionsAfterCompletingChallenge: 'v2AIChallengeReportViewSolutionsAfterCompletingChallenge', //userId, challengeId, challengeType

  // Ai Mentor screen
  v2AiMentorScreenOpen: 'v2AiMentorScreenOpen',

  // Mock Test Screen
  v2MockTestScreenOpen: 'v2MockTestScreenOpen',
  v2MockTestStartTestButtonClicked: 'v2MockTestStartTestButtonClicked', // testId,
  v2MockTestReTestButtonClicked: 'v2MockTestReTestButtonClicked', // testId,
  v2MockTestViewReportButtonClicked: 'v2MockTestViewReportButtonClicked', // testId,
  v2MockTestInstructionScreenOpen: 'v2MockTestInstructionScreenOpen', // testId
  v2MockTestInstructionScreenBeginTest:'v2MockTestInstructionScreenBeginTest', //testId,testInstanceId
  v2MockTestInstructionScreenCloseButtonClicked: 'v2MockTestInstructionScreenCloseButtonClicked', //testId,testInstanceId
  v2MockTestQuestionScreenOpen: 'v2MockTestQuestionScreenOpen', //testId,testInstanceId,moduleId,questionId
  v2MockTestQuestionCalculatorButtonClick: 'v2MockTestQuestionCalculatorButtonClick',  //testId,testInstanceId,moduleId, questionId
  v2MockTestQuestionReferencesButtonClick: 'v2MockTestQuestionReferencesButtonClick',  //testId,testInstanceId,moduleId, questionId
  v2MockTestQuestionPreviousButtonClicked: 'v2MockTestQuestionPreviousButtonClicked', //testId,testInstanceId,moduleId, questionId, qDuration, qResponse
  v2MockTestQuestionClearResponseButtonClicked: 'v2MockTestQuestionClearResponseButtonClicked',//testId,testInstanceId,moduleId, questionId
  v2MockTestQuestionNextButtonClicked: 'v2MockTestQuestionNextButtonClicked',//testId,testInstanceId,moduleId, questionId, qDuration, qResponse
  v2MockTestQuestionModuleInstructionOpen: 'v2MockTestQuestionModuleInstructionOpen', //testId,testInstanceId,moduleId
  v2MockTestLeaveTestButtonClicked: 'v2MockTestLeaveTestButtonClicked',//testId,testInstanceId,moduleId
  v2MockTestQuestionListPopupOpen: 'v2MockTestQuestionListPopupOpen',//testId,testInstanceId,moduleId
  v2MockTestReviewScreenOpen: 'v2MockTestReviewScreenOpen',//testId,testInstanceId,moduleId,
  v2MockTestModuleYesSubmitButtonClicked: 'v2MockTestModuleYesSubmitButtonClicked',//testId,testInstanceId,moduleId, testSubmission
  v2MockTestModuleNoWaitButtonClicked: 'v2MockTestModuleNoWaitButtonClicked',//testId,testInstanceId,moduleId,
  v2MockTestModuleBreakScreenOpen: 'v2MockTestModuleBreakScreenOpen',//testId,testInstanceId,
  v2MockTestModuleBreakScreenResumeButtonClicked: 'v2MockTestModuleBreakScreenResumeButtonClicked',//testId,testInstanceId,nextModuleId
  v2MockTestSubmission: 'v2MockTestSubmission',//testId,testInstanceId

  //Mock Test Attempts
  v2MockTestAttemptScreenOpen: 'v2MockTestAttemptScreenOpen', // testId
  v2MockTestAttemptBackButtonClick: 'v2MockTestAttemptBackButtonClick', // testId
  v2MockTestAttemptReTestButtonClick: 'v2MockTestAttemptReTestButtonClick', // testId
  v2MockTestAttemptViewReportButtonClick: 'v2MockTestAttemptViewReportButtonClick', //testId,testInstanceId

  // Mock Test Report
  v2MockTestReportOpen: 'v2MockTestReportOpen',// testId, testInstanceId, lowerScore, upperScore, correctAnswer, incorrectAnswer, unanswered
  v2MockTestReportModuleOpen: 'v2MockTestReportModuleOpen', // testId, testInstanceId, subjectKey, moculeLabel, lowerScore, upperScore, correctAnswer, incorrectAnswer, unanswered, readingAndWritingLowerScore, readingAndWritingLowerScore, mathLowerScore, mathUpperScore // testId, testInstanceId, subjectKey, moculeLabel, lowerScore, upperScore, correctAnswer, incorrectAnswer, unanswered, moduleDifficulty
  v2MockTestReportOpenPractice: 'v2MockTestReportOpenPractice', // testId, testInstanceId, topicId, topicName
  v2MockTestReportViewSolution: 'v2MockTestReportViewSolution', // testId, testInstanceId, activityType
  v2MockTestReportPdfDownloadButtonClick: 'v2MockTestReportPdfDownloadButtonClick', //testId,testInstanceId
  v2MockTestReportParentDetailsDialogOpen: 'v2MockTestReportParentDetailsDialogOpen', //testId,testInstanceId
  v2MockTestReportParentDetailsDialogClose: 'v2MockTestReportParentDetailsDialogClose', //testId,testInstanceId
  v2MockTestReportParentDetailsDialogSaveButtonClick: 'v2MockTestReportParentDetailsDialogSaveButtonClick', //testId,testInstanceId

  // Mock Test Solution
  v2MockTestSolutionScreenOpen: 'v2MockTestSolutionScreenOpen', //testId,testInstanceId
  v2MockTestSolutionChangeModule: 'v2MockTestSolutionChangeModule', //testId,testInstanceId,prevModuleId, moduleId
  v2MockTestSolutionPreviousButtonClicked: 'v2MockTestSolutionPreviousButtonClicked', //testId,testInstanceId,moduleId,questionId
  v2MockTestSolutionNextButtonClicked: 'v2MockTestSolutionNextButtonClicked', //testId,testInstanceId,moduleId,questionId
  v2MockTestSolutionCloseButtonClicked: 'v2MockTestSolutionCloseButtonClicked', //testId, testInstanceId

  //chatbot
  v2ChatbotOpen: 'v2ChatbotOpen', //testId, testInstanceId, qId, mode(mockTestSolution, practiceSolution, practiceQuestion)
  v2ChatbotClose: 'v2ChatbotClose', //testId, testInstanceId, qId, mode

  v2ChatbotCustomMessage : 'v2ChatbotCustomMessage', //testId, testInstanceId, qId, mode, customMessage
  v2ChatbotButtonClicked : 'v2ChatbotButtonClicked', //testId, testInstanceId, qId, mode, tag, buttonLabel, buttonType(ai, nudge), userMessage

  // Stripe Payment
  // Pricing 
  v2PricingScreenOpen: 'v2PricingScreenOpen', // userRegistrationFlow (true/false)
  v2PricingScreenPackageSelected: 'v2PricingScreenPackageSelected', //packageId,  userRegistrationFlow (true/false)
  v2PricingScreenPackageContinueClicked: 'v2PricingScreenPackageContinueClicked', //packageId,  userRegistrationFlow (true/false)
  v2PricingScreenClosed: 'v2PricingScreenClosed', //  userRegistrationFlow (true/false)
  v2SubscriptionSuccessScreenOpen: 'v2SubscriptionSuccessScreenOpen', //packageIds(name and id), sessionId


  v2PricingDialogOpenForQuiz: 'v2PricingDialogOpenForQuiz', // topicId
  v2PricingDialogOpenForPractice: 'v2PricingDialogOpenForPractice',// topicId
  v2PricingDialogOpenForMocktest: 'v2PricingDialogOpenForMocktest',// testId
  v2PricingDialogOpenForTestReportPractice: 'v2PricingDialogOpenForTestReportPractice', // topicId
  v2PricingDialogOpenForMockTestAttempt: 'v2PricingDialogOpenForMockTestAttempt',// testId

  v2PricingDialogPackageUpgradeButtonClicked: 'v2PricingDialogPackageUpgradeButtonClicked', //packageId
  v2PricingDialogClose: 'v2PricingDialogClose',

  // MyProfile
  v2ProfileScreenOpen: 'v2PricingScreenOpen',
  v2ProfileScreenPackageUpgradeButtonClicked: 'v2ProfileScreenPackageUpgradeButtonClicked',
  v2ProfileScreenCancelButtonClicked: 'v2ProfileScreenCancelButtonClicked', //packageId
  v2ProfileScreenRenewButtonClicked: 'v2ProfileScreenRenewButtonClicked', //packageId'

  //Feedback
  v2FeedbackScreenOpen: 'v2FeedbackScreenOpen',
  v2FeedbackSubmitButtonClicked:'v2FeedbackSubmitButtonClicked',

  //Teacher Dashboard
  v2TeacherViewStudentScreenOpen: 'v2TeacherViewStudentScreenOpen', //courseId, fromClass (true/false), batchId
  v2TeacherViewStudentScreenStudentSearched: 'v2TeacherViewStudentScreenStudentSearched', //courseId, searchedKeyword, fromClass, batchId
  v2TeacherViewStudentScreenStudentOpen: 'v2TeacherViewStudentScreenStudentOpen', //courseId, stuedentId, fromClass, batchId

  v2TeacherViewStudentTabSwitched: 'v2TeacherViewStudentTabSwitched', //courseId, tabName, fromClass (true/false), batchId

  v2TeacherViewStudentScreenTestAttemptsTabOpen: 'v2TeacherViewStudentScreenTestAttemptsTabOpen', // courseId, studentId, fromClass, batchId
  v2TeacherViewStudentScreenTestAttemptsTabReportOpen: 'v2TeacherViewStudentScreenTestAttemptsTabReportOpen', //courseId, studentId, testId, testInstanceId, fromClass, batchId

  v2TeacherViewStudentScreenSkillsTabOpen: 'v2TeacherViewStudentScreenSkillsTabOpen', //courseId, studentId, fromClass, batchId
  v2TeacherViewStudentScreenSkillsTabReportOpen: 'v2TeacherViewStudentScreenSkillsTabReportOpen',  //courseId, studentId, topicId, fromClass, batchId
  
  v2TeacherViewClassScreenOpen: 'v2TeacherViewClassScreenOpen', //courseId
  v2TeacherViewClassScreenClassOpen: 'v2TeacherViewClassScreenClassOpen', //courseId, stuedentId

  v2TeacherViewClassTabSwitched: 'v2TeacherViewClassTabSwitched', //courseId, tabName, batchId
  
  v2TeacherViewClassScreenAssignmentProgressTabOpen: 'v2TeacherViewClassScreenAssignmentProgressTabOpen', //courseId, batchId
  v2TeacherViewClassScreenAssignmentProgressTabTimeButtonSwitched: 'v2TeacherViewClassScreenAssignmentProgressTabTimeButtonSwitched', //courseId, batchId, isCurrent

  v2TeacherViewClassScreenTestSummaryTabOpen: 'v2TeacherViewClassScreenTestSummaryTabOpen', //courseId, batchId
  v2TeacherViewClassScreenTestSummaryTabViewButtonClicked: 'v2TeacherViewClassScreenTestSummaryTabViewButtonClicked', //courseId, batchId, isCurrent

  v2TeacherViewStudentScreenAssignmentTabOpen: 'v2TeacherViewStudentScreenAssignmentTabOpen', //courseId, fromClass, batchId, studentId
  v2TeacherViewStudentScreenAssignmentTabTimeButtonSwitched: 'v2TeacherViewStudentScreenAssignmentTabTimeButtonSwitched', //courseId, fromClass, batchId, studentId, isCurrent
  v2TeacherViewStudentScreenAssignmentTabViewQuestionsButtonClicked: 'v2TeacherViewStudentScreenAssignmentTabViewQuestionsButtonClicked', //courseId, fromClass, batchId, studentId
  v2TeacherViewStudentScreenAssignmentTabAssignmentCreated: 'v2TeacherViewStudentScreenAssignmentTabAssignmentCreated', //courseId, fromClass, batchId, studentId, isCopied
  v2TeacherViewStudentScreenAssignmentTabAssignmentEdited: 'v2TeacherViewStudentScreenAssignmentTabAssignmentEdited', //courseId, fromClass, batchId, studentId, assignmentId
  v2TeacherViewStudentScreenAssignmentTabAssignmentCopied: 'v2TeacherViewStudentScreenAssignmentTabAssignmentCopied', //courseId, fromClass, batchId, studentId,
  v2TeacherViewStudentScreenAssignmentTabAssignmentPasted: 'v2TeacherViewStudentScreenAssignmentTabAssignmentPasted', //courseId, fromClass, batchId, studentId,
  
  //Admin Dashboard
  v2AdminDashboardTabSwitched: 'v2AdminStudentTabSwitched', //courseId, tabName

  // Student Screen
  v2AdminStudentScreenOpen: 'v2AdminStudentScreenOpen', //courseId
  v2AdminStudentScreenStudentSearched: 'v2AdminStudentScreenStudentSearched', //courseId, searchedKeyword
  v2AdminStudentScreenEditButtonClicked: 'v2AdminStudentScreenEditButtonClicked', //courseId, studentId
  v2AdminStudentScreenAddButtonClicked: 'v2AdminStudentScreenAddButtonClicked', //courseId, studentId
  v2AdminStudentScreenDeleteButtonClicked: 'v2AdminStudentScreenDeleteButtonClicked', //courseId, studentId
  v2AdminStudentScreenAssignButtonClicked: 'v2AdminStudentScreenAssignButtonClicked', //courseId, studentId

  // Tutor Screen
  v2AdminTutorScreenOpen: 'v2AdminTutorScreenOpen', //courseId
  v2AdminTutorScreenTutorSearched: 'v2AdminTutorScreenTutorSearched', //courseId, searchedKeyword
  v2AdminTutorScreenEditButtonClicked: 'v2AdminTutorScreenEditButtonClicked', //courseId, tutorId
  v2AdminTutorScreenAddButtonClicked: 'v2AdminTutorScreenAddButtonClicked', //courseId, tutorId
  v2AdminTutorScreenDeleteButtonClicked: 'v2AdminTutorScreenDeleteButtonClicked', //courseId, tutorId

  // Class Screen
  v2AdminClassScreenOpen: 'v2AdminClassScreenOpen', //courseId
  v2AdminClassScreenClassSearched: 'v2AdminClassScreenClassSearched', //courseId, searchedKeyword
  v2AdminClassScreenEditButtonClicked: 'v2AdminClassScreenEditButtonClicked', //courseId, batchId
  v2AdminClassScreenAddButtonClicked: 'v2AdminClassScreenAddButtonClicked', //courseId, batchId
  v2AdminClassScreenDeleteButtonClicked: 'v2AdminClassScreenDeleteButtonClicked', //courseId, batchId
  
  // AI Screens
  v2AdminAIHubScreenOpen: 'v2AdminAIHubScreenOpen', 
  v2AdminAIHubScreenToolOpen: 'v2AdminAIHubScreenToolOpen', //toolId
  v2AdminAIHubScreenToolJoinWaitlist: 'v2AdminAIHubScreenToolJoinWaitlist', //toolId
  v2AIToolGenerateButtonClicked: 'v2AIToolGenerateButtonClicked', //toolId, metadata (easy, intermediate, hard questions count)
  v2AIToolPromptMessageButtonClicked: 'v2AIToolPromptMessageButtonClicked', //toolId
  v2AIToolActionsOpened: 'v2AIToolActionsOpened', //toolId, isOpened (true/ false)
  v2AIToolActionsButtonClicked: 'v2AIToolActionsButtonClicked', // toolId, buttonName
  v2AIEditQuestionDialogOpened: 'v2AIEditQuestionDialogOpened', //toolId, aiQuestionId
  v2AIEditedQuestionSaved: 'v2AIEditedQuestionSaved', //toolId, aiQuestionId
  v2AIQuestionLiked: 'v2AIQuestionLiked', //toolId, aiQuestionId, isLiked (true/false)
  v2AIMyCollectionsButtonClicked: 'v2AIMyCollectionsButtonClicked', // fromTool (true/false), toolId
  v2AIMyCollectionsScreenOpened: 'v2AIMyCollectionsScreenOpened',
  v2AINewCollectionCreated: 'v2AINewCollectionCreated', // collectionName, fromTool (true/false), toolId, aiQuestionIds (if there)
  v2AICollectionEdited: 'v2AICollectionEdited', // collectionId, fromTool (true/false), toolId, nameChanged (true/false), aiQuestionAdded (true/false), aiQuestionRemoved(true/false)
  v2AICollectionRemoved: 'v2AICollectionRemoved', // collectionId,
  v2AICollectionShareButtonClicked: 'v2AICollectionShareButtonClicked', //collectionId
  v2AICollectionShareLinkCopied: 'v2AICollectionShareLinkCopied', //collectionId
  v2AICollectionViewQuestionCliked: 'v2AICollectionViewQuestionCliked', //collectionId
  v2AICollectionEditNameButtonClicked: 'v2AICollectionEditNameButtonClicked', //collectionId
  v2AICollectionDeleteButtonClicked: 'v2AICollectionDeleteButtonClicked', //collectionId

  // PDF AI
  v2AIPDFQuestionsExtract: 'v2AIPDFQuestionsExtract', //toolId, metadata 

  // Assignments
  v2AssignmentStart: 'v2AssignmentStart', //assignmentId 
  v2AssignmentEnd: 'v2AssignmentEnd', //assignmentId
  v2TeacherAssignmentStart : 'v2TeacherAssignmentStart',


  // Scribble Functionality
  v2NotesButtonClicked: 'v2NotesButtonClicked', //userType(teacher/student), isOpenedFromTeacherView (true/false), buttonCurrentAction(add/edit/view), testInstanceId, questionId
  v2NotesScreenOpened: 'v2NotesScreenOpened', //userType(teacher/student), isOpenedFromTeacherView, testInstanceId, questionId, notesFromServer(true/false), successfullyOpened (true/ false), errorMessage
  v2NotesToolSelected: 'v2NotesToolSelected', //testInstanceId, questionId, toolType
  v2NotesSaveButtonClicked: 'v2NotesSaveButtonClicked',// testInstanceId, questionId, action(add/update), successfullySaved(true/false), errorMessage
  v2NotesConvertingImageToBase64: 'v2NotesConvertingImageToBase64', // testInstanceId, questionId, successfullyConverted(true/false), errorMessage 
  v2NotesClosed: 'v2NotesClosed', // testInstanceId, questionId

  // Parent Dashboard
  v2ParentViewChildrenListScreenOpen: 'v2ParentViewChildrenListScreenOpen', //courseId,
  v2ParentViewChildrenListScreenChildOpen: 'v2ParentViewChildrenListScreenChildOpen', //courseId, childId,
  v2ParentViewAddChildButtonClicked: 'v2ParentViewAddChildButtonClicked', //courseId, childId,
  v2ParentViewUpgradeChildPackageButtonClicked: 'v2ParentViewUpgradeChildPackageButtonClicked', //courseId, childId,
  v2ParentViewChildTabSwitched: 'v2ParentViewChildTabSwitched', //courseId, tabName, childId
  v2ParentViewChildTestAttemptsTabOpen: 'v2ParentViewChildTestAttemptsTabOpen', //courseId, childId
  v2ParentViewChildTestAttemptsTabReportOpen: 'v2ParentViewChildTestAttemptsTabReportOpen', //courseId, childId, testId, testInstanceId,
  v2ParentViewChildSkillsTabOpen: 'v2ParentViewChildSkillsTabOpen', //courseId, studentId,
  v2ParentViewChildSkillsTabReportOpen: 'v2ParentViewChildSkillsTabReportOpen', //courseId, childId, topicId,
  // Resource Center Functionality
  // Admin Resource Screen
  v2AdminResourceScreenOpen: 'v2AdminResourceScreenOpen', // customerId
  v2AdminResourceAddButtonClicked: 'v2AdminResourceAddButtonClicked', // customerId, folderId, contentType
  v2AdminResourceEditButtonClicked: 'v2AdminResourceEditButtonClicked', // customerId, folderId, contentType, contentId
  v2AdminResourceDeleteButtonClicked: 'v2AdminResourceDeleteButtonClicked', // customerId, folderId, contentType, contentId
  v2AdminResourceFolderFilesOpen: 'v2AdminResourceFolderFilesOpen', // customerId, folderId
  v2AdminResourceFolderVideoPlayed: 'v2AdminResourceFolderVideoPlayed', // customerId, folderId, contentType, contentId, youtubeUrl
  v2AdminResourceFolderPdfOpened: 'v2AdminResourceFolderPdfOpened', // customerId, folderId, contentType, contentId, pdfUrl
  v2AdminResourceFolderShareButtonClicked: 'v2AdminResourceFolderShareButtonClicked', // customerId, folderId
  v2AdminResourceContentShareButtonClicked: 'v2AdminResourceContentShareButtonClicked', // customerId, folderId, contentType, contentId
  v2AdminResourceFolderShareLinkCopied: 'v2AdminResourceFolderShareLinkCopied', // customerId, folderId
  v2AdminResourceContentShareLinkCopied: 'v2AdminResourceContentShareLinkCopied', // customerId, folderId, contentType, contentId
  v2AdminResourceFolderSendEmailButtonClicked: 'v2AdminResourceFolderSendEmailButtonClicked', // customerId, folderId
  v2AdminResourceContentSendEmailButtonClicked: 'v2AdminResourceContentSendEmailButtonClicked', // customerId, folderId, contentType, contentId
  // Student Resource Screen
  v2StudentResourceScreenOpen: 'v2StudentResourceScreenOpen', // customerId, folderId
  v2StudentResourceFolderFilesOpen: 'v2StudentResourceFolderFilesOpen', // customerId, folderId
  v2StudentResourceFolderVideoPlayed: 'v2StudentResourceFolderVideoPlayed', // customerId, folderId, contentType, contentId, youtubeUrl
  v2StudentResourceFolderPdfOpened: 'v2StudentResourceFolderPdfOpened', // customerId, folderId, contentType, contentId, pdfUrl
  v2StudentResourceFolderShareButtonClicked: 'v2StudentResourceFolderShareButtonClicked', // customerId, folderId
  v2StudentResourceContentShareButtonClicked: 'v2StudentResourceContentShareButtonClicked', // customerId, folderId, contentType, contentId
  v2StudentResourceFolderShareLinkCopied: 'v2StudentResourceFolderShareLinkCopied', // customerId, folderId
  v2StudentResourceContentShareLinkCopied: 'v2StudentResourceContentShareLinkCopied', // customerId, folderId, contentType, contentId
  // Student Resource Content Screen
  v2StudentResourceContentScreenOpen: 'v2StudentResourceContentScreenOpen', // customerId, folderId, contentId

  // Question Action tools
  v2QuestionActionToolsOpen: 'v2QuestionActionToolsOpen', // qId
  v2QuestionActionChatbotToolClicked: 'v2QuestionActionChatbotToolClicked', // qId,
  v2QuestionActionBookmarkToolClicked: 'v2QuestionActionChatbotToolClicked', // qId,
  v2QuestionActionReportToolClicked: 'v2QuestionActionChatbotToolClicked', // qId,
  // Bookmark Component tool
  v2QuestionBookmarkToolScreenOpen: 'v2QuestionBookmarkToolScreenOpen', // qId
  v2QuestionBookmarkToolScreenClose: 'v2QuestionBookmarkToolScreenOpen', // qId
  v2QuestionBookmarkToolAddButtonClicked: 'v2QuestionBookmarkToolScreenOpen', // qId
  v2QuestionBookmarkToolEditButtonClicked: 'v2QuestionBookmarkToolScreenOpen', // qId, bookmarkId
  v2QuestionBookmarkToolRemoveButtonClicked: 'v2QuestionBookmarkToolScreenOpen', // qId, bookmarkId

  // Student Bookmark Screen
  v2StudentBookmarkScreenOpen: 'v2StudentBookmarkScreenOpen',
  v2StudentBookmarkFilterData: 'v2StudentBookmarkFilterData', // subjectId, tag, categoryId, topicId
  v2StudentBookmarkQuestionViewOpened: 'v2StudentBookmarkQuestionViewOpened', // qId, bookmarkId, qDisplayNo
  v2StudentBookmarkQuestionViewPrevButtonClicked: 'v2StudentBookmarkQuestionViewPrevButtonClicked', // qId, bookmarkId, qDisplayNo
  v2StudentBookmarkQuestionViewNextButtonClicked: 'v2StudentBookmarkQuestionViewNextButtonClicked', // qId, bookmarkId, qDisplayNo
  v2StudentBookmarkQuestionViewClosed: 'v2StudentBookmarkQuestionViewClosed' // qId, bookmarkId, qDisplayNo   


};

const amplifyConfig = {
  Auth: getCognitoAuthConfigForAmplify()
}

const analyticsConfig = {
  AWSPinpoint: getPinPointConfig()
}

export function initializeAWSPinpointAnalytics() {
  //Initialize Amplify
  Auth.configure(amplifyConfig);

  Analytics.configure(analyticsConfig);
  enableAutoSessionTracking();
  enableAutoPageViewTracking();
}

function enableAutoSessionTracking() {
  Analytics.autoTrack('session', {
    enable: true,
    attributes: () => {
      return getCommonAttributes();
    },
  });
}

function enableAutoPageViewTracking() {
  Analytics.autoTrack('pageView', {
    enable: true,
    attributes: () => {
      return getCommonAttributes();
    },
    type: 'SPA',
    // getUrl: () => {
    //   // the default function
    //   return window.location.href;
    // }
  });
}

export function recordAnalyticsEvent(eventName, eventAttributes, immediatedSend=false) {
  try{
    let commonAttributes = getCommonAttributes();

    let attributeValues = { ...eventAttributes, ...commonAttributes };
    // store.dispatch("lastActivity", { buildmode: "web", version:  `${verFlavor.APP_VERSION}.${verFlavor.APP_BUILDNUMBER}`})
    // console.log(`Recording Analaytics Event: ${eventName} | ${JSON.stringify(attributeValues)}`);

    Analytics.record({
      name: eventName,
      attributes: attributeValues,
      immediate: immediatedSend
    });
   }catch(err){
    console.log("Error catch", err)
   }

  return {}

}

function getCommonAttributes() {

  store.dispatch("lastActivity", { buildmode: "web", version:  `${verFlavor.APP_VERSION}.${verFlavor.APP_BUILDNUMBER}`})
  let attrs = new Object();
  attrs.appflavour = verFlavor.APP_FLAVOR;
  attrs.appversion = verFlavor.APP_VERSION;
  attrs.appbuildnumber = verFlavor.APP_BUILDNUMBER;
  attrs.appbuildmode = "web";
  attrs.eventtimestampms = Math.floor(Date.now());
  let headers = getHeaders();
  let userId = headers.userid;
  if (!userId) {
    userId = 'notloggedin'
  }
  attrs.deviceid = headers.deviceid;
  attrs.domainid = headers.domainid;
  attrs.organizationid = headers.organizationid;
  attrs.userid = userId;
  attrs.userAgent = navigator.userAgent.toString().substring(0,198);
  attrs.platform = navigator.platform;
  attrs.browserappname = navigator.appName;
  attrs.browserappVersion = navigator.appVersion.toString().substring(0,198);
  attrs.browservendor = navigator.vendor;
  attrs.pageURL = window.location.href.toString().substring(0,198);
  attrs.origin = window.location.origin.toString().substring(0,198);
  attrs.devicescreenheight = window.screen.availHeight;
  attrs.devicescreenwidth = window.screen.availWidth;
  attrs.deviceabsoluteheight = window.screen.height;
  attrs.deviceabsolutewidth = window.screen.width;
  attrs.devicePixelRatio = window.devicePixelRatio;
  attrs.pagewidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  attrs.pageheight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  attrs.domainname = store.getters.currentDomainName;
  attrs.timezone = moment.tz.guess(true);
  attrs.utcoffsetmins = moment().utcOffset();
  let referralCode = getReferralId()
  if(referralCode)
  {
    attrs.referralCode = referralCode
  }
  let customerid = getCourseId();
  if(!customerid){
    customerid = 'mentominddefault'
  }
  let customername = getCourseName();
  if(!customername){
    customername = 'mentominddefault'
  }
  attrs.customerid = customerid;
  attrs.customername = customername;
  // referralCode : "" // else not applicable
  //console.log(`Name of currentDomain = ${store.getters.currentDomainName}`);
  return attrs;
}

//export default { AnalyticsEvents }