import store from "../store";
import router from '../router'

export function checkSubscription(item) {
    console.log("check sub");
    if ("accessAllowed" in item) {
        if (item.accessAllowed == false) {
            store.commit('openUpgradeDialog', true)
            return  false
        }
    }
    return true
}

export async function redirectPricePage(item, requiredSubscription=true) {
    
    if ( typeof item === "object" &&
        "isAccessAllowed" in item && requiredSubscription) {
        if (item.isAccessAllowed == false) {
            await router.replace('/');
            store.commit("appbarVisible", true);
            store.commit("navbarVisible", true);
            store.commit("showNotification", false);
            store.commit('openUpgradeDialog', true)
            return null;
        }else{
            return item;
        }
    }else{
        return item;
    }
    // return true
}