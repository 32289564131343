<template>
	<div class="bgW">

		<div v-if="loadedFromServer" class="">

			<EditQuestionComponent v-if="openEditQuestionDialog" @closeQuestionDialog="closeQuestionDialog"
				@saveQuestionResponse="saveQuestionResponse" :questionItem="editQuestionItem"
				@showQuestionSnackbar="showQuestionSnackbar = true" @questionSnackbarMsg="updatequestionSnackbarMsg" />

			<!-- Delete Question -->
			<v-dialog style="backdrop-filter: blur(12.5px)" persistent v-model="dialogDelete" max-width="400px">
				<v-card elevation="0" class="assignment-form text-white pa-4"
					:width="$vuetify.display.smAndUp ? 400 : '95%'">
					<v-card-text class="text-white pa-0 ma-0">
						<span class="assignment-form-title">Are you sure you want to delete the Question?</span>
					</v-card-text>
					<v-card-text></v-card-text>
					<v-card-actions class="pa-0 ma-0">
						<v-spacer></v-spacer>
						<v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default"
							variant="flat" height="40" @click.stop="closeDeleteDialog()">No</v-btn>
						<v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default"
							variant="flat" height="40" @click.stop="deleteQuestion()">Yes</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog absolute class="bottom dialog-bx" height="700px" width="800px" v-model="questionDialog">
				<v-card height="700px" color="background">
					<v-card-text class="pa-8 ">
						<v-row justify="space-between" class="t-h5 px-3">
							<v-col class="ml-0m pl-0">

								Questions
							</v-col>
							<v-col class="text-right">
								<v-icon @click="questionDialog = false">
									mdi-close
								</v-icon>
							</v-col>
						</v-row>
						<v-row>
							<v-divider class=" mt-2 mb-5">

							</v-divider>
						</v-row>

						<v-row class="my-4" v-for="item, sIndex in currentTestResultJson" :key="sIndex">
							<v-col v-if="item.visibleOnApp" cols="12" class="t-h5Reg">
								{{ item.sectionName }}
							</v-col>

							<v-row>

								<v-col cols="auto" class="mx-2" v-for="item, qIndex in item.questions" :key="qIndex">

									<v-avatar style="cursor: pointer;" @click="openQFromDialog(sIndex, qIndex)"
										size="40" variant="outlined" :class="{
											'unattempted': item.isCorrect == null,
											'correct': item.isCorrect != null && item.isCorrect == true,
											'incorrect': item.isCorrect != null && item.isCorrect == false,
										}">
										{{ qIndex + 1 }}

									</v-avatar>

								</v-col>
							</v-row>
						</v-row>

					</v-card-text>

				</v-card>
			</v-dialog>

			<v-app-bar app color="breadCrumbBackColor">

				<v-app-bar-title>
					<template v-slot:text>
						<span class>
							{{ testInstanceQuestionSummary.testName }}

						</span>
					</template>

				</v-app-bar-title>


				<v-spacer></v-spacer>

				<div v-if="!$vuetify.display.xs" class="filter-switch mr-4">
					<v-switch color="primaryPurple" v-model="showSolution" hide-details
						@update:modelValue="toggleSolution" label="Show Solution"></v-switch>
				</div>

				<v-icon v-if="$vuetify.display.xs" @click="toggleFilter" class="mr-4">mdi-filter</v-icon>

				<v-icon @click.stop="backFromDialog" class="mr-4"> mdi-close</v-icon>
				<template v-slot:extension v-if="showFilter">
					<v-card class="testCard w-100">
						<v-card-text class="pa-0" :class="$vuetify.display.mdAndUp ? 'px-10' : 'px-3'">
							<v-row class="text-white my-3" align="center" dense>
								<v-col cols="auto">
									<span class="t-1remReg text-bold">Filters</span>
								</v-col>
								<v-spacer></v-spacer>
								<v-col cols="auto">
									<div class="filter-switch">
										<v-switch color="primaryPurple" v-model="showSolution" hide-details
											@update:modelValue="toggleSolution" label="Show Solution"></v-switch>
									</div>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</template>

			</v-app-bar>
			<DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false" :test-type="testType"
				:q-id="currentQ.qId" :test-id="testId" :test-instance-id="testInstanceId" :q-obj="currentQ"
				:isAIQuestion="isAIQuestion"></DragableFeedbackDialog>
			<v-dialog
				v-model="removeQuestionConfirmationDialog"
				max-width="400"
				persistent>
				<v-card
					text="Are you sure you want to remove this question from the assignment?">
					<template v-slot:actions>
						<v-spacer></v-spacer>

						<v-btn @click="removeQuestionConfirmationDialog = false">
							No
						</v-btn>

						<v-btn @click="removeAssignmentQuestion()">
							Yes
						</v-btn>
					</template>
				</v-card>
			</v-dialog>
			<v-container fluid class="solution-height" :class="{
				'solution-height-sm': $vuetify.display.xs
			}">
				<v-row justify="start" :class="{ 'w-70': $vuetify.display.mdAndUp }">
					<v-col cols="12">
						<v-card elevation="0" color="white" scrollable>
							<section class=" mx-5 mt-10">
								<div class="mb-8">
									<v-row class="align-start">
										<v-col cols="10" sm="auto" class="ma-0 pa-0">

											<v-row align="start">
												<v-col cols=auto>
													<span class=" qNoClass font-weight-bold ml-3 mr-5">
														Question {{ currentQNo }}
													</span>
													<v-tooltip location="top" v-if="currentQ.reviewRequired">
														<template #activator="{ props }">
															<span class="mx-0" v-bind="props">
																<span class="mr-n1  text-error">(</span>
																<v-icon color="error">mdi-exclamation</v-icon>
																<span class="ml-n1 text-error">Review Needed</span>
																<span class="text-error">)</span>
															</span>

														</template>
														<span>Please review this question and add any required
															images,figures,
															etc.</span>
													</v-tooltip>
												</v-col>
										
												<v-col cols="12" sm="9" class="ma-0 pa-0 ml-3 "
													v-if="$vuetify.display.xs">
													<v-row align="start" class="ma-0 pa-0">
														<v-col v-if="currentQ.difficulty != null" cols="auto"
															class="mx-3 mx-sm-5 my-1 my-sm-0">
															<v-tooltip :text="currentQ.difficulty.trim()"
																location="bottom">
																<template v-slot:activator="{ props }">
																	<v-row align="center" v-bind="props">
																		<v-icon size="24"
																			class="icon-opacity">mdi-podium</v-icon>
																		<span class="ml-1 qNoClass">
																			{{ currentQ.difficulty.trim() }}
																		</span>
																	</v-row>
																</template>
															</v-tooltip>

														</v-col>
														<v-col v-if="currentQ.categoryName != null" cols="auto"
															class="mx-3 mx-sm-5 my-1 my-sm-0">
															<v-tooltip :text="currentQ.categoryName.trim()"
																location="bottom">
																<template v-slot:activator="{ props }">
																	<v-row align="center" v-bind="props">
																		<v-icon size="24"
																			class="icon-opacity">mdi-book-open-blank-variant</v-icon>
																		<span class="ml-1 qNoClass text-truncate"
																			style="max-width: 200px ;"> {{
																				currentQ.categoryName.trim() }}
																		</span>
																	</v-row>
																</template>
															</v-tooltip>
														</v-col>

														<v-col v-if="currentQ.topicName != null" cols="auto"
															class="mx-3 mx-sm-5 my-1 my-sm-0">
															<v-tooltip :text="currentQ.topicName.trim()"
																location="bottom">
																<template v-slot:activator="{ props }">
																	<v-row align="center" v-bind="props">

																		<v-icon size="24"
																			class="icon-opacity">mdi-book-open-variant</v-icon>
																		<span class="ml-1 qNoClass text-truncate"
																			style="max-width: 200px ;">
																			{{ currentQ.topicName.trim() }}
																		</span>
																	</v-row>
																</template>
															</v-tooltip>
														</v-col>
														<v-col v-if="currentQ.concepts?.length > 0" cols="auto"
															class="mx-3 mx-sm-5 my-1 my-sm-0">
															<v-tooltip :text="currentQ.concepts[0]['label'].trim()"
																location="bottom">
																<template v-slot:activator="{ props }">
																	<v-row align="center" v-bind="props">

																		<v-icon size="24"
																			class="icon-opacity">mdi-book-open-variant</v-icon>
																		<span class="ml-1 qNoClass text-truncate"
																			style="max-width: 200px ;">
																			{{ currentQ.concepts[0]['label'].trim() }}
																		</span>
																	</v-row>
																</template>
															</v-tooltip>
														</v-col>
													</v-row>
												</v-col>
											</v-row>

										</v-col>

										<v-col cols="12" sm="9" class="ma-0 pa-0 " v-if="!$vuetify.display.xs">
											<v-row align="start" class="ma-0 pa-0">
												<v-col v-if="currentQ.difficulty != null" cols="auto"
													class="mx-3 mx-sm-5 my-1 my-sm-0">
													<v-tooltip :text="currentQ.difficulty.trim()" location="bottom">
														<template v-slot:activator="{ props }">
															<v-row align="center" v-bind="props">
																<v-icon size="24"
																	class="icon-opacity">mdi-podium</v-icon>
																<span class="ml-1 qNoClass">
																	{{ currentQ.difficulty.trim() }}
																</span>
															</v-row>
														</template>
													</v-tooltip>
												</v-col>
												<v-col v-if="currentQ.categoryName != null" cols="auto"
													class="mx-3 mx-sm-5 my-1 my-sm-0">
													<v-tooltip :text="currentQ.categoryName.trim()" location="bottom">
														<template v-slot:activator="{ props }">
															<v-row align="center" v-bind="props"> <v-icon size="24"
																	class="icon-opacity">mdi-book-open-blank-variant</v-icon>
																<span class="ml-1 qNoClass text-truncate"
																	style="max-width: 200px ;"> {{
																		currentQ.categoryName.trim() }}
																</span>
															</v-row>
														</template>
													</v-tooltip>
												</v-col>

												<v-col v-if="currentQ.topicName != null" cols="auto"
													class="mx-3 mx-sm-5 my-1 my-sm-0">
													<v-tooltip :text="currentQ.topicName.trim()" location="bottom">
														<template v-slot:activator="{ props }">
															<v-row align="center" v-bind="props">
																<v-icon size="24"
																	class="icon-opacity">mdi-book-open-variant</v-icon>
																<span class="ml-1 qNoClass text-truncate"
																	style="max-width: 200px ;">
																	{{ currentQ.topicName.trim() }}
																</span>
															</v-row>
														</template>
													</v-tooltip>
												</v-col>

												<v-col v-if="currentQ.concepts?.length > 0" cols="auto"
													class="mx-3 mx-sm-5 my-1 my-sm-0">
													<v-tooltip :text="currentQ.concepts[0]['label'].trim()"
														location="bottom">
														<template v-slot:activator="{ props }">
															<v-row align="center" v-bind="props">
																<v-icon size="24"
																	class="icon-opacity">mdi-book-open-variant</v-icon>
																<span class="ml-1 qNoClass text-truncate"
																	style="max-width: 450px ;">
																	{{ currentQ.concepts[0]['label'].trim() }}
																</span>
															</v-row>
														</template>
													</v-tooltip>
												</v-col>
											</v-row>
										</v-col>

										<v-spacer> </v-spacer>
										<v-col v-if="!fromCollections" cols="2" sm="1" class="ma-0 pa-0">
											<v-row :class="$vuetify.display.smAndUp ? 'mt-0' : 'mt-1'">
												<v-tooltip text="Remove question" location="end">
													<template v-slot:activator="{ props }">
														<v-btn v-if="assignmentType == 'practice' && (isUserTeacher || isUserAdmin)" size="small" v-bind="props" variant="outlined"
															class="outline-color mr-2" @click="showRemoveDailog()"
															icon="mdi-delete" ></v-btn>
													</template>
												</v-tooltip>
												<v-tooltip :text="feedbackOrQuestionReiewIconTooltipText" location="end">
													<template v-slot:activator="{ props }">
														<v-btn size="small" v-bind="props" variant="outlined"
															class="outline-color" @click="showReportDialog()"
															:icon="feedbackOrQuestionReiewIconName"></v-btn>
													</template>
												</v-tooltip>
											</v-row>
										</v-col>
										<v-col v-if="fromCollections || currentQ.isAIQuestion" cols="2" sm="1"
											class="ma-0 pa-0">
											<v-tooltip location="end">
												<template v-slot:activator="{ props }">
													<v-btn id="more-menu-activator" size="small" v-bind="props"
														variant="outlined" class="outline-color"
														icon="mdi-dots-vertical"></v-btn>
												</template>
												<span>Menu</span>
											</v-tooltip>

											<v-menu activator="#more-menu-activator">
												<v-list>
													<!-- Edit Question -->
													<v-list-item @click="editQuestion(currentQ)">
														<template v-slot:prepend>
															<v-icon icon="mdi-pencil"></v-icon>
														</template>
														<v-list-item-title>Edit Question</v-list-item-title>
													</v-list-item>
													<!-- Delete Question -->
													<v-list-item @click="openDeleteDialog(currentQ.qId, testId)">
														<template v-slot:prepend>
															<v-icon icon="mdi-delete"></v-icon>
														</template>
														<v-list-item-title>Delete Question</v-list-item-title>
													</v-list-item>
													<!-- Question Feedback -->
													<v-list-item @click="showReportDialog(currentQ.isAIQuestion)">
														<template v-slot:prepend>
															<v-icon :icon="feedbackOrQuestionReiewIconName"></v-icon>
														</template>
														<v-list-item-title>Feedback</v-list-item-title>
													</v-list-item>
												</v-list>
											</v-menu>
										</v-col>


									</v-row>

								</div>
								<section>

									<div>

										<HtmlRender :htmlString="currentQ.qText
											" class="htmlClassTest"></HtmlRender>
									</div>
								</section>

								<section>
									<div>
										<HtmlRender :htmlString="currentQ.qTextForSecondColumn"
											class="htmlClassTest mt-1">
										</HtmlRender>
									</div>
								</section>


								<div class="mt-15">


									<!-- <v-row class="mt-4 mb-10 mx-1" v-if="currentQ.isCorrect == null">
										<v-alert color="info" icon="$info" variant="tonal"
											text="This question was not attempted"></v-alert>
									</v-row> -->
									<!-- case 1 -->
									<section v-if="currentQ.qType == '1'">
										<v-radio-group v-model="currentQ.userAnswerSingleChoice" readonly>

											<v-row v-for="option in currentQ.options" :key="option.key">
												<v-col class="mb-0 pb-0" cols="12"
													v-if="!userAnswerSingleChoice(currentQ, option.key) && correctAnswerSingleChoice(currentQ, option.key) && showSolution">

													<span class="text-green">
														Correct Answer
													</span>

												</v-col>
												<v-col cols="12">
													<v-hover v-slot="{ isHovering, props }">

														<v-sheet class="bx" :class="{
															'on-hover': isHovering,
															'selected': userAnswerSingleChoice(currentQ, option.key) && !showSolution,
															'green-border': correctAnswerSingleChoice(currentQ, option.key) && showSolution,
															'red-border': !correctAnswerSingleChoice(currentQ, option.key) && userAnswerSingleChoice(currentQ, option.key) && showSolution
														}" v-bind="props">

															<v-radio readonly density="compact" color="primary"
																class="ma-0 pa-0 w-100  custom-radio" dense
																:value="option.key">

																<template v-slot:label>
																	<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																		<v-col class="ma-0 pa-0" cols="auto">
																			<v-avatar elevation="2" size="25"
																				class="t-h6  outline-color-grey"
																				:class="{ 'outline-color-white  text-primaryPurple': userAnswerSingleChoice(currentQ, option.key) && !showSolution }"
																				variant="outlined">
																				{{ option.key.toUpperCase() }}
																			</v-avatar>
																		</v-col>
																		<v-col class="ma-0 pa-0">
																			<HtmlRender
																				class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
																				:htmlString="option.value">
																			</HtmlRender>
																		</v-col>

																	</v-row>

																</template>
															</v-radio>
														</v-sheet>
													</v-hover>
												</v-col>

											</v-row>

										</v-radio-group>
									</section>


									<section v-if="currentQ.qType == '2'">

										<v-row v-for="option in currentQ.options" :key="option.key">
											<v-col class="mb-0 pb-0" cols="12"
												v-if="!userAnswerMultipleChoice(currentQ, option.key) && correctAnswerMultiChoice(currentQ, option.key) && showSolution">

												<span class="text-green">
													Correct Answer
												</span>

											</v-col>
											<v-col cols="12">
												<v-hover v-slot="{ isHovering, props }">

													<v-sheet v-bind="props" class="bx pa-0 ma-0" :class="{
														'on-hover': isHovering,
														'selected': userAnswerMultipleChoice(currentQ, option.key) && !showSolution,
														'green-border': correctAnswerMultiChoice(currentQ, option.key) && showSolution,
														'red-border': !correctAnswerMultiChoice(currentQ, option.key) && userAnswerMultipleChoice(currentQ, option.key) && showSolution
													}">
														<v-checkbox density="compact" hide-details
															v-model="currentQ.userAnswers" readonly color="primary"
															class="testOption  custom-radio" :value="option.key">
															<template v-slot:label>
																<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																	<v-col class="ma-0 pa-0" cols="auto">
																		<v-avatar elevation="2" size="25"
																			class="t-h6  outline-color-grey"
																			:class="{ 'outline-color-white  text-primaryPurple': userAnswerMultipleChoice(currentQ, option.key) && !showSolution }"
																			variant="outlined">
																			{{ option.key.toUpperCase() }}
																		</v-avatar>
																	</v-col>
																	<v-col class="ma-0 pa-0">
																		<HtmlRender
																			class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100"
																			:htmlString="option.value">
																		</HtmlRender>
																	</v-col>

																</v-row>

															</template>
														</v-checkbox>
													</v-sheet>
												</v-hover>
											</v-col>

										</v-row>

									</section>

									<section v-if="currentQ.qType == '3'">

										<v-row
											v-if="(currentQ.isCorrect == null || !currentQ.isCorrect) && showSolution">
											<v-col class="mb-0 pb-0 text-green" cols="12">
												<span>
													Correct Answer
												</span>
											</v-col>
											<v-col cols="8" md="3" lg="2">
												<v-text-field readonly v-model="currentQ.answerInteger" label="Answer"
													variant="outlined" color="green" focused
													base-color="green"></v-text-field>

											</v-col>
										</v-row>

									</section>

									<section v-if="currentQ.qType == '4'">

										<v-row v-if="showSolution">
											<v-col class="mb-0 pb-0 text-green" cols="12">
												<span>
													Correct Answer
												</span>
											</v-col>
											<v-col cols="8" md="3" lg="2">
												<v-text-field readonly v-model="correctAnswerDecimal" label="Answer"
													variant="outlined" color="green" focused></v-text-field>

											</v-col>
										</v-row>

									</section>
								</div>



							</section>



							<section class="mx-4 pb-5" v-if="currentQ.solution != null && showSolution">
								<v-row class="t-h5 mt-4 ">
									<v-col cols="12">
										Solution
									</v-col>

								</v-row>
								<v-row>

									<div>
										<v-col cols="12">

											<HtmlRender :htmlString="currentQ.solution
												" class="htmlClassTest"></HtmlRender>
										</v-col>

									</div>
								</v-row>

							</section>
						</v-card>
					</v-col>
				</v-row>
			</v-container>

		</div>

		<v-footer v-if="loadedFromServer" app class="bgWhite px-4 pt-4 pb-6 ma-0" elevation="2">
			<v-row justify="space-between" class=" footer-row px-5 align-center">

				<v-col class="align-center">
					<v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-prev"
						:disabled="prevButtonDisabled" @click="prevQuestion"
						:size="$vuetify.display.smAndUp ? 'large' : 'default'"
						:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0"
						:color="!prevButtonDisabled ? 'secondaryBackground' : undefined">
						Previous
						<template v-slot:prepend>
							<v-icon>mdi-chevron-left</v-icon>
						</template>
					</v-btn>

					<v-btn v-else density="compact" size="large" icon="mdi-chevron-left" :disabled="prevButtonDisabled"
						@click="prevQuestion">
					</v-btn>

				</v-col>



				<v-col cols="auto">
					<v-btn @click="questionDialog = true" color="secondaryBackground" density="compact" elevation="0"
						size="large" :icon="questionDialog == true ? 'mdi-chevron-down' : 'mdi-chevron-up'"></v-btn>
					<span class="ml-2">
						{{ currentQNo }} of {{
							currentTestResultJson[currentTestState.sectionIndex].questions.length }}
					</span>
				</v-col>

				<v-col class="text-end  align-center">
					<v-btn v-if="$vuetify.display.smAndUp"
						class=" t-btn text-capitalize btn-grad  text-primaryTextColor"
						:size="$vuetify.display.smAndUp ? 'large' : 'default'"
						:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0" @click="nextQuestion">
						{{ nextButtonDisabled ? 'Close' : 'Next' }}
						<template v-slot:append v-if="!nextButtonDisabled">
							<v-icon color="white">mdi-chevron-right</v-icon>
						</template>
					</v-btn>
					<v-btn v-else density="compact" size="large" icon="mdi-chevron-right" @click="nextQuestion"
						color="primaryPurple">
					</v-btn>
				</v-col>
			</v-row>
		</v-footer>
		<v-snackbar v-model="showDeleteMessage" :timeout="-1" color="cardBackgroundColor">
			<span v-if="isDeleteSuccessful" class="submit-message-text text-easyColor">{{ deleteMessage }}</span>
			<span v-if="!isDeleteSuccessful" class="submit-message-text text-report-red">{{ deleteMessage }}</span>
			<template v-slot:actions>
				<v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
			</template>
		</v-snackbar>
		<v-snackbar v-model="showQuestionSnackbar" :timeout="-1" color="cardBackgroundColor" location="bottom">
			<span class="text-easyColor">{{ questionSnackbarMsg }}</span>
			<template v-slot:actions>
				<v-btn density="compact" color="#8e8ea7" @click.stop="showQuestionSnackbar = false;"
					icon="mdi-close"></v-btn>
			</template>
		</v-snackbar>
		<v-snackbar v-model="deleteQuestionSnakckbar" :timeout="-1" color="cardBackgroundColor" location="bottom">
			<span class="submit-message-text text-report-red">{{ deleteQuestionSnakckbarMsg }}</span>
			<template v-slot:actions>
				<v-btn density="compact" color="#8e8ea7" @click.stop="deleteQuestionSnakckbar = false;"
					icon="mdi-close"></v-btn>
			</template>
		</v-snackbar>

	</div>

</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import EditQuestionComponent from "./EditQuestionComponent.vue";
import { getLoggedInUserCurrentDomain,  } from "@/services/authService";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
	return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
	name: 'ViewQuestionsComponent',
	props: {
		questionsData: {
			type: Object,
		},
		fromCollections: {
			type: Boolean,
			default: false,
		},
		collectionId: {
			type: String,
			default: "",
		},
		testId: {
			type: String,
			default: "",
		},
		assignmentType: {
			type: String,
			default: ""
		},
		assignmentId: {
			type: String,
			default: ""
		}
	},
	data: () => ({
		questionDialog: false,
		loadedFromServer: false,
		exitDialog: false,
		// testId: "",
		testInstanceId: "",
		reportDialog: false,
		isFromReport: false,
		testType: "",
		currentMode: "",
		annotatedText: "",
		showFilter: false,
		showSolution: true,
		editedCollection: {
			collectionId: "",
			collectionName: "",
			aiQuestionIds: [],
			aiQuestionIdsToBeAdded: [],
			aiQuestionIdsToBeRemoved: [],
			toolId: "",
			createMetaData: {}
		},
		defaultCollection: {
			collectionId: "",
			collectionName: "",
			aiQuestionIds: [],
			aiQuestionIdsToBeAdded: [],
			aiQuestionIdsToBeRemoved: [],
			toolId: "",
			createMetaData: {}
		},
		dialogDelete: false,
		showDeleteMessage: false,
		deleteMessage: "",
		isDeleteSuccessful: false,
		editedItem: {
			"questionPartitionKey": "",
			"aiQuestionId": "",
			"questionDesc": "wwwwww",
			"questionTypeId": "",
			"a": "222as",
			"b": "",
			"c": "",
			"d": "",
			"answerSingleChoice": "",
			"difficultyLevel": "",
			"domainId": "",
			"insertedBy": "",
			"insertedTimeStamp": 0,
			"categoryId": "",
			"solutionText": "",
			"subjectId": "",
			"topicId": ""
		},
		defaultItem: {
			"questionPartitionKey": "",
			"aiQuestionId": "",
			"questionDesc": "",
			"questionTypeId": "",
			"a": "",
			"b": "",
			"c": "",
			"d": "",
			"answerSingleChoice": "",
			"difficultyLevel": "",
			"domainId": "",
			"insertedBy": "",
			"insertedTimeStamp": 0,
			"categoryId": "",
			"solutionText": "",
			"subjectId": "",
			"topicId": ""
		},
		editQuestionItem: {},
		openEditQuestionDialog: false,
		removeQuestionConfirmationDialog: false,
		deleteQuestionSnakckbar: false,
		deleteQuestionSnakckbarMsg: "",
		isAIQuestion: false,
		showQuestionSnackbar: false,
		questionSnackbarMsg: '',
	}),
	components:
	{
		HtmlRender,
		DragableFeedbackDialog,
		EditQuestionComponent
	},
	async mounted() {
		this.showSolution = true;
		this.$store.commit('updateCurrentTestState', {
			sectionIndex: 0,
			qIndex: 0
		})
		this.$store.commit('clearTestResult')
		await this.$store.commit("loadTestResult", this.questionsData)
		this.loadedFromServer = true;
		setTimeout(() => {
			window.MathJax.typesetPromise();

		}, 10);

	},
	async created() {
		console.log("qdata", this.questionsData);
		this.$store.commit('appbarVisible', false);
		this.$store.commit('navbarVisible', false);
		this.$store.commit("showNotification", false);
		if (window.document.getSelection) {
			document.addEventListener('selectionchange', (e) => {
				this.annotatedText = window.getSelection().toString().trim().replace(" ", "")

			});
		}

	},
	computed: {
		...mapState(["currentTestResult", "currentTestResultJson", "currentTestState", "newLearnNodeActivity", "currentActivityId", "currentTopicId", "config", "isUserTeacher", "isUserAdmin"]),
		...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
		testInstanceQuestionSummary: {
			get() {
				return this.currentTestResult.testInstanceQuestionSummary
			}
		},
		currentQNo: {
			get() {
				return this.currentTestState.qIndex + 1
			}
		},
		currentQ: {
			get() {
				if (this.currentTestResultJson.length == 0) {
					return []
				}
				return this.currentTestResultJson[this.currentTestState.sectionIndex].questions[this.currentTestState.qIndex]
			}
		},
		currentSection: {
			get() {
				return this.currentTestResultJson[this.currentTestState.sectionIndex]
			}
		},
		prevButtonDisabled: {
			get() {
				return this.currentTestState.qIndex == 0 && this.currentTestState.sectionIndex == 0 ? true : false
			}
		},
		nextButtonDisabled: {
			get() {
				return this.currentTestState.qIndex ==
					this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length - 1 &&
					this.currentTestState.sectionIndex == 0
					? true
					: false;
			},
		},
		correctAnswerDecimal: {
			get() {
				if (this.currentQ.answerDecimalHigh == this.currentQ.answerDecimalLow) {
					return this.currentQ.answerDecimalHigh
				}
				return `${this.currentQ.answerDecimalHigh} - ${this.currentQ.answerDecimalLow}`;
			},
		},
		feedbackOrQuestionReiewIconName: {
			get() {
				var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
				if (this.isQuestionReviewer) {
					retVal = "mdi-shield-check-outline"; // for internal Question reviewers
				}
				return retVal;
			},
		},
		feedbackOrQuestionReiewIconTooltipText: {
			get() {
				var retVal = "Report a problem" ////"Report a Problem" for users/students
				if (this.isQuestionReviewer) {
					retVal = "Review Feedback & Corrections"; // for internal Question reviewers
				}
				return retVal;
			},
		},
	},

	watch: {
		currentTestState: {
			handler() {
				console.log("currentTestState")
				const element = document.getElementsByClassName('solution-height');
				if (element && element[0]) {
					element[0].scrollIntoView({
						behavior: "smooth",
					})
				}

			},
			deep: true
		}
	},

	methods: {
		getCategoryName(id) {
			let data = ""
			let found = this.categoryList.find((e) => e.key == id)
			if (found) {
				data = found.label ?? ""

			} return data
		},
		getTopicName(id) {
			let data = ""
			let found = this.topicList.find((e) => e.topicKey == id)
			if (found) {
				data = found.label ?? ""

			}
			return data
		},

		annotate() {
			if (window.document.getSelection) {
				let selected = window.document.getSelection().toString()
				return selected
			}
			return ''
		},
		showReportDialog(isAIQuestion = false) {

			// recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
			//   testId: this.currentTestId,
			//   testName: this.startTestInfo.testmetadata.testName,
			//   testInstanceId: this.startTestInfo.testInstanceId,
			//   sectionId: this.currentSection.sectionId,

			// });
			this.isAIQuestion = isAIQuestion;
			this.reportDialog = true;
		},
		showRemoveDailog(){
			this.removeQuestionConfirmationDialog = true;
		},
		correctAnswerSingleChoice(item, key) {
			if (item.answerSingleChoice == null) {
				return false
			}
			if (item.answerSingleChoice == key) {
				return true
			}
			return false
		},
		userAnswerSingleChoice(item, key) {
			if (item.userAnswerSingleChoice == null) {
				return false
			}
			if (item.userAnswerSingleChoice == key) {
				return true
			}
			return false
		},
		correctAnswerMultiChoice(item, key) {

			if (item.answers == null) {
				return false
			}
			if (item.answers.includes(key)) {
				return true
			}
			return false
		},
		userAnswerMultipleChoice(item, key) {
			if (item.userAnswers == null) {
				return false
			}
			if (item.userAnswers.includes(key)) {
				return true
			}
			return false
		},

		async openQFromDialog(sIndex, qIndex) {
			this.$store.commit('updateCurrentTestState', {
				qIndex: qIndex,
				sectionIndex: sIndex
			})

			this.questionDialog = false

			setTimeout(() => {
				window.MathJax.typesetPromise();

			}, 10)
		},
		getMarkDownHtml(markdown) {
			if (markdown == null) {
				return ""
			}
			let markDownText = markdown
			let out = false;
			const regex = /\\/gm;
			const escapedString = markDownText.replace(regex, "\\\\");

			out = marked.parseInline(escapedString, { renderer });

			return out;
		},
		async nextQuestion() {


			if (this.currentTestState.qIndex == this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length - 1) {

				if (this.currentTestState.sectionIndex == this.currentTestResultJson.length - 1) {
					this.backFromDialog();
					return;
				}
				else {
					this.$store.commit('updateCurrentTestState', {
						qIndex: 0,
						sectionIndex: this.currentTestState.sectionIndex + 1
					});
				}
			}
			else {

				this.$store.commit('updateCurrentTestState', {
					qIndex: this.currentTestState.qIndex + 1,
					sectionIndex: this.currentTestState.sectionIndex
				});
			}


			setTimeout(() => {
				window.MathJax.typesetPromise();

			}, 10)
			console.log(this.currentQ);
		},
		async prevQuestion() {


			if (this.currentTestState.qIndex == 0) {

				if (this.currentTestState.sectionIndex == 0) {
					console.log("first section");
				}
				else {
					this.$store.commit('updateCurrentTestState', {
						qIndex: this.currentTestResultJson[this.currentTestState.sectionIndex - 1].questions.length - 1,
						sectionIndex: this.currentTestState.sectionIndex - 1
					})
				}
			}
			else {

				// changes ui to next question
				this.$store.commit('updateCurrentTestState', {
					qIndex: this.currentTestState.qIndex - 1,
					sectionIndex: this.currentTestState.sectionIndex
				})
			}

			setTimeout(() => {
				window.MathJax.typesetPromise();

			}, 10)
		},
		backFromDialog() {
			console.log("Close");
			this.$emit("closeDialog");
			this.$store.commit('appbarVisible', true);
			this.$store.commit('navbarVisible', true);
		},
		toggleFilter() {
			this.showFilter = !this.showFilter;
		},
		toggleSolution(item) {
			this.showSolution = item;
		},
		openDeleteDialog(questionId, testId) {
			console.log("collectestid", testId);
			this.editedCollection.aiQuestionIdsToBeRemoved = [questionId];
			this.editedCollection.collectionId = this.collectionId;
			this.dialogDelete = true;
		},
		closeDeleteDialog() {
			this.deleteItem = _.cloneDeep(this.defaultDeleteItem);
			this.dialogDelete = false;
		},
		deleteQuestion() {
			this.editedCollection = _.omit(this.editedCollection, ['aiQuestionIds', 'toolId']);
			this.$store.dispatch('editCollection', this.editedCollection).then((res) => {
				if (res.success) {
					// this.nextQuestion();./
					// if (this.currentTestState.qIndex == this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length - 1)
					if (this.currentTestState.qIndex == this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length - 1) {
						if (this.currentTestState.sectionIndex == this.currentTestResultJson.length - 1) {
							this.backFromDialog();
							return;
						}
						else {
							this.$store.commit('updateCurrentTestState', {
								qIndex: 0,
								sectionIndex: this.currentTestState.sectionIndex + 1
							});
						}
					}
					else {
						this.currentTestResultJson[this.currentTestState.sectionIndex].questions.splice(this.currentTestState.qIndex, 1);
					}
					this.deleteMessage = "Question Deleted Successfully";
					this.isDeleteSuccessful = true;
					this.showDeleteMessage = true;
					this.closeDeleteDialog();
				}
				else {
					this.deleteMessage = "Could not delete the question.";
					this.isDeleteSuccessful = false;
					this.showDeleteMessage = true;
				}
			})

		},
		closeSnackBar() {
			this.showDeleteMessage = false;
			this.deleteMessage = "";
			this.isDeleteSuccessful = false;
		},
		async editQuestion(item) {
			await this.$store.dispatch("loadEntities");
			console.log("here edq", item);
			let x = this.createEditableQuestionItem(item)
			this.editQuestionItem = Object.assign({}, x);
			console.log("here edq", item, this.questionItem);
			this.openEditQuestionDialog = true;
		},
		saveQuestionResponse(questionItem) {
			// var foundQ = this.messages.find(x => (x.type == 'question') && (x.questionItem.aiQuestionId == questionItem.aiQuestionId));
			let x = this.createViewQuestionItem(this.currentTestResultJson[this.currentTestState.sectionIndex].questions[this.currentTestState.qIndex], questionItem)
			console.log("xxx", x);
			this.currentTestResultJson[this.currentTestState.sectionIndex].questions[this.currentTestState.qIndex] = Object.assign({}, x);
			// console.log("foundq", foundQ);
			// Object.assign(foundQ.questionItem, questionItem);
		},
		closeQuestionDialog() {
			this.openEditQuestionDialog = false;
			this.questionItem = {};
		},
		createEditableQuestionItem(item) {
			let editedItem = {
				"aiQuestionId": item.qId,
				"questionDesc": item.qText,
				"questionTypeId": item.qType,
				"a": item.options.find((e) => e.key == 'a').value,
				"b": item.options.find((e) => e.key == 'b').value,
				"c": item.options.find((e) => e.key == 'c').value,
				"d": item.options.find((e) => e.key == 'd').value,
				"answerSingleChoice": item.answerSingleChoice,
				"answerDecimalHigh": item.answerDecimalHigh,
				"answerDecimalLow": item.answerDecimalLow,
				"answerInteger": item.answerInteger,
				"difficultyLevel": item.difficultyLevel,
				"difficulty": item.difficulty,
				"domainId": getLoggedInUserCurrentDomain(),
				"categoryId": item.categoryId,
				"solutionText": item.solution,
				"subjectId": item.subjectId,
				"topicId": item.topicId,
				"topicName": item.topicName,
				"categoryName": item.categoryName,
				"questionTextForSecondColumn": item.qTextForSecondColumn ?? ''
			}
			return editedItem
		},
		createViewQuestionItem(currentQ, item) {

			console.log(("item from aialof", item));
			let x = _.cloneDeep(currentQ)

			x.answerSingleChoice = item.answerSingleChoice
			x.answerInteger = item.answerInteger,
				x.answerDecimalHigh = item.answerDecimalHigh,
				x.answerDecimalLow = item.answerDecimalLow,
				x.difficultyLevel = item.difficultyLevel
			x.difficulty = item.difficulty
			x.qText = item.questionDesc
			x.qTextForSecondColumn = item.questionTextForSecondColumn ?? ''
			x.options[0].value = item.a
			x.options[1].value = item.b
			x.options[2].value = item.c
			x.options[3].value = item.d
			x.categoryId = item.categoryId
			x.solution = item.solutionText
			x.subjectId = item.subjectId
			x.topicId = item.topicId
			x.topicName = item.topicName
			x.categoryName = item.categoryName
			x.qType = item.questionTypeId

			return x

		},
		updatequestionSnackbarMsg(msg) {
			this.questionSnackbarMsg = msg;
		},
		removeAssignmentQuestion(){
			let reqData = {
				assignmentId: this.assignmentId,
				questionId: this.currentQ.qId,
				testId: this.testId
			};
			this.$store.dispatch('removeAssignmentQuestion', reqData).then((res) => {
				if(res.success){
					if (this.currentTestState.qIndex == this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length - 1) {
						if (this.currentTestState.sectionIndex == this.currentTestResultJson.length - 1) {
							this.backFromDialog();
							return;
						}
						else {
							this.$store.commit('updateCurrentTestState', {
								qIndex: 0,
								sectionIndex: this.currentTestState.sectionIndex + 1
							});
						}
					}
					else {
						this.currentTestResultJson[this.currentTestState.sectionIndex].questions.splice(this.currentTestState.qIndex, 1);
					}
					this.removeQuestionConfirmationDialog = false;
				}else{
					this.removeQuestionConfirmationDialog = false;
					this.deleteQuestionSnakckbarMsg = res.message;
					this.deleteQuestionSnakckbar = true;
				}
			});
		}
	},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.correct {
	background: #26B99AA6 !important;
	color: white !important;
	;
}

.success {
	background: #26B99AA6 !important;

}

.timer-color {
	color: #26B99AA6 !important;
}

.unanswered {
	border: 2px solid #949494 !important;
}

.marked {
	border: 2.5px solid #FF894F !important;
}

.option {

	/* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
	border-radius: 10px;
	border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.v-sheet.on-hover {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: white;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.green-border {
	border: 2.5px solid green !important;
}

.red-border {
	border: 2.5px solid #ff8a80 !important;
}

.incorrect {
	background: #ff8a80 !important;
	color: white !important;
}

.btn-grad {
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
	border-radius: 29.4079px;
}

.btn-prev {
	border-radius: 29.4079px;
}

.bgWhite {
	background-color: white !important
}

.unattempted {
	border: 2px solid #949494 !important;
}

.bx {
	background-color: rgb(var(--v-theme-navColor));
	border: 1px solid #E7E7E7;
	border-radius: 4px;
}

.card-bg-color {
	background-color: rgb(var(--v-theme-navColor)) !important;
	border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
	border-radius: 12px !important;

}

.outline-color {
	border-color: rgb(var(--v-theme-secondaryBackground)) !important;
	;

}

.bottom {
	position: absolute !important;
	bottom: 10px !important;
}

.w-70 {
	max-width: 70%;
}

.v-overlay__content {
	position: absolute !important;
	bottom: 10px !important;

}

.bgW {
	height: 100%;
	width: 100%;
	background-color: white;
}

.scroll {}

.dialog-bx {
	position: fixed !important;
}

.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
}

.icon-opacity {
	opacity: 0.4;
	/* color: #A7A6B0; */
}

.mw {
	max-width: 150px;
}

.qNoClass {
	font-size: 0.875rem;
	font-family: "Inter", sans-serif !important;

}

/* chatbot css */

.v-textarea input::placeholder {
	color: red;
	/* Your desired color */
}


.btn-rd {
	border-radius: 100px !important;
	border: 1.5px solid #966DEC;
	background: #FFF;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12)
}

.bot-msg {
	background: rgba(230, 228, 233, 0.46);
}

.user-msg {
	/* background-color: rgb(var(--v-theme-primary)) !important;
	border-radius: 30px; */

}

.dround {
	border-radius: 10px !important;
}

/* Hide scrollbar for IE, Edge and Firefox */


.dialog-rounded .overlay-scrim {
	border-radius: 10px !important;
}

.noClick {
	pointer-events: none;
}

.w450px {
	width: 450px;
}

.w240px {
	width: 370px;
}

.slide-fade-enter-active {
	transition: all 0.3s linear;
}

.slide-fade-leave-active {
	transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(100px);
	opacity: 0;
}

.mento {
	color: #FEFEFE;
	font-family: Prompt;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	/* 24px */
}

.mind {
	color: #A174FD;
	font-family: Prompt;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	/* 24px */
	letter-spacing: 0.48px;
}

.testCard {
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
	background: rgb(var(--v-theme-cardBackgroundColor));
	cursor: pointer;
	border-radius: 0 !important;
}

.filter-switch {
	width: 160px !important;
}

.selected {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
	color: white !important
}

.outline-color-grey {
	border-color: #010101b3 !important;
	border: 1px solid;
	color: #010101b3 !important;

}

.outline-color-white {
	border-color: white !important;
	background-color: white;
}

.text-primaryPurple {
	color: rgb(var(--v-theme-primaryPurple)) !important;
}

.solution-height {
	padding-top: 60px;
	padding-bottom: 100px;
}

.solution-height-sm {
	padding-top: 40px !important;
}

.assignment-form-main {
	overflow: auto !important;
}

.assignment-form {
	/* background: rgb(var(--v-theme-primaryGrey)); */
	border-radius: 12px !important;
	/* border: 2px solid #c3c3c3; */
	border: 2px solid rgba(255, 255, 255, 0.22);
	/* background: #232238; */
	background-color: rgb(var(--v-theme-reportBgColor));
	height: 100%;
}

.assignment-form-title {
	background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
	border: 2px solid rgba(255, 255, 255, 0.22);
	color: white !important;
	background: rgb(var(--v-theme-cardBackgroundColor));
	border-radius: 29.4079px;
	width: 100px;
}

.delete-btn {
	border: 2px solid red !important;
}

.save-btn {
	color: white !important;
	border-radius: 29.4079px;
	width: 100px;
	background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}
</style>
<style>
.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
	opacity: 1 !important;
}

.v-label {

	opacity: 1 !important;
}




.custom-radio .v-selection-control__wrapper {
	display: none;
}




.inputText {
	color: black !important;

	/* 114.286% */
}







.dialog-rounded .v-overlay__scrim {
	border-radius: 10px !important;
}
</style>
