<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>

  <!-- parent component  -->
  <v-dialog
    v-model="showParentDetailsDialog"
    style="backdrop-filter: blur(12.5px)"
    width="400"
    persistent
  >
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="" justify="space-between" align="center">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar
                size="30"
                color="report-subCardBg"
                class="icon-bg ma-0 pa-0 mr-2"
              >
                <v-icon
                  icon="mdi-account"
                  color="#ffffff"
                  class=""
                  size="15"
                ></v-icon>
              </v-avatar>
              <span class="t-h125Reg wrap-text">Required: Parent Details</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="closeDialog()" class="mr-4">
                mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-text class="pa-6 pb-1 pt-4 student-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense justify="center">
            <v-col cols="12" class="mb-4">
              <span class="test-subtitle t-938remReg">
                Enter parent details to view your test report
              </span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Parent Name *"
                variant="outlined"
                v-model="editedItem.guardianName"
                :rules="requiredRule"
                density="compact"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Parent Email *"
                variant="outlined"
                v-model="editedItem.guardianEmail"
                :rules="emailRule"
                density="compact"
              >
              </v-text-field>
            </v-col>

            <v-col v-if="showErrorMsg" cols="12" class="errorMsg pb-6">
              <span>{{ errorMsg }}</span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="saveParentDetails"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Practice Report download Loader -->
  <v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="downloadingTestReport"
    class="align-center justify-center px-2">
    <v-card elevation="0" class="downloading-test-report d-flex justify-center align-center pa-4">
      <v-row v-if="!showInfoMessage" justify="center" align="center">
        <v-col cols="1" sm="auto">
          <v-avatar size="32" class="border-circle">
            <v-img height="20" :src="require('@/assets/download.svg')" />
          </v-avatar>
        </v-col>
        <v-col class="text-h6 text-white" cols="11" sm="10" :class="{'pa-0 pl-6': $vuetify.display.xs}">
          Downloading Test Report
        </v-col>
        <v-col class="mt-1" cols="10" >
          <v-progress-linear color="progress" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="10" class="">
          {{ infoMessage }}
        </v-col>
        <v-col cols="2" class="justify-end d-flex" >
          <v-icon end size="30"  color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-icon>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>

  <!-- Practice Loader -->
  <v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="downloadingTest"
    class="align-center justify-center px-2">
    <v-card elevation="0" class="start-quiz-loading d-flex justify-center align-center pa-2">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
            src="@/assets/mento_loading_icon.png"></v-img>
        </v-col>
        <v-col cols="12" class="start-quiz-loading-title text-center">
          {{ downloadingTestTitle }}
        </v-col>
        <v-col class="mt-2" cols="6">
          <v-progress-linear color="progress" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>

  <!-- Assignment Dialog -->
  <AssignmentFormDialog v-if="assignmentDialig" assignmentType="student" :studentId="$route.params.studentId" @closeAssignmentDialog="closeAssignment" @saveAssignmentDialog="saveAssignment" :assignmentFormDetails="assignmentItem"></AssignmentFormDialog>

  <v-container fluid class="ma-0 pa-4">
    <div v-if="showData && !captureParentDetails" :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
      <v-container class="px-0">
        <v-row>
          <v-col class="box-padding">
            <v-card elevation="0" class=" bx ">

              <v-card-text class="pa-3">
                <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>

                  <v-col cols="12">
                    <v-row align="center" justify="start" class="pb-sm-4">
                      <v-col>
                        <span class="text-white report-heading">
                          Test Report - {{ testInstancesData.testName }}
                        </span>
                      </v-col>

                      <v-col cols="12" sm="auto" class="pt-sm-0  pb-2 px-2 d-flex align-center">
                      <v-row class="py-0" >
                        <v-col cols="auto" class="pb-0">
                          <v-tooltip
                            location="top"
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  v-bind="props"
                                  density="default"
                                  variant="flat"
                                  class="download-pdf-btn"
                                  @click.stop="downloadPdf()"
                                  >
                                  <v-img :src="require('@/assets/download.svg')"></v-img>
                                </v-icon>
                            </template>
                            <span>Download Test Report</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="pb-0 pl-0">
                          <v-select bg-color="#26253A00" base-color="#ffffff" color="#ffffff" :focused="false"
                            hide-details="true" class="text-white selector" variant="solo" v-model="selectedTest"
                            :items="pastTestAttemptData" item-color="black" :item-title="item => getScoreDate(item)"
                            item-value="testInstanceId" density="compact" return-object single-line :flat="true"
                            elevation="0" @update:modelValue="selectTestInstance">
                          </v-select>
                        </v-col>
                      </v-row>
                      </v-col>
                    </v-row>
                    <v-divider v-if="$vuetify.display.mdAndUp" color="white" class="mt-0 mb-4"></v-divider>
                  </v-col>

                </v-row>

                <v-row v-if="$vuetify.display.mdAndUp" class="mt-0 pa-3" justify="space-between">
                  <v-col class="pa-0 item-gap" v-for="item in moduleList" :key="item.moduleId">
                    <v-card :id="item.moduleId" :class="item.moduleId == selectedModuleId ? 'recommended-border' : ''"
                      @click="selectModule(item)" elevation="0" class="sub-box h-100 pa-1">
                      <v-card-text class="pa-2">
                        <span class="category-heading">{{ item.moduleLabel }}</span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col cols="12">
                    <v-select bg-color="#26253A00" base-color="#ffffff" color="#ffffff" :focused="false"
                      hide-details="true" class="selector" item-color="black" variant="solo" v-model="selectedModule"
                      :items="moduleList" item-title="moduleLabel" item-value="moduleLabel" density="compact"
                      return-object single-line :flat="true" elevation="0" @update:modelValue="selectModule">
                    </v-select>
                  </v-col>
                </v-row>


              </v-card-text>

            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="selectedModuleId == 'Test Score'">
          <v-col cols="12">
            <v-row>
              <v-col class="box-padding">
                <v-card elevation="0" class="bx ">

                  <v-card-text class="pa-3 ma-0">
                    <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>

                      <v-col cols="12">
                        <v-row align="center" justify="start" class="pb-4">
                          <v-col>
                            <span class="text-white report-heading">
                              Overall Test Score
                            </span>
                          </v-col>
                        </v-row>

                      </v-col>

                    </v-row>

                    <v-row class="justify-md-space-between" justify="center" align="end">
                      <v-col cols="10" sm="5" md="4" lg="3">
                        <v-img src="@/assets/report_illustration.svg" />
                      </v-col>
                      <v-col cols="12" md="8" lg="9" class="mb-4 mb-sm-8 test-bar-height">

                        <TestBarComponent :lowerScore="testSummary.lowerScore" :upperScore="testSummary.upperScore"
                          isLarge="true" />

                      </v-col>


                    </v-row>
                  </v-card-text>

                </v-card>
              </v-col>
            </v-row>

            <!-- for loop from here -->
            <v-row>
              <v-col v-for="item in moduleSubjects" :key="item.subjectKey" cols="12" sm="6" class="box-padding">
                <v-card elevation="0" class="bx h-100">

                  <v-card-text class="pa-0 ma-3">
                    <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>

                      <v-col cols="12">
                        <v-row align="center" justify="start">
                          <v-col>
                            <span class="text-white report-heading d-flex align-center">
                              <v-icon class="text-progress mr-3 icon-height">mdi-book-open-variant</v-icon>
                              {{ item.subjectLabel }}
                            </span>
                          </v-col>

                          <v-col cols="12" class="test-bar-height">
                            <TestBarComponent :lowerScore=item.lowerScore :upperScore=item.upperScore isLarge="false" />
                          </v-col>

                          <v-col cols="12" class="mt-8">
                            <v-row justify="space-around" align="center" class="justify-sm-center">
                              <v-col class="px-1 py-2" cols="auto" @click="selectModuleBySubject(item, 'baseline')">
                                <span class="module-step  pa-2">Module 1</span>
                              </v-col>
                              <v-col class="px-1 py-2" cols="1">
                                <v-img src="@/assets/right_arrow.svg"></v-img>
                              </v-col>
                              <v-col class="px-1 py-2" cols="auto"
                                @click="selectModuleBySubject(item, getDifficulty(item.moduleDifficulty))">
                                <span class="module-step  pa-2">Module 2 
                                  <span v-if="getDifficulty(item.moduleDifficulty)">
                                    ({{
                                  getDifficulty(item.moduleDifficulty) }})
                                  </span>
                                  </span>
                              </v-col>
                            </v-row>

                            <v-row justify="center" class="pt-4">
                              <v-col class="">
                                <p class="extra-text">{{isUserTeacher? "Student's ": "Your "}} performance in Module 1 determines the
                                  difficulty level of Module 2</p>
                              </v-col>
                            </v-row>

                          </v-col>
                        </v-row>

                      </v-col>

                    </v-row>

                  </v-card-text>

                </v-card>
              </v-col>


            </v-row>
          </v-col>



        </v-row>



        <v-row v-else>
          <v-col cols="12" class="box-padding">
            <v-card elevation="0" class="bx">
              <v-card-text :class="xs ? 'pa-1' : 'pa-3'">
                <v-row class="mb-3" :class="{ 'mt-2': xs }">
                  <v-col>
                    <span class="text-white report-heading">How {{isUserParent? " your child " : isUserTeacher? " student ": " you "}} did by Difficulty</span>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>
                  <v-col lg="4" cols="12" class="py-2 px-0 px-md-1" v-for="(item, key) in selectedModule.difficulties"
                    :key="key">
                    <div class="donut-box donut-view w-100">
                      <div class="pie-chart-title"><span class="t-1remReg text-pieTitle">{{ textCapitalize(key) }}</span>
                      </div>
                      <pie-half-donut :data="getChartData(item)"></pie-half-donut>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="box-padding">
            <v-card elevation="0" class="bx">
              <v-card-text :class="xs ? 'pa-1' : 'pa-3'">
                <v-row class="mb-3" :class="{ 'mt-2': xs }">
                  <v-col>
                    <span class="text-white report-heading">How {{isUserParent? " your child " : isUserTeacher? " student ": " you "}} did, by Content domain</span>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>
                  <v-col cols="12" class="py-2 px-0" v-for="item in selectedModule.categories" :key="item.categoryId">
                    <v-card :id="item.categoryId" :class="item.categoryId == selectedCategoryId
                      ? 'recommended-border'
                      : ''
                      " elevation="0" class="item-box h-100">
                      <v-card-text class="pa-0">
                        <v-row @click="selectedCategory(item)" align="center" justify="space-between" class="pa-4">
                          <v-col cols="12" md="8" class="d-flex align-center justify-md-start">
                            <v-avatar class="mr-4" :color="item.categoryId == selectedCategoryId
                              ? 'primaryPurple'
                              : 'btn-bg'
                              " size="32">
                              <v-icon color="white">{{
                                item.categoryId == selectedCategoryId
                                ? "mdi-chevron-down"
                                : "mdi-chevron-right"
                              }}</v-icon>
                            </v-avatar>

                            <span class="text-white report-heading">{{
                              item.categoryDisplayName
                            }}
                              <span class="text-grey report-heading">{{
                                " (" +
                                item.topicBreakdown.length +
                                ")"
                              }}</span>
                            </span>


                          </v-col>
                          <v-col cols="12" md="auto">
                            <div class="d-flex" :class="{ 'justify-end': mdAndUp }">
                              <div class="mr-3">
                                <div class="text-white subtext-h4 text-center pb-2">
                                  {{
                                    getNumberFormat(item.correctQuestions)
                                  }}
                                </div>
                                <div class="d-flex align-center">
                                  <v-avatar color="report-green" size="14"></v-avatar>
                                  <div class="ml-2 text-white t-h6Reg">
                                    Correct
                                  </div>
                                </div>
                              </div>

                              <div class="mr-3">
                                <div class="text-white subtext-h4 text-center pb-2">
                                  {{
                                    getNumberFormat(item.incorrectQuestions)
                                  }}
                                </div>
                                <div class="d-flex align-center">
                                  <v-avatar color="report-red" size="14"></v-avatar>
                                  <div class="ml-2 text-white t-h6Reg">
                                    Incorrect
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div class="text-white subtext-h4 text-center pb-2">
                                  {{
                                    getNumberFormat(
                                      item.unansweredQuestions
                                    )
                                  }}
                                </div>
                                <div class="d-flex align-center">
                                  <v-avatar color="report-grey" size="14"></v-avatar>
                                  <div class="ml-2 text-white t-h6Reg">
                                    Skipped
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0 mb-1" align="center" dense v-if="item.categoryId == selectedCategoryId"
                          :class="xs ? 'mt-2' : 'mt-3'">
                          <v-col lg="4" cols="12" v-for="(topic, key) in item.topicBreakdown" :key="key">
                            <v-row class="donut-box ma-0 pa-0">
                              <v-col cols="12" class="donut-view w-100 pa-2">
                                <div class="pie-chart-title" :class="topic.skill ? 'text-primaryOrange' : 'text-pieTitle'">
                                  <span class="t-1remReg text-pieTitle">{{ topic.topicDisplayName + ' ' }} <strong
                                      v-if="topic.skill" class="t-h6 text-primaryOrange">{{ textCapitalize(topic.skill)
                                      }}</strong></span></div>
                                <pie-half-donut :data="getTopicChartData(topic)"
                                  :title="topic.topicDisplayName"></pie-half-donut>
                              </v-col>
                              <v-col cols="12" class="mb-4 py-0">
                                <v-btn prepend-icon="mdi-lightbulb-on"
                                  :append-icon="!topic.isAccessAllowed ? 'mdi-lock-outline' : undefined"
                                  class=" t-btn text-white activity-btn w-100 text-none text-bold"
                                  :class="{ 'disabled-practice': !topic.isReadyForMentoApp }"
                                  :disabled="!topic.isReadyForMentoApp" density="default" variant="flat"
                                  @click="!topic.isReadyForMentoApp ? null : (isUserTeacher? createAssignment(topic) : startPractice(topic))">
                                  <template v-slot:prepend>
                                    <v-icon color="white"></v-icon>
                                  </template>
                                  {{ `${isUserTeacher?'Assign more practice':'Practice'}${!topic.isReadyForMentoApp ? ' (Coming Soon) ' : ''}` }}
                                  <!-- <v-icon v-if="!topic.isAccessAllowed" size="25" color="primaryPurple">mdi-lock-outline</v-icon> -->
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="box-padding">
            <v-card elevation="0" class="bx">
              <v-card-text :class="xs ? 'pa-1' : 'pa-3'">
                <v-row class="mb-3" :class="{ 'mt-2': xs }">
                  <v-col cols="12" lg="8">
                    <div class="text-white report-heading">
                      Time Allocation Insights
                    </div>
                    <div class="text-white mt-2 category-headin t-h6Regg">
                      {{isUserParent? "Child's Performance": isUserTeacher? "Student's Performance ": "Optimize Your Performance "}} Through Comparative Question Time Analysis
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div class="d-flex" :class="{ 'justify-end': lgAndUp }">
                      <div class="d-flex align-center">
                        <v-avatar color="primaryPurple" size="14"></v-avatar>
                        <div class="ml-2 text-white t-h6Reg">{{isUserParent? "Your Child's ": isUserTeacher? "Student's ": "Your "}} Time</div>
                      </div>
                      <div class="ml-6 d-flex align-center">
                        <v-avatar color="report-orange" size="14"></v-avatar>
                        <div class="ml-2 text-white t-h6Reg">Others Time</div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>
                  <v-col cols="12">
                    <div class="line-bar-chart">
                      <line-bar-chart :data="getTimeAllocationData()"></line-bar-chart>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row justify="space-between" align="center">
              <v-col cols="auto">
                <v-btn v-if="!xs" prepend-icon="mdi-chevron-left"
                  class=" t-btn text-white activity-btn-2-width activity-btn-2 text-none text-bold" density="default"
                  @click="onPrevious()">
                  <template v-slot:prepend>
                    <v-icon color="white"></v-icon>
                  </template>
                  Prev Module
                </v-btn>
                <v-btn v-else size="36" icon="mdi-chevron-left"
                  class=" t-btn text-white activity-btn-2 text-none text-bold" density="default" @click="onPrevious()">
                </v-btn>
              </v-col>

              <v-col cols="auto">

                <v-btn prepend-icon="mdi-lightbulb-on" class=" t-btn text-white activity-btn text-none text-bold"
                  density="default" variant="flat" @click="viewSolution()">
                  <template v-slot:prepend>
                    <v-icon color="white"></v-icon>
                  </template>
                  Check Solution
                </v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn v-if="!xs" :disabled="!showRight" :class="{ 'disabled-btn': !showRight }"
                  append-icon="mdi-chevron-right"
                  class=" t-btn text-white activity-btn-2-width activity-btn-2 text-none text-bold" density="default"
                  @click="onNext()">
                  <template v-slot:append>
                    <v-icon class="" color="white"></v-icon>
                  </template>
                  Next Module
                </v-btn>
                <v-btn v-else size="36" :disabled="!showRight" :class="{ 'disabled-btn': !showRight }"
                  icon="mdi-chevron-right" class=" t-btn text-white activity-btn-2  text-none text-bold" density="default"
                  @click="onNext()">
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>







      </v-container>
    </div>
  </v-container>

  <!-- Dialog for not completed module -->
  <v-dialog
    style="backdrop-filter: blur(12.5px)"
    persistent
    :model-value="dialogShowMessage"
    max-width="400px"
  >
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0"

        >
        <span class="assignment-form-title">Module not attempted yet</span>
        </v-card-text
      >
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="dialogShowMessage =false"
          >ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Show assignment snackbar -->
   <v-snackbar v-model="showAssignmentSnackbar" color="cardBackgroundColor" location="top center ">
    <span class="submit-message-text">Assignment has been created.</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="showAssignmentSnackbar=false" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>

</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { useDisplay } from "vuetify";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import moment from "moment";
import PieHalfDonut from "./PieHalfDonut.vue";
import LineBarChart from "./LineBarChart.vue";
import TestBarComponent from "./TestBarComponent.vue";
import {
  breadcrumbHandler,
} from "@/services/breadcrumbHandler";
import {getLoggedInUserId } from "@/services/authService";
import AssignmentFormDialog from "../teacher/AssignmentFormDialog.vue";
export default {
  name: "TestReport",
  setup() {
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  components: { TestBarComponent, PieHalfDonut, LineBarChart, AssignmentFormDialog },
  data: () => ({
    testId: '',
    testInstanceId: '',
    showData: false,
    selectedModuleId: "Test Score",
    selectedModuleIndex: 0,
    selectedModule: {},
    items1: ['Item 1', 'Item 2', 'Item 3'],
    currentPractice: {},
    downloadingTestTitle: "Unpacking this practice!",
    downloadingTest: false,
    downloadingTestReport: false,
    selectedTest: {},
    selectedCategoryId: "",
    showRight: true,
    isUserTeacher: false,
    dialogShowMessage: false,
    
    // breadCrumbs: [],
    showParentDetailsDialog: false,
    formValue: false,
    showCreateNewCollectionBox: false,
    requiredRule: [(v) => !!v || "Required"],
    emailRule: [
      (v) =>
        !!(v || "").match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ||
        "Please enter a valid email",
    ],
    editedItem: {
      guardianName: "",
      guardianEmail: "",
    },
    defaultItem: {
      guardianName: "",
      guardianEmail: "",
    },
    errorMsg: "",
    showErrorMsg: false,
    showInfoMessage: false,
    infoMessage: "",
    isUserParent: false,
    assignmentDialig: false,
    isAssignmentCalled: false,
    assignmentItem:{},
    showAssignmentSnackbar: false
    // showParentDetailsSnackbar: false,
    // parentDetailsSnackbarMsg: "Parents detail successfully added",
  }),
  async beforeRouteUpdate(to, from) {
    // react to route changes... // previous, next
    console.log("route changed to", to);
    console.log("route changed from", from);

    const previousTestInstanceId = from.params.testInstanceId
    const nextTestInstanceId = to.params.testInstanceId
    if (previousTestInstanceId !== nextTestInstanceId) {
      this.testInstanceId = nextTestInstanceId;
      this.$store.dispatch("getTestReportData", { testId: this.testId, testInstanceId: nextTestInstanceId })
        .then(() => {
          if(!this.captureParentDetails){
            const moduleData = _.find(this.moduleList, { moduleId: this.selectedModuleId });
            if (moduleData && moduleData.status=='completed') {
              this.selectedModule = moduleData;
              this.selectedModuleIndex = _.findIndex(this.moduleList, (e) => { return e.moduleId == this.selectedModuleId });
            }
            else {
              this.selectedModuleIndex = 0;
              this.selectedModuleId = "Test Score"
              this.selectedModule = this.moduleList[0];
            }

            recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportOpen, {
              testId: this.testId,
              testInstanceId: this.testInstanceId,
              lowerScore: this.testReportData.testsummary.lowerScore,
              upperScore: this.testReportData.testsummary.upperScore,
              correctAnswer: this.testReportData.testsummary.correct,
              incorrectAnswer: this.testReportData.testsummary.incorrect,
              unanswered: this.testReportData.testsummary.unanswered,
            });

            let body = {};

            if (this.selectedModule.moduleLabel == 'Test Score') {
              body = {
                subjectKey: this.selectedModule.subjectKey,
                testLabel: this.selectedModule.moduleLabel,
                testLowerScore: this.selectedModule.lowerScore,
                testUpperScore: this.selectedModule.upperScore,
                testCorrectAnswer: this.selectedModule.correct,
                testIncorrectAnswer: this.selectedModule.incorrect,
                testUnanswered: this.selectedModule.unanswered,
              }

            }
            else {
              body = {
                subjectKey: this.selectedModule.subjectKey,
                moduleLabel: this.selectedModule.moduleLabel,
                moduleCorrectAnswer: this.selectedModule.correct,
                moduleIncorrectAnswer: this.selectedModule.incorrect,
                moduleUnanswered: this.selectedModule.unanswered,
                moduleDifficulty: this.selectedModule.difficulty,
              }
            }
            recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
              testId: this.testId,
              testInstanceId: this.testInstanceId,
              ...body
            });

          }else{
            recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportParentDetailsDialogOpen, {
              testId: this.testId,
              testInstanceId: this.testInstanceId
            });
            this.showParentDetailsDialog = true;
          }
        })

    }
  },
  created() {
    // this.creatNodes();
    breadcrumbHandler(this)
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);

    if ('testId' in this.$route.params) {
      this.testId = this.$route.params.testId;
    }
    if ('testInstanceId' in this.$route.params) {
      this.testInstanceId = this.$route.params.testInstanceId;

    }

    this.$store.dispatch("getTestReportData", { testId: this.testId, testInstanceId: this.testInstanceId })
      .then(() => {
        if(!this.captureParentDetails){
          this.showData = true;

          this.selectedModuleIndex = 0;
          this.selectedModuleId = "Test Score"
          this.selectedModule = this.moduleList[0];


          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportOpen, {
            testId: this.testId,
            testInstanceId: this.testInstanceId,
            lowerScore: this.testReportData.testsummary.lowerScore,
            upperScore: this.testReportData.testsummary.upperScore,
            correctAnswer: this.testReportData.testsummary.correct,
            incorrectAnswer: this.testReportData.testsummary.incorrect,
            unanswered: this.testReportData.testsummary.unanswered,
          });

          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
            testId: this.testId,
            testInstanceId: this.testInstanceId,
            subjectLabel: "Test Score",
            subjectKey: "testscore",
            testLabel: "Test Score",
            testLowerScore: this.testReportData.testsummary.lowerScore,
            testUpperScore: this.testReportData.testsummary.upperScore,
            testCorrectAnswer: this.testReportData.testsummary.correct,
            testIncorrectAnswer: this.testReportData.testsummary.incorrect,
            testUnanswered: this.testReportData.testsummary.unanswered,
          });
        }else{
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportParentDetailsDialogOpen, {
            testId: this.testId,
            testInstanceId: this.testInstanceId
          });
          this.showParentDetailsDialog = true;
        }
      });

    if (this.$route.params.studentId) {
      console.log("Heree in studentId")
      this.$store
        .dispatch("getUserTestInstances", { testId: this.testId, studentId: this.$route.params.studentId })
        .then(() => {
          this.selectedTest = _.find(this.pastTestAttemptData, { testInstanceId: this.testInstanceId });
        });
    } else {
      this.$store
        .dispatch("getUserTestInstances", { testId: this.testId })
        .then(() => {
          this.selectedTest = _.find(this.pastTestAttemptData, { testInstanceId: this.testInstanceId });
        });
    }
    if(this.$route.fullPath.startsWith('/teacher')){
      this.isUserTeacher = true;
    }
    if(this.$route.fullPath.startsWith('/parent')){
      this.isUserParent = true;
    }
  },
  methods: {
    creatNodes(breadCrumbs) {
      console.log(this.$route);
      console.log(breadCrumbs);
      breadCrumbs = [];
      let testId = ""
      let testInstanceId = ""
      let backRoute = {
        name: "",
        path: ""
      }
      if ("testId" in this.$route.params) {
        testId = this.$route.params.testId
      }
      if ("testInstanceId" in this.$route.params) {
        testInstanceId = this.$route.params.testInstanceId
      }


      
      breadCrumbs.push({
        title: "Practice Tests",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "practicetest",
        pageRoute: `/mocktest`,
      });
      breadCrumbs.push({
        title: "Test Attempts",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "testattempts",
        pageRoute: `/digisatresult/${testId}`,
      });

      


      breadCrumbs.push({
        title: "Test Report",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "testreport",
        pageRoute: `${backRoute.path}/digisatresult/${testId}/${testInstanceId}`,
      });

      console.log(this.breadCrumbs);
      return(breadCrumbs)
    },
    back() {
      // recordAnalyticsEvent(AnalyticsEvents.v2MockTestAttemptBackButtonClick, {
      //   testId: this.testId,
      // });
      
      console.log((this.$route));
      // if(this.$route.fullPath.startsWith('/digisatresult')){
      //   console.log("begining with digisatresult")
      //   this.$router.replace(`/digisatresult/${this.testId}`);
      //   return
      // }
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
      // if('assignment' in this.$route.query)
      // {
      //   this.$router.replace('/action')
      //   return
      // }
    },
    breadCrumbRoute(item){
      this.$router.replace(item.pageRoute)
    },
    selectModuleBySubject(item, difficulty) {

      const moduleItem = _.find(this.moduleList, { subjectKey: item.subjectKey, difficulty: difficulty })
      if (moduleItem) {
        this.selectModule(moduleItem);
      }

    },
    selectModule(item) {
      if(item.status == 'completed' || item.moduleLabel == 'Test Score'){
        this.selectedModuleId = item.moduleId;
        this.selectedModule = item;
        this.selectedModuleIndex = _.findIndex(this.moduleList, (e) => { return e == item });
        this.selectedCategoryId = "";
        // console.log("selectedModule", this.selectedModule);
        // console.log("selectedModuleId", this.selectedModuleId);
        // console.log("selectedModuleIndex", this.selectedModuleIndex);

        let body = {};

        if (this.selectedModule.moduleLabel == 'Test Score') {
          body = {
            subjectKey: this.selectedModule.subjectKey,
            testLabel: this.selectedModule.moduleLabel,
            testLowerScore: this.selectedModule.lowerScore,
            testUpperScore: this.selectedModule.upperScore,
            testCorrectAnswer: this.selectedModule.correct,
            testIncorrectAnswer: this.selectedModule.incorrect,
            testUnanswered: this.selectedModule.unanswered,
          }

        }
        else {
          body = {
            subjectKey: this.selectedModule.subjectKey,
            moduleLabel: this.selectedModule.moduleLabel,
            moduleCorrectAnswer: this.selectedModule.correct,
            moduleIncorrectAnswer: this.selectedModule.incorrect,
            moduleUnanswered: this.selectedModule.unanswered,
            moduleDifficulty: this.selectedModule.difficulty,
          }
        }
        recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
          testId: this.testId,
          testInstanceId: this.testInstanceId,
          ...body
        });
      }else{
        this.dialogShowMessage = true;
      }
    },
    onPrevious() {
      if (this.selectedModuleIndex > 0) {
        if(this.moduleList[this.selectedModuleIndex-1].moduleLabel != 'Test Score' && this.moduleList[this.selectedModuleIndex-1].status != 'completed' ){
          this.dialogShowMessage = true;
        }else{
          this.selectedModuleIndex--;
          this.selectedModule = this.moduleList[this.selectedModuleIndex];
          this.selectedModuleId = this.selectedModule.moduleId;
          let body = {};
          if (this.selectedModule.moduleLabel == 'Test Score') {
            body = {
              subjectKey: this.selectedModule.subjectKey,
              testLabel: this.selectedModule.moduleLabel,
              testLowerScore: this.selectedModule.lowerScore,
              testUpperScore: this.selectedModule.upperScore,
              testCorrectAnswer: this.selectedModule.correct,
              testIncorrectAnswer: this.selectedModule.incorrect,
              testUnanswered: this.selectedModule.unanswered,
            }
          }
          else {
            body = {
              subjectKey: this.selectedModule.subjectKey,
              moduleLabel: this.selectedModule.moduleLabel,
              moduleCorrectAnswer: this.selectedModule.correct,
              moduleIncorrectAnswer: this.selectedModule.incorrect,
              moduleUnanswered: this.selectedModule.unanswered,
              moduleDifficulty: this.selectedModule.difficulty,
            }

          }
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
            testId: this.testId,
            testInstanceId: this.testInstanceId,
            ...body
          });
        }
      }
    },
    onNext() {
      if (this.selectedModuleIndex < this.moduleList.length - 1) {
        if(this.moduleList[this.selectedModuleIndex+1].status != 'completed' ){
          this.dialogShowMessage = true;
        }else{
          this.selectedModuleIndex++;
          this.selectedModule = this.moduleList[this.selectedModuleIndex];
          this.selectedModuleId = this.selectedModule.moduleId;
          this.showRight = true;
          let body = {};
          if (this.selectedModule.moduleLabel == 'Test Score') {
            body = {
              subjectKey: this.selectedModule.subjectKey,
              testLabel: this.selectedModule.moduleLabel,
              testLowerScore: this.selectedModule.lowerScore,
              testUpperScore: this.selectedModule.upperScore,
              testCorrectAnswer: this.selectedModule.correct,
              testIncorrectAnswer: this.selectedModule.incorrect,
              testUnanswered: this.selectedModule.unanswered,
            }
          }
          else {
            body = {
              subjectKey: this.selectedModule.subjectKey,
              moduleLabel: this.selectedModule.moduleLabel,
              moduleCorrectAnswer: this.selectedModule.correct,
              moduleIncorrectAnswer: this.selectedModule.incorrect,
              moduleUnanswered: this.selectedModule.unanswered,
              moduleDifficulty: this.selectedModule.difficulty,
            }
          }
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
            testId: this.testId,
            testInstanceId: this.testInstanceId,
            ...body
          });

          if (this.selectedModuleIndex == this.moduleList.length - 1) {
            this.showRight = false;
          }
        }
      }
      else {
        this.showRight = false;
      }
    },
    getChartData(item) {
      return {
        data: [
          {
            name: this.getNumberFormat(item.correct) + " Correct",
            value: item.correct,
          },
          {
            name: this.getNumberFormat(item.incorrect) + " Incorrect",
            value: item.incorrect,
          },
          {
            name: this.getNumberFormat(item.unanswered) + " Skipped",
            value: item.totalQuestions ==0 ? 1: item.unanswered,
          },
          {
            name: this.getNumberFormat(item.totalQuestions) + " Total",
            value: item.totalQuestions ==0? 1: item.totalQuestions,
          },
        ], 
        percentage: item.correct == 0 ? 0 : _.round((item.correct * 100) / item.totalQuestions),
        isEmpty: item.totalQuestions == 0
      };
    },
    selectedCategory(item) {
      if (this.selectedCategoryId == item.categoryId) {
        this.selectedCategoryId = "";
      } else {
        this.selectedCategoryId = item.categoryId;
      }
    },
    getDifficulty(item) {
      let x = _.filter(item, (i) => i !== 'baseline');
      // console.log("difficulty is " ,x, item);
      return x[0];
    },
    getTopicChartData(item) {
      const total =
        item.correctQuestions +
        item.incorrectQuestions +
        item.unansweredQuestions;
      return {
        data: [
          {
            name: this.getNumberFormat(item.correctQuestions) + " Correct",
            value: item.correctQuestions,
          },
          {
            name: this.getNumberFormat(item.incorrectQuestions) + " Incorrect",
            value: item.incorrectQuestions,
          },
          {
            name: this.getNumberFormat(item.unansweredQuestions) + " Skipped",
            value: total ==0 ? 1: item.unansweredQuestions,
          },
          { 
            name: this.getNumberFormat(total) + " Total", 
            value: total == 0? 1: total,
          },
        ], 
        percentage: item.correctQuestions == 0 ? 0 : _.round((item.correctQuestions * 100) / total),
        isEmpty: total == 0
      };
    },
    getNumberFormat(number) {
      if (number >= 10) {
        return number + "";
      }
      return "0" + number;
    },
    textCapitalize(word) {
      return word[0].toUpperCase() + word.substring(1);
    },
    getTimeAllocationData() {
      const questionList = [];
      const yourTimeSpentPerQuestionList = [];
      const averageTimeSpentPerQuestionList = [];
      const timeInSecList = [0, 20, 40, 60, 80, 100, 120, 140, 160];
      _.forEach(
        this.selectedModule.questionsTimeSpentComparison,
        (question) => {
          questionList.push("Q" + question.qNo);
          yourTimeSpentPerQuestionList.push(question.timeSpentInSec);
          averageTimeSpentPerQuestionList.push(question.averageTimeSpentInSec);
        }
      );
      return {
        xAxisData: questionList,
        yAxisData: timeInSecList,
        barData: yourTimeSpentPerQuestionList,
        lineData: averageTimeSpentPerQuestionList,
      };
    },
    async startPractice(item) {
      //Open Practice page

      // this.downloadingTestTitle = "Unpacking this practice!";
      // this.downloadingTest = true;
      // let topicId = item.topicId;


      // this.$store.dispatch("startPractice", topicId)
      //   .then(async () => {
      //     this.currentPractice = this.practiceInfo;
      //     if (this.currentPractice.success == false) {
      //       console.log("not working");
      //       this.downloadingTest = false;
      //     }
      //     else {
      //       this.$store.commit("getTopicName", this.practiceInfo.testName);
      //       this.downloadingTest = false;
      //       recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportOpenPractice, {
      //         testId: this.testId,
      //         testInstanceId: this.testInstanceId,
      //         topicId: this.practiceInfo.topicId,
      //         topicName: this.practiceInfo.testName,
      //       });
      //       this.$store.commit('appbarVisible', false);
      //       this.$store.commit('navbarVisible', false);
      //       this.$store.commit("updateTestRoutingAllowed", true);
      //       this.$router.push(`/practicequestionpage`);
      //     }
      //   });

      // Open Activity page

      if (item.isAccessAllowed) {
        recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportOpenPractice, {
          testId: this.testId,
          testInstanceId: this.testInstanceId,
          topicId: this.practiceInfo.topicId,
          topicName: this.practiceInfo.testName,
        });
        this.$router.push(`/learn/${item.topicId}`);
      } else {
        //Add Pinpoint
        recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogOpenForTestReportPractice, {
          topicId: item.topicId,
        });
        this.$store.commit('openUpgradeDialog', true);
      }
    },
    viewSolution() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportViewSolution, {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
        activityType: 'mockTest',
      });
      console.log("called is user parent ", this.isUserParent)
      if( this.isUserParent == true){
        this.$router.push(`/mocktestresult/${this.testId}?instance=${this.testInstanceId}&report=${false}&mocktest=${true}&moduleId=${this.selectedModuleId}&parent=${true}`);
      }
      else if( this.isUserTeacher == true){
        this.$router.push(`/mocktestresult/${this.testId}?instance=${this.testInstanceId}&report=${false}&mocktest=${true}&moduleId=${this.selectedModuleId}&teacher=${true}`);
      }
      else{
        this.$router.push(`/mocktestresult/${this.testId}?instance=${this.testInstanceId}&report=${false}&mocktest=${true}&moduleId=${this.selectedModuleId}`);
      }
    },
    getScoreDate(testAttempt) {
      let date = new Date(testAttempt.testInstanceStartTimestamp);
      date = moment(date).format("D MMM, hh:mm A");
      return "Test on " + date;
    },
    selectTestInstance(item) {
      this.selectedTest = item;
      this.$router.replace(`/digisatresult/${this.testId}/${item.testInstanceId}`);
    },
    async downloadPdf(){
      this.downloadingTestReport = true;
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportPdfDownloadButtonClick, {
        testId: this.testId,
        testInstanceId: this.testInstanceId
      });
      const reqData = {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
      }
      if('studentId' in this.$route.params){
        reqData['userId'] = this.$route.params.studentId;
      } else{
        reqData['userId'] = getLoggedInUserId();
      }
      const response =  await this.$store.dispatch("getDownloadPdf", reqData);
      const self = this;
      if(response.success){
        if(response?.url?.length>0){
          fetch(response.url)
          .then(response => response.blob())
          .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.target = '_blank';
              a.href = url;
              // TestReport-Ajay Singh-Mock Test 7-2024-05-22T16_15_GMT.pdf
              a.download = `Test Report-${response.name}-${self.testInstancesData.testName}-${self.getTestReportDate(self.selectedTest)}.pdf`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              this.closeSnackBar();
          });
        }
      }else{
        this.infoMessage = response.message;
        this.showInfoMessage = true;
      }   
    },
    closeSnackBar() {
      this.downloadingTestReport = false;
      setTimeout(()=>{
        this.infoMessage = "";
        this.showInfoMessage = false;
      },1000)
    },
    getTestReportDate(testAttempt) {
      let date = new Date(testAttempt.testInstanceStartTimestamp);

      date = date.toISOString().substring(0,16).replace(':','_') + '_GMT';
      return date;
    },
    closeDialog(){
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportParentDetailsDialogClose, {
        testId: this.testId,
        testInstanceId: this.testInstanceId
      });
      this.close();
      this.back();
    },
    close() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.showParentDetailsDialog = false;
    },
    async saveParentDetails() {
      const self = this
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportParentDetailsDialogSaveButtonClick, {
          testId: this.testId,
          testInstanceId: this.testInstanceId
        });
       const res = await this.$store
          .dispatch("saveParentDetails", this.editedItem);
            console.log("res", res);
        if (res.success) {
          this.close();
          this.$store.dispatch("getTestReportData", { testId: this.testId, testInstanceId: this.testInstanceId })
          .then(() => {
            if(!this.captureParentDetails){
              this.showData = true;

              this.selectedModuleIndex = 0;
              this.selectedModuleId = "Test Score"
              this.selectedModule = this.moduleList[0];


              recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportOpen, {
                testId: this.testId,
                testInstanceId: this.testInstanceId,
                lowerScore: this.testReportData.testsummary.lowerScore,
                upperScore: this.testReportData.testsummary.upperScore,
                correctAnswer: this.testReportData.testsummary.correct,
                incorrectAnswer: this.testReportData.testsummary.incorrect,
                unanswered: this.testReportData.testsummary.unanswered,
              });

              recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportModuleOpen, {
                testId: this.testId,
                testInstanceId: this.testInstanceId,
                subjectLabel: "Test Score",
                subjectKey: "testscore",
                testLabel: "Test Score",
                testLowerScore: this.testReportData.testsummary.lowerScore,
                testUpperScore: this.testReportData.testsummary.upperScore,
                testCorrectAnswer: this.testReportData.testsummary.correct,
                testIncorrectAnswer: this.testReportData.testsummary.incorrect,
                testUnanswered: this.testReportData.testsummary.unanswered,
              });
            }else{
              recordAnalyticsEvent(AnalyticsEvents.v2MockTestReportParentDetailsDialogOpen, {
                testId: this.testId,
                testInstanceId: this.testInstanceId
              });
              this.showParentDetailsDialog = true;
            }
          });
        } else {
          this.errorMsg = res.message;
          this.showErrorMsg = true;
        }
      }
    },
    async createAssignment(topic){

      if(!this.isAssignmentCalled){
        this.$store.commit("increamentLoader");
        this.$store.commit("entitiesLoadedFromServer", false);
        await this.$store.dispatch("loadEntities");
        this.$store.commit("testsLoadedFromServer", false)
        await this.$store.dispatch("loadAllTests")
        
        if(this.isDevEnvironment){
          await this.$store.dispatch("loadTags");
          await this.$store.dispatch("loadNormalTests");
        }
        this.$store.commit("decreamentLoader");
        this.isAssignmentCalled = true
      }
      this.assignmentItem={
        title: topic.topicDisplayName,
        type: "practice",
        details: {
          "practice": [
              {
                  "subjectId": this.selectedModule.subjectKey,
                  "topicId": topic.topicId,
                  "subTopicId": null,
                  "easyQuestionCount": 3,
                  "mediumQuestionCount": 3,
                  "hardQuestionCount": 3
              }
          ],
        },
        subType: 'topic',
        topicId: topic.topicId
      }
      this.assignmentDialig = true;
      document.documentElement.classList.add('overflow-hidden');

    },
    closeAssignment(){
      console.log("closeAssignment");
      this.assignmentDialig = false;
      document.documentElement.classList.remove('overflow-hidden');
    },
    saveAssignment(){
      console.log("saveAssignment");
      this.assignmentDialig = false;
      this.showAssignmentSnackbar = true;
      document.documentElement.classList.remove('overflow-hidden');
    }
  },
  computed: {
    ...mapState(["testReportData", "practiceInfo", "testInstancesData", "breadCrumbs", "className", "studentName","userState", "config"]),
    displayBreadCrumbs: {
      get() {
        let breadCrumbs= []
        // if(this.$route.fullPath.startsWith('/digisatresult')){
        //   console.log("begining with digisatresult")
          
        //   return this.creatNodes(breadCrumbs);
        // }

        breadCrumbs = this.breadCrumbs;
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        
        if('studentId' in this.$route.params){
          let currentStudentName = this.studentName !== ''? this.studentName : 'Student';
          breadCrumbs[breadCrumbs.length-3].title = currentStudentName;
        }
        if('batchId' in this.$route.params){
          let currentClassName = this.className !== ''? this.className : 'Class Details';
          breadCrumbs[breadCrumbs.length-4].title = currentClassName;
        }
        if('studentTab' in this.$route.params && this.$route.params.studentTab == '2'){
          breadCrumbs.splice(-2, 1)
        }
        if(breadCrumbs.length>4){
          breadCrumbs = breadCrumbs.slice(-4);
        }
        return breadCrumbs;
      }
    },
    moduleList: {
      get() {
        let mudulesData = _.cloneDeep(_.get(this.testReportData, "modules", {}));
        mudulesData = _.map(mudulesData, (moduleData)=>({...moduleData, props:{ disabled: moduleData['status'] != 'completed'}}))
        const moduleList = [
          {
            subjectLabel: "Test Score",
            subjectKey: "testscore",
            moduleLabel: "Test Score",
            moduleId: "Test Score",
            ..._.get(this.testReportData, "testsummary", {}),
            timeSpentInMinutes: _.get(this.testReportData, "timeSpentInMinutes", 0),
          },
         
          ...mudulesData
        ];
        // console.log("moduleList is ", moduleList);
        return moduleList;
      },
    },
    pastTestAttemptData: {
      get() {
        let data = _.orderBy(this.testInstancesData.attempts, ['testInstanceStartTimestamp'], ['desc']);
        return data;
      },
    },
    testSummary: {
      get() {
        return this.testReportData.testsummary;
      }
    },
    moduleSubjects: {
      get() {
        return this.testReportData.modulesubjects;
      }
    },
    modules: {
      get() {
        return this.testReportData.modules;
      }
    },
    modulesList: {
      get() {
        let x = [];
        this.modules.forEach((item) => x.push(item.difficulty))
        return x;
      }
    },
    captureParentDetails: {
      get() {
        return _.get(this.testReportData, 'captureParentDetails', false);
      }
    },
    isDevEnvironment: {
      get(){
        return this.config.environment=="dev";
      }
    },


  },
  watch: {
    selectedModuleIndex(newVal) {
      if (newVal == this.moduleList.length - 1) {
        this.showRight = false;
      }
      else {
        this.showRight = true;
      }
    },
    "editedItem.guardianName"() {
      this.errorMsg = "";
      this.showErrorMsg = false;
    },
    "editedItem.guardianEmail"() {
      this.errorMsg = "";
      this.showErrorMsg = false;
    },
    showParentDetailsDialog() {
      this.errorMsg = "";
      this.showErrorMsg = false;
    },
  }
};
</script>


<style scoped>
.heightBread {
  height: 80px;
}

.box-padding {
  padding: 10px !important;
}

.main-lg {
  padding-top: 20px;
  padding-left: 7%;
  padding-right: 7%;
}

.item-gap {
  margin: 0 10px;
}

.item-gap:first-child {
  margin-left: 0 !important;
}

.item-gap:last-child {
  margin-right: 0 !important;
}

.main-md {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.main-box {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.main-md-box {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.bx {
  background-color: rgb(var(--v-theme-reportBgColor));
  border-radius: 12px;
  padding: 8px;
}

.sub-box {
  background-color: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
  border: 2px solid transparent;
}

.item-box {
  background: rgb(var(--v-theme-report-boxType));
  border-radius: 12px;
  border: 2px solid transparent;
}


.test-bar-height {
  height: 170px;
}

.report-heading {
  font-weight: 600 !important;
  font-size: 1.125rem;
  line-height: 1.25;
}

.icon-height {
  font-size: 22px;
}

.disabled-btn {
  background-color: #00000000;
  opacity: 0.2;
}

.disabled-practice {
  opacity: var(--v-disabled-opacity) !important;
}

.v-btn__overlay {
  display: none !important;
}

.category-heading {
  color: rgb(var(--v-theme-report-categoryHeading));
  font-size: 0.875rem;
  align-self: stretch;
}

.selector {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
}

.selector .v-field.v-field {
  cursor: pointer;
  color: white;
}

.extra-text {
  font-size: 1rem;
  color: rgb(var(--v-theme-report-extraText));
  font-weight: 500;
}

.recommended-border {
  background: linear-gradient(#393850, #393850) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.module-step {
  cursor: pointer;
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  background: rgb(var(--v-theme-report-boxType2));
  color: rgb(var(--v-theme-primaryTextColor));
  border-radius: 12px !important;
}

.subtext-h4 {
  font-size: 1.25rem !important;
  font-weight: 600;
}

.donut-view {
  width: 318px;
  height: 160px;
  position: relative;
}

.line-bar-chart {
  max-width: 100%;
  height: 400px;
}

.donut-box {
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px;
  background: rgb(var(--v-theme-report-donutBox));
}

.pie-chart-title {
  position: absolute;
  width: 90%;
  z-index: 1;
  left: 5%;
  top: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity-btn {
  background: rgb(var(--v-theme-primaryPurple));
  border-radius: 12px !important;
  font-size: 15px;

}

.activity-btn-2 {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  background: rgb(var(--v-theme-report-boxType2));
  font-size: 15px;
}

.activity-btn-2-width {
  width: 154px;
  border-radius: 12px !important;
}

.start-quiz-loading {
  background-color: #3D3D54 !important;
  color: rgb(var(--v-theme-primaryTextColor));
  border-radius: 15px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 300px;
  width: 480px;
}

.downloading-test-report{
  color: rgb(var(--v-theme-primaryTextColor));
  border-radius: 15px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 480px;
  background: linear-gradient(rgb(var(--v-theme-breadCrumbBackColor)), rgb(var(--v-theme-breadCrumbBackColor))) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 1px solid transparent;

}

.start-quiz-loading-title {
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: 25px !important;
}

.start-quiz-loading-subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  color: rgb(var(--v-theme-cardSecondaryTextColor));
}

.v-field__input {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .start-quiz-loading {
    height: 200px;
    width: 300px;
  }

  .practice-report-dialog {
    height: 240px;
    width: 300px;
  }

  .start-quiz-loading-img {
    width: 75px !important;
  }

  .start-quiz-loading-title {
    font-size: 20px !important;
  }

  .downloading-test-report{
    width: 300px;
  }
}
.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}


/* parent dialog */
.student-form {
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.22);
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.student-form-main {
  overflow: auto !important;
}

.student-form-main::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  background-color: rgba(156, 156, 156, 0.4);
  border-radius: 20px !important;
  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.wrap-text {
  white-space: pre-line;
}

.errorMsg {
  font-size: 0.8rem;
  font-weight: 700;
  color: #ff786e;
}

.download-pdf-btn{
  border: 2px solid rgb(var(--v-theme-primaryPurple)) !important;
  border-radius: 12px;
  background: transparent;
  height: 43px;
  width: 40px;
  padding: 8px 7px;
}

.border-circle{
  border: 1px solid white;
}

.overflow-hidden{
  overflow: hidden !important;
}
</style>