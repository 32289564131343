import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import velocity      from 'velocity-animate'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
import VueConfetti from 'vue-confetti'
import axios from "axios";
import Notifications from '@kyvg/vue3-notification'
import eventBus from './plugins/event-bus';


const myCustomLightTheme = {
  dark: false,
  colors: {
    // 'surface-variant': '#966DEC',  /* Tooltip background */ 

    // 'on-surface-variant': '#BDC3C7', /* Tooltip text */
    activeButtonColor : "#D9DFFC",
    easyColor : "#3db985",
    hardColor : "#ea4b67",
    intermediateColor : "#ff8e4f",
    mentoSubtitle : "ffffffb3",
    background: '#FFFFFF',
    background2: '#3D3D54',
    iconGrey : "#BDC3C7",
    primaryGreen : "#26B99A",
    primaryGrey : "#8f8f90",
    primaryOrange : "#FF894F",
    primaryPurple: "#A174FD",
    primaryTextColor : '#FFFFFF',
    secondaryTextColor : '#515151',
    breadCrumbBackColor : "#26253A",
    secondaryBackground : "#f0f0f0",
    cardBackgroundColor : "#23223A",
    cardSecondaryTextColor: "#C4C4CE",
    navColor : "#F8F8F8",
    navBarColor : "#030C1E",
    cardHoverColor : "#FFFF",
    // borderHoverColor : "",
        surface: '#FFFFFF',
    primary: '#536DFE',
    'primary-darken-1': '#3700B3',
    progress: '#844DDE',
    secondary: '#F3F2F3',
    'secondary-darken-1': '#018786',
    // error: '#B00020',
    error : "#EA4B67",
    navbar: "#0D143C",
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    cardBackgroundColor2: '#232238',
    'login-background': '#0D143C',
    'poweredby-mentoColor': '#FFFFFF',
    'poweredby-mindColor': '#536DFE',
    'content-background': '#FCFCFC',
    'domain-button-color': '#494F6C',
    'reportBgColor': "#26253A",
    'btn-bg': '#383851',
    'report-green': '#70BB9C',
    'report-red': '#EA4B67',
    'report-grey': '#424159',
    'report-orange': '#F27144',
    'report-subCardBg': '#393850',
    'report-boxType' : '#2E2D45',
    'report-extraText': '#BFBEC6',
    'report-boxType2': '#35354E',
    'report-categoryHeading': '#FEFEFE',
    'report-customMenu': '#343541',
    'report-donutBox': '#2f2f48',
    'practice-prompts-border': '#3F3E6F',
    'practice-prompts-background': '#232238',
    'practice-decision-box-subtitle': '#C4C4CE',
    'practice-prompts-option-box': '#2D2C42',
    'practice-hover-drop-shadow' : '#A274FD80',
    'practice-info-card-background' : '#484464',
    'practice-info-card-background-beginner' : '#484464',
    'practice-info-card-background-intermediate' : '#393A6F',
    'practice-info-card-background-advanced' : '#2D4453',
    'practice-info-card-background-mastery' : '#453C6A',
    'practice-info-card-border' : '#5B538F',
    'practice-info-card-border-beginner' : '#5B538F',
    'practice-info-card-border-intermediate' : '#6D9FFF',
    'practice-info-card-border-advanced' : '#4AC3A9',
    'practice-info-card-border-mastery' : '#A174FE',
    'practice-warn-prompt-border' : '#EA4B67',
    'ai-recommended-text': '#AEAEB8',
    dialogHeader: '#3c3b4e',
    pieTitle: '#b6b6be',
    actionIcon: "#474765"
  }
}


const textfieldtheme = {
  dark: false,
  colors: {
    // 'surface-variant': '#966DEC',  /* Tooltip background */ 
    // 'on-surface-variant': '#BDC3C7', /* Tooltip text */
    error : "#EA4B67",

  }
}
const vuetify = createVuetify({
  components : {
  ...components,
  ...labsComponents
  },
  directives,
  theme: {
    options: {
      customProperties: true,
    },
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
      textfieldtheme
    }
  }
})
axios
  .get(process.env.BASE_URL + "config.json")
  .then(resp => resp.data)
  .then(resp => {
    store.commit("setConfigValues", resp)

  })
  .catch(console.warn)
  .then(() =>
  createApp(App).use(VueConfetti).use(Notifications, { velocity }).use(store).use(router).use(vuetify).use(eventBus).mount('#app')
  );

