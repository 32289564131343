<template>
  <v-dialog style="backdrop-filter: blur(12.5px)" :model-value="dialog" width="900" persistent>
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-account-settings" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.name" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="">
            <v-col cols="auto" class="pt-3 pb-0">
              <span>Login Type: </span>
            </v-col>
            <v-col class="">
              <v-radio-group v-model="editedItem.loginType" inline dense class="" :rules="requiredRule" :disabled="checkIfEditingInfo()">
                <v-radio label="Email" value="email"></v-radio>
                <v-radio label="Phone" value="phone"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.email" :label="`Email ${editedItem.loginType == 'email' ? '*' : ''}`"
                density="compact" variant="outlined" :rules="editedItem.loginType == 'email' ? rules.email : editedItem.email.length > 0 ? rules.email : []" :disabled="checkIfEditingInfo()"
                dense></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <tel-input v-model="displayTutorPhoneNumber" @updatedPhoneNumberObject="updateTutorPhoneNumberInput" :disabled="checkIfEditingInfo()" :placeholder="`Tutor Phone Number ${editedItem.loginType == 'phone' ? '*' : ''}`" :required="editedItem.loginType == 'phone'"  />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select :items="packageList" item-title="packageName" item-value="packageId" v-model="editedItem.packageId"
                label="License *" density="compact" variant="outlined" dense placeholder="no select" :rules="requiredRule"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.notes" label="Notes" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="isUserSuperAdmin && editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default" variant="flat" height="40" @click.stop="opendeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="save">{{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Tutor -->
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent :model-value="dialogDelete" max-width="400px">
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0">
        <span class="assignment-form-title">Are you sure you want to delete
          ' {{ editedItem.name }}' ?</span>
      </v-card-text>
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="closeDeleteDialog()">No</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="deleteItem()">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container fluid v-if="showTutorScreen" class="assignment-card">

    <v-row class="mb-6 ma-0" justify="space-between" align="center">
      <v-col class="pa-0 ma-0 mr-5">
        <v-row class="pa-1 card-bg pa-0 ma-0" justify="space-between" align="center" ref="AdminTutorsScreen__tutorsComponent--searchBox" >
          <v-col cols="auto" class="heading pa-0 ma-0 ml-3"> Tutors </v-col>
          <v-divider vertical class="ml-5 mr-3" :thickness="3"></v-divider>
          <v-col class="ma-0 pa-0">
            <v-text-field density="compact" variant="solo" flat label="Search by Name or Contact Details"
              append-inner-icon="mdi-magnify" class="search-bar" bg-color="report-subCardBg" hide-details="true"
              v-model="searchFilter" v-on:input="debounceSearch" single-line></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="pa-0 ma-0">
        <v-btn class="t-btn text-white activity-btn text-none t-1remReg" density="default" variant="flat" height="46"
          @click="createNewTutor" ref="AdminTutorsScreen__tutorsComponent--addTutor">Add tutor</v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-6 ma-0" align="center">
      <v-col cols="auto" class="pa-0 pr-3">
        <span class="t-1remReg text-bold">Filter</span>
      </v-col>

      <v-col cols="auto" class="pa-0 pr-3">
        <div class="filter-dropdown">
          <v-select class="filter-box" base-color="#ffffff" color="#ffffff" item-color="black" flat density="compact"
            :items="packageList" v-model="filterTutors.packageName" label="License" item-title="packageName"
            item-value="packageId" multiple variant="outlined" hide-details prepend-inner-icon="mdi-license" ref="AdminTutorsScreen__tutorsComponent--filter0">
            <template v-slot:prepend-item>
              <v-list-item title="All" @click="togglePackageList">
                <template v-slot:prepend>
                  <v-checkbox-btn :indeterminate="filterTutors.packageName.length > 0 && !(filterTutors.packageName.length == packageList.length)" :model-value="filterTutors.packageName.length == packageList.length"></v-checkbox-btn>
                </template>
              </v-list-item>

              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ item, index }">
              <span v-if="filterTutors.packageName.length == packageList.length &&
                index == 0
                ">All</span>
              <span v-else-if="filterTutors.packageName.length > 1 && index == 0">
                +{{ filterTutors.packageName.length }}
              </span>
              <span v-else-if="index == 0 && item.title.length <= 8">
                {{ item.title }}
              </span>
              <span v-else-if="index == 0 && item.title.length > 8">
                {{ item.title.substring(0, 5) }}...
              </span>
            </template>
          </v-select>
        </div>
      </v-col>

      <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer>

      <v-col cols="auto" class="pa-0">
        <div class="filter-dropdown">
          <v-select class="filter-box" density="compact" :items="sortList" v-model="sortCategory" label="Sort by" item-title="sortName"
            item-value="sortId" variant="outlined" hide-details prepend-inner-icon="mdi-sort" ref="AdminTutorsScreen__tutorsComponent--filter1">
          </v-select>
        </div>
      </v-col>
    </v-row>

    <template v-for="(item, i) in tutors" :key="i">
      <v-card class="card-bg card-border px-2 mb-3 py-2" @click="editTutorDetails(item)" elevation="0" :ref="i == 0? `AdminTutorsScreen__tutorsComponent__tutorsList--card${i}`: ''">
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <!-- Name -->
            <v-col cols="3" class="d-flex align-center justify-start pa-1">
              <div class="mr-4">
                <v-avatar size="34" color="report-subCardBg" class="icon-bg">
                  <v-icon icon="mdi-account-settings" color="#ffffff" class="" size="20"></v-icon>
                </v-avatar>
              </div>
              <div class="pb-0 wrap-text">
                <div class="d-flex flex-column justify-start">
                  <span v-if="item.name != 'null'" class="t-1remReg">{{
                    item.name
                  }}</span>
                  <span v-else class="t-1remReg"> - </span>
                  <div class="">
                    <span class="t-h6Reg parameter-style">Last Online {{getTime(item.lastLoginTimeStamp)}} </span>
                  </div>
                </div>
              </div>

            </v-col>

            <!-- Contact -->
            <v-col cols="3" class="d-flex flex-column align-start pa-1">
              <div v-if="showPhoneNumber(item.mobileNumber)" class="pb-0 wrap-text">
                <span class="t-h6Reg">{{ item.mobileCountryCode }}{{ item.mobileNumber }}</span>
              </div>
              <div v-if="showEmail(item.email)" class="wrap-text">
                <span class="t-h6Reg">{{ item.email }}</span>
              </div>
            </v-col>

            <!-- License -->
            <v-col cols="2" class="d-flex flex-column align-start pa-1">
              <v-tooltip v-if="!item.packageDetails.packageId" location="top" max-width="400px">
                <template #activator="{ props }">
                  <div class="pb-0" v-bind="props">
                    <span class="t-h6Reg text-capitalize">-</span>
                  </div>
                </template>
                <span>Tutor is not assigned any license</span>
              </v-tooltip>
              <div v-else class="pb-0">
                <span class="t-h6Reg">{{
                  getPackageName(item)
                }}</span>
              </div>
              <div v-if="item.packageDetails.packageId" class="">
                <span class="t-h6Reg parameter-style">Ends {{ displayLocalDate(item.packageDetails.packageEndDate)
                }}</span>
              </div>
            </v-col>

            <!-- Edit -->
            <v-col cols="auto">
              <v-row justify="end" align="center">
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      prepend-icon="mdi-pencil"
                      style="text-transform: none;"
                      size="small"
                      density="comfortable"
                      rounded="xl"
                      stacked
                      variant="text"
                      @click.stop="editTutorDetails(item)" v-bind="props">
                      Edit
                    </v-btn>
                  </template>
                  <span>Edit Tutor</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-card v-if="tutors.length == 0" class="card-bg card-border px-2 mb-3 py-2" elevation="0" ref="AdminTutorsScreen__tutorsComponent__tutorsList--card0">
      <v-card-text class="text-primaryTextColor pa-0">
        <v-row justify="space-between" align="center" class="ma-0 px-1">
          <v-col cols="12" class="pa-3 text-center t-h6Reg">
            <span>No Tutor Found</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <v-snackbar v-model="showTutorMessage" :timeout="-1" color="cardBackgroundColor" location="top">
    <span class="submit-message-text text-report-red">{{ tutorErrorMessage }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { getLoggedInUserId } from "@/services/authService";
import { useDisplay } from "vuetify";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import TelInput from '@/shared/components/tel-input.vue';
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";

export default {
  name: "AdminTutorsComponent",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  components: { TelInput },
  emits: ['updateTutorNumber'],
  created() {
    recordAnalyticsEvent(AnalyticsEvents.v2AdminTutorScreenOpen, {
      courseId: this.courseId,
    });
    this.$store.dispatch("getPackageList", { courseId: this.courseId }).then(() => { this.packageListLoaded = true; });
    this.$store
      .dispatch("getTutorList", { courseId: this.courseId })
      .then((res) => {
        this.tutorsDetailLoaded = true;
      });
    const currTz = moment
      .tz(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
      .zoneName();

    if (currTz === "IST") {
      this.defaultItem.mobileCountryCode = "91";
    }
    else {
      this.defaultItem.mobileCountryCode = "1";
    }
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: 'AdminTutorsScreen',
    formValue: false,
    tutorsDetailLoaded: false,
    packageListLoaded: false,
    searchFilter: "",
    filterTutors: {
      packageName: [],
    },
    sortCategory: null,
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
      { sortName: "Last Online (Recent)", sortId: "onlineRecent" },
      { sortName: "Last Online (Oldest)", sortId: "onlineOldest" },
      { sortName: "License End Date (Earliest)", sortId: "endDateRecent" },
      { sortName: "License End Date (Latest)", sortId: "endDateOldest" },
    ],
    dialog: false,
    dialogDelete: false,
    defaultItem: {
      tutorId: "",
      name: "",
      loginType: "email",
      email: "",
      mobileCountryCode: "",
      mobileNumber: "",
      packageId: "",
      packageType: "",
      notes: "",
      active: true
    },
    editedItem: {
      tutorId: "",
      name: "",
      loginType: "email",
      email: "",
      mobileCountryCode: "",
      mobileNumber: "",
      packageId: "",
      packageType: "",
      notes: "",
      active: true
    },
    editedIndex: -1,
    requiredRule: [(v) => !!v || "Required"],
    rules: {
      email: [(v) => !!(v || "").match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || "Please enter a valid email"],
      phoneNumber: [(v) => !!(v || "").match(/^\d{10}$/) || "Please enter a valid mobile number"]
    },
    tutorErrorMessage: '',
    timeout: 3000,
    showTutorMessage: false,
    displayTutorPhoneNumber: '',
    isValidTutorPhoneNumber: false,
  }),
  methods: {
    debounceSearch() {
      if (this.searchFilter.trim() == "") {
        this.$store.commit("clearSearchedTutorList");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        if (this.searchFilter.trim() != "") {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminTutorScreenTutorSearched, {
            courseId: this.courseId,
            searchedKeyword: this.searchFilter.trim()
          });
          this.$store.dispatch("loadSearchedAdminTutorList", {
            courseId: this.courseId,
            search: this.searchFilter.trim(),
          });
        }
      }, 700); // delay
    },
    getTime(time) {
      if (time == null || time == 0) {
        return "-";
      }
      let vardata = moment(time).fromNow();
      return vardata;
    },
    showPhoneNumber(item) {
      let phoneNumber = item + "";
      return !phoneNumber.includes("null");
    },
    showEmail(item) {
      let email = item + "";
      return !email.includes("null");
    },
    togglePackageList() {
      if (this.filterTutors.packageName.length == this.packageList.length) {
        this.filterTutors.packageName = [];
      } else {
        this.filterTutors.packageName = this.packageList
          .slice()
          .map((e) => e.packageId);
      }
    },
    getPackageName(item) {
      if (item.packageDetails.packageId == "") {
        return "-";
      }
      for (let x of this.packageList) {
        if (item.packageDetails.packageId == x.packageId) {
          return x.packageName;
        }
      }
      return "-";
    },
    getPackageType(item) {
      if(item == ""){
        return "";
      }
      for (let x of this.packageList) {
        if (item == x.packageId) {
          return x.packageType;
        }
      }
      return "";
    },
    editTutorDetails(item) {
      this.editedIndex = this.tutors.indexOf(item);
      this.editedItem = _.omit(item, ['packageDetails']);
      this.displayTutorPhoneNumber = this.editedItem.mobileNumber?.length>0 ? `+${this.editedItem.mobileCountryCode}${this.editedItem.mobileNumber}`: '';
      this.editedItem.packageId = item?.packageDetails?.packageId;
      this.dialog = true;
    },
    createNewTutor() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.displayTutorPhoneNumber = '';
      this.editedIndex = -1;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      this.tutorErrorMessage = "";
      const { valid } = await this.$refs.form.validate();
      if((this.editedItem.loginType == 'phone' || this.editedItem.mobileNumber?.length > 0) && !this.isValidTutorPhoneNumber){
        return;
      }
      if (valid) {
        this.editedItem.courseId = this.courseId;
        this.editedItem.packageType = "";
        this.editedItem.packageType = this.getPackageType(this.editedItem.packageId);
        let userId = getLoggedInUserId();
        if (this.editedIndex > -1) {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminTutorScreenEditButtonClicked, {
            courseId: this.courseId,
            tutorId: this.editedItem.tutorId
          });
          this.$store.dispatch("updateTutor", this.editedItem).then((res) => {
            if (res.success) {
              if(this.editedItem.tutorId == userId ){
                this.$store.dispatch("onBoardingUserDetails").then(()=> {
                  this.close();
                })
              }
              else{
                this.close();
              }
            } else {
              this.tutorErrorMessage = res.message;
              this.showTutorMessage = true;
            }
          });
        } else {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminTutorScreenAddButtonClicked, {
            courseId: this.courseId,
            tutorId: this.editedItem.tutorId
          });
          this.$store.dispatch("addTutor", this.editedItem).then((res) => {
            if (res.success) {
              if(this.editedItem.tutorId == userId ){
                this.$store.dispatch("onBoardingUserDetails").then(()=> {
                  this.close();
                })
              }
              else{
                this.close();
              }
            } else {
              this.tutorErrorMessage = res.message;
              this.showTutorMessage = true;
            }
          });
        }

      }

    },
    closeSnackBar() {
      this.tutorErrorMessage = "";
      this.showTutorMessage = false;
    },
    displayLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      date = date * 1000;
      let displayDate = moment(date).format("YYYY-MM-DD");
      return moment(displayDate).format("ll");
    },
    opendeleteDialog() {
      this.dialog = false;
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.dialog = true;
    },
    deleteItem() {
      recordAnalyticsEvent(AnalyticsEvents.v2AdminTutorScreenDeleteButtonClicked, {
        courseId: this.courseId,
        tutorId: this.editedItem.tutorId
      });
      const reqData = {
        courseId: this.courseId,
        tutorId: this.editedItem.tutorId
      };
      this.$store.dispatch("deleteTutor", reqData).then((res) => {
        if (res.success) {
          this.close();
          this.$store.commit("deleteTutor", reqData);
          this.dialogDelete = false;
          this.editedItem = _.cloneDeep(this.defaultItem);
        } else {
          this.tutorErrorMessage = res.message;
          this.showTutorMessage = true;
        }
      });

    },
    isTutorActive(item) {
      return item.active ? 'Tutor is active' : 'Tutor is deactivated';
    },
    updateTutorPhoneNumberInput(phoneNumberObject){
      this.editedItem.mobileCountryCode = phoneNumberObject.telCountryCode;
      this.editedItem.mobileNumber = phoneNumberObject.telPhoneNumber; 
      this.isValidTutorPhoneNumber = phoneNumberObject.telIsValidPhoneNumber;
    },
    checkIfEditingInfo(){
      if(this.editedIndex == -1){
        return false;
      }
      else{
        return true;
      }
    },
  },

  computed: {
    ...mapState([
      "searchedTutorList",
      "classList",
      "tutorList",
      "courseId",
      "mobileCountryCodeList",
      "isUserSuperAdmin"
    ]),
    ...mapGetters(['currentHighlightedElements']),
    showTutorScreen: {
      get() {
        if (this.packageListLoaded && this.tutorsDetailLoaded) {
          return true;
        }
        else return false;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Tutor" : "Edit Tutor";
    },
    packageList: {
      get() {
        let list = _.cloneDeep(this.$store.state.packageList.tutorPackages) ?? [];
        list = list.map((e) => {
          e.packageName = e.packageName + ' (' + _.capitalize(e.packageType) + ')'
          return e;
        })
        list = _.orderBy(list, [license => license?.packageName?.toLowerCase()], "asc");
        return list;
      }
    },

    tutors: {
      get() {
        let tutors = [];
        if (this.searchFilter.trim() != "") {
          tutors = this.searchedTutorList;
        } else {
          tutors = this.tutorList;
        }

        switch (this.sortCategory) {
          case "nameAsc":
            tutors = _.orderBy(tutors, [tutor => tutor?.name?.toLowerCase()], "asc");
            break;
          case "nameDesc":
            tutors = _.orderBy(tutors, [tutor => tutor?.name?.toLowerCase()], "desc");
            break;
          case "onlineRecent":
            tutors = _.orderBy(tutors, [tutor => tutor?.lastLoginTimeStamp], "desc");
            break;
          case "onlineOldest":
            tutors = _.orderBy(tutors, [tutor => tutor?.lastLoginTimeStamp], "asc");
            break;
          case "endDateRecent":
            tutors = _.orderBy(tutors, ["licenseEndDate"], "asc");
            break;
          case "endDateOldest":
            tutors = _.orderBy(tutors, ["licenseEndDate"], "desc");
            break;

          default:
            tutors = _.orderBy(tutors, [tutor => tutor?.name?.toLowerCase()], "asc");
        }

        let check = false;
        let modifiedTutorsData = tutors;
        if (this.filterTutors.packageName.length > 0) {
          check = true;
          modifiedTutorsData = _.filter(modifiedTutorsData, (q) =>
            this.filterTutors.packageName.includes(q.packageDetails.packageId)
          );
        }
        if (check) {
          return modifiedTutorsData;
        } else return tutors;
      },
    },
  },
  watch: {
    tutors: {
      handler(val){
        this.$emit('updateTutorNumber', val.length);
      }
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
/* ---------------- */

.assignment-form-main {
  overflow: auto !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.delete-btn {
  border: 2px solid red !important;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

/* ------------------- */
.assignment-card {
  border-radius: 12px !important;
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.card-bg {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.icon-bg-disabled {
  background: rgb(var(--v-theme-domain-button-color)) !important;
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

.filter-dropdown {
  width: 180px !important;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word;
  /* or word-break: break-all; */
}
</style>

<style>
.search-bar .v-field__input, .search-bar .v-field-label, .filter-box .v-label, .filter-box .v-field__input {
  color: #BFBEC6 !important;
}
</style>
