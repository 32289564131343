const TRIGONOMETRY_KEYBOARD_LAYER = {
  "trigonometry-layer": {
    styles: "",
    rows: [
      [
        {
          class: "small",
          latex: "\\cos"
        },
        {
          class: "small",
          latex: "\\sec"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\sin"
        },
        {
          class: "small",
          latex: "\\cosec"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\tan"
        },
        {
          class: "small",
          latex: "\\cot"
        },

        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\sin^{-1}"
        },
        {
          class: "small",
          latex: "\\cosec^{-1}"
        }
      ],

      [
        {
          class: "small",
          latex: "\\arccos"
        },
        {
          class: "small",
          latex: "\\cosh"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\arcsin"
        },
        {
          class: "small",
          latex: "\\sinh"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\arctan"
        },
        {
          class: "small",
          latex: "\\tanh"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\cos^{-1}"
        },
        {
          class: "small",
          latex: "\\sec^{-1}"
        }
      ],
      [
        {
          class: "small",
          latex: "\\coth"
        },
        {
          class: "small",
          latex: "\\cth"
        },
        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\ctg"
        },
        {
          class: "small",
          latex: "\\cotg"
        },

        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\lb"
        },
        {
          class: "small",
          latex: "\\lg"
        },

        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\tan^{-1}"
        },
        {
          class: "small",
          latex: "\\cot^{-1}"
        }
      ],
      [
        {
          class: "small",
          latex: "\\csc"
        },
        {
          class: "small",
          latex: "\\sh"
        },
        {
          class: "separator w5"
        },

        {
          class: "small",
          latex: "\\tg"
        },
        {
          class: "small",
          latex: "\\th"
        },
        {
          class: "separator w5"
        },

        {
          class: "small",
          latex: "\\arctg"
        },
        {
          class: "small",
          latex: "\\arcctg"
        },

        {
          class: "separator w5"
        },
        {
          class: "small",
          latex: "\\max"
        },
        {
          class: "small",
          latex: "\\min"
        }
      ]
    ]
  }
};
const TRIGONOMETRY_KEYBOARD = {
  "trigonometry-keyboard": {
    label: "Trigno", // Label displayed in the Virtual Keyboard Switcher
    tooltip: "Trigonometry", // Tooltip when hovering over the label
    layer: "trigonometry-layer"
  }
};
export { TRIGONOMETRY_KEYBOARD_LAYER, TRIGONOMETRY_KEYBOARD };
