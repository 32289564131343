<template>
  <v-layout class="mb-0 pb-0 d-flex flex flex-column" v-if="dataLoaded"
    :class="{ layoutLarge: $vuetify.display.mdAndUp }">
    <v-row v-if="dataLoaded" class="ma-0" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end"
      align="center">
      <v-col cols="auto" class="pa-0">
        <SelfHelpGuideTogglerComponent class="mt-6 mr-8" />
      </v-col>
    </v-row>
    <v-container fluid class="h-100 mt-3 mt-md-0 mb-0 pb-0"
      :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">

      <div class="" v-if="showAssignments">
        <v-row align="center" justify="space-between" dense class="mb-6">
          <v-col cols="auto">
            <span class="t-h125Reg text-center mb-1 text-primaryTextColor">My Assignments</span>
          </v-col>
          <v-col cols="auto" sm="auto" md="auto" class="py-0 ma-0 ">
            <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex"
              ref="AssignmentsPage__btn--currentPastBtn">

              <v-btn v-for="tab in assignmentTimes" :key="tab.value" @click="changeCategory(tab.value)"
                :value="tab.value" class="py-2 px-3 flex-grow-1"
                :class="[tab.value == currentAssignmentTime ? 'active-button' : 'normal-button']"
                :variant="tab.value == currentAssignmentTime ? 'elevated' : 'plain'" elevation="0">
                <span class="text-none button-text t-h6Reg">{{ tab.name }}</span>
              </v-btn>

            </v-sheet>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="assignments.length == 0" ref="AssignmentsPage__assignmentList--card0">
          <v-col cols="auto">
            <span class="t-h125 text-center mb-1 text-primaryTextColor">No

              {{ currentAssignmentTime }}

              assignments</span>
          </v-col>
        </v-row>
        <v-row>

        </v-row>
        <v-card elevation="0" class="my-2 pa-0 testCard" v-for="(assignment, i) in assignments"
          :key="assignment.assignmentId"
          @click="assignment.status == 'completed' ? (assignment.showAssignmentSolution ? viewResults(assignment) : null) : assignment.status == 'inprogress' ? resumeAssignment(assignment) : startAssignment(assignment)"
          :ref="`AssignmentsPage__assignmentList--card${i}`">
          <v-card-text class="px-3 py-0 py-3 ">
            <v-row class="ma-0 text-white px-1" align="center">
              <v-col cols="auto" class="ma-0 pa-0 mr-6 ">
                <v-avatar :size="46" class="ma-0 pa-0">
                  <v-img v-if="assignment.type == 'practice'" :height="46" src="@/assets/icon_assignment.png" />
                  <v-img v-else :height="46" src="@/assets/icon_assignment_time.png" />

                </v-avatar>
              </v-col>
              <v-col cols="9" sm="3" md="6" lg="6" class=" my-2 my-sm-0">
                <v-row>
                  <span class="t-1remReg text-white mr-2">{{ assignment.title }}</span>

                </v-row>
                <v-row class=" pt-1">
                  <span v-if="assignment.type == 'practice'" class="mdi mdi-note-outline  text-grey mr-1"></span>
                  <span v-else class="mdi mdi-laptop  text-grey mr-1"></span>

                  <span class="t-para text-capitalize text-grey">
                    {{ displayTypeName(assignment.type) }}</span>
                </v-row>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" class=" my-2 my-sm-0">
                <v-row justify-sm="start">
                  <span class="t-1remReg text-white mr-2">{{ getDate(assignment.dueDate) }}</span>

                </v-row>
                <v-row class=" pt-1" justify-sm="start">
                  <span class="mdi mdi-clock-time-four  text-grey mr-1"></span>

                  <span class="t-para  text-grey ">Due</span>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="10" sm="12" md="3" lg="3" class="my-2 my-lg-0">

                <v-row class="" justify-md="end" align="center">
                  <v-avatar size="30" class="mr-2" color="#70BB9C" v-if="assignment.status == 'completed'">
                    <v-icon :size="25" icon="mdi-check" color="#fff"></v-icon>
                  </v-avatar>
                  <v-tooltip v-if="assignment.status == 'completed' && !assignment.showAssignmentSolution"
                    location="top" :open-on-click="true">
                    <template #activator="{ props }">
                      <v-btn width="155" prepend-icon="mdi-lock-outline"
                        class="t-btn text-white result-btn text-none px-3 " density="default" variant="flat"
                        v-bind="props">
                        <template v-slot:prepend>
                          <v-icon color="primaryPurple"></v-icon>
                        </template>
                        View Results
                      </v-btn>
                    </template>
                    <span>Please request your teacher to unlock this result</span>
                  </v-tooltip>
                  <v-btn width="155" prepend-icon="mdi-chart-pie" v-else-if="assignment.status == 'completed'"
                    class="t-btn   text-white result-btn text-none px-3 " density="default" variant="flat"
                    @click.stop="viewResults(assignment)">
                    <template v-slot:prepend>
                      <v-icon color="primaryPurple"></v-icon>
                    </template>
                    View Results
                  </v-btn>

                  <v-btn width="175" append-icon="mdi-play" class="t-btn text-white  not-activity-btn text-none px-3"
                    density="default" variant="flat" @click.stop="resumeAssignment(assignment)"
                    v-else-if="assignment.status == 'inprogress'">
                    <template v-slot:append>
                      <v-icon color="white"></v-icon>
                    </template>
                    <span>
                      Resume
                      {{ ['practice', 'practicewithtag'].includes(assignment.type) ? 'Practice' : 'Test' }}
                    </span>
                  </v-btn>

                  <v-btn width="155" append-icon="mdi-play" class="t-btn text-white  activity-btn text-none px-3"
                    density="default" variant="flat" @click.stop="startAssignment(assignment)" v-else>
                    <template v-slot:append>
                      <v-icon color="white"></v-icon>
                    </template>
                    <span>
                      Start
                      {{ ['practice', 'practicewithtag'].includes(assignment.type) ? 'Practice' : 'Test' }}
                    </span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </div>
      <div v-if="!showAssignments">

        <v-row align="center" justify="start" dense>
          <v-col cols="auto">
            <span class="t-h125 text-center mb-1 text-primaryTextColor">My Actions</span>
          </v-col>
          <v-col cols="auto">
            <div class="recommended">
              <span class="recommended-text">AI recommended</span>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="start" class="mt-5 mb-7">
          <v-col class="py-0">
            <span class="t-1rem text-coming-soon-color">Coming Soon...</span>
          </v-col>
        </v-row>
        <v-card elevation="0" class="my-2 pa-0 testCard">
          <v-card-text class="pa-3">
            <v-row class="ma-0" :class="{ 'px-2': $vuetify.display.mdAndUp }"
              :align="$vuetify.display.xs ? 'start' : 'center'" justify="start" dense>
              <v-col :class="{ 'pt-3': $vuetify.display.xs }" cols="1">
                <v-avatar size="28" class="text-center">
                  <v-img height="28" :src="getIconSrc('assets/mentomind/mentomind_icon_disable.svg')
                    " />
                </v-avatar>
              </v-col>
              <v-col :class="{ 'pl-3': $vuetify.display.xs }" cols="11">
                <v-row dense align="center" class="pa-0">
                  <v-col cols="12" sm="8" lg="9">
                    <span class="text-message">It’s been two weeks since you practiced Comprehension.
                      Start a quiz ?</span>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3">
                    <v-row align="center" justify-sm="end" justify="start" class="pa-0" dense>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Accept</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Decline</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="my-2 pa-0 testCard">
          <v-card-text class="pa-3">
            <v-row class="ma-0" :class="{ 'px-2': $vuetify.display.mdAndUp }"
              :align="$vuetify.display.xs ? 'start' : 'center'" justify="start" dense>
              <v-col :class="{ 'pt-3': $vuetify.display.xs }" cols="1">
                <v-avatar size="28" class="text-center">
                  <v-img height="28" :src="getIconSrc('assets/mentomind/mentomind_icon_disable.svg')
                    " />
                </v-avatar>
              </v-col>
              <v-col :class="{ 'pl-3': $vuetify.display.xs }" cols="11">
                <v-row dense align="center" class="pa-0">
                  <v-col cols="12" sm="6" lg="7">
                    <span class="text-message">You are only 2 questions away from achieving mastery in
                      Command of Evidence. Play a mini challenge ?</span>
                  </v-col>
                  <v-col cols="12" sm="6" lg="5">
                    <v-row align="center" justify-sm="end" justify="start" class="pa-0" dense>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Start</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Snooze</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Dismiss</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="my-2 pa-0 testCard">
          <v-card-text class="pa-3">
            <v-row class="ma-0" :class="{ 'px-2': $vuetify.display.mdAndUp }"
              :align="$vuetify.display.xs ? 'start' : 'center'" justify="start" dense>
              <v-col :class="{ 'pt-3': $vuetify.display.xs }" cols="1">
                <v-avatar size="28" class="text-center">
                  <v-img height="28" :src="getIconSrc('assets/mentomind/mentomind_icon_disable.svg')
                    " />
                </v-avatar>
              </v-col>
              <v-col :class="{ 'pl-3': $vuetify.display.xs }" cols="11">
                <v-row dense align="center" class="pa-0">
                  <v-col cols="12" sm="8" lg="9">
                    <span class="text-message">Sign up for MIT courses introduction - 2 days
                      remaining</span>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3">
                    <v-row align="center" justify-sm="end" justify="start" class="pa-0" dense>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Open</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Snooze</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="my-2 pa-0 testCard">
          <v-card-text class="pa-3">
            <v-row class="ma-0" :class="{ 'px-2': $vuetify.display.mdAndUp }"
              :align="$vuetify.display.xs ? 'start' : 'center'" justify="start" dense>
              <v-col :class="{ 'pt-3': $vuetify.display.xs }" cols="1">
                <v-avatar size="28" class="text-center">
                  <v-img height="28" :src="getIconSrc('assets/mentomind/mentomind_icon_disable.svg')
                    " />
                </v-avatar>
              </v-col>
              <v-col :class="{ 'pl-3': $vuetify.display.xs }" cols="11">
                <v-row dense align="center" class="pa-0">
                  <v-col cols="12" sm="8" lg="9">
                    <span class="text-message">Your skill level for Inferences has gone down. Take the
                      recommended quiz to restore your level</span>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3">
                    <v-row align="center" justify-sm="end" justify="start" class="pa-0" dense>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Start</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn disabled class="btn-disabled">
                          <span class="btn-text">Snooze</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

    </v-container>
  </v-layout>
</template>

<script>
import { useDisplay } from "vuetify";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import moment from "moment-timezone";
import { securePostAPI } from "@/services/apiService";
import {
  breadcrumbHandler,
} from "@/services/breadcrumbHandler";
import _ from "lodash";
import { mapGetters } from "vuex";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
export default {
  name: "AiActionPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    currentScreenName: 'AssignmentsPage',
    breadCrumbs: [],
    drawer: false,
    dataLoaded: false,
    aiBar: false,
    currentAssignmentTime: "current",
    currentPractice: {

    },
    assignmentTimes: [
      {
        value: "current",
        name: "Current"

      },
      {
        value: "past",
        name: "Past"

      },


    ]
  }),
  components: { SelfHelpGuideTogglerComponent },
  async created() {
    this.$store.commit('showPracticeReport', false);
    this.$store.commit('getPracticeReport', {});
    this.$store.commit('updateCurrentAssignmentId', '')
    this.$store.commit('showStudentAssignmentSolution', true);
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);
    this.$store.commit("updateNavBarItems", { data: "/action", show: false })
    this.$store.commit("assignmentsLoadedFromServer", false)
    await this.$store.dispatch("loadAssignments", {
      isCurrent: this.currentAssignmentTime == "current" ? true : false,
      currentDate: moment().format("YYYY-MM-DD")
    })
    recordAnalyticsEvent(AnalyticsEvents.v2AiMentorScreenOpen, {});
    // this.creatNodes();
    breadcrumbHandler(this)
    this.dataLoaded = true;
  },
  mounted() {
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted() {
    this.$store.commit("updateCurrentScreenName", null);
  },
  computed: {
    ...mapGetters(['currentHighlightedElements']),
    displayBreadCrumbs: {
      get() {
        return [this.breadCrumbs[this.breadCrumbs.length - 1]]
      }
    },
    assignments: {
      get() {
        try {

          let data = this.$store.state.assignments.items

          let sortedData = _.orderBy(data, ["dueDatetimestamp", "title"], ['asc', 'asc']);
          // let currentTimestamp = Date.now() / 1000

          // const tomorrowMidnight = moment().startOf('day'); // Get the start of tomorrow
          // const timestampTomorrowMidnight = tomorrowMidnight.valueOf() / 1000; // Get the Unix timestamp

          // console.log(timestampTomorrowMidnight);

          // if (this.currentAssignmentTime == "current") {
          //   data = data.filter((e) => e.dueDatetimestamp >= timestampTomorrowMidnight)
          // }
          // else {
          //   data = data.filter((e) => e.dueDatetimestamp < timestampTomorrowMidnight)
          // }
          //return data;
          return sortedData;

        } catch (error) {
          return []
        }

      }
    },
    showAssignments: {
      get() {
        let data = this.$store.state.assignments
        if (typeof data != "object") {
          data = {}
        }
        if ("isAssignedToTutorBatch" in data) {
          return data.isAssignedToTutorBatch
        }
        else {
          return false
        }
      }
    },
    practiceInfo: {
      get() {
        const data = this.$store.state.practiceInfo
        return data;
      }
    },
    typeList: {
      get() {
        const commonTypes = [
          { name: "Practice", value: "practice" },
          { name: "Practice Test", value: "mocktest" },
        ];

        const typeListForDev = [
          ...commonTypes,
          { name: "Practice(With tag)", value: "practicewithtag" },
        ];

        return (this.$store.state.config.environment == "dev") ? typeListForDev : commonTypes;
      },
    },
  },

  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    creatNodes() {
      this.breadCrumbs = [];

      this.breadCrumbs.push({
        title: "Ai Mentor",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "action",
        pageRoute: `/action`,
      });
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    // getDate(d) {
    //   let date = new Date(parseInt(d) * 1000);
    //   date = moment(date).format("MMM Do");
    //   return date;
    // },
    getLocalDateTime(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      let d = new Date(date * 1000);
      return moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("lll");
    },
    getDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      let d = new Date(date);
      let displayDate = moment(date, "YYYY-MM-DD")
      // const displayDate = moment.tz(
      //   d,
      //   Intl.DateTimeFormat().resolvedOptions().timeZone
      // );

      // Check for today, yesterday, or other dates
      if (displayDate.isSame(moment(), "day")) {
        return "Today ";
      } else if (displayDate.isSame(moment().subtract(1, "days"), "day")) {
        return "Yesterday ";
      } else if (displayDate.isSame(moment().add(1, "days"), "day")) {
        return "Tomorrow ";
      } else {
        // Format as MM/DD/YYYY 12:00 AM
        return displayDate.format("ll");
      }
    },
    async changeCategory(value) {
      this.currentAssignmentTime = value
      this.$store.commit("assignmentsLoadedFromServer", false)
      await this.$store.dispatch("loadAssignments", {
        isCurrent: this.currentAssignmentTime == "current" ? true : false,
        currentDate: moment().format("YYYY-MM-DD")
      })

    },
    async startAssignment(item) {
      if (item.type == 'practice' || item.type == 'practicewithtag') {
        await this.startPractice(item)
      }
      if (item.type == 'mocktest') {
        await this.startMocktest(item)
      }
    },
    async viewResults(item) {
      if (item.type == 'practice' || item.type == 'practicewithtag') {
        //
        this.$router.push("/testresult/" + item.practiceTestId + "?instance=" + item.testInstanceId + "&report=" + true);
      }
      //
      if (item.type == 'mocktest') {
        this.$router.push(`action/digisatresult/${item.mockTestId}`);
      }
    },
    async startPractice(item) {
      this.$store.commit("updateAssignmentPracticeInfo", {});
      recordAnalyticsEvent(AnalyticsEvents.v2AssignmentStart, {
        assignmentId: item.assignmentId,
        testId: item.practiceTestId,
        testInstanceId: item.testInstanceId
      });
      this.$store.commit('updateCurrentAssignmentId', item.assignmentId)
      this.$store.commit('showStudentAssignmentSolution', _.get(item, "showAssignmentSolution", true));
      let res = await securePostAPI({
        assignmentId: item.assignmentId,
        testId: item.practiceTestId,
        testInstanceId: item.testInstanceId
      }, 'mentoapp/studenttest/startPracticeAssignment', true, true);
      this.$store.commit("getPracticeInfo", res);
      this.$store.commit("updateAssignmentPracticeInfo", res);
      this.currentPractice = this.practiceInfo;
      if (this.currentPractice.success == false) {
        this.downloadingTest = false;
      }
      else {
        this.$store.commit("getTopicName", this.practiceInfo.testName);
        this.downloadingTest = false;
        this.$store.commit('appbarVisible', false);
        this.$store.commit('navbarVisible', false);
        this.$store.commit("showNotification", false);
        this.$store.commit("updateTestRoutingAllowed", true);
        this.$router.push(`/practicequestionpage`);
      }

    },
    async startMocktest(item) {
      this.$store.commit('updateCurrentAssignmentId', item.assignmentId)
      this.$store.commit('showStudentAssignmentSolution', _.get(item, "showAssignmentSolution", true));
      recordAnalyticsEvent(AnalyticsEvents.v2AssignmentStart, {
        assignmentId: item.assignmentId,
        testId: item.mockTestId
      })
      let res = await securePostAPI({
        assignmentId: item.assignmentId,
        testId: item.mockTestId
      }, 'mentoapp/studenttest/startMockTestAssignment', true, true);


      this.$store.commit("updateSelectedTest", res);
      this.$router.push(`/startmocktest/${item.mockTestId}?assignment=true`);
    },
    displayTypeName(type) {
      const foundType = _.find(this.typeList, { value: type });
      if (foundType) {
        return foundType.name;
      }
      return "";
    },
    async resumeAssignment(item) {
      if (item.type == 'practice' || item.type == 'practicewithtag') {
        await this.startPractice(item)
      }

      if (item.type == 'mocktest') {
        await this.resumeMocktest(item)
      }
    },
    async resumeMocktest(item) {
      this.$store.commit('updateCurrentAssignmentId', item.assignmentId)
      this.$store.commit('showStudentAssignmentSolution', _.get(item, "showAssignmentSolution", true));

      // let res = await securePostAPI({
      //   assignmentId: item.assignmentId,
      //   testId: item.mockTestId
      // }, 'mentoapp/studenttest/startMockTestAssignment', true, true);
      const res = {
        testInstanceId: item.testInstanceId,
        moduleId: item.inProgessModuleId,
        testId: item.mockTestId,
      }
      this.$store.commit("updateSelectedTest", res);
      this.$router.push(`/startmocktest/${item.mockTestId}?assignment=true`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-lg {
  padding-top: 50px;
  padding-left: 12%;
  padding-right: 12%;
}

.main-md {
  padding-top: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.v-card.on-hover {
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  /* border-color: transparent;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.ai {
  background: linear-gradient(180deg, #bf98ff 0%, #656cff 100%);
  border-radius: 10px;
}

.bg {
  background-color: red;
}

.heightBread {
  height: 80px;
}

.layoutLarge {
  height: 100%;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.text-font-8rem {
  font-size: 0.8rem;
  font-family: "Inter" !important;
  color: rgba(255, 255, 255, 0.7);
}

.view-past-challenges {
  padding: 10px 25px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 1.5px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.view-past-challenges.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.small-card {}

@media only screen and (max-width: 335px) {
  .small-card {
    text-align: center !important;
    justify-content: center !important;
    margin-left: 0px !important;
  }
}

.recommended {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 26px;
  background: linear-gradient(124deg, #7946E1 0%, #1E24AD 100%);
}

.recommended-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.64rem !important;
  font-weight: 500 !important;
  color: white;
}

.text-coming-soon-color {
  color: #8e8ea7;
}

.btn-disabled {
  padding: 10px 14px !important;
  border-radius: 6px !important;
  background: #4b4b5f !important;
  text-transform: none !important;
}

.toggler-outer-bg {
  border-radius: 200px !important;
  /* border: 2px solid rgba(255, 255, 255, 0.12); */
  background: #2A2941;
}

.btn-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.64rem !important;
  font-weight: 600 !important;
  color: white;
}

.text-message {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  color: #cacaca;
}

.active-button {
  border-radius: 200px;
  /* background-color: rgb(var(--v-theme-background)) !important; */
  /* background-color: #494960 !important; */
  /* border: 1.4px solid var(--Purple-G-1, #AB77FF); */
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
  border-radius: 200px;
  background: none;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
  border: 2px solid transparent;
}

.result-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.not-activity-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}
</style>

<style></style>
