<template>
	<!-- Navbar -->
	<v-app-bar color="breadCrumbBackColor" v-if="dataReady">

		<!-- Title -->
		<v-app-bar-title>
			<template v-slot:text>
				<span class>
					{{ practiceInfo.testName }}
				</span>

			</template>

		</v-app-bar-title>

		<v-btn v-if="xs && practiceInfo.subjectType == 'math'" icon="mdi-calculator" color="white" size="small"
			density="comfortable" class="text-white more-btn mr-2" @click="openCalculator">
		</v-btn>
		<v-btn v-else-if="practiceInfo.subjectType == 'math'" prepend-icon="mdi-calculator" color="white"
			class="t-btn text-white more-btn text-none mr-3" @click="openCalculator">
			Calculator
		</v-btn>

		<v-btn v-if="xs && practiceInfo.subjectType == 'math'" icon="mdi-format-superscript" color="white" size="small"
			density="comfortable" class="text-white more-btn mr-2" @click="openReferences">
		</v-btn>
		<v-btn v-else-if="practiceInfo.subjectType == 'math'" prepend-icon="mdi-format-superscript" color="white"
			class="t-btn text-white more-btn text-none mr-3" @click="openReferences">
			References
		</v-btn>

		<!-- Menu -->
		<v-menu :class="mdAndUp ? 'mr-5' : 'mr-2'">
			<template v-slot:activator="{ props }">
				<v-btn color="white" icon="mdi-dots-vertical" v-bind="props">
				</v-btn>
			</template>

			<v-list>
				<v-list-item @click="exitPractice()">
					<v-list-item-title>Leave practice</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</v-app-bar>
	<div v-show="calculatorDialog" class="main-draggable">
		<Vue3DraggableResizable class="calculator-parent" :minW="450" :minH="600" :draggable="draggable"
			:resizable="false" v-model:x="calculatorDimension.x" v-model:y="calculatorDimension.y"
			v-model:w="calculatorDimension.w" v-model:h="calculatorDimension.h" :parent="true">
			<v-card height="100%" width="100%">
				<v-row class="calculator-heading ma-0 align-center">
					<v-col cols="5">
						<span class="text-white t-h5">Calculator</span>
					</v-col>
					<v-col cols="2" class="d-flex align-center justify-center ma-0 pa-0">
						<v-tooltip location="top" z-index="3050">
							<template #activator="{ props }">
								<v-icon v-bind="props" color="white" class="draggable"
									@mousemove.stop="draggableStart()" @mouseleave.stop="draggableEnd()">
									mdi-drag-horizontal
								</v-icon>
							</template>
							<span>Press, hold, and drag to move</span>
						</v-tooltip>
					</v-col>
					<v-col cols="5" class="d-flex">
						<v-row dense class="align-center justify-end">
							<v-col cols="auto">
								<v-btn :prepend-icon="isCalculatorExpanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
									class="t-btn text-dialogHeader t-h5 more-btn text-none mr-3"
									@click="expandCalculatorDialog">{{
										isCalculatorExpanded ? 'Collapse' : 'Expand' }}</v-btn>
							</v-col>
							<v-col cols="auto">
								<v-icon color="white" @click="closeCalculator">
									mdi-close
								</v-icon>
							</v-col>
						</v-row>

					</v-col>
				</v-row>
				<iframe src="https://www.desmos.com/calculator" class="frame"></iframe>
			</v-card>
		</Vue3DraggableResizable>
	</div>

	<div v-show="referenceDialog" class="main-draggable">
		<Vue3DraggableResizable class="calculator-parent" :minW="$vuetify.display.xs ? 300 : 500"
			:minH="$vuetify.display.xs ? 400 : 500" :draggable="referenceDraggable" :resizable="!$vuetify.display.xs"
			v-model:x="selectedReferenceDimension.x" v-model:y="selectedReferenceDimension.y"
			v-model:w="selectedReferenceDimension.w" v-model:h="selectedReferenceDimension.h" :parent="true">
			<v-card height="100%" width="100%">
				<v-row class="calculator-heading ma-0 align-center">
					<v-col cols="5">
						<span class="text-white t-h5">Reference Sheet</span>
					</v-col>
					<v-col cols="2" class="d-flex align-center justify-center">
						<v-tooltip location="top" z-index="3050">
							<template #activator="{ props }">
								<v-icon v-bind="props" color="white" class="draggable"
									@mousemove.stop="!$vuetify.display.xs && referenceDraggableStart()"
									@mouseleave.stop="!$vuetify.display.xs && referenceDraggableEnd()">
									mdi-drag-horizontal
								</v-icon>
							</template>
							<span>Press, hold, and drag to move</span>
						</v-tooltip>
					</v-col>
					<v-col cols="3"></v-col>
					<v-col cols="2" class="d-flex justify-end">
						<v-icon color="white" @click="referenceDialog = false">
							mdi-close
						</v-icon>
					</v-col>
				</v-row>
				<v-row class="ma-0 pa-4 reference-body">
					<v-col cols="12">
						<v-row>
							<v-col cols="auto" v-for="(image, index ) in geometriesImages" :key="index">
								<v-img :width="index == 4 ? 200 : 100" height="100" :src="image"></v-img>
							</v-col>
						</v-row>
						<v-divider class="mt-2"></v-divider>
						<v-row dense class="mt-2">
							<v-col cols="12" class="d-flex">
								<v-img width="16" height="16" :inline="true" class="pa-0 pr-1"
									src="@/assets/pin_icon.png"></v-img>
								<span class="type-circle ma-0">The number of degrees of arc in a circle is 360.</span>
							</v-col>
							<v-col cols="12" class="d-flex">
								<v-img width="16" height="16" :inline="true" class="pa-0 pr-1"
									src="@/assets/pin_icon.png"></v-img>
								<span class="type-circle ma-0">The number of radians of arc in a circle is 2&pi;.</span>
							</v-col>
							<v-col cols="12" class="d-flex">
								<v-img width="16" height="16" :inline="true" class="pa-0 pr-1"
									src="@/assets/pin_icon.png"></v-img>
								<span class="type-circle ma-0">The sum of the measures in degrees of the angles of a
									triangle is
									180.</span>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

			</v-card>
		</Vue3DraggableResizable>
	</div>

	<!-- Feedback/report form-->
	<DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false" test-type="quiz"
		:q-id="currentQ.qId" :test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId"
		:q-obj="currentQ">
	</DragableFeedbackDialog>

	<!-- Main -->
	<div class="bgw" v-if="dataReady">

		<div class="bgWColor">


			<!-- Feedback/report form-->
			<!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
				<FeedbackComponent @closeDialog="reportDialog = false" test-type="quiz" :q-id="currentQ.qId"
					:test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId" :currentQ="currentQ"></FeedbackComponent>
			</v-dialog> -->

			<!-- Question Space Begins -->
			<v-container ref="componentToCapture" v-if="!showSolution" fluid class="pb-8">
				<template v-if="showBreaks">
					<br /><br />
				</template>
				<v-row justify="center" :class="{ 'w-70': $vuetify.display.mdAndUp }">
					<v-col cols="12" ref="mainQuestionScreen" id="mainQuestionScreen">
						<v-card elevation="0" class="pb-14 pt-6" color="white">
							<section class="mx-5">
								<div class="mb-12">
									<v-row class="align-center" style="width: 100%;">
										<span class="mx-3 qNoClass font-weight-bold">
											Question {{ currentQ.qDisplayNo }}
										</span>

										<!-- Button for Crossed Out Options -->
										<div v-if="currentQ.questionTypeId == '1' || currentQ.questionTypeId == '2'">
											<div class="" v-if="currentQResponse.crossedOutOptions">
												<v-tooltip text="Cross out options" location="end">
													<template v-slot:activator="{ props }">
														<v-btn elevation="0" theme="dark" v-bind="props" size="small"
															@click="hideCrossedOutOptions()" color="primaryPurple"
															icon="mdi-alphabetical-variant-off"></v-btn>
													</template>
												</v-tooltip>
											</div>
											<div class="" v-else>
												<v-tooltip text="Cross out options" location="end">
													<template v-slot:activator="{ props }">
														<v-btn size="small" v-bind="props" class="outline-color"
															variant="outlined" @click="showCrossedOutOptions()"
															icon="mdi-alphabetical-variant-off"></v-btn>
													</template>
												</v-tooltip>
											</div>
										</div>

										<v-spacer></v-spacer>
										<!-- Button to show report/feedback dialog -->
										<v-tooltip :text="feedbackOrQuestionReiewIconTooltipText" location="end">
											<template v-slot:activator="{ props }">
												<v-btn size="small" v-bind="props" variant="outlined"
													class="outline-color" @click="showReportDialog()"
													v-if="isQuestionReviewer"
													:icon="feedbackOrQuestionReiewIconName"></v-btn>
											</template>
										</v-tooltip>
									</v-row>
								</div>

								<!-- Question -->
								<section>
									<HtmlRender class="htmlClassTest" :html-string="currentQ.questionDesc"></HtmlRender>
								</section>

								<section>
									<HtmlRender class="htmlClassTest mt-1" :html-string="currentQ.qTextForSecondColumn">
									</HtmlRender>
								</section>

								<!-- Options -->
								<div class="mt-15">

									<!-- case 1 -->
									<section v-if="currentQ.questionTypeId == '1'">
										<v-radio-group v-model="currentQResponse.checkedOption" :ripple="false"
											@update:modelValue="radioGroupUpdate()">

											<v-row v-for="option in currentQ.options" :key="option.key"
												style="position: relative;" align="center">

												<v-col cols="" class="">
													<v-hover v-slot="{ isHovering, props }">

														<v-sheet
															:class="{ 'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': currentQResponse.checkedOption == option.key }"
															v-bind="props" class="pa-0  ma-0  bx"
															style="position: relative;">

															<div class="strike"
																v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
															</div>

															<v-radio :ripple="false" density="compact" color="white"
																class="ma-0 pa-0 w-100  custom-radio testOption" dense
																:value="option.key" :class="{
																	'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
																}">
																<div class="strike"
																	v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
																</div>
																<template v-slot:label>
																	<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																		<v-col class="ma-0 pa-0" cols="auto">
																			<v-avatar elevation="2" size="25"
																				class="t-h6 " variant="outlined" :class="{
																					'outline-color-white  text-primaryPurple': currentQResponse.checkedOption == option.key,
																					'outline-color-grey  ': !(currentQResponse.checkedOption == option.key),

																				}">
																				{{ option.key.toUpperCase() }}
																			</v-avatar>
																		</v-col>
																		<v-col class="ma-0 pa-0">
																			<HtmlRender
																				class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 mb-1"
																				:htmlString="option.value"></HtmlRender>
																		</v-col>

																	</v-row>

																</template>
															</v-radio>
														</v-sheet>
													</v-hover>
												</v-col>
												<v-col cols="auto" class="px-0 mx-0"
													v-if="currentQResponse.crossedOutOptions">
													<v-avatar
														class="outline-color text-capitalize text-decoration-line-through"
														variant="outlined" elevation="0" size="24"
														v-if="!checkOptionExistsInCrossedList(option.key)"
														@click="updateCrossedOptions(option.key, true)"
														style="cursor: pointer;">
														{{ option.key }}

													</v-avatar>
													<v-avatar v-else class="outline-color " elevation="0" size="24"
														@click="updateCrossedOptions(option.key, false)"
														style="cursor: pointer;">
														<v-icon>
															mdi-rotate-right
														</v-icon>
													</v-avatar>

												</v-col>
											</v-row>

										</v-radio-group>
									</section>

									<!-- case 2 -->
									<section v-if="currentQ.questionTypeId == '2'">

										<v-row v-for="option in currentQ.options" :key="option.key" align="center">
											<v-col cols="">
												<v-hover v-slot="{ isHovering, props }">


													<v-sheet :class="{
														'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': getMultichoiceSelected(option.key)
													}" v-bind="props" class="pa-0  ma-0 bx" style="position: relative;">
														<div class="strike"
															v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
														</div>

														<v-checkbox density="compact" multiple hide-details size
															color="white" v-model="currentQResponse.checkedOptions"
															class=" tqp ma-0 pa-0 w-100 testOption custom-radio " dense
															:value="option.key" @update:modelValue="checkBoxesUpdate"
															:class="{
																'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
															}">

															<template v-slot:label>
																<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																	<v-col class="ma-0 pa-0" cols="auto">
																		<v-avatar elevation="2" size="25" class="t-h6 "
																			variant="outlined" :class="{
																				'outline-color-white  text-primaryPurple': getMultichoiceSelected(option.key),
																				'outline-color-grey  ': !(getMultichoiceSelected(option.key)),

																			}">
																			{{ option.key.toUpperCase() }}
																		</v-avatar>
																	</v-col>
																	<v-col class="ma-0 pa-0">
																		<HtmlRender
																			class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 mb-1"
																			:htmlString="option.value"></HtmlRender>
																	</v-col>

																</v-row>

															</template>
														</v-checkbox>
													</v-sheet>

												</v-hover>
											</v-col>
											<v-col cols="auto" class="px-0 mx-0"
												v-if="currentQResponse.crossedOutOptions">
												<v-avatar
													class="outline-color text-capitalize text-decoration-line-through"
													variant="outlined" elevation="0" size="24"
													v-if="!checkOptionExistsInCrossedList(option.key)"
													style="cursor: pointer;"
													@click="updateCrossedOptions(option.key, true)">
													{{ option.key }}

												</v-avatar>
												<v-avatar v-else class="outline-color " elevation="0" size="24"
													@click="updateCrossedOptions(option.key, false)"
													style="cursor: pointer;">
													<v-icon>
														mdi-rotate-right
													</v-icon>
												</v-avatar>

											</v-col>
										</v-row>

									</section>


									<!-- case 3-->
									<section v-if="currentQ.questionTypeId == '3'">
										<v-row>
											<v-col cols="8" md="3" lg="2">
												<v-text-field label="Answer" v-model="currentQResponse.integerOption"
													variant="outlined" @update:modelValue="checkIntegerUpdate"
													:rules="mathRules" :hide-details="true"
													:maxLength="currentQResponse.integerOption[0] == '-' ? 6 : 5"
													@keyup="isMobile ? integerChangedValue() : null"
													@keydown="isMobile ? null : checkValue($event)"
													@dragover="preventDrag($event)" @paste="preventDrag($event)"
													@drop="preventDrag($event)"
													@cut="preventDrag($event)"></v-text-field>
											</v-col>
											<v-col cols="12" v-if="mathFormatError(currentQResponse.integerOption)">
												<span class="t-h6 text-red"> <v-icon color="red"
														size="14">mdi-alert-circle-outline</v-icon>You've
													entered a decimal point or slash in the wrong position.</span>
											</v-col>
										</v-row>
									</section>

									<!-- case 4-->
									<section v-if="currentQ.questionTypeId == '4'">

										<v-row>
											<v-col cols="8" md="3" lg="2">
												<v-text-field label="Answer" v-model="currentQResponse.decimalOption"
													variant="outlined" @update:modelValue="checkDecimalUpdate"
													:rules="mathRules" :hide-details="true"
													:maxLength="currentQResponse.decimalOption[0] == '-' ? 6 : 5"
													@keyup="isMobile ? decimalChangedValue() : null"
													@keydown="isMobile ? null : checkValue($event)"
													@dragover="preventDrag($event)" @paste="preventDrag($event)"
													@drop="preventDrag($event)"
													@cut="preventDrag($event)"></v-text-field>
											</v-col>
											<v-col cols="12" class="ma-0 pa-0"
												v-if="mathFormatError(currentQResponse.decimalOption)">
												<span class="t-h6 text-red"> <v-icon color="red"
														size="14">mdi-alert-circle-outline</v-icon>You've
													entered a decimal point or slash in the wrong position.</span>
											</v-col>

										</v-row>

									</section>


								</div>
							</section>
						</v-card>
					</v-col>
				</v-row>
			</v-container>

			<PracticeResult v-else :currentQuestion="currentQ" :currentQResponseProp="currentQResponse"
				:currentQSolutionProp="currentQSolution" :practiceInfo="practiceInfo" ref="mainSolutionScreen" id="mainSolutionScreen" />

			<!-- Footer -->
			<v-footer app class="bg-background" elevation="2">
				<v-row justify="space-between" class="  px-5 py-2 align-center">

					<v-col class="align-center" cols="auto">

						<!-- Skip Button -->
						<template v-if="!isNextQuestion">
							<v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-prev"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" color="secondaryBackground"
								elevation="0" @click="validateQuestion(true)">
								Skip
								<template v-slot:append>
									<v-icon>mdi-debug-step-over</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" size="large" icon="mdi-debug-step-over"
								@click="validateQuestion(true)" color="secondaryBackground">
							</v-btn>
						</template>
					</v-col>
					<v-col cols="auto">
						<span class="ml-2 t-1remReg">
							{{ currentQ.qDisplayNo }} of {{ noOfQuestions }}
						</span>
					</v-col>
					<v-col cols="auto">
						<!-- Next Button -->
						<template v-if="isNextQuestion">
							<v-btn v-if="$vuetify.display.smAndUp"
								class="t-btn text-capitalize btn-grad  text-primaryTextColor"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0" @click="nextQuestion">
								Next
								<template v-slot:append>
									<v-icon>mdi-chevron-right</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" size="large" icon="mdi-chevron-right" @click="nextQuestion"
								color="primaryPurple">
							</v-btn>
						</template>

						<!-- Validate Button -->
						<template v-else>
							<v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize"
								:disabled="!enableCheck" @click="validateQuestion(false)"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0"
								:class="!enableCheck ? 'btn-prev' : 'btn-grad'">
								{{ showStudentAssignmentSolution ? 'Check' : 'Next' }}
								<template v-slot:append>
									<v-icon>{{ showStudentAssignmentSolution ? 'mdi-check' : 'mdi-chevron-right' }}</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" size="large" :disabled="!enableCheck"
								:icon="showStudentAssignmentSolution ? 'mdi-check' : 'mdi-chevron-right'"
								@click="validateQuestion(false)" :class="!enableCheck ? 'btn-prev' : 'btn-grad'">
							</v-btn>
						</template>

					</v-col>


				</v-row>
			</v-footer>

			<QuestionActionsComponent
				test-type="quiz"
				:q-id="currentQ.qId"
				:test-id="practiceInfo.testId"
				:test-instance-id="practiceInfo.testInstanceId"
				:q-obj="currentQ"
				ref="questionActions"
				v-bind="{
					...(isDevEnvironment ? { screenshotElement } : {}),
					chatbotConfig,
					chatbotAllowed
				}"
				:currentQResponseProp="currentQResponse"
				:updateCrossedOptionsBot="updateCrossedOptionsBot"
			></QuestionActionsComponent>

		</div>

	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { useDisplay } from "vuetify";
// import FeedbackComponent from "@/components/Test/TestQuestionPageComponents/FeedbackComponent.vue";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import ChatBotTyping from "@/components/practice/ChatBotTyping.vue";

import PracticeResult from "./PracticeResult.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import html2canvas from 'html2canvas';
import router from "@/router";
import { securePostAPI, securePostAPIWithoutOverlay } from "@/services/apiService";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { getHeaders } from "@/services/authService";
import VueResizable from 'vue-resizable'
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import QuestionActionsComponent from '@/components/questionTools/QuestionActionsComponent.vue';

import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
	return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
	name: 'PracticeQuestionPage',
	setup() {
		// Destructure only the keys we want to use
		const { mdAndDown, xs, mdAndUp, width, height, smAndDown } = useDisplay();

		return { xs, mdAndDown, mdAndUp, width, height, smAndDown };
	},
	unmounted() {
		this.$store.commit("updateShowBackground", true);
		// window.removeEventListener('resize', this.onResize);

	},
	// mounted() {
	// 	this.$nextTick(() => {
	// 		window.addEventListener('resize', this.onResize);
	// 	})
	// },


	async created() {
		this.isMobile = navigator.userAgent.match(/Android|iPhone|iPad|iPod/i);
		// this.onResize()

		this.dataReady = false;
		this.$store.commit("updateShowBackground", false);

		// redirect if come again
		this.$store.commit("updateTestRoutingAllowed", false);

		// Current time for question
		this.currentQDuration.startTime = this.getCurrentClientTimeInSeconds();
		// this.currentMode = "practiceQuestion"
		// await this.getNudgeInfoForCurrentQuestion()
		// if (this.chatbotAllowed) {
		// 	if (this.smAndDown) {
		// 		this.chatbot = false;
		// 	}
		// 	else {
		// 		this.chatbot = true
		// 	}
		// }

		this.chatbotConfig = {
			currentMode: "practiceQuestion",
			chatbotMode: "practice_practiceQuestion"
		};

		// this.currentMessagesList.push({ message: "hello", type: "user", messageId: "1" })

		recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenOpen, {
			testId: this.practiceInfo.testId,
			testInstanceId: this.practiceInfo.testInstanceId,
			testName: this.practiceInfo.testName,
			questionNo: this.currentQ.qDisplayNo,
			questionId: this.currentQ.qId,
			difficultyLevel: this.currentQ.difficultyLevel,
		});

		// data ready only when the question formulas are set
		this.dataReady = true

		setTimeout(() => {
			window.MathJax.typesetPromise();
		}, 10);

		if (window.document.getSelection) {
			document.addEventListener('selectionchange', (e) => {
				this.annotatedText = window.getSelection().toString().trim().replace(" ", "")

			});
		}

		if(this.isDevEnvironment){
			this.checkElement("mainQuestionScreen");
		}
	},
	components: {
		// FeedbackComponent, 
		HtmlRender, PracticeResult, Vue3DraggableResizable,
		DragableFeedbackDialog, QuestionActionsComponent

	},
	data: () => ({
		showBreaks: false,
		dataReady: false,
		annotateDialog: false,
		reportDialog: false,
		controller: new AbortController(),
		botTyping: false,
		chatbot: false,
		aiTokensUsed: null,
		chatbotInputText: "",
		currentMessagesList: [],
		tokenInfoDialog: false,
		windowHeight: (window.innerHeight) * 80 / 100,
		isChatBotAllowed:false,
		chatbotheight: null,
		annotatedText: "",
		currentQDuration: {
			qId: "",
			startTime: '',
			endTime: ''
		},
		notificationSound: null,
		trueIcons: {
			'a': 'fa: fa-solid fa-a fa-2xl',
			'b': 'mdi-alpha-b-circle',
			'c': 'mdi-alpha-c-circle',
			'd': 'mdi-alpha-d-circle'
		},
		falseIcons: {
			'a': 'fa: fa-solid fa-a fa-2xl',
			'b': 'mdi-alpha-b-circle-outline',
			'c': 'mdi-alpha-c-circle-outline',
			'd': 'mdi-alpha-d-circle-outline'
		},
		currentQResponse: {
			qId: "",
			qType: "",
			checkedOption: "",
			checkedOptions: [],
			integerOption: "",
			decimalOption: "",
			markForReview: false,
			crossedOutOptions: false,
			crossedOutOptionsList: [],
			aiCrossedOutOptionsList: []
		},
		currentMode: "",
		conversation: [],
		disableChatbot: false,
		limitReachedDialog: false,
		limitReachedDialogMsg: "",
		aiTokensUsedForBarColor: {
			green: 'green',
			orange: 'orange',
			red: 'red'
		},
		aiTokensAllowed: 0,
		defualtCurrentQResponse: {
			qId: "",
			qType: "",
			checkedOption: "",
			checkedOptions: [],
			integerOption: "",
			decimalOption: "",
			markForReview: false,
			crossedOutOptions: false,
			crossedOutOptionsList: [],
			aiCrossedOutOptionsList: []
		},
		imgExists: false,
		currentQSolution: {},
		isNextQuestion: false,
		isSkipped: false,
		testId: '',
		testInstanceId: '',
		enableCheck: false,
		showSolution: false,
		screenshotElement: {},

		isMobile: false,
		mathRules: [(v) => /^-?(\d*)?(\d*\.\d*)?(\d+\/(\d*\.\d*)?\d*)?$/.test(v) || "You've entered a decimal point or slash in the wrong position.",],

		calculatorDialog: false,
		isCalculatorExpanded: false,
		referenceDialog: false,
		calculatorDimension: {
			x: 50,
			y: 100,
			h: 600,
			w: 450,
		},
		selectedReferenceDimension: {},
		referenceDimensionMobile: {
			x: 10,
			y: 100,
			h: 400,
			w: 320,
		},
		referenceDimension: {
			x: 50,
			y: 100,
			h: 500,
			w: 500,
		},
		geometriesImages: [
			require('@/assets/geometries/1.png'),
			require('@/assets/geometries/2.png'),
			require('@/assets/geometries/3.png'),
			require('@/assets/geometries/4.png'),
			require('@/assets/geometries/5.png'),
			require('@/assets/geometries/6.png'),
			require('@/assets/geometries/7.png'),
			require('@/assets/geometries/8.png'),
			require('@/assets/geometries/9.png'),
			require('@/assets/geometries/10.png'),
		],
		draggable: false,
		referenceDraggable: false,
		chatbotConfig: {
			currentMode: "practiceQuestion",
			chatbotMode: "practice_practiceQuestion"
		},
	}),
	// watch: {
	// 	botTyping(val) {
	// 	},
	// },
	methods: {
		feedbackAIMessage(val, index) {
			let previousVal = this.currentMessagesList[index].feedback ?? null
			this.currentMessagesList[index].feedback = val
			securePostAPI({
				feedback: val,
				messageId: this.currentMessagesList[index].messageId
			}, `mentoapp/mentopracticenudge/feedback`, false).then((res) => {
				// console.log("feedback", res);
				if ("error" in res) {
					this.currentMessagesList[index].feedback = previousVal
				}
			})
		},
		onResize() {
			// console.log("resize");
			// console.log("height", ((window.innerHeight) * (45 / 100)) / window.devicePixelRatio);
			this.chatbotheight = ((window.innerHeight) * (47 / 100)) / window.devicePixelRatio
			// this.chatbotheight = ((window.innerHeight -76 -140)*70/100) / window.devicePixelRatio

		},
		async getNudgeInfoForCurrentQuestion() {
			if (!this.isChatBotAllowed) {
				return
			}
			this.botTyping = true

			this.conversation = []
			this.imgExists = false

			let headers = getHeaders()
			let msg = await securePostAPI({
				qType: this.currentQ.questionTypeId,
				mentoHelpMode: this.currentMode,
				subjectId: this.currentQ.subjectId,
				question: this.currentQ,
				getNudge: true,
				getTokens: this.aiTokensUsed == null ? true : false,
				checkImage: true
			}, `mentoapp/mentopracticenudge/organization/${headers.organizationid}/user/${headers.userid}`, true)

			this.imgExists = msg.imgExists
			if ('limitReachedDialogMsg' in msg) {
				this.limitReachedDialogMsg = msg.limitReachedDialogMsg
			}
			if ('aiTokensUsed' in msg) {
				this.aiTokensUsed = msg.aiTokensUsed
			}
			if ('aiTokensAllowed' in msg) {
				this.aiTokensAllowed = msg.aiTokensAllowed
			}
			console.log("play");
			// console.log(msg);
			this.currentMessagesList = msg.messages

			// this.currentMessagesList.push({
			// 	message: "Option c is incorrect because it states that critically endangered birds only make up 10 percent of the IUCN Red List in the Philippines. However, the text does not provide any information about the proportion of critically endangered birds in the Philippines compared to the overall IUCN Red List. Therefore, option c cannot be supported by the data from the graph."
			// 	, type: "nudge", messageId: uuidv4()
			// })
			// this.currentMessagesList.push({
			// 	message: "I want a hint"
			// 	, type: "user", messageId: uuidv4()
			// })

			this.botTyping = false

		},
		async chatbotButtonClicked(info) {
			this.controller = new AbortController()
			// console.log(info);
			if (this.disableChatbot) {
				this.limitReachedDialog = true
				return
			}
			this.scrollChatBotToBottom()
			if (info.tag != 'customText') {

				let annotatedPrompt = ""

				if (info.tag == 'annotate') {
					if (this.annotate().replace(' ', '').length == 0) {
						this.annotateDialog = true
						return
					}

					annotatedPrompt = info.text + ` : <b>'${this.annotate()}'</b>`

					this.currentMessagesList.push({ message: annotatedPrompt, type: "user", messageId: uuidv4() })

				}
				else {
					this.currentMessagesList.push({ message: info.text, type: "user", messageId: uuidv4() })
				}


				recordAnalyticsEvent(
					AnalyticsEvents.v2ChatbotButtonClicked, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					qId: this.currentQ.qId,
					mode: "practice_" + this.currentMode,
					tag: info.tag ?? '',
					buttonLabel: info.text,
					buttonType: info.type == "CUSTOM_ACTION_TAG" ? "ai" : "nudge",
					userMessage: info.tag == "annotate" ? annotatedPrompt : info.text,
					annotatedWord: this.annotate(),
				});

			}

			else {
				recordAnalyticsEvent(
					AnalyticsEvents.v2ChatbotCustomMessage, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					qId: this.currentQ.qId,
					mode: "practice_" + this.currentMode,
					userMessage: info.userPrompt ?? ''
				});
			}

			this.botTyping = true
			let userPrompt = info.userPrompt ?? ''
			if (info.tag == "optionExplanation") {
				userPrompt = info.value
			}


			let res = await securePostAPIWithoutOverlay({
				question: this.currentQ, tag: info.tag, session: {
					conversation: this.conversation,
				},
				userPrompt: info.tag == 'annotate' ? this.annotate() : userPrompt,
				crossedOutOptionsList: this.currentQResponse.crossedOutOptionsList,
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				userNudgeText: info.text,
				systemTag: this.currentMode,
				imgExists: this.imgExists
			}, info.handlerUrl, this.controller.signal)
			// console.log(res);

			if (!("error" in res)) {

				this.currentMessagesList.push(...res.messages)
				// console.log(this.currentMessagesList);


				if ("aiTokensUsed" in res) {
					this.aiTokensUsed = res.aiTokensUsed
				}

				if (this.aiTokensUsed >= this.aiTokensAllowed) {
					this.limitReachedDialog = true
					this.disableChatbot = true
				}
				// this.disableChatbot = true

				if (res.type == "aiframework") {
					this.conversation = res.conversation
				}
				// this.notificationSound.play()

			}

			if (info.tag == 'crossout') {
				// console.log(res)
				this.updateCrossedOptionsBot(res);
				// this.currentQResponse.crossedOutOptions = true

				// this.updateCrossedOptions(res.aiCrossedOutOption, true)
			}
			this.botTyping = false
			this.scrollChatBotToBottom()

		},
		getButtonMessage(currentMessage) {
			if (this.currentMessagesList.length > 0) {
				let firstMessage = {}
				if (currentMessage == null) {
					firstMessage = this.currentMessagesList[0]
				}
				else {
					firstMessage = currentMessage
				}


				// console.log(firstMessage);
				if (firstMessage.type == 'nudge' && "responseCard" in firstMessage
					&& firstMessage.responseCard != null
					&& "genericAttachments" in firstMessage.responseCard) {
					// console.log(firstMessage.responseCard.genericAttachments[0].buttons);
					return firstMessage.responseCard.genericAttachments[0].buttons
				}
			}

			return []
			// currentMessagesList[0].type == 'nudge' && currentMessagesList[0].responseCard != null
		},
		annotate() {
			if (window.document.getSelection) {
				let selected = window.document.getSelection().toString()
				return selected
			}
			return ''
		},
		chatButtonDisabled(info) {
			if (this.botTyping) {
				return true
			}

			if (info.tag == 'crossout') {
				if (this.currentQResponse.crossedOutOptionsList.length >= 2) {
					return true
				}

			}

			// if (info.tag == 'annotate') {
			// 	if (this.annotatedText.length <= 0) {
			// 		return true
			// 	}
			// }

			return false
		},
		async chatbotSendHandler() {
			if (this.botTyping) {
				return
			}
			if (this.disableChatbot) {
				this.limitReachedDialog = true
				return
			}
			if (this.chatbotInputText.trim().replace(' ', '').length == 0) {
				return ''
			}
			let val = this.chatbotInputText

			this.currentMessagesList.push({ message: val, type: "user", messageId: uuidv4() })
			this.chatbotInputText = ''

			this.chatbotButtonClicked({ tag: "customText", userPrompt: val, text: val, handlerUrl: 'mentoapp/mentopracticenudge/aimessage/customtext' })
		},
		scrollChatBotToBottom() {
			try {
				$(".msg_container_base").stop().animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 1000);

			} catch (error) {
				console.log(error);
			}

		},
		openChatbot() {
			this.chatbot = true
			recordAnalyticsEvent(
				AnalyticsEvents.v2ChatbotOpen, {
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				testName: this.practiceInfo.testName,
				qId: this.currentQ.qId,
				mode: "practice_" + this.currentMode
			});

			setTimeout(() => {
				try {

					$(".msg_container_base").stop().animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 0);

				} catch (error) {
					console.log(error);
				}
			}, 1);


		},
		closeChatbot() {
			this.chatbot = false
			recordAnalyticsEvent(
				AnalyticsEvents.v2ChatbotClose, {
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				testName: this.practiceInfo.testName,
				qId: this.currentQ.qId,
				mode: "practice_" + this.currentMode
			});

		},
		radioGroupUpdate() {
			// console.log("update");
			let currentVal = this.currentQResponse.checkedOption
			if (currentVal == '') {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
			this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== currentVal)
		},
		checkBoxesUpdate(val) {
			// console.log("update checkbox", val);
			let currentVals = this.currentQResponse.checkedOptions
			if (currentVals == []) {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
			this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !currentVals.includes(item))
		},
		checkIntegerUpdate() {
			let currentVals = this.currentQResponse.integerOption;
			if (currentVals.length == 0) {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
		},
		checkDecimalUpdate() {
			let currentVals = this.currentQResponse.decimalOption;
			console.log("updated value is ", currentVals, currentVals.length);
			if (currentVals.length == 0) {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
		},
		showCrossedOutOptions() {
			this.currentQResponse.crossedOutOptions = true
			if (this.currentQ.questionTypeId == "1") {
				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== this.currentQResponse.checkedOption)
			}

			if (this.currentQ.questionTypeId == "2") {

				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !this.currentQResponse.checkedOptions.includes(item))
			}
		},
		hideCrossedOutOptions() {
			this.currentQResponse.crossedOutOptions = false
		},
		checkOptionExistsInCrossedList(val) {
			let find = this.currentQResponse.crossedOutOptionsList.find((e) => e == val) ?? null
			if (find == null) {
				return false
			}
			return true

		},
		updateCrossedOptions(val, add) {
			if (add) {
				this.currentQResponse.crossedOutOptionsList.push(val)
				if (this.currentQ.questionTypeId == "1") {
					if (this.currentQResponse.checkedOption == val) {
						this.currentQResponse.checkedOption = ""
					}
				}
				if (this.currentQ.questionTypeId == "2") {
					// console.log(val)
					if (this.currentQResponse.checkedOptions.includes(val)) {
						this.currentQResponse.checkedOptions = this.currentQResponse.checkedOptions.filter(item => item !== val)
					}
				}
			}
			else {
				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== val)
			}
			// console.log(this.currentQResponse);
		},
		updateCrossedOptionsBot(res){
			this.currentQResponse.crossedOutOptions = true
			this.updateCrossedOptions(res.aiCrossedOutOption, true)
		},
		getMultichoiceSelected(key) {
			return this.currentQResponse.checkedOptions.includes(key)
		},
		getCurrentClientTimeInSeconds() {
			return Math.floor(Date.now() / 1000)
		},
		startTimer() {
			this.intervalId = setInterval(() => {
				this.remaningTimeInSeconds = (this.remaningTimeInSeconds - 1)
				this.serverTimeCounter += 1
			}, 1000);
		},
		minTwoDigits(n) {
			return (n < 10 ? '0' : '') + n;
		},

		getMarkDownHtml(markdown) {
			if (markdown == null) {
				return ""
			}
			let markDownText = markdown
			let out = false;
			const regex = /\\/gm;
			const escapedString = markDownText.replace(regex, "\\\\");

			out = marked.parseInline(escapedString, { renderer });
			out

			return out;
		},
		async nextQuestion() {
			this.controller.abort()
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			if (this.currentQSolution.nextQId == null || this.currentQSolution.nextQId == '') {
				recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenFinalSubmitButtonClicked, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					testName: this.practiceInfo.testName,
					oldSkillName: this.practiceReport?.oldPracticeSkillLevel,
					oldPraciceSkillScore: this.practiceReport?.oldPracticeSkillScoreToNextLevel,
					newSkillName: this.practiceReport?.newPracticeSkillLevel,
					newPracticeSkillScore: this.practiceReport?.newPracticeSkillScoreToNextLevel
				});

				recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenFinalSubmitButtonClicked, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					testName: this.practiceInfo.testName,
					oldSkillName: this.practiceReport?.oldPracticeSkillLevel,
					oldPraciceSkillScore: this.practiceReport?.oldPracticeSkillScoreToNextLevel,
					newSkillName: this.practiceReport?.newPracticeSkillLevel,
					newPracticeSkillScore: this.practiceReport?.newPracticeSkillScoreToNextLevel
				});
				if (this.$store.state.assignmentId.length > 0) {
					recordAnalyticsEvent(AnalyticsEvents.v2AssignmentEnd, {
						testId: this.practiceInfo.testId,
						testInstanceId: this.practiceInfo.testInstanceId,
						testName: this.practiceInfo.testName,
						oldSkillName: this.practiceReport?.oldPracticeSkillLevel,
						oldPraciceSkillScore: this.practiceReport?.oldPracticeSkillScoreToNextLevel,
						newSkillName: this.practiceReport?.newPracticeSkillLevel,
						newPracticeSkillScore: this.practiceReport?.newPracticeSkillScoreToNextLevel,
						assignmentId : this.$store.state.assignmentId
					});
				}

				this.$store.commit('appbarVisible', true);
				this.$store.commit('navbarVisible', true);
				this.$store.commit('showPracticeReport', true);
				this.$store.commit('getPracticeReport', this.currentQSolution.report?.practiceSkillProgress);
				this.$store.commit('updateCurrentAssignmentId', '')
				this.$store.commit("updateAssignmentPracticeInfo", {});
				if ("ta" in this.$route.query || !this.showStudentAssignmentSolution) {
					console.log("go to action")
					this.$store.commit('showStudentAssignmentSolution', true);
					this.$router.replace('/action')
				}
				else {
					console.log("go back")
					this.$router.back();

				}
				return
			}
			else {
				let body = {
					testInstanceId: this.practiceInfo.testInstanceId,
					testId: this.practiceInfo.testId,
					questionId: this.currentQSolution.nextQId,
				}

				this.$store.dispatch('nextPractice', body)
					.then(async () => {
						this.currentQ = this.practiceInfo.question;
						this.currentQResponse = _.cloneDeep(this.defualtCurrentQResponse);
						this.showSolution = false;

						this.chatbotAllowed = false;
						
						recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenNextQuestionButtonClicked, {
							testId: this.practiceInfo.testId,
							testInstanceId: this.practiceInfo.testInstanceId,
							testName: this.practiceInfo.testName,
							questionNo: this.currentQ.qDisplayNo,
							questionId: this.currentQ.qId,
							difficultyLevel: this.currentQ.difficultyLevel,
						});
						// this.currentMode = "practiceQuestion"
						// await this.getNudgeInfoForCurrentQuestion()

						this.chatbotConfig.currentMode = "practiceQuestion";
						this.chatbotConfig.chatbotMode = "practice_practiceQuestion";

						await this.$refs.questionActions.getNudgeInfo(this.currentQ);
						if(this.isDevEnvironment){
							this.checkElement("mainQuestionScreen");
						}
						//this.checkElement();
						setTimeout(() => {
							window.MathJax.typesetPromise();
						}, 10)
					})

				this.enableCheck = false;
				this.isNextQuestion = false;
				this.currentQDuration.startTime = this.getCurrentClientTimeInSeconds();

			}

		},

		clearResponse() {
			this.currentQResponse.checkedOption = ""
			this.currentQResponse.checkedOptions = []
			this.currentQResponse.integerOption = ""
			this.currentQResponse.decimalOption = ""
			this.enableCheck = false;
		},
		showReportDialog() {
			this.reportDialog = true
		},

		async validateQuestion(isSkipped) {
			console.log(this.controller);
			this.controller.abort()

			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
			this.currentQDuration.qId = this.currentQ.qId;
			this.currentQDuration.endTime = this.getCurrentClientTimeInSeconds();

			let responseBody = {};
			if (isSkipped) {
				this.isSkipped = isSkipped;
				this.currentQResponse = _.cloneDeep(this.defualtCurrentQResponse);
				this.currentQResponse.qId = this.currentQ.qId;
				this.currentQResponse.qType = this.currentQ.questionTypeId;
				this.currentQResponse.difficultyLevel = this.currentQ.difficultyLevel == "" ? "0" : this.currentQ.difficultyLevel;

				responseBody = {
					submit: false,
					topicId: this.currentQ.topicId,
					qDuration: this.currentQDuration,
					qResponse: this.currentQResponse
				}

				recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenSkipButtonClicked, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					testName: this.practiceInfo.testName,
					questionNo: this.currentQ.qDisplayNo,
					questionId: this.currentQ.qId,
					difficultyLevel: this.currentQ.difficultyLevel,
				});
			}
			else {
				this.currentQResponse.qId = this.currentQ.qId;
				this.currentQResponse.qType = this.currentQ.questionTypeId;
				this.currentQResponse.difficultyLevel = this.currentQ.difficultyLevel == "" ? "0" : this.currentQ.difficultyLevel;
				this.isSkipped = isSkipped;
				responseBody = {
					submit: false,
					topicId: this.currentQ.topicId,
					qDuration: this.currentQDuration,
					qResponse: this.currentQResponse
				}

				recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenCheckAnswerButtonClicked, {
					testId: this.practiceInfo.testId,
					testInstanceId: this.practiceInfo.testInstanceId,
					testName: this.practiceInfo.testName,
					questionNo: this.currentQ.qDisplayNo,
					questionId: this.currentQ.qId,
					difficultyLevel: this.currentQ.difficultyLevel,
				});
			}

			let body = {
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				responseBody: responseBody
			}
			this.$store.dispatch("validatePracticeQuestion", body)
				.then(async () => {
					this.currentQSolution = this.validatePracticeResponse;
					if (this.showStudentAssignmentSolution) {
						this.showSolution = true;
						// this.currentMode = "practiceSolution"
						// await this.getNudgeInfoForCurrentQuestion();
						this.chatbotAllowed = false;
						
						// this.screenshotElement = document.getElementById("mainSolutionScreen");
						// if (this.screenshotElement) {
						// 	if (this.screenshotElement.offsetHeight < window.innerHeight) {
						// 	this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
						// 	}
						// 	this.isChatBotAllowed = true;
						// 	// await this.getNudgeInfoForCurrentQuestion()
						// }
						this.chatbotConfig.currentMode = "practiceSolution";
						this.chatbotConfig.chatbotMode = "practice_practiceSolution";
						await this.$refs.questionActions.getNudgeInfo(this.currentQ);
						if(this.isDevEnvironment){
							this.checkElement("mainSolutionScreen");
						}
					}
					if (!this.showStudentAssignmentSolution) {
						await this.nextQuestion();
					}
					setTimeout(() => {
						window.MathJax.typesetPromise();
					}, 10)
				});
			if (this.showStudentAssignmentSolution) {
				this.isNextQuestion = true;
			}


		},
		exitPractice() {
			recordAnalyticsEvent(AnalyticsEvents.v2PracticeScreenLeaveButtonClicked, {
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				testName: this.practiceInfo.testName,
				questionNo: this.currentQ.qDisplayNo,
				questionId: this.currentQ.qId,
				difficultyLevel: this.currentQ.difficultyLevel,
			});
			console.log(this.$route.query);
			if ("ta" in this.$route.query) {
				console.log("go to action")
				this.$router.replace('/action')
			}
			else {
				console.log("go back")
				this.$router.back();

			}
		},
		getInitials(fullName) {
			const allNames = fullName.trim().split(' ');
			const initials = allNames.reduce((acc, curr, index) => {
				if (index === 0 || index === allNames.length - 1) {
					acc = `${acc}${curr.charAt(0).toUpperCase()}`;
				}
				return acc;
			}, '');
			return initials[0];
		},
		openHeresWHy() {
			window.open("https://mentomind.ai/how-do-large-language-models-power-mento/", '_blank');

		},

		integerChangedValue() {
			let changeText = _.filter(this.currentQResponse.integerOption, (v) => (/[0-9-./]$/.test(v)));
			if (changeText.slice(1).includes('-')) {
				changeText = [changeText[0], ..._.filter(changeText.slice(1), (v) => v != '-')];
			}
			this.currentQResponse.integerOption = changeText.join('');
		},
		checkValue(event) {
			const charCode = event.which || event.keyCode;
			if (!(/[0-9-./]$/.test(event.key)) && ![8, 35, 36, 37, 39, 46].includes(charCode)) {
				event.preventDefault();
			}
			if (event.key == '-' && event.target.value.includes('-') && event.target.selectionStart != 0) {
				event.preventDefault();
			}
		},
		preventDrag(event) {
			event.preventDefault(); // Prevent dragging within the input field
		},
		decimalChangedValue() {
			let changeText = _.filter(this.currentQResponse.decimalOption, (v) => (/[0-9-./]$/.test(v)));
			if (changeText.slice(1).includes('-')) {
				changeText = [changeText[0], ..._.filter(changeText.slice(1), (v) => v != '-')];
			}
			this.currentQResponse.decimalOption = changeText.join('');
		},
		mathFormatError(value) {
			return !(/^-?(\d*)?(\d*\.\d*)?(\d+\/(\d*\.\d*)?\d*)?$/.test(value))
		},
		openCalculator() {
			// recordAnalyticsEvent(
			// 	AnalyticsEvents.v2MockTestQuestionCalculatorButtonClick,
			// 	{
			// 	testId: this.currentTestId,
			// 	testInstanceId: this.startTestInfo.testInstanceId,
			// 	moduleId: this.selectedTest.moduleId,
			// 	questionId: this.currentQ.qId,
			// 	}
			// );
			if (this.$vuetify.display.mdAndUp) {
				if (this.calculatorDialog) {
					this.closeCalculator();
				}
				else {
					this.calculatorDialog = true;
				}
			} else {
				window.open("https://www.desmos.com/calculator", "_blank");
			}
		},
		closeCalculator() {
			this.calculatorDialog = false;
		},
		expandCalculatorDialog() {
			this.isCalculatorExpanded = !this.isCalculatorExpanded;
			if (this.isCalculatorExpanded) {
				this.calculatorDimension = {
					...this.calculatorDimension,
					w: this.width * 0.45 - 10,
					h: this.height * 0.75
				};
			} else {
				this.calculatorDimension = {
					...this.calculatorDimension,
					w: 450,
					h: 500
				};
			}
		},
		openReferences() {
			// recordAnalyticsEvent(
			// 	AnalyticsEvents.v2MockTestQuestionReferencesButtonClick,
			// 	{
			// 	testId: this.currentTestId,
			// 	testInstanceId: this.startTestInfo.testInstanceId,
			// 	moduleId: this.selectedTest.moduleId,
			// 	questionId: this.currentQ.qId,
			// 	}
			// );
			this.selectedReferenceDimension = this.xs ? this.referenceDimensionMobile : this.referenceDimension;
			this.referenceDialog = !this.referenceDialog;
		},
		draggableStart() {
			this.draggable = true;
		},
		draggableEnd() {
			this.draggable = false;
		},
		referenceDraggableStart() {
			this.referenceDraggable = true;
		},
		referenceDraggableEnd() {
			this.referenceDraggable = false;
		},
		async checkElement(elementId){
			console.log("Check element", this.chatbotAllowed);
			this.screenshotElement = document.getElementById(elementId);
			if (this.screenshotElement) {
				if (this.screenshotElement.offsetHeight < window.innerHeight) {
				this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
				}
				this.isChatBotAllowed = true;
				// await this.getNudgeInfoForCurrentQuestion()
			} else {
				setTimeout(() => {this.checkElement(elementId);}, 100); // Retry after 100ms if element is not found
			}
		}
	},
	computed: {
		...mapState(["practiceInfo", "validatePracticeResponse", "practiceNudgeInfo", "config", "showStudentAssignmentSolution"]),
		...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
		userName: {
			get() {
				return this.$store.state.userState.name
			}
		},
		chatbotAllowed: {
			get() {
				if(this.isDevEnvironment){
					return this.config.practiceChatBotEnabled && this.isChatBotAllowed
				}
				else{
					return this.config.practiceChatBotEnabled && this.practiceInfo.subjectType == 'readingandwriting'
				}
				// return this.config.practiceChatBotEnabled 

			}
		},
		noOfQuestions: {
			get() {
				if ("questionsCount" in this.$store.state.assignmentPracticeInfo) {
					return this.$store.state.assignmentPracticeInfo.questionsCount
				}
				return this.config.practiceNumberOfQuestions
			}
		},
		currentQ: {
			get() {
				return this.practiceInfo.question;
			}
		},
		aiTokensUsedForBar: {
			get() {
				return (this.aiTokensUsed / this.aiTokensAllowed) * 100;
			}
		},
		aiTokensLeft: {
			get() {
				return this.aiTokensAllowed - this.aiTokensUsed;
			}
		},
		aiTokensUsedForBarColorComputed: {
			get() {
				if (this.aiTokensUsedForBar > 75) {
					return this.aiTokensUsedForBarColor.red
				}
				else if (this.aiTokensUsedForBar > 50) {
					return this.aiTokensUsedForBarColor.orange
				}
				else {
					return this.aiTokensUsedForBarColor.green
				}
			}
		},
		isDevEnvironment: {
			get(){
				return this.config.environment=="dev";
			}
		},
		// chatbotheight:{
		// 	get()
		// 	{
		// 		console.log("heighjt",((window.innerHeight)*(45/100))/window.devicePixelRatio);
		// 		return ((window.innerHeight)*(45/100))/window.devicePixelRatio

		// 	}
		// }
		feedbackOrQuestionReiewIconName: {
			get() {
				var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
				if (this.isQuestionReviewer) {
					retVal = "mdi-shield-check-outline"; // for internal Question reviewers
				}
				return retVal;
			},
		},
		feedbackOrQuestionReiewIconTooltipText: {
			get() {
				var retVal = "Report a problem" ////"Report a Problem" for users/students
				if (this.isQuestionReviewer) {
					retVal = "Review Feedback & Corrections"; // for internal Question reviewers
				}
				return retVal;
			},
		},
	},

}
</script>


<style scoped>
canvas {
	max-width: 100% !important;
}

#canvasDiv {
	max-width: 100vw !important;
}

.frame {
	height: calc(100% - 60px) !important;
	width: 100% !important;
}

.v-textarea input::placeholder {
	color: red;
	/* Your desired color */
}


.btn-rd {
	border-radius: 100px !important;
	border: 1.5px solid #966DEC;
	background: #FFF;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12)
}

.bot-msg {
	background: rgba(230, 228, 233, 0.46);
}

.user-msg {
	/* background-color: rgb(var(--v-theme-primary)) !important;
	border-radius: 30px; */

}

.chatbot-card {
	border-radius: 12px;
	border-color: transparent;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.chatbot-bg {
	background-color: white !important;
	overflow-y: auto;
	/* -ms-overflow-style: none; */
	/* IE and Edge */
	/* scrollbar-width: none; */
	/* Firefox */
	overflow-x: hidden;
	scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #D3D3D3;
	scrollbar-width: thin;


}

.chatbot-bg::-webkit-scrollbar {
	/* display: none; */
}

.chatbot-bg::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.chatbot-bg::-webkit-scrollbar-track {
	background-color: #D3D3D3;
}

/* Handle */
.chatbot-bg::-webkit-scrollbar-thumb {
	background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
	cursor: pointer;

}

/* Handle on hover */
.chatbot-bg::-webkit-scrollbar-thumb:hover {
	cursor: pointer;

}

.dround {
	border-radius: 10px !important;
}

.chatbotText {
	color: rgba(0, 0, 0, 0.80);
	font-family: Inter;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 500;
}

/* Hide scrollbar for IE, Edge and Firefox */


.chatbot {
	position: fixed;
	right: 30px;
	bottom: 10%;
	z-index: 1005;
}

.chatbot-box {
	position: fixed;
	right: 20px;
	bottom: 10%;
	z-index: 1010;

}

.chatbot-header {
	background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
}

.chatbot-bottom {
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
}

.chatbotsendbuttondisabled {
	pointer-events: none;

}

.dialog-rounded .overlay-scrim {
	border-radius: 10px !important;
}

.noClick {
	pointer-events: none;
}

.w450px {
	width: 450px;
}

.w240px {
	width: 370px;
}

.slide-fade-enter-active {
	transition: all 0.3s linear;
}

.slide-fade-leave-active {
	transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(100px);
	opacity: 0;
}

.correct {
	background: #26B99AA6 !important;
	color: white !important;
	;
}

.mento {
	color: #FEFEFE;
	font-family: Prompt;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	/* 24px */
}

.mento-sup {
	color: #FEFEFE;
	font-family: Prompt;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	/* 24px */
}

.mind {
	color: #A174FD;
	font-family: Prompt;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	/* 24px */
	letter-spacing: 0.48px;
}

.correctQ {
	background: #26B99A;

}

.img {
	vertical-align: middle;
}

.success {
	background: #26B99AA6 !important;

}

.totalQ {
	background: rgba(255, 255, 255, 0.15);

}

.timer-color {
	color: #26B99A !important;
}

.bgW {
	height: 100% !important;
	width: 100%;
	background-color: white;
}

.bgWColor {
	width: 100%;
	background-color: white;
}

.bgP {
	height: 100% !important;
	width: 100%;
	background-color: #2B2B45;
}

.bgPColor {
	background-color: #2B2B45;

}

.unanswered {
	border: 2px solid #949494 !important;
}

.unansweredQ {
	background: rgba(255, 255, 255, 0.15);
}

.marked {
	border: 4px solid #FF894F !important;
}

.markedQ {
	border: 4px solid #FF894F !important;
}

.btn-grad {
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
	border-radius: 29.4079px;
	color: #ffffff !important;
}

.btn-prev {
	border-radius: 29.4079px;
}

.bgWhite {
	background-color: white !important
}

.disabledOpacity {
	opacity: var(--v-disabled-opacity) !important;
}

.option {

	/* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
	border-radius: 10px;
	border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.subColor {
	color: rgba(255, 255, 255, 0.5) !important;
}

.v-sheet.on-hover {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: white;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.redTimer {
	color: #FF786E !important;
}

.w-70 {
	max-width: 70%;
}

.submitFont {
	font-size: 0.7841875rem;
	font-family: "Inter", sans-serif !important;

}

.blink {
	animation: blinker 1s step-start infinite;
}

.v-textarea textarea::placeholder {
	color: white !important;
	/* Your desired color */
	opacity: 1 !important;
}

.strike {
	position: absolute;
	width: calc(100%);
	height: 2px;
	background: rgb(var(--v-theme-primaryPurple));
	left: 0px;
	top: 50%;
	opacity: 1 !important;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.bx {
	background-color: rgb(var(--v-theme-navColor));
	border: 1px solid #E7E7E7;
	border-radius: 4px;
}

.card-bg-color {
	background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
	border-radius: 12px;
	background: #26253A;

}

.outline-color {
	border-color: rgba(0, 0, 0, 0.2) !important;
}

.outline-color-grey {
	border-color: #010101b3 !important;
	border: 1px solid;
	color: #010101b3 !important;

}

.outline-color-white {
	border-color: white !important;
	background-color: white;
}

.bottom {
	position: absolute !important;
	bottom: 10px !important;
}

.v-overlay__content {
	position: absolute !important;
	bottom: 10px !important;

}

.layoutLarge {
	height: calc(100% - 160px);
}

.scroll {}

.blink {
	animation: blinker 1s step-start infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.selected {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
	color: white !important
}

.qNoClass {
	font-size: 0.875rem;
	font-family: "Inter", sans-serif !important;
}

.dialog-bx {
	position: fixed !important;
}

.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.more-btn {
	padding: 5px 10px;
	border: 1px solid white;
	border-radius: 12px;
}

.calculator-container {
	width: fit-content;
	height: fit-content;
	position: absolute;
	z-index: 10;
	top: 15%;
	left: 5%;
}

.calculator-parent {
	/* position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  margin: 0px !important; */
	z-index: 3000;
}

.reference-body {
	height: calc(100% - 60px);
	overflow: hidden;
	overflow-y: auto;
}

.main-draggable {
	position: absolute !important;
	top: 0px !important;
	left: 0px !important;
	margin: 0px !important;
	width: 100vw;
	height: 90vh;
}

.calculator-heading {
	background-color: rgb(var(--v-theme-dialogHeader)) !important;
	color: white !important;
}

.draggable {
	cursor: grab;
}
</style>
<style>
.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
	opacity: 1 !important;
}


.inputText {
	color: black !important;

	/* 114.286% */
}



.practiceField .v-field__outline__start {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
	opacity: 1 !important;
}

.practiceField .v-field__outline__end {
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	opacity: 1 !important;

}


.custom-radio .v-selection-control__wrapper {
	display: none;
}

.practiceField .v-field__append-inner>.v-icon {
	opacity: 1;
}

.practiceField .v-field__input {
	color: rgba(92, 95, 98, 1) !important;
}


.dialog-rounded .v-overlay__scrim {
	border-radius: 10px !important;
}
</style>
