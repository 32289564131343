import store from "../store";
import { storeReferralId, getReferralId, clearReferralId, getTestId, clearTestId, clearRequestId } from "@/services/authService";
import moment from "moment-timezone";
import router from "@/router";
import {
    AnalyticsEvents,
    recordAnalyticsEvent,
  } from "@/services/awsPinpointAnalyticsService";

export async function signInLoad() {
    // console.log('signInLoad()');
    const referralId = getReferralId();
    if (referralId) {
        // console.log('referralId', referralId)
        await store.dispatch("storeReferralCode", {
            referralId: referralId
        })
        // clearReferralId()
    }
}

export async function teacherAssignment() {
    let testId = store.state.teacherTestId ?? null
    if (testId == null) {
        console.log("no test id");

        return null
    }
    await store.dispatch("shareCollectionLinkOpened", { testId: testId })

    await store.dispatch("teacherAssignmentAccess", { testId: testId })

    await store.dispatch("getAPIUrlsForFlavorAndVersion", {})

    let linkSnackbarState = store.state.linkSnackbarState
    if (linkSnackbarState) {
        return null
    }

    store.commit('updateTeacherTestId', null)
    store.commit("shareCollectionLinkOpened", {})

    await store.dispatch("startTeacherAssignment", {
        testId: testId, currentDate: moment().format("YYYY-MM-DD"),
        currentDateTimeStamp : getStartTimeStamp(moment())
    })
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherAssignmentStart, {
        testId: testId, currentDate: moment().format("YYYY-MM-DD"),
        currentDateTimeStamp : getStartTimeStamp(moment())
      })
    // console.log(store.state.practiceInfo)
    let practiceInfo = store.state.practiceInfo;
    if (practiceInfo.success == false) {
        return null
    }
    else {
        store.commit('updateCurrentAssignmentId', practiceInfo.assignmentId)
        store.commit('showStudentAssignmentSolution', true);
        store.commit("getTopicName", practiceInfo.testName);
        store.commit('appbarVisible', false);
        store.commit('navbarVisible', false);
        store.commit("showNotification", false);
        store.commit("updateTestRoutingAllowed", true);
        router.replace(`/practicequestionpage?ta=true`);
    }
    return true
}

export async function apiCallsOnSigningUp(){
    // console.log("apiCallsOnSigningUp");
    await store.dispatch("getProfileDetails", {});
    await store.dispatch("getNotificationsList", {});
    if (store.state.isUserSuperAdmin) {
        store.dispatch("getCustomersList");
    }
    store.dispatch("getFeatureFlagsList", {});
    
    store.commit("assignmentsLoadedFromServer", false)
    store.dispatch("loadAssignments", {
        isCurrent: true,
        currentDate: moment().format("YYYY-MM-DD")
    })

    store.dispatch("getUserPreferences", {});
    store.dispatch("fetchSelfHelpGuideScript");
}

function getDateToString() {
    let d = new Date();
    return moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("YYYY-MM-DD");
}
function getStartTimeStamp(date) {
    let localDate = new Date(date); // current local date and time
    return Math.floor(localDate.getTime() / 1000);
}