<template>
  <!-- Delete Student -->
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent :model-value="dialogDelete" max-width="400px">
    <v-card elevation="0" class="student-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0">
        <span class="student-form-title">Are you sure you want to delete '{{ editedItem.name }}'?</span>
      </v-card-text>
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-primaryTextColor mr-2" density="default" variant="flat" height="40"
          @click.stop="closeDeleteDialog()">No</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="deleteItem()">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Dialog for edit/update student -->
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent :model-value="dialog" width="900">
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-account" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle() }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 student-form-main">
        <v-form ref="form" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.name" label="Name*" density="compact" variant="outlined"
                :rules="requiredRule" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="">
            <v-col cols="auto" class="pt-3 pb-0">
              <span>Login Type*: </span>
            </v-col>
            <v-col class="">
              <v-radio-group v-model="editedItem.loginType" inline dense class="" :rules="requiredRule" :disabled="checkIfEditingInfo()">
                <v-radio label="Email" value="email"></v-radio>
                <v-radio label="Phone" value="phone"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="editedItem.email" :label="`Email ${editedItem.loginType == 'email' ? '*' : ''}`"
                density="compact" variant="outlined" :rules="editedItem.loginType == 'email' ? rules.email : editedItem.email.length > 0 ? rules.email : []"
                dense :disabled="checkIfEditingInfo()"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-0" dense>
            <v-col cols="12">
              <tel-input v-model="displayStudentPhoneNumber" @updatedPhoneNumberObject="updateStudentPhoneNumberInput" :disabled="checkIfEditingInfo()" :placeholder="`Student Phone Number ${editedItem.loginType == 'phone' ? '*' : ''}`" :required="editedItem.loginType == 'phone'" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="10" sm="6">
              <v-select :items="packageList" item-title="packageName" item-value="packageId"
                v-model="editedItem.packageId" label="License" density="compact" variant="outlined" dense></v-select>
            </v-col>
            <v-col cols="auto">
              <v-tooltip location="top" max-width="300px">
                <template #activator="{ props }">
                  <v-icon small class="ml-2 mt-2" color="iconGrey" v-bind="props">
                    mdi-information-variant-circle-outline
                  </v-icon>
                </template>
                <span>If it is a prospect, leave the license unassigned.</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row align="center" class="ma-0 pa-0 parent-form">
            <v-col cols="auto" class="pa-0">
              <span class="main-heading">Digital SAT Accommodations</span>
            </v-col>
            <v-col class="pa-0">
              <v-divider :thickness="1" color="background"></v-divider>
            </v-col>
          </v-row>

          <v-row dense class="align-center">
            <v-col cols="auto">
              Extended Time
            </v-col>
            <v-col cols="auto">
              <v-tooltip location="top" max-width="300px">
                <template #activator="{ props }">
                  <v-icon small class="mr-2" color="iconGrey" v-bind="props">
                    mdi-information-variant-circle-outline
                  </v-icon>
                </template>
                <span>Enter the time for Reading & Writing and Math modules.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-switch color="primaryPurple" v-model="editedItem.isDSATAccommodation" hide-details dense density="compact"></v-switch>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.isDSATAccommodation">
            <v-col cols="6">
              <v-text-field label="Reading & Writing Module Time (Minutes)" v-model="editedItem.accommodationDetails.dSATReadingAndWritingTimeDurationInMins" type="number" density="compact" variant="outlined" dense :rules="rules.questionNumber"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Math Module Time (Minutes)" v-model="editedItem.accommodationDetails.dSATMathTimeDurationInMins" density="compact" variant="outlined" type="number" dense :rules="rules.questionNumber"></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center" class="ma-0 pa-0 parent-form">

            <v-col cols="auto" class="pa-0">
              <span class="main-heading">Parent Details</span>
            </v-col>
            <v-col cols="auto">
              <v-tooltip location="top" max-width="200px">
                <template #activator="{ props }">
                  <v-icon small class="mr-2" color="iconGrey" v-bind="props">
                    mdi-information-variant-circle-outline
                  </v-icon>
                </template>
                <span>If you want the student's test report to be shared with parents automatically, enter the details here.</span>
              </v-tooltip>
            </v-col>
            <v-col class="pa-0">
              <v-divider :thickness="1" color="background"></v-divider>
            </v-col>


          </v-row>

          <template v-if="showParentForm">
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.guardianName" label="Parent Name" density="compact" variant="outlined"
                  dense></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="editedItem.guardianEmail" label="Parent Email" density="compact" variant="outlined" :rules=" editedItem.guardianEmail.length > 0 ? rules.email : []" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <tel-input v-model="displayGuardianPhoneNumber" @updatedPhoneNumberObject="updateGuardianPhoneNumber" placeholder="Parent Phone Number"/>
              </v-col>
            </v-row>
          </template>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.notes" label="Notes" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="isUserSuperAdmin && editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default" variant="flat" height="40" @click.stop="openDeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="save">{{ editedIndex == -1 ? "Save" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Dialog for student assignment -->
  <v-dialog style="backdrop-filter: blur(12.5px)" :model-value="assignmentDialog" width="900" persistent>
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-account" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">Assign Tutor or Classes</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="closeAssignmentDialog" class="mr-4">
                mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 student-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">

              <v-select multiple readonly v-model="editedAssignmentItem.studentName" label="Name" density="compact"
                variant="outlined" dense>
              </v-select>

            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select :items="tutorList.slice(1)" item-title="name" item-value="tutorId"
                v-model="editedAssignmentItem.assignedToTutors" label="Assigned To Tutor" density="compact"
                variant="outlined" multiple dense>
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="editedAssignmentItem.assignedToTutors.length == this.tutorList.length && index == 0">All</span>
                  <span v-else-if="editedAssignmentItem.assignedToTutors.length > 1 && index == 0">
                    {{ item.title }} + {{ editedAssignmentItem.assignedToTutors.length - 1 }}
                  </span>
                  <span v-else-if="editedAssignmentItem.assignedToTutors.length == 1 && index == 0">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select :items="classList.slice(1)" item-title="name" item-value="batchId"
                v-model="editedAssignmentItem.assignedToBatches" label="Assigned To Class" density="compact"
                variant="outlined" multiple dense>
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="editedAssignmentItem.assignedToBatches.length == this.classList.length && index == 0">All</span>
                  <span v-else-if="editedAssignmentItem.assignedToBatches.length > 1 && index == 0">
                    {{ item.title }} + {{ editedAssignmentItem.assignedToBatches.length - 1 }}
                  </span>
                  <span v-else-if="editedAssignmentItem.assignedToBatches.length == 1 && index == 0">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="closeAssignmentDialog">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="saveAssignmentDialog()">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Main Screen -->
  <v-container fluid v-if="showStudentScreen" class="assignment-card">
    <v-row class="mb-6 ma-0" justify="space-between" align="center">
      <v-col class="pa-0 ma-0 mr-5">
        <v-row class="pa-1 card-bg pa-0 ma-0" justify="space-between" align="center" ref="AdminStudentsScreen__studentsComponent--searchBox">
          <v-col cols="auto" class="heading pa-0 ma-0 ml-3"> Students </v-col>
          <v-divider vertical class="ml-5 mr-3" :thickness="3"></v-divider>
          <v-col class="ma-0 pa-0">
            <v-text-field density="compact" variant="solo" flat label="Search by Name or Contact Details"
              append-inner-icon="mdi-magnify" class="search-bar" bg-color="report-subCardBg" hide-details="true"
              v-model="searchFilter" v-on:input="debounceSearch" single-line></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="pa-0 ma-0">
        <v-btn class="t-btn text-white activity-btn text-none t-1remReg" density="default" variant="flat" height="46"
          @click="createNewStudent" ref="AdminStudentsScreen__studentsComponent--addStudent">Add student</v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-6 ma-0" align="center">
      <v-btn v-if="selectedStudents.length > 0" class="t-btn text-white activity-btn text-none t-1remReg"
        density="default" variant="flat" height="40" @click="assignMultipleStudents()">
        Assign Students
      </v-btn>

      <v-spacer v-if="$vuetify.display.mdAndUp && selectedStudents.length > 0"></v-spacer>

      <v-col cols="auto" class="pa-0 pr-3">
        <span class="t-1remReg text-bold">Filter</span>
      </v-col>


      <!-- Assigned To Filter -->
      <v-col cols="auto" class="pa-0 pr-3">
        <div class="filter-dropdown-2">
          <v-select class="filter-box" base-color="report-extraText" color="report-extraText" item-color="black" flat
            density="compact" :items="tutorAndClassCombinedList" v-model="filterStudents.combinedList" label="Tutor/Class"
            item-title="name" item-value="uniqueId" multiple variant="outlined" hide-details
            prepend-inner-icon="mdi-human-male-board" ref="AdminStudentsScreen__studentsComponent--filter0">

            <template v-slot:selection="{ item, index }">
              <span v-if="filterStudents.combinedList.length == tutorAndClassCombinedList.length && index == 0">All</span>
              <span v-else-if="filterStudents.combinedList.length > 1 && index == 0"> +{{
                filterStudents.combinedList.length }}</span>
              <span v-else-if="index == 0 && item.title.length <= 8"> {{ item.title }} </span>
              <span v-else-if="index == 0 && item.title.length > 8"> {{ item.title.substring(0, 8) }}... </span>
            </template>

          </v-select>
        </div>
      </v-col>

      <v-col cols="auto" class="pa-0 pr-3">
        <div class="filter-dropdown">
          <v-select class="filter-box" base-color="#ffffff" color="#ffffff" item-color="black" flat density="compact"
            :items="packageList" v-model="filterStudents.packageName" label="License" item-title="packageName"
            item-value="packageId" multiple variant="outlined" hide-details prepend-inner-icon="mdi-license" ref="AdminStudentsScreen__studentsComponent--filter1">
            <template v-slot:prepend-item>
              <v-list-item title="All" @click="togglePackageList">
                <template v-slot:prepend>
                  <v-checkbox-btn
                    :indeterminate="filterStudents.packageName.length > 0 && !(filterStudents.packageName.length == packageList.length)"
                    :model-value="filterStudents.packageName.length == packageList.length"></v-checkbox-btn>
                </template>
              </v-list-item>

              <v-divider class="mt-2"></v-divider>
            </template>

            <template v-slot:selection="{ item, index }">
              <span v-if="filterStudents.packageName.length == packageList.length &&
                index == 0
                ">All</span>
              <span v-else-if="filterStudents.packageName.length > 1 && index == 0">
                +{{ filterStudents.packageName.length }}
              </span>
              <span v-else-if="index == 0 && item.title.length <= 5">
                {{ item.title }}
              </span>
              <span v-else-if="index == 0 && item.title.length > 5">
                {{ item.title.substring(0, 5) }}...
              </span>
            </template>
          </v-select>
        </div>
      </v-col>

      <v-spacer v-if="$vuetify.display.mdAndUp && selectedStudents.length == 0"></v-spacer>

      <v-col cols="auto" class="pa-0">
        <div class="filter-dropdown">
          <v-select class="filter-box" density="compact" :items="sortList" v-model="sortCategory" label="Sort by"
            item-title="sortName" item-value="sortId" variant="outlined" hide-details prepend-inner-icon="mdi-sort" ref="AdminStudentsScreen__studentsComponent--filter2">
          </v-select>
        </div>
      </v-col>
    </v-row>

    <template v-for="(item, i) in paginatedStudentsList" :key="i">
      <v-card class="card-bg card-border px-2 mb-3 py-2" @click="editStudentDetails(item)" elevation="0" :ref="i==0? `AdminStudentsScreen__studentsComponent__studentsList--card${i}` : ''">
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <!-- Name -->
            <v-col cols="3" class="d-flex align-center justify-start pa-1">
              <div class="mr-4">
                <v-avatar size="34" color="report-subCardBg icon-bg">
                  <v-icon icon="mdi-account" color="#ffffff" class="" size="20"></v-icon>
                </v-avatar>                  
              </div>
              <div class="pb-0 wrap-text">
                <div class="d-flex flex-column justify-start">
                  <span v-if="item.studentName != 'null'" class="t-1remReg">{{
                    item.name
                  }}</span>
                  <span v-else class="t-1remReg"> - </span>
                </div>
                <div class="">
                  <span class="t-h6Reg parameter-style">Last Online {{getTime(item.lastLoginTimeStamp)}} </span>
                </div>

              </div>
            </v-col>

            <!-- Contact -->
            <v-col cols="3" class="d-flex flex-column align-start pa-1">
              <div v-if="showPhoneNumber(item.mobileNumber)" class="pb-0 wrap-text">
                <span class="t-h6Reg">{{ item.mobileCountryCode }}{{ item.mobileNumber }}</span>
              </div>
              <div v-if="showEmail(item.email)" class="wrap-text">
                <span class="t-h6Reg wrap-text">{{ item.email }}</span>
              </div>
            </v-col>

            <!-- License -->
            <v-col cols="2" class="d-flex flex-column align-start pa-1">
              <v-tooltip v-if="!item.packageDetails.packageId" location="top" max-width="400px">
                <template #activator="{ props }">
                  <div class="pb-0" v-bind="props">
                    <span class="t-h6Reg text-capitalize">-</span>
                  </div>
                </template>
                <span>Student is not assigned any license</span>
              </v-tooltip>
              <div v-else class="pb-0">
                <span class="t-h6Reg text-capitalize">{{
                  getPackageName(item.packageDetails.packageId)
                }}</span>
              </div>
              <div v-if="item.packageDetails.packageId" class="">
                <span class="t-h6Reg parameter-style">Ends {{ displayLocalDate(item.packageDetails.packageEndDate)
                }}</span>
              </div>
            </v-col>

            <!-- Assigned To -->
            <v-col cols="2" class="d-flex flex-column align-start pa-1">
              <v-tooltip location="top" max-width="400px">
                <template #activator="{ props }">
                  <div class="pb-0" v-bind="props">
                    <span class="t-h6Reg text-capitalize">{{
                      getAssignedTo(item)
                    }}</span>
                  </div>
                  <div class="">
                    <span class="t-h6Reg parameter-style">Assigned To</span>
                  </div>
                </template>
                <span>{{ getAssignedToComplete(item) }}</span>
              </v-tooltip>
            </v-col>

            <!-- Edit -->
            <v-col cols="auto">
              <v-row justify="end" align="center">
                <v-col cols="auto" class="ma-0 pa-0 mr-4">
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                        prepend-icon="mdi-account-plus-outline"
                        size="small"
                        density="comfortable"
                        rounded="xl"
                        stacked
                        variant="text"
                        class="text-none"
                        @click.stop="assignStudents(item)" v-bind="props">
                        Assign
                      </v-btn>
                    </template>
                    <span>Assign to Tutors or Classes</span>
                  </v-tooltip>
                </v-col>

                <v-col cols="auto" class="ma-0 pa-0">
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                      prepend-icon="mdi-pencil"
                      style="text-transform: none;"
                      size="small"
                      density="comfortable"
                      rounded="xl"
                      stacked
                      variant="text"
                      @click.stop="editStudentDetails(item)" v-bind="props">
                        Edit
                      </v-btn>
                    </template>
                    <span>Edit Student</span>
                  </v-tooltip>
                  
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <v-card v-if="paginatedStudentsList.length == 0" class="card-bg card-border px-2 mb-3 py-2" elevation="0" ref="AdminStudentsScreen__studentsComponent__studentsList--card0">
      <v-card-text class="text-primaryTextColor pa-0">
        <v-row justify="space-between" align="center" class="ma-0 px-1">
          <v-col cols="12" class="pa-3 text-center t-h6Reg">
            <span>No Student Found</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="totalPages>1" class="ma-0" justify="center">
      <v-col cols="auto" class="pa-0">
        <v-pagination v-model="currentPageNumber" :length="totalPages" total-visible="5" size="small" color="white" rounded="lg"></v-pagination>
      </v-col>
    </v-row>

  </v-container>

  <v-snackbar v-model="showStudentMessage" color="cardBackgroundColor" :timeout="-1" location="top">
    <span class="submit-message-text text-report-red">{{ studentErrorMessage }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapGetters, mapState } from "vuex";
import moment from "moment-timezone";
import _, { get, isNil } from "lodash";
import TelInput from '@/shared/components/tel-input.vue';
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";

export default {
  name: "AdminStudentsComponent",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  components: {TelInput},
  emits: ['updateStudentNumber'],
  created() {
    recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenOpen, {
      courseId: this.courseId,
    });

    this.$store.commit("updateStudentName", "");
    this.$store.dispatch("getTutorList", { courseId: this.courseId }).then(() => this.tutorListLoaded = true);
    this.$store.dispatch("getClassList", { courseId: this.courseId }).then(() => this.classListLoaded = true);
    this.$store.dispatch("getPackageList", { courseId: this.courseId }).then(() => this.packageListLoaded = true);
    this.$store
      .dispatch("loadAdminStudentList", { courseId: this.courseId })
      .then((res) => {
        this.studentsDetailLoaded = true;
      });
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: 'AdminStudentsScreen',
    formValue: false,
    studentsDetailLoaded: false,
    packageListLoaded: false,
    classListLoaded: false,
    tutorListLoaded: false,
    searchFilter: "",
    filterStudents: {
      packageName: [],
      combinedList: [],
    },
    sortCategory: null,
    // { sortName: "License Start Date (Recent)", sortId: "startDateRecent" },
    // { sortName: "License Start Date (Oldest)", sortId: "startDateOldest" },
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
      { sortName: "Last Online (Recent)", sortId: "onlineRecent" },
      { sortName: "Last Online (Oldest)", sortId: "onlineOldest" },
      { sortName: "License End Date (Earliest)", sortId: "endDateRecent" },
      { sortName: "License End Date (Latest)", sortId: "endDateOldest" },
    ],
    dialog: false,
    dialogDelete: false,
    assignmentDialog: false,
    defaultAssignmentItem: {
      studentName: [],
      studentIds: [],
      assignedToBatches: [],
      assignedToTutors: [],
    },
    editedAssignmentItem: {
      studentName: [],
      studentIds: [],
      assignedToBatches: [],
      assignedToTutors: [],
    },
    defaultItem: {
      userId: "",
      name: "",
      email: "",
      loginType: "email",
      mobileCountryCode: "",
      packageId: "",
      packageType: "",
      packageDetails: {
        packageId: "",
        packageEndDate: "",
      },
      mobileNumber: "",
      guardianName: "",
      guardianEmail: "",
      guardianMobileCountryCode: "",
      guardianMobileNumber: "",
      licenseStartDate: "",
      licenseEndDate: "",
      notes: "",
      active: true,
      isDSATAccommodation: false,
      accommodationDetails:{
        dSATReadingAndWritingTimeDurationInMins:32,
        dSATMathTimeDurationInMins: 35
      },
    },
    editedItem: {
      userId: "",
      name: "",
      email: "",
      loginType: "email",
      mobileCountryCode: "",
      packageId: "",
      packageType: "",
      packageDetails: {
        packageId: "",
        packageEndDate: "",
      },
      mobileNumber: "",
      guardianName: "",
      guardianEmail: "",
      guardianMobileCountryCode: "",
      guardianMobileNumber: "",
      licenseStartDate: "",
      licenseEndDate: "",
      notes: "",
      active: true,
      isDSATAccommodation: false,
      accommodationDetails:{
        dSATReadingAndWritingTimeDurationInMins:32,
        dSATMathTimeDurationInMins:35
      },
    },
    editedIndex: -1,
    requiredRule: [(v) => !!v || "Required"],
    rules: {
      email: [(v) => !!(v || "").match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || "Please enter a valid email"],
      phoneNumber: [(v) => !!(v || "").match(/^\d{10}$/) || "Please enter a valid mobile number"],
      questionNumber: [
        (v) => !!(v ? (v+ "") :"").match(/^[0-9]+(\.[0-9]+)?$/) ||  "Please enter only number (int or decimal)",
        (v) => parseFloat(v) > 0 || "Number should be greater than 0"
      ],
    },
    showParentForm: true,
    selectedStudents: [],
    defaultSelectedStudents: [],
    showStudentMessage: false,
    studentErrorMessage: '',
    timeout: 3000,
    currentPageNumber: 1,
    itemsPerPage: 100,
    phoneProps: {
      autoFormat: true,
      required: true,
      disabledFetchingCountry: false,
      enabledFlags: true,
      dropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
      },
      inputOptions: {
        placeholder: "Phone Number",
        type: "tel",
      },
      mode: "international",
      validCharactersOnly: true,
      defaultCountry: "US",
      onlyCountries: ["US", "IN"],
      invalidMsg: 'Please enter correct phone number'
    },
    displayStudentPhoneNumber: '',
    displayGuardianPhoneNumber: '',
    isValidGuardianPhoneNumber: false,
    isValidStudentPhoneNumber: false,
  }),
  methods: {
    debounceSearch() {
      if (this.searchFilter.trim() == "") {
        this.$store.commit("clearSearchedAdminStudentList");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        if (this.searchFilter.trim() != "") {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenStudentSearched, {
            courseId: this.courseId,
            searchedKeyword: this.searchFilter.trim()
          });

          this.$store.dispatch("loadSearchedAdminStudentList", {
            search: this.searchFilter.trim(),
            courseId: this.courseId,
          });
        }
      }, 700); // delay
    },
    getTime(time) {
      if (time == null || time == 0) {
        return "-";
      }
      let vardata = moment(time).fromNow();
      return vardata;
    },
    displayLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      date = date * 1000;
      let displayDate = moment(date).format("YYYY-MM-DD");
      return moment(displayDate).format("ll");
    },
    showPhoneNumber(item) {
      let phoneNumber = item + "";
      return !phoneNumber.includes("null");
    },
    showEmail(item) {
      let email = item + "";
      return !email.includes("null");
    },
    toggleClassList() {
      if (this.filterStudents.className.length == this.classList.length) {
        this.filterStudents.className = [];
      } else {
        this.filterStudents.className = this.classList
          .slice()
          .map((e) => e.batchId);
      }
    },
    toggleTutorList() {
      if (this.filterStudents.tutors.length == this.tutorList.length) {
        this.filterStudents.tutors = [];
      } else {
        this.filterStudents.tutors = this.tutorList
          .slice()
          .map((e) => e.tutorId);
      }
    },
    togglePackageList() {
      if (this.filterStudents.packageName.length == this.packageList.length) {
        this.filterStudents.packageName = [];
      } else {
        this.filterStudents.packageName = this.packageList
          .slice()
          .map((e) => e.packageId);
      }
    },
    getPackageName(item) {
      for (let x of this.packageList) {
        if (item == x.packageId) {
          return x.packageName;
        }
      }
      return "-";
    },
    getPackageType(item) {
      for (let x of this.packageList) {
        if (item == x.packageId) {
          return x.packageType;
        }
      }
      return "";
    },
    getBatchName(item) {
      for (let x of this.packageList) {
        if (item == x.packageId) {
          return x.packageName;
        }
      }
      return item;
    },
    getTutorName(item) {
      for (let x of this.tutorList) {
        if (item == x.tutorId) {
          return x.name;
        }
      }
      return item;
    },
    editStudentDetails(item) {
      this.editedIndex = this.students.indexOf(item);
      this.editedItem = _.omit(item, ["packageDetails"]);
      this.editedItem.packageId = item.packageDetails.packageId;
      this.displayGuardianPhoneNumber = this.editedItem.guardianMobileNumber?.length>0 ? `+${this.editedItem.guardianMobileCountryCode}${this.editedItem.guardianMobileNumber}` : '';
      this.displayStudentPhoneNumber = this.editedItem.mobileNumber?.length>0 ? `+${this.editedItem.mobileCountryCode}${this.editedItem.mobileNumber}`: '';      
      if(isNil(this.editedItem.isDSATAccommodation) || (this.editedItem.isDSATAccommodation == false)){
        this.editedItem.isDSATAccommodation = false;
        this.editedItem.accommodationDetails={
          dSATReadingAndWritingTimeDurationInMins: 32,
          dSATMathTimeDurationInMins: 35
        } 
      }
      this.dialog = true;
    },
    assignStudents(item) {
      this.editedIndex = this.students.indexOf(item);
      this.editedAssignmentItem.studentName = [item.name];
      this.editedAssignmentItem.studentIds = [item.userId];
      this.editedAssignmentItem.assignedToTutors = item.assignedToTutors.map(item => item.tutorId);
      this.editedAssignmentItem.assignedToBatches = item.assignedToBatches.map(item => item.batchId);
      this.selectedStudents = [];
      this.assignmentDialog = true;
    },
    assignMultipleStudents() {
      this.editedAssignmentItem.studentName = this.selectedStudents.map(item => item.name);
      this.editedAssignmentItem.studentIds = this.selectedStudents.map(item => item.userId);
      this.editedAssignmentItem.assignedToBatches = []
      this.editedAssignmentItem.assignedToTutors = []
      this.assignmentDialog = true;
    },
    createNewStudent() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.displayStudentPhoneNumber = "",
      this.displayGuardianPhoneNumber= "",
      this.editedIndex = -1;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    closeAssignmentDialog() {
      this.assignmentDialog = false;
    },
    async saveAssignmentDialog() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let reqData = _.cloneDeep(this.editedAssignmentItem);
        reqData.courseId = this.courseId;
        recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenEditButtonClicked, {
          courseId: this.courseId,
          studentId: this.editedItem.userId
        });
        this.$store.dispatch("updateStudentAssignment", reqData).then(() => {
          this.$store.dispatch("loadAdminStudentList", { courseId: this.courseId });
        })
        this.editedIndex = -1;
        this.editedAssignmentItem = _.cloneDeep(this.defaultAssignmentItem);
        this.selectedStudents = [];
        this.closeAssignmentDialog();
      }
    },
    async save() {
      this.studentErrorMessage = "";
      const { valid } = await this.$refs.form.validate()
      let passPhoneNumberCheck = true;
      if((this.editedItem.loginType == 'phone' || this.editedItem.mobileNumber?.length > 0) && !this.isValidStudentPhoneNumber){
        passPhoneNumberCheck = false;
      }
      if(this.editedItem.guardianMobileNumber?.length > 0 && !this.isValidGuardianPhoneNumber){
        passPhoneNumberCheck = false;
      }
      if(passPhoneNumberCheck == false){
        return;
      }
      if (valid) {
        let reqData = _.cloneDeep(this.editedItem);

        reqData.courseId = this.courseId;
        reqData.studentId = this.editedItem.userId;
        reqData.packageType = this.getPackageType(this.editedItem.packageId);
        if(isNil(reqData.isDSATAccommodation) || reqData.isDSATAccommodation == false){
          reqData.accommodationDetails={
            dSATReadingAndWritingTimeDurationInMins:0,
            dSATMathTimeDurationInMins:0
          }
        }else{
          reqData.accommodationDetails={
            dSATReadingAndWritingTimeDurationInMins: Number(this.editedItem.accommodationDetails.dSATReadingAndWritingTimeDurationInMins),
            dSATMathTimeDurationInMins: Number(this.editedItem.accommodationDetails.dSATMathTimeDurationInMins)
          }
        }
        if (this.editedIndex > -1) {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenEditButtonClicked, {
            courseId: this.courseId,
            studentId: this.editedItem.userId
          });

          this.$store.dispatch("updateStudent", reqData).then((res) => {
            if (res.success) {
              this.close();
            }
            else {
              this.studentErrorMessage = res.message;
              this.showStudentMessage = true;
            }
          });
        } else {
          recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenAddButtonClicked, {
            courseId: this.courseId,
            studentId: this.editedItem.userId
          });
          this.$store.dispatch("addStudent", reqData).then((res) => {
            if (res.success) {
              this.close();
            } else {
              this.studentErrorMessage = res.message;
              this.showStudentMessage = true;
            }
          });
        }
      }      
    },
    getActiveStatus(Active) {
      return Active ? "Active" : "Inactive";
    },
    formTitle() {
      return this.editedIndex == -1 ? "Add Student" : "Edit Student";
    },
    getAssignedTo(item) {
      let initialName = "", count = 0;
      if (item.assignedToTutors.length > 0) {
        initialName = item.assignedToTutors[0].tutorName;
        count = item.assignedToBatches.length + item.assignedToTutors.length - 1;
        if (count > 0)
          return `${initialName}, + ${count}`;
        else
          return `${initialName}`;
      }
      else if (item.assignedToBatches.length > 0) {
        initialName = item.assignedToBatches[0].batchName;
        count = item.assignedToBatches.length + item.assignedToTutors.length - 1;
        if (count > 0)
          return `${initialName}, + ${count}`;
        else
          return `${initialName}`;
      }
      else {
        return '-';
      }
    },
    getAssignedToComplete(item) {
      let namesList1 = [], namesList2 = [];
      if (item.assignedToBatches.length == 0 && item.assignedToTutors.length == 0) {
        return 'Student is not assigned to any tutor or class';
      }
      if (item.assignedToTutors.length > 0) {
        namesList1 = item.assignedToTutors.map((e) => e.tutorName);
      }
      if (item.assignedToBatches.length > 0) {
        namesList2 = item.assignedToBatches.map((e) => e.batchName);
      }
      let namesList = [...namesList1, ...namesList2];
      return namesList.join(", ")
    },
    isStudentActive(item) {
      return item.active ? 'Student is active' : 'Student is deactivated';
    },
    // toggleParentForm(){
    //   return this.showParentForm = !this.showParentForm;
    // },
    openDeleteDialog() {
      this.dialog = false;
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.dialog = true;
    },
    async deleteItem() {
      recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenDeleteButtonClicked, {
        courseId: this.courseId,
        studentId: this.editedItem.userId
      });
      const reqData = {
        courseId: this.courseId,
        studentId: this.editedItem.userId
      };
      await this.$store.dispatch("deleteStudent", reqData).then((res) => {
        if (res.success) {
          this.dialogDelete = false;
          this.editedItem = _.cloneDeep(this.defaultItem);
        }
        else {
          this.studentErrorMessage = res.message;
          this.showStudentMessage = true;
        }
      })
    },
    closeSnackBar() {
      this.studentErrorMessage = "";
      this.showStudentMessage = false;
    },
    checkIfPaidLicenseToStudent() {
      if(this.editedIndex == -1){
        return false;
      }
      const foundPackage = _.find(this.packageList, {packageId: this.students[this.editedIndex]?.packageDetails?.packageId});
      if(foundPackage){
        return (foundPackage.packageType == 'paid');
      }
      return false;
    },
    checkIfEditingInfo(){
      if(this.editedIndex == -1){
        return false;
      }
      else{
        return true;
      }
    },
    paginatedData(students){
      const start = (this.currentPageNumber-1)*this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return students.slice(start, end);
    },
    updateStudentPhoneNumberInput(phoneNumberObject){
      this.editedItem.mobileCountryCode = phoneNumberObject.telCountryCode;
      this.editedItem.mobileNumber = phoneNumberObject.telPhoneNumber; 
      this.isValidStudentPhoneNumber = phoneNumberObject.telIsValidPhoneNumber;
    },
    updateGuardianPhoneNumber(phoneNumberObject){
      this.editedItem.guardianMobileCountryCode = phoneNumberObject.telCountryCode;
      this.editedItem.guardianMobileNumber = phoneNumberObject.telPhoneNumber; 
      this.isValidGuardianPhoneNumber = phoneNumberObject.telIsValidPhoneNumber;
    },
  },
  computed: {
    ...mapState([
      "adminStudentList",
      "searchedAdminStudentList",
      "courseId",
      "mobileCountryCodeList",
      "isUserSuperAdmin"
    ]),
    ...mapGetters(['currentHighlightedElements']),
    showStudentScreen: {
      get() {
        if (this.studentsDetailLoaded && this.packageListLoaded && this.tutorListLoaded && this.classListLoaded) {
          return true;
        }
        else return false;
      }
    },
    packageList: {
      get() {
        let list = _.cloneDeep(this.$store.state.packageList.studentPackages) ?? [];
        list = list.map((e) => {
          e.packageName = e.packageName + ' (' + _.capitalize(e.packageType) + ')'
          return e;
        })
        return [{ packageId: '', packageName: 'Not Assigned' }, ...list];
      },
    },
    tutorList: {
      get() {
        const noData = { tutorId: "", name: 'Not Assigned' };
        let list = _.cloneDeep(this.$store.state.tutorList);
        // list = list.map((e) => {
        //   if (e.active == false) {
        //     e.name = e.name + ' (Inactive)';
        //   }
        //   return e;
        // }) 
        list = _.orderBy(list, [tutor => tutor?.name.toLowerCase()], "asc");  
        return _.concat(noData, list);
      }
    },
    classList: {
      get() {
        const noData = { batchId: "", name: 'Not Assigned' };
        let list = this.$store.state.classList;
        // list = list.map((e) => {
        //   if (e.active == false) {
        //     e.name = e.name + ' (Inactive)';
        //   }
        //   return e;
        // })
        list = _.orderBy(list, [batch => batch?.name?.toLowerCase()], "asc");
        return _.concat(noData, list);
      }
    },
    tutorAndClassCombinedList: {
      get() {
        const noData = { uniqueId: "", name: 'Not Assigned' };
        let list1 = this.classList.slice(1);
        list1 = list1.map((batch) => {
          let obj = {};
          obj.name = batch.name;
          obj.uniqueId = batch.batchId;
          return obj;
        })
        let list2 = this.tutorList.slice(1);
        list2 = list2.map((tutor) => {
          let obj = {};
          obj.name = tutor.name;
          obj.uniqueId = tutor.tutorId;
          return obj;
        })
        let list = _.concat(list1, list2);
        list = _.orderBy(list, [obj => obj?.name?.toLowerCase()], "asc");
        return _.concat(noData, list1, list2);
      }
    },
    students: {
      get() {
        let students = [];
        if (this.searchFilter.trim() != "") {
          students = this.searchedAdminStudentList;
        } else {
          students = this.adminStudentList;
        }

        switch (this.sortCategory) {
          case "nameAsc":
            students = _.orderBy(students, [student => student?.name?.toLowerCase()], "asc");
            break;
          case "nameDesc":
            students = _.orderBy(students, [student => student?.name?.toLowerCase()], "desc");
            break;
          case "onlineRecent":
            students = _.orderBy(students, [student => student?.lastLoginTimeStamp], "desc");
            break;
          case "onlineOldest":
            students = _.orderBy(students, [student => student?.lastLoginTimeStamp], "asc");
            break;
          case "startDateRecent":
            students = _.orderBy(students, ["licenseStartDate"], "asc");
            break;
          case "startDateOldest":
            students = _.orderBy(students, ["licenseStartDate"], "desc");
            break;
          case "endDateRecent":
            students = _.orderBy(students, ["packageDetails.packageEndDate"], "asc");
            break;
          case "endDateOldest":
            students = _.orderBy(students, ["packageDetails.packageEndDate"], "desc");
            break;

          default:
            students = _.orderBy(students, [student => student.name.toLowerCase()], "asc");
        }

        let check = false;
        let modifiedStudentsData = students;

        if (this.filterStudents.packageName.length > 0) {
          check = true;
          modifiedStudentsData = _.filter(modifiedStudentsData, (q) =>
            this.filterStudents.packageName.includes(q.packageDetails.packageId)
          );
        }

        if (this.filterStudents.combinedList.length > 0) {
          check = true;
          modifiedStudentsData = _.filter(modifiedStudentsData, student => {
            if (_.includes(this.filterStudents.combinedList, "")) {
              return (_.isEmpty(student.assignedToTutors) && _.isEmpty(student.assignedToBatches)) ||
                _.some(student.assignedToTutors, tutor => _.includes(this.filterStudents.combinedList, tutor.tutorId)) ||
                _.some(student.assignedToBatches, batch => _.includes(this.filterStudents.combinedList, batch.batchId));
            } else {
              return _.some(student.assignedToTutors, tutor => _.includes(this.filterStudents.combinedList, tutor.tutorId)) ||
                _.some(student.assignedToBatches, batch => _.includes(this.filterStudents.combinedList, batch.batchId));
            }
          });
        }

        if (check) {
          return modifiedStudentsData;
        }
        else return students;
      },
    },
    totalPages: {
      get(){
        return Math.ceil(this.students.length/this.itemsPerPage);
      }
    },
    paginatedStudentsList: {
      get(){
        return this.paginatedData(this.students);
      }
    },
    bindProps: {
      get(){
        return this.phoneProps;
      }
    },
  },
  watch: {
    sortCategory: {
      handler(){
        this.currentPageNumber = 1;
      },
      deep: true
    },
    students: {
      handler(val){
        this.currentPageNumber = 1;
        this.$emit('updateStudentNumber', val.length);
      }
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
/* ---------------- */


.student-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.student-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.student-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

/* ------------------- */
.assignment-card {
  border-radius: 12px !important;
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.transparent-card-bg {
  background: #ffffff00;
}

.card-bg {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.icon-bg-disabled {
  background: rgb(var(--v-theme-domain-button-color)) !important;
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

/* ----------------- */
.filter-dropdown {
  width: 150px !important;
}

.filter-dropdown-2 {
  width: 180px !important;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.parent-form {
  transition: all 0.5s ease;
}

.delete-btn {
  border: 2px solid red !important;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word;
  /* or word-break: break-all; */
}
</style>

<style>
.search-bar .v-field__input,
.search-bar .v-field-label,
.filter-box .v-label,
.filter-box .v-field__input {
  color: #BFBEC6 !important;
}

</style>
