<template>
  <v-main class="pt-0 pa-4 s-size outer-container d-flex flex-column justify-space-between">
    <v-container class="pt-0">
      <div class="pa-0 main-container">

        <!-- Registration Form -->
        <v-row v-if="isInputFormShow" class="pt-0">
          <v-card color="#ffffff00" class="mx-auto mt-0 pa-2" elevation="0">
            <!-- :max-width="smAndUp ? 400 : '100vw'" -->
            <v-row align="center" justify="center" style="color: white" class="mb-1 text-center mt-4 pa-0 ma-0">
              <v-col class="pa-0 ma-0">
                <h1 class="title">
                  Fun and effective way to learn and <br />
                  improve your skills for the SAT!
                </h1>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mt-10">
              <v-col cols="12" sm="9" style="max-width: 360px;">
                <v-form v-model="form" @submit.prevent="onInputSubmit">
                  
                  <p style="color: white; font-weight: 500; margin-bottom: 0.875rem" class="text-left">
                    Please enter your phone number or email
                  </p>

                  <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex"
                    style="margin-bottom: 1.25rem;">
                    <v-btn v-for="tab in contactTypeList" :key="tab.value" @click="changeIdentityType(tab.value)"
                      :value="tab.value" class="py-2 px-3 flex-grow-1"
                      :class="[tab.value == identityType ? 'active-button' : 'normal-button']"
                      :variant="tab.value == identityType ? 'elevated' : 'plain'" elevation="0">
                      <span class="text-none button-text t-h6Reg">{{ tab.name }}</span>
                    </v-btn>

                  </v-sheet>

                  <vue-tel-input v-if="identityType == 'phone'" density="compact" :class="{ 'error-field': existingLoginError }" v-model="displayPhoneNumber" v-bind="bindProps" @on-input="updateUserPhoneNumberObject" style="
                      height: 40px;
                      background: white;
                      outline: none;
                      font-size: 16px;
                      border-radius: 4px;
                      font-family: 'Inter', sans serif;
                      font-weight: 400;
                      margin-bottom: 0.875rem;
                    "></vue-tel-input>

                  <v-text-field v-if="identityType == 'email'" :class="{ 'error-field': existingLoginError }" placeholder="Email Address"
                    v-model="editedValue.email" color="#000000" :readonly="loading" density="compact"
                    class="mb-4" rounded="8px" clearable variant="solo" flat bg-color="#fff" style="width: 100%;"
                    :hide-details="true"></v-text-field>

                  <div v-if="existingLoginError != ''" role="alert" class="text-wrap auth-error mb-3 font-weight-bold">
                    {{ existingLoginError }}
                  </div>

                  <p v-if="identityType == 'phone'" style="color: white; font-weight: 500; font-size: 0.875rem;" class="text-left">
                    We will text you to confirm your number. Standard message and data rates apply.
                  </p>
                  <p v-if="identityType == 'email'" style="color: white; font-weight: 500; font-size: 0.875rem;" class="text-left">
                    We will email you to confirm your email address.
                  </p>


                  <v-row justify="center" align="center">
                    <v-spacer></v-spacer>
                    <v-col class="pt-0 pt-sm-3">
                      <v-btn :class="[checkValidity() ? 'deselectedButton' : 'button-bg-clr']"
                        :disabled="checkValidity()"
                        class="btn-h-size font-weight-bold text-none mx-auto mt-12 rounded-pill" :loading="loading"
                        color="primary" size="large" type="submit" variant="elevated" style="width: 230px">
                        Next
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  
                  <v-row v-if="!isWhiteLabelApp" align="center" justify="center">
                    <v-col cols="5">
                      <v-divider class="border-opacity-50" color="white"></v-divider>
                    </v-col>
                    <v-col cols="2">
                      <span class="text-white">OR</span>
                    </v-col>
                    <v-col cols="5">
                      <v-divider class="border-opacity-50" color="white"></v-divider>
                    </v-col>
                  </v-row>

                  <!-- Google login -->
                  <v-row v-if="!isWhiteLabelApp" justify="center" align="center" class="ma-0" dense>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="onCustomGoogleSignIn"
                      class="btn-h-size px-2 font-weight-bold text-none mx-auto mt-2 rounded-pill"
                         size="large" variant="elevated" style="width: 230px"
                      >
                        <v-icon size="35" class="ma-0 pa-0 mr-2"><v-img :src="require('@/assets/google.svg')"></v-img></v-icon>
                        <span class="btn-h-text nav-text">Sign in with Google</span>
                      
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row v-if="showGoToNewRegistrationPage">
                    <v-col>
                      <p style="color: white" class="mb-4 mt-4 text-center mt-4">
                        Don't have an account?<span style="
                            cursor: pointer;
                            font-weight: 600;
                            color: #55BCFF;
                          " @click="redirect(true)">
                          Create new
                        </span>
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-row>

        <!-- OTP Form -->
        <v-row v-if="isOtpFormShow" class="pt-0">
          <v-card color="#ffffff00" class="mx-auto mt-0 pa-2" elevation="0">
            <!-- :max-width="smAndUp ? 400 : '100vw'" -->
            <v-row align="start" justify="center" style="color: white" class="mb-1 text-center mt-4 pa-0 ma-0">
              <v-col cols="auto" class="py-0 pl-0 mt-2">
                <v-icon class="pa-0 ma-0" @click="showPrevQuestion" icon="mdi-arrow-left" color="white"
                  size="24"></v-icon>
              </v-col>
              <v-col class="pa-0 ma-0">
                <h1 class="title">
                  Let's continue your <br /> learning journey!
                </h1>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mt-10 text-left">
              <v-col cols="12" style="max-width: 350px;">
                <v-form v-model="otpForm" @submit.prevent="onOtpSubmit">

                  <p v-if="identityType == 'phone'" style="color: white; font-weight: 500; margin-bottom: 1rem">
                    Enter the secure code we sent over SMS
                  </p>
                  <p v-if="identityType == 'email'" style="color: white; font-weight: 500; margin-bottom: 1rem">
                    Enter the secure code we sent you in an email
                  </p>

                  <v-text-field hide-details="true" type="number" placeholder="Secure Code" v-model="otpValue" color="primary"
                    :readonly="loading" class="mb-4" :class="{ 'error-field-otp': otpError }" density="compact" rounded="8px" clearable
                    variant="solo" flat bg-color="#fff"></v-text-field>

                  <v-row align="start" class="px-0 mx-0">
                    <v-col cols="auto text-left px-0 mx-0">
                      <p role="alert" class="text-wrap auth-error font-weight-bold; font-size: 12.8px">
                        {{ otpError }}
                      </p>
                    </v-col>

                    <v-col class="px-0 ma-0">
                      <p style="color: white; font-size: 14px" class="mb-4 pa-0 ma-0 text-right">
                        Did not receive?<span style="
                            cursor: pointer;
                            font-weight: 600;
                            color: #55BCFF;
                          " @click="resendOtp">
                          Resend
                        </span>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row justify="center" align="center">
                    <v-spacer></v-spacer>
                    <v-col class="pt-0 pt-sm-3">
                      <v-btn class="btn-h-size font-weight-bold  mx-auto mt-16 text-none rounded-pill"
                        :class="[otpValue.length !== 6 ? 'deselectedButton' : 'button-bg-clr']"
                        :disabled="otpValue.length !== 6" :loading="loading" color="primary" size="large" type="submit"
                        variant="elevated" style="width: 300px">
                        Next
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row v-if="!isWhiteLabelApp">
                    <v-col class="pb-0">
                      <p style="color: white" class="my-4 text-center">
                        Don't have an account?<span style="
                            cursor: pointer;
                            font-weight: 600;
                            color: #55BCFF;
                          " @click="redirect(true)">
                          Create new
                        </span>
                      </p>
                    </v-col>
                  </v-row>


                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-row>

      </div>
    </v-container>

    <footer-component v-if="isFooterShow" class="mt-4 pb-0" style="flex: unset"/>
  </v-main>
</template>

<script>
// import AppBarNew from "./AppBarNew.vue";
import { getDeviceId, isUserLoggedIn, getTempUserId, setTempUserId, getResourceLink, clearResourceLink} from "@/services/authService";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { v4 as uuidv4 } from "uuid";
import { useDisplay } from "vuetify";
import _ from "lodash";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { mapState } from 'vuex';
import FooterComponent from "./FooterComponent.vue";
import {createFirebaseApp} from "@/services/firebaseMessagingService"
import moment from "moment-timezone";
// import OtpComponent from "./OtpComponent.vue";
import { googleSdkLoaded } from "vue3-google-login"
import { signInLoad, teacherAssignment, apiCallsOnSigningUp } from "@/services/signinService";
import { isWhiteLabelApp } from "@/services/whiteLabelService";

export default {
  name: "ExistingUser",
  components: { VueTelInput, FooterComponent },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp };
  },
  data() {
    return {
      contactTypeList: [
        {
          name: 'Phone Number',
          value: 'phone'
        },
        {
          name: 'Email',
          value: 'email'
        },
      ],
      identityType: 'phone',
      isInputFormShow: false,
      isOtpFormShow: false,
      form: false,
      otpForm: false,
      isFooterShow: true,
      isInvalidOTP: false,
      otpError: "",
      otpValue: "",
      defaultInputValue: {
        mobileCountryCode: "",
        mobileCountry: "",
        mobileNumber: "",
        name: "",
        email: "",
      },
      editedValue: {
        mobileCountryCode: "",
        mobileCountry: "",
        mobileNumber: "",
        name: "",
        email: "",
      },
      isValidPhoneNumber: false,
      genuineUserId: "",
      phoneProps: {
        autoFormat: true,
        required: true,
        disabledFetchingCountry: false,
        enabledFlags: true,
        dropdownOptions: {
          showDialCodeInSelection: true,
          showDialCodeInList: true,
          showFlags: true,
        },
        inputOptions: {
          placeholder: "Phone Number",
          type: "tel",
        },
        mode: "international",
        validCharactersOnly: true,
        defaultCountry: "US",
        onlyCountries: ["US", "IN"],
      },
      loading: false,
      isWhiteLabelApp: false,
      displayPhoneNumber: '',
    };
  },
  created() {
    recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountLoginScreenOpen);
    this.checkWhiteLabelApp();
    if(this.isWhiteLabelApp){
      this.contactTypeList = [
        {
          name: 'Email',
          value: 'email'
        },
        {
          name: 'Phone Number',
          value: 'phone'
        },
      ];
      this.identityType = 'email';
      this.isInputFormShow = true
    }
    else {
      this.isInputFormShow = true;
    }
    this.$store.commit("clearExistingLoginError");
    
    const currTz= moment
        .tz(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        .zoneName();

    if(currTz === "IST"){
      this.phoneProps.defaultCountry = "IN";
    }
  },
  methods: {
    toStep1(newUser) {
      if(newUser){
        recordAnalyticsEvent(AnalyticsEvents.v2OnboardingHomeGetStartedClicked);
      }else{
        recordAnalyticsEvent(AnalyticsEvents.v2OnboardingHomeExistingAccountClicked);
      }

      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
        localStorage.setItem('deviceId', deviceId)
      }
      const self  = this
      this.$store
        .dispatch("onBoardingStart", {
          newUser: newUser,
          deviceId: deviceId,
          courseId: this.courseId
        })
        .then((response) => {
          if (
            !_.isNil(response)
          ) {
            this.$store.commit("loadOnBoardingInfo", response);
            // this.$store.commit("setAPIUrlsForFlavorAndVersion", response.appurls);

            setTempUserId(response.tempUserId)
            if (newUser) {
              if(response.domains.length === 1){
                this.$store
                    .dispatch("onBoardingDomain", {
                    "tempUserId": _.get(response, "tempUserId", ""),
                    "selectedDomainId": _.get(response.domains[0], "key", "")
                })
                    .then((responseFromDomain) => {
                    if (!_.isNil(responseFromDomain)) {
                        this.$store.commit("loadOnBoardingQuestions", []);
                        this.$router.push("/welcome");
                    }
                });
              }
              else{
                this.$router.push("/domain");
              }
            }
            else{
              this.contactTypeList = [
                {
                  name: 'Email',
                  value: 'email'
                },
                {
                  name: 'Phone Number',
                  value: 'phone'
                },
              ];
              this.identityType = 'email';
              this.isInputFormShow = true
            }
          }
        });
    },
    countryChanged(country) {
      this.editedValue.mobileCountryCode = country.dialCode
      this.editedValue.mobileCountry = country.iso2;
    },
    phoneObject(object) {
      this.isValidPhoneNumber = object.valid;
      if (object.valid === true) {
        this.editedValue.mobileCountryCode = object.country.dialCode;
        this.editedValue.mobileCountry = object.country.iso2;
      }
    },
    redirect(newUser) {
      recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountLoginScreenCreateNewButton);
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
        localStorage.setItem('deviceId', deviceId)
      }
      const self = this
      this.$store
        .dispatch("onBoardingStart", {
          newUser: newUser,
          deviceId: deviceId,
          courseId: this.courseId
        })
        .then((response) => {
          if (
            !_.isNil(response)
          ) {
            this.$store.commit("loadOnBoardingInfo", response);
            // this.$store.commit("setAPIUrlsForFlavorAndVersion", response.appurls);

            setTempUserId(response.tempUserId)
            if (newUser) {
              if(response.domains.length === 1){
                this.$store
                    .dispatch("onBoardingDomain", {
                    "tempUserId": _.get(response, "tempUserId", ""),
                    "selectedDomainId": _.get(response.domains[0], "key", "")
                })
                    .then((responseFromDomain) => {
                    if (!_.isNil(responseFromDomain)) {
                      if( this.isWhiteLabelApp ){
                        this.$store.commit("loadOnBoardingQuestions", []);
                      }
                      else{
                        this.$store.commit("loadOnBoardingQuestions", responseFromDomain);
                      }
                      this.$router.push("/welcome");
                    }
                });
              }
              else{
                this.$router.push("/domain");
              }
            } else {
              this.$router.push('/existing');
            }
          }
        });
    },
    required(v) {
      return !!v || "Field is required";
    },
    showPrevQuestion() {
      this.isInputFormShow = true;
      this.isOtpFormShow = false;
      this.isInvalidOTP = false;
      // this.loginError = "";
      this.$store.commit("clearExistingLoginError");
      this.otpError = "";
    },
    onInputSubmit() {
      if(this.identityType == 'phone' && this.isValidPhoneNumber == false){
        return
      }
      recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountLoginScreenNextButton, {
        mobileCountryCode: this.editedValue.mobileCountryCode,
        mobileNumber: this.editedValue.mobileNumber,
        identityType: this.identityType,
        email: this.editedValue.email,
      });
      // if (!this.form) return;
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.$store
        .dispatch("onBoardingLogin", {
          tempUserId: "",
          deviceId: deviceId,
          identityType: this.identityType,
          ...this.editedValue,
        })
        .then((response) => {
          if (
            response.success &&
            response.success == true
          ) {
            // this.loginError = response.error
            this.$store.commit("setExistingLoginError", response.error);
            this.genuineUserId = response.userId;
            this.otpValue = "";
            this.isInvalidOTP = false;
            this.otpError = "";
            this.isInputFormShow = false;
            this.isOtpFormShow = true;

            recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountOTPScreenNextButtonSuccess, {
              userId: response.userId
            });
            recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountOTPScreenOpen, {
              userId: response.userId
            });
          }
          else {
            recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountLoginScreenNextButtonError, {
              mobileCountryCode: this.editedValue.mobileCountryCode,
              error: response.error,
              mobileNumber: this.editedValue.mobileNumber,
              email: this.email,
              identityType: this.identityType,
            });
            // this.loginError = response.error;
            this.$store.commit("setExistingLoginError", response.error);
          }
        });
    },

    onOtpSubmit() {
      recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountOTPScreenNextButton, {
        userId: this.genuineUserId
      });
      if (!this.otpForm) return;
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.$store
        .dispatch("onBoardingValidate", {
          userId: this.genuineUserId,
          deviceId: deviceId,
          identityType: this.identityType,
          verificationCode: this.otpValue
        }).then( async (response) => {
          if (isUserLoggedIn() == 'true') {
            this.isOtpFormShow = false;
            this.isInputFormShow = false;
            this.isFooterShow = false;
            await createFirebaseApp()
            await  signInLoad();
            this.$store.commit("pricingModelsLoadedFromServer", false);
            this.$store.commit('appbarVisible', true);
            this.$store.commit('navbarVisible', true);
            
           let res = await teacherAssignment()
           await apiCallsOnSigningUp();
            if(res==null)
            {
              const redirectResourceLink = getResourceLink() ?? null
              if(redirectResourceLink){
                clearResourceLink();
                this.$router.push(redirectResourceLink);
              }else{
                this.$router.push('/');
              }
            }
          } 
          else {
            this.isInvalidOTP = true;
            if(this.isInvalidOTP == true){
              this.otpError = "Invalid secure code"
            }
            else{
              this.otpError = "";
            }
          }
        });
    },
    handleOtpChange(otp) {
      return (this.otpValue = otp);
    },
    resendOtp() {
      recordAnalyticsEvent(AnalyticsEvents.v2ExistingAccountOTPScreenResendButton, {
        userId: this.genuineUserId
      });
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.$store
        .dispatch("onBoardingLogin", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          deviceId: deviceId,
          identityType: this.identityType,
          ...this.editedValue,
        })
        .then((response) => {
          if (
            response.userId &&
            response.userId != "" &&
            response.userId.toLowerCase() != "undefined"
          ) {
            this.otpValue = "";
          }
        });
    },
   async onCustomGoogleSignIn() {
      await recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleExistingUser, {
        tempUserId: getTempUserId()
      },true);
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }

      googleSdkLoaded((google) => {
        const state = {
          userType: "existing",
          differentDomain: this.isWhiteLabelApp,
          url: window.location.origin,
          testId : this.$store.state.teacherTestId,
          ...this.$store.state.shareCollectionLinkOpened
        };
        google.accounts.oauth2.initCodeClient({
          client_id: _.get(this.config, 'web.client_id', ''),
          scope: 'email profile openid',
          ux_mode: "redirect",
          redirect_uri:  _.get(this.config, 'web.redirect_uris', [])[0],
          state: window.btoa(JSON.stringify(state)),
          callback: (response) => {
            console.log("Handle the response", response)
          }
        }).requestCode()
      });
    },
    changeIdentityType(value) {
      this.identityType = value;
    },
    checkValidity(){
      if (this.identityType == 'email'){
        const emailRegex = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
        return !emailRegex.test(this.editedValue.email);
      }
      else if (this.identityType == 'phone'){
        return !this.isValidPhoneNumber
      }
    }, 
    checkWhiteLabelApp(){
      this.isWhiteLabelApp = isWhiteLabelApp();
    },
    updateUserPhoneNumberObject(val, phoneObject){
      this.editedValue.mobileCountryCode = phoneObject.countryCallingCode;
      this.editedValue.mobileCountry = phoneObject.countryCode;
      this.editedValue.mobileNumber = phoneObject.nationalNumber;
      this.isValidPhoneNumber = phoneObject.valid ?? false;
    }

  },
  computed: {
    ...mapState(["onBoardingResponse", "existingLoginError", "config","isTeacherViewSelected", "courseDetails", "courseId"]),
    bindProps() {
      return this.phoneProps;
    },
    showGoToNewRegistrationPage: {
      get(){
        if(!this.isWhiteLabelApp || (this.isWhiteLabelApp && this.courseDetails.isNewRegistrationAllowed)){
          return true;
        }
        else{
          return false;
        }
      }
    }
  },
  watch: {
    editedValue: {
      deep :true,
      handler(newValue){
      }
    },
    otpValue(){
      this.otpError= "";
      this.isInvalidOTP= false;
    },
    identityType(newVal){
      if(newVal == 'phone'){
        this.$store.commit("clearExistingLoginError");
        this.editedValue.email = "";
      }
      else if(newVal == 'email'){
        this.$store.commit("clearExistingLoginError");
        this.editedValue.mobileCountry = "";
        this.editedValue.mobileCountryCode = "";
        this.editedValue.mobileNumber = "";
        this.displayPhoneNumber = "";
      }
    },
    'editedValue.email'(newVal) {
      if(this.existingLoginError != ""){
        this.$store.commit("clearExistingLoginError");
      }
    },
    'editedValue.mobileNumber'(newVal) {
      if(this.existingLoginError != ""){
        this.$store.commit("clearExistingLoginError");
      }
    },
  }
};
</script>

<style scoped>
.title {
  font-size: 31.5px !important;
}

@media only screen and (min-width: 950px) {
  .s-size{
    min-height: calc(100vh - 80px) !important;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.25rem !important;
    line-height: 30px;
  }

  .outer-container{
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .outer-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .outer-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .s-size{
    /* margin-top: 16px !important; */
    height: calc(100vh - 100px) !important;
  }
}


.toggler-outer-bg {
  border-radius: 12px !important;
  background: #2A2941;
}

.active-button {
  border-radius: 8px;
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
  border-radius: 8px;
  background: none;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
  border: 2px solid transparent;
}

.auth-error {
  font-size: 0.8rem;
  font-weight: 700;
  color: #FF786E;
  height: 0.8rem;
}

.error-field {
  border: 2.5px solid #FF786E;
  border-radius: 6px;
}

.error-field-otp {
  border: 2.5px solid #FF786E;
  border-radius: 6px;
}

.s-size {
  min-height: calc(100vh - 100px) !important;
}

.bhover {
  background-color: #fff !important;
  transition: all 0.3s ease;
  border: 4px solid #538dfe !important;
  border-color: transparent !important;
  border-radius: 12px !important;
}

.bhover:hover {
  cursor: pointer;
  border: 1px solid #e7e7e7 !important;
  background-color: #ffffff !important;
  /* box-shadow: 5px 5px 0px rgba(222, 222, 222, 0.47) !important; */
  /* box-shadow: 0px 0px 11px 2px rgba(222, 222, 222, 0.47) !important; */
  filter: drop-shadow(0px 0px 25px #536dfe);
  border: 4px solid #538dfe !important;
}

.deselectedButton {
  background: #3a4951 !important;
}

.btn-h-size {
  font-size: 14px !important;
  height: 48px !important;
}

.btn-h-text{
  font-size: 14px !important;
}

.button-bg-clr {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) !important;
}


</style>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
