<template>
  <!-- Navbar -->
  <header class="bg-none align-center pa-0 ma-0 app-bar" id="app-bar-height"
  :class="{'app-bar-lg': !$vuetify.display.smAndDown}">
    <v-col cols="12" :class="[isWhiteLabelApp? 'pa-2': 'pt-4 pt-sm-4']">
      
      <v-row class="ma-0 pa-0" v-if="!isWhiteLabelApp">
        <v-col cols="12" class="ma-0 pa-0">
          <img 
            :src="orgLogo"
            class="nav-logo py-0"
          />
        </v-col>
      </v-row>

      <v-row v-else class="ma-0 pa-0">
        <v-col cols="auto" class="ma-0 pa-0 d-flex flex-column justify-space-between align-center">
          <img 
            :src="orgLogo"
            class="nav-logo py-0" :style="`background-color: ${orgLogoBackgroundColor};border-radius:6px;`" :class="{'max-height': $vuetify.display.smAndDown}"
          />
          <div v-if="showPoweredBy" class="pa-0 my-1 ma-0 d-flex align-center height-text-box">
            <span @click.stop="openMento()" class="pointer t-small-subtitle text-grey">Powered By <span class="text-white">Mento</span><span class="text-primaryPurple">Mind</span></span>
          </div>
        </v-col>
      </v-row>
        
    </v-col>
  </header>
</template>

<script>
import orgSignInLogo from "@/assets/mento_logo_long.svg";
import { hidePoweredByMentoMind, isWhiteLabelApp } from "@/services/whiteLabelService";
import { isEmpty } from "lodash";
import { mapState } from "vuex";

export default {
  name: 'AppBarNew',
  data: () => ({
    isWhiteLabelApp: false,
  }),
  created(){
    this.checkWhiteLabelApp();
  },
  computed: {
    ...mapState([ "config", "courseDetails"]),
    orgLogo: {
      get(){
        if(this.isWhiteLabelApp){
          return this.$store.state.app_s3url + this.courseDetails.image;
        }else{
          return orgSignInLogo;
        }
      }
    },
    orgLogoBackgroundColor: {
      get(){
        if(this.isWhiteLabelApp && (this.courseDetails.wlLogoBackground == 'solid')){
          return this.courseDetails.wlLogoBackgroundColor;
        }else{
          return 'transparent';
        }
      }
    },
    showPoweredBy: {
      get() {
        return !isEmpty(this.courseDetails) && !hidePoweredByMentoMind();
      }
    }
  },
  methods: {
    openMento(){
      window.open('https://mentomind.ai/');
    },
    checkWhiteLabelApp(){
      this.isWhiteLabelApp = isWhiteLabelApp();
    }
  },
}
</script>

<style scoped>

.app-bar{
  top: 0px;
  z-index: 1006;
  transform: translateY(0%);
  position: fixed;
  left: 0px;
  width: calc(100% + 0px);
}

.nav-logo {
  /* height: 46px !important; */
  width: 184px !important;
  object-fit: contain;
}

.nav-logo-text{
  width: 184px !important;
}

.bg-none {
  background: none !important;
}

.height-text-box{
  height: 16px !important;
}

.text-grey{
  opacity: 1 !important;
}
.max-height{
  max-height: 70px;
}
</style>

<style>

.app-bar ~ .page-color{
  margin-top: 100px !important;
}

.app-bar-lg ~ .page-color{
  margin-top: 80px !important;
}
</style>