<template>
    <v-snackbar class="" v-model="snackbar" :timeout="timeout">
        {{ copiedText }}
    </v-snackbar>
    <!-- <div class="mx-4">
        <v-container fluid v-if="dataLoaded" class="mt-10">
            <v-row class="align-center">
                <v-col cols="12" md="8">

                    <v-hover v-slot="{ isHovering, props }">

                        <v-card elevation=0 :class="{ 'on-hover': isHovering }" class="px-4 py-1 testCard" v-bind="props">

                            <v-card-text>
                                <v-row justify="space-between">
                                    <v-col cols="auto" class="text-h6">
                                        Carloline's Challenge
                                    </v-col>
                                </v-row>
                                <v-row justify="space-between">
                                    <v-col cols="auto">
                                        {{ quizTemplate.templateName }}
                                    </v-col>
                                    <v-col cols="auto">
                                        {{ quizTemplate.durationInMinutes }} mins
                                    </v-col>
                                    <v-col cols="auto">
                                        {{ quizTemplate.easyQuestions + quizTemplate.mediumQuestions +
                                            quizTemplate.hardQuestions }}
                                        Questions
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-hover>
                </v-col>

            </v-row>
            <v-row class="my-8">

            </v-row>
            <v-row class="align-center">
                <v-col cols="12" md="8">

                    <v-hover v-slot="{ isHovering, props }">

                        <v-card elevation=0 :class="{ 'on-hover': isHovering }" class="px-4 py-1 roomCodeCard"
                            v-bind="props">

                            <v-card-text>
                                <v-row justify="space-between">
                                    <v-col cols="auto" class="">
                                      Share this code with your friends and invite
them to take a challenge with you 
                                    </v-col>
                                </v-row>
                                <v-row justify="space-between" class="align-center">
                                    <v-col class="text-h6" cols="auto">
                                        <span style="letter-spacing: 10px;">
                                            {{ selectedChallenge.code }}
                                        </span>

                                    </v-col>
                                    <v-col cols="auto">
                                        <v-icon @click="copyCode(selectedChallenge.code)" size="40">
                                            mdi-content-copy
                                        </v-icon>
                                    </v-col>

                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-hover>
                </v-col>

            </v-row>
            <v-row class="mt-8">
                <v-col class="px-4" cols="12" sm="5" md="5" lg="3" xl="2">
                    {{ participantsStateMessage }}
                </v-col>

            </v-row>
            <v-row>
                <v-col class="px-4" cols="12" sm="5" md="5" lg="3" xl="2">
                    {{ getFirstParticipantName() }}
                </v-col>

            </v-row>
            <v-row>
                <v-col class="px-4" cols="12" sm="5" md="5" lg="3" xl="2">
                    {{ getTestStatus() }}
                </v-col>

            </v-row>
            <v-row v-if="startChallengeAllowed" class="mt-8">
                <v-col class="px-4" cols="12" sm="5" md="5" lg="3" xl="2">
                    <v-btn @click="startChallengeForEveryone()" class="py-6" block color="primaryGreen">Start
                        Challenge</v-btn>
                </v-col>

            </v-row>
        </v-container>

    </div> -->

    <div class="h-100">

        <v-row v-if="dataLoaded" class="ma-0 pt-4 px-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }">
            <v-col cols="12" class="ma-0 pa-0">

                <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white  text-primaryTextColor">
                    <template v-slot:divider>
                        <v-icon icon="mdi-chevron-right"></v-icon>
                    </template>
                    <template v-slot:prepend>
                        <v-btn v-if="breadCrumbs.length > 1" color="#26253A" class="ma-0 mr-2 text-primaryTextColor "
                            size="x-small" @click="back()" icon="mdi-chevron-left">
                        </v-btn>
                        <v-icon icon="mdi-trophy-outline" color="primaryPurple"></v-icon>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-spacer></v-spacer>
            <v-divider></v-divider>

        </v-row>

        <v-layout class="mb-0 pb-0" v-if="dataLoaded" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
            <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

                <v-row class="mt-0 pt-0">

                    <v-col class=" mt-0 pt-0 justify-center align-center text-center"
                        :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
                        <v-container fluid="" class="px-2 mt-0 pt-0">
                            <v-row justify="start" class="mb-0" align="center">
                                <v-col cols="12">
                                    <v-row class="t-h5 mb-1  text-primaryTextColor" justify="center">
                                        {{ userName }}’s Challenge

                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row justify="center">

                                        <v-col cols="12" sm="9" md="7" lg="4" xl="3">
                                            <v-hover v-slot="{ isHovering, props }">

                                                <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                                                    class=" py-3 py-md-8 testCard px-3 px-md-8 fill-height" v-bind="props">
                                                    <v-card-text class="d-flex flex-column h-100 fill-height">


                                                        <v-row justify="start" class=" ">
                                                            <v-col cols="auto" class=" my-0 py-0">

                                                                <span class="t-h5 text-primaryTextColor">
                                                                    Invite a friend
                                                                </span>
                                                            </v-col>
                                                        </v-row>


                                                        <v-row justify="start" class=" ">
                                                            <v-col cols="auto" class=" my-0 my-md-1  text-start">

                                                                <span class="t-subtitle text-cardSecondaryTextColor">

                                                                    Share this code with your friend to invite for this
                                                                    challenge

                                                                </span>
                                                            </v-col>
                                                        </v-row>

                                                        <v-spacer>

                                                        </v-spacer>

                                                        <v-row justify="space-between" align="center"
                                                            class="mt-4 mt-md-5 px-3 " :class="{
                                                                'border-code py-2': true
                                                            }">
                                                            <v-col cols="auto" class="my-0 py-0 t-h5  text-primaryTextColor"
                                                                style="letter-spacing: 6px;">
                                                                {{ selectedChallenge.code }}

                                                            </v-col>
                                                            <v-col cols="auto" class="my-0 py-0">
                                                                <v-icon @click="copyCode(selectedChallenge.code)"
                                                                    color="red" size="30" class="copy">
                                                                    mdi-content-copy
                                                                </v-icon>

                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>

                                                </v-card>
                                            </v-hover>
                                        </v-col>

                                        <v-col cols="12" sm="9" md="7" lg="4" xl="3">
                                            <v-hover v-slot="{ isHovering, props }">

                                                <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                                                    class=" py-3 py-md-8 fill-height testCard px-3 px-md-8" v-bind="props">
                                                    <v-card-text class="d-flex flex-column h-100 fill-height">


                                                        <v-row justify="center" class=" ">
                                                            <v-col cols="auto " class=" my-0 py-0 mb-2">

                                                                <span class="t-h6Reg  text-primaryTextColor"
                                                                    v-if="!startChallengeAllowed">
                                                                    Waiting for the friend to join
                                                                </span>
                                                                <span class="t-h6Reg  text-primaryTextColor"
                                                                    v-if="startChallengeAllowed">
                                                                    Ready for this challenge ?

                                                                </span>
                                                            </v-col>
                                                        </v-row>


                                                        <v-row justify="center" class=" mt-3">
                                                            <v-col cols="auto" class=" my-0  text-center">
                                                                <v-progress-circular v-if="!startChallengeAllowed"
                                                                    :size="$vuetify.display.mdAndUp ? '115' : '95'"
                                                                    indeterminate color="primaryPurple" :width="4">

                                                                    <v-avatar color="primaryPurple" class="my-0 py-0"
                                                                        :size="$vuetify.display.mdAndUp ? '100' : '80'">
                                                                        <v-icon
                                                                            :size="$vuetify.display.mdAndUp ? '70' : '40'"
                                                                            color="white">
                                                                            mdi-account
                                                                        </v-icon>
                                                                        <div v-if="startChallengeAllowed"
                                                                            class=" text-primaryTextColor">
                                                                            {{ getInitials(getFirstParticipantName()) }}
                                                                        </div>
                                                                    </v-avatar>
                                                                </v-progress-circular>

                                                                <v-avatar v-if="startChallengeAllowed" size="100"
                                                                    color="primaryGreen">
                                                                    <div v-if="startChallengeAllowed"
                                                                        class="text-primaryTextColor t-h4">
                                                                        {{ getInitials(getFirstParticipantName()) }}
                                                                    </div>
                                                                </v-avatar>

                                                            </v-col>
                                                        </v-row>

                                                        <v-row justify="center" class="mb-3">
                                                            <v-col cols="auto" class=" my-0  text-center mt-0 pt-0">
                                                                <div v-if="startChallengeAllowed"
                                                                    class=" t-subtitle text-primaryTextColor">
                                                                    {{ getFirstParticipantName() }}
                                                                </div>

                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer>

                                                        </v-spacer>
                                                        <v-row justify="center" align="center" class="mt-4 mt-md-5 ">
                                                            <v-col cols="auto" class="my-0 py-0">
                                                                <v-btn v-if="startChallengeAllowed" width="160"
                                                                    variant="flat"
                                                                    class="text-none t-btn btn-color text-primaryTextColor"
                                                                    size="large" @click="startChallengeForEveryone">Start
                                                                </v-btn>

                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>

                                                </v-card>
                                            </v-hover>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>

                        </v-container>
                    </v-col>

                </v-row>
            </v-container>
        </v-layout>

    </div>
</template>
          
<script>
import { getLoggedInUserName } from "@/services/authService";
import { setLocalForageItem } from "@/services/localforageService";
import axios from "axios";
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";



export default {
    name: 'HostWaitingRoom',
    data: () => ({
        dataLoaded: false,
        snackbar: false,
        copiedText: 'Code is copied to clipboard',
        timeout: 1000,
        downloadingTest: null,
        testDownloaded: false,
        currentGeneratedChallenge: null,
        participantsReady: false,
        breadCrumbs: []

    }),
    components:
    {


    },
    async created() {
        let sounds = {
            selfIncrease: new Audio(require("@/assets/audio/me_correct.mp3")),
            selfDecrease: new Audio(require("@/assets/audio/me_incorrect.mp3")),
            otherUserIncrease: new Audio(require("@/assets/audio/other_correct.mp3")),
            otherUserDecrease: new Audio(require("@/assets/audio/other_incorrect.mp3"))
        }

        this.$store.commit("updateChallengeSounds", sounds)
        if (this.ablyRealTime != null) {
            this.ablyRealTime.close()
            console.log("close ably");
        }
        this.$store.commit("clearCurrentTestState")
        this.$store.commit("challengeGeneratedFromServer", false);

        this.dataLoaded = false

        if ('challengeId' in this.$route.params && 'quizTemplateId' in this, this.$route.params) {
            this.$store.commit('increamentLoader')

            let challengeId = this.$route.params.challengeId
            this.createNodes(challengeId)
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeHostWaitingRoomScreenOpen, {
                challengeId: challengeId
            });
            let quizTemplateId = this.$route.params.quizTemplateId
            this.$store.commit("selectedChallengeLoadedFromServer", false);

            await this.$store.dispatch("selectChallenge", { challengeId: challengeId, quizTemplateId: quizTemplateId })

            this.$store.commit("updateAblyRealtime",)
            this.ablyRealTime.connection.on('connected', async () => {
                console.log('Connected to Ably!');


                this.dataLoaded = true
                const self = this
                this.$store.commit("updateAblyChannel", this.selectedChallenge.code)
                this.ablyChannel.presence.enter({
                    name: getLoggedInUserName(),
                    ready: false,
                    host: true,
                    participant: false,
                    submitted: false
                })
                this.$store.commit('decreamentLoader')

                await this.ablyChannel.presence.subscribe((msg) => {
                    console.log(msg);
                    this.ablyChannel.presence.get(async (err, members) => {

                        if (err) {
                            return console.error(`Error retrieving presence data: ${err}`);
                        }
                        self.$store.commit("updateAblyParticipants", members)

                        if (members.length >= 2) {
                            await this.generateChallenge()
                            if (this.currentGeneratedChallenge.success == false) {
                                this.downloadingTest = false
                            }
                            else {
                                if (msg.action == "enter") {
                                    await this.publishChallengeMessage()
                                }
                                await this.updateTestData()
                                console.log("check participants");
                                this.participantsReady = this.checkParticipantsReady()
                            }
                        }
                        else {
                            this.participantsReady = false
                        }
                    });
                });
            });

        }
    },
    unmounted() {
        console.log('destroyed')
        this.ablyChannel.presence.unsubscribe()
    },
    computed: {
        ...mapState(["selectedChallenge", "ablyRealTime", "ablyParticipants", "startTestInfo", "ablyChannel"]),
        userName: {
            get() {
                return this.$store.state.userState.name
            }
        },
        quizTemplate: {
            get() {
                return this.selectedChallenge.quizTemplate
            }
        },
        participantJoined: {
            get() {
                let members = this.ablyParticipants
                if (members.length > 1) {
                    return true
                }
                else {
                    return false
                }
            }
        },
        startChallengeAllowed:
        {
            get() {
                return this.participantsReady && this.testDownloaded
            }
        },
        participantsStateMessage: {
            get() {
                if (this.participantJoined) {
                    return "Joined"
                }
                else {
                    return "Waiting for the participant to join"
                }
            }
        },
        displayBreadCrumbs: {
            get() {
                return [this.breadCrumbs[this.breadCrumbs.length - 1]]
            }
        }
    },

    watch: {

    },

    methods: {
        async updateTestData() {
            if (!this.testDownloaded) {
                this.downloadingTest = true

                const testJson = await axios.get(this.currentGeneratedChallenge.testurl)
                // console.log(testJson);
                this.$store.commit("setTestJson", testJson.data)
                this.$store.commit("updateQResponsesState")


                setLocalForageItem('qDurations', [])
                setLocalForageItem('qResponses', [])
                setLocalForageItem('serverLastAttemptSavedTimeStamp', '')
                this.downloadingTest = false
                this.testDownloaded = true
                this.ablyChannel.presence.update({
                    name: getLoggedInUserName(),
                    ready: true,
                    host: true,
                    participant: false,
                    submitted: false
                })
            }
        },
        async copyCode(code) {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(code);
                this.snackbar = true
            }
            else {
                console.log('The Clipboard API is not available.');
            }
        },

        checkParticipantsReady() {
            let members = this.ablyParticipants
            if (members.length > 0) {
                console.log("check");

                for (let item of members) {
                    if (item.data.ready == false) {
                        return false
                    }
                }
                return true
            }
            else {

                return false

            }

        },
        async generateChallenge() {
            this.$store.commit("updateCurrentChallengeId", this.selectedChallenge.challengeId)

            await this.$store.dispatch("generateChallenge", {
                challengeId: this.selectedChallenge.challengeId
            })
            this.currentGeneratedChallenge = this.startTestInfo
            this.$store.commit("updateCurrentTestId", this.currentGeneratedChallenge.testId)


        },
        async publishChallengeMessage() {
            console.log(this.currentGeneratedChallenge);
            await this.ablyChannel.publish("testInfo", {
                challengeId: this.selectedChallenge.challengeId,
                testId: this.currentGeneratedChallenge.testId
            }
            )
        },
        getFirstParticipantName() {
            let members = this.ablyParticipants
            if (this.participantJoined) {
                let firstMember = members[1]
                if ("data" in firstMember && "name" in firstMember.data) {
                    return firstMember.data.name
                }
            }
            else {
                return ""
            }
        },
        getTestStatus() {
            if (this.downloadingTest == null) {
                return ""
            }
            if (this.downloadingTest == true) {
                return "Downloading Challenge"
            }
            if (this.testDownloaded) {
                return ""
            }
        },
        async startChallengeForEveryone() {
            this.$store.commit("updateUserType", "host")
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeHostWaitingRoomScreenStartChallenge, {
                challengeId: this.selectedChallenge.challengeId,
                challengeCode: this.selectedChallenge.code,

                testId: this.currentGeneratedChallenge.testId,
                testInstanceId: this.currentGeneratedChallenge.testInstanceId


            });
            this.ablyChannel.publish("startTest", {})
            this.$store.commit("updateTestRoutingAllowed", true)
            this.$router.replace('/challenge/challengestartingscreen')
        },
        createNodes(challengeId) {
            this.breadCrumbs = [];

            this.breadCrumbs.push(
                {
                    title: "Challenge",
                    disabled: false,
                    parentNodeIdKeyinChildNodesMap: "challenge",
                    pageRoute: `/challenge`

                });
            this.breadCrumbs.push(
                {
                    title: "Challenge Types",
                    disabled: false,
                    parentNodeIdKeyinChildNodesMap: "challenges",
                    pageRoute: `/challenge/challenges`

                });
            this.breadCrumbs.push(
                {
                    title: "Host Waiting Room",
                    disabled: false,
                    parentNodeIdKeyinChildNodesMap: "hostwaitingroom",
                    pageRoute: `/challenge/hostwaitingroom/${challengeId}`

                });
        },
        back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
            this.$router.replace(page)
        },
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        }
    },
}
</script>
          
<style scoped>
.testCard {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background: rgb(var(--v-theme-cardBackgroundColor));

}

.border-code {
    background: rgba(241, 241, 241, 0.05);
    border-radius: 8px;
}

.heightBread {
    height: 80px;
}

.btn-color {
    background: #A174FD;
    border: 1.5px solid #A174FD;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
}

.layoutLarge {
    height: calc(100% - 80px);
}

.roomCodeCard {
    background: rgba(222, 20, 20, 0.05);
    border-radius: 8px;

}

.copy {
    color: rgba(255, 255, 255, 0.3) !important;
}


#speech.btn {}

.pulse-ring {

    border: 5px solid #FF3D7F;
    border-radius: 50%;

    animation: pulsate infinite 1.3s;
}

@keyframes pulsate {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }

    100% {
        transform: scale(1.1, 1.1);
        opacity: 0;
    }
}
</style>
          