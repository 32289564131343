<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>

  <v-container fluid class="pb-4 main-lg">
    <v-container fluid class="pa-0 container-width">
      <v-row>
        <v-col>
          <v-card class="main-card text-white mb-4">
            <v-card-title class="px-5 pt-4 pb-0">
              <span v-if="className !== '' " class="main-heading">
                {{ className }}
              </span>
              <span v-else class="main-heading">Class Details</span>
              <v-divider class="mt-2"></v-divider>
            </v-card-title>
            <v-card-text class="pa-5 pt-4">
              <v-row class="ma-0">
                <v-col
                  cols="auto"
                  class="mr-1 tab t-1remReg text-white"
                  :class="{ 'selected-tab': tab.id == tabIndex }"
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click.stop="changeTab(tab)"
                >
                  {{ tab.name }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      
          <v-card class="main-card text-white">
            <v-card-text class="pa-0">
              <v-row class="ma-0" v-if="tabIndex == '1'"> 
                <AssignmentComponent assignmentType="batch" :batchId="batchId"></AssignmentComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '2'">
                <AssignmentProgressComponent :batchId="batchId"></AssignmentProgressComponent>
              </v-row>
              <v-row v-else-if="tabIndex == '3'"> 
                <TopicWiseSummaryComponent :batchId="batchId"></TopicWiseSummaryComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '4'"> 
                <ClassStudentsListComponent :batchId="batchId"></ClassStudentsListComponent>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  
</template>
<script>
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import _ from "lodash";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { breadcrumbHandler} from "@/services/breadcrumbHandler";
import AssignmentComponent from "../teacher/AssignmentComponent.vue";
import ClassStudentsListComponent from "./ClassStudentsListComponent.vue";
import AssignmentProgressComponent from "./AssignmentProgressComponent.vue";
import TopicWiseSummaryComponent from "./TopicWiseSummaryComponent.vue";
export default {
  name: "ClassDetailPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  components: {
    AssignmentComponent,
    ClassStudentsListComponent,
    AssignmentProgressComponent,
    TopicWiseSummaryComponent,
  },
  data: () => ({
    dataLoaded: false,
    selectedTab: null,
    batchId: '',
    tabs: [
      {
        name: "Assignment",
        id: "1",
      },
      {
        name: "Assignment Progress",
        id: "2",
      },
      {
        name: "Test Summary",
        id: "3",
      },
      {
        name: "Students",
        id: "4",
      },
    ],
    tabIndex: "",
  }),
  async beforeRouteUpdate(to, from) {
    this.batchId = this.$route.params.batchId;
    console.log('beforeRouteUpdate', to)
    // this.tabIndex = this.$route.params.tab;
    // this.selectedTab = _.find(this.tabs, {id: this.tabIndex});
  },
  async created() {
    if(!this.isUserTeacher ){
      if(this.isUserAdmin){
        this.$router.replace('/admin/1');
      }
      else{
        this.$router.replace('/learn');
      }
    }
    
    breadcrumbHandler(this);
    this.$store.commit('appbarVisible', true);
		this.$store.commit('navbarVisible', true);

    this.$store.commit("clearTeacherAssignments");
    console.log('created',this.$route.params);
    this.batchId = this.$route.params.batchId;
    this.tabIndex = this.$route.params.classesTab;
    if(this.$route.params.classesTab > this.tabs.length){
      this.$router.replace('1');
      this.tabIndex = 1;
    }
    this.selectedTab = _.find(this.tabs, {id: this.tabIndex});
    this.dataLoaded = true;

    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassTabSwitched, { 
      courseId: this.courseId,
      batchId: this.batchId,
      tabName: this.selectedTab.name,
    });
  },
  computed: {
    ...mapState(['className', 'breadCrumbs', "isUserTeacher", "isUserAdmin"]),
    displayBreadCrumbs: {
      get(){
        let breadCrumbs = this.breadCrumbs;
        // breadCrumbs.pop();
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        return breadCrumbs;
      }
    },
  },

  watch: {},

  methods: {
    createNodes() {
      this.breadCrumbs = [];
      
      this.breadCrumbs.push({
        title: "Classes",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "testattempt",
        pageRoute: `/classes/`,
      });
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
    },
    breadCrumbRoute(item){
      this.$router.replace(item.pageRoute)
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    changeTab(tab) {
      console.log("this.tabIndex", this.tabIndex, tab);
      this.tabIndex = tab.id;
      this.selectedTab = tab;
      const batchId = this.$route.params.batchId;
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassTabSwitched, { 
        courseId: this.courseId,
        batchId: batchId,
        tabName: tab.name,
      });
      this.$router.replace(`${tab.id}`);
    },
  },
};
</script>
<style scoped>

.heightBread {
  height: 80px;
}

.container-width {
  max-width: 1080px;
}

.main-lg {
  padding-top: 20px;
}

.main-md {
  padding-top: 60px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.tab-name{
  font-weight: 600;
}

.tab {
  background: rgb(var(--v-theme-report-subCardBg));
  padding: 10px 15px;
  margin-right: 12px !important;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 12px;
}

.selected-tab {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
  border: 2px solid transparent;
}
</style>
