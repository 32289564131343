<template>
  <MyCollectionDialogComponent v-if="openCollectionDialog" @closeCollectionDialog="closeCollectionDialog()"
    @showCollectionSnackBar="openCollectionSnackBar" @collectionMsg="updateCollectionSnackBarMsg"
    :openCollectionDialog="openCollectionDialog" :showExistingCollections="showExistingCollections" :editName="editName"
    :collectionId="editCollectionId" />

  <!-- Delete Collection -->
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent v-model="dialogDelete" max-width="400px">
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0">
        <span class="assignment-form-title">Are you sure you want to delete '{{ deleteItem.collectionName }}' ?</span>
      </v-card-text>
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="closeDeleteDialog()">No</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="deleteCollection()">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogShareLink" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white" width="fit-content">
      <v-card-text class="text-white px-2  pb-3">
        <v-row dense class="t-1remReg mb-1 mx-2" justify="center">
          <v-col class="text-center">
            Share your collection
          </v-col>
          <v-col class="text-right" cols=auto>
            <v-icon @click.stop="dialogShareLink = false" size=20>mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="t-subtitle text-center mb-7 mx-4 mx-sm-auto" justify="center">
          Copy the link below and share it with you students.
        </v-row>

        <v-row v-if="$vuetify.display.smAndUp" justify="space-between" align="center"
          class=" my-4 mx-2 border-code py-2">
          <v-col cols="auto" class="my-0 py-0 t-subtitle  text-primaryTextColor"> {{ getUrl() }} </v-col>
          <v-col cols="" class="my-0 py-0 text-right">
            <v-icon @click="copyCode(getUrl())" color="grey" size="20" class="copy">
              mdi-content-copy
            </v-icon>
          </v-col>
        </v-row>

        <v-row v-else justify="center" align="center" class="mx-2 mb-4">
          <v-col cols="auto" class="pa-0">
            <v-btn append-icon="mdi-content-copy" class="t-btn text-white activity-btn text-none px-3 pr-5"
              density="default" variant="flat" @click="copyCode(getUrl())">
              <template v-slot:append>
                <v-icon color="primaryTextColor"></v-icon>
              </template>
              Copy Link
            </v-btn>
          </v-col>
        </v-row>


      </v-card-text>

    </v-card>
  </v-dialog>


  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="showNoQuestion" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white" max-width="400px">
      <v-card-text class="text-white">
        <v-row dense class="t-1remReg mb-1 mx-2" justify="center">
          <v-col class="text-center">
            No Question Available
          </v-col>
          <v-col class="text-right" cols=auto>
            <v-icon @click.stop="showNoQuestion = false" size=20>mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="t-subtitle text-center mx-4 mb-3 mx-sm-auto" justify="center">
          Currently, this collection does not contain any questions. You can add questions using the provided AI tools.
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog fullscreen no-click-animation persistent :model-value="dialogViewQuestions" scrollable>
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <ViewQuestionsComponent :questionsData="viewQuestionsData" @closeDialog="closeQuestionsPage"
          :fromCollections="true" :collectionId="questionCollectionId">
        </ViewQuestionsComponent>
      </v-card-text>
    </v-card>
  </v-dialog>

  <EditQuestionComponent v-if="openEditQuestionDialog" @closeQuestionDialog="closeQuestionDialog"
    @saveQuestionResponse="saveQuestionResponse" :questionItem="questionItem"
    @showQuestionSnackbar="showQuestionSnackbar = true" @questionSnackbarMsg="updatequestionSnackbarMsg" />

  <v-layout v-if="showCollectionsList" class="mb-0 pb-0 d-flex flex-column">

    <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }" justify="space-between"
      align="center">
      <v-col class="ma-0 pa-0">
        <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template v-slot:prepend>
            <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
              @click="back()" icon="mdi-chevron-left">
            </v-btn>
          </template>
          <template v-slot:title="{ item }">
            <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="auto">
        <MentoAITokensComponent />
      </v-col>
      <v-divider></v-divider>
    </v-row>

    <v-container fluid class="ma-0 pa-0 main-lg">
      <v-container fluid class="mt-3 container-width">
        <v-row align="center" justify="center" dense>
          <v-col>

            <v-row class="pa-0 ma-0" align="center">
              <v-col class="pa-0 d-flex flex-column justify-center">
                <span class="t-h125Reg mb-1 text-primaryTextColor">My Collections</span>
                <span class="t-1remReg  mb-1 test-subtitle">These are all the collections of questions created by
                  you</span>
              </v-col>

              <v-col cols="auto" class="pr-0">
                <v-btn prepend-icon="mdi-plus" class="t-btn text-white activity-btn text-none px-3 pr-5"
                  density="default" variant="flat" @click="createNewCollection()">
                  <template v-slot:prepend>
                    <v-icon color="primaryTextColor"></v-icon>
                  </template>
                  Create a collection
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

        <v-row align="center" justify="center" dense>
          <v-col cols="12">
            <template v-if="collectionsList?.length > 0">
              <template v-for="(item, i) in collectionsList" :key="i">
                <v-card class="card-bg card-border py-2 px-2 mb-3"
                  @click.stop="showCollectionQuestions(item.collectionId)">
                  <v-card-text class="text-primaryTextColor pa-0">
                    <v-row justify="space-between" align="center" class="ma-0 px-1">

                      <!-- Name -->
                      <v-col class="d-flex align-center justify-start pa-1">
                        <div class="mr-4">
                          <v-avatar size="40" color="report-subCardBg" class="icon-bg">
                            <v-icon icon="mdi-book-open-blank-variant-outline" color="#ffffff" class=""
                              size="25"></v-icon>
                          </v-avatar>
                        </div>
                        <div class="pb-0 wrap-text">
                          <div class="d-flex flex-column justify-start">
                            <span v-if="item.collectionName != 'null'" class="t-1remReg">{{ item.collectionName
                              }}</span>
                            <span v-else class="t-1remReg"> - </span>

                            <div class="">
                              <span class="t-h6Reg parameter-style">Created on {{ displayLocalDate(item.createdOn) }}
                              </span>
                            </div>

                            <div class="">
                              <span class="t-h6Reg parameter-style">{{ item.collectionDetails.totalQuestionsCount }} questions
                              </span>
                            </div>
                          </div>
                        </div>

                      </v-col>

                      <!-- Students Opened -->
                      <v-col cols="12" sm="2" class="d-flex flex-column align-start pa-1" >
                        <div class="pb-0">
                          <span class="t-h6Reg text-capitalize">{{ 
                            item.sharedLinkDetails.usersLinkOpenCount?? '-' }} 
                            {{ 
                              (item.sharedLinkDetails.usersLinkOpenCount?? 0) > 1 ? 'Students' : 'Student' }}</span>
                        </div>
                        <div class="">
                          <span class="t-h6Reg parameter-style">Opened</span>
                        </div>
                      </v-col>

                      <!-- Students completed -->
                      <v-col cols="12" sm="2" class="d-flex flex-column align-start pa-1">
                        <div class="pb-0">
                          <span class="t-h6Reg">{{ 
                            item.sharedLinkDetails.userPracticeCompletedCount?? '-' }} {{ 
                              (item.sharedLinkDetails.userPracticeCompletedCount??0) > 1 ? 'Students' : 'Student' }}</span>
                        </div>
                        <div class="">
                          <span class="t-h6Reg parameter-style">Completed</span>
                        </div>
                      </v-col>

                      <!-- Edit -->
                      <v-col cols="auto">
                        <v-row align="center">

                          <v-col cols="auto" class="mr-1">

                            <v-tooltip location="top">
                              <template #activator="{ props }">
                                <v-btn v-bind="props" prepend-icon="mdi-share"
                                  class="t-btn text-white share-btn text-none px-3 pr-5" density="default"
                                  variant="flat" @click.stop="shareLink(item.collectionId)">
                                  <template v-slot:prepend>
                                    <v-icon color="primaryTextColor"></v-icon>
                                  </template>
                                  Share link
                                </v-btn>
                              </template>
                              <span>Share this with your students</span>
                            </v-tooltip>

                          </v-col>

                          <v-col cols="auto" class="mr-1">
                            <v-menu>
                              <template v-slot:activator="{ props: menu }">
                                <v-tooltip location="top">
                                  <template v-slot:activator="{ props: tooltip }">
                                    <v-icon v-bind="mergeProps(menu, tooltip)" small class="outline-color"
                                      @click.stop="undefined">mdi-dots-vertical</v-icon>
                                  </template>
                                  <span>More actions</span>
                                </v-tooltip>
                              </template>
                              <v-list>


                                <!-- Add new question  -->
                                <v-list-item @click="addNewQuestion(item.collectionId)">
                                  <template v-slot:prepend>
                                    <v-icon icon="mdi-plus"></v-icon>
                                  </template>
                                  <v-list-item-title>Add a question</v-list-item-title>
                                </v-list-item>

                                <!-- View Question -->
                                <v-list-item @click="showCollectionQuestions(item.collectionId)">
                                  <template v-slot:prepend>
                                    <v-icon icon="mdi-format-list-bulleted"></v-icon>
                                  </template>
                                  <v-list-item-title>View questions</v-list-item-title>
                                </v-list-item>

                                <!-- Edit Collection Name -->
                                <v-list-item @click="editCollectionName(item.collectionId)">
                                  <template v-slot:prepend>
                                    <v-icon icon="mdi-pencil"></v-icon>
                                  </template>
                                  <v-list-item-title>Edit collection name</v-list-item-title>
                                </v-list-item>

                                <!-- Delete  -->
                                <v-list-item @click="openDeleteDialog(item.collectionId, item.collectionName)">
                                  <template v-slot:prepend>
                                    <v-icon icon="mdi-delete"></v-icon>
                                  </template>
                                  <v-list-item-title>Delete collection</v-list-item-title>
                                </v-list-item>

                              </v-list>
                            </v-menu>
                          </v-col>


                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </template>
            <template v-if="collectionsList?.length == 0">
              <v-card class="card-bg card-border py-2 px-2 mb-3">
                <v-card-text class="text-primaryTextColor pa-0">
                  <v-row justify="space-between" align="center" class="ma-0 px-1">

                    <v-col cols="12" class="text-center pa-1">
                      <div class="pb-0 wrap-text">
                        <span class="t-1remReg">No collection found. Please create a new collection.</span>
                      </div>

                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>


      </v-container>
    </v-container>
  </v-layout>

  <v-snackbar v-model="showCollectionSnackBar" :timeout="-1" color="cardBackgroundColor" location="bottom">
    <span class="text-easyColor">{{ collectionMsg.text }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeCollectionSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>

  <v-snackbar v-model="showDeleteMessage" :timeout="-1" color="cardBackgroundColor">
    <span class="submit-message-text text-report-red">{{ deleteErrorMessage }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>

  <v-snackbar v-model="showQuestionSnackbar" :timeout="-1" color="cardBackgroundColor" location="bottom">
    <span class="text-easyColor">{{ questionSnackbarMsg }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="showQuestionSnackbar = false;" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import moment from "moment-timezone";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import { breadcrumbHandler } from "@/services/breadcrumbHandler";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import _, { takeWhile } from "lodash";
import MyCollectionDialogComponent from "@/components/ai/MyCollectionDialogComponent.vue";
import ViewQuestionsComponent from "../teacher/ViewQuestionsComponent.vue";
import MentoAITokensComponent from "@/components/ai/MentoAITokensComponent.vue";
import EditQuestionComponent from "../teacher/EditQuestionComponent.vue";
import { mergeProps } from 'vue'

export default {
  name: "MyCollections",
  components: {
    MyCollectionDialogComponent, ViewQuestionsComponent, MentoAITokensComponent, EditQuestionComponent
  },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    showCollectionsList: false,
    openCollectionDialog: false,
    openEditQuestionDialog: false,
    showExistingCollections: false,
    aiQuestionId: "hello",
    showCollectionSnackBar: false,
    collectionMsg: "",
    dialogViewQuestions: false,
    dialogShareLink: false,
    viewQuestionsData: {},
    editName: false,
    shareCollectionId: '',
    editCollectionId: '',
    defaultQItem: {
      "questionPartitionKey": "",
      "aiQuestionId": "",
      "questionDesc": "",
      "questionTypeId": "",
      "a": "",
      "b": "",
      "c": "",
      "d": "",
      "answerSingleChoice": "",
      "difficultyLevel": "",
      "domainId": "",
      "insertedBy": "",
      "insertedTimeStamp": 0,
      "categoryId": "",
      "solutionText": "",
      "subjectId": "",
      "topicId": ""
    },
    deleteItem: {
      collectionId: '',
      collectionName: '',
    },
    defaultDeleteItem: {
      collectionId: '',
      collectionName: '',
    },
    dialogDelete: false,
    showDeleteMessage: false,
    deleteErrorMessage: "",
    questionCollectionId: "",
    showNoQuestion: false,
    showQuestionSnackbar: false,
    questionSnackbarMsg: '',
  }),
  async mounted() {
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);
    breadcrumbHandler(this);
    this.showCollectionsList = false;

    await this.$store.dispatch('getCollectionsList', {})
    this.showCollectionsList = true;
    recordAnalyticsEvent(AnalyticsEvents.v2AIMyCollectionsScreenOpened, {});
    this.$store.dispatch("loadEntitiesWithoutLoader");
  },
  methods: {
    mergeProps,
    breadCrumbRoute(item) {
      this.$router.replace(item.pageRoute)
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
    },
    createNewCollection() {
      this.openCollectionDialog = true;
    },
    async showCollectionQuestions(collectionId) {
      let reqData = { collectionId };
      let response = await this.$store.dispatch('getCollectionQuestionsList', reqData);
      if (!_.isEmpty(response) || "practiceTestQuestionSummary" in response) {
        this.viewQuestionsData.testInstanceQuestionSummary = response.practiceTestQuestionSummary;
        this.questionCollectionId = collectionId;
        if (response.practiceTestQuestionSummary.sections == null || response.practiceTestQuestionSummary.sections.length == 0 || response.practiceTestQuestionSummary.sections[0].questions.length == 0) {
          this.showNoQuestion = true;
        }
        else {
          this.dialogViewQuestions = true;
        }
        recordAnalyticsEvent(AnalyticsEvents.v2AICollectionViewQuestionCliked, { collectionId: collectionId });
      }
    },
    closeQuestionsPage() {
      this.dialogViewQuestions = false;
    },
    closeCollectionDialog() {
      this.aiQuestionIds = [];
      this.editCollectionId = "";
      this.editName = false;
      this.openCollectionDialog = false;
    },
    displayLocalDate(timestamp) {
      if (timestamp == null || timestamp.length == 0) {
        return "-";
      }
      timestamp = Math.round(timestamp / 1000);
      // let date = new Date(timestamp);
      var dateString = moment.unix(timestamp).format("ll");
      return dateString
    },
    closeCollectionSnackBar() {
      this.collectionMsg = "";
      this.showCollectionSnackBar = false;
    },
    openCollectionSnackBar() {
      this.showCollectionSnackBar = true;
    },
    updateCollectionSnackBarMsg(data) {
      this.collectionMsg = data;
    },
    editCollectionName(collectionId) {
      this.editName = true;
      this.editCollectionId = collectionId;
      recordAnalyticsEvent(AnalyticsEvents.v2AICollectionEditNameButtonClicked, { collectionId: collectionId });

      this.openCollectionDialog = true;
    },
    openDeleteDialog(collectionId, collectionName) {
      this.deleteItem.collectionId = collectionId;
      this.deleteItem.collectionName = collectionName;
      recordAnalyticsEvent(AnalyticsEvents.v2AICollectionDeleteButtonClicked, { collectionId: collectionId });
      this.dialogDelete = true;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.deleteItem = _.cloneDeep(this.defaultDeleteItem);
    },
    deleteCollection() {
      let reqData = {
        collectionId: this.deleteItem.collectionId,
      };
      this.$store.dispatch("deleteCollection", reqData).then((res) => {
        if (res.success) {
          this.$store.commit("deleteCollection", reqData);
          recordAnalyticsEvent(AnalyticsEvents.v2AICollectionRemoved, { collectionId: reqData.collectionId });
          this.closeDeleteDialog();
        }
        else {
          this.deleteErrorMessage = res.message;
          this.showDeleteMessage = true;
        }
      })
    },
    closeSnackBar() {
      this.showDeleteMessage = false;
      this.deleteErrorMessage = "";
    },
    shareLink(collectionId) {
      this.shareCollectionId = collectionId;
      recordAnalyticsEvent(AnalyticsEvents.v2AICollectionShareButtonClicked, { collectionId: this.shareCollectionId });
      this.dialogShareLink = true
    },
    getUrl() {
      return window.location.origin + "/ta/" + this.shareCollectionId
    },
    async copyCode(code) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(code);
        recordAnalyticsEvent(AnalyticsEvents.v2AICollectionShareLinkCopied, { collectionId: this.shareCollectionId });
        this.updateCollectionSnackBarMsg({ text: "Your collection assignment link has been copied to clipboard." })
        this.openCollectionSnackBar()
      }
      else {
        console.log({ text: 'The clipboard API is not available.' });
      }
    },
    addNewQuestion(collectionId) {

      this.questionItem = Object.assign({}, this.defaultQItem);
      this.questionItem.collectionId = collectionId
      this.questionItem.questionTypeId = '1'

      this.openEditQuestionDialog = true;
    },
    closeQuestionDialog() {
      this.openEditQuestionDialog = false;

      this.questionItem = {};
    },
    saveQuestionResponse(questionItem) {
      console.log("save question");
    },
    updatequestionSnackbarMsg(msg) {
      this.questionSnackbarMsg = msg;
    }
  },
  computed: {
    ...mapState(["isUserTeacher", "isUserAdmin", "courseId", "breadCrumbs",]),
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = this.breadCrumbs;
        // breadCrumbs.pop();
        breadCrumbs[breadCrumbs.length - 1].disabled = true;
        return breadCrumbs;
      }
    },
    collectionsList: {
      get() {
        let list = this.$store.state.collectionsList;
        list = _.orderBy(list, [collection => collection?.collectionName.toLowerCase()], "asc");
        return list;
      }
    }
  },
}
</script>

<style scoped>
.main-lg {
  padding-top: 20px !important;
}

.container-width {
  max-width: 900px;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.share-btn {
  background: #393850;
  border-radius: 12px;
}


.card-bg {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;

}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.parameter-style {
  opacity: 0.4;
}

.assignment-form-main {
  overflow: auto !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.delete-btn {
  border: 2px solid red !important;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.border-code {
  background: rgba(241, 241, 241, 0.05);
  border-radius: 8px;
}
</style>