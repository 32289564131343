import {
  secureGetAPI,
  securePostAPI,
  securePostAPIWithoutOverlay,
  securePostAPIWithoutOverlayWithoutData,
  fetchAPIUrls,
  secureGetAPIWithCredentials,
  secureDeleteAPI,
  fetchStartAPIUrls,
  secureGetAPIWithoutOverlay,
  securePostAPIWithCustomerId,
  secureGetAPIWithCustomerId,
  securePostAPIWithCustomerIdWithoutOverlay,
  securePostAPIWithoutHeadersWithoutOverlay,
} from "@/services/apiService";
import {
  getLoggedInUserOrganization,
  getLoggedInUserId,
  getHeaders,
  storeRequestId,
  getRequestId,
} from "@/services/authService";
import {
  getLocalForageItem,
  setLocalForageItem,
} from "@/services/localforageService";
import store from ".";
import { useNotification } from "@kyvg/vue3-notification";

export default {
  async getAPIUrlsForFlavorAndVersion(context) {
    return fetchAPIUrls().then((response) => {
      context.commit("setAPIUrlsForFlavorAndVersion", response?.appurls);
    });
  },

  async userSSO(context, reqData) {
    return securePostAPI({ ...reqData }, "mysa/idam/validatessotoken").then(
      (response) => {
        if (!response) {
          response = {};
        }
        if ("error" in response) {
          context.commit("setLoginError", response);
        } else {
          context.commit("storeLoggedInUserDetails", response);
        }
      }
    );
  },

  async userLoginByCredentials(context, reqData) {
    const orgIdToAutheticate = context.state.app_defaultOrgnizationIdForFlavor;
    context.commit("clearLoginError");
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mysa/connectorg/organizations/${orgIdToAutheticate}/login`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if ("error" in response) {
        context.commit("setLoginError", response);
      } else {
        context.commit("storeLoggedInUserDetails", response);
      }
    });
  },
  async userLoginByCredentialsToSpecificOrg(context, reqData) {
    const orgIdToAutheticate = reqData.orgId;
    context.commit("clearLoginError");
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mysa/connectorg/organizations/${orgIdToAutheticate}/login`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if ("error" in response) {
        context.commit("setLoginError", response);
      } else {
        context.commit("storeLoggedInUserDetails", response);
      }
    });
  },
  async logout(context) {
    context.commit("logout");
    context.commit("resetStateToDefault");
  },

  async loadDomainsList(context) {
    let userOrgId = getLoggedInUserOrganization();
    let reqData = { organizationId: userOrgId };
    return secureGetAPI(
      { ...reqData },
      `mysa/dashboard/domains?organizationId=${userOrgId}`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadDomainsList", response);
    });
  },

  async fetchProfileInfo(context) {
    let userId = getLoggedInUserId();
    let apiPath = `mysa/connectorg/users/${userId}/fetchUser`;
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("fetchProfileInfo", response);
    });
  },

  async deleteProfile(context) {
    let userId = getLoggedInUserId();
    let apiPath = `mysa/connectorg/users/${userId}/deleteUser`;
    return secureDeleteAPI({}, apiPath).then((response) => {
      if (!response) {
        response = {};
      }
      if (response.error !== "") {
        console.log("error in the program", response.error);
        context.commit("setProfileDeleteError", response);
        console.log(context.state.deleteProfileError);
      } else {
        console.log("no error in the program");
      }
    });
  },

  async loadLearnHierarchyRootNodes(context) {
    let userOrgId = getLoggedInUserOrganization();
    let userId = getLoggedInUserId();
    let domainId = context.state.userCurrentDomain;
    let apiPath = `mysa/practice/organization/${userOrgId}/users/${userId}/domains/${domainId}/node/`;
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadLearnHierarchyChildNodes", {
        parentNodeId: domainId,
        children: response,
      });
    });
  },

  async loadNewLearnNodes(context) {
    let apiPath = `mentoapp/learningplan/entitiesv2`;
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadNewLearnNodes", response);
    });
  },

  async loadAIInsights(context) {
    let apiPath = `mentoapp/aihub/getAIInsights`;
    return securePostAPI({}, apiPath, false).then((response) => {
      console.log("rrr", response);
      if (!response) {
        response = [];
      }
    });
  },
  async loadNewLearnNodeActivity(context, reqData) {
    let apiPath = `mentoapp/learningplan/${reqData.entityId}/activities`;
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = {};
      } else if (response && response.error) {
        response = {};
      }
      context.commit("loadNewLearnNodeActivity", response);
      return response;
    });
  },
  async clearNewLearnNodeActivity(context) {
    context.commit("clearNewLearnNodeActivity");
  },
  async clearNewLearnNodes(context) {
    context.commit("clearNewLearnNodes");
  },

  async clearLearnHierarchyNodes(context) {
    context.commit("clearLearnHierarchyNodes");
  },

  async loadLearnHierarchyNodesUnderaNode(context, reqData) {
    //console.log(`loadLearnHierarchyNodesUnderaNode: ${JSON.stringify(reqData)}`);
    let userOrgId = getLoggedInUserOrganization();
    let userId = getLoggedInUserId();
    let domainId = context.state.userCurrentDomain;

    let apiPath = `mysa/practice/organization/${userOrgId}/users/${userId}/domains/${domainId}/`;
    let parentNodeId = null;
    if (reqData.nodeType == "FOLDER" || reqData.nodeType == "SUBFOLDER") {
      //if Children of a Folder or SubFolder are being requested
      apiPath = apiPath + `folder/${reqData.nodeId}`;
      parentNodeId = reqData.nodeId;
    } else if (
      reqData.nodeType == "COLLECTION" &&
      reqData.collectionType == "VIDEO"
    ) {
      //if Children of Video Collection
      apiPath = apiPath + `videocollections/${reqData.collectionId}`;
      parentNodeId = reqData.collectionId;
    } else if (
      reqData.contentType == "COLLECTION" &&
      reqData.collectionType == "VIDEO"
    ) {
      //if Children of Video Collection under a folder/subfolder
      apiPath = apiPath + `videocollections/${reqData.contentId}`;
      parentNodeId = reqData.contentId;
    } else if (
      reqData.nodeType == "COLLECTION" &&
      reqData.collectionType == "PDF"
    ) {
      //if Children of PDF Collection
      apiPath = apiPath + `pdfcollections/${reqData.collectionId}`;
      parentNodeId = reqData.collectionId;
    } else if (
      reqData.contentType == "COLLECTION" &&
      reqData.collectionType == "PDF"
    ) {
      //if Children of PDF Collection under a folder/subfolder
      apiPath = apiPath + `pdfcollections/${reqData.contentId}`;
      parentNodeId = reqData.contentId;
    } else {
      //if Children of other Node Types Collection
      apiPath = apiPath + `node/${reqData.nodeId}`;
      parentNodeId = reqData.nodeId;
    }
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadLearnHierarchyChildNodes", {
        parentNodeId: parentNodeId,
        children: response,
      });
    });
  },

  async getCloudFrontCookies(context, reqData) {
    return secureGetAPIWithCredentials(
      { ...reqData },
      "cloudfrontcookies"
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("updateCloudFrontCookieResponse", response);
    });
  },
  async lastActivity(context, reqData) {
    const currentTimestamp = Date.now();
    if (currentTimestamp - context.state.lastTimeStampOfIAmHere >= 10000) {
      context.commit("lastTimeStampOfIAmHere", currentTimestamp);

      return securePostAPIWithoutOverlay(
        { ...reqData },
        `mentoapp/iamhere/here`,
        false
      )
        .then((response) => {
          if (response && response.needToReload) {
            const { notify } = useNotification();
            notify({
              group: "reload",
              title: "New Update Available!",
              text: "A newer version of the application is available. Please refresh to enjoy the best experience and to ensure all features work correctly.",
              duration: -1,
              id: "2",
              ignoreDuplicates: true,
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  },
  async loadUserTestCollections(context, reqData) {
    if (!context.state.userTestCollectionsLoadedFromServer) {
      return secureGetAPI(
        { ...reqData },
        "mentoapp/studenttest/testcollection"
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadUserTestCollections", response);
        context.commit("userTestCollectionsLoadedFromServer", true);
      });
    }
  },

  async loadTests(context, reqData) {
    if (!context.state.testsLoadedFromServer) {
      return secureGetAPI(
        { ...reqData },
        `mentoapp/studenttest/collections/${reqData.collectionId}/tests`
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadTests", response);
        context.commit("testsLoadedFromServer", true);
      });
    }
  },

  async loadQuizReports(context, reqData) {
    let apiPath = `mentoapp/learningplan/activities/${reqData.activityId}/attemptsReport`;
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = [];
      } else if (response && response.error) {
        response = [];
      }
      context.commit("updateActivityQuizReporst", response);
      return response;
    });
  },
  // New one
  async loadAllReports(context, reqData) {
    let apiPath = "";
    if ("studentId" in reqData) {
      apiPath = `mentoapp/learningplan/topics/${reqData.topicId}/attemptsReport?userId=${reqData.studentId}`;
    } else {
      apiPath = `mentoapp/learningplan/topics/${reqData.topicId}/attemptsReport`;
    }
    return secureGetAPI({}, apiPath).then((response) => {
      if (!response) {
        response = [];
      } else if (response && response.error) {
        response = [];
      }
      context.commit("updateActivityQuizReporst", response);
      return response;
    });
  },
  async loadTestReports(context, reqData) {
    if (!context.state.testReportsLoadedFromServer) {
      return secureGetAPI(
        { ...reqData },
        `mentoapp/studenttest/test/${reqData.testId}/report`
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadTestReports", response);
        context.commit("testReportsLoadedFromServer", true);
      });
    }
  },

  async startTest(context, reqData) {
    if (!context.state.startTestLoadedFromServer) {
      return securePostAPIWithoutOverlay(
        { ...reqData },
        `mentoapp/studenttest/test/${reqData.testId}/startTest`
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("startTest", response);
        context.commit("startTestLoadedFromServer", true);
      });
    }
  },
  async startPractice(context, reqData) {
    if (context.state.assignmentId.length > 0) {
      reqData.assignmentId = context.state.assignmentId;
    }
    return securePostAPIWithoutOverlay(
      {},
      `mentoapp/studenttest/test/${reqData}/startPractice`
    ).then((response) => {
      if (!response) {
        response = {};
      }

      context.commit("updateAssignmentPracticeInfo", {});

      context.commit("getPracticeInfo", response);
    });
  },
  async nextPractice(context, reqData) {
    return secureGetAPI(
      {},
      `mentoapp/studenttest/test/collection/${reqData.testId}/testInstance/${reqData.testInstanceId}/question/${reqData.questionId}`
    ).then((response) => {
      if (!response) {
        response = {};
      }

      context.commit("getPracticeInfo", response);
    });
  },
  async validatePracticeQuestion(context, reqData) {
    if (context.state.assignmentId.length > 0) {
      reqData.responseBody.assignmentId = context.state.assignmentId;
    }
    return securePostAPIWithoutOverlay(
      { ...reqData.responseBody },
      `mentoapp/studenttest/test/${reqData.testId}/testInstance/${reqData.testInstanceId}/userPracticeAttempt`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("validatePractice", response);
    });
  },
  async updateQuestionDurationAndResponse(context, reqData) {
    //add to unsent qDuration list

    const qDuration = reqData.qDuration;
    const qResponse = reqData.qResponse;

    // console.log(qDuration);
    // console.log(qResponse);
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;
    newQDuationArray.push(qDuration);
    await setLocalForageItem("qDurations", newQDuationArray);

    //// QRESPONSE (**Upsert** with search and update  ) to unSentlist

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    var foundQResponse =
      newQResponseArray.find((x) => x.qId == qResponse.qId) ?? null;
    console.log("found qresponse", foundQResponse);
    if (foundQResponse == null) {
      newQResponseArray.push(qResponse);
    } else {
      Object.assign(foundQResponse, qResponse);
    }

    await setLocalForageItem("qResponses", newQResponseArray);

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;

    let body = { qDuration: newQDuationArray, qResponses: newQResponseArray };
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/userattempt`
    );
    // console.log(apiResponse);

    //api call faliure
    if (
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      context.commit("updateQuestionAttemptError", apiResponse.error);
    }

    //api call success
    else {
      if ("quizReport" in apiResponse && apiResponse.quizReport != null) {
        context.commit("updateCurrentQuizReport", apiResponse.quizReport);
      }
      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },

  async recordError(context, reqData) {
    const userId = context.state.userState.userId;
    const deviceId = context.state.userState.deviceId;
    return securePostAPIWithoutHeadersWithoutOverlay(
      { ...reqData, UserId: userId, DeviceId: deviceId, RequestId: "" },
      `mentoapp/error`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return;
    });
  },

  async updateSyncTimer(context, reqData) {
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let body = reqData;
    console.log(context.state.assignmentId);
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/syncTestTimer`
    );
    // console.log(apiResponse);
    context.commit("updateSyncTimer", apiResponse);
  },

  async submitTest(context) {
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      submit: true,
    };
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/userattempt`
    );
    // console.log(apiResponse);

    //api call faliure
    if (
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
      context.commit("updateSubmitError", apiResponse.error);
    }

    //api call success
    else {
      if ("quizReport" in apiResponse && apiResponse.quizReport != null) {
        context.commit("updateCurrentQuizReport", apiResponse.quizReport);
      }
      context.commit("updateSubmissionInfo", apiResponse);
      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },
  async loadTestResult(context, reqData) {
    if (!context.state.currentTestResultLoadedFromServer) {
      return secureGetAPI(
        { ...reqData },
        `mentoapp/studenttest/test/${reqData.testId}/testInstance/${reqData.instance}/solution`
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadTestResult", response);
        context.commit("testResultLoadedFromServer", true);
      });
    }
  },
  async updateFeedback(context, reqData) {
    return securePostAPI({ ...reqData }, `mentoapp/feedback`).then(
      (response) => {
        if (!response) {
          response = {};
        }
        context.commit("updateFeedback", response);
      }
    );
  },
  async updateQuestionReviewStatus(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/studenttest/question/${reqData.qId}/updatereviewstatus`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("updateQuestionReviewStatus", response);
    });
  },
  async loadQuestionReviewFeedbackDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/studenttest/question/${reqData.qId}/questionReviewFeedbackDetails`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadQuestionReviewFeedbackDetails", response);
    });
  },
  async loadPatternsList(context, reqData) {
    const currentTimestamp = Date.now();
    if (currentTimestamp - context.state.lastTimeStampOfPatternList >= 300000) {
      context.commit("lastTimeStampOfPatternList", currentTimestamp);

      return securePostAPI(
        { ...reqData },
        `mentoapp/studenttest/getPatternsList`
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadPatternsList", response);
      });
    }
  },
  async addQuestionMapping(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/studenttest/addQuestionMapping`
    );
  },
  async loadClassesList(context, reqData) {
    if (!context.state.classesListLoadedFromServer) {
      return securePostAPI(
        { ...reqData },
        `mysa/zoommeetings/getMeetingsForUser`
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadClassesList", response);
        context.commit("classesListLoadedFromServer", true);
      });
    }
  },
  async loadUserMessages(context, reqData) {
    if (!context.state.userMessagesLoadedFromServer) {
      return secureGetAPI({ ...reqData }, "mysa/message/usermessages").then(
        (response) => {
          if (!response) {
            response = [];
          }
          context.commit("loadUserMessages", response);
          context.commit("userMessagesLoadedFromServer", true);
        }
      );
    }
  },
  async updateUserMessageStatus(context, reqData) {
    return securePostAPI({ ...reqData }, "mysa/message/status", false).then(
      (response) => {
        if (response && response.success) {
          context.commit("userMessageUpdated", true);
        } else {
          context.commit("userMessageUpdated", false);
        }
      }
    );
  },
  async getUserInvite(context, reqData) {
    if (!context.state.classesListLoadedFromServer) {
      return securePostAPI(
        { ...reqData },
        `mysa/zoommeetings/getMeetingInvites`
      );
    }
  },
  async getPdfUrl(context, reqData) {
    return secureGetAPI({ ...reqData }, "signedurl?path=" + reqData);
  },
  async storeUserCurrentDomain(context, reqData) {
    context.commit("storeUserCurrentDomain", reqData.domainId);
    return securePostAPI(
      { ...reqData },
      "mysa/connectorg/saveUserDomain/" + reqData.domainId,
      false
    );
  },

  // challenges
  async loadAIChallenge(context, reqData) {
    if (!context.state.aiChallengeLoadedFromServer) {
      return secureGetAPI(reqData, "mentoapp/challenge/ai/request").then(
        (response) => {
          if (!response) {
            response = {};
          }
          context.commit("loadAIChallenge", response);
          context.commit("aiChallengeLoadedFromServer", true);
        }
      );
    }
  },
  async rejectAIChallenge(context, reqData) {
    return securePostAPIWithoutOverlay(
      reqData,
      "mentoapp/challenge/ai/participant/reject"
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("rejectAIChallenge", response);
    });
  },
  async acceptAIChallenge(context, reqData) {
    if (!context.state.acceptChallengeInfoLoadedFromServer) {
      return securePostAPI(reqData, "mentoapp/challenge/ai/accept").then(
        (response) => {
          if (!response) {
            response = [];
          }
          context.commit("acceptChallenge", response);
          context.commit("acceptChallengeInfoLoadedFromServer", true);
        }
      );
    }
  },
  async loadAIInstanceSteps(context, reqData) {
    if (!context.state.aiInstanceStepsLoadedFromServer) {
      return securePostAPI(reqData, "mentoapp/challenge/ai/instancesteps").then(
        (response) => {
          if (!response) {
            response = [];
          }
          context.commit("loadAIInstanceSteps", response);
          context.commit("aiInstanceStepsLoadedFromServer", true);
        }
      );
    }
  },
  async loadChallenges(context, reqData) {
    if (!context.state.challengesLoadedFromServer) {
      return securePostAPI(reqData, "mentoapp/challenge/templates").then(
        (response) => {
          if (!response) {
            response = [];
          }
          context.commit("loadChallenges", response);
          context.commit("challengesLoadedFromServer", true);
        }
      );
    }
  },
  async selectChallenge(context, reqData) {
    if (!context.state.selectedChallengeLoadedFromServer) {
      return securePostAPIWithoutOverlay(
        reqData,
        "mentoapp/challenge/template/select"
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("selectChallenge", response);
        context.commit("selectedChallengeLoadedFromServer", true);
      });
    }
  },
  async generateChallenge(context, reqData) {
    if (!context.state.challengeGeneratedFromServer) {
      return securePostAPIWithoutOverlay(
        reqData,
        "mentoapp/challenge/generate"
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("startTest", response);
        context.commit("challengeGeneratedFromServer", true);
      });
    }
  },
  async generateChallengeForParticipant(context, reqData) {
    if (!context.state.challengeGeneratedFromServer) {
      return securePostAPIWithoutOverlay(
        reqData,
        "mentoapp/challenge/participant/instance"
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("startTest", { ...response, type: reqData.type });
        context.commit("challengeGeneratedFromServer", true);
      });
    }
  },
  async acceptChallenge(context, reqData) {
    if (!context.state.acceptChallengeInfoLoadedFromServer) {
      return securePostAPI(
        reqData,
        "mentoapp/challenge/participant/accept"
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("acceptChallenge", response);
        context.commit("acceptChallengeInfoLoadedFromServer", true);
      });
    }
  },
  async submitChallenge(context, reqData) {
    const qDuration = reqData.qDuration;
    const qResponse = reqData.qResponse;

    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;
    newQDuationArray.push(qDuration);
    await setLocalForageItem("qDurations", newQDuationArray);

    //// QRESPONSE (**Upsert** with search and update  ) to unSentlist

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    var foundQResponse =
      newQResponseArray.find((x) => x.qId == qResponse.qId) ?? null;
    console.log("found qresponse", foundQResponse);
    if (foundQResponse == null) {
      newQResponseArray.push(qResponse);
    } else {
      Object.assign(foundQResponse, qResponse);
    }

    await setLocalForageItem("qResponses", newQResponseArray);

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let challengeId = store.getters.currentChallengeId;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      challengeId: challengeId,
      submit: true,
    };
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/challenge/test/${testId}/testInstance/${testInstanceId}/userattempt`
    );
    console.log(apiResponse);

    //api call faliure
    if (
      apiResponse &&
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
      context.commit("updateSubmitError", apiResponse.error);
    }

    //api call success
    else {
      context.commit("updateSubmissionInfo", apiResponse);

      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },
  async submitChallengeWithoutResponse(context) {
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let challengeId = store.getters.currentChallengeId;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      challengeId: challengeId,
      submit: true,
    };
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/challenge/test/${testId}/testInstance/${testInstanceId}/userattempt`
    );
    console.log(apiResponse);

    //api call faliure
    if (
      apiResponse &&
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
      context.commit("updateSubmitError", apiResponse.error);
    }

    //api call success
    else {
      context.commit("updateSubmissionInfo", apiResponse);

      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },
  async updateQuestionDurationAndResponseChallenge(context, reqData) {
    //add to unsent qDuration list
    const qDuration = reqData.qDuration;
    const qResponse = reqData.qResponse;

    // console.log(qDuration);
    // console.log(qResponse);
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;
    newQDuationArray.push(qDuration);
    await setLocalForageItem("qDurations", newQDuationArray);

    //// QRESPONSE (**Upsert** with search and update  ) to unSentlist

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    var foundQResponse =
      newQResponseArray.find((x) => x.qId == qResponse.qId) ?? null;
    console.log("found qresponse", foundQResponse);
    if (foundQResponse == null) {
      newQResponseArray.push(qResponse);
    } else {
      Object.assign(foundQResponse, qResponse);
    }

    await setLocalForageItem("qResponses", newQResponseArray);

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let challengeId = store.getters.currentChallengeId;
    let startTestInfo = store.getters.startTestInfo;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      challengeId: challengeId,
    };
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/challenge/test/${testId}/testInstance/${testInstanceId}/userattempt`
    );
    // console.log(apiResponse);

    //api call faliure
    if (
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
    }

    //api call success
    else {
      if (startTestInfo.type == "ai") {
        context.commit(
          "updateParticipantScoreWithAI",
          apiResponse.challengeScore
        );
      } else {
        context.state.ablyChannel.presence.update({
          score: apiResponse.challengeScore,
        });
      }
      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },

  async getChallengeReport(context, reqData) {
    if (!context.state.challengeReportFromServer) {
      return secureGetAPI(
        {},
        `mentoapp/challenge/${reqData.challengeId}/report`
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("challengeReport", response);
        context.commit("challengeReportFromServer", true);
      });
    }
  },
  async getPastChallenges(context, reqData) {
    if (!context.state.pastChallengesFromServer) {
      return secureGetAPI({ ...reqData }, `mentoapp/challenge/attempts`).then(
        (response) => {
          if (!response) {
            response = [];
          }
          context.commit("loadPastChallenges", response);
          context.commit("pastChallengesFromServer", true);
        }
      );
    }
  },
  async getUserInfoForChallenge(context, reqData) {
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mentoapp/challengesso/getuserinfo`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      if ("error" in response) {
        context.commit("setChallengeSSOInfoError", response);
      } else {
        context.commit("updateChallengeSSOInfo", response);

        context.commit("storeLoggedInUserDetails", response);
      }
    });
  },
  async getChallengeInfo(context, reqData) {
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mentoapp/challengesso/getchallengeinfo`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      if ("error" in response) {
        context.commit("setChallengeSSOInfoError", response);
      } else {
        context.commit("updateChallengeSSOInfo", response);
      }
    });
  },

  // On Boarding action
  async onBoardingStart(context, reqData) {
    return securePostAPI({ ...reqData }, "mentoapp/onboarding/start", true);
  },
  async onBoardingResponse(context, reqData) {
    return securePostAPI({ ...reqData }, "mentoapp/onboarding/response", false);
  },
  async onBoardingDomain(context, reqData) {
    return securePostAPI({ ...reqData }, "mentoapp/onboarding/domain", true);
  },
  async onBoardingLogin(context, reqData) {
    console.log(reqData);
    return securePostAPI({ ...reqData }, "mentoapp/onboarding/login", true);
  },
  async onBoardingValidate(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/onboarding/validate",
      true
    ).then((response) => {
      if (response.isVerified) {
        context.commit("storeLoggedInUserDetails", response);
      }
    });
  },
  async onBoardingUserDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/onboarding/getUserDetails",
      true
    ).then((response) => {
      if (response.isVerified) {
        context.commit("storeLoggedInUserDetails", response);
      }
    });
  },
  async getCustomersList(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/onboarding/getCustomers"
    ).then((response) => {
      context.commit("getCustomersList", response);
    });
  },

  //google verify
  async googleUserSSO(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/idam/googlevalidationssotoken"
    );
  },
  setConfigValues(context, reqData) {
    context.commit("setConfigValues", reqData);
  },
  async updateFirebaseInfo(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/notification/settings",
      true
    ).then((response) => {
      context.commit("updateFirebaseInfo", response);
    });
  },
  async sendFCMTokenToFirebase(context, reqData) {
    return securePostAPIWithoutOverlay(
      { ...reqData },
      "mentoapp/notification/token"
    ).then((response) => {
      context.commit("updateFirebaseInfo", response);
    });
  },
  async loadAllTests(context, reqData) {
    if (!context.state.testsLoadedFromServer) {
      let environment = "";
      if ("config" in context.state && "environment" in context.state.config) {
        environment = context.state.config.environment;
      }

      return secureGetAPIWithCustomerId(
        { ...reqData },
        `mentoapp/studenttest/getAllTests?environment=${environment}`
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadTests", response);
        context.commit("testsLoadedFromServer", true);
      });
    }
  },
  async getAllTests(context, reqData) {
    if (!context.state.testsLoadedFromServer) {
      let environment = "";
      if ("config" in context.state && "environment" in context.state.config) {
        environment = context.state.config.environment;
      }

      return secureGetAPIWithCustomerId(
        { ...reqData },
        `mentoapp/studenttest/tests?environment=${environment}`
      ).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadTests", response);
        context.commit("testsLoadedFromServer", true);
      });
    }
  },
  async startTestModule(context, reqData) {
    if (!context.state.startTestLoadedFromServer) {
      if (context.state.assignmentId.length > 0) {
        reqData.assignmentId = context.state.assignmentId;
      }
      return securePostAPIWithoutOverlay(
        { ...reqData },
        `mentoapp/studenttest/test/${reqData.testId}/moduleId/${reqData.moduleId}/startTest`,
        false
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("startTestModule", response);
        context.commit("startTestLoadedFromServer", true);
      });
    }
  },
  async updateModuleQuestionDurationAndResponse(context, reqData) {
    //add to unsent qDuration list
    const qDuration = reqData.qDuration;
    const qResponse = reqData.qResponse;

    // console.log(qDuration);
    // console.log(qResponse);
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;
    newQDuationArray.push(qDuration);
    await setLocalForageItem("qDurations", newQDuationArray);

    //// QRESPONSE (**Upsert** with search and update  ) to unSentlist

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    var foundQResponse =
      newQResponseArray.find((x) => x.qId == qResponse.qId) ?? null;
    console.log("found qresponse", foundQResponse);
    if (foundQResponse == null) {
      newQResponseArray.push(qResponse);
    } else {
      Object.assign(foundQResponse, qResponse);
    }

    await setLocalForageItem("qResponses", newQResponseArray);

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let moduleId = store.getters.selectedTest.moduleId;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      testSubmission: false,
      moduleSubmission: false,
    };
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/moduleId/${moduleId}/userattempt`
    );
    // console.log(apiResponse);

    //api call faliure
    if (
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
    }

    //api call success
    else {
      if ("quizReport" in apiResponse && apiResponse.quizReport != null) {
        context.commit("updateCurrentQuizReport", apiResponse.quizReport);
      }
      context.commit("updateSubmissionInfo", apiResponse);
      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },

  async updateModuleSyncTimer(context, reqData) {
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let moduleId = store.getters.selectedTest.moduleId;
    let body = reqData;
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    let apiResponse = await securePostAPIWithoutOverlay(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/moduleId/${moduleId}/syncTestTimer`
    );
    // console.log(apiResponse);
    context.commit("updateSyncTimer", apiResponse);
  },

  async submitTestModule(context, reqData) {
    const qDuationLocalForageresponse = await getLocalForageItem("qDurations");
    const newQDuationArray = qDuationLocalForageresponse;

    const qResponseLocalForageresponse = await getLocalForageItem("qResponses");
    const newQResponseArray = qResponseLocalForageresponse;

    //api call
    let testInstanceId = store.getters.startTestInfo.testInstanceId;
    let testId = store.getters.currentTestId;
    let moduleId = store.getters.selectedTest.moduleId;

    let body = {
      qDuration: newQDuationArray,
      qResponses: newQResponseArray,
      testSubmission: false,
      moduleSubmission: true,
    };
    console.log("xyzab", context.state.assignmentId);
    if (context.state.assignmentId.length > 0) {
      body.assignmentId = context.state.assignmentId;
    }
    if (reqData?.testSubmission) {
      body["testSubmission"] = true;
    }
    let apiResponse = await securePostAPIWithCustomerId(
      body,
      `mentoapp/studenttest/test/${testId}/testInstance/${testInstanceId}/moduleId/${moduleId}/userattempt`,
      false
    );
    // console.log(apiResponse);

    //api call faliure
    if (
      "error" in apiResponse &&
      apiResponse.error != null &&
      apiResponse.error.length > 0
    ) {
      // do nothing
      context.commit("updateSubmitError", apiResponse.error);
    }

    //api call success
    else {
      if ("quizReport" in apiResponse && apiResponse.quizReport != null) {
        context.commit("updateCurrentQuizReport", apiResponse.quizReport);
      }
      context.commit("updateSubmissionInfo", apiResponse);
      setLocalForageItem("qDurations", []);
      setLocalForageItem("qResponses", []);
      setLocalForageItem(
        "serverLastAttemptSavedTimeStamp",
        apiResponse.serverLastAttemptSavedTimeStamp
      );
    }
  },
  async loadTestModuleResult(context, reqData) {
    if (!context.state.currentTestResultLoadedFromServer) {
      return secureGetAPI(
        { ...reqData },
        `mentoapp/studenttest/test/${reqData.testId}/testInstance/${reqData.instance}/solution`
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadTestResult", response);
        context.commit("testResultLoadedFromServer", true);
      });
    }
  },
  async getTestReportData(context, reqData) {
    return secureGetAPI(
      {},
      `mentoapp/studenttest/test/${reqData.testId}/testInstance/${reqData.testInstanceId}/report`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getTestReportData", response);
    });
  },
  async getUserTestInstances(context, reqData) {
    let url = "";
    if ("studentId" in reqData) {
      url = `mentoapp/studenttest/test/${reqData.testId}/userTestInstances?studentId=${reqData.studentId}`;
    } else {
      url = `mentoapp/studenttest/test/${reqData.testId}/userTestInstances`;
    }
    return secureGetAPIWithCustomerId({}, url).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getTestInstancesData", response);
    });
  },
  async getTestCategoriesData(context, reqData) {
    return secureGetAPIWithoutOverlay(
      {},
      `mentoapp/studenttest/test/${reqData.testId}/categories`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getTestCategoriesData", response);
    });
  },
  async getTestTopicsData(context, reqData) {
    return secureGetAPIWithoutOverlay(
      {},
      `mentoapp/studenttest/test/${reqData.testId}/topics`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getTestTopicsData", response);
    });
  },

  //ai
  async updatePracticeNudgeInfo(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/mentopracticenudge/getNudgeId",
      true
    ).then((response) => {
      context.commit("updatePracticeNudgeInfo", response);
    });
  },
  async getPracticeNudgeMessage(context, reqData) {
    let headers = getHeaders();
    return securePostAPI(
      { ...reqData },
      `mentoapp/mentopracticenudge/organization/${headers.organizationid}/user/${headers.userid}/nudge/${reqData.nudgeId}`,
      true
    );
  },
  async createCheckoutSession(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/subscription/createCheckoutSession",
      true
    );
  },
  async getSubscriptionDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/subscription/getPaymentDetails`,
      true
    ).then((response) => {
      // context.commit("updateUserPackages", response);
      return response;
    });
  },
  async cancelSubscription(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/subscription/cancelSubscription",
      true
    );
  },
  async getPricingModels(context, reqData) {
    if (!context.state.pricingModelsLoadedFromServer) {
      return securePostAPI(
        { ...reqData },
        `mentoapp/pricing/getModelsV2`,
        true,
        false
      ).then((response) => {
        context.commit("updatePricingModels", response);
        context.commit("pricingModelsLoadedFromServer", true);
      });
    }
  },
  async createCheckSub(context, reqData) {
    return securePostAPI({ ...reqData }, "mentoapp/pricing/checksub", true);
  },

  async getProfileDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/pricing/getProfileV2",
      true
    ).then((response) => {
      context.commit("loadProfileDetails", response);
    });
  },

  async submitFeedback(context, reqData) {
    return securePostAPI({ ...reqData }, `mentoapp/feedback`).then(
      (response) => {
        context.commit("feedbackSubmissionInfoLoadedFromServer", response);
      }
    );
  },
  async storeReferralCode(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/onboarding/setUserReferral",
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if (response.success == true) {
        context.commit("setUserReferral", response);
      } else {
        context.commit(
          "updateLinkSnackbarError",
          response.message ?? " Please try again later"
        );
        context.commit("linkSnackbar", true);
      }
      return response;
    });
  },
  async getAIPrompts(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/aihub/getPrompts",
      true
    ).then((response) => {
      context.commit("getAiPromptsList", response);
    });
  },
  //assignments
  async loadAssignments(context, reqData) {
    if (!context.state.assignmentsLoadedFromServer) {
      return securePostAPI(
        { ...reqData },
        `mentoapp/studenttest/studentAssignments`,
        true,
        false
      ).then((response) => {
        context.commit("loadAssignments", response);
        if (reqData.isCurrent == true) {
          context.commit("updateAssignmentsCount", response);
        }
        context.commit("assignmentsLoadedFromServer", true);
      });
    }
  },

  // teacher view
  async loadEntities(context) {
    let apiPath = `mentoapp/teacherdashboard/entitiesv3`;
    if (!context.state.entitiesLoadedFromServer) {
      return secureGetAPI({}, apiPath).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadNewLearnNodes", response);
        context.commit("entitiesLoadedFromServer", true);
      });
    }
  },
  async loadEntitiesWithoutLoader(context) {
    let apiPath = `mentoapp/teacherdashboard/entitiesv3`;
    if (!context.state.entitiesLoadedFromServer) {
      return secureGetAPIWithoutOverlay({}, apiPath).then((response) => {
        if (!response) {
          response = [];
        }
        context.commit("loadNewLearnNodes", response);
        context.commit("entitiesLoadedFromServer", true);
      });
    }
  },
  async loadStudentList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/studentList`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadStudentList", response);
    });
  },
  async loadSearchedStudentList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/studentSearch`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadSearchedStudentList", response);
    });
  },
  async loadStudentPracticeTestReport(context, reqData) {
    let studentId = reqData.studentId;
    return securePostAPIWithCustomerId(
      {},
      `mentoapp/teacherdashboard/student/${studentId}/practiceTestReport`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadStudentPracticeTestReport", response);
    });
  },
  // mentoapp/teacherapp/batchList
  async getTeacherClassesData(context, reqData) {
    return securePostAPIWithCustomerId(
      {},
      "mentoapp/teacherdashboard/batchList"
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getTeacherClassesData", response);
    });
  },
  async loadTeacherAssignments(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/assignmentsV2"
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadTeacherAssignments", {
        data: response,
        isCurrent: reqData.isCurrent,
      });
    });
  },
  async addTeacherAssignment(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/createAssignment/v3",
      true
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("addTeacherAssignment", response);
      return response;
    });
  },
  async updateTeacherAssignment(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/updateAssignment",
      true
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("updateTeacherAssignment", response);
      return response;
    });
  },
  async deleteTeacherAssignment(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/deleteAssignment",
      true
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("deleteTeacherAssignment", reqData);
    });
  },
  async loadBatchStudentList(context, reqData) {
    let batchId = reqData.batchId;
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/batch/${batchId}/studentList`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadStudentList", response);
    });
  },
  async practiceQuestionsAssignment(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/viewPracticeQuestionsAssignment"
    );
  },

  async removeAssignmentQuestion(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/teacherdashboard/removeAssignmentQuestion"
    );
  },

  async loadStudentSkills(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/student/${reqData.studentId}/skills`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadStudentSkills", response);
    });
  },
  async aiResearchSkills(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/airesearch/getSkills`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("aiResearchSkills", response);
    });
  },
  async loadAssignmentReport(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData.data },
      `mentoapp/teacherdashboard/batch/${reqData.batchId}/assignmentReport`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadAssignmentReport", response);
    });
  },

  async loadTopicWiseReport(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData.data },
      `mentoapp/teacherdashboard/batch/${reqData.batchId}/practiceTestTopicReport`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadTopicWiseReport", response);
    });
  },

  async loadTags(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/tags`
    ).then((response) => {
      context.commit("loadTags", response);
    });
  },

  async loadNormalTests(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/teacherdashboard/tests`
    ).then((response) => {
      context.commit("loadNormalTests", response);
    });
  },

  // Admin View
  async loadOrganizationDetails(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/customerdetails`
    ).then((response) => {
      context.commit("updateOrganizationDetails", response);
    });
  },
  async updateCustomerDetails(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/updateCustomerDetails`
    );
  },
  async loadAdminStudentList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/studentList`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadAdminStudentList", response);
    });
  },
  async getClassList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/batchList`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("getClassList", response);
    });
  },
  async getTutorList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/tutorList`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("getTutorList", response);
    });
  },
  async getPackageList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/packageList`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("getPackageList", response);
    });
  },
  async addStudent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/addStudent`
    ).then((response) => {
      if (response.success == true) {
        context.commit("addStudent", response.student);
      } else {
        console.log(response.message);
      }
      return response;
    });
  },
  async updateStudent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/updateStudent`
    ).then((response) => {
      if (response.success == true) {
        context.commit("updateStudent", response.student);
      } else {
        console.log(response.message);
      }
      return response;
    });
  },
  async updateStudentAssignment(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/assignStudents`
    );
  },
  deleteStudent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/deleteStudent`
    ).then((response) => {
      if (response.success == true) {
        context.commit("deleteStudent", reqData);
      }
      return response;
    });
  },
  async loadSearchedAdminStudentList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/studentSearch`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadSearchedAdminStudentList", response);
    });
  },
  async loadSearchedAdminClassList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/batchSearch`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadSearchedClassList", response);
    });
  },
  async loadSearchedAdminTutorList(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/tutorSearch`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadSearchedTutorList", response);
    });
  },

  //tutor
  async addTutor(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/addTutor`
    ).then((response) => {
      context.commit("addTutor", response);
      return response;
    });
  },
  async updateTutor(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/editTutor`
    ).then((response) => {
      context.commit("updateTutor", response);
      return response;
    });
  },
  async deleteTutor(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/admindashboard/deleteTutor",
      true
    ).then((response) => {
      return response;
    });
  },

  //class
  async addClass(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/addBatch`
    ).then((response) => {
      context.commit("addClass", response);
      return response;
    });
  },
  async updateClass(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/admindashboard/updateBatch`
    ).then((response) => {
      context.commit("updateClass", response);
      return response;
    });
  },
  async deleteClass(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/admindashboard/deleteBatch",
      true
    ).then((response) => {
      return response;
    });
  },

  //White Labeling
  async loadCourseDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/onboarding/getCourseDetails",
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadCourseDetails", response);
    });
  },

  // ai
  async loadTopicsList(context, reqData) {
    return securePostAPI({ ...reqData }, "mentoapp/ai/getTopics", true).then(
      (response) => {
        if (!response) {
          response = {};
        }
        context.commit("loadTopicsList", response);
      }
    );
  },
  async getCollectionsList(context, reqData) {
    return secureGetAPI({ ...reqData }, "mentoapp/ai/collections", true).then(
      (response) => {
        if (!response) {
          response = {};
        }
        context.commit("getCollectionsList", response);
        return response;
      }
    );
  },
  async saveInCollection(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/ai/createCollection",
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("addCollection", response);
      return response;
    });
  },
  async editCollection(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/ai/editCollection",
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },
  async deleteCollection(context, reqData) {
    return securePostAPI(
      { ...reqData },
      "mentoapp/ai/deleteCollection",
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },
  async getCollectionQuestionsList(context, reqData) {
    return secureGetAPI(
      {},
      `mentoapp/ai/collections/${reqData.collectionId}/questions`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
      // context.commit("getCollectionsList", response);
    });
  },
  async getWidgetsList(context, reqData) {
    let environment = "";
    if ("config" in context.state && "environment" in context.state.config) {
      environment = context.state.config.environment;
    }

    return secureGetAPI(
      {},
      `mentoapp/aihub/getWidgets?environment=${environment}`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("getWidgetsList", response);
      return response;
    });
  },
  async getTokens(context, reqData) {
    if (!context.state.aiTokensDataLoadedFromServer) {
      return securePostAPI(
        { ...reqData },
        `mentoapp/aihub/getTokens`,
        false
      ).then((response) => {
        if (!response) {
          response = {};
        }
        context.commit("getTokens", response);
        context.commit("aiTokensDataLoadedFromServer", true);
        return response;
      });
    }
  },
  async likeAIQuestion(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/aihub/aiQFeedback`,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      // context.commit("", response);
      return response;
    });
  },
  async joinWaitlist(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/aihub/joinWaitlist`,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if (response && response.success == true) {
        context.commit("joinWaitlist", reqData.toolId);
      }
      return response;
    });
  },

  async startTeacherAssignment(context, reqData) {
    if (context.state.assignmentId.length > 0) {
      reqData.assignmentId = context.state.assignmentId;
    }
    return securePostAPI(
      reqData,
      `mentoapp/ai/startAssignment`,
      true,
      false
    ).then((response) => {
      console.log("ressss", response);

      if (!response) {
        response = {};
      }
      console.log("ressss", response);

      context.commit("updateAssignmentPracticeInfo", response);

      context.commit("getPracticeInfo", response);
    });
  },
  async shareCollectionLinkOpened(context, reqData) {
    console.log(
      context.state.shareCollectionLinkOpened,
      "context.state.shareCollectionLinkOpened"
    );
    return securePostAPI(
      { ...reqData, ...context.state.shareCollectionLinkOpened },
      `mentoapp/aihub/shareCollectionLinkOpened`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if (response && response.success == true) {
        context.commit("shareCollectionLinkOpened", response);
      }
      return response;
    });
  },
  async teacherAssignmentAccess(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/ai/linkStudentWithTutor`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      console.log("teacherAssignmentAccess", response);
      if (response.success == true) {
        context.commit("storeUserInfoIds", response);
        context.commit("shareCollectionLinkOpened", response);
      } else {
        context.commit(
          "updateLinkSnackbarError",
          response.message ?? " Please try again later"
        );
        context.commit("linkSnackbar", true);
      }
      return response;
    });
  },
  async enableTrialTeacherMode(context, reqData) {
    return secureGetAPI(
      { ...reqData },
      `mentoapp/teacherdashboard/convertToTeacher `,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },
  async saveTeacherNotes(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/scribble/storeScribbleInfo`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },
  async updateTeacherNotes(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/scribble/updateScribbleInfo`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },
  async getTeacherNotes(context, reqData) {
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mentoapp/scribble/getScribbleScribbleInfo`
    ).then((response) => {
      if (!response) {
        response = {};
      }
      return response;
    });
  },

  // notification
  async getNotificationsList(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/studenttest/userNotificationMessages`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      if (response.length > 0) {
        context.commit("getNotificationsList", response);
        context.commit("showNotification", true);
      }
    });
  },

  // parent details
  async saveParentDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/onboarding/updateMentoAppUser`,
      true
    );
  },

  // Download Test Report
  async getDownloadPdf(context, reqData) {
    return securePostAPIWithoutOverlay(
      { ...reqData },
      `mentoapp/generatePdf/downloadPdf`
    );
  },

  // parent dashboard
  async addChild(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      `mentoapp/parentdashboard/addChild`
    ).then((response) => {
      if (response.success == true) {
        context.commit("addChild", response.child);
      } else {
        console.log(response.message);
      }
      return response;
    });
  },
  async loadChildList(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/parentdashboard/childrenList`,
      true
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadChildList", response);
    });
  },

  async getFeatureFlagsList(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/onboarding/getCustomerFeatures`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("getFeatureFlagsList", response);
    });
  },

  // Resource Center folder
  async addResourceCenterFolder(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/addResourceCenterFolder"
    );
  },
  async updateResourceCenterFolder(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/updateResourceCenterFolder"
    );
  },
  async deleteResourceCenterFolder(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/deleteResourceCenterFolder"
    );
  },

  // Resource Center folder content
  async loadAllResourceCenterFolderContent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/getResourceCenterFoldersAndContents"
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadAllResourceCenterFolderContent", {
        parentNodeId: reqData.resourceCenterFolderId || "-1",
        children: response,
      });
    });
  },
  async addResourceCenterFolderContent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/addResourceCenterFolderContent"
    );
  },
  async updateResourceCenterFolderContent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/updateResourceCenterFolderContent"
    );
  },
  async deleteResourceCenterFolderContent(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/deleteResourceCenterFolderContent"
    );
  },
  async getFilePaths(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/getFilePaths"
    );
  },
  async getContentDetails(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/getContentDetails"
    );
  },
  async loadAllStudentResourceFoldersAndContents(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/getStudentResourceFoldersAndContents"
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("loadAllStudentResourceFoldersAndContents", {
        parentNodeId: reqData.resourceCenterFolderId || "-1",
        children: response,
      });
    });
  },
  async sendResourceToUser(context, reqData) {
    return securePostAPIWithCustomerId(
      { ...reqData },
      "mentoapp/resourcecenter/sendResourceToUser"
    );
  },

  //self help guide
  goToNextSelfHelpStep({ commit, getters }) {
    const nextStepId = getters.nextSelfHelpStepId;
    if (nextStepId) {
      commit("updateCurrentSelfHelpStepId", nextStepId);
    } else {
      console.log("No next step available.");
    }
  },
  goToPrevSelfHelpStep({ commit, getters }) {
    const prevStepId = getters.prevSelfHelpStepId;
    if (prevStepId) {
      commit("updateCurrentSelfHelpStepId", prevStepId);
    } else {
      console.log("No previous step available.");
    }
  },
  getUserPreferences(context, reqData) {
    return secureGetAPIWithCustomerId(
      { ...reqData },
      `mentoapp/userpreferences/getUserPreferences`
    ).then((response) => {
      if (!response) {
        response = [];
      }
      if ("turnOnSelfHelpGuide" in response) {
        context.commit("toggleSelfHelpGuide", response?.turnOnSelfHelpGuide);
      }
    });
  },
  setUserPreferences(context, reqData) {
    return securePostAPIWithCustomerIdWithoutOverlay(
      { ...reqData },
      `mentoapp/userpreferences/setUserPreferences`
    ).then((response) => {
      if (!response) {
        response = [];
      }
    });
  },
  async fetchSelfHelpGuideScript({ state, commit }) {
    let url = state.app_s3url;
    if (state.isUserAdmin && state.isUserTeacher) {
      url += state.config.selfHelpGuides.selfHelpGuideAdminAndTeacher;
    } else if (state.isUserAdmin) {
      url += state.config.selfHelpGuides.selfHelpGuideAdminOnly;
    } else if (state.isUserTeacher) {
      url += state.config.selfHelpGuides.selfHelpGuideTeacherOnly;
    } else if (!state.isUserParent) {
      url += state.config.selfHelpGuides.selfHelpGuideStudentOnly;
    } else {
      url = "";
    }
    try {
      const fetchResponse = await fetch(url);
      const res = await fetchResponse.json();
      commit("setSelfHelpGuideData", res); // Commit the result to the state
    } catch (err) {
      commit("setSelfHelpGuideData", {});
      console.error("Error - ", err);
    }
  },

  // Question Tools
  async addBookmark(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/questiontools/addBookmark`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("addBookmark", response);
      return response;
    });
  },
  async updateBookmark(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/questiontools/updateBookmark`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("updateBookmark", response);
      return response;
    });
  },
  async getQuestionBookmarkAndQueryDetails(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/questiontools/getQuestionBookmarkAndQueryDetails`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = {};
      }
      context.commit("loadQuestionBookmarkAndQueryDetails", response);
      return response;
    });
  },
  async getAllBookmark(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/questiontools/getAllBookmark`,
      true,
      false
    ).then((response) => {
      if (!response) {
        response = [];
      }
      context.commit("getAllBookmark", response);
      return response;
    });
  },
  async getQuestionAnswer(context, reqData) {
    return securePostAPI(
      { ...reqData },
      `mentoapp/questiontools/getQuestionAnswer`,
      true,
      false
    );
  },
};
