import store from "../store";
import _ from "lodash";

export function getTitleForWhiteLabel() {
  if(store.state.appBrowserTitle != null && store.state.appBrowserTitle.trim().length != 0 && !_.isEmpty(store.state.appBrowserTitle) ){
    return store.state.courseDetails.appBrowserTitle;
  } 
  else{
    return  null;
  }
}

export function hidePoweredByMentoMind(){
  return store.state.hidePoweredByMentoMind ?? false;
}

export function isWhiteLabelApp(){
  return !_.isEqual(window.location.origin, _.get(store.state.config, 'defaultAppUrl', ''));
}