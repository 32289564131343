<template>
    <div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { createFirebaseApp } from "@/services/firebaseMessagingService";
import { getDeviceId } from "@/services/authService";
import { v4 as uuidv4 } from "uuid";
import { teacherAssignment } from "@/services/signinService";
import { storeTestId, getTestId, clearTestId } from "@/services/authService";

export default {
    name: 'TeacherAssignment',

    async created() {
        let route = this.$router.currentRoute.value;
        console.log(route);
        let testId = route.params.testId || null
        if (testId == null) {
            this.$router.replace('/')
        }
        this.$store.commit('updateTeacherTestId', testId)
        if (this.userLoggedIn == 'true') {
            // start assignment
            let res = await teacherAssignment()
            if (res == null) {
                this.$router.replace('/')
            }
        }

        else {
            // store test id for after login
            await this.$store.dispatch("shareCollectionLinkOpened", { testId: testId })
            console.log("Store id", testId);
            this.$router.replace('/')
        }

    },
    data: () => ({

    }),

    computed: {
        ...mapState(["userState", "userLoggedIn"]),

    },

    watch: {

    },

    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>