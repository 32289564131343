<template>
  <v-chart class="chart" :option="option" :autoresize="true" />
</template>

<script>
import _ from "lodash";
import { useDisplay } from "vuetify";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide } from "vue";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
]);

// const option = ref();

export default {
  name: "LineBarChart",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay();
    provide(THEME_KEY, "dark");
    return { xs, smAndUp, mdAndUp };
  },
  props: {
    data: Object,
    title: String,
  },
  data: () => ({}),
  components: { VChart },
  async created() {},
  computed: {
    option: {
      get() {
        const colors = ["#A174FD", "#F27144"];
        return {
          color: colors,
          backgroundColor: "#26253a",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "line",
            },
          },
          xAxis: [
            {
              type: "category",
              name: "Questions",
              nameLocation: "middle",
              axisTick: {
                alignWithLabel: true,
              },
              nameTextStyle: {
                padding: [10, 0, 0, 0],
              },
              // prettier-ignore
              data: this.data.xAxisData,
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "Time(Seconds)",
              nameLocation: "middle",
              nameTextStyle: {
                padding: [0, 0, 15, 0],
              },
              axisLine: {
                show: true,
              },
              data: this.data.yAxisData,
            },
          ],
          dataZoom: [
            {
              type: "slider",
              xAxisIndex: 0, // Use a slider for scrolling
              start: this.data.xAxisData[0], // Start position (percentage)
              end: _.last(this.data.xAxisData), // End position (percentage),
              height: 15,
              bottom: 15
            },
          ],
          series: [
            {
              name: "You",
              type: "bar",
              data: this.data.barData,
              color: colors[0],
            },
            {
              name: "Other",
              type: "line",
              smooth: true,
              data: this.data.lineData,
              color: colors[1],
            },
          ],
        };
      },
    },
  },

  watch: {},

  methods: {},
};
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>
