import { Matrix_KEYBOARD_LAYER } from "./matrices.js";
import { SUMMATION_KEYBOARD_LAYER } from "./summation.js";
import { TRIGONOMETRY_KEYBOARD_LAYER } from "./trigonometry.js";
const ourKeyBoardLayers = {
  "matrix-layer": Matrix_KEYBOARD_LAYER["matrix-layer"],
  "summation-layer": SUMMATION_KEYBOARD_LAYER["summation-layer"],
  "trigonometry-layer": TRIGONOMETRY_KEYBOARD_LAYER["trigonometry-layer"]
};

const ourKeyboards = {
  "matrices-keyboard": {
    label: "<span><i>Matrices</i></span>", // Label displayed in the Virtual Keyboard Switcher
    tooltip: "MATRIX", // Tooltip when hovering over the label
    layer: "matrix-layer"
  },
  "summation-keyboard": {
    label: `<span><i><?xml version="1.0" encoding="UTF-8" standalone="no"?>
    <svg
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:ns1="http://sozi.baierouge.fr"
        xmlns:cc="http://web.resource.org/cc/"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        id="svg1"
        sodipodi:docname="sum.svg"
        viewBox="0 0 60 60"
        sodipodi:version="0.32"
        _SVGFile__filename="oldscale/actions/sum.svg"
        version="1.0"
        y="0"
        x="0"
        inkscape:version="0.40"
        sodipodi:docbase="/home/danny/work/flat/SVG/mono/scalable/actions"
      >
      <sodipodi:namedview
          id="base"
          bordercolor="#666666"
          inkscape:pageshadow="2"
          inkscape:window-y="0"
          pagecolor="#ffffff"
          inkscape:window-height="699"
          inkscape:zoom="2.8984657"
          inkscape:window-x="0"
          borderopacity="1.0"
          inkscape:current-layer="svg1"
          inkscape:cx="32.246296"
          inkscape:cy="49.521103"
          inkscape:window-width="1024"
          inkscape:pageopacity="0.0"
      />
      <path
          id="path1087"
          style="stroke-linejoin:round;stroke:#ffffff;stroke-linecap:round;stroke-width:12.5;fill:none"
          d="m53.124 11.543h-46.248l32.013 18.483-31.506 17.595h44.604"
      />
      <path
          id="path847"
          style="stroke-linejoin:round;stroke:#000000;stroke-linecap:round;stroke-width:7.5;fill:none"
          d="m53.124 11.543h-46.248l32.013 18.483-31.506 17.595h44.604"
      />
    </svg
    ></i></span>`, // Label displayed in the Virtual Keyboard Switcher
    tooltip: "Summation", // Tooltip when hovering over the label
    layer: "summation-layer"
  },
  "trigonometry-keyboard": {
    label: "Trigonometry", // Label displayed in the Virtual Keyboard Switcher
    tooltip: "trigonometry", // Tooltip when hovering over the label
    layer: "trigonometry-layer"
  }
};
export { ourKeyBoardLayers, ourKeyboards };
