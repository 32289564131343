const deployflavour='dev'
const deployApiUrl='https://api-app.devtest.thestudypod.com/'

export function getFlavour(){
    return deployflavour
}

export function getAPIURL(){
    return deployApiUrl
}
