<template>
  <MyCollectionDialogComponent v-if="openCollectionDialog" @closeCollectionDialog="closeCollectionDialog()"
    @showCollectionSnackBar="openCollectionSnackBar" @collectionMsg="updateCollectionSnackBarMsg"
    :openCollectionDialog="openCollectionDialog" :aiQuestionIds="aiQuestionIds" :toolId="toolId" />

  <EditQuestionComponent v-if="openEditQuestionDialog" @closeQuestionDialog="closeQuestionDialog"
    @saveQuestionResponse="saveQuestionResponse" :questionItem="questionItem"
    @showQuestionSnackbar="showQuestionSnackbar = true" @questionSnackbarMsg="updatequestionSnackbarMsg" />

  <!-- Overlay for Total Tokens-->
  <v-overlay style="backdrop-filter: blur(12.5px)" persistent v-model="limitReachedDialog"
    class="align-center justify-center px-2 mx-auto">
    <v-card elevation="0" class="decision-box d-flex justify-center align-center pa-2 flex-column"
      :width="smAndUp ? 350 : '95%'" height="100%">
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col class="decision-box-img-col" cols="12">
            <v-img class="mx-auto" aspect-ratio="1" width="70" src="@/assets/token.png"></v-img>
          </v-col>
          <v-col cols="12" class="d-flex justify-end align-center mb-0 pb-0">
            <v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-0" density="compact" variant="text"
              color="primaryTextColor" @click="limitReachedDialog = false">
            </v-btn>
          </v-col>
          <v-col cols="12" color="primaryTextColor" class="decision-box-title text-center pb-0">
            Daily Limit Reached
          </v-col>
          <v-col cols="12" class="decision-box-subtitle text-center">
            {{ limitReachedDialogMsg }}
          </v-col>
          <v-col cols="12">
            <v-progress-linear height="10" bg-color="grey" model-value="100%" rounded color="red"></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-overlay>

  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="aiErrorDialog" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white" max-width="400px">
      <v-card-text class="text-white">
        <v-row dense class="t-1remReg mb-1 mx-1" justify="center">
          <v-col class="text-center"> Mento AI Hub Error </v-col>
          <v-col class="text-right" cols="auto">
            <v-icon @click.stop="aiErrorDialog = false" size="20">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="t-subtitle text-center mx-2 mb-3 mx-sm-auto" justify="center">
          {{ limitReachedDialogMsg }}
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="showLeaveToCollection" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white" max-width="400px">
      <v-card-text class="text-white">
        <v-row dense class="t-1remReg mb-1 mx-2" justify="center">
          <v-col class="text-center"> Are you sure you want to leave? </v-col>
          <v-col class="text-right" cols="auto">
            <v-icon @click.stop="showLeaveToCollection = false" size="20">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="t-subtitle text-center mx-4 mb-3 mx-sm-auto" justify="center">
          You may have some unsaved changes on this page.
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4 ma-0">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn px-4 t-btn text-none text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="closeShowLeaveToCollection()">Don't leave</v-btn>
        <v-btn class="save-btn px-4 t-btn text-none text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="getMyCollections()">Leave anyway</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <v-layout v-if="dataReady" class="mb-0 pb-0 layoutLarge d-flex flex-column">
    <!-- breadcrumb -->
    <v-row class="ma-0 pt-4 px-4 breadcrumb-row" align="center" justify="space-between"
      :class="{ heightBread: $vuetify.display.mdAndUp }">
      <v-col class="ma-0 pa-0">
        <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template v-slot:prepend>
            <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
              @click="back()" icon="mdi-chevron-left">
            </v-btn>
          </template>
          <template v-slot:title="{ item }">
            <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{
              item.title
            }}</span>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="auto">
        <MentoAITokensComponent />
        <v-btn v-if="smAndUp" append-icon="mdi-bookmark-outline"
          class="t-btn text-white activity-btn text-none px-3 pr-5 ml-6" density="default"
          @click="openShowLeaveToCollection()">
          <template v-slot:append>
            <v-icon color="primaryTextColor"></v-icon>
          </template>
          My collections
        </v-btn>

        <v-btn v-if="!smAndUp" size="small" @click="openShowLeaveToCollection()" class="activity-btn text-white ml-6"
          icon="mdi-bookmark-outline"></v-btn>
      </v-col>
      <v-divider></v-divider>
    </v-row>

    <v-container fluid class="py-5 ma-0 pa-0 main-body focusable-div" v-scroll.self="onScroll" @scroll="handleScroll2"
      @wheel.passive="handleScroll" v-touch="{ down: () => handleSwipe() }" @keydown.up="handleKeyDown" tabIndex="0"
      :class="{ 'main-body-scroll': smAndUp }">
      <v-container fluid class="container-width py-0">
        <v-row align="center" justify="center" dense>
          <v-col cols="12">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pb-0 px-0">
                <span class="t-h125 text-center mb-1 text-primaryTextColor">Get Questions From PDF</span>
              </v-col>
              <v-col cols="12" class="px-0">
                <span class="t-1remReg text-center mb-1 test-subtitle">Upload, scan, and save your PDFs to a
                  collection.</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" dense>
          <v-col cols="">
            <v-theme-provider theme="textfieldtheme">
              <v-form ref="form">
                <v-card class="assignment-card px-6 py-4 assignment-card fill-height card-border ">
                  <v-card-text class="text-white">
                    <v-row>
                      <v-col cols="12" sm="auto" class="mr-sm-3 pl-0">
                        <v-row dense>
                          <v-avatar size="40" color="transparent">
                            <v-img height="40" :src="require('@/assets/epq.svg')">
                            </v-img>
                          </v-avatar>
                        </v-row>
                      </v-col>

                      <v-col cols>
                        <v-row class="t-1remReg text-white"> Upload a PDF</v-row>
                        <v-row class="test-subtitle">
                          PDF should contain questions
                        </v-row>
                        <v-row class="">
                          <v-col cols="10" class="mr-n3">
                            <v-file-input class="" density="compact" :clearable="true" ref="imgInput" v-model="pdfFiles"
                              accept=".pdf" variant="outlined" :rules=fileRules prepend-icon=""
                              prepend-inner-icon="mdi-file-pdf-box" :disabled="loadingQuestions"></v-file-input>
                          </v-col>

                        </v-row>
                      </v-col>
                    </v-row>




                    <v-row class="mt-0 pt-0">
                      <v-col cols="12" sm="auto" class="mr-sm-3 pl-0">
                        <v-row dense>
                          <v-avatar size="40" color="transparent">
                            <v-img height="40" :src="require('@/assets/epq.svg')">
                            </v-img>
                          </v-avatar>
                        </v-row>
                      </v-col>

                      <v-col cols>
                        <v-row class="t-1remReg text-white">
                          <v-col cols="3" class="ml-0 pl-0">
                            PDF Pages
                          </v-col>


                          <v-col class="ma-0 pa-0  mt-1" cols="8">
                            <v-radio-group hide-details :rules="requiredRule" color="primaryPurple" inline
                              v-model="selectedPageWay" class="t-h6Reg ml-n2 " :disabled="loadingQuestions">
                              <v-radio hide-details v-for="item, index in pageWays" class="t-h6Reg mr-2 testOption"
                                :label="item.title" :value="item.id" :key=index></v-radio>

                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row class="text-white  mt-5" v-if="selectedPageWay == 'custom'">
                          <v-col cols="10" class="ma-0 pa-0">
                            <v-text-field label="PDF Pages" variant="outlined" v-model="pdfPages" :rules="requiredRule"
                              density="compact" placeholder="1-2, 4-5" hide-details :disabled="loadingQuestions">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                    </v-row>





                    <v-row class="mt-6">
                      <v-col cols="12" sm="auto" class="mr-sm-3 pl-0">
                        <v-row dense>
                          <v-avatar size="40" color="transparent">
                            <v-img height="40" :src="require('@/assets/epq.svg')">
                            </v-img>
                          </v-avatar>
                        </v-row>
                      </v-col>

                      <v-col cols>
                        <v-row class="t-1remReg text-white">
                          <v-col cols="3" class="ml-0 pl-0">
                            Question Collections
                          </v-col>


                          <v-col class="ma-0 pa-0  mt-1" cols="8">
                            <v-radio-group hide-details :rules="requiredRule" color="primaryPurple" inline
                              v-model="selectedCollectionWay" class="t-h6Reg  ml-n2" :disabled="loadingQuestions">
                              <v-radio hide-details v-for="item, index in collectionWays"
                                class="t-h6Reg mr-2 testOption" :label="item.title" :value="item.id"
                                :key=index></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <v-row class="text-white  mt-5">
                          <v-col cols="10" class="ma-0 pa-0 ">
                            <v-autocomplete v-if="selectedCollectionWay == 'existing'" :items="collectionsList"
                              item-title="collectionName" item-value="collectionId" v-model="selectedCollectionId"
                              label="Select Collection *" density="compact" variant="outlined" hide-details dense
                              :rules=requiredRule :disabled="loadingQuestions">
                            </v-autocomplete>
                            <v-text-field v-if="selectedCollectionWay == 'new'" label="Collection Name *"
                              variant="outlined" v-model="newCollectionName" :rules="requiredRule" density="compact"
                              hide-details :disabled="loadingQuestions">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                    </v-row>



                    <v-row class="text-white mt-8" v-if="showPdfError">
                      <v-col cols="10" class="pt-0">
                        <v-alert density="compact" v-model="showPdfError" border="start" color="error" class="t-h6Reg"
                          variant="tonal" closable>
                          {{ pdfError }}
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row v-if="showGenerate" class="text-white mt-8">
                      <v-col cols="10" class="text-right py-0 pr-0 mr-0">
                        <v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
                          width="150" @click="getQuestionsFromPDF" :loading="loadingQuestions">
                          Generate
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-else class="text-white">
                      <v-col :class="{ 'pl-12': smAndUp }" cols="12" class="text-right py-0">
                        <v-btn class="t-btn text-white activity-btn text-none px-3" density="default" variant="flat"
                          width="150" @click="clearResponse" :loading="loadingQuestions"
                          :disabled="!enableClearResponseButton">
                          Reset response
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-form>
            </v-theme-provider>
          </v-col>


          <v-col cols="12">
            <v-expansion-panels @update:modelValue="panelsUpdate" varaint="accordion" multiple v-model="panels">
              <template v-for="(msg, index) in paginatedMessagesList" :key="index">
                <v-expansion-panel color="reportBgColor" bg-color="#00000000" ripple
                  class="panel-style panel-shadow mt-2" style="border-radius: 12px !important" elevation="0"
                  v-if="msg.type == 'question'" :id="msg.questionItem.aiQuestionId">
                  <template v-slot:title>
                    <v-row align="center">
                      <!-- <v-col v-if="showActions == true" cols="auto" class="pa-0">
                        <v-checkbox @click.stop="undefined" v-model="selectedQuestions" hide-details class="pa-0"
                          :value="msg.questionItem.aiQuestionId"></v-checkbox>
                      </v-col> -->

                      <v-col align-self="start" cols="auto">
                        <v-avatar size="40">
                          <v-img src="@/assets/mentochat.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="t-h6Reg ml-0 pl-0" cols="auto">

                        Question
                        <span v-if="('sourceQuestionId' in msg.questionItem) && msg.questionItem.sourceQuestionId != null">
                          {{ msg.questionItem.sourceQuestionId ?? '' }}
                        </span>
                        <span v-if="('questionNumber' in msg.questionItem) && msg.questionItem.questionNumber != null">
                          {{ msg.questionItem.questionNumber ?? '' }}
                        </span>

                        ( <v-icon>mdi-file-pdf-box</v-icon> Page No.
                        {{ msg.questionItem.pageNo }})
                      </v-col>
                      <v-col class="pl-0 ml-0" v-if="msg.questionItem.reviewRequired == true" cols="auto">
                        <v-tooltip location="top">
                          <template #activator="{ props }">
                            <span class="mx-0" v-bind="props">
                              <span class="mr-n1  text-error">(</span>
                              <v-icon color="error">mdi-exclamation</v-icon>
                              <span class="ml-n1 text-error">Review Needed</span>
                              <span class="text-error">)</span>
                            </span>

                          </template>
                          <span>Please review this question and add any required images,figures, etc.</span>
                        </v-tooltip>

                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col class="text-right" cols="auto">
                        <v-tooltip location="top">
                          <template #activator="{ props }">
                            <span class="mx-0" v-bind="props">
                              <v-icon @click.stop="addToCollection(msg.questionItem)">mdi-bookmark-outline</v-icon>
                            </span>
                          </template>
                          <span>Add to collection</span>
                        </v-tooltip>
                      </v-col>
                      <v-col class="text-right mr-3" cols="auto">
                        <v-tooltip location="top">
                          <template #activator="{ props }">
                            <span class="mx-2" v-bind="props">
                              <v-icon @click.stop="editQuestion(msg.questionItem)">mdi-pencil-outline</v-icon>
                            </span>
                          </template>
                          <span>Edit Question</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:text>
                    <QuestionComponent :question="msg.questionItem"></QuestionComponent>
                    <v-row class="ma-0 mt-2">
                      <v-col cols="auto" class="pr-2">
                        <v-tooltip location="bottom">
                          <template #activator="{ props }">
                            <v-icon @click.stop="
                              likeQuestion(msg.questionItem.aiQuestionId, true)
                              " color="primaryGrey" v-bind="props" small>
                              {{
                                "isLiked" in msg && msg.isLiked == true
                                  ? "mdi-thumb-up"
                                  : "mdi-thumb-up-outline"
                              }}
                            </v-icon>
                          </template>
                          <span>Good Response</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="auto" class="pl-2">
                        <v-tooltip location="bottom">
                          <template #activator="{ props }">
                            <v-icon @click.stop="
                              likeQuestion(msg.questionItem.aiQuestionId, false)
                              " color="primaryGrey" v-bind="props" small>
                              {{
                                "isLiked" in msg && msg.isLiked == false
                                  ? "mdi-thumb-down"
                                  : "mdi-thumb-down-outline"
                              }}
                            </v-icon>
                          </template>
                          <span>Bad Response</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel>

                <v-expansion-panel elevation="0" color="reportBgColor" bg-color="#00000000" class="panel-style mt-2"
                  v-if="msg.type == 'user'" style="border-radius: 12px !important" readonly hide-actions :id="msg.id">
                  <template v-slot:title>
                    <v-row align="center" class="">
                      <v-col class="t-h6Reg text-lh mr-0 pr-0 text-right">
                        <div v-html="getMarkDownHtml(msg.content)">

                        </div>
                      </v-col>

                      <v-col align-self="start" cols="auto">
                        <v-avatar size="40" color="primaryGreen ">
                          {{ getInitials(userName) }}
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel>

                <v-expansion-panel elevation="0" color="reportBgColor" bg-color="#00000000" class="panel-style mt-2"
                  v-if="msg.type == 'error' || msg.type == 'aiConversation'" style="border-radius: 12px !important"
                  readonly hide-actions>
                  <template v-slot:title>
                    <v-row align="center">
                      <v-col align-self="start" cols="auto">
                        <v-avatar size="40">
                          <v-img src="@/assets/mentochat.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="t-h6Reg text-lh ml-0 pl-0">
                        <div v-html="getMarkDownHtml(msg.rawResponse)"></div>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>

            <section class="pb-4" v-if="streaming">
              <v-expansion-panels varaint="accordion" multiple v-model="showStreamingPanel">
                <v-expansion-panel elevation="0" color="reportBgColor" bg-color="#00000000" ripple
                  class="panel-style mt-2" style="border-radius: 12px !important">
                  <template v-slot:title>
                    <v-row align="center">
                      <v-col align-self="start" cols="auto">
                        <v-avatar size="40">
                          <v-img src="@/assets/mentochat.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="t-1remReg ml-0 pl-0"> Mento AI </v-col>
                    </v-row>
                  </template>
                  <template v-slot:text>
                    <v-col class="htmlClassTest">
                      <span v-html="getMarkDownHtml(streamingContent)"> </span>
                      <span>
                        <v-icon class="ic" size="17" color="primaryPurple">mdi-circle</v-icon>
                      </span>
                    </v-col>
                  </template>
                </v-expansion-panel>
              </v-expansion-panels>
            </section>

          </v-col>

          <v-col cols="12">
            <v-row v-if="totalPages > 1" class="ma-0" justify="center">
              <v-col cols="auto" class="pa-0">
                <v-pagination v-model="currentPageNumber" :length="totalPages" total-visible="5" size="small"
                  color="white" rounded="lg"></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-layout>

  <v-snackbar v-model="showCollectionSnackBar" :timeout="-1" color="cardBackgroundColor" location="bottom" vertical>
    <v-row justify="space-between" align="center" class="ma-0">
      <v-col class="pa-0">
        <p class="text-primaryTextColor t-1remReg">{{ collectionMsg.text }}</p>
      </v-col>
      <v-col class="pa-0" cols="auto">
        <v-btn density="compact" color="#8e8ea7" @click.stop="closeCollectionSnackBar()" icon="mdi-close"
          variant="text"></v-btn>
      </v-col>
    </v-row>
    <v-row v-if="collectionMsg.subText != ''" class="ma-0 mt-2">
      <v-col cols="12" class="pa-0">
        <p class="text-primaryTextColor">
          <span class="text-easyColor text-clickable" @click="openShowLeaveToCollection()">
            Go to collections</span>{{ collectionMsg.subText }}
        </p>
      </v-col>
    </v-row>
  </v-snackbar>

  <v-snackbar v-model="showQuestionSnackbar" :timeout="-1" color="cardBackgroundColor" location="bottom">
    <span class="text-easyColor">{{ questionSnackbarMsg }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="showQuestionSnackbar = false" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import MyCollectionDialogComponent from "@/components/ai/MyCollectionDialogComponent.vue";
import EditQuestionComponent from "../teacher/EditQuestionComponent.vue";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import _ from "lodash";
import {
  getLoggedInUserCurrentDomain,
  getLoggedInUserId,
  getLoggedInUserOrganization,
} from "@/services/authService";
import {
  LambdaClient,
  InvokeCommand,
  InvokeWithResponseStreamCommand,
} from "@aws-sdk/client-lambda"; // ES Modules import
import { Auth } from "aws-amplify";
import QuestionComponent from "./QuestionComponent.vue";
import $ from "jquery";

import { breadcrumbHandler } from "@/services/breadcrumbHandler";
import { v4 as uuidv4 } from "uuid";
import { getHeaders, getLoggedInUserName } from "@/services/authService";
import MentoAITokensComponent from "@/components/ai/MentoAITokensComponent.vue";
import { uploadFiles } from "@/services/storageService";
import axios from "axios";
import * as pdfjsLib from 'pdfvuer/node_modules/pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: "PDFQuestions",
  data: () => ({
    pdfFiles: [],
    showPdfError: false,
    pdfError: null,
    newCollectionName: "",
    selectedPageWay: 'all',
    currenPdfPath: null,
    currentPageNumber: 1,
    itemsPerPage: 10,
    pdfPages: null,
    numberRules: [
      (v) => !!v || Number(v) == 0 || "Number is required",
      (v) => Number(v) <= 10 || "Number must be less than 10",
      (v) => Number(v) > 0 || "Number must be greater than or equal to 0",
    ],
    valueIs: "",
    collectionWays: [
      {
        title: "Create new collection",
        id: "new",
      },
      {
        title: "Add to existing collection",
        id: "existing",
      }
    ],
    pageWays: [
      {
        title: "All Pages",
        id: "all",
      },
      {
        title: "Custom",
        id: "custom",
      }
    ],
    value2Is: 0,
    requiredRule: [(v) => !!v || "Field is required"],
    fileRules: [
      value => value.length > 0 || 'At least one file is required.',
      value => value.every(file => file.type === 'application/pdf') || 'Only PDF files are allowed.',
      value => value.every(file => file.size < 20000000) || 'PDF size should be less than 2 MB!',
    ],
    messages: [],
    selectedTopic: undefined,
    message: "",
    panels: [],
    loadingQuestions: false,
    aiQuestions: [],
    streaming: false,
    streamingContent: "",
    showStreamingPanel: [],
    qDialog: false,
    aiQuestionsInput: null,
    toolId: "pdfQuestions",
    editedItem: {
      questionPartitionKey: "",
      aiQuestionId: "",
      questionDesc: "",
      questionTypeId: "",
      a: "",
      b: "",
      c: "",
      d: "",
      answerSingleChoice: null,
      answerDecimal: null,
      difficultyLevel: "",
      domainId: "",
      insertedBy: "",
      insertedTimeStamp: 0,
      categoryId: "",
      solutionText: "",
      subjectId: "",
      topicId: "",
    },
    defaultItem: {
      questionPartitionKey: "",
      aiQuestionId: "",
      questionDesc: "",
      questionTypeId: "",
      a: "",
      b: "",
      c: "",
      d: "",
      answerSingleChoice: null,
      answerDecimal: null,
      difficultyLevel: "",
      domainId: "",
      insertedBy: "",
      insertedTimeStamp: 0,
      categoryId: "",
      solutionText: "",
      subjectId: "",
      topicId: "",
    },
    openCollectionDialog: false,
    aiQuestionIds: [],
    limitReachedDialog: false,
    initialPrompt: "",
    aiErrorDialog: false,
    limitReachedDialogMsg: "",
    showCollectionSnackBar: false,
    collectionMsg: "",
    showQuestionCountError: false,
    questionCountError: "",
    autoScrolling: true,
    difficultyList: [
      {
        difficultyTitle: "Easy",
        difficultyValue: "easy",
      },
      {
        difficultyTitle: "Medium",
        difficultyValue: "intermediate",
      },
      {
        difficultyTitle: "Hard",
        difficultyValue: "hard",
      },
    ],
    selectedQuestions: [],
    showGenerate: true,
    questionItem: {},
    openEditQuestionDialog: false,
    dataReady: false,
    userScroll: false,
    countNoOfQuestions: undefined,
    selectedGenre: undefined,
    enableClearResponseButton: false,
    showLeaveToCollection: false,
    prevScrollTop: 0,
    showQuestionSnackbar: false,
    questionSnackbarMsg: "",
    selectedCollectionId: null,
    selectedCollectionWay: 'new'
  }),
  setup() {
    const {
      xs,
      smAndUp,
      smAndDown,
      mdAndDown,
      mdAndUp,
      lgAndUp,
      lg,
      xl,
      xxl,
    } = useDisplay();
    return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
  },
  async mounted() {
    console.log("mounted");
    if (!this.isUserTeacher) {
      if (this.isUserAdmin) {
        this.$router.replace("/admin/1");
      } else {
        this.$router.replace("/learn");
      }
    } else {
      this.dataReady = true;
      breadcrumbHandler(this);
    }
  },
  async created() {
    await this.$store.dispatch('getCollectionsList', {});
    this.$store.dispatch("loadEntitiesWithoutLoader");
  },
  components: {
    QuestionComponent,
    EditQuestionComponent,
    MentoAITokensComponent,
    MyCollectionDialogComponent
  },

  computed: {
    ...mapState([
      "newLearnNodes",
      "breadCrumbs",
      "topicsList",
      "config",
      "maxAITokens",
      "totalAITokens",
      "aiPromptsList",
      "isUserTeacher",
      "isUserAdmin",
      "passageTypes",
      "genres",
      "app_aiApiUrl"
    ]),
    totalPages: {
      get() {
        return Math.ceil(this.messages.length / this.itemsPerPage);
      }
    },
    paginatedMessagesList: {
      get() {
        return this.paginatedData(this.messages);
      }
    },
    collectionsList: {
      get() {
        let list = this.$store.state.collectionsList;
        list = _.orderBy(list, [collection => collection?.collectionName.toLowerCase()], "asc");
        return list;
      }
    },
    collectionName: {
      get() {
        let collection = "";
        collection = _.find(this.collectionsList, (collection) => collection.collectionId == this.collectionId);
        let name = "";
        name = collection.collectionName;
        return name;
      }
    },
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = this.breadCrumbs;
        // breadCrumbs.pop();
        breadCrumbs[breadCrumbs.length - 1].disabled = true;
        return breadCrumbs;
      },
    },
    userName: {
      get() {
        return this.$store.state.userState.name;
      },
    },
    noOfQuestions: {
      get() {
        let noOfQs = 0;
        for (let msg of this.messages) {
          if (msg.type == "question") {
            noOfQs++;
          }
        }
        return noOfQs;
      },
    },
  },
  methods: {
    panelsUpdate(val) {
      console.log("panel update", val);
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },
    paginatedData(list) {
      const start = (this.currentPageNumber - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return list.slice(start, end);
    },
    async getQuestionsFromPDF() {
      recordAnalyticsEvent(AnalyticsEvents.v2AIPDFQuestionsExtract, {
        toolId: this.toolId,
        "selectedPageWay": this.selectedPageWay,
        collectionName: this.newCollectionName
      });
      this.showPdfError = false
      this.pdfError = null
      this.messages = [];

      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const typedArray = new Uint8Array(e.target.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        console.log("number of pages", pdf.numPages);

        if (this.selectedPageWay == 'all' && pdf.numPages > 7) {
          this.showPdfError = true
          this.pdfError = 'Upto 7 pages are allowed at a time'
          return
        }

        if (this.selectedPageWay == 'custom' && this.parsePageRangesToPages(this.pdfPages).length > 7) {
          this.showPdfError = true
          this.pdfError = 'Upto 7 pages are allowed at a time'
          return
        }
        this.loadingQuestions = true

        await this.uploadToS3()


        if (this.selectedCollectionWay == 'new') {
          let x = {
            collectionId: "",
            collectionName: this.newCollectionName,
            toolId: this.toolId,
            aiQuestionIds: [],
            createMetaData: {},
            username: getLoggedInUserName(),


          }

          let res = await this.$store.dispatch('saveInCollection', x)
          this.selectedCollectionId = res.collectionId
          this.selectedCollectionWay = 'existing'

        }

        let aiQuestionsInput = {
          toolId: this.toolId,
          domainId: getLoggedInUserCurrentDomain(),
          sessionId: uuidv4(),
          userId: getLoggedInUserId(),
          organizationId: getLoggedInUserOrganization(),
          username: getLoggedInUserName(),
          pdfPath: this.currenPdfPath,
          collectionId: this.selectedCollectionId,
          pdfPages: this.pdfPages,
          allPages: this.selectedPageWay == 'all'
        };

        recordAnalyticsEvent(AnalyticsEvents.v2AIPDFQuestionsExtract, {
          toolId: this.toolId,
          username: getLoggedInUserName(),
          pdfPath: this.currenPdfPath,
          collectionId: this.selectedCollectionId,
          pdfPages: this.pdfPages,
          selectedPageWay: this.selectedPageWay,
          allPages: this.selectedPageWay == 'all'
        });
        const response = await axios.post(
          `${this.app_aiApiUrl}/pdfquestions`,
          aiQuestionsInput,
          {
            responseType: "stream",
            adapter: "fetch",
          }
        );
        console.log(response);
        const decoder = new TextDecoder();
        const stream = response.data;

        for await (const chunk of stream) {
          const decodedChunk = decoder.decode(chunk, { stream: true });
          console.log(decodedChunk);
          const regex = /<chunk>(.*?)<\/chunk>/gs;
          const matches = decodedChunk.matchAll(regex);
          const matchArray = Array.from(matches);

          for (const match of matchArray) {
            let jsonData = JSON.parse(match[1]);
            if (
              "aiError" in jsonData &&
              jsonData.aiError != null &&
              jsonData.aiError.length > 0
            ) {
              if (jsonData.aiError == "token_limit_exceeded") {
                this.limitReachedDialogMsg =
                  jsonData.limitReachedDialogMsg ??
                  "Daily limit has reached. You can try tomorrow";
                this.limitReachedDialog = true;
              } else {
                this.limitReachedDialogMsg = jsonData.aiError;
                this.aiErrorDialog = true;
              }

              this.showStreamingPanel = [];
              this.streaming = false;

              // show dialog
              return;
            }
            let cl = this.messages.length;
            this.createMessages(JSON.parse(match[1]));
            setTimeout(() => {
              window.MathJax.typesetPromise();
            }, 0);
          }

        }
        this.loadingQuestions = false
        console.log(this.messages);
      }
      reader.readAsArrayBuffer(this.pdfFiles[0]);
    },
    parsePageRangesToPages(pageRanges) {
      // Split the string by commas to get individual page ranges
      const ranges = pageRanges.split(',');

      // Initialize an array to hold all pages
      const pages = [];

      // Loop through each range
      ranges.forEach(range => {
        const [start, end] = range.split('-').map(Number);

        // Add all pages within the range to the pages array
        for (let i = start; i <= end; i++) {
          pages.push(i);
        }
      });

      return pages;
    },
    async uploadToS3() {
      console.log("loader");
      if (this.pdfFiles.length > 0) {
        let storagePDf = await uploadFiles(this.pdfFiles[0], "questionsPdf");
        console.log(storagePDf);
        this.currenPdfPath = storagePDf.key
      }
    },
    genreUpdate(val) {
      this.initialPrompt = this.fillTemplate(this.selectedPattern.customPrompt, {
        genre: _.get(
          this.genres.find((e) => e.genreId == val),
          "title",
          ""
        ),
        passageType: _.get(
          this.passageTypes.find((e) => e.passageTypeId == this.selectedPassageType),
          "passageType",
          ""
        ),
      });

      this.$refs.initialMyTextarea.focus();
    },
    passageTypeUpdate(val) {
      this.initialPrompt = this.fillTemplate(this.selectedPattern.customPrompt, {
        genre: _.get(
          this.genres.find((e) => e.genreId == this.selectedGenre),
          "title",
          ""
        ),
        passageType: _.get(
          this.passageTypes.find((e) => e.passageTypeId == val),
          "passageType",
          ""
        ),
      });
      this.$refs.initialMyTextarea.focus();
    },
    promptUpdateByTyping(val) {
      this.selectedPattern = undefined;
      this.selectedGenre = undefined;
      this.selectedPassageType = undefined;
    },
    topicUpdate(val) {
      this.advancedOptions = false;
      this.selectedDifficulty = undefined;
    },
    advancedOptionsUpdate(val) {
      if (!val) {
        this.initialPrompt = "";
      }
    },
    async difficultyUpdate(val) {
      let reqData = {
        toolId: this.toolId,
        topicId: this.selectedTopic,
        difficulty: val,
      };
      // this.advancedOptions = false;
      this.$store.commit("resetAiPromptsList");
      await this.$store.dispatch("getAIPrompts", reqData);
      this.setDefaultGenrePassage();
      this.selectedPattern = undefined;
      this.initialPrompt = "";
    },
    setDefaultGenrePassage() {
      this.selectedGenre = this.genres[0].genreId;
      this.selectedPassageType = this.passageTypes[0].passageTypeId;
    },
    handleScroll(event) {
      // Your scroll handling code here
      if (event.deltaY < 0) {
        this.userScroll = true;
      }
    },
    // handleScroll2(event) {
    //   if (this.isScrollbarDragging(event)) {
    //     // Your code to handle scrollbar dragging
    //     const scrollTop = event.target.scrollTop;
    //     console.log(`Scrollbar dragged to: ${scrollTop}px`);
    //   }
    // },
    handleScroll2(event) {
      const currentScrollTop = event.target.scrollTop;

      if (currentScrollTop < this.prevScrollTop) {
        this.userScroll = true;
      }
      this.prevScrollTop = currentScrollTop;
    },
    isScrollbarDragging(event) {
      const target = event.target;
      return target.clientHeight + target.scrollTop >= target.scrollHeight;
    },
    handleSwipe() {
      this.userScroll = true;
    },
    onScroll(event) {
      let calcValue = Math.round(event.target.scrollTop + event.target.offsetHeight);
      if (
        Math.abs(calcValue - event.target.scrollHeight) <= 1 &&
        this.userScroll == true
      ) {
        this.userScroll = false;
      }
    },
    handleKeyDown(event) {
      this.userScroll = true;
    },
    getMyCollections() {
      recordAnalyticsEvent(AnalyticsEvents.v2AIMyCollectionsButtonClicked, {
        fromTool: true,
        toolId: this.toolId,
      });
      this.closeShowLeaveToCollection();
      this.$router.push(`${this.$route.fullPath}/mycollections`);
    },
    openShowLeaveToCollection() {
      if (this.messages.length == 0 && this.streamingContent == "") {
        this.getMyCollections();
        return;
      }
      this.showLeaveToCollection = true;
    },
    closeShowLeaveToCollection() {
      this.showLeaveToCollection = false;
    },
    sendDisabled() {
      return this.loadingQuestions || this.message.length == 0 || this.streaming;
    },
    saveQuestionResponse(questionItem) {
      recordAnalyticsEvent(AnalyticsEvents.v2AIEditedQuestionSaved, {
        toolId: this.toolId,
        aiQuestionId: questionItem.aiQuestionId,
      });
      var foundQ = this.messages.find(
        (x) =>
          x.type == "question" && x.questionItem.aiQuestionId == questionItem.aiQuestionId
      );
      Object.assign(foundQ.questionItem, questionItem);
    },
    closeQuestionDialog() {
      this.openEditQuestionDialog = false;
      this.questionItem = {};
    },
    async generate() {
      this.easy = 0;
      this.intermediate = 0;
      this.hard = 0;
      if (this.selectedDifficulty == "easy") {
        this.easy = this.countNoOfQuestions;
      } else if (this.selectedDifficulty == "intermediate") {
        this.intermediate = this.countNoOfQuestions;
      } else if (this.selectedDifficulty == "hard") {
        this.hard = this.countNoOfQuestions;
      }
      recordAnalyticsEvent(AnalyticsEvents.v2AIToolGenerateButtonClicked, {
        toolId: this.toolId,
        easy: this.easy,
        intermediate: this.intermediate,
        hard: this.hard,
        topicId: this.selectedTopic,
      });

      if (this.maxAITokens <= this.totalAITokens) {
        this.limitReachedDialogMsg = "Daily limit has reached. You can try tomorrow";
        this.showStreamingPanel = false;

        this.limitReachedDialog = true;

        return;
      }

      this.showQuestionCountError = false;
      this.questionCountError = "";
      // if (parseInt(this.easy) + parseInt(this.hard) + parseInt(this.intermediate) > 10) {
      //   this.questionCountError = "Maximum 10 questions are allowed."
      //   this.showQuestionCountError = true;
      //   return;
      // }
      // else if (parseInt(this.easy == '' ? '0' : this.easy) + parseInt(this.hard == '' ? '0' : this.hard) + parseInt(this.intermediate == '' ? '0' : this.intermediate) <= 0) {
      //   this.questionCountError = "Atleast 1 Question is required."
      //   this.showQuestionCountError = true;
      //   return;
      // }
      this.$store.commit("clearAIQuestions");
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.enableClearResponseButton = false;
      this.showGenerate = false;
      this.streamingContent = "";
      this.showStreamingPanel = [0];
      this.streaming = true;
      this.messages = [];
      let aiQuestionsInput = {
        easy: this.easy,
        hard: this.hard,
        intermediate: this.intermediate,
        topicId: this.selectedTopic,
        toolId: "englishPracticeQuestions",
        domainId: getLoggedInUserCurrentDomain(),
        sessionId: uuidv4(),
        userId: getLoggedInUserId(),
        organizationId: getLoggedInUserOrganization(),
        username: getLoggedInUserName(),
        customPrompt: this.initialPrompt,
      };

      this.aiQuestionsInput = aiQuestionsInput;

      this.questionCount =
        parseInt(aiQuestionsInput.easy) +
        parseInt(aiQuestionsInput.hard) +
        parseInt(aiQuestionsInput.intermediate);
      if (this.$store.state.aiQuestions.length > 0) {
        this.loadingQuestions = false;
        this.aiQuestions = this.$store.state.aiQuestions;
        return;
      }
      $(".main-body")
        .stop()
        .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 1000);

      this.invokeAILambda(aiQuestionsInput);

      let reqData = {
        toolId: this.toolId,
        topicId: this.selectedTopic,
        difficulty: this.selectedDifficulty,
      };
      // this.$store.dispatch("getAIPrompts", reqData);
    },
    breadCrumbRoute(item) {
      this.$router.replace(item.pageRoute);
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute;
      this.$router.replace(page);
    },
    editQuestion(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2AIEditQuestionDialogOpened, {
        toolId: this.toolId,
        aiQuestionId: item.aiQuestionId,
      });
      this.$store.dispatch("loadEntities");


      this.questionItem = Object.assign({}, item);
      this.openEditQuestionDialog = true;
    },
    difficultyColor(difficultyLevel) {
      if (difficultyLevel == 2) {
        return "easyColor";
      }
      if (difficultyLevel == 5) {
        return "intermediateColor";
      }
      if (difficultyLevel == 8) {
        return "hardColor";
      }
    },
    getMarkDownHtml(markdown) {
      let out = markdown;
      out = out.replaceAll("[", "").replaceAll("]", "");

      const regex = /\\/gm;
      const escapedString = markdown.replace(regex, "\\\\");

      out = marked.parseInline(escapedString, { renderer });

      out = out.replace(/\\\\+/g, "\\");
      out = out.replace(/\\n/g, "<br>");
      return out;
    },
    async sendMessage() {
      recordAnalyticsEvent(AnalyticsEvents.v2AIToolPromptMessageButtonClicked, {
        toolId: this.toolId,
      });
      if (this.maxAITokens <= this.totalAITokens) {
        this.limitReachedDialogMsg = "Daily limit has reached. You can try tomorrow";
        this.showStreamingPanel = false;

        this.limitReachedDialog = true;

        return;
      }
      this.enableClearResponseButton = false;
      this.streamingContent = "";
      this.showStreamingPanel = [0];
      this.streaming = true;
      let id = uuidv4();
      this.messages.push({ type: "user", content: this.message, id: id });

      if (this.autoScrolling) {
        $(".main-body")
          .stop()
          .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 1000);
      }

      let credentials = await Auth.currentCredentials();
      let aiQuestions = this.messages.filter((e) => e.type == "question");
      let data = {
        ...this.aiQuestionsInput,
        query: this.message,
        currentNumberOfQuestions: aiQuestions.length,
        selectedDifficulty: this.selectedDifficulty,
      };
      this.message = "";

      const client = new LambdaClient({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
        httpOptions: {
          connectTimeout: 900000,
          timeout: 900000,
        },
      });
      const input = {
        // InvocationRequest
        FunctionName: this.config.lambda.aiQuestionGeneratorQuery, // required
        InvocationType: "RequestResponse",
        Payload: JSON.stringify(data),
      };
      const command = new InvokeWithResponseStreamCommand(input);
      const response = await client.send(command);

      this.showStreamingPanel = [0];
      this.streaming = true;
      this.userScroll = false;
      for await (const event of response.EventStream) {
        // Each event will likely contain the 'PayloadChunk' property
        const chunk = event.PayloadChunk?.Payload;
        if (chunk) {
          const textChunk = new TextDecoder().decode(chunk);
          const regex = /<chunk>(.*?)<\/chunk>/gs;
          const matches = textChunk.matchAll(regex);
          const matchArray = Array.from(matches);
          if (matchArray.length == 0) {
            this.streamingContent += textChunk;

            if (!this.userScroll) {
              $(".main-body")
                .stop()
                .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 0);
            }
          } else {
            const jsonChunks = [];
            for (const match of matchArray) {
              let jsonData = JSON.parse(match[1]);
              if (
                "aiError" in jsonData &&
                jsonData.aiError != null &&
                jsonData.aiError.length > 0
              ) {
                if (jsonData.aiError == "token_limit_exceeded") {
                  this.limitReachedDialogMsg =
                    jsonData.limitReachedDialogMsg ??
                    "Daily limit has reached. You can try tomorrow";
                  this.limitReachedDialog = true;
                } else {
                  this.limitReachedDialogMsg = jsonData.aiError;
                  this.aiErrorDialog = true;
                }

                this.showStreamingPanel = [];
                this.streaming = false;

                // show dialog
                return;
              }
              jsonChunks.push(JSON.parse(match[1]));
              let cl = this.messages.length;
              this.createMessages(JSON.parse(match[1]));

              setTimeout(() => {
                $(".main-body").animate(
                  {
                    scrollTop:
                      $(`#${id}`).offset().top -
                      $(".main-body").offset().top +
                      $(".main-body").scrollTop(),
                  },
                  "smooth"
                );
              }, 200);
            }
          }
        }
      }

      this.enableClearResponseButton = true;
      // Here, getToken
      this.$store.commit("aiTokensDataLoadedFromServer", false);
      this.$store.dispatch("getTokens", { maxTokens: false });
      this.showStreamingPanel = [];
      this.streaming = false;
    },
    async invokeAILambda(data) {
      let credentials = await Auth.currentCredentials();

      const client = new LambdaClient({
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
        httpOptions: {
          connectTimeout: 900000,
          timeout: 900000,
        },
      });
      const input = {
        // InvocationRequest
        FunctionName: this.config.lambda.aiQuestionGenerator, // required
        InvocationType: "RequestResponse",
        Payload: JSON.stringify(data),
      };
      const command = new InvokeWithResponseStreamCommand(input);
      const response = await client.send(command);

      this.showStreamingPanel = [0];
      this.streaming = true;
      this.userScroll = false;
      for await (const event of response.EventStream) {
        // Each event will likely contain the 'PayloadChunk' property
        const chunk = event.PayloadChunk?.Payload;
        if (chunk) {
          const textChunk = new TextDecoder().decode(chunk);
          const regex = /<chunk>(.*?)<\/chunk>/gs;
          const matches = textChunk.matchAll(regex);
          const jsonChunks = [];
          const matchArray = Array.from(matches);
          if (matchArray.length == 0) {
            this.streamingContent += textChunk;

            if (!this.userScroll) {
              $(".main-body")
                .stop()
                .animate({ scrollTop: $(".main-body")[0].scrollHeight }, 0);
            }
          } else {
            for (const match of matchArray) {
              let jsonData = JSON.parse(match[1]);

              if (
                "aiError" in jsonData &&
                jsonData.aiError != null &&
                jsonData.aiError.length > 0
              ) {
                if (jsonData.aiError == "token_limit_exceeded") {
                  this.limitReachedDialogMsg =
                    jsonData.limitReachedDialogMsg ??
                    "Daily limit has reached. You can try tomorrow";
                  this.limitReachedDialog = true;
                } else {
                  this.limitReachedDialogMsg = jsonData.aiError;
                  this.aiErrorDialog = true;
                }

                this.showStreamingPanel = [];
                this.streaming = false;

                // show dialog
                return;
              }

              jsonChunks.push(JSON.parse(match[1]));
              let msg = this.createMessage(JSON.parse(match[1]));
              this.messages.push(msg);
              this.streamingContent = "";
              this.panels.push(this.messages.length - 1);
              // if (this.messages.length == 1) {
              let id = this.messages[this.messages.length - 1].questionItem.aiQuestionId;
              setTimeout(() => {
                $(".main-body").animate(
                  {
                    scrollTop:
                      $(`#${id}`).offset().top -
                      $(".main-body").offset().top +
                      $(".main-body").scrollTop(),
                  },
                  "smooth"
                );
              }, 200);

              // }
            }
          }

          if (jsonChunks.length > 0) {
            //
          } else {
            //
          }
        }
      }

      this.showStreamingPanel = [];
      this.streaming = false;
      this.enableClearResponseButton = true;
      this.$store.commit("aiTokensDataLoadedFromServer", false);
      this.$store.dispatch("getTokens", { maxTokens: false });
    },
    createMessage(msg) {
      let message = {};
      //type - error, question, user
      if (msg.type == "ai") {
        if (msg.error != null) {
          message.rawResponse = msg.rawResponse;
          message.type = "error";
        } else {
          message.questionItem = msg.question;
          message.type = "question";
        }
      }
      if (msg.type == "user") {
        message.content = msg.content;
        message.type = "user";
      }
      return message;
    },
    createMessages(msg) {
      let messages = [];
      let message = {};
      //type - error, question, user
      if (msg.type == "ai") {
        if (msg.error != null) {
          this.messages.push({
            rawResponse: msg.rawResponse,
            type: "error",
          });

          return;
        } else {
          if (msg.conversationStart != null) {
            this.messages.push({
              rawResponse: msg.conversationStart,
              type: "aiConversation",
            });
          }
          for (let q of msg.questions) {
            this.messages.push({
              questionItem: q,
              type: "question",
            });
            this.panels.push(this.messages.length - 1);
          }
          return;
        }
      }
    },
    addToCollection(questionItem = "") {
      if (questionItem != "") {
        this.aiQuestionIds = [];
        this.aiQuestionIds.push(questionItem.aiQuestionId);
        this.openCollectionDialog = true;
      } else {
        this.aiQuestionIds = this.selectedQuestions;
        this.openCollectionDialog = true;
      }
    },
    closeCollectionDialog() {
      this.aiQuestionIds = [];
      this.openCollectionDialog = false;
    },
    closeCollectionSnackBar() {
      this.collectionMsg = "";
      this.showCollectionSnackBar = false;
    },
    openCollectionSnackBar() {
      this.showCollectionSnackBar = true;
    },
    updateCollectionSnackBarMsg(data) {
      this.collectionMsg = data;
    },
    getInitials(fullName) {
      const allNames = fullName.trim().split(" ");
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, "");
      return initials[0];
    },
    openActions() {
      this.showActions = !this.showActions;
      this.selectedQuestions = [];
      if (this.showActions == true) {
        recordAnalyticsEvent(AnalyticsEvents.v2AIToolActionsOpened, {
          toolId: this.toolId,
          isOpened: true,
        });
        this.showActionsRow = true;
      } else {
        recordAnalyticsEvent(AnalyticsEvents.v2AIToolActionsOpened, {
          toolId: this.toolId,
          isOpened: false,
        });
        this.showActionsRow = false;
      }
    },
    closeActions() {
      this.showActionsRow = false;
      this.showActions = false;
    },
    toggleSelectedQuestions() {
      if (this.selectedQuestions.length == this.noOfQuestions) {
        this.selectedQuestions = [];
      } else {
        this.selectedQuestions = [];
        for (let msg of this.messages) {
          if (msg.type == "question") {
            this.selectedQuestions.push(msg.questionItem.aiQuestionId);
          }
        }
      }
    },
    toggleQuestionExpansion() {
      this.panels = [];
      this.showExpansionButton = !this.showExpansionButton;
      recordAnalyticsEvent(AnalyticsEvents.v2AIToolActionsButtonClicked, {
        toolId: this.toolId,
        buttonName: "expandAndCollapseQuestionButton",
      });
      if (this.showExpansionButton == true) {
        for (const [idx, msg] of this.messages.entries()) {
          if (msg.type == "question") {
            this.panels.push(idx);
          }
        }
      } else {
        this.panels = [];
      }
    },
    clicked() {
      return;
    },
    likeQuestion(aiQuestionId, isLiked) {
      recordAnalyticsEvent(AnalyticsEvents.v2AIQuestionLiked, {
        toolId: this.toolId,
        aiQuestionId: aiQuestionId,
        isLiked: isLiked,
      });
      var foundQ = this.messages.find(
        (x) => x.type == "question" && x.questionItem.aiQuestionId == aiQuestionId
      );
      if ("isLiked" in foundQ && foundQ.isLiked == isLiked) {
        isLiked = null;
      }
      let reqData = {
        liked: isLiked,
        aiQuestionId: aiQuestionId,
      };
      foundQ.isLiked = isLiked;
      this.$store.dispatch("likeAIQuestion", reqData).then((res) => {
        if (res.success == true) {
          return;
        } else {
          foundQ.isLiked = null;
        }
      });
    },
    addPromptToChatBox(msg) {
      this.selectedPattern = msg;
      if (!this.selectedGenre) {
        this.setDefaultGenrePassage();
      }

      if (!this.selectedPassageType) {
        this.setDefaultGenrePassage();
      }

      this.initialPrompt = this.fillTemplate(this.selectedPattern.customPrompt, {
        genre: _.get(
          this.genres.find((e) => e.genreId == this.selectedGenre),
          "title",
          ""
        ),
        passageType: _.get(
          this.passageTypes.find((e) => e.passageTypeId == this.selectedPassageType),
          "passageType",
          ""
        ),
      });
      this.$refs.initialMyTextarea.focus();
    },
    fillTemplate(template, values) {
      for (const placeholder in values) {
        template = template.replace(
          new RegExp(`{${placeholder}}`, "g"),
          values[placeholder]
        );
      }
      return template;
    },
    clearResponse() {
      this.easy = 0;
      this.intermediate = 0;
      this.hard = 0;
      this.selectedDifficulty = undefined;
      this.countNoOfQuestions = undefined;
      this.selectedPattern = undefined;
      this.selectedTopic = undefined;
      this.messages = [];
      this.showGenerate = true;
      this.message = "";
      this.initialPrompt = "";
      this.advancedOptions = false;
    },
    updatequestionSnackbarMsg(msg) {
      this.questionSnackbarMsg = msg;
    },
  },
  watch: {
    easy() {
      this.showQuestionCountError = false;
      this.questionCountError = "";
    },
    intermediate() {
      this.showQuestionCountError = false;
      this.questionCountError = "";
    },
    hard() {
      this.showQuestionCountError = false;
      this.questionCountError = "";
    },
  },
};
</script>

<style scoped>
.focusable-div {
  outline: none;
}

.text-clickable {
  cursor: pointer;
}

.breadcrumb-row {
  flex: 0 0 auto;
}

.container-width {
  max-width: 900px;
}

.htmlClassTest {
  font-size: 0.875rem !important;
  white-space: pre-line !important;
  color: black !important;
}

.ic {
  animation: grow 2s ease-in-out infinite alternate;
  font-size: 30px;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  /* Initial state */
  50% {
    transform: scale(1.2);
  }

  /* Slightly larger */
  100% {
    transform: scale(1);
  }

  /* Back to original */
}

.aiChatField .v-field__append-inner>.v-icon {
  opacity: 1;
}

.selected {
  border-color: rgb(var(--v-theme-primaryPurple));
}

.assignment-card {
  background-color: rgb(var(--v-theme-reportBgColor));
  border-radius: 10px;
  white-space: pre-line !important;
}

.footer-card {
  background-color: #00000000;
}

.click {
  cursor: pointer;
}

.disabledOpacity {}

.card-border:hover {}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  /* width: 100px; */
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.heightBread {
  height: 80px;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 12px;
}

.btn-radius {
  /* background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%); */
  border-radius: 12px;
}

.cardDisable {
  pointer-events: none;
  opacity: 0.8;
}

/* .layoutLarge {
  overflow-y: hidden !important; */
/* -ms-overflow-style: none; */
/* IE and Edge */
/* scrollbar-width: none; */
/* Firefox
} */
/* Here */
.layoutLarge {
  overflow-y: hidden !important;
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .layoutLarge {
    height: calc(100vh - 75px);
  }
}

/* .main-body::-webkit-scrollbar {
  display: none;
} */

.main-body-scroll::-webkit-scrollbar {
  /* display: none; */
  width: 12px;
  border-radius: 12px !important;
  height: 8px !important;
}

/* Track */
.main-body-scroll::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px !important;
  height: 8px !important;
}

/* Handle */
.main-body-scroll::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

.hide-overflow {
  max-height: 100vh;
  overflow: hidden !important;
}

.layout-main {
  height: calc(100vh - 200px) !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.main-lg {
  padding-top: 40px;
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.main-md {
  padding-top: 30px;
  padding-left: 10%;
  padding-right: 10%;
}

.text-field-icon {
  cursor: pointer;
}

.dround {
  border-radius: 10px !important;
}

.dialog-rounded .overlay-scrim {
  border-radius: 10px !important;
}

.report-btn {
  background: rgb(var(--v-theme-practice-prompts-background)) !important;
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  letter-spacing: -0.15px;
}

.textArea-col {
  background: white !important;
  border-radius: 12px !important;
}

.decision-box {
  border-radius: 12px;
  border: 3px solid rgb(var(--v-theme-practice-prompts-border));
  background: rgb(var(--v-theme-practice-prompts-background));
  margin: 0 auto;
  overflow: visible !important;
}

.decision-box-img-col {
  position: absolute;
  top: -45px;
}

.decision-box-title {
  color: rgb(var(--v-theme-primaryTextColor));
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 25px */
  letter-spacing: -0.25px;
}

.decision-box-subtitle {
  color: rgb(var(--v-theme-practice-decision-box-subtitle));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.decision-box-options {
  width: 221px;
  height: 142px;
  border-radius: 12px;
  background: rgb(var(--v-theme-practice-prompts-option-box));
}

.decision-box-options-row {
  min-width: 125px;
}

.decision-box-options-title {
  color: rgb(var(--v-theme-primaryTextColor));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.17px;
}
</style>

<style>
.panel-style {
  white-space: pre-line !important;
}

.panel-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}

.panel-style .v-expansion-panel-title__overlay {
  display: none !important;
}

.panel-style .v-expansion-panel-text {
  background-color: white !important;
  border-bottom-right-radius: inherit !important;
  border-bottom-left-radius: inherit !important;
}

/* .custom-panel>.v-expansion-panel-text {
    background-color: rgb(var(--v-theme-surface)) !important;
    color: black !important;
    border-bottom-right-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
}

.testOption .v-label {

    width: 100% !important;
    opacity: 1 !important;
}
} */
.testOption .v-label {
  width: 100% !important;
  opacity: 1 !important;
}

.text-lh {
  line-height: 1.5 !important;
}

.footer-color {
  /* background: linear-gradient(360deg, rgba(53, 53, 78, 1) 84%, rgba(53, 53, 78, 0) 100%) !important;  */
  background: #35354e !important;
  box-shadow: 0 -8px 7px #35354e !important;
  position: sticky;
  flex: 0 0 auto;
  bottom: 0;
  z-index: 200;
  overflow: hidden;
}

.footer-width {
  transition: height 2s ease;
}

.main-body {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto !important;
}

.actionBox-border {
  border-radius: 12px;
  border: 2px solid #55556a;
}

.aiChatField .v-input__control>.v-field--disabled {
  opacity: 0.8 !important;
  /* background: #aaaaaa !important;  */
  color: white !important;
}

.slide-fade-enter-active {
  transition: all 0.3s linear;
}

.slide-fade-leave-active {
  transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translatey(50px);
  opacity: 0;
}

.dialog-rounded .v-overlay__scrim {
  border-radius: 10px !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}
</style>
