<template>
	<v-row class="h-100 align-end mx-sm-4 mx-1">
		<div class="outer-div" :class="{ 'small-outer-div': showLarge == false }">
			<div class="inner-div" :class="{ 'small-inner-div': showLarge == false }"
				:style="{ left: scoreStartVal + '%', width: scoreWidth + '%' }">
				<div class="bubble" :class="{ 'small-bubble': showLarge == false }"
					:style="{ left: 'calc(50% - ' + bubbleArrowVal + 'px)' }">
					<p class="bubble-text" :class="{ 'small-bubble-text': showLarge == false }">{{ lowerScore }}-{{ upperScore }}
					</p>
				</div>
				<div class="inner-circle inner-circle-1" :class="{ 'small-inner-circle': showLarge == false }"></div>
				<div class="inner-circle inner-circle-2" :class="{ 'small-inner-circle': showLarge == false }"></div>
			</div>
		</div>
	</v-row>

	<v-row justify="space-between" align="center" class="pb-4 mx-sm-4 mx-1">
		<v-col cols="auto" class="px-0">
			<span class="legend-text">{{ this.rangeVal.lowerRange }}</span>
		</v-col>
		<v-col cols="auto" class="px-0">
			<span class="legend-text">{{ this.rangeVal.upperRange }}</span>
		</v-col>
	</v-row>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
	name: "TestBarComponent",
	props: {
		lowerScore: Number,
		upperScore: Number,
		isLarge: String,
	},
	setup() {
		const { xs, smAndDown, mdAndUp, lgAndUp, xlAndUp } = useDisplay();
		return { xs, smAndDown, mdAndUp, lgAndUp, xlAndUp };
	},
	computed: {
		showLarge: {
			get() {
				if (this.isLarge == 'false' || this.$vuetify.display.smAndDown) {
					return false;
				}
				else {
					return true;
				}
			}
		},
		scoreWidth: {
			get() {
				let x = this.upperScore - this.lowerScore;
				let y = (x / (this.rangeVal.upperRange - this.rangeVal.lowerRange)) * 100;
				let res = Math.round(y);
				// console.log("value of scorewidth is ", res);
				return res;
			}
		},
		scoreStartVal: {
			get() {
				let baseVal = (this.rangeVal.upperRange - this.rangeVal.lowerRange) / 100;
				// console.log("the base value is ", baseVal);

				let numerator = this.lowerScore - this.rangeVal.lowerRange;

				// console.log("the numerator is ", numerator);
				let x = Math.round(numerator / baseVal);
				// console.log("value of scorestartval is ", x);

				// if (this.scoreWidth + x >= 100) {
				// 	x = 100 - this.scoreWidth;
				// 	if (x < 5) {
				// 		x = 10;
				// 	}
				// }
					
					if(x> 93){
						x= 93;
					}
					
				// x = Math.round(x - this.scoreWidth/2);
				return x;
			}
		},
		bubbleArrowVal: {
			get() {
				if (this.isLarge == "true" && !this.$vuetify.display.smAndDown) {

					let x = this.scoreStartVal + this.scoreWidth;
					let z = "";
					// console.log("the value of bubbleArrowVal is ", x);
					if (x > 80 && this.scoreStartVal > 90) {

						// z = 'calc(50% - 200px)';
						z = 187;
					}
					else if (x > 80 && this.scoreStartVal > 60) {

						// z = 'calc(50% - 200px)';
						z = 180;
					}
					else if (x < 40) {
						// z= 'calc(50% - 30px)'
						z = 30;
					}
					else {
						// z=  'calc(50% - 113px)'
						z = 113;

					}
					// console.log("z val is ", z);
					return z;
				}
				else {
					let x = this.scoreStartVal + this.scoreWidth;
					let z = "";
					// console.log("the value of bubbleArrowVal is ", x);
					if (this.scoreWidth > 70) {
						z = 74;
					}
					else if (x > 80 && this.scoreStartVal > 90) {
						// z = 'calc(50% - 200px)';
						z = 122;
					}
					else if (this.scoreStartVal < 30 && x < 30) {
						z = 20;
					}
					else if (x > 80 && this.scoreStartVal > 60) {

						// z = 'calc(50% - 200px)';
						z = 120;
					}
					else if (x < 40) {
						// z= 'calc(50% - 30px)'
						z = 20;
					}
					else {
						// z=  'calc(50% - 113px)'
						z = 74;

					}
					// console.log("z val is ", z);
					return z;
				}
			}
		},
		rangeVal: {
			get() {
				let x = {};
				if (this.isLarge == "true") {
					
					x = {
						lowerRange: 400,
						upperRange: 1600,
					}
				}
				else {
					
					x = {
						lowerRange: 200,
						upperRange: 800,
					}
				}
				
				return x;
			}
		}
	}
}
</script>

<style scoped>
.outer-div {
	height: 24px;
	width: 100%;
	border-radius: 50px;
	background: #3E3D55;
	position: relative;
}

.small-outer-div {
	height: 20px !important;
}

.inner-div {
	height: 32px;
	border-radius: 50px;
	background: linear-gradient(90deg, #AB77FF 0%, #585EF3 100%);
	border: 4px solid #4D4C64;
	position: relative;
	bottom: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 4px;
	min-width: 32px;
	text-align: center;
}

.small-inner-div {
	height: 28px !important;
}

.inner-circle {
	height: 16px;
	width: 16px;
	border-radius: 50px;
	background-color: white;
	position: absolute;
}

.small-inner-circle {
	height: 14px;
	width: 14px;
}

.inner-circle-1 {
	left: 4px;
}

.inner-circle-2 {
	right: 4px;
}

.bubble {
	padding: 16px;
	position: absolute;
	background: #3E3D55;
	border-radius: .4em;
	bottom: 55px;
	width: 200px;
}

.small-bubble {
	width: 140px !important;

}

.inner-div:after {
	content: '';
	position: absolute;
	top: -36px;
	left: calc(50% + 4px);
	width: 0;
	height: 0;
	border-top-color: #3E3D55;
	border-right: 15px solid transparent;
	border-top: 30px solid #3E3D55;
	border-left: 15px solid transparent;
	margin-left: -20px;
	margin-bottom: -20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bubble-text {
	font-size: 32px;
	color: #ffffff;
	font-weight: 700;
	line-height: 1;
}

.small-bubble-text {
	font-size: 24px !important;
}

.legend-text {
	font-size: 1rem;
	color: #ffffff;
}
</style>