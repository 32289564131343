<template>
    <div class="hello"></div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { v4 as uuidv4 } from "uuid";
  import { getDeviceId, getTempUserId } from "@/services/authService";
  import _ from "lodash";
  import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
  
  export default {
    name: 'SubscriptionSuccussPage',
    async created() {
        // this.$store.commit("appbarVisible", false);
        // this.$store.commit("navbarVisible", false);
        if ("sessionId" in this.$route.query) {
            const sessionId = this.$route.query.sessionId;
            const response = await this.$store.dispatch("getSubscriptionDetails", {"sessionId": sessionId})
            // console.log(response)
            if(!_.isEmpty(response)){
              //Add pinpoint
              recordAnalyticsEvent(AnalyticsEvents.v2SubscriptionSuccessScreenOpen, {
                packageIds: JSON.stringify(response.packageIds),
                sessionId: sessionId,
              });
              const route = response.route === '/prices'? '/learn': response.route;
              this.$router.replace(route); 
            }
        }
    },
    data: () => ({
      missingToken: false,
      invalidToken: false
    }), 
    computed: {},
    watch: {},
    methods: {}
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin: 0 10px;
  }
  
  a {
    color: #42b983;
  }
  </style>
  