<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="breadcrumbItems" density="compact" class="no-new-line t-h6Reg text-white">
        <template v-slot:prepend>
          <v-btn v-if="breadcrumbItems.length > 0" color="#26253A" class="ma-0 mr-2 text-white" size="x-small"
            icon="mdi-chevron-left" @click="$router.back()"></v-btn>
        </template>
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right" color="grey"></v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>


  <Transition v-if="showDialog" name="slide-fade">
    <v-row class="text-center mb-1 mt-0 pt-0" justify="center">
      <v-col cols="12" sm="9" md="10" lg="6" xl="6">

        <v-card class="ai fill-height py-8 px-6 px-sm-6 mx-2">
          <v-row justify="start" align="center">
            <v-col cols="auto">
              <v-avatar size="50">
                <v-img src="@/assets/mento_ai.png">
                </v-img>
              </v-avatar>
            </v-col>
            <v-col cols="auto">
              <v-row class=" t-h6 text-white text-left text-wrap">
                {{ currentSkill.message }}
              </v-row>
            </v-col>


          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </Transition>

  <v-layout class="mb-0 pb-0" v-if="true" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
    <v-container fluid class="d-flex mt-3 mt-md-0 mb-0 pb-0">

      <v-row class="mt-0 pt-0">

        <v-col class=" mt-0 pt-0 justify-center align-center" :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
          <v-container fluid class="px-2 mt-0 pt-0">
            <v-row justify="center" class="mb-0" align="center">
              <v-col cols="12">
                <v-row class="t-h5 text-white text-center mb-4 mt-4" justify="center">
                  {{ currentTopic ? currentTopic.entityLabel : '' }} 
                </v-row>
              </v-col>
              <v-col v-if="!mdAndUp">
                <v-img height="70" :src="getSkillLevelIcon()" />
                <p class="text-center text-white  mt-3">{{newSkillName}}</p>
              </v-col>
              
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12" sm="9" xs="8" md="8" lg="6" xl="5">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-card elevation=0 :class="{ 'on-hover': isHovering }" class=" py-4 report-container"
                        v-bind="props">
                        <v-card-text class="pa-4">
                          <v-row class="pa-4" justify="space-around">
                            <v-col v-if="mdAndUp" cols="6" xl="3" md="4" sm="4" xs="2" align-self="center" class="">
                              <v-img height="112" :src="getSkillLevelIcon()" />
                              <p class="text-center text-white  mt-3">{{newSkillName}}</p>
                            </v-col>
                            
                            <v-col class="align-center justify-center" :cols="mdAndUp ? '6' : '12'">
                              <template v-for="(item, i) in defaultReportDetails" :key="i">
                                <v-row justify="center" class="t-h6">
                                  <v-col cols="6">
                                    <span class="text-center text-white">{{ item.name }}</span>
                                  </v-col>
                                  <v-col cols="6">
                                    <div class="d-flex px-2">
                                      <v-icon :icon="item.icon" :color="item.color"></v-icon>
                                      <span class="px-4 text-white">{{ item.value }}</span>
                                    </div>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-hover>


                    <v-row  class="ma-0 pa-8 text-center" align="center" justify="center">
                      <v-col cols="auto">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-sheet @click="onViewSolution" class="view-past-solutions t-subtitle"
                            :class="{ 'on-hover': isHovering }" v-bind="props">
                            <span class="text-font-8rem text-white">View solutions</span>
                          </v-sheet>
                        </v-hover>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

              </v-col>
            </v-row>

          </v-container>
        </v-col>

      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { useDisplay } from 'vuetify';
import {AnalyticsEvents, recordAnalyticsEvent} from "@/services/awsPinpointAnalyticsService";
import { mapState } from "vuex";

export default {
  name: 'QuizReportPage',
  async created() {
    if ('testId' in this.$route.params) {
      this.testId = this.$route.params.testId;
    }
    if ('testInstanceId' in this.$route.params) {
      this.testInstanceId = this.$route.params.testInstanceId;
    }
    if (!this.currentTopicId) {
      console.log("No topicId")
      this.$router.back();
    } else {
      console.log("Current Topic Id", this.currentTopicId);
      if (this.currentTopicId && this.newLearnNodes) {
        let current = {};
        this.newLearnNodes.forEach(item => {
          if (item["entityId"] == this.currentTopicId) {
            current = item;
          }
        });
        this.currentTopic = current;
      }

      if (this.currentQuizReport) {
        console.log("currentQuizReport is ", this.defaultReportDetails, this.currentQuizReport);
        this.defaultReportDetails = this.defaultReportDetails.map(item => {
          if (item.name == "Attempted") {
            item.value = this.currentQuizReport.attemptedQuestions + "/" + this.currentQuizReport.totalQuestionInTest;
          } else if (item.name == "Correct") {
            item.value = this.currentQuizReport.correctQuestions;
          } else if (item.name == "Incorrect") {
            item.value = this.currentQuizReport.incorrectQuestions;
          } else if (item.name == "Quiz time") {
            item.value = this.formatTime(this.currentQuizReport.quizTimeInMinutes);
          } else if (item.name == "My time") {
            item.value = this.convertTimeInSecondsToMinutes(this.currentQuizReport.timespentInSeconds);
          }
          return item;
        });
        if (this.currentQuizReport.topicSkill) {
          let topicSkill = this.currentQuizReport.topicSkill
          this.currentSkill = topicSkill[0];
        }
        if(this.currentSkill.message == null || this.currentSkill.message == undefined || this.currentSkill.message == ''){
          this.showDialog = false;
        }
        else{
          this.showDialog= true;
        }
      }

      
    }
    if(this.newLearnNodeActivity?.skillVerificationQuiz){
      if(this.newLearnNodeActivity.skillVerificationQuiz.activityId == this.currentActivityId){
        this.currentSkillVerificationActivity = this.newLearnNodeActivity.skillVerificationQuiz;
      }
    }

    if(this.newLearnNodeActivity?.skillUpgradeQuiz){
      this.newLearnNodeActivity.skillUpgradeQuiz.forEach(item => {
        if(item["activityId"] == this.currentActivityId){
          this.currentSkillUpgradeActivity = item;
        }
      });
    }
    recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicQuizReportOpenAfterSubmit, {
      topicId: this.currentTopicId,
      topicName: this.newLearnNodeActivity.topicName,
      currentSkill: this.newLearnNodeActivity.skill,
      isReady: this.currentTopic.isReady, 
      activityId: this.currentActivityId, 
      activityType: this.currentSkillUpgradeActivity ? "skillUpgradeQuiz" : "skillVerificationQuiz", 
      attempted: false, 
      recommended: this.currentSkillUpgradeActivity ? this.currentSkillUpgradeActivity.recommended : this.currentSkillVerificationActivity.recommended,
      testId: this.testId, 
      testInstanceId: this.testInstanceId, 
      skillStatus: this.currentSkill.skillStatus, 
      oldSKill: this.currentSkill.oldSkillStr, 
      newSkill: this.currentSkill.newSkillStr
    });
  },
  data: () => ({
    testId: "",
    testInstanceId: "",
    currentTopic: {},
    newSkillName: "",
    currentSkillUpgradeActivity:{},
    currentSkillVerificationActivity:{},
    currentSkill: {},
    showDialog: false,
    defaultReportDetails: [
      {
        name: 'Attempted',
        icon: 'mdi-file-question',
        color: '#90CAF9',
        value: '0/0'
      },
      {
        name: 'Correct',
        icon: 'mdi-check-circle',
        color: '#55B56A',
        value: '0'
      },
      {
        name: 'Incorrect',
        icon: 'mdi-close-circle',
        color: '#F44336',
        value: '0'
      },
      {
        name: 'Quiz time',
        icon: 'mdi-clock-time-three-outline',
        color: '#536DFE',
        value: '00:00'
      },
      {
        name: 'My time',
        icon: 'mdi-clock-time-three-outline',
        color: '#536DFE',
        value: '00:00'
      }
    ],
    breadcrumbItems: [
      {
        title: "Report",
        disabled: false,
        href: "newLearn",
      }
    ],
  }),
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, smAndDown, mdAndUp, lgAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, smAndDown, lgAndUp };
  },
  methods: {
    getSkillLevelIcon() {
      this.newSkillName = this.currentSkill?.newSkillStr;
      console.log("newSkillName", this.newSkillName);
      let skillLevel = this.currentSkill?.newSkillStr?.toLowerCase();
      
      let icon = "assets/images/medal_beginner_enable.svg";
      if (skillLevel) {
        if (skillLevel == "intermediate") {
          icon = "assets/images/medal_intermediate_enable.svg";
        } else if (skillLevel == "advanced") {
          icon = "assets/images/medal_advanced_enable.svg";
        } else if (skillLevel == "mastery") {
          icon = "assets/images/medal_mastery_enable.svg";
        }
      }
      return require(`@/${icon}`);
    },
    convertTimeInSecondsToMinutes(timeInSeconds) {
      if(timeInSeconds < 0) return '-- : --';
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    formatTime(minutes) {
      const formattedMinutes = String(Math.floor(minutes)).padStart(2, '0');
      const formattedSeconds = '00';

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    onViewSolution() {
      recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicQuizReportViewSolutionAfterSubmit, {
        topicId: this.currentTopicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
        isReady: this.currentTopic.isReady, 
        activityId: this.currentActivityId, 
        activityType: this.currentSkillUpgradeActivity ? "skillUpgradeQuiz" : "skillVerificationQuiz", 
        attempted: false, 
        recommended: this.currentSkillUpgradeActivity ? this.currentSkillUpgradeActivity.recommended : this.currentSkillVerificationActivity.recommended,
        testId: this.testId, 
        testInstanceId: this.testInstanceId
      });
      this.$router.replace("/testresult/" + this.testId + "?instance=" + this.testInstanceId+"&report="+true+"&quiz="+true);
    },
  },
  computed: {
    ...mapState(["newLearnNodes", "newLearnNodeActivity", "currentActivityId", "currentTopicId", "currentQuizReport"])
  },
}
</script>

<style scoped>
.skill-container {
  border: 0.5px solid rgba(231, 231, 231, 0.25);
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  border-radius: 14px;
}

.view-solution {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 10px 14px;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: black;
  background-color: rgb(var(--v-theme-navColor));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.report-container {
  border-color: transparent;
  border-radius: 16px;
  background-color: #26253A !important;
  width: 100%;
}

.heightBread {
  height: 80px;
}

.ai {
  background: linear-gradient(180deg, #BF98FF 0%, #656CFF 100%);
  border-radius: 10px;
}

.small-card {}

@media only screen and (max-width: 335px) {
  .small-card {
    text-align: center !important;
    justify-content: center !important;
    margin-left: 0px !important;
  }

  .t-h6{
    font-size: 0.8rem !important;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.view-past-solutions {
  padding: 10px 25px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 1.5px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.view-past-solutions.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}
</style>