<template>


    <v-container style="position: relative" fluid class="ma-0 pa-4 bg-transparent">
        <div class="mt-4" :class="{
            'entity-container': lg,
            'entity-container-xl': xl,
            'entity-container-xxl': xxl,
        }">
            <AIInsights></AIInsights>
        </div>
    </v-container>
</template>

<script>

import AIInsights from "@/components/ai/AIInsights.vue";
import { useDisplay } from 'vuetify';

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};

export default {
    name: "AIInsightsParent",
    components: {AIInsights},
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {

    },
    mounted() {
    },
    unmounted() {
    },
    data: () => ({

    }),
    computed: {

    },

    methods: {

    },
    watch: {

    },
};
</script>

<style scoped>
.entity-container {
    padding-left: 8%;
    padding-right: 8%;
    background-color: transparent;
}

.entity-container-xl {
    padding-left: 17%;
    padding-right: 17%;
    background-color: transparent;
}

.entity-container-xxl {
    padding-left: 28%;
    padding-right: 28%;
    background-color: transparent;
}
</style>

<style>
.htmlAI * {
    padding: revert !important;
    margin: revert !important;
}
</style>