<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs
        :items="displayBreadCrumbs"
        class="no-new-line t-h6Reg text-white text-primaryTextColor"
      >
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn
            v-if="breadCrumbs.length > 0"
            color="#26253A"
            class="ma-0 mr-2 text-primaryTextColor"
            size="x-small"
            @click="back()"
            icon="mdi-chevron-left"
          >
          </v-btn>
        </template>
        <template v-slot:title="{ item }">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{
            item.title
          }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>

  <v-container fluid class="pb-4 main-lg">
    <v-container fluid class="pa-0 container-width">
      <v-row>
        <v-col>
          <v-card class="main-card text-white mb-4">
            <v-card-title class="px-5 pt-4 pb-0">
              <span v-if="studentName !== ''" class="main-heading">
                {{ studentName }}
              </span>
              <span v-else class="main-heading">Student</span>
              <div class="t-h6Reg parameter-style">
                Here's what your child is currently working on and how they're doing.
              </div>
              <v-divider></v-divider>
            </v-card-title>
            <v-card-text class="pa-5 pt-4">
              <v-row class="ma-0">
                <v-col
                  cols="auto"
                  class="mr-1 tab t-1remReg text-white"
                  :class="{ 'selected-tab': tab.id == tabIndex }"
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click.stop="changeTab(tab)"
                >
                  {{ tab.name }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="main-card text-white">
            <v-card-text class="pa-0">
              <v-row class="ma-0" v-if="tabIndex == '1'">
                <PracticeTestReportComponent
                  :studentId="studentId"
                ></PracticeTestReportComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '2'">
                <SkillsComponent
                  assignmentType="student"
                  :studentId="studentId"
                ></SkillsComponent>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { useDisplay } from "vuetify";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import SkillsComponent from "../teacher/SkillsComponent.vue";
import _ from "lodash";
import { breadcrumbHandler } from "@/services/breadcrumbHandler";
import { mapState } from "vuex";

import PracticeTestReportComponent from "../teacher/PracticeTestReportComponent.vue";

export default {
  name: "ChildDetailPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  components: {
    PracticeTestReportComponent,
    SkillsComponent,
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      {
        name: "Test Attempts",
        id: "1",
      },
      {
        name: "Skills",
        id: "2",
      },
    ],
    studentId: "",
    tabIndex: "1",
  }),
  async beforeRouteUpdate(to, from) {
    this.studentId = this.$route.params.studentId;
  },
  async created() {
    
    if (!this.isUserParent) {
      if (this.isUserAdmin) {
        this.$router.replace("/admin/1");
      }
      else if(this.isUserTeacher){
        this.$router.replace("teacher/students")
      } 
      else {
        this.$router.replace("/learn");
      }
    }

    this.$store.commit("appbarVisible", true);
    this.$store.commit("navbarVisible", true);
    breadcrumbHandler(this);
    
    this.$store.commit("clearTeacherAssignments");

    if ("studentId" in this.$route.params) {
      this.studentId = this.$route.params.studentId;
    }

    this.tabIndex = this.$route.params.studentTab;
    if (this.$route.params.studentTab > this.tabs.length) {
      this.$router.replace("1");
      this.tabIndex = 1;
    }
    this.selectedTab = _.find(this.tabs, { id: this.tabIndex });

    recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildTabSwitched, {
      courseId: this.courseId,
      tabName: this.selectedTab?.name,
      childId: this.studentId,
    });
  },
  computed: {
    ...mapState([
      "studentName",
      "breadCrumbs",
      "config",
      "isUserTeacher",
      "isUserAdmin",
      "courseId",
      "isUserParent"
    ]),
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = this.breadCrumbs;
        breadCrumbs[breadCrumbs.length - 1].disabled = true;
        return breadCrumbs;
      },
    },
  },

  watch: {},

  methods: {
    breadCrumbRoute(item) {
      this.$router.replace(item.pageRoute);
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute;
      this.$router.replace(page);
    },
    changeTab(tab) {
      this.tabIndex = tab.id;
      this.selectedTab = tab;
      recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildTabSwitched, {
        courseId: this.courseId,
        tabName: this.selectedTab?.name,
        childId: this.studentId,
      });
      this.$router.replace(`${tab.id}`);
    },
  },
};
</script>
<style scoped>
.heightBread {
  height: 80px;
}

.container-width {
  max-width: 1080px !important;
}

.main-lg {
  padding-top: 20px;
  /* padding-left: 12%;
  padding-right: 12%; */
}

.main-md {
  padding-top: 60px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  /* background: rgb(var(--v-theme-cardBackgroundColor)); */
  background-color: rgb(var(--v-theme-cardBackgroundColor2));
}

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.tab {
  background: rgb(var(--v-theme-report-subCardBg));
  padding: 10px 15px;
  margin-right: 12px !important;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 12px;
}

.selected-tab {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
  border: 2px solid transparent;
}

.parameter-style {
  opacity: 0.4;
}
</style>
