<template>
  <v-chart class="chart" :option="option" :autoresize="true" />
</template>

<script>
import _ from "lodash";
import { useDisplay } from "vuetify";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GraphicComponent 
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide } from "vue";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GraphicComponent 
]);

// const option = ref();

export default {
  name: "PieHalfDonut",
  setup() {
        // Destructure only the keys we want to use
    const { xs,  md, lg } = useDisplay();
    provide(THEME_KEY, "dark");
    return { xs, md, lg };
  },
  props: {
    data: Object,
    title: String,
  },
  data: () => ({}),
  components: { VChart },
  async created() {},
    computed: {
    option: {
      get() {
        return {
          backgroundColor: "#2F2F48",
          title:{
            show: false
          },
          // title: {
          //   text: this.title,
          //   left: 5,
          //   top: 10,
          //   textStyle: {
          //     fontSize: "16px",
          //     color: "#b6b6be",
          //     fontFamily: "Inter",
          //     overflow: "truncate",
          //     width: 290
          //   },
          // },

          legend: {
            selectedMode: false,
            orient: "vertical",
            bottom: 10,
            right: 5,
            itemWidth: 10,
            icon: "circle",
            textStyle: {
              fontSize: "13px",
              color: "#b6b6be",
              fontFamily: "Inter"
            },
          },
          graphic: [
            {
              type: "text",
              z: 100,
              left: this.xs ? '23%' : this.md? '27%' : this.lg? '23%' :'24%',
              top: '63%',
              style: {
                fill: "#FFFFFF",
                text: this.data.isEmpty ? '--' : this.data.percentage +"%",
                font: 'bolder 22px "Inter", sans-serif',
                textAlign: "center",
              },
            },
            {
              type: "text",
              z: 100,
              left: this.xs ? '23%' : this.md? '27%' : this.lg? '23%' :'24%',
              top: '80%',
              // top: 'middle',
              style: {
                fill: "FFFFFF", 
                text: "Correct",
                font: 'normal 12px "Inter", sans-serif',
                textAlign: "center",
                opacity: 0.7
              },
            },
          ],
          series: [
            {
              name: this.title,
              type: "pie",
              radius: [55, 80],
              center: ["30%", "90%"],
              startAngle: 180,
              label: {
                show: false,
              },
              data: [
                ...this.data.data.slice(0, 3),
                {
                  // make an record to fill the bottom 50%
                  value: this.data.data[3].value,
                  itemStyle: {
                    // stop the chart from rendering this piece
                    color: "none",
                    decal: {
                      symbol: "none",
                    },
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
          ],
          color: ["#70BB9C", "#EA4B67", "#424159"],
        };
      },
    },
      },

  watch: {},

  methods: {},
};
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>
