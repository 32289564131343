const SUMMATION_KEYBOARD_LAYER = {
  "summation-layer": {
    styles: "",
    rows: [
      [
        { class: "small", latex: "\\sum_{#0}^{#0}" },
        { class: "small", latex: "\\sum_{#0}" },
        { class: "separator w5" },
        { class: "small", latex: "\\prod_{#0}^{#0}" },
        { class: "small", latex: "\\textstyle\\prod_{#0}^{#0}" },
        { class: "separator w5" },
        { class: "small", latex: "\\bigcap" },
        {
          class: "small",
          latex: "\\frac{\\operatorname d{#0}}{\\operatorname d{#0}}"
        },

        { class: "separator w5" },
        {
          class: "small",
          label:
            "<span><img  class ='int' src='/assets/integration/inti1.png'></span>",
          insert: "$$\\int_{#0}^{#0}{#0}\\operatorname d{#0}$$"
        },
        {
          class: "small",
          label:
            "<span><img  class ='int'   src='/assets/integration/inti2.png'></span>",
          insert: "$$\\int_{#0}{#0}\\operatorname d{#0}$$"
        }
      ],

      [
        { class: "small", latex: "\\textstyle\\sum_{#0}^{#0}" },
        { class: "small", latex: "\\textstyle\\sum_{#0}" },
        { class: "separator w5" },
        { class: "small", latex: "\\prod_{#0}" },
        { class: "small", latex: "\\textstyle\\prod_{#0}" },
        { class: "separator w5" },
        { class: "small", latex: "\\bigcup" },
        { class: "small", latex: "\\frac{\\partial{#0}}{\\partial{#0}}" },
        { class: "separator w5" },
        { class: "small", latex: "\\int_{#0}^{#0}" },
        { class: "small", latex: "\\int_{#0}" }
      ],
      [
        { class: "small", latex: "\\lim_{{#0}\\rightarrow\\infty}" },
        { class: "small", latex: "\\lim_{#0}" },
        { class: "separator w5" },
        { class: "small", latex: "\\operatorname d" },
        { class: "small", latex: "\\partial" },

        { class: "separator w5" },
        { class: "small", latex: "\\nabla\\cdot{#0}" },
        { class: "small", latex: "\\nabla\\times{#0}}" },

        { class: "separator w5" },
        { class: "small", latex: "\\oint" },
        { class: "small", latex: "\\iint" }
      ],
      [
        {
          class: "small",
          latex: "\\overset\\rightharpoonup{#0}",
          variants: [
            { class: "small", latex: "\\overrightarrow{#0}" },
            { class: "small", latex: "\\overline{#0}" },
            { class: "small", latex: "\\overleftrightarrow{#0}" },
            { class: "small", latex: "\\ddot{#0}" }
          ]
        },
        {
          class: "small",
          latex: "\\overbrace{#0}",
          variants: [
            { class: "small", latex: "\\overset\\frown{#0}" },
            { class: "small", latex: "\\underset\\smile{#0}" },
            { class: "small", latex: "\\underbrace{#0}" },
            { class: "small", latex: "\\left\\langle{#0}\\right\\rangle" },
            { class: "small", latex: "\\left||{#0}|\\right|" }
          ]
        },

        { class: "separator w5" },
        {
          class: "small",
          latex: "\\triangle{#0}",
          variants: [{ class: "small", latex: "\\nabla{#0}" }]
        },

        {
          class: "small",
          latex: "\\xrightarrow{#0}",
          variants: [
            { class: "small", latex: "\\xrightarrow[{#0}]{}" },
            { class: "small", latex: "\\xrightarrow[{#0}]{#0}" },
            { class: "small", latex: "\\xleftarrow[{#0}]{#0}" },
            { class: "small", latex: "\\xleftarrow[{}]{#0}" },
            { class: "small", latex: "\\xleftarrow[{#0}]{}" }
          ]
        },
        { class: "separator w5" },

        {
          class: "small",
          latex: "\\cdots",
          variants: [
            { class: "small", latex: "\\vdots" },
            { class: "small", latex: "\\ddots" },
            { class: "small", latex: "\\cdotp" },
            { class: "small", latex: "\\ldotp" },
            { class: "small", latex: "\\ldots" },
            { class: "small", latex: "\\mathellipsis" }
          ]
        },
        {
          class: "small",
          latex: "\\begin{rcases} {#0} \\\\ {#0} \\end",
          variants: [
            {
              class: "small",
              latex: "\\begin{cases} {#0} & {#0} \\\\ {#0} & {#0} \\end"
            },
            { class: "small", latex: "\\begin{cases} {#0} \\\\ {#0} \\end" },
            {
              class: "small",
              latex:
                "\\begin{array}{l} {#0} \\\\  {+}{\\underline{#0}}\\\\ {#0} \\end{array}"
            },
            {
              class: "small",
              latex:
                "\\begin{array}{lc} {#0}{=}{#0} \\\\  {#0}{=}{#0} \\end{array}"
            }
          ]
        },

        { class: "separator w5" },

        {
          class: "small",
          latex: "\\overset{#0}{\\underset{#0}{#0}}",
          variants: [
            { class: "small", latex: "\\overset{#0}{#0}" },
            { class: "small", latex: "\\underset{#0}{#0}" }
          ]
        },
        {
          class: "small",
          latex: "\\overbrace{#0}^{#0}!}",
          variants: [{ class: "small", latex: "\\underbrace{#0}_{#0}!" }]
        }
      ]
    ]
  }
};
const SUMMATION_KEYBOARD = {
  "summation-keyboard": {
    label: "Summation", // Label displayed in the Virtual Keyboard Switcher
    tooltip: "summation", // Tooltip when hovering over the label
    layer: "summation-layer"
  }
};
export { SUMMATION_KEYBOARD_LAYER, SUMMATION_KEYBOARD };
