import store from "../store";

export function navDataFeatureFlagCheck(featureFlag, userRole){
  let featuresList = store.state.featureFlagsList;
  if(featureFlag in featuresList){
    if((userRole == 'teacher') && featuresList[featureFlag].teacher){
      return true;
    }
    else if ((userRole == 'admin') && featuresList[featureFlag].admin){
      return true;
    }
    else if((userRole == 'parent') && featuresList[featureFlag].parent){
      return true;
    }
    else if((userRole == 'students') && featuresList[featureFlag].students){
      return true;
    }
    else{
      return false;
    }
  }
  return true;
}

export function checkNavListForFeatureFlags(featuresList, userRole){
  let navArr = []; 
  if(featuresList?.length > 0){
    for( let item of featuresList){
      if('featureFlag' in item ){
        let show = navDataFeatureFlagCheck(item.featureFlag, userRole);
        if(show){
          navArr.push(item);
        }
      }
      else{
        navArr.push(item);
      }
    }
  }
  return navArr;
}