<template>
  <div  v-html="computedHtmlString">


  </div>
</template>
  
<script>

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: 'HtmlRender',
  props: {
    htmlString: String
  },
  created() {

  },
  data: () => ({
    currentDomain: window.location.hostname == 'localhost' ? 'http://' + window.location.host + '/' : 'https://' + window.location.host + '/',

  }),

  computed: {
    computedHtmlString: {
      get() {
        if (this.htmlString == null) {
          return ""
        }

        return this.getMarkDownHtml(this.htmlString)

        // let node = document.createElement('div')
        // node.innerHTML =html
        // let images = node.querySelectorAll('img')

        // let links = node.querySelectorAll('a');
        // for(let item of links){
        //   item.target="_blank";
        // }

        // for (let item of images) {
        //   console.log(item);
        //   console.log("Img src : ", item.src);
        //   console.log("Host: ", window.location.href);
        //   item.src = ('https://innotrail-questionadmin-images.s3.amazonaws.com/' + item.src).replace(this.currentDomain, '')
        // }

        // return node.innerHTML

      }
    }
  },

  watch: {

  },

  methods: {
    getMarkDownHtml(markDownText) {

      let out = false;
      // console.log('markDownText 1: ' + markDownText);
      const regex = /\\/gm;
      const escapedString = markDownText.replace(regex, "\\\\");

      const escapedStringWithAbsSrc = this.appendSrcBaseUrl(escapedString);
      out = marked.parseInline(escapedStringWithAbsSrc, { renderer });
      // out = unescape(marked(escape(markDownText), { renderer }));
      // console.log('markDownText 2: ' + out);
      /* eslint-disable prefer-template */
      // return '(' + optionKey + ') ' + out;

      return out;
    },
    appendSrcBaseUrl(element) {
      // Image Abs URL FIX
      const baseUrl = this.$store.state.app_s3url;
      const srcWithBaseUrl = `src="` + `${baseUrl}`;
      const updatedElement = element.replace(/src="/gi, srcWithBaseUrl);
      return updatedElement;
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  