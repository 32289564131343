<template>
  <div>

    <v-app-bar color="navbar">

      <v-toolbar-title class="ml-10 fontClass">{{ startTestInfo.testmetadata.testName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="backFromDialog" class="mr-4"> mdi-close</v-icon>


    </v-app-bar>
    <section class="  center-div">
      <v-row class="align-center" justify="center">
        <v-col cols="12" class=" text-center t-h5 font-weight-bold text-white">
          <span class="timer-color">
            Congratulations!
          </span> You have successfully completed the test
        </v-col>
      </v-row>
      <v-row class="align-center" justify="center">
        <v-col cols="12" class=" text-center">
          <div class="" @click="showResults">
            <span class="result-btn t-btn btn-rd text-grey">
              Go back to Results >
            </span>

          </div>
        </v-col>
      </v-row>
      <v-row v-if="message.length > 0" class="align-center" justify="center">
        <v-col cols="12" class=" text-center text-grey">
          <span class="font-weight-bold">
            Note: </span>
          {{ message }}
        </v-col>
      </v-row>
      <!-- 
      <v-row class="align-center " justify="center">
        <v-col cols="auto">
          <v-btn prepend-icon="mdi-download" color="secondaryBackground" class="btn-rd t-btn" variant="flat">
            Download Answers
          </v-btn>
        </v-col>

      </v-row> -->

    </section>
  </div>
</template>
      
<script>
import { mapState } from "vuex";
import moment from 'moment'
import { getLocalForageItem } from "@/services/localforageService";


export default {
  name: 'TestSubmissionPage',
  data: () => ({

  }),
  components:
  {


  },
  async created() {
    this.$store.commit("updateTestRoutingAllowed", false)

  },
  computed: {
    ...mapState(["startTestInfo", "submissionInfo"]),
    message: {
      get() {
        if (this.submissionInfo.resultavailable == false) {
          return `Your Result will be declared on ${this.getDateTimeFromTimeStamp(this.submissionInfo.resultTimestamp)}`
        }
        else {
          return "Your result has been declared already"
        }
      }
    }
  },

  watch: {

  },

  methods: {
    getCurrentClientTimeInSeconds() {
      return Math.floor(Date.now() / 1000)
    },
    getDateTimeFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM  YYYY	hh:mm A	");
      return dateString

    },
    async showResults() {
      let collectionId = await getLocalForageItem("collectionId")
      if (collectionId != null) {
        let testId = this.submissionInfo.testId
        this.$router.replace(`/testseries/${collectionId}/1/${testId}`)
      }
      else {
        this.$router.back()
      }
    },
    backFromDialog() {

      this.$router.back()
    }
  },

}
</script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.correct {
  background: #26B99AA6 !important;
  color: white !important;
  ;
}

.result-btn {
  cursor: pointer;
  border-bottom: 1px dashed black;

}

.success {
  background: #26B99AA6 !important;

}

.timer-color {
  color: #26B99AA6;
}

.unanswered {
  border-color: #949494 !important;
}

.marked {
  border-color: #FF894F !important;
}

.option {
  background-color: rgb(var(--v-theme-secondaryBackground)) !important;
  ;
  border-radius: 10px;
}

.card-bg-color {
  background-color: rgb(var(--v-theme-secondaryBackground)) !important;
  ;

}

.outline-color {
  border-color: rgb(var(--v-theme-secondaryBackground)) !important;
  ;

}

.bottom {
  position: absolute !important;
  bottom: 10px !important;
}

.center-div {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
</style>
      