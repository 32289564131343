<template>
  <div v-if="currentMode == 'crop'" class="cameraBG">
    <cropper class="cropper" :src="imgsrc" @change="change" />
    <v-row justify="center">
      <div class="capturePhoto">
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor"
          prepend-icon="mdi-camera"
          @click="currentMode = 'normal'"
          >Back</v-btn
        >
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor ml-3"
          prepend-icon="mdi-camera"
          @click="crop"
          >Crop</v-btn
        >
      </div>
    </v-row>
  </div>
  <div v-if="currentMode == 'camera'" class="cameraBG">
    <v-row justify="center">
      <div class="capturePhoto">
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor"
          prepend-icon="mdi-camera"
          @click="currentMode = 'normal'"
          >Back</v-btn
        >
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor ml-3"
          prepend-icon="mdi-camera"
          @click="takePhoto"
          >Take Photo</v-btn
        >
      </div>
    </v-row>

    <video id="video" autoplay muted playsinline>
      <button id="capture">Take Photo</button>
    </video>
    <div id="settings">Camera settings will appear here...</div>

    <canvas id="canvas"></canvas>
  </div>

  <div class="px-4 pt-4" v-if="currentMode == 'normal'">
    <v-row align="center" justify="start" dense>
      <v-col cols="auto">
        <span class="t-h125 text-center mb-1 text-primaryTextColor"
          >AI Question Solver</span
        >
      </v-col>
    </v-row>

    <v-row align="center" justify="start" class="">
      <v-col cols="auto">
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor"
          prepend-icon="mdi-camera"
          @click="openCamera"
          >Take Photo</v-btn
        >
      </v-col>
    </v-row>

    <v-row justify="center" class="pa-0 py-5 ma-0" v-if="aiImg != null">
      <v-col cols="12">
        <v-select
          label="Subjects"
          @update:modelValue="subjectUpdate"
          hide-details
          v-model="currentSubject"
          item-title="subjectName"
          item-value="subjectId"
          :items="subjectList"
          :rules="requiredRule"
          bg-color="#26253A00"
          base-color="#ffffff"
          color="#ffffff"
          class="text-white selector"
          item-color="black"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-textarea
          placeholder="System Prompt"
          rows="1"
          auto-grow
          v-model="systemPrompt"
          theme="dark"
          bg-color="#26253A00"
          base-color="#ffffff"
          class="text-white selector"
          label="System Prompt"
        ></v-textarea>

        <v-textarea
          placeholder="Human Prompt"
          rows="1"
          auto-grow
          v-model="humanPrompt"
          bg-color="#26253A00"
          base-color="#ffffff"
          class="text-white selector"
          label="Human Prompt"
        ></v-textarea>
      </v-col>
      <img
        id="photo"
        v-if="imgsrc.length > 0"
        :src="imgsrc"
        alt="Captured Photo"
        class="imgC my-2 mx-2"
      />
      <v-col cols="12" class="text-center">
        <v-btn
          color="success"
          class="t-btn text-capitalize btn-grad text-primaryTextColor"
          prepend-icon="mdi-camera"
          @click="sendToAI"
          :loading="loading"
          >Solve
        </v-btn>
      </v-col>

      <v-col cols="12" class="text-white" v-if="answer != null">
        {{ answer }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import {
  dataURLtoFile,
  uploadFile,
  uploadFiles,
  uploadImageFile,
  uploadJsonFiles,
} from "@/services/storageService";
import { invokeAIImageLambda } from "@/services/apiService";
import router from "@/router";
export default {
  name: "AIQSolver",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 400,
    },
  },
  async created() {
    if (this.config.environment == "prod") {
      this.$router.replace("/");
      return;
    }
    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
    await this.$store.dispatch("loadNewLearnNodes");
  },
  data: () => ({
    cameraMode: false,
    cropMode: false,
    imgsrc: "",
    currentMode: "normal",
    cropCanvas: null,
    answer: null,
    aiImg: null,
    currentSubject: "",
    loading: false,
    systemPrompt:
      "Analyze the provided image. Identify any text, context, or questions present in the image. Extract relevant information and provide a clear, step-by-step explanation. Answer the question accurately, referencing specific details from the image as needed.",
    humanPrompt: "Please solve the below question",
  }),
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  computed: {
    ...mapState(["newLearnNodes", "config"]),
    subjectList: {
      get() {
        console.log(this.newLearnNodes);
        return this.newLearnNodes;
      },
    },
  },
  components: {
    Cropper,
  },
  watch: {},

  methods: {
    async solve() {},
    async subjectUpdate(currentSubject) {
      let s = this.subjectList.find((e) => e.subjectId == currentSubject);
      console.log(s);
      if (s.subjectType == "readingandwriting") {
        this.humanPrompt = "Please solve the below english question";
        this.systemPrompt =
          "Analyze the provided image. Identify any text, context, or questions present in the image. Extract relevant information and provide a clear, step-by-step explanation. Answer the question accurately, referencing specific details from the image as needed.";
      } else {
        this.systemPrompt =
          "Analyze the provided math image. Identify the problem type and extract relevant information. Solve the problem step-by-step, ensuring to explain each step clearly. Provide the final answer along with any necessary formulas or diagrams";
        this.humanPrompt = "Please solve the below maths question";
      }
    },
    async sendToAI() {
      let uuid = uuidv4();
      this.loading = true;
      let fileName = uuid + ".png";
      let file1 = dataURLtoFile(this.aiImg, fileName);
      //console.log(file);
      let storageImg = await uploadImageFile(file1);

      let res = await invokeAIImageLambda({
        humanPrompt: this.humanPrompt,
        systemPrompt: this.systemPrompt,
        img: storageImg.key,
      });
      this.answer = res;
      this.loading = false;
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 1000);
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 1000);
    },
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);

      this.cropCanvas = canvas;
    },
    crop() {
      const dataUrl = this.cropCanvas.toDataURL("image/png");
      this.cropCanvas = null;
      console.log(dataUrl);
      this.imgsrc = dataUrl;
      this.currentMode = "normal";
      this.aiImg = dataUrl;
    },
    openCamera() {
      this.currentMode = "camera";
      this.imgsrc = "";
      this.aiImg = null;
      this.answer = null;
      this.startCamera();
    },
    // Function to start the camera stream
    // Start the camera and display the settings
    async startCamera() {
      await new Promise((r) => setTimeout(r, 1000));

      const video = document.getElementById("video");
      const settingsDiv = document.getElementById("settings");

      // Request camera access
      window.navigator.mediaDevices
        .getUserMedia({
          video: {
            // // Prefer portrait orientation by setting the width smaller than the height
            width: { ideal: 2560 }, // Example: width set lower than height to ensure portrait mode
            height: { ideal: 1440 },
            facingMode: "environment", // Or 'user' for the front-facing camera
          },
        })
        .then((stream) => {
          // Start video playback
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            video.play();
          };

          // Access the first video track
          const videoTrack = stream.getVideoTracks()[0];

          // Get camera settings and capabilities
          const settings = videoTrack.getSettings();
          const capabilities = videoTrack.getCapabilities();

          // Display the current settings and some useful capabilities
          settingsDiv.innerHTML = `
                        <strong>Current Settings:</strong><br>
                        Width: ${settings.width || "Unknown"}<br>
                        Height: ${settings.height || "Unknown"}<br>
                        Frame Rate: ${settings.frameRate || "Unknown"}<br>
                        Facing Mode: ${settings.facingMode || "Unknown"}<br><br>
                        <strong>Available Capabilities:</strong><br>
                        Max Width: ${
                          capabilities.width ? capabilities.width.max : "Unknown"
                        }<br>
                        Max Height: ${
                          capabilities.height ? capabilities.height.max : "Unknown"
                        }<br>
                        Max Frame Rate: ${
                          capabilities.frameRate ? capabilities.frameRate.max : "Unknown"
                        }<br>
                    `;
        });
    },
    // Function to take a photo and display it
    async takePhoto() {
      const video = document.getElementById("video");
      const canvas = document.getElementById("canvas");

      // Desired resolution (e.g., Full HD)
      const desiredWidth = 720; // Change this value to increase the width
      const desiredHeight = 1280; // Change this value to increase the height

      // Check the video's actual dimensions
      const aspectRatio = video.videoWidth / video.videoHeight;

      // Maintain the aspect ratio while scaling to desired dimensions
      if (desiredWidth / desiredHeight > aspectRatio) {
        canvas.width = desiredHeight * aspectRatio;
        canvas.height = desiredHeight;
      } else {
        canvas.width = desiredWidth;
        canvas.height = desiredWidth / aspectRatio;
      }

      // Draw the current frame from the video onto the canvas
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.currentMode = "crop";
      await new Promise((r) => setTimeout(r, 1000));
      // Get the image data URL and display the captured photo
      const dataUrl = canvas.toDataURL("image/png");
      this.imgsrc = dataUrl;

      // photo.style.display = "block"; // Show the captured image
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-lg {
  padding-top: 50px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.layoutLarge {
  height: 100%;
}

.cropper {
  background: #ddd;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

/* The video element will fill the entire browser window while maintaining the aspect ratio */
video {
  width: 100%;
  /* 100% of the viewport width */
  height: auto;
  /* 100% of the viewport height */
  object-fit: cover;
  /* Maintain aspect ratio and crop the overflow if necessary */
}

.imgC {
  width: 60%;
}

.cameraBG {
  background-color: black;
  width: 100%;
  height: 100vh;
  touch-action: none;
}

/* Style for the capture button */
#capture {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff5722;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  /* Ensures button is above video */
}

#settings {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

/* Make canvas invisible initially */
#canvas {
  display: none;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.capturePhoto {
  position: absolute;
  bottom: 10%;
  z-index: 2600;
}
</style>
