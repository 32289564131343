<template>
  <!-- <AppBar :show=true></AppBar>
  <NavigationDrawer :show=true></NavigationDrawer> -->
  <v-dialog hide-overlay fullscreen v-model="syllabusDialog">
    <v-card class="grey-bx" color="background">
      <v-card-text>


        <v-row justify="space-around" class=" align-center ml-2">
          <v-col cols="10">
            <v-row class=" align-center">
              <v-icon class="mr-3">
                mdi-note-multiple-outline

              </v-icon>
              <span class="t-h4
                      "> {{ this.currentTest.testName }}
              </span>
            </v-row>
          </v-col>
          <v-col align-self="end" class="text-end" offset="1" cols="1">
            <v-icon color="black" size="30" @click="syllabusDialog = false">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-5 mb-6">
          <v-divider></v-divider>
        </v-row>

        <v-row class="t-h5 px-4">
          Test Details
        </v-row>
        <v-row dense class="align-center t-h6Reg ma-0 pa-0 mt-5">
          <v-col cols="12" v-if="currentTest.subjects != null">
            {{ currentTest.subjects.toString() }}
          </v-col>
        </v-row>
        <v-row dense class="align-center t-subtitle ma-0 pa-0 ">

          <v-col cols="auto "> {{ currentTest.questionsCount }} questions</v-col>
          <v-col cols="auto" class="mx-2">
            <v-icon>mdi-clock-outline</v-icon>
            {{ currentTest.testDurationInMinutes }} mins</v-col>

          <v-col cols="auto" class="t-subtitle mx-2">Starting: {{
            getDateTimeFromTimeStamp(currentTest.startTimeStamp
            ) }}</v-col>

        </v-row>
        <v-divider class="my-8">

        </v-divider>
        <v-row class="t-h5 px-4">
          Test Syllabus
        </v-row>
        <v-row class=" px-4 mt-6">
          <HtmlRender class="htmlClass" :html-string="currentTest.syllabus"></HtmlRender>

        </v-row>
        <v-divider class="my-8">

        </v-divider>
        <v-row class="t-h5 px-4">
          Test Instructions
        </v-row>
        <v-row class=" px-4 mt-6">
          <HtmlRender class="htmlClass" :html-string="currentTest.instructions"></HtmlRender>

        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-container v-if="showData" fluid class="bg-background ma-0 pa-4">
  </v-container>
  <v-container v-else fluid class="bg-background ma-0 pa-4">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">

        <v-breadcrumbs :items="testNodesBreadcrumbs" class="no-new-line t-h6Reg">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template v-slot:prepend>
            <v-btn v-if="testNodesBreadcrumbs.length > 1" class="ma-0 mr-2" color="secondary" size="x-small"
              @click="back()" icon="mdi-chevron-left">
            </v-btn>
            <v-icon icon="mdi-book-open-variant" color="primary"></v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col>
        <v-text-field elevation="0" v-model="searchText" clearable clear-icon="$close" density="compact"
          variant="underlined" label="Search..." single-line hide-details append-inner-icon="mdi-magnify"
          @click:append-inner="onSearch" class="search-box"></v-text-field>
      </v-col> -->
    </v-row>
    <v-divider></v-divider>
    <v-container fluid class="mt-4 ma-0 pa-0 mr-5">


      <v-row>
        <v-col cols="12">


          <div v-if="currentParentType == 'testCollections'">

            <template v-for="(item, i) in userTestCollections" :key="i">
              <v-hover v-slot="{ isHovering, props }">

                <v-sheet elevation=0 :class="{ 'on-hover': isHovering }" class="my-2 pa-0 bx" v-bind="props"
                  @click="testNodeClicked(item, $event)">
                  <v-container fluid class="ma-0 py-2 px-4">
                    <v-row class="ma-0 pa-0" align="center" justify="center">
                      <v-col class="ma-0 pa-0">

                        <span class="t-h6Reg ml-4">{{ item.collectionName }}</span>
                      </v-col>
                      <v-col cols="1" class="ma-0 pa-0 text-right">
                        <v-icon color="primary" icon="mdi-chevron-right"></v-icon>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-sheet>

              </v-hover>
            </template>
          </div>
        </v-col>
      </v-row>
      <div v-if="currentParentType == 'tests'">

        <div class="mt-2 mb-6">

          <v-tabs color="transparent" v-model="tab" hide-slider mandatory align-tabs="start" show-arrows>
            <v-tab rounded @click="getTestsOnTabClick(index, item)" v-for="item, index in tests" :ripple=false
              :key="item.title" class="mx-3" :value="index">
              <v-chip color="transparent" centered class=" px-5 py-5 active" v-if="tab == index">
                <span class="testTabSelected">
                  {{ item.testStatusGroupName }}
                </span>
                <template v-slot:append>
                  <v-avatar color="white" size="25px" class=" text-black">
                    {{ item.count }}
                  </v-avatar>

                </template>
              </v-chip>
              <div v-else>
                <v-row class="align-center px-2 pl-2">
                  <span class="testTab">
                    {{ item.testStatusGroupName }}

                  </span> <v-avatar size="30px" color="#ECECEC" class=" text-black mx-1">
                    {{ item.count }}
                  </v-avatar>
                </v-row>

              </div>
            </v-tab>

          </v-tabs>
        </div>
        <v-row class="mx-3" v-for="item in currentTests" :key="item.testId">
          <v-col cols="12">
            <v-hover v-slot="{ isHovering, props }">

              <v-card elevation=0 :class="{ 'on-hover': isHovering }" class="pa-4 testCard" v-bind="props">
                <v-card-item>
                  <v-card-title class="t-h6">
                    <v-row class="align-center ma-0 pa-0">
                      {{ item.testName }}
                      <span class="ml-4">
                        <v-tooltip text="Test Details" location="end">
                          <template v-slot:activator="{ props }">
                            <v-icon size="25" @click="viewTestSyllabus(item)" v-bind="props">
                              mdi-information-variant-circle-outline
                            </v-icon>
                          </template>
                        </v-tooltip>
                      </span>
                    </v-row>

                  </v-card-title>

                </v-card-item>

                <v-card-text class="t-subtitle">
                  <v-row class="align-center">
                    <v-col cols="12" v-if="item.subjects != null">
                      {{ item.subjects.toString() }}
                    </v-col>
                  </v-row>
                  <v-row class="align-center" justify="start">

                    <v-col cols="12" lg="3">
                      <v-row justify="start" class="t-subtitle">
                        <v-col> {{ item.questionsCount }} questions</v-col>
                        <v-col>
                          <v-icon>mdi-clock-outline</v-icon>
                          {{ item.testDurationInMinutes }} mins</v-col>
                      </v-row>
                    </v-col>

                    <v-col class="t-subtitle" cols="12" lg="3" offset="0">Starting: {{
                      getDateTimeFromTimeStamp(item.startTimeStamp
                      ) }}</v-col>
                    <v-col cols="12" lg="5">

                      <v-row justify="start" justify-lg="end">

                        <v-col v-if="testType === 'attempted'" class="mt-1 ma-md-0 pa-md-0 text-left text-lg-right"
                          cols="12" sm="6" md="6" lg="5" xl="3">

                          <v-btn class="btn-rd t-btn text-none" rounded @click=" viewTestReport(item)" elevation="0"
                            color="secondary">
                            View reports
                          </v-btn>


                        </v-col>
                        <v-col v-if="testType != 'upcoming'" class="mt-1 ma-md-0 pa-md-0 text-left text-lg-right"
                          cols="12" sm="6" md="6" lg="5" xl="3">

                          <v-btn class="btn-rd t-btn text-none" @click="startTest(item)" color="primary"
                            elevation="0">
                            {{ startTestText(item) }}


                          </v-btn>


                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </div>

      <div v-if="currentParentType == 'reports'" class="mx-4">
        <v-row>
          <v-col class="t-h5" cols="auto">
            {{ currentTest.testName }} | Reports
          </v-col>
        </v-row>


        <div v-if="reportSuccess == false && reportError != null && reportError.length > 0" class=" div-center t-h5Reg">
          <v-row class="align-center" justify="center">
            <v-col cols="12">

              <v-container>
                {{ reportError }}

              </v-container>
            </v-col>
          </v-row>

        </div>
        <v-row v-for="item in testReports" :key="item">
          <v-col cols="12">
            <v-hover v-slot="{ isHovering, props }">

              <v-card elevation=0 :class="{ 'on-hover': isHovering }" class="px-4 py-1 testCard" v-bind="props">

                <v-card-text class="">
                  <v-row dense justify="space-between" class="align-center">
                    <v-col cols="12" xs="12" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0 mb-2">
                        <v-col class="t-h6Reg pa-0 mr-2" cols="auto">
                          Attempted Date
                        </v-col>



                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ getDateFromTimeStamp(item.startTimeStamp) }}
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          Status
                        </v-col>


                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.status }}
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          Percentile
                        </v-col>


                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.percentile }}
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          No. of Question
                        </v-col>


                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.totalQuestion }}
                        </v-col>
                      </v-row>
                    </v-col>




                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          Attempted
                        </v-col>



                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.attemptedQuestions }}
                        </v-col>
                      </v-row>
                    </v-col>






                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          Time Spent
                        </v-col>



                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.timespentInMinutes }} Mins
                        </v-col>
                      </v-row>
                    </v-col>


                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          <span v-if="item.mode == 'Test'">
                            Rank
                          </span>
                          <span v-else>
                            Reference Rank
                          </span>
                        </v-col>


                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.rank ?? '--' }}
                        </v-col>
                      </v-row>
                    </v-col>


                    <v-col cols="6" xs="6" sm="6" md="5" lg="4" xl="1">
                      <v-row dense class="align-center ma-0 pa-0  mb-2">
                        <v-col class="t-h6Reg ma-0 pa-0 mr-2" cols="auto">
                          Total Marks
                        </v-col>


                      </v-row>

                      <v-row dense class="ma-0 pa-0">
                        <v-col class="t-h6 ma-0 pa-0" cols="auto">
                          {{ item.marks }}/{{ item.totalTestMarks }}
                        </v-col>
                      </v-row>
                    </v-col>



                    <v-col class="mt-3 mt-mt-lg-0" cols="12" xs="12" sm="6" md="5" lg="4" xl="1">
                      <v-btn class="btn-rd t-btn text-none mt" @click="viewSolution(item)" color="primary"
                        elevation="0">
                        View solutions
                      </v-btn>
                    </v-col>



                  </v-row>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>


      </div>
    </v-container>
  </v-container>
</template>

<script>
import _ from "lodash";

import { mapState } from "vuex";
import HtmlRender from "@/components/Test/HtmlRender";
import moment from 'moment'
import { setLocalForageItem } from "@/services/localforageService";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
// import NavigationDrawer from "../appNavigation/NavigationDrawer.vue";
// import AppBar from "../appNavigation/AppBar.vue";

export default {
  name: 'TestListPage',
  async beforeRouteUpdate(to, from) {
    // react to route changes...
    console.log("route changed to", to);
    console.log("route changed from", from);

    const name = to.name
    if (name == "tests") {

      let collectionId = to.params.collectionId
      let type = to.params.type
      this.tab = to.params.type

      await this.getTestsFromServer(collectionId, type)

      this.addTestCollectionNode()

      this.addTestNode(this.currentCollection)
    }

    if (name == "reports") {
      // let collectionId = to.params.collectionId
      // let testId = to.params.testId

      await this.getTestReportsFromServer(this.currentTest)
      this.addTestCollectionNode()
      this.addTestNode(this.currentCollection)

      this.addReportNode(this.currentTest)
    }
    if (name == "collections") {
      recordAnalyticsEvent(AnalyticsEvents.v2TestSeriesPageOpen);

      await this.getCollectionsFromServer()
      this.addTestCollectionNode()
    }
  },
  async created() {
    console.log("created test series page");
    const to = this.$route
    console.log("route changed", to);
    this.$store.commit('increamentLoader')
    this.showData = true

    const name = to.name
    if (name == "tests") {

      let collectionId = to.params.collectionId
      let type = to.params.type
      this.tab = to.params.type
      await this.getCollectionsFromServer()
      this.addTestCollectionNode()

      await this.getTestsFromServer(collectionId, type)
      this.currentCollection = this.getCollectionFromCollectionsList(collectionId)

      this.addTestNode(this.currentCollection)
    }

    if (name == "reports") {
      // let collectionId = to.params.collectionId
      // let testId = to.params.testId
      let collectionId = to.params.collectionId
      let type = to.params.type
      this.tab = to.params.type
      let testId = to.params.testId
      await this.getCollectionsFromServer()
      this.addTestCollectionNode()
      this.currentCollection = this.getCollectionFromCollectionsList(collectionId)

      await this.getTestsFromServer(collectionId, type)

      this.addTestNode(this.currentCollection)
      this.currentTest = this.getTestsFromTestsList(testId, type)
      await this.getTestReportsFromServer(this.currentTest)
      this.addTestCollectionNode()
      this.addTestNode(this.currentCollection)
      this.addReportNode(this.currentTest)
    }
    if (name == "collections") {
      recordAnalyticsEvent(AnalyticsEvents.v2TestSeriesPageOpen);

      await this.getCollectionsFromServer()
      this.addTestCollectionNode()
    }
    this.$store.commit('decreamentLoader')
    this.showData = false

  },
  data: () => ({
    tab: 0,
    currentTab: {},
    syllabusDialog: false,
    currentParentNodeId: null,
    currentParentType: null,
    group: null,
    currentTests: [],
    currentTest: {},
    testType: "",
    testNodesBreadcrumbs: [],
    testReports: [],
    currentCollectionId: "",
    currentCollection: {},
    reportError: "",
    showData: false,
    reportSuccess: false,
  }),
  components:
  {
    HtmlRender,
    // NavigationDrawer,
    // AppBar
  },
  computed: {
    ...mapState(["userCurrentDomain", "testSeriesState"]),
    testNodes: {
      get() {

        if (this.currentParentType == "testCollections") {
          return this.userTestCollections
        }
        if (this.currentParentType == "tests") {
          return this.currentTests
        }
        return []
      }
    },
    userTestCollections: {
      get() {
        let data = this.$store.state.userTestCollections;
        data = data.map(e => ({
          ...e,
        }))
        data = _.orderBy(data, ['order'], ['desc']);
        return data;
      },
    },
    tests: {
      get() {
        let data = this.$store.state.tests;

        return data;
      },
    },


  },
  watch: {
    userCurrentDomain() {
      if (this.userCurrentDomain) {
        this.currentParentNodeId = this.userCurrentDomain;
        this.$store.commit('userTestCollectionsLoadedFromServer', false)
        this.$store.dispatch('loadUserTestCollections', {}).then(() => {

        })
        this.testNodesBreadcrumbs = [];

        this.testNodesBreadcrumbs.push(
          {
            title: "Test Series",
            disabled: false,
            type: "testCollections",
            parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
          });
        this.currentParentType = "testCollections"
      }
    },
    testSeriesState: {
      handler(val) {
        if (val.clear) {
          this.currentParentNodeId = this.userCurrentDomain;
          this.$store.commit('userTestCollectionsLoadedFromServer', false)
          this.$store.dispatch('loadUserTestCollections', {}).then(() => {

          })
          this.testNodesBreadcrumbs = [];

          this.testNodesBreadcrumbs.push(
            {
              title: "Test Series",
              disabled: false,
              type: "testCollections",
              parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
            });
          this.currentParentType = "testCollections"
        }
      },
      deep: true
    }

  },
  methods: {
    getTestsFromTestsList(testId, index) {
      console.log(this.tests);
      let tests = this.tests[index].tests
      let item = tests.find((e) => e.testId == testId)
      console.log(item);
      return item
    },
    getCollectionFromCollectionsList(collectionId) {
      // console.log(this.userTestCollections);
      let item = this.userTestCollections.find((e) => e.collectionId == collectionId)
      // console.log(item);
      return item
    },
    addTestCollectionNode() {
      this.testNodesBreadcrumbs = [];

      this.testNodesBreadcrumbs.push(
        {
          title: "Test Series",
          disabled: false,
          type: "testCollections",
          parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
          pageRoute: `/testseries`

        });
      this.currentParentType = "testCollections"
    },
    addTestNode(item) {
      this.currentParentNodeId = item.collectionId;

      this.testNodesBreadcrumbs.push(
        {
          title: item.collectionName,
          disabled: false,
          parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
          type: "tests",
          pageRoute: `/testseries/${item.collectionId}/${this.tab}`
        });
      this.currentParentType = "tests"
    },
    addReportNode(item) {
      this.currentParentNodeId = item.testId;

      this.testNodesBreadcrumbs.push(
        {
          title: item.testName,
          disabled: false,
          parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
          type: "reports",
          pageRoute: `/testseries/${this.currentCollectionId}/${this.tab}/${item.testId}`

        });
      this.currentParentType = "reports"
    },
    async getCollectionsFromServer() {
      if (this.userCurrentDomain) {
        this.currentParentNodeId = this.userCurrentDomain;
        // this.$store.commit('userTestCollectionsLoadedFromServer', false)
        await this.$store.dispatch('loadUserTestCollections', {})

      }

    },
    viewTestSyllabus(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2TestListTestDetailOpen);

      this.currentTest = item
      this.syllabusDialog = true
    },

    getTestsOnTabClick(index, item) {
      this.currentTab = item
      this.$router.replace(`/testseries/${this.currentCollectionId}/${index}`)

    },
    // getTestsOnTabClickFromServer(index, item) {

    //   this.$store.commit('testsLoadedFromServer', false)
    //   this.$store.dispatch('loadTests', {
    //     collectionId: this.currentCollectionId
    //   }).then(() => {

    //     this.testType = item.testStatusGroupName
    //     this.currentTests = this.tests[index].tests
    //   })



    // },
    async getTestsFromServer(collectionId, index) {
      this.currentCollectionId = collectionId
      await setLocalForageItem("collectionId", collectionId)

      this.$store.commit('testsLoadedFromServer', false)
      await this.$store.dispatch('loadTests', {
        collectionId: collectionId
      }).then(() => {
        // console.log(this.tests);
        // console.log(index);
        this.currentTests = this.tests[index].tests
        this.testType = this.tests[index].testStatusGroupName
        recordAnalyticsEvent(AnalyticsEvents.v2TestStatusGroupOpen, {
          testseriesGroupName: this.testType,
          testseriesCollectionId: this.currentCollection.collectionId,
          testseriesName: this.currentCollection.collectionName
        });

      })

    },

    startTest(item) {
      if (item.isPractice == true) {
        recordAnalyticsEvent(AnalyticsEvents.v2TestListStartPracticeTest, { testId: item.testId, testName: item.testName });

      }
      else if (item.alreadyInProgress == true) {
        recordAnalyticsEvent(AnalyticsEvents.v2TestListResumeTest, { testId: item.testId, testName: item.testName });
      }
      else {
        recordAnalyticsEvent(AnalyticsEvents.v2TestListStartTest, { testId: item.testId, testName: item.testName });

      }

      let body = _.cloneDeep(item)
      if (this.testType == 'attempted' || this.testType == 'missed') {
        body['isPractice'] = true
      }
      this.$store.commit("updateSelectedTest", body)
      this.$router.push(`/starttest/${item.testId}?testType=${item.isPractice ? 'practice' : 'test'}`)
    },
    startTestText(item) {
      let text = "Start test"
      if (item.isPractice == true) {
        text = 'Practice test'
      }
      if (item.alreadyInProgress == true) {
        text = "Resume test"
      }
      return text
    },
    async getTestReportsFromServer(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2TestListTestReportsOpen, { testId: item.testId, testName: item.testName });

      this.reportError = ""
      this.$store.commit("clearTestReports")
      this.$store.commit("testReportsLoadedFromServer", false)
      await this.getTestReport(item)
      let report = this.$store.state.testReports
      this.testReports = report.report ?? []
      this.reportSuccess = report.success ?? false
      let resultAvailable = report.resultAvailable
      let resultTestTime = report.resultTimestamp
      if (this.reportSuccess == false) {
        if (resultAvailable == false) {
          this.reportError = `Your Result will be declared on ${this.getDateTimeFromTimeStamp(resultTestTime)}`
        }
        else {
          this.reportError = this.$store.state.testReports.errorMessage
        }
      }
    },
    viewTestReport(item) {
      this.currentTest = item

      this.$router.push(`/testseries/${this.currentCollectionId}/${this.tab}/${item.testId}`)

    },
    async getTestReport(item) {
      // console.log("report");
      this.$store.commit("testReportsLoadedFromServer", false)
      await this.$store.dispatch("loadTestReports", item)

    },
    back() {
      this.removeLastItemFromBreadcrumbsandUpdateNewParentNodeId();
    },
    getDateTimeFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM  YYYY	hh:mm A	");
      return dateString

    },

    getDateFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM  YYYY");
      return dateString

    },
    updateNewParentNodeId(item) {

      let parentNodeIdKeyinChildNodesMap = '';
      if (this.currentParentType == 'testCollections') {
        parentNodeIdKeyinChildNodesMap = this.userCurrentDomain;
      }
      else if (this.currentParentType == "tests") {
        parentNodeIdKeyinChildNodesMap = item.collectionId;
      } else if (this.currentParentType == 'reports') {
        parentNodeIdKeyinChildNodesMap = item.testId;
      }

      this.currentParentNodeId = parentNodeIdKeyinChildNodesMap;
    },
    addNewItemToBreadcrummbs(item) {
      if (this.currentParentType == "tests") {
        this.testNodesBreadcrumbs.push(
          {
            title: item.collectionName,
            disabled: false,
            parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
            type: "tests"
          });
      }
      if (this.currentParentType == "reports") {
        this.testNodesBreadcrumbs.push(
          {
            title: item.testName,
            disabled: false,
            parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
            type: "reports"
          });
      }

    },
    testNodeClicked(item) {
      this.currentCollection = item
      recordAnalyticsEvent(AnalyticsEvents.v2TestSeriesNodeOpen, { testseriesCollectionId: item.collectionId, testseriesName: item.collectionName });

      this.$router.push(`/testseries/${item.collectionId}/${0}`)
    },
    updateBreadCrumbOnTestCollectionNodeClicked(item) {
      if (this.currentParentType == "testCollections") {

        this.currentParentType = "tests"

        this.updateNewParentNodeId(item);
        this.addNewItemToBreadcrummbs(item);
      }
    },
    removeLastItemFromBreadcrumbsandUpdateNewParentNodeId() {

      let page = this.testNodesBreadcrumbs[this.testNodesBreadcrumbs.length - 2].pageRoute
      this.$router.replace(page)
      // this.testNodesBreadcrumbs.pop();

      // if (this.testNodesBreadcrumbs.length > 0) {

      //   this.currentParentNodeId = this.testNodesBreadcrumbs[this.testNodesBreadcrumbs.length - 1].parentNodeIdKeyinChildNodesMap;
      //   this.currentParentType = this.testNodesBreadcrumbs[this.testNodesBreadcrumbs.length - 1].type;


      // } else {
      //   this.currentParentNodeId = this.userCurrentDomain;
      //   this.currentParentType = "testCollections"
      // }

    },
    getTestReportDuration(item) {
      let duration = item.completedTimeStamp - item.startTimeStamp
      duration = Math.round((duration / 1000) / 60);
      return duration
    },
    viewSolution(item) {
      this.$router.push(`/testresult/${item.testId}?instance=${item.testInstanceId}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.active {
  background: rgb(var(--v-theme-primary)) !important;
  color: white;

}

.non-active {
  background: #F3F2F3;

}

.v-tab {
  text-transform: none !important;
  text-transform: capitalize !important;
}

.nav-margin {
  margin-left: 0;
}


v-tab:hover {
  background-color: transparent !important;
  background-color: green !important;
  ;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.v-card.on-hover {
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}


.bx {
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #E7E7E7;
  border-radius: 4px;
}



.testCard {
  box-shadow: 0px 2px 16px rgba(15, 27, 38, 0.1);
  border-radius: 12px;
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #E7E7E7;
}

.div-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100vw;
  text-align: center;
}

.btn-rd {
  border-radius: 8px;
}

.htmlClass {
  color: black;
  font-family: 'Inter', sans-serif;
}

.testTabSelected {
  font-family: 'Inter', sans-serif !important;
  color: white !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;

}

.testTab {
  font-family: 'Inter', sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #323232 !important;
}

.subject-chip {
  font-family: 'Inter', sans-serif !important;
  font-size: 0.65rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: black !important;

}
</style>
