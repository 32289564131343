<template>
  <v-container fluid class="dialog-conatiner">
    <Vue3DraggableResizable
      class="zIndex"
      :draggable="draggable"
      :resizable="false"
      v-model:x="feedbackDimension.x"
      v-model:y="feedbackDimension.y"
    >
      <FeedbackComponent
        @closeDialog="closeDialog"
        :test-type="testType"
        :q-id="qId"
        :test-id="testId"
        :test-instance-id="testInstanceId"
        :q-obj="qObj"
        :isAIQuestion="isAIQuestion"
        @draggableStart="draggableStart"
        @draggableEnd="draggableEnd"
      ></FeedbackComponent>
    </Vue3DraggableResizable>
  </v-container>
</template>

<script>
import FeedbackComponent from "@/components/Test/TestQuestionPageComponents/FeedbackComponent.vue";
import { useDisplay } from "vuetify";
import Vue3DraggableResizable from "vue3-draggable-resizable";
//default styles
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";

export default {
  name: "DragableFeedbackDialog",
  setup() {
    // Destructure only the keys we want to use
    const { width } = useDisplay();
    return { width };
  },
  data: () => ({
    draggable: false,
    feedbackDimension: {
      x: 50,
      y: 24,
    },
  }),
  created() {
    this.feedbackDimension = {
      x: this.width * 0.5 > 400 ? this.width * 0.5 - 400 : 0,
      y: 90,
    };
  },
  components: {
    FeedbackComponent,
    Vue3DraggableResizable,
  },
  props: {
    qId: String,
    testType: String,
    testId: String,
    testInstanceId: String,
    qObj: Object,
    isAIQuestion: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    draggableStart(){
      this.draggable = true;
    },
    draggableEnd(){
      this.draggable = false;
    },
  },
};
</script>

<style scoped>
.dialog-conatiner {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  margin: 0px !important;
  height: 100vh;
  width: 100vw;
}
.zIndex {
  z-index: 2000;
  max-width: 820px !important;
  max-height: calc(100vh - 48px) !important;
}
.zIndex > .v-card {
  max-height: inherit;
  --v-scrollbar-offset: 0px;
  border-radius: 4px;
  overflow-y: auto;
  box-shadow: 0 11px 15px -7px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0 24px 38px 3px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0 9px 46px 8px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}
</style>
