<template>
  <v-layout class="overflow-visible" style="height: 56px;">
    <v-bottom-navigation active>
      <v-btn @click="prevPage()"><v-icon icon="mdi-arrow-up"></v-icon></v-btn>
      <v-btn @click="nextPage()"><v-icon icon="mdi-arrow-down"></v-icon></v-btn>
    </v-bottom-navigation>
  </v-layout>
  <div>
    <div ref="pageContainer" id="pageContainer" height="460" width="800">
      <div ref="viewer" class="pdfViewer"></div>
    </div>
  </div>
</template>
  
<script>
import * as pdfjsLib from "pdfjs-dist";
import { PDFViewer, EventBus } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";

let pdfViewerGlobalInsatnce = null;

export default {
  name: 'PDFJSViewer',
  props: {
    fileUrl: String
  },
  created() {


  },
  mounted() {
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/build/pdf.worker.min.js";


    /*console.log(`inside mounted ##!`);
    import("pdfjs-dist/build/pdf.min").then((pdfjsLib) => {
      console.log(`inside import("pdfjs-dist") ##2`);
      let pdfFile = 'https://vid.devtest.thestudypod.com/app-pdfs/2023-02-09/1675958090071_OSENDSEM21928168.pdf';
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/build/pdf.worker.min.js";
      pdfjsLib.getDocument({
        url: pdfFile,
        withCredentials: true,
        httpHeaders: {
        }
      }).promise.then(function (pdf) {
        PDFViewer.open(pdf);
        pdf.getPage(1).then((page) => {
          console.log(page);
        });
      });
    });*/
    this.getPdf();
  },
  data: () => ({
    pdfjsPath: 'lib/pdfjs-3.4.120-dist/web/viewer.html',
    pdfViewer: '',
  }),

  computed: {
    getFilePath: {
      get() {
        //console.log(`inside PDFJSViewer | getFilePath:`);
        //console.log(`this.pdfjsPath: ${this.pdfjsPath}`);
        //console.log(`this.fileUrl: ${this.fileUrl}`);
        if (this.fileUrl !== '') {
          return this.pdfjsPath + '?file=' + this.fileUrl
        }
        return this.pdfjsPath
      }
    }
  },

  watch: {

  },

  methods: {
    async getPdf() {
      //let container = document.getElementById("pageContainer");
      let container = this.$refs.pageContainer;
      const eventBus = new EventBus();
      //let pdfViewer = new PDFViewer({
      pdfViewerGlobalInsatnce = new PDFViewer({
        //this.pdfViewer = new PDFViewer({
        container: container,
        eventBus: eventBus,
        downloadManager: null,
      });
      let pdfFile = this.fileUrl;
      //console.log(`pdf File to download url: ${pdfFile}`);
      //console.log(`this.pdfViewer befroe promise: ${pdfViewerGlobalInsatnce}`);
      //let pdf = await pdfjsLib.getDocument(this.docPath);
      //let pdf = await pdfjsLib.getDocument({;
      //const self = this;
      pdfjsLib.getDocument({
        url: pdfFile,
        withCredentials: true,
        httpHeaders: {
        }
      }).promise.then(function (pdf) {
        //pdfViewer.setDocument(pdf);

        //        myapp.open(pdf);
        //console.log(`this.pdfViewer inside promise: ${pdfViewerGlobalInsatnce}`);

        //pdfViewer.setDocument(pdf);
        pdfViewerGlobalInsatnce.setDocument(pdf);
        //self.pdfViewer.setDocument(pdf);
        //PDFViewer.open(pdf);
        //pdf.getPage(1).then((page) => {
        //  console.log(page);
      });

    },
    nextPage() {
      pdfViewerGlobalInsatnce.nextPage();
    },
    prevPage() {
      pdfViewerGlobalInsatnce.previousPage();
    },
  }
}
</script>
  
<style scoped>
#pageContainer {
  position: absolute;
  margin: auto;
  height: "560px";
  width: "800px";
  overflow: scroll;
}

div {
  width: 50%;
  height: 460px;
  /*79vh;*/
  min-width: 700px;
  /*400px;*/
}
</style>
  