<template>
  <v-overlay persistent :model-value="downloadingTest" class="align-center justify-center">

    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="text-h6 font-weight-bold text-center text-white" cols="12">
        Preparing Next Module
      </v-col>
      <v-col cols="12">
        <v-progress-linear color="deep-purple-accent-4" indeterminate rounded height="6"
          :style="{ width: $vuetify.display.xs ? '250px' : '500px' }"></v-progress-linear>
      </v-col>
    </v-row>

  </v-overlay>
  <v-layout class="mb-0 pb-0" :class="{ layoutLarge: true }">
    <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">
      <v-row v-if="!downloadingTest && waitingTimeInSeconds > 0" class="mt-0 pt-0">
        <v-col cols="12" md="6" class="d-flex align-center justify-center flex-column">
          <v-row class="flex-column justify-center align-center">
            <v-card class="waitingCard">
              <v-card-text>
                <v-row>
                  <v-col cols="12 t-1remReg text-center">
                    Remaining Break Time:
                  </v-col>
                  <v-col cols="12" class="t-h1 text-center">
                    {{ getRemainingTime }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn class="t-btn text-white text-none activity-btn mt-5" size="default" variant="flat"
              @click="resumeTest()">Resume Testing</v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="text-white d-flex align-start justify-center flex-column">
          <v-row class="w-50 flex-column justify-center" :class="$vuetify.display.mdAndUp ? 'w-75' : 'w-100 my-2 pl-3'">
            <div class="mb-5 t-h4">Practice Test Break</div>
            <div class="mb-6 t-h6Reg">
              You can resume this practice test as soon as you're ready to move
              on. On test day, you'll wait until the clock counts down. Read
              below to see how breaks work on test day.
            </div>
            <v-divider class="mb-7"></v-divider>
            <div class="mb-5 t-h4">Take a Break: Do Not Close Your Device</div>
            <div class="mb-6 t-h6Reg">
              You may leave the room, but do not disturb students who are still
              testing.
            </div>
            <div class="mb-6 t-h6Reg">
              Testing won't resume until you return.
            </div>
            <div class="mb-4 t-h5">Follow these rules during the break:</div>
            <ol class="ml-7">
              <li class="mb-4 t-h6Reg">
                Do not access your phone, smartwatch, textbooks, notes, or the
                internet.
              </li>
              <li class="mb-4 t-h6Reg">
                Do not eat or drink in the test room.
              </li>
              <li class="mb-4 t-h6Reg">
                Do not speak in the test room; outside the test room, do not
                discuss the exam with anyone.
              </li>
            </ol>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import axios from "axios";
import {
  getLocalForageItem,
  setLocalForageItem,
} from "@/services/localforageService";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
export default {
  data: () => ({
    waitingTimeInSeconds: 0,
    downloadingTest: false,
    intervalId: null,
    currentTest: {
      moduleUrl: "",
      testmetadata: {
        allowSkippingQuestion: null,
        randomizedQuestionOrder: null,
        testDurationInMinutes: null,
        testRemainingDurationInMinutes: null,
        totalQuestion: null,
        instructions: "",
        syllabus: "",
        sections: [],
      },
    },
  }),
  async created() {
    console.log("testabcde");
    recordAnalyticsEvent(AnalyticsEvents.v2MockTestModuleBreakScreenOpen, {
      testId: this.currentTestId,
      testInstanceId: this.startTestInfo.testInstanceId
    });
    if (_.get(this.submissionInfo, "nextModuleExists", false)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const nextModuleInfo = _.get(this.submissionInfo, "nextModuleInfo", {});
      if (_.get(nextModuleInfo, "waitForNextModuleToStart", false)) {
        this.waitingTimeInSeconds = _.get(
          nextModuleInfo,
          "waitingTimeInSeconds",
          0
        );
        this.startTimer();
      } else {
        this.resumeTest();
      }
    } else {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSubmission, {
        testId: this.currentTestId,
        testInstanceId: this.startTestInfo.testInstanceId
      });
      await this.$store.dispatch("submitTestModule", { testSubmission: true });
      console.log("abcde", this.$store.state.assignmentId);
      if (this.$store.state.assignmentId.length > 0) {
        if (this.$store.state.assignmentId.length > 0) {
          recordAnalyticsEvent(AnalyticsEvents.v2AssignmentEnd, {
            testId: this.currentTestId,
            testInstanceId: this.startTestInfo.testInstanceId,
            assignmentId: this.$store.state.assignmentId
          });
        }
        this.$store.commit('updateCurrentAssignmentId', '')
        if (this.showStudentAssignmentSolution) {
          this.$store.commit('decreaseIncompleteAssignmentCount');
          this.$router.replace(`action/digisatresult/${this.currentTestId}/${this.startTestInfo.testInstanceId}?assignment=true`);
        } else {
          this.$store.commit('showStudentAssignmentSolution', true);
          this.$router.replace('action');
        }
        return
      }
      this.$router.replace(`mocktest/digisatresult/${this.currentTestId}/${this.startTestInfo.testInstanceId}`);
    }
  },
  watch: {
    waitingTimeInSeconds(val) {
      if (val == 600) {
        this.warningTimeLeft = 10;
      }
      if (val == 120) {
        this.warningTimeLeft = 2;
      }
      if (val == 0) {
        clearInterval(this.intervalId);
        // window.alert("time over")
        this.intervalId = null;
        // this.updateNextQDurationAndSendLastQToServer()
        //this.submitTest()
        this.resumeTest();
      }
    },
  },
  computed: {
    ...mapState([
      "submissionInfo",
      "selectedTest",
      "currentTestId",
      "startTestInfo",
      "showStudentAssignmentSolution"
    ]),
    getRemainingTime: {
      get() {
        if (this.intervalId == null) {
          return "";
        }
        const res = this.toHoursAndMinutes(this.waitingTimeInSeconds);
        console.log("getRemainingTime", res);
        return `${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`;
      },
    },
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        this.waitingTimeInSeconds = this.waitingTimeInSeconds - 1;
      }, 1000);
    },
    minTwoDigits(n) {
      return (n < 10 ? "0" : "") + n;
    },
    toHoursAndMinutes(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);

      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return { h: parseInt(hours), m: parseInt(minutes), s: parseInt(seconds) };
    },
    async resumeTest() {

      this.$store.commit("startTestLoadedFromServer", false);
      const nextModuleInfo = _.get(this.submissionInfo, "nextModuleInfo", {});
      let selectedTestBody = _.cloneDeep(this.selectedTest);
      selectedTestBody["moduleId"] = _.get(nextModuleInfo, "moduleId", null);
      this.$store.commit("updateSelectedTest", selectedTestBody);
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestModuleBreakScreenResumeButtonClicked, {
        testId: this.currentTestId,
        testInstanceId: this.startTestInfo.testInstanceId,
        nextModuleId: nextModuleInfo.moduleId
      });
      this.downloadingTest = true;
      let qResponses = (await getLocalForageItem("qResponses")) ?? [];
      let qDurations = (await getLocalForageItem("qDurations")) ?? [];
      let serverLastAttemptSavedTimeStamp =
        (await getLocalForageItem("serverLastAttemptSavedTimeStamp")) ?? "";
      let body = {
        qDurations: qDurations,
        qResponses: qResponses,
        serverLastAttemptSavedTimeStamp: serverLastAttemptSavedTimeStamp,
        testInstanceId: this.startTestInfo.testInstanceId,
      };

      await this.$store.dispatch("startTestModule", {
        testId: this.currentTestId,
        moduleId: _.get(nextModuleInfo, "moduleId", null),
        ...body,
      });
      console.log("startTestModule....");
      this.currentTest = this.startTestInfo;
      if (this.currentTest.success == false) {
        this.downloadingTest = false;
        console.log("downloading....");
      } else {
        const testJson = await axios.get(this.currentTest.moduleUrl);
        console.log(testJson.data);
        this.$store.commit("setTestJson", testJson.data);
        this.$store.commit("updateQResponsesState");

        setLocalForageItem("qDurations", []);
        setLocalForageItem("qResponses", []);
        setLocalForageItem("serverLastAttemptSavedTimeStamp", "");

        // this.startButtonDisabled = false;
        this.$store
          .dispatch("updateModuleSyncTimer", { state: "start" })
          .then(() => {
            this.$store.commit("updateCurrentTestState", {
              sectionIndex: 0,
              qIndex: 0,
            });
            this.$store.commit("updateTestRoutingAllowed", true);
            this.$router.replace(`/testmodulequestionpage`);
            // this.downloadingTest = false;

          });
      }
      // }
    },
  },
};
</script>

<style scoped>
.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.waitingCard {
  height: 120px;
  width: 220px;
  border-radius: 12px;
  background-color: rgb(var(--v-theme-cardBackgroundColor));
  color: white;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.layoutLarge {
  height: 100%;
}

.t-h1 {
  font-family: "Inter", sans-serif !important;
  font-size: 2.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}
</style>
