<template>
  <!-- Dialog for add/update child -->
  <v-dialog
    style="backdrop-filter: blur(12.5px)"
    persistent
    :model-value="dialog"
    width="900"
  >
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar
                size="30"
                color="report-subCardBg"
                class="icon-bg ma-0 pa-0 mr-2"
              >
                <v-icon
                  icon="mdi-account"
                  color="#ffffff"
                  class=""
                  size="20"
                ></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle() }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 student-form-main">
        <v-form ref="form" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="editedItem.name"
                label="Name*"
                density="compact"
                variant="outlined"
                :rules="requiredRule"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="">
            <v-col cols="auto" class="pt-3 pb-0">
              <span>Login Type*: </span>
            </v-col>
            <v-col class="">
              <v-radio-group
                v-model="editedItem.loginType"
                inline
                dense
                class=""
                :rules="requiredRule"
              >
                <v-radio label="Email" value="email"></v-radio>
                <v-radio label="Phone" value="phone"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.email"
                :label="`Email ${editedItem.loginType == 'email' ? '*' : ''}`"
                density="compact"
                variant="outlined"
                :rules="
                  editedItem.loginType == 'email'
                    ? rules.email
                    : editedItem.email.length > 0
                    ? rules.email
                    : []
                "
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-0" dense>
            <v-col cols="12">
              <tel-input
                v-model="displayChildPhoneNumber"
                @updatedPhoneNumberObject="updateChildPhoneNumber"
                :placeholder="`Child Phone Number ${
                  editedItem.loginType == 'phone' ? '*' : ''
                }`"
                :required="editedItem.loginType == 'phone'"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.notes"
                label="Notes"
                density="compact"
                variant="outlined"
                rows="2"
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">

        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="close"
          >Cancel</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="save"
          >{{ editedIndex == -1 ? "Save" : "Update" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container fluid class="ma-0 pa-4 main-lg">
    <v-container fluid class="container-width" v-if="studentsDetailLoaded">
      <v-row class="mb-6 mx-0">
        <v-col cols="12" class="pa-0">
          <v-card class="title-card text-white" variant="flat">
            <v-card-text class="py-0">
              <v-row align="center" justify="space-between">
                <v-col cols="auto" class="heading">
                  {{ students.length > 1 ? "Children" : "Child" }}
                  <span v-if="studentList.length > 0">
                    ({{ students.length }})</span
                  >
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    class="t-btn text-white activity-btn text-none t-1remReg"
                    density="default"
                    variant="flat"
                    height="40"
                    @click="createNewChild"
                    >Add Child</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="paginatedStudentsList.length > 0">
        <template v-for="(item, i) in paginatedStudentsList" :key="i">
          <v-card
            class="card-bg card-border py-2 px-2 mb-3"
            @click="openStudentDetail(item)"
          >
            <v-card-text class="text-primaryTextColor pa-0">
              <v-row justify="space-between" align="center" class="ma-0 px-1">
                <!-- name -->
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center justify-start pa-1"
                >
                  <div class="mr-4">
                    <v-avatar
                      size="34"
                      color="report-subCardBg"
                      class="icon-bg"
                    >
                      <v-icon
                        icon="mdi-account"
                        color="#ffffff"
                        class=""
                        size="20"
                      ></v-icon>
                    </v-avatar>
                  </div>
                  <div class="pb-0 wrap-text">
                    <span v-if="item.studentName != 'null'" class="t-1remReg">{{
                      item.name
                    }}</span>
                    <span v-else class="t-1remReg"> - </span>
                  </div>
                </v-col>

                <!-- contact -->
                <v-col cols="4" class="d-flex flex-column align-start pa-1">
                  <div
                    v-if="showPhoneNumber(item.mobileNumber)"
                    class="pb-0 wrap-text"
                  >
                    <span class="t-h6Reg">{{ `${item.mobileCountryCode}${item.mobileNumber}` }}</span>
                  </div>
                  <div v-if="showEmail(item.email)" class="wrap-text">
                    <span class="t-h6Reg">{{ item.email }}</span>
                  </div>
                </v-col>

                <!-- last online -->
                <v-col cols="2" class="d-flex flex-column align-start pa-1">
                  <div class="pb-0">
                    <span class="t-h6Reg">{{
                      getTime(item.lastLoginTimeStamp)
                    }}</span>
                  </div>
                  <div class="">
                    <span class="t-para parameter-style">Last Online</span>
                  </div>
                </v-col>

                <!-- buy license -->
                <v-col v-if="showUpgradeButton(item.packageDetails)" cols="auto" md="2" class="d-flex align-center justify-end pa-1">
                  <v-btn
                    class="t-btn text-white activity-btn text-none t-1remReg"
                    density="default"
                    variant="flat"
                    height="40"
                    @click.stop="openUpgradePackageDialog(item.userId, item.packageDetails)"
                    >Buy License</v-btn
                  >
                </v-col>

                <!-- License -->
                <v-col v-else cols="auto" md="2" class="d-flex flex-column align-start pa-1">
                  <v-tooltip v-if="!item.packageDetails.packageId" location="top" max-width="400px">
                    <template #activator="{ props }">
                      <div class="pb-0" v-bind="props">
                        <span class="t-h6Reg text-capitalize">-</span>
                      </div>
                    </template>
                    <span>Child is not assigned any license</span>
                  </v-tooltip>
                  <div v-else class="pb-0">
                    <span class="t-h6Reg text-capitalize">{{
                     item.packageDetails.name
                    }}</span>
                  </div>
                  <div v-if="item.packageDetails.packageId" class="">
                    <span class="t-h6Reg parameter-style">Ends {{ displayLocalDate(item.packageDetails.packageEndDate)
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </template>

      <v-card
        v-if="students.length <= 0"
        class="card-bg card-border px-2 mb-3 py-2"
        elevation="0"
      >
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <v-col
              cols="12"
              class="d-flex pa-3 align-center justify-center pa-1"
            >
              <div class="pb-0 wrap-text">
                <span class="t-h6Reg">No Child Added</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row v-if="totalPages > 1" class="ma-0" justify="center">
        <v-col cols="auto" class="px-0">
          <v-pagination
            v-model="currentPageNumber"
            :length="totalPages"
            total-visible="5"
            size="small"
            color="white"
            rounded="lg"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-container>

  <v-snackbar v-model="showChildErrorMessage" color="cardBackgroundColor" :timeout="-1" location="top">
    <span class="submit-message-text text-report-red">{{ childErrorMessage }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import TelInput from '@/shared/components/tel-input.vue';
export default {
  name: "ChildrenListPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  components: {TelInput},
  async created() {
    if (!this.isUserParent) {
      this.$router.replace("/learn");
    }
    this.$store.commit("updateStudentName", "");
    this.$store.dispatch("loadChildList", {}).then((res) => {
      this.studentsDetailLoaded = true;
    });
    recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildrenListScreenOpen, {
      courseId: this.courseId,
    });
  },
  data: () => ({
    studentsDetailLoaded: false,
    options: "",
    searchFilter: "",
    sortCategory: null,
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
      { sortName: "Email (A to Z)", sortId: "emailAsc" },
      { sortName: "Email (Z to A)", sortId: "emailDesc" },
      { sortName: "Last Online (Recent)", sortId: "onlineRecent" },
      { sortName: "Last Online (Oldest)", sortId: "onlineOldest" },
    ],
    currentPageNumber: 1,
    itemsPerPage: 100,
    defaultItem: {
      active: true,
      name: "",
      loginType: "email",
      email: "",
      mobileCountryCode: "",
      mobileNumber: "",
      notes: "",
    },
    editedItem: {
      active: true,
      name: "",
      loginType: "email",
      email: "",
      mobileCountryCode: "",
      mobileNumber: "",
      notes: "",
    },
    editedIndex: -1,
    dialog: false,
    formValue: false,
    requiredRule: [(v) => !!v || "Required"],
    rules: {
      email: [(v) => !!(v || "").match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || "Please enter a valid email"],
      phoneNumber: [(v) => !!(v || "").match(/^\d{10}$/) || "Please enter a valid mobile number"],
    },
    displayChildPhoneNumber: "",
    isValidChildPhoneNumber: false,
    showChildErrorMessage: false,
    childErrorMessage: "",
  }),
  methods: {
    getTime(time) {
      if (time == 0) {
        return "-";
      }
      let vardata = moment(time).fromNow();
      return vardata;
    },
    openStudentDetail(item) {
      let studentId = item.userId;
      this.$store.commit("updateStudentId", studentId);
      this.$store.commit("updateStudentName", item.name);
      recordAnalyticsEvent(
        AnalyticsEvents.v2ParentViewChildrenListScreenChildOpen,
        {
          courseId: this.courseId,
          childId: studentId,
        }
      );
      this.$router.push(`${this.$route.fullPath}/${studentId}/1`);
    },
    showPhoneNumber(item) {
      let phoneNumber = item + "";
      return !phoneNumber.includes("null");
    },
    showEmail(item) {
      let email = item + "";
      return !email.includes("null");
    },
    paginatedData(students) {
      const start = (this.currentPageNumber - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return students.slice(start, end);
    },
    /////new methods
    createNewChild() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.displayChildPhoneNumber = "",
      this.editedIndex = -1;
      this.dialog = true;
    },
    formTitle() {
      return "Add child";
    },
    updateChildPhoneNumber(phoneNumberObject){
      this.editedItem.mobileCountryCode = phoneNumberObject.telCountryCode;
      this.editedItem.mobileNumber = phoneNumberObject.telPhoneNumber; 
      this.isValidChildPhoneNumber = phoneNumberObject.telIsValidPhoneNumber;
    },
    async save() {
      this.childErrorMessage = "";
      this.showChildErrorMessage = false;
      const { valid } = await this.$refs.form.validate()
      if((this.editedItem.loginType == 'phone' || this.editedItem.mobileNumber?.length > 0) && !this.isValidChildPhoneNumber){
        return;
      }
      if (valid) {
        let reqData = _.cloneDeep(this.editedItem);
        reqData.courseId = this.courseId;
        
        if (this.editedIndex > -1) {
          // recordAnalyticsEvent(AnalyticsEvents.v2AdminStudentScreenEditButtonClicked, {
          //   courseId: this.courseId,
          //   studentId: this.editedItem.userId
          // });

          // this.$store.dispatch("updateChild", reqData).then((res) => {
          //   if (res.success) {
          //     this.close();
          //   }
          //   else {
          //     this.childErrorMessage = res.message;
          //     this.showChildErrorMessage = true;
          //   }
          // });
        } else {
          this.$store.dispatch("addChild", reqData).then((res) => {
            if (res.success) {
              recordAnalyticsEvent(AnalyticsEvents.v2ParentViewAddChildButtonClicked, {
                courseId: this.courseId,
                childId: res.child?.userId
              });
              this.close();
            } else {
              this.childErrorMessage = res.message;
              this.showChildErrorMessage = true;
            }
          });
        }
      }      
    },
    close() {
      this.dialog = false;
    },
    openUpgradePackageDialog(userId, packageDetails) {
      if(packageDetails?.priceType == 'free'){
        this.$store.commit('updateChildUserId', userId);
        this.$store.commit('openUpgradeDialog', true);
        recordAnalyticsEvent(AnalyticsEvents.v2ParentViewUpgradeChildPackageButtonClicked, {
          courseId: this.courseId,
          childId: userId
        });
      }
      else{
        return;
      }
    },
    showUpgradeButton(packageDetails){
      return packageDetails?.priceType == 'free' ? true : false;
    },
    closeSnackBar() {
      this.childErrorMessage = "";
      this.showChildErrorMessage = false;
    },
    displayLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      date = date * 1000;
      let displayDate = moment(date).format("YYYY-MM-DD");
      return moment(displayDate).format("ll");
    },
  },
  computed: {
    ...mapState([
      "studentList",
      "searchedStudentList",
      "isUserAdmin",
      "isUserTeacher",
      "courseId",
      "isUserParent",
    ]),
    students: {
      get() {
        let students = [];
        if (this.searchFilter.trim() != "") {
          students = this.searchedStudentList;
        } else {
          students = this.studentList;
        }
        switch (this.sortCategory) {
          case "nameAsc":
            students = _.orderBy(
              students,
              [(student) => student?.name?.toLowerCase()],
              "asc"
            );
            break;
          case "nameDesc":
            students = _.orderBy(
              students,
              [(student) => student?.name?.toLowerCase()],
              "desc"
            );
            break;
          case "emailAsc":
            students = _.orderBy(
              students,
              [(student) => student?.email?.toLowerCase()],
              "asc"
            );
            break;
          case "emailDesc":
            students = _.orderBy(
              students,
              [(student) => student?.email?.toLowerCase()],
              "desc"
            );
            break;
          case "onlineRecent":
            students = _.orderBy(
              students,
              [(student) => student?.lastLoginTimeStamp],
              "desc"
            );
            break;
          case "onlineOldest":
            students = _.orderBy(
              students,
              [(student) => student?.lastLoginTimeStamp],
              "asc"
            );
            break;

          default:
            students = _.orderBy(
              students,
              [(student) => student?.name?.toLowerCase()],
              "asc"
            );
        }
        return students;
      },
    },
    totalPages: {
      get() {
        return Math.ceil(this.students.length / this.itemsPerPage);
      },
    },
    paginatedStudentsList: {
      get() {
        return this.paginatedData(this.students);
      },
    },
  },
  watch: {
    options: {
      handler() {
        let itemsPerPage = this.options.itemsPerPage;
        let page = this.options.page;
        let max = itemsPerPage * page;
        let length = this.studentsData.length;
      },
      deep: true,
    },
    sortCategory: {
      handler() {
        this.currentPageNumber = 1;
      },
      deep: true,
    },
    students: {
      handler() {
        this.currentPageNumber = 1;
      },
    },
  },
};
</script>

<style scoped>
.container-width {
  max-width: 1080px;
}

.main-lg {
  padding-top: 100px !important;
}

.card-bg {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;
}
.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.icon-bg {
  background: linear-gradient(90deg, #ab77ff 0%, #585ef3 100%);
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}
.heading {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word; /* or word-break: break-all; */
}

.filter-dropdown {
  width: 150px !important;
}

.title-card {
  background-color: transparent;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.student-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.student-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.student-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

</style>
