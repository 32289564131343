<template>
  <!-- <AppBar :show=true></AppBar>
  <NavigationDrawer :show=true></NavigationDrawer> -->
  <v-container fluid class="bg-background ma-0 pa-4">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">

        <v-breadcrumbs :items="learnNodesBreadcrumbs" class="no-new-line">
          <template v-slot:divider>
            <v-icon icon="mdi-chevron-right"></v-icon>
          </template>
          <template v-slot:prepend>
            <v-btn v-if="learnNodesBreadcrumbs.length > 1" class="ma-0 mr-2" color="secondary" size="x-small"
              @click="back()" icon="mdi-chevron-left">
            </v-btn>
            <v-icon icon="mdi-book-open-variant" color="primary"></v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field elevation="0" v-model="searchText" clearable clear-icon="$close" density="compact"
          variant="underlined" label="Search..." single-line hide-details append-inner-icon="mdi-magnify"
          @click:clear="clearSearch" v-on:input="debounceSearch" class="search-box"></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-container fluid class="mt-4 ma-0 pa-0 mr-5">
      <v-row>
        <v-col cols="12">
          <template v-for="(item, i) in learnNodes" :key="i">
            <v-hover v-slot="{ isHovering, props }">
              <v-sheet elevation=0 :class="{ 'on-hover': isHovering }" class="my-2 pa-0 bx" v-bind="props"
                @click="learnNodeClicked(item, $event)">
                <v-container fluid class="ma-0 px-4 py-2 ">
                  <v-row class="ma-0 pa-0" align="center" justify="center">
                    <v-col class="ma-0 pa-0">
                      <v-avatar rounded="0" size="small">
                        <v-img height="32" v-if="item.nodeImage" :src="getNodeImageURL(item)"></v-img>
                        <v-icon v-else :icon="getPlaceHolderIcon(item)" :color="getPlaceHolderIconColor(item)"></v-icon>
                      </v-avatar>
                      <span class="t-h6Reg ml-4">{{ getNodeName(item) }}</span>
                    </v-col>
                    <v-col cols="1" class="ma-0 pa-0 text-right" v-if="!isVideoPDFOrLink(item)">
                      <v-icon color="primary" icon="mdi-chevron-right"></v-icon>
                    </v-col>
                    <v-col cols="1" v-if="item.allowStudentsToDownload">
                      <v-btn flat icon="mdi-download" @click="downloadPDF(item)"></v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="ml-11 t-subtitle" dense v-if="item.description">
                    <v-col>
                      {{ item.description }}

                    </v-col>
                  </v-row>
                </v-container>

              </v-sheet>
            </v-hover>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-dialog v-model="ytVideoDialog" persistent width="auto">
        <v-card class="pa-1">
          <iframe :width="smAndUp ? 560 : 368" elevation="0" :height="smAndUp ? 315 : 207" :src="playVideoUrl"
            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="ytVideoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="privateVideoDialog" persistent width="auto" ref="pvtVideoDialog">
        <v-card class="pa-1">
          <video id="videopId1" controls ref="privateVideoPlayer" :width="smAndUp ? 560 : 368" elevation="0"
            :height="smAndUp ? 315 : 207"></video>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="privateVideoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="pdfViewerDialog" persistent fullscreen>
        <v-card class="pa-1">
          <!--<PDFJSViewer :fileUrl='pdfFileUrl' />-->
          <!--<PDFVuer :fileUrl='pdfFileUrl' />-->
          <!--<PDFVuerSimple :fileUrl='pdfFileUrl'></PDFVuerSimple>-->
          <PDFJSExpressViewer :fileUrl='pdfFileUrl'></PDFJSExpressViewer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="pdfViewerDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
    
<script>
import { mapState } from "vuex";
import { useDisplay } from 'vuetify';
import _ from "lodash";
// import AppBar from "../appNavigation/AppBar.vue";
// import NavigationDrawer from "../appNavigation/NavigationDrawer.vue";
//import PDFJSViewer from "./PDFJSViewer.vue";
//import PDFVuer from "./PDFVuer.vue";
//import PDFVuerSimple from "./PDFVuerSimple.vue";
import PDFJSExpressViewer from "./PDFJSExpressViewer.vue";
import Hls from 'hls.js';
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: 'LearnPage',
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay()

    return { xs, smAndUp, mdAndUp }
  },

  data: () => ({
    searchText: '',
    filterSearchVal: '',
    learnNodesBreadcrumbs: [
    ],
    currentParentNodeId: null,
    ytVideoDialog: false,
    playVideoUrl: '',
    privateVideoDialog: false,
    pvtVideoDomControl: '',
    hlsInstance: null,
    pdfViewerDialog: false,
    pdfFileUrl: '',
    download: false,
  }),
  components:
  {
    // AppBar,
    // NavigationDrawer,
    //PDFJSViewer,
    //PDFVuer,
    //PDFVuerSimple,
    PDFJSExpressViewer,
  },
  async created() {
    // this.$store.commit('appbarVisible', true);
    // this.$store.commit('navbarVisible', true);
    if (this.userCurrentDomain) {
      this.currentParentNodeId = this.userCurrentDomain;
      this.$store.dispatch("loadLearnHierarchyRootNodes");
      this.learnNodesBreadcrumbs.push(
        {
          title: "Learn",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
        });
      recordAnalyticsEvent(AnalyticsEvents.v2LearnOpen);
    }
  },
  computed: {
    ...mapState(["learnHierarchyNodes", "learnHierarchyChildNodes", "userCurrentDomain", "app_s3privateUrl"]),
    learnNodes: {
      get() {
        let nodesToDisplay = [];
        nodesToDisplay = this.learnHierarchyChildNodes.get(this.currentParentNodeId);

        if (this.filterSearchVal) {
          nodesToDisplay = nodesToDisplay?.filter(
            item =>
              this.getNodeName(item)
                ?.toLowerCase()
                .includes(this.filterSearchVal.toLowerCase())
          );
        }

        // need to filter out practice and quiz collections
        return _.filter(nodesToDisplay,
          function (item) {
            let showNode = false;
            if ((item.nodeType && item.nodeType !== 'COLLECTION')) {
              showNode = true;
            } else if (item.nodeType && item.nodeType == 'COLLECTION'
              && (item.collectionType == 'VIDEO' || item.collectionType == 'PDF')) {
              showNode = true;
            } else if (!item.nodeType && item.contentType == 'COLLECTION'
              && (item.collectionType == 'VIDEO' || item.collectionType == 'PDF')) {
              showNode = true;
            } else if (!item.nodeType && item.contentType != 'COLLECTION') {
              showNode = true;
            }
            return showNode;
          }
        );
      },
    },
  },
  mounted() {
    this.$store.commit('appbarVisible', true);

    this.$store.commit('navbarVisible', true);
    /*this.$watch(
      () => {
        return this.$refs.pvtVideoDialog;
      },
      (val) => {
        //console.log(` |ref watch | ${val}`);
        let pvtVidDialog = this.$refs.pvtVideoDialog;
        //console.log(`video pvtVideoDialog : ${pvtVidDialog}`)
        let video = this.$refs.privateVideoPlayer;
        //console.log(`video domelement : ${video}`)
      }
    );*/
  },
  watch: {
    userCurrentDomain() {
      //console.log(`watch LearnPage.vue | userCurrentDomain changed from ${oldVal} to ${newVal}`);
      if (this.userCurrentDomain) {
        this.currentParentNodeId = this.userCurrentDomain;
        this.$store.dispatch("clearLearnHierarchyNodes").then(() => {
          this.$store.dispatch("loadLearnHierarchyRootNodes");
        });
        this.learnNodesBreadcrumbs = [];
        this.learnNodesBreadcrumbs.push(
          {
            title: "Learn",
            disabled: false,
            parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
          });
      }
    },
    ytVideoDialog(val) {
      if (!val) {
        this.playVideoUrl = "";
      }
    },
    privateVideoDialog(val) {
      if (!val) {
        if (this.pvtVideoDomControl) {
          this.pvtVideoDomControl.pause();
          console.log(`this.pvtVideoDomControl.pause() called`)
          this.hlsInstance.detachMedia();
          console.log(`hlsInstance.destroy() called`)
          this.hlsInstance.destroy();
          console.log(`POST hlsInstance.destroy() call`);
          console.log(`this.hlsInstance = null`);
          this.hlsInstance = null;
          console.log(`POST this.hlsInstance = null`);

        }
      }
    },
    pdfViewerDialog(val) {
      if (!val) {
        this.pdfFileUrl = "";
      }
    }
  },
  methods: {
    isVideoPDFOrLink(item) {
      let isVideoPDFOrLink = false;
      if (this.isVideoContent(item)) {
        isVideoPDFOrLink = true;
      } else if (this.isPDFContent(item)) {
        isVideoPDFOrLink = true;
      } else if (this.isLink(item)) {
        isVideoPDFOrLink = true;
      }
      return isVideoPDFOrLink;
    },
    isVideoContent(item) {
      let isVideoContent = false;
      if (item.videoType) {
        isVideoContent = true;
      } else if (item.contentType == 'VIDEO') {
        isVideoContent = true;
      }
      return isVideoContent;
    },
    isPDFContent(item) {
      let isPdfContent = false;
      if (item.pdfUrl) {
        isPdfContent = true;
      } else if (item.contentType == 'PDF') {
        isPdfContent = true;
      }
      return isPdfContent;
    },
    isLink(item) {
      return (item.nodeType == "LINK");
    },
    getNodeName(item) {
      let nodeName = '';
      if (item.nodeName) {
        nodeName = item.nodeName;
      } else if (item.name) {
        nodeName = item.name;
      } else if (item.contentType == "VIDEO") {
        nodeName = item.video.name;
      } else if (item.contentType == "PDF") {
        nodeName = item.pdf.name || item.pdf.pdfUrl;
      } else if (item.pdfUrl) {
        nodeName = item.pdfUrl;
      }
      return nodeName;
    },
    getNodeImageURL(item) {
      let imageSrc = '';
      if (item.nodeImage) {
        imageSrc = item.nodeImage
      }
      return this.$store.state.app_s3url + imageSrc;
    },
    getPlaceHolderIcon(item) {
      let iconName = 'mdi-folder-outline';
      if (item.nodeType == "FOLDER" || item.nodeType == "SUBFOLDER") {
        iconName = 'mdi-folder-open';
      } else if (item.nodeType == "COLLECTION" && item.collectionType == "VIDEO") {
        iconName = 'mdi-folder-play-outline';
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'VIDEO') {
        iconName = 'mdi-folder-play-outline';
      } else if (item.nodeType == "COLLECTION" && item.collectionType == "PDF") {
        iconName = 'mdi-file-pdf-box';
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'PDF') {
        iconName = 'mdi-file-pdf-box';
      } else if (this.isVideoContent(item)) {
        iconName = 'mdi-video-outline';
      } else if (this.isPDFContent(item)) {
        iconName = 'mdi-file-document-outline';
      } else if (item.nodeType == "LINK") {
        iconName = 'mdi-link-variant';
      }
      return iconName;
    },
    getPlaceHolderIconColor(item) {
      let iconColor = 'grey';
      if (item.nodeType == "FOLDER" || item.nodeType == "SUBFOLDER") {
        iconColor = 'orange';
      } else if (item.nodeType == "COLLECTION" && item.collectionType == "VIDEO") {
        iconColor = 'primary';
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'VIDEO') {
        iconColor = 'primary';
      } else if (item.nodeType == "COLLECTION" && item.collectionType == "PDF") {
        iconColor = 'red';
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'PDF') {
        iconColor = 'red';
      } else if (this.isVideoContent(item)) {
        iconColor = 'primary';
      } else if (this.isPDFContent(item)) {
        iconColor = 'blue';
      } else if (item.nodeType == "LINK") {
        iconColor = 'blue';
      }
      return iconColor;
    },
    clearSearch() {
      this.filterSearchVal = this.searchText;
      //console.log(`clearSearch called:: this.filterSearchVal: ${this.filterSearchVal}`);
    },
    debounceSearch: _.debounce(function () {
      this.filterSearchVal = this.searchText;
      if (this.filterSearchVal) {
        recordAnalyticsEvent(AnalyticsEvents.v2LearnSearch, { searchText: this.filterSearchVal });
      }
      //console.log(`debounceSearch called: filterSearchVal:: ${this.filterSearchVal}`);
    }, 500),
    learnNodeClicked(item) {
      if (this.isLink(item)) {
        recordAnalyticsEvent(AnalyticsEvents.v2LearnopenLink, { linkUrl: item.nodeUrl });
        window.open(item.nodeUrl);
      } else if (this.isVideoContent(item)) {
        //console.log(`display video player`);
        if ((item.videoType && item.videoType.toLowerCase() == 'youtube')
          || (item.video && item.video.videoType.toLowerCase() == 'youtube')) {
          this.openYoutubeVideoPlayer(item);
        } else if ((item.videoType && item.videoType.toLowerCase() == 's3')
          || (item.video && item.video.videoType.toLowerCase() == 's3')) {
          this.openprivateVideoPlayer(item)
        }

      } else if (this.isPDFContent(item)) {
        //console.log(`ajay display pdf viewer`);
        //console.log(`----------2----------`);
        if (!this.download) {
          this.openPDfViewer(item);
        }
        //console.log(`----------3----------`);
      } else {
        this.$store.dispatch("loadLearnHierarchyNodesUnderaNode", item).then(() => {
          this.updateNewParentNodeId(item);
          this.addNewItemToBreadcrummbs(item);
          this.$store.dispatch("getCloudFrontCookies", {});
          recordAnalyticsEvent(AnalyticsEvents.v2LearnNodeDrilldown, { nodeId: this.currentParentNodeId, nodeName: item.nodeName || item.name });
        });
      }

    },
    openYoutubeVideoPlayer(item) {
      let regex =
        /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      let itemYTURL = item.youTubeUrl ?? item.video.youTubeUrl;
      let temp = itemYTURL.match(regex)[1];
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const topOfQueue = youtubeEmbedTemplate.concat(temp);
      this.playVideoUrl = topOfQueue;
      this.ytVideoDialog = true;
      recordAnalyticsEvent(AnalyticsEvents.v2LearnPlayVideo,
        {
          videoType: 'youtube', videoName: item.name || item.video.name,
          youtubeUrl: itemYTURL
        });
    },
    openprivateVideoPlayer(item) {
      // console.log(item.videoUrl)
      // console.log("Url : ", this.app_s3privateUrl)
      this.privateVideoDialog = true;
      this.$nextTick(function () {
        // console.log(`privateVideoDialog dom element loaded`);
        this.pvtVideoDomControl = this.$refs.privateVideoPlayer;
        // console.log(`this.pvtVideoDomControl 11 | ${this.pvtVideoDomControl}`);
        let videoURl = item.videoUrl ?? item.video.videoUrl;
        let stream = this.app_s3privateUrl + videoURl;
        recordAnalyticsEvent(AnalyticsEvents.v2LearnPlayVideo,
          {
            videoType: 'private', videoName: item.name || item.video.name,
            videoUrl: videoURl
          });
        console.log(`stream: ${stream}`)
        if (Hls.isSupported()) {
          console.log(`Hls.isSupported is true`);
          if (!this.hlsInstance) {
            var config = {
              xhrSetup: function (xhr, url) {
                console.log(`Hls config xhrSetup urk: ${url}`);
                xhr.withCredentials = true; // do send cookies
              },
            };
            this.hlsInstance = new Hls(config);
            console.log(`Created new Hls js instance  ${this.hlsInstance}`)
          } else {
            console.log(`reusing hls js instance: ${this.hlsInstance}`)
          }
          this.hlsInstance.loadSource(stream);
          this.hlsInstance.attachMedia(this.pvtVideoDomControl);
          this.hlsInstance.on(Hls.Events.MANIFEST_PARSED, function () {
            console.log(`inside Hls.Events.MANIFEST_PARSED, function ...`);
            let videoElement = document.getElementById(`videopId1`);
            console.log(`trying to play thls video: ${videoElement} `)
            videoElement.play();
          });
        } else if (this.pvtVideoDomControl.canPlayType('application/vnd.apple.mpegurl')) {
          this.pvtVideoDomControl.src = stream;
        }
      })
    },
    openPDfViewer(item) {
      //console.log(`inside openPDfViewer(item) : ${JSON.stringify(item)}`);
      let pdfURL = item.pdfUrl ?? item.pdf.pdfUrl;
      this.$store.dispatch('getPdfUrl', pdfURL).then((pdf) => {
        this.pdfFileUrl = pdf.url;
        this.pdfViewerDialog = true;
        recordAnalyticsEvent(AnalyticsEvents.v2LearnOpenPdf,
          {
            pdfName: item.name || item.pdf.name,
            pdfUrl: this.pdfFileUrl
          });
      })

      //console.log(`pdf dialog should be visible now : ${fullpdfFileURL}`)
    },
    downloadPDF(item) {
      let userAgent = navigator.userAgent;
      this.download = true;
      let pdfURL = item.pdfUrl ?? item.pdf.pdfUrl;
      this.$store.dispatch('getPdfUrl', pdfURL).then((pdf) => {
        if (userAgent.indexOf('Firefox') > -1) {
          console.log("In firefox")
          if (parent.window.open(pdf.url, '_blank') === null) {
            window.addEventListener('click', () => {
              console.log("Ok")
            });
            window.alert("Download Pdf")
          }
        } else {
          window.open(pdf.url, '_blank');
        }
        recordAnalyticsEvent(AnalyticsEvents.v2LearnDownloadPdf,
          {
            pdfName: item.name || item.pdf.name,
            pdfUrl: pdfURL
          });
        this.download = false;
      })
    },
    back() {
      this.removeLastItemFromBreadcrummbsandUpdateNewParentNodeId();
    },
    updateNewParentNodeId(item) {
      let parentNodeIdKeyinChildNodesMap = '';
      if (item.nodeType == 'FOLDER' || item.nodeType == 'SUBFOLDER') { //if Children of a Folder or SubFolder are being requested
        parentNodeIdKeyinChildNodesMap = item.nodeId;
      } else if (item.nodeType == 'COLLECTION' && item.collectionType == 'VIDEO') { //if Children of Video Collection
        parentNodeIdKeyinChildNodesMap = item.collectionId;
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'VIDEO') { //if Children of Video Collection
        parentNodeIdKeyinChildNodesMap = item.contentId;
      } else if (item.nodeType == 'COLLECTION' && item.collectionType == 'PDF') { //if Children of PDF Collection
        parentNodeIdKeyinChildNodesMap = item.collectionId;
      } else if (item.contentType == 'COLLECTION' && item.collectionType == 'PDF') { //if Children of PDF Collection
        parentNodeIdKeyinChildNodesMap = item.contentId;
      } else {    //if Children of other Node Types Collection
        parentNodeIdKeyinChildNodesMap = item.nodeId;
      }
      this.currentParentNodeId = parentNodeIdKeyinChildNodesMap;
    },
    addNewItemToBreadcrummbs(item) {
      this.learnNodesBreadcrumbs.push(
        {
          title: item.nodeName || item.name,
          disabled: false,
          parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
        });

    },
    removeLastItemFromBreadcrummbsandUpdateNewParentNodeId() {
      this.learnNodesBreadcrumbs.pop();
      if (this.learnNodesBreadcrumbs.length > 0) {
        this.currentParentNodeId = this.learnNodesBreadcrumbs[this.learnNodesBreadcrumbs.length - 1].parentNodeIdKeyinChildNodesMap;
      } else {
        this.currentParentNodeId = this.userCurrentDomain;
      }

    },
  },
}
</script>
   
<style scoped>
.search-box {
  max-width: 300px;
}

.v-card {}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.bx {
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #E7E7E7;
  border-radius: 4px;
}

.cardT {
  font-style: normal;
  font-weight: 500 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 0.875rem !important;
  color: black;
}

.cardS {
  color: rgb(var(--v-theme-secondaryTextColor));
  font-size: 0.8rem !important;

}
</style>
<style>
.no-new-line>a.v-breadcrumbs-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: none;
}

.no-new-line>li.v-breadcrumbs-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: none;
}
</style>
    