<template>
  <v-container class="s-size  d-flex flex-column justify-space-between " style="color: white;">

    <v-container class="pa-0 mt-0 main-container">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" lg="7">
          <!-- Question -->
          <v-row class="text-center w-100 mt-4 pa-0 ma-0 justify-center">
            <v-col class="pa-0 ma-0">
              <h2 class="d-q-title text-none ">{{ domainQuestion.domainSelectionMessage }} <span style="font-size: 30px">⛳</span>
              </h2>

            </v-col>
          </v-row>
          <v-row class="text-center w-100 mt-4 pa-0 ma-0 justify-center">
            <v-col class="py-0" cols="12" sm="6">
              <h3 class="d-q-subtitle">{{ domainQuestion.domainSelectionMessageDetails }}</h3>
            </v-col>
          </v-row>

          <!-- Options -->
          <v-row class="mx-auto pa-0 mt-8 ma-0 text-center" justify="center" align="center">
            <v-col cols="12" sm="6" class="ma-0 pa-0 d-flex justify-center bg-none"
              v-for="domain in domainQuestion.domains" :key="domain.key" color="white">
              <v-hover v-slot="{ isHovering, props }">
                <v-sheet :width="mdAndUp ? 300 : 320" :class="{
                  'selectedOption': domainResponse?.key === domain.key, 'on-hover': isHovering
                }" class="ma-2 pa-8 cardStyle d-flex justify-center align-center" height="100%"
                  @click="onSelectedOption(domain)" v-bind="props">

                  <v-row justify="start" style="min-height: 125px" align="center">
                    <v-col class="ma-0 pa-0" cols="12">
                      <v-avatar class="ma-0" size="45px">
                        <v-img :src="getImageUrl(domain.image)"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12">
                      <v-card-title class="d-o-title ma-0 pa-0 text-h5 text-wrap">
                        {{ domain.label }}
                      </v-card-title>
                      <v-card-subtitle class="d-o-subtitle ma-0 pa-0 mt-2 text-wrap">{{
                        domain.description
                      }}</v-card-subtitle>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-hover>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>

    <footer-component style="flex: unset" class="mt-8 pb-0"/>
  </v-container>
</template>

<script>

import _ from "lodash";
import {AnalyticsEvents, recordAnalyticsEvent} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import FooterComponent from "./FooterComponent.vue";

export default {
    name: "QuestionairePage",
    components: { FooterComponent },
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp } = useDisplay();
        return { xs, smAndUp, mdAndUp };
    },
    data() {
        return {
            isAnswerSelected: false,
            domainResponse: {},
        };
    },
    created() {
        recordAnalyticsEvent(AnalyticsEvents.v2OnboardingDomainScreen);
        if (!_.get(this.onBoardingResponse, "tempUserId", null)) {
            this.$router.replace("/");
        }
    },
    computed: {
        ...mapState(["onBoardingResponse"]),
        domainQuestion: {
            get() {
                return {
                    domainSelectionMessage: _.get(this.onBoardingResponse, "domainSelectionMessage", ""),
                    domainSelectionMessageDetails: _.get(this.onBoardingResponse, "domainSelectionMessageDetails", ""),
                    domains: _.get(this.onBoardingResponse, "domains", [])
                };
            }
        }
    },
    methods: {
        getImageUrl(imagePath) {
            return this.$store.state.app_s3url + imagePath;
        },
        onSelectedOption(option) {
            this.domainResponse = _.cloneDeep(option);
            recordAnalyticsEvent(AnalyticsEvents.v2OnboardingDomainScreenDomainClicked, { domainId: _.get(this.domainResponse, "key", "") });
            this.isAnswerSelected = true;
            this.$store
                .dispatch("onBoardingDomain", {
                "tempUserId": _.get(this.onBoardingResponse, "tempUserId", ""),
                "selectedDomainId": _.get(this.domainResponse, "key", "")
            })
                .then((response) => {
                if (!_.isNil(response)) {
                    this.$store.commit("loadOnBoardingQuestions", response);
                    this.$router.push("/welcome");
                }
            });
        },
    },
};
</script>

<style scoped>
.s-size {
  min-height: calc(100vh - 64px) !important;
}

.d-q-title {
  font-size: 1.75em !important;
  font-weight: 600 !important;
}

.d-q-subtitle {
  font-size: 1em !important;
  font-weight: 500 !important;
}

.d-o-title {
  font-size: 1em !important;
  font-weight: 600 !important;
  /* color: black; */
}

.d-o-subtitle {
  font-size: 1em !important;
  font-weight: 500 !important;
  color: white !important;
  opacity: 1 !important;
}

.bg-none {
  background: none !important;
}

.cardStyle {
  background-color: #3D3D54 !important;
  transition: all 0.3s ease;
  border: 4px solid #A174FD !important;
  color: #fff !important;
  border-color: transparent !important;
  border-radius: 12px !important;
  cursor: pointer;
}

.on-hover {
  cursor: pointer;
  /* border: 1px solid #e7e7e7 !important; */
  background-color: #3D3D54 !important;
  color: #fff !important;
  filter: drop-shadow(0px 0px 25px #A274FD80);
  /* border: 4px solid #538dfe !important; */
  border: 4px solid #A174FD !important;

}

/* Hide the scrollbar */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
</style>