<template>
  <v-layout class="flex-column" style="position: relative;">
    <!-- Body -->
    <v-main v-if="showData">
      <v-container class="d-flex screen-size justify-center align-center text-center flex-column w-100">
        <v-row class="w-100" justify="center" align="center">
          <v-col cols="12" sm="8">
            <v-sheet class="bg-none" style="color: white">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <h1 class="title">Fun and effective way to learn and<br /> improve your skills for the SAT!</h1>
                </v-col>
              </v-row>

              <v-row justify="center" align="center" class="pa-0 ma-0 mt-8 mt-sm-16">
                <v-col col="12" sm="7" md="5" xl="4" class="pa-0 ma-0">
                  <v-btn
                    class="btn-h-size button-bg-clr font-weight-bold text-none ma-0 pa-0 rounded-pill no-uppercase btn-width"
                    block color="primary" size="large" variant="elevated" @click="toStep1(true)">
                    Get started
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center" align="center" class="pa-0 ma-0">
                <v-col cols="12" sm="7" md="5" xl="4" class="pa-0 ma-0">
                  <v-btn class="btn-h-size font-weight-bold text-none mt-5 ma-0 pa-0 rounded-pill no-uppercase" block
                    color="white" size="large" variant="outlined" @click="toStep1(false)">
                    Already have an account
                  </v-btn>
                </v-col>
              </v-row>

            </v-sheet>
          </v-col>
        </v-row>
        <footer-component class="pb-0" style="flex: unset;" />
      </v-container>
    </v-main>

  </v-layout>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { getDeviceId, setTempUserId } from "@/services/authService";
import { v4 as uuidv4 } from "uuid";
import { useDisplay } from "vuetify";
import _ from "lodash";
import FooterComponent from './FooterComponent.vue'
import { mapState } from "vuex";
import { isWhiteLabelApp } from "@/services/whiteLabelService";

export default {
  name: "NewLogin",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp };
  },
  components: {
    FooterComponent
  },
  created() {

    let deviceId = getDeviceId();
    if (
      !deviceId ||
      deviceId == "" ||
      deviceId.toLowerCase() == "undefined"
    ) {
      try {
        deviceId = crypto.randomUUID();
      } catch (err) {
        console.log(
          `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
        );
        deviceId = uuidv4();
      }
      localStorage.setItem('deviceId', deviceId)
    }
    
    recordAnalyticsEvent(AnalyticsEvents.v2OnboardingHome);
    this.checkWhiteLabelApp();
    this.$store.commit('appbarVisible', false);
    this.$store.commit('navbarVisible', false);
    this.$store.commit("showNotification", false);
    if (this.isWhiteLabelApp) {
      if (!this.courseDetails.isNewRegistrationAllowed) {
        this.$router.replace("/login");
      }
      else {
        this.showData = true;
      }
    }
    else {
      this.showData = true;
    }
  },
  computed: {
    ...mapState(["config", "courseDetails", "courseId"]),
  },
  data() {
    return {
      showData: false,
      isWhiteLabelApp: false,
    };
  },
  methods: {
    toStep1(newUser) {
      if (newUser) {
        recordAnalyticsEvent(AnalyticsEvents.v2OnboardingHomeGetStartedClicked);
      } else {
        recordAnalyticsEvent(AnalyticsEvents.v2OnboardingHomeExistingAccountClicked);
      }

      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
        localStorage.setItem('deviceId', deviceId)
      }
      const self = this
      if (newUser == true) {
        this.$store
          .dispatch("onBoardingStart", {
            newUser: newUser,
            deviceId: deviceId,
            courseId: this.courseId,
          })
          .then((response) => {
            if (
              !_.isNil(response)
            ) {
              console.log("response", response);
              this.$store.commit("loadOnBoardingInfo", response);
              // this.$store.commit("setAPIUrlsForFlavorAndVersion", response.appurls);
              setTempUserId(response.tempUserId)
              if (newUser) {
                if (response.domains.length === 1) {
                  this.$store
                    .dispatch("onBoardingDomain", {
                      "tempUserId": _.get(response, "tempUserId", ""),
                      "selectedDomainId": _.get(response.domains[0], "key", "")
                    })
                    .then((responseFromDomain) => {
                      if (!_.isNil(responseFromDomain)) {
                        if (this.isWhiteLabelApp) {
                          this.$store.commit("loadOnBoardingQuestions", []);
                        }
                        else {
                          this.$store.commit("loadOnBoardingQuestions", responseFromDomain);
                        }
                        this.$router.push("/welcome");
                      }
                    });
                }
                else {
                  this.$router.push("/domain");
                }
              } else {
                this.$router.push('/login');
              }
            }
          });
      }
      else {
        this.$router.push('/login');
      }
    },
    checkWhiteLabelApp() {
      this.isWhiteLabelApp = isWhiteLabelApp();
    }
  },
};
</script>

<style scoped>
.bg-none {
  background: none !important;
}

.btn-h-size {
  font-size: 14px !important;
  height: 54px !important;
}

.btn-width {
  max-width: 100px !important;
}

.screen-size {
  height: calc(100vh - 84px) !important;
}

.button-bg-clr {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) !important;
}

.title {
  font-size: 31.5px !important;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.25rem !important;
    line-height: 30px;
  }
}

/* .footer-pos{
  position: fixed;
  left: 0;
  bottom: 1rem;
  width: 100%;
} */
.footer-text {
  color: #9b9b9b;
  font-weight: 600 !important;
}
</style>
