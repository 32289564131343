import store from "../store";
import verFlavor from "@/appFlavorAndVersion";
import { setLocalForageItem } from "./localforageService";


export function storeUserInfo(data) {
  localStorage.setItem('userId', data['userId'])
  localStorage.setItem('deviceId', data['deviceId'])
  localStorage.setItem('name', data['name'])
  localStorage.setItem('accessToken', data['accessToken'])
  localStorage.setItem('refreshToken', data['refreshToken'])
  localStorage.setItem('organizationId', data['organizationId'])
  setLocalForageItem("organizationId", data['organizationId'])
  localStorage.setItem('appFlavour', verFlavor.APP_FLAVOR)
  localStorage.setItem('teacherView', data['isTeacher'])
  localStorage.setItem('superAdminView', data['isSuperAdmin'])
  localStorage.setItem('adminView', data['isAdmin'])
  localStorage.setItem('parentView', data['isParent'] ?? false);
  localStorage.setItem('courseId', data['courseId'])
  localStorage.setItem('courseName', data['courseName']);
  localStorage.setItem("reviewLevels", JSON.stringify(data['reviewLevels'] ?? []));
}

export function storeLoggedInFlag(flag) {
  localStorage.setItem('isUserLoggedIn', flag)
}

export function storeUserCurrentDomain(domain) {
  localStorage.setItem('domainId', domain)
}

export function storeUserOrganizationId(organizationId) {
  localStorage.setItem('organizationId', organizationId)
}

export function setTempUserId(tempUserId) {
  return localStorage.setItem('tempUserId', tempUserId);
}

export function setApiBaseUrl(apiBaseUrl) {
  return localStorage.setItem('apiBaseUrl', apiBaseUrl);
}

export function isUserLoggedIn() {
  return localStorage.getItem('isUserLoggedIn');
}

export function setShowSolution(showSolution) {
  return localStorage.setItem('showSolution', showSolution)
}

export function getLoggedInUserInfo() {
  var reviewLevels = [];
  try {
    reviewLevels = JSON.parse(localStorage.getItem('reviewLevels'));
  } catch (e) {
    console.log(`getLoggedInUserInfo error: ${localStorage.getItem('reviewLevels')}`)
  }
  return {
    userId: localStorage.getItem('userId'),
    deviceId: localStorage.getItem('deviceId'),
    name: localStorage.getItem('name'),
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    organizationId: localStorage.getItem('organizationId'),
    appFlavour: localStorage.getItem('appFlavour'),
    appVersion: verFlavor.APP_VERSION,
    domainId: localStorage.getItem('domainId') ?? '',
    reviewLevels: reviewLevels,
  }
}


export function getLoggedInUserOrganizationId() {
  return localStorage.getItem('organizationId');
}

export function getLoggedInUserName() {
  return localStorage.getItem('name');
}

export function getLoggedInUserId() {
  return localStorage.getItem('userId');
}

export function getLoggedInUserOrganization() {
  return localStorage.getItem('organizationId');
}

export function getLoggedInUserCurrentDomain() {
  return localStorage.getItem('domainId') ?? '';
}

export function getDeviceId() {
  return localStorage.getItem('deviceId') ?? '';
}

export function getTempUserId() {
  return localStorage.getItem('tempUserId') ?? '';
}

export function getApiBaseUrl() {
  return localStorage.getItem('apiBaseUrl') ?? '';
}

export function getShowSolution() {
  return localStorage.getItem('showSolution');
}

export function storeReferralId(referralId) {
  return localStorage.setItem('referralId', referralId);

}
export function getReferralId() {
  return localStorage.getItem('referralId');

}
export function clearReferralId() {
  return localStorage.removeItem('referralId');

}

export function getTeacherView() {
  return localStorage.getItem('teacherView');
}
export function getAdminView() {
  return localStorage.getItem('adminView');
}
export function getSuperAdminView() {
  return localStorage.getItem('superAdminView');
}
export function getParentView() {
  return localStorage.getItem('parentView');
}

export function getCourseId() {
  return localStorage.getItem('courseId');
}

export function storeCourseId(courseId) {
  localStorage.setItem('courseId', courseId);
}
export function getCourseName() {
  return localStorage.getItem('courseName');
}

export function storeCourseName(courseName) {
  localStorage.setItem('courseId', courseName);
}
export function getHeaders() {
  //console.log(`getHeaders | ${localStorage.getItem("organizationId")} | ${store.getters.defaultOrgnizationIdForFlavors}`)
  return {
    userid: localStorage.getItem('userId') ?? '',
    deviceid: localStorage.getItem('deviceId') ?? '',
    token: localStorage.getItem('accessToken') ?? '',
    organizationid: localStorage.getItem('organizationId') ?? store.getters.defaultOrganizationIdForFlavor,
    appflavour: localStorage.getItem('appFlavour') ?? verFlavor.APP_FLAVOR,
    appversion: verFlavor.APP_VERSION ?? '',
    domainid: localStorage.getItem('domainId'),
  }
}

export function getHeadersWithCustomerId() {
  //console.log(`getHeaders | ${localStorage.getItem("organizationId")} | ${store.getters.defaultOrgnizationIdForFlavors}`)
  return {
    userid: localStorage.getItem('userId') ?? '',
    deviceid: localStorage.getItem('deviceId') ?? '',
    token: localStorage.getItem('accessToken') ?? '',
    organizationid: localStorage.getItem('organizationId') ?? store.getters.defaultOrganizationIdForFlavor,
    appflavour: localStorage.getItem('appFlavour') ?? verFlavor.APP_FLAVOR,
    appversion: verFlavor.APP_VERSION ?? '',
    domainid: localStorage.getItem('domainId'),
    customerid: localStorage.getItem('courseId')?? '',
  }
}

export function logoutUser() {
  localStorage.removeItem('userId')
  //localStorage.removeItem('deviceId') // we can leave deviceid in localStorage even afetr logout
  localStorage.removeItem('name')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('organizationId')
  localStorage.removeItem('appFlavour')
  localStorage.removeItem("isUserLoggedIn")
  localStorage.removeItem("appVersion")
  localStorage.removeItem("domainId")
  localStorage.removeItem("referralId")
  localStorage.removeItem('teacherView')
  localStorage.removeItem('reviewLevels')
  localStorage.removeItem('courseId')
  localStorage.removeItem('adminView')
  localStorage.removeItem('superAdminView')
  localStorage.removeItem('tempUserId')
  localStorage.removeItem('parentView')
}

export function storeTestId(testId) {
  return localStorage.setItem('testId', testId);

}
export function getTestId() {
  return localStorage.getItem('testId');

}
export function clearTestId() {
  return localStorage.removeItem('testId');

}

export function storeRequestId(requestId) {
  return localStorage.setItem('requestId', requestId);
}
export function getRequestId() {
  return localStorage.getItem('requestId');
}
export function clearRequestId() {
  return localStorage.removeItem('requestId');
}
export function storeResourceLink(url) {
  return localStorage.setItem('resourceLink', url);
}
export function getResourceLink() {
  return localStorage.getItem('resourceLink');
}
export function clearResourceLink() {
  return localStorage.removeItem('resourceLink');
}