<template>
  <div class="cambg" v-if="cameraMode">
    <WebCam ref="webcam" :fullscreenState="true" />
    <v-row justify="center">
      <v-col cols="2">
        <v-btn size="42" @click="cameraMode = false" color="grey" icon="mdi-close">
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn size="42" @click="takePhoto" color="grey" icon="mdi-camera"> </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn size="42" color="grey" icon="mdi-camera-flip-outline"> </v-btn>
      </v-col>
    </v-row>
  </div>

  <v-layout
    class="mb-0 pb-0"
    :class="{ layoutLarge: $vuetify.display.mdAndUp }"
    v-if="!cameraMode"
  >
    <v-container
      fluid
      class="h-100 mt-3 mt-md-0 mb-0 pb-0"
      :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''"
    >
      <v-row align="center" justify="start" dense>
        <v-col cols="auto">
          <span class="t-h125 text-center mb-1 text-primaryTextColor"
            >AI Question Solver</span
          >
        </v-col>
      </v-row>

      <v-row align="center" justify="start" class="">
        <v-col cols="auto">
          <v-btn
            color="success"
            class="t-btn text-capitalize btn-grad text-primaryTextColor"
            prepend-icon="mdi-camera"
            @click="openCamera"
            >Take Photo</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <img id="photo" alt="Captured Photo" class="imgC" style="display: none" />
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { WebCamUI } from "vue-camera-lib";
import { WebCam } from "vue-camera-lib";

export default {
  name: "AIQSolver",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 400,
    },
  },
  async created() {
    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
  },
  data: () => ({
    cameraMode: false,
  }),
  components: {
    WebCam,
    // WebCamUI,
  },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  computed: {
    ...mapState([]),
  },

  watch: {},

  methods: {
    photoTaken(data) {
      console.log("image blob: ", data.blob);
      console.log("image data url", data.image_data_url);
    },
    openCamera() {
      this.cameraMode = true;
      // this.startCamera();
    },
    // Function to start the camera stream
    // Start the camera and display the settings
    async startCamera() {
      await new Promise((r) => setTimeout(r, 1000));

      const video = document.getElementById("video");
      const settingsDiv = document.getElementById("settings");

      // Request camera access
      window.navigator.mediaDevices
        .getUserMedia({
          video: {
            // // Prefer portrait orientation by setting the width smaller than the height
            width: { ideal: 1080 }, // Example: width set lower than height to ensure portrait mode
            height: { ideal: 1080 },
            aspectRatio: { ideal: 1 / 1 }, // This will ensure portrait aspect ratio
            facingMode: "environment", // Or 'user' for the front-facing camera
          },
        })
        .then((stream) => {
          // Start video playback
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            video.play();
          };

          // Access the first video track
          const videoTrack = stream.getVideoTracks()[0];

          // Get camera settings and capabilities
          const settings = videoTrack.getSettings();
          const capabilities = videoTrack.getCapabilities();

          // Display the current settings and some useful capabilities
          settingsDiv.innerHTML = `
                        <strong>Current Settings:</strong><br>
                        Width: ${settings.width || "Unknown"}<br>
                        Height: ${settings.height || "Unknown"}<br>
                        Frame Rate: ${settings.frameRate || "Unknown"}<br>
                        Facing Mode: ${settings.facingMode || "Unknown"}<br><br>
                        <strong>Available Capabilities:</strong><br>
                        Max Width: ${
                          capabilities.width ? capabilities.width.max : "Unknown"
                        }<br>
                        Max Height: ${
                          capabilities.height ? capabilities.height.max : "Unknown"
                        }<br>
                        Max Frame Rate: ${
                          capabilities.frameRate ? capabilities.frameRate.max : "Unknown"
                        }<br>
                    `;
        });
    },
    // Function to take a photo and display it
    async takePhoto() {
      const video = document.getElementById("video");
      const canvas = document.getElementById("canvas");

      // Desired resolution (e.g., Full HD)
      const desiredWidth = 720; // Change this value to increase the width
      const desiredHeight = 1280; // Change this value to increase the height

      // Check the video's actual dimensions
      const aspectRatio = video.videoWidth / video.videoHeight;

      // Maintain the aspect ratio while scaling to desired dimensions
      if (desiredWidth / desiredHeight > aspectRatio) {
        canvas.width = desiredHeight * aspectRatio;
        canvas.height = desiredHeight;
      } else {
        canvas.width = desiredWidth;
        canvas.height = desiredWidth / aspectRatio;
      }

      // Draw the current frame from the video onto the canvas
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.cameraMode = false;
      await new Promise((r) => setTimeout(r, 1000));
      // Get the image data URL and display the captured photo
      const photo = document.getElementById("photo");
      const dataUrl = canvas.toDataURL("image/png");
      photo.src = dataUrl;
      photo.style.display = "block"; // Show the captured image
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-lg {
  padding-top: 50px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.layoutLarge {
  height: 100%;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

/* The video element will fill the entire browser window while maintaining the aspect ratio */
video {
  width: 100vw;
  /* 100% of the viewport width */
  height: 100vh;
  /* 100% of the viewport height */
  object-fit: cover;
  /* Maintain aspect ratio and crop the overflow if necessary */
  border: 1px solid black;
  /* Optional border to help visualize the video element */
}

.imgC {
  width: 100%;
  height: 100%;
}
.cambg {
  background-color: black;
  height: 100%;
  touch-action: none;
}
/* Style for the capture button */
#capture {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff5722;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1000;
  /* Ensures button is above video */
}

#settings {
  position: absolute;
  bottom: 20px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

/* Make canvas invisible initially */
#canvas {
  display: none;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.capturePhoto {
  position: absolute;
  bottom: 10%;
  z-index: 2600;
}
</style>
