<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0 ma-0">
      <vue-tel-input 
        :disabled="disabled" 
        :required="true"
        v-model="value" 
        v-bind="bindProps"
        @on-input="handleInput"
        class="telInputField"
        :class="{ 'invalidNumber': !telObject.telIsValidPhoneNumber && (value?.length > 0 || required) }" 
        ></vue-tel-input>
    </v-col>
    <v-col cols="12" class="v-input__details telInputField__errorMsg">
      <span :class="(!telObject.telIsValidPhoneNumber) && (value?.length>0 || required)? 'invalidNumberMsg' : 'validNumberMsg'" class="text-error">
        {{ errorMsg }}
      </span>
    </v-col>
  </v-row>

</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import moment from "moment-timezone";

export default {
  name: "TelInput",
  components: { VueTelInput },
  props: {
    modelValue: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Phone Number'
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'updatedPhoneNumber', 'updatedPhoneNumberObject'],
  created(){
    const currTz = moment
      .tz(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
      .zoneName();

    if (currTz === "IST") {
      this.phoneProps.defaultCountry = "IN";
    }
  },
  data: () => ({
    phoneProps: {
      autoFormat: true,
      required: true,
      disabledFetchingCountry: false,
      enabledFlags: true,
      dropdownOptions: {
        showDialCodeInSelection: true,
        showDialCodeInList: true,
        showFlags: true,
      },
      inputOptions: {
        placeholder: "Phone Number",
        type: "tel",
      },
      mode: "international",
      validCharactersOnly: true,
      defaultCountry: "US",
      onlyCountries: ["US", "IN"],
      invalidMsg: 'Please enter correct phone number'
    },
    telObject: {
      telCountryCode: '',
      telCountryName: '',
      telPhoneNumber: '',
      telIsValidPhoneNumber: false,
    },
    errorMsg: 'Please enter a valid phone number',
  }),
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    bindProps() {
      return this.phoneProps;
    }
  },
  methods: {
    handleInput(value, object){
      this.telObject = {
        telCountryCode: object.countryCallingCode ?? '',
        telCountryName: object.countryCode ?? '',
        telPhoneNumber: object.nationalNumber ?? '',
        telIsValidPhoneNumber: object.valid?? false,
      }
      this.$emit('updatedPhoneNumberObject', this.telObject);
    }
  },
  watch: {
    placeholder: {
      handler(val){
        this.phoneProps.inputOptions.placeholder = val;
      },
      immediate: true,
      
    }
  }
}
</script>

<style>

/* Normal Input Field Styling */
.telInputField{
  height: 40px;
  background: #26253A;
  border: 1px solid #ffffff70;
  outline: none;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'Inter', sans serif;
  font-weight: 400;
  transition: all 0.3s ease;
}

.telInputField:hover{
  background: #26253A;
  border: 1px solid white;
  outline: none;
  color: white;
}

.telInputField:focus-within{
  border: 2px solid white;
  box-shadow: none;
}

.telInputField .vti__dropdown{
  border-right: 1px solid #ffffff70;
  
}

.telInputField .vti__dropdown:hover{
  background: transparent;
  outline: none;
  color: white;
}

.telInputField .vti__selection{
  display: flex;
  justify-content: space-around;
}

.telInputField .vti__dropdown.open{
  background: transparent;
  border-right: 2px solid white;
  outline: none;
}

.telInputField .vti__dropdown-list.below, .telInputField .vti__dropdown-list.above{
  color: #000000DE;
}

.telInputField .vti__selection{
  display: flex;
  justify-content: space-around;
}

.telInputField .vti__input{
  color: white;
}

.telInputField .vti__input::placeholder, .telInputField .vti__country-code, .telInputField .vti__dropdown-arrow{
  color: white;
  opacity: 0.5;
}

.validNumberMsg{
  display: none;
}

/* Invalid Field Styling */
.invalidNumber{
  border:2px solid rgb(var(--v-theme-error)) !important;
}

.invalidNumberMsg{
  display: block
}


.telInputField__errorMsg {
  color: rgb(var(--v-theme-error));
  padding: 6px 16px 0 !important;
  transition: all 3s ease;
}

/* Disabled Field */
.telInputField.disabled{
  border: 1px solid #ffffff6b;
  cursor: default;
  opacity: 0.38;
}
.telInputField.disabled input, .telInputField .vti__dropdown{
  cursor: default;

}

.telInputField.disabled:hover{
  opacity: 0.38;
  border: 1px solid #ffffff6b;
} 

</style>