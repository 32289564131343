<template>
    <v-footer class="bg-none footer-pos">
      <v-row justify="center" no-gutters>
        <v-col justify="center" class="text-center">
          <p class="footer-text">
            Copyright &copy; 2024 Innotrail, Inc.  All Rights Reserved.  Copying, modifying, publishing are strictly prohibited. 
          </p>
          <p class="footer-text"> SAT&reg;, PSAT&reg; and College Board&reg;  are trademarks registered by the College Board, which is not affiliated with, and does not endorse, this website.
          </p>
        </v-col>
      </v-row>
    </v-footer>
</template>

<script>
    export default {
        name: 'FooterComponent',
    }
</script>

<style scoped>

.bg-none {
  background: none !important;
}

.footer-pos{
  /* position: fixed; */
  width: 100%;
}
.footer-text{
  color: #9b9b9b;
  font-weight: 600 !important;
  line-height: 1.25rem !important;
  font-size: 11px !important;
  padding-top: 4px !important;
}

@media only screen and (max-width: 600px) {
  .footer-text {
    font-size: 0.51188rem !important;
    line-height: 0.84375rem !important;
  }
}
</style>