<template>
  <v-app-bar v-if="showNotificationCheck" :height="heightValue" class="notificationBackground text-white" :class="[smAndUp? 'notificationWidth': 'notificationWidthMobile']" id="checkHeight" v-resize="onResize" flat>
    <v-app-bar-title class="mx-8 w-100">
      <v-row justify="center" align="center">
        <!-- <v-col cols="auto" class="pr-0">
          <v-avatar size="40" >
            <v-img src="@/assets/mento_logo_rounded.svg">
            </v-img>
          </v-avatar>
          
        </v-col> -->
        <v-col class="text-wrap adjustLineHeight" id="contentElement">
          <span :class="[smAndUp? 't-1remReg' : 't-h6Reg']">
            {{currentNotification.description}} 
          </span>
          
        </v-col>
        <v-col class="text-right" cols="auto">
          <v-icon @click.stop="closeNotification" size="25">mdi-close-circle</v-icon>
        </v-col>
      </v-row>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
export default {
  name: 'NotificationComponent',
  props: {
    show: Boolean,
  },
  setup() {
    // Destructure only the keys we want to use
    const { smAndUp} = useDisplay();
    return { smAndUp };
  },
  async created() {
    setTimeout(() => {
      this.appBarHeight();
    }, 0);
  },
  mounted(){
  },
  data: () => ({
    currentNotificationIndex: 0,
    currTimeExceedsPausedTime: false,
    showContent: false,
    heightValue: 100,
    messages: [
      {
        messageId: 'uuid',
        description: 'String',
        messageRepeatDuration: 'time in seconds (eg. 2 days in seconds)',
      }
    ],
    removeFromLocalStorage: false,
  }),
  computed: {
    ...mapState(["showNotification", "notificationsList"]),
    currentNotification: {
      get(){
        return this.notificationsList[this.currentNotificationIndex];
      }
    },
    showNotificationCheck: {
      get(){
        let flag = (this.notificationsList.length>0 && this.showNotification && this.show && this.notificationNotPaused) ? true : false;
        return flag;
      }
    },
    notificationNotPaused: {
      get(){
        if(localStorage.getItem("pausedNotifications") !== null){
          let lsItem = JSON.parse(localStorage.getItem("pausedNotifications"));
          let currentLSItemBeingCompared = lsItem[0];
          if(currentLSItemBeingCompared?.messageId == this.currentNotification?.messageId){
            if(Math.floor(Date.now()/1000) > (currentLSItemBeingCompared.timeStampWhileStoring + (this.currentNotification.messageRepeatDurationInMinutes)*60)){
              return true;
            }
            else{
              return false;
            }
          }
          else{
            return true;
          }
        }
        else{
          return true;
        }
      }
    },
  },
  methods: {
    closeNotification(){
      this.$store.commit("showNotification", false);
      const currentTimestamp = Math.floor(Date.now()/1000);
      let notification = [
        {
          "messageId": this.currentNotification.messageId,
          "timeStampWhileStoring": currentTimestamp,
        }
      ];
      localStorage.setItem("pausedNotifications", JSON.stringify(notification));
    },
    onResize(){
      this.appBarHeight();
    },
    appBarHeight(){
      let contentHeight = document.getElementById("contentElement")?.offsetHeight ?? 100;
      this.heightValue = contentHeight + 16;
    }
  },
  watch:{
    showNotificationCheck(val){
      if(val == true){
        localStorage.removeItem("pausedNotifications");
      }
    }
  }
}
</script>

<style scoped>

.notificationBackground {
  background: rgb(var(--v-theme-breadCrumbBackColor)) !important;
  border: 2px solid rgb(var(--v-theme-primaryPurple)) !important;
  border-radius: 12px;
  margin: 4px;
}

.notificationWidth {
  width: calc(100% - 210px) !important;
}
.notificationWidthMobile {
  width: calc(100% - 10px) !important;
}

.adjustLineHeight{
  line-height: 120% !important;
}


</style>

<style>

/* .challenge-background.v-main{
  --v-layout-left: 200px !important;
    --v-layout-right: 0px !important;
    --v-layout-top: 200px !important;
    --v-layout-bottom: 0px !important;
    background-attachment: red !important;
}

.v-app-bar.notificationBackground{
  height: fit-content !important;
  justify-content: center;
  align-items: center;
} */

</style>