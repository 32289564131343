//import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import {
  isUserLoggedIn,
  getTeacherView,
  getAdminView,
  storeTestId,
  clearTestId,
  clearRequestId,
  getParentView,
} from "@/services/authService";
import { getAppTitle } from "@/flavors";
import store from "@/store";
import verFlavor from "@/appFlavorAndVersion";

const routes = [
  {
    path: "/login",
    name: "existing",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLogin/ExistingUser.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/ssoPage",
    name: "sso",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/SSOPage.vue"),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/aiinsights",
    name: "aiinsights",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/ai/AIInsightsParent.vue"),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/action",
    name: "action",
    meta: {
      title: "Assignments",
    },
    children: [
      {
        path: "",
        name: "student_actions",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/actions/ActionsPage.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },
      {
        path: "digisatresult/:testId",
        name: "action_digisatresult",

        meta: {
          frontRoute: {
            path: "/action",
            name: "Action",
          },
          title: "Test Attempts",
        },

        children: [
          {
            path: "",
            name: "action_digisatresult",
            meta: {
              showInBreadcrumb: false,
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
              ),
          },
          {
            path: ":testInstanceId",
            name: "action_digisatresult_digisatresult",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
              ),

            meta: {
              backRoute: {
                path: "/action",
                name: "Action",
              },
              title: "Test Report",
            },
          },
          // {
          //   path: '', name: 'action_digisatresult_testattempt1',

          //   component: () => import(/* webpackChunkName: "about" */ '../components/testModules/TestAttemptPage.vue'),

          // },
        ],
      },
    ],
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/feedback/FeedBack.vue"
      ),
  },
  {
    path: "/learn",
    name: "learn",
    meta: {
      title: "Learn",
    },
    children: [
      {
        path: "",
        name: "topics",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/newLearn/NewLearnPage.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },
      {
        path: ":entityId",
        name: "learn_activities",
        props: true,
        meta: {
          title: "Learn",
        },
        children: [
          {
            path: "",
            name: "learn_activities_self",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/newLearn/NewLearnActivitiesPage.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
          {
            path: "learnreports",
            name: "learn_allreports",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/newLearn/AllReports.vue"
              ),
            meta: {
              title: "Report",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/myclasses",
    name: "myclasses",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/classes/MyClassesPage.vue"
      ),
  },
  {
    path: "/testseries",
    name: "collections",
    children: [
      {
        path: ":collectionId/:type",
        name: "tests",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Test/TestListPage.vue"
          ),
      },
      {
        path: ":collectionId/:type/:testId",
        name: "reports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Test/TestListPage.vue"
          ),
      },
      {
        path: ":collectionId/:type",
        name: "tests",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Test/TestListPage.vue"
          ),
      },
      {
        path: ":collectionId/:type/:testId",
        name: "reports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/Test/TestListPage.vue"
          ),
      },
    ],

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Test/TestListPage.vue"
      ),
  },
  {
    path: "/starttest/:testId",
    name: "starttest",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Test/StartTestPage.vue"
      ),
  },
  {
    path: "/testquestionpage",
    name: "testquestion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Test/TestQuestionPage.vue"
      ),
  },
  {
    path: "/testmodulequestionpage",
    name: "testmodulequestion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/testModules/TestQuestionPage.vue"
      ),
  },
  {
    path: "/practicequestionpage",
    name: "practicequestion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/practice/PracticeQuestionPage.vue"
      ),
  },
  {
    path: "/digisatresult",
    name: "digisatresult",
    children: [
      {
        path: ":testId",
        name: "testattempt",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
          ),
      },
      {
        path: ":testId/:testInstanceId",
        name: "digisatresult",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
          ),
      },
    ],
  },
  {
    path: "/testsubmission",
    name: "testsubmission",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Test/TestSubmissionPage.vue"
      ),
  },
  {
    path: "/quizreport/:testId/:testInstanceId",
    name: "quizreport",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLearn/QuizReportPage.vue"
      ),
  },

  {
    path: "/quizreports/:activityName/:activityId",
    name: "quizreports",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLearn/QuizReports.vue"
      ),
  },

  {
    path: "/learnreports/:topicId",
    name: "allreports",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLearn/AllReports.vue"
      ),
  },

  {
    path: "/testresult/:testId",
    name: "testresult",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/Test/TestResultPage.vue"
      ),
  },
  {
    path: "/mocktestresult/:testId",
    name: "mocktestresult",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/testModules/TestResultPage.vue"
      ),
  },
  {
    path: "/challenge",
    name: "challenge",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ChallengePage.vue"
      ),
    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/welcomemento",
    name: "welcomemento",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLogin/WelcomeMento.vue"
      ),
    meta: { nav: true },
  },
  {
    path: "/challenge/challenges",
    name: "challenges",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ChallengeTemplates.vue"
      ),
    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/challenge/hostwaitingroom/:challengeId/:quizTemplateId",
    name: "hostwaitingroom",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/HostWaitingRoom.vue"
      ),
    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/challenge/joinchallenge",
    name: "joinchallenge",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/JoinRoomPage.vue"
      ),
    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/challenge/participantwaitingroom",
    name: "participantwaitingroom",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ParticipantWaitingRoom.vue"
      ),

    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/challenge/participantwaitingroom/:challengeId",
    name: "participantAIwaitingroom",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ParticipantWaitingRoom.vue"
      ),

    meta: { nav: true, currentTab: "challenge" },
  },
  {
    path: "/challenge/challengestartingscreen",
    name: "challengestartingscreen",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ChallengeStartingScreen.vue"
      ),
    meta: { nav: false, checkRouteForChallenge: true },
  },
  {
    path: "/challenge/challengequestionpage",
    name: "challengequestionpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ChallengeQuestionPage.vue"
      ),
    meta: { nav: false, checkRouteForChallenge: true },
  },
  {
    path: "/challenge/challengereport/:challengeId",
    name: "challengereportpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/ChallengeReportPage.vue"
      ),
    meta: { nav: true },
  },
  {
    path: "/challenge/pastchallenges",
    name: "pastchallengepage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/challenge/PastChallengePage.vue"
      ),
    meta: { nav: true },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLogin/QuestionairePage.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLogin/NewLogin.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/domain",
    name: "domain",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/newLogin/DomainPage.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/aicsso",
    name: "aicsso",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/SSO/SSOChallengePage.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/ta/:testId",
    name: "ta",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/TeacherAssignment.vue"
      ),
    meta: { noAuthNeeded: true },
  },
  {
    path: "/mocktest",
    name: "mocktest",
    meta: {
      title: "Practice Test",
    },
    children: [
      {
        path: "",
        name: "mocktest_self",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/testModules/TestModulesPage.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },
      {
        path: "digisatresult/:testId",
        name: "mocktest_digisatresult_testattempt",
        meta: {
          title: "Test Attempts",
        },
        children: [
          {
            path: "",
            name: "mocktest_digisatresult_testattempt_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
          {
            path: ":testInstanceId",
            name: "mocktest_digisatresult_testattempt_testinstanceid",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
              ),
            meta: {
              title: "Test Report",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/testwaitingpage",
    name: "testwaitingpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/testModules/TestBreakTimeScreen.vue"
      ),
  },
  {
    path: "/startmocktest/:testId",
    name: "startmocktest",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/testModules/StartTestPage.vue"
      ),
  },
  {
    path: "/prices",
    name: "prices",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/subscription/SubscriptionPricingPage.vue"
      ),
  },
  {
    path: "/subscriptionsuccess",
    name: "subscriptionsuccess",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/subscription/SubscriptionSuccussPage.vue"
      ),
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/profile/MyProfile.vue"
      ),
  },
  {
    path: "/rl/:referralId",
    name: "referralpage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/ReferralPage.vue"),
    meta: { noAuthNeeded: true },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  // Teacher Apps

  {
    path: "/teacher",
    name: "teacher",
    meta: {
      showInBreadcrumb: false,
    },
    children: [
      {
        path: "classes",
        name: "classes",
        meta: {
          title: "Classes",
        },
        children: [
          {
            path: "",
            name: "classes_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/teacher/ClassesListPage.vue"
              ),
          },
          {
            path: ":batchId",
            name: "classes_batchId",
            meta: {
              showInBreadcrumb: false,
            },
            children: [
              {
                path: ":classesTab",
                name: "classes_tab",
                meta: {
                  title: "Class Details",
                },
                children: [
                  {
                    path: "",
                    name: "classes_tab_self",
                    component: () =>
                      import(
                        /* webpackChunkName: "about" */ "../components/teacher/ClassDetailsPage.vue"
                      ),
                    meta: {
                      showInBreadcrumb: false,
                    },
                  },
                  {
                    path: "student/:studentId/:studentTab",
                    name: "classes_student_studentTab",
                    meta: {
                      title: "Student",
                    },
                    children: [
                      {
                        path: "",
                        name: "classes_student_self",
                        component: () =>
                          import(
                            /* webpackChunkName: "about" */ "../components/teacher/StudentDetailPage.vue"
                          ),
                        meta: {
                          showInBreadcrumb: false,
                        },
                      },
                      {
                        path: "digisatresult/:testId",
                        name: "classes_student_digisatresult_testId",
                        meta: {
                          title: "Test Attempts",
                        },
                        children: [
                          {
                            path: "",
                            name: "classes_student_digisatresult_testattempt",
                            component: () =>
                              import(
                                /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
                              ),
                            meta: {
                              showInBreadcrumb: false,
                            },
                          },
                          {
                            path: ":testInstanceId",
                            name: "classes_e_digisatresult_digisatresult",
                            component: () =>
                              import(
                                /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
                              ),
                            meta: {
                              title: "Test Report",
                            },
                          },
                        ],
                      },
                      {
                        path: "learnreports/:topicId",
                        name: "classes_student_practicereport",
                        component: () =>
                          import(
                            /* webpackChunkName: "about" */ "../components/newLearn/AllReports.vue"
                          ),
                        meta: {
                          title: "Report",
                        },
                      },
                    ],
                  },
                  {
                    path: "digisatresult/:testId",
                    name: "classes_digisatresult_testattempt",
                    meta: {
                      title: "Test Attempts",
                    },
                    children: [
                      {
                        path: "",
                        name: "classes_digisatresult_testattempt_self",
                        component: () =>
                          import(
                            /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
                          ),
                        meta: {
                          showInBreadcrumb: false,
                        },
                      },
                      {
                        path: ":testInstanceId",
                        name: "classes_digisatresult_testattempt_testinstanceid",
                        component: () =>
                          import(
                            /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
                          ),
                        meta: {
                          title: "Test Report",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "students",
        name: "students",
        meta: {
          title: "Students",
        },
        children: [
          {
            path: "",
            name: "students_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/teacher/StudentsListPage.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
          {
            path: ":studentId/:studentTab",
            name: "students_studentid_studenttab",
            meta: {
              title: "Student",
            },
            children: [
              {
                path: "",
                name: "students_studentid_studenttab_self",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/teacher/StudentDetailPage.vue"
                  ),
                meta: {
                  showInBreadcrumb: false,
                },
              },
              {
                path: "digisatresult/:testId",
                name: "students_digisatresult_testattempt",
                meta: {
                  title: "Test Attempts",
                },
                children: [
                  {
                    path: "",
                    name: "students_digisatresult_testattempt_self",
                    component: () =>
                      import(
                        /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
                      ),
                    meta: {
                      showInBreadcrumb: false,
                    },
                  },
                  {
                    path: ":testInstanceId",
                    name: "students_digisatresult_testattempt_testinstanceid",
                    component: () =>
                      import(
                        /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
                      ),
                    meta: {
                      title: "Test Report",
                    },
                  },
                ],
              },
              {
                path: "learnreports/:topicId",
                name: "student_practicereport",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/newLearn/AllReports.vue"
                  ),
                meta: {
                  title: "Report",
                },
              },
            ],
          },
        ],
      },
      {
        path: "aihub",
        name: "aihub",
        meta: {
          title: "Mento AI Hub",
        },
        children: [
          {
            path: "",
            name: "aihub_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/MentoAIHub.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
          {
            path: "mycollections",
            name: "my_collections",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/MyCollections.vue"
              ),
            meta: {
              title: "My Collections",
            },
          },
          {
            path: "readingAndWritingSnapAndSolve",
            name: "reading_and_writing_snap_and_solve",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/SnapAndSolve.vue"
              ),
            meta: {
              title: "Reading And Writing Snap And Solve",
            },
          },
          {
            path: "mathSnapAndSolve",
            name: "math_snap_and_solve",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/SnapAndSolve.vue"
              ),
            meta: {
              title: "Math Snap And Solve",
            },
          },
          {
            path: "englishPracticeQuestions",
            name: "englishpracticequestion",
            meta: {
              title: "English Practice Question",
            },
            children: [
              {
                path: "",
                name: "englishpracticequestion_self",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/QuestionGeneration.vue"
                  ),
                meta: {
                  showInBreadcrumb: false,
                },
              },
              {
                path: "mycollections",
                name: "my_collections_1",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/MyCollections.vue"
                  ),
                meta: {
                  title: "My Collections",
                },
              },
              {
                path: "question",
                name: "questions",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/teacherApp/AIQuestionsList.vue"
                  ),
                meta: {
                  title: "Questions",
                },
              },
            ],
          },
          {
            path: "pdfQuestions",
            name: "pdfQuestions",
            meta: {
              title: "PDF Questions",
            },
            children: [
              {
                path: "",
                name: "pdfQuestions_self",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/PDFQuestions.vue"
                  ),
                meta: {
                  showInBreadcrumb: false,
                },
              },
              {
                path: "mycollections",
                name: "pdfQuestions_self_my_collections_1",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/MyCollections.vue"
                  ),
                meta: {
                  title: "My Collections",
                },
              },
              {
                path: "question",
                name: "pdfQuestions_self_questions",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/teacherApp/AIQuestionsList.vue"
                  ),
                meta: {
                  title: "Questions",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  // Admin Panel
  {
    path: "/admin",
    redirect: { path: "/admin/1" },
    children: [
      {
        path: ":adminTab",
        name: "admin",
        meta: {
          title: "Admin",
        },
        children: [
          {
            path: "",
            name: "admin_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/admin/AdminDetailPage.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/parent",
    name: "parent",
    redirect: { path: "/parent/children" },
    meta: {
      showInBreadcrumb: false,
    },
    children: [
      {
        path: "children",
        name: "children",
        meta: {
          title: "Children",
        },
        children: [
          {
            path: "",
            name: "children_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/parent/ChildrenListPage.vue"
              ),
          },
          {
            path: ":studentId/:studentTab",
            name: "parent_students_studentid_studenttab",
            meta: {
              title: "Child",
            },
            children: [
              {
                path: "",
                name: "parent_students_studentid_studenttab_self",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/parent/ChildDetailPage.vue"
                  ),
                meta: {
                  showInBreadcrumb: false,
                },
              },
              {
                path: "digisatresult/:testId",
                name: "parent_students_digisatresult_testattempt",
                meta: {
                  title: "Test Attempts",
                },
                children: [
                  {
                    path: "",
                    name: "parent_students_digisatresult_testattempt_self",
                    component: () =>
                      import(
                        /* webpackChunkName: "about" */ "../components/testModules/TestAttemptPage.vue"
                      ),
                    meta: {
                      showInBreadcrumb: false,
                    },
                  },
                  {
                    path: ":testInstanceId",
                    name: "parent_students_digisatresult_testattempt_testinstanceid",
                    component: () =>
                      import(
                        /* webpackChunkName: "about" */ "../components/testReport/TestReport.vue"
                      ),
                    meta: {
                      title: "Test Report",
                    },
                  },
                ],
              },
              {
                path: "learnreports/:topicId",
                name: "parent_student_practicereport",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/newLearn/AllReports.vue"
                  ),
                meta: {
                  title: "Report",
                },
              },
            ],
          },
        ],
      },
      {
        path: "aihub",
        name: "parent_aihub",
        meta: {
          title: "Mento AI Hub",
        },
        children: [
          {
            path: "",
            name: "parent_aihub_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/MentoAIHub.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
          {
            path: "mycollections",
            name: "parent_my_collections",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/ai/MyCollections.vue"
              ),
            meta: {
              title: "My Collections",
            },
          },
          {
            path: "englishPracticeQuestions",
            name: "parent_englishpracticequestion",
            meta: {
              title: "English Practice Question",
            },
            children: [
              {
                path: "",
                name: "parent_englishpracticequestion_self",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/QuestionGeneration.vue"
                  ),
                meta: {
                  showInBreadcrumb: false,
                },
              },
              {
                path: "mycollections",
                name: "parent_my_collections_1",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/ai/MyCollections.vue"
                  ),
                meta: {
                  title: "My Collections",
                },
              },
              {
                path: "question",
                name: "parent_questions",
                component: () =>
                  import(
                    /* webpackChunkName: "about" */ "../components/teacherApp/AIQuestionsList.vue"
                  ),
                meta: {
                  title: "Questions",
                },
              },
            ],
          },
        ],
      },
    ],
  },

  //404page redirect
  {
    path: "/:notFound",
    redirect: { path: "/" },
  },
  {
    path: "/studentaihub",
    name: "student_aihub",
    meta: {
      title: "Mento AI Hub",
    },
    children: [
      {
        path: "",
        name: "student_aihub_self",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/ai/MentoAIHub.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },     
      {
        path: "readingAndWritingSnapAndSolve",
        name: "student_reading_and_writing_snap_and_solve",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/ai/SnapAndSolve.vue"
          ),
        meta: {
          title: "Reading And Writing Snap And Solve",
        },
      },
      {
        path: "mathSnapAndSolve",
        name: "student_math_snap_and_solve",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/ai/SnapAndSolve.vue"
          ),
        meta: {
          title: "Math Snap And Solve",

        },
      } 
    ],
  },
  {
    path: "/resource",
    name: "Resource",
    meta: {
      title: "Resource Center",
    },
    children: [
      {
        path: '', name: 'resource_self', component: () => import(/* webpackChunkName: "about" */ '../components/admin/AdminResourcePage.vue'), meta: {
          showInBreadcrumb: false
        }
      }
    ]
  },
  {
    path: "/studentResource",
    name: "StudentResource",
    meta: {
      title: "A Resource Center",
    },
    children: [
      {
        path: '', name: 'studentresource_self', component: () => import(/* webpackChunkName: "about" */ '../components/teacherApp/StudentResourcePage.vue'), meta: {
          showInBreadcrumb: false
        }
      }
    ]
  },
  {
    path: "/rc",
    name: "rc",
    meta: {
      title: "A Resource Center",
      noAuthNeeded: true
    },
    children: [
      {
        path: "f/:fileId",
        name: "rc_folder",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/teacherApp/StudentResourceContent.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },
      {
        path: "f/:fileId/c/:contentId",
        name: "rc_content",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../components/teacherApp/StudentResourceContent.vue"
          ),
        meta: {
          showInBreadcrumb: false,
        },
      },
    ],
  },

  // saved
  {
    path: "/saved",
    redirect: { path: "/saved/1" },
    children: [
      {
        path: ":savedTab",
        name: "saved",
        meta: {
          title: "Saved",
        },
        children: [
          {
            path: "",
            name: "saved_self",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../components/saved/SavedDetailPage.vue"
              ),
            meta: {
              showInBreadcrumb: false,
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_HOME_PATH = "/learn";
const DEFAULT_TEACHER_HOME_PATH = "/teacher/students";
const DEFAULT_ADMIN_HOME_PATH = "/admin/1";
const DEFAULT_PARENT_HOME_PATH = "/parent/children";
router.beforeEach(async (to, from) => {
  // console.log("to", to);
  // console.log("from", from);

  const homePath =
    getParentView() == "true"
      ? DEFAULT_PARENT_HOME_PATH
      : getAdminView() == "true"
      ? DEFAULT_ADMIN_HOME_PATH
      : getTeacherView() == "true"
      ? DEFAULT_TEACHER_HOME_PATH
      : DEFAULT_HOME_PATH;

  if (
    to.path.startsWith("/testseries") ||
    ("nav" in to.meta && to.meta.nav == true)
  ) {
    store.commit("appbarVisible", true);
    store.commit("navbarVisible", true);
  }

  if (to.path == "/testquestionpage" && !store.getters.testRoutingAllowed) {
    // console.log("sending user back to test");
    return {
      path: "/learn",
    };
  }
  if (to.path == "/testsubmission" && !store.getters.testRoutingAllowed) {
    console.log("sending user back to test2");
    return {
      path: "/learn",
    };
  }
  if (to.path == "/practicequestionpage" && !store.getters.testRoutingAllowed) {
    console.log("sending user back to Learn");
    return {
      path: "/learn",
    };
  }

  if (
    "checkRouteForChallenge" in to.meta &&
    to.meta.checkRouteForChallenge == true &&
    !store.getters.testRoutingAllowed
  ) {
    console.log("sending user back to test2");
    return {
      path: "/challenge",
    };
  }

  if (from.name == "participantwaitingroom") {
    if (to.name == "challengestartingscreen") {
      // nothing
    } else {
      // leave as participant
      if (store.getters.ablyChannel != null) {
        let channel = store.getters.ablyChannel;
        let ablyRealTime = store.getters.ablyRealTime;

        channel.presence.leave();
        channel.presence.unsubscribe();
        if (ablyRealTime != null) {
          console.log("close ably");
          setTimeout(() => {
            ablyRealTime.close();
          }, 1000);
        }
      }
    }
  }
  if (from.name == "hostwaitingroom") {
    if (to.name == "challengestartingscreen") {
      // nothing
    } else {
      // leave as host
      if (store.getters.ablyChannel != null) {
        let channel = store.getters.ablyChannel;
        let ablyRealTime = store.getters.ablyRealTime;

        channel.presence.leave();
        channel.presence.unsubscribe();
        await channel.publish("hostLeft", {});
        if (ablyRealTime != null) {
          console.log("close ably");
          setTimeout(() => {
            ablyRealTime.close();
          }, 1000);
        }
      }
    }
  }

  if (!to.meta.noAuthNeeded && !isUserLoggedIn()) {
    return {
      path: "/",
      // save the location we were at to come back later
      //query: { redirect: to.fullPath },
    };
  } else if (to.path === "/" && isUserLoggedIn()) {
    return {
      path: homePath,
    };
  } else if (
    ["/register", "/domain", "/welcome", "/login"].includes(to.path) &&
    isUserLoggedIn()
  ) {
    return {
      path: homePath,
    };
  } 
  // else if (
  //   ["/mocktest", "/testwaitingpage", "/testmodulequestionpage"].includes(
  //     to.path
  //   ) &&
  //   isUserLoggedIn()
  // ) {
  //   return {
  //     path: homePath,
  //   };
  //   // } else if(['/prices', '/subscriptionsuccess'].includes(to.path) && (localStorage.getItem("newUser") != "true" && localStorage.getItem("newLearn") != "true")){
  //   //   return {
  //   //     path: DEFAULT_HOME_PATH
  //   //   }
  // }
});

//const DEFAULT_TITLE = 'MentoMind';
router.afterEach(() => {});

export default router;
