<template>
	<!-- Navbar -->
	<v-app-bar color="breadCrumbBackColor"  v-if="dataReady"> 

		<!-- Title -->
		<v-app-bar-title>
			<template v-slot:text>
				<span class>
					Test Name
				</span>

			</template>

		</v-app-bar-title>

		<!-- Menu -->
		<v-menu :class="mdAndUp ? 'mr-5' : 'mr-2'">
			<template v-slot:activator="{ props }">
				<v-btn color="white" icon="mdi-dots-vertical" v-bind="props">
				</v-btn>
			</template>

			<v-list>
				<v-list-item @click="exitPractice()">
					<v-list-item-title>Leave practice</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</v-app-bar>


	<!-- Main -->
	<div class="bgw"  v-if="dataReady">

		<div class="bgWColor">


			<!-- Feedback/report form-->
			<!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
				<FeedbackComponent @closeDialog="reportDialog = false" test-type="quiz" :q-id="currentQ.qId"
					:test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId" :currentQ="currentQ"></FeedbackComponent>
			</v-dialog> -->

			<DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false"  
			test-type="quiz" :q-id="currentQ.qId"
			:test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId"
            :q-obj="currentQ"></DragableFeedbackDialog>

			<!-- Question Space Begins -->
			<v-container v-if="!showSolution" fluid class="pb-8">
				<v-row justify="center" :class="{ 'w-70': $vuetify.display.mdAndUp }">
					<v-col cols="12">
						<v-card elevation="0" class="pb-14 pt-6" color="transparent">

							<section class="mx-5">
								<div class="mb-12">
									<v-row class="align-center" style="width: 100%;">
										<span class="mx-3 qNoClass font-weight-bold">
											Question 2
										</span>

										<!-- Button for Crossed Out Options -->
										<div v-if="currentQ.questionTypeId == '1' || currentQ.questionTypeId == '2'">
											<div class="" v-if="currentQResponse.crossedOutOptions">
												<v-tooltip text="Cross out options" location="end">
													<template v-slot:activator="{ props }">
														<v-btn elevation="0" theme="dark" v-bind="props" size="small"
															@click="hideCrossedOutOptions()" color="primaryPurple"
															icon="mdi-alphabetical-variant-off"></v-btn>
													</template>
												</v-tooltip>
											</div>
											<div class="" v-else>
												<v-tooltip text="Cross out options" location="end">
													<template v-slot:activator="{ props }">
														<v-btn size="small" v-bind="props" class="outline-color"
															variant="outlined" @click="showCrossedOutOptions()"
															icon="mdi-alphabetical-variant-off"></v-btn>
													</template>
												</v-tooltip>
											</div>
										</div>

										<v-spacer></v-spacer>

										<!-- Button to show report/feedback dialog -->
										<v-tooltip text="Report a problem" location="end">
											<template v-slot:activator="{ props }">
												<v-btn size="small" v-bind="props" variant="outlined" class="outline-color"
													@click="showReportDialog()" icon="mdi-message-alert-outline"></v-btn>
											</template>
										</v-tooltip>
									</v-row>
								</div>

								<!-- Question -->
								<section>
									<HtmlRender class="htmlClass" :html-string="currentQ.questionDesc"></HtmlRender>
								</section>

								<!-- Options -->
								<div class="mt-15">

									<!-- case 1 -->
									<section v-if="currentQ.questionTypeId == '1'">
										<v-radio-group v-model="currentQResponse.checkedOption" :ripple="false"
											@update:modelValue="radioGroupUpdate()">

											<v-row v-for="option in currentQ.options" :key="option.key"
												style="position: relative;" align="center">

												<v-col cols="" class="">
													<v-hover v-slot="{ isHovering, props }">

														<v-sheet
															:class="{ 'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': currentQResponse.checkedOption == option.key }"
															v-bind="props" class="pa-0 pl-2 ma-0  bx"
															style="position: relative;">

															<div class="strike"
																v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
															</div>

															<v-radio :ripple="false" density="compact" color="white"
																class="ma-0 pa-0 w-100  pr-3" dense :value="option.key"
																:class="{
																	'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
																}">
																<div class="strike"
																	v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
																</div>
																<template v-slot:label>


																	<div class="htmlClassTest htmlClassTestOption ml-1 py-4 w-100"
																		v-html="getMarkDownHtml(option.value)"> </div>
																</template>
															</v-radio>
														</v-sheet>
													</v-hover>
												</v-col>
												<v-col cols="auto" class="px-0 mx-0"
													v-if="currentQResponse.crossedOutOptions">
													<v-avatar
														class="outline-color text-capitalize text-decoration-line-through"
														variant="outlined" elevation="0" size="24"
														v-if="!checkOptionExistsInCrossedList(option.key)"
														@click="updateCrossedOptions(option.key, true)"
														style="cursor: pointer;">
														{{ option.key }}

													</v-avatar>
													<v-avatar v-else class="outline-color " elevation="0" size="24"
														@click="updateCrossedOptions(option.key, false)"
														style="cursor: pointer;">
														<v-icon>
															mdi-rotate-right
														</v-icon>
													</v-avatar>

												</v-col>
											</v-row>

										</v-radio-group>
									</section>

									<!-- case 2 -->
									<section v-if="currentQ.questionTypeId == '2'">

										<v-row v-for="option in currentQ.options" :key="option.key" align="center">
											<v-col cols="">
												<v-hover v-slot="{ isHovering, props }">


													<v-sheet :class="{
														'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': getMultichoiceSelected(option.key)
													}" v-bind="props" class="pa-0 pl-2 ma-0 bx" style="position: relative;">
														<div class="strike"
															v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
														</div>

														<v-checkbox density="compact" multiple hide-details size
															color="white" v-model="currentQResponse.checkedOptions"
															class=" tqp ma-0 pa-0 w-100 testOption  pr-3" dense
															:value="option.key" @update:modelValue="checkBoxesUpdate"
															:class="{
																'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
															}">

															<template v-slot:label>

																<div class="htmlClassTest htmlClassTestOption ml-1 py-4 w-100 "
																	v-html="getMarkDownHtml(option.value)"> </div>

															</template>
														</v-checkbox>
													</v-sheet>

												</v-hover>
											</v-col>
											<v-col cols="auto" class="px-0 mx-0" v-if="currentQResponse.crossedOutOptions">
												<v-avatar class="outline-color text-capitalize text-decoration-line-through"
													variant="outlined" elevation="0" size="24"
													v-if="!checkOptionExistsInCrossedList(option.key)"
													style="cursor: pointer;"
													@click="updateCrossedOptions(option.key, true)">
													{{ option.key }}

												</v-avatar>
												<v-avatar v-else class="outline-color " elevation="0" size="24"
													@click="updateCrossedOptions(option.key, false)"
													style="cursor: pointer;">
													<v-icon>
														mdi-rotate-right
													</v-icon>
												</v-avatar>

											</v-col>
										</v-row>

									</section>

									<!-- case 3-->
									<section v-if="currentQ.questionTypeId == '3'">

										<v-row>
											<v-col cols="8" md="3" lg="2">
												<v-text-field label="Answer" v-model="currentQResponse.integerOption"
													variant="outlined"></v-text-field>

											</v-col>

										</v-row>

									</section>

									<!-- case 4-->
									<section v-if="currentQ.questionTypeId == '4'">

										<v-row>
											<v-col cols="8" md="3" lg="2">
												<v-text-field label="Answer" v-model="currentQResponse.decimalOption"
													variant="outlined"></v-text-field>

											</v-col>

										</v-row>

									</section>


								</div>
							</section>
						</v-card>
					</v-col>
				</v-row>
			</v-container>

			<PracticeResult v-else :currentQuestion="currentQ" :currentQResponseProp="currentQResponse"
				:currentQSolutionProp="currentQSolution" :practiceInfo="practiceInfo" />

			<!-- Footer -->
			<v-footer app class="bg-background" elevation="2">
				<v-row justify="space-between" class="  pa-5 align-center">

					<v-col class="align-center" cols="auto">

						<!-- Skip Button -->
						<template v-if="!isNextQuestion">
							<v-btn v-if="$vuetify.display.smAndUp"
								class="t-btn text-capitalize btn-grad  text-primaryTextColor"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0"
								@click="validateQuestion(true)">
								Skip
								<template v-slot:append>
									<v-icon color="white">mdi-chevron-right</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" size="large" icon="mdi-table-check"
								@click="validateQuestion(true)" color="primaryPurple">
							</v-btn>
						</template>
					</v-col>

					<!-- Clear Response Button -->
					<!-- <v-col class=" align-center">
						<v-btn v-if="$vuetify.display.mdAndUp" size="large" class="t-btn text-none text-black ml-3"
							@click="clearResponse" elevation="0" color="transparent">
							Clear response
							<template v-slot:prepend>
								<v-icon color="primaryPurple">mdi-rotate-right</v-icon>
							</template>
						</v-btn>
						<v-icon v-else class="ml-3" color="primaryPurple" @click="clearResponse" elevation="0">
							mdi-rotate-right
						</v-icon>
					</v-col> -->

					<v-col cols="auto">

						<!-- Next Button -->
						<template v-if="isNextQuestion">
							<v-btn v-if="$vuetify.display.smAndUp"
								class="t-btn text-capitalize btn-grad  text-primaryTextColor"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0" @click="nextQuestion">
								Next
								<template v-slot:append>
									<v-icon color="white">mdi-chevron-right</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" size="large" icon="mdi-chevron-right" @click="nextQuestion"
								color="primaryPurple">
							</v-btn>
						</template>

						<!-- Validate Button -->
						<template v-else>
							<v-btn v-if="$vuetify.display.smAndUp" :disabled="!enableCheck"
								:color="enableCheck ? 'secondaryBackground' : undefined"
								class="t-btn text-capitalize btn-grad  text-primaryTextColor"
								:size="$vuetify.display.smAndUp ? 'large' : 'default'"
								:width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0"
								@click="validateQuestion(false)">
								Check
								<template v-slot:append>
									<v-icon color="white">mdi-chevron-right</v-icon>
								</template>
							</v-btn>
							<v-btn v-else density="compact" :disabled="!enableCheck"
								:color="enableCheck ? 'secondaryBackground' : undefined" size="large" icon="mdi-table-check"
								@click="validateQuestion(false)">
							</v-btn>
						</template>
					</v-col>


				</v-row>
			</v-footer>

		</div>

		<div class="chatbot-box">
			<Transition name="slide-fade">

				<v-card class="chatbot-card" elevation="0" v-if="chatbot"
					:width="$vuetify.display.mdAndUp ? '400px' : '300px'">
					<v-card-text class="pa-0 ma-0">
						<v-row justify="end" align="center" class="chatbot-header pa-0 ma-0">
							<v-col cols="auto">
								<v-avatar size="30">
									<v-img src="@/assets/mento_win.png"></v-img>

								</v-avatar>

							</v-col>

							<v-col cols="auto" class="text-primaryTextColor t-h5 ">
								Mento
							</v-col>
							<v-spacer>

							</v-spacer>
							<v-col cols="auto">
								<v-icon @click="chatbot = false" color="primaryTextColor">
									mdi-close
								</v-icon>
							</v-col>
						</v-row>
						<v-sheet class="px-4 pt-4 pb-4  chatbot-bg">
							<v-sheet v-for="msg in currentMessagesList" :key="msg.messageId">

								<v-row v-if="msg.type == 'nudge'" class="ma-0 pa-0">
									<v-col cols="auto" class="t-h6Reg ma-0 pa-0 my-2" >
										<v-sheet class="bot-msg py-2 px-3 text-white">
											{{ msg.message }}
										</v-sheet>

									</v-col>
								</v-row>

								<v-row v-if="msg.type == 'nudge' && msg.responseCard != null"  class="ma-0 pa-0">
									<v-col cols="" v-for="(attatchment, index) in msg.responseCard.genericAttachments"
										:key="index" class="ma-0 pa-0">
											<v-row v-for="(btn, index) in attatchment.buttons" :key="index" class="ma-0 pa-0">
												<v-col cols="auto" class="t-h6Reg ma-0 pa-0 my-2" >

												<v-sheet class="text-white">
													<v-btn variant="outlined" color="primaryPurple" class="btn-rd text-decoration-none text-none t-h6Reg">
														<span class="text-decoration-none text-none t-h6Reg">
															{{ btn.text }}

														</span>
													</v-btn> </v-sheet>
													</v-col>

											</v-row>
									</v-col>
								</v-row>
								<v-row v-if="msg.type == 'user'" justify="end" class="ma-0 pa-0">
									<v-col cols="auto" class="t-h6Reg ma-0 pa-0 my-2">
										<v-sheet class="user-msg py-2 px-3 text-white">
											{{ msg.message }}
										</v-sheet>

									</v-col>
								</v-row>

							</v-sheet>

						</v-sheet>

					</v-card-text>
				</v-card>
			</Transition>
		</div>
		<div class="chatbot">
			<!-- <v-card class="px-8 py-10 " v-if="chatbot">
				<v-card-text>
					<v-row justify="end">
						<v-icon @click="chatbot = false">
							mdi-close
						</v-icon>
					</v-row>
					<v-row>
						hello
					</v-row>
					<v-row class="my-10">
						hi
					</v-row>
				</v-card-text>
			</v-card> -->
			<Transition name="slide-fade">

				<v-avatar size="50" @click="chatbot = true" v-if="!chatbot">
					<v-img src="@/assets/mento_win.png"></v-img>

				</v-avatar>
			</Transition>

		</div>


	</div>
</template>
    
<script>
import { mapState } from "vuex";
import _ from "lodash";
import { useDisplay } from "vuetify";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import PracticeResult from "./PracticeResult.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import html2canvas from 'html2canvas';
import router from "@/router";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
	return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
	name: 'TestQuestionPage',
	setup() {
		// Destructure only the keys we want to use
		const { mdAndDown, xs, mdAndUp } = useDisplay();

		return { xs, mdAndDown, mdAndUp };
	},
	unmounted() {
		this.$store.commit("updateShowBackground", true);
	},
	async created() {
		this.dataReady = false;
		this.$store.commit("updateShowBackground", false);

		// redirect if come again
		this.$store.commit("updateTestRoutingAllowed", false);

		// Current time for question
		this.currentQDuration.startTimeStamp = Date.now();
		console.log(this.currentQ);
		await this.$store.dispatch("updatePracticeNudgeInfo",
			{
				qType: this.currentQ.questionTypeId,
				mentoHelpMode: "practiceQuestion",
				subjectId: this.currentQ.subjectId
			}
		)
		let msg = await this.$store.dispatch("getPracticeNudgeMessage",
			{
				nudgeId: this.practiceNudgeInfo.nudgeId ?? ''
			}
		)
		console.log(msg);
		this.currentMessagesList = msg
		this.currentMessagesList.push({ message: "hello", type: "user", messageId: "1" })

		// data ready only when the question formulas are set
		// setTimeout(() => {
		// 	window.MathJax.typesetPromise();
		this.dataReady = true
		// }, 0);

	},
	components: {
		DragableFeedbackDialog, HtmlRender, PracticeResult,

	},
	data: () => ({
		dataReady: false,
		reportDialog: false,
		chatbot: false,
		currentMessagesList: [],
		currentQDuration: {
			qId: "",
			startTimeStamp: '',
			endTimeStamp: ''
		},
		currentQResponse: {
			qId: "",
			qType: "",
			checkedOption: "",
			checkedOptions: [],
			integerOption: "",
			decimalOption: "",
			markedForReview: false,
			crossedOutOptions: false,
			crossedOutOptionsList: []
		},
		defualtCurrentQResponse: {
			qId: "",
			qType: "",
			checkedOption: "",
			checkedOptions: [],
			integerOption: "",
			decimalOption: "",
			markedForReview: false,
			crossedOutOptions: false,
			crossedOutOptionsList: []
		},
		currentQSolution: {},
		isNextQuestion: false,
		isSkipped: false,
		testId: '',
		testInstanceId: '',
		enableCheck: false,
		showSolution: false,
	}),
	methods: {
		// **** keep **** //
		radioGroupUpdate() {
			console.log("update");
			let currentVal = this.currentQResponse.checkedOption
			if (currentVal == '') {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
			this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== currentVal)



		},
		// **** keep **** //
		checkBoxesUpdate(val) {
			console.log("update checkbox", val);
			let currentVals = this.currentQResponse.checkedOptions
			if (currentVals == []) {
				this.enableCheck = false;
			}
			else {
				this.enableCheck = true;
			}
			this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !currentVals.includes(item))

		},
		// **** keep **** //
		showCrossedOutOptions() {
			this.currentQResponse.crossedOutOptions = true
			// let val = null
			if (this.currentQ.qType == "1") {
				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== this.currentQResponse.checkedOption)
			}

			if (this.currentQ.qType == "2") {

				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !this.currentQResponse.checkedOptions.includes(item))

			}
		},
		// **** keep **** //
		hideCrossedOutOptions() {
			this.currentQResponse.crossedOutOptions = false
		},
		// **** keep **** //
		checkOptionExistsInCrossedList(val) {
			let find = this.currentQResponse.crossedOutOptionsList.find((e) => e == val) ?? null
			if (find == null) {
				return false
			}
			return true

		},
		// **** keep **** //
		updateCrossedOptions(val, add) {
			if (add) {
				this.currentQResponse.crossedOutOptionsList.push(val)
				if (this.currentQ.qType == "1") {
					if (this.currentQResponse.checkedOption == val) {
						this.currentQResponse.checkedOption = ""
					}
				}
				if (this.currentQ.qType == "2") {
					console.log(val)
					if (this.currentQResponse.checkedOptions.includes(val)) {
						this.currentQResponse.checkedOptions = this.currentQResponse.checkedOptions.filter(item => item !== val)
					}
				}
			}
			else {
				this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== val)

			}
			console.log(this.currentQResponse);
		},
		// **** keep **** //
		getMultichoiceSelected(key) {
			return this.currentQResponse.checkedOptions.includes(key)
		},
		// **** keep **** //
		getCurrentClientTimeInSeconds() {
			return Math.floor(Date.now() / 1000)
		},
		// **** keep **** //
		startTimer() {
			this.intervalId = setInterval(() => {
				this.remaningTimeInSeconds = (this.remaningTimeInSeconds - 1)
				this.serverTimeCounter += 1
			}, 1000);
		},
		minTwoDigits(n) {
			return (n < 10 ? '0' : '') + n;
		},
		// **** keep **** //
		getMarkDownHtml(markdown) {
			if (markdown == null) {
				return ""
			}
			let markDownText = markdown
			let out = false;
			const regex = /\\/gm;
			const escapedString = markDownText.replace(regex, "\\\\");

			out = marked.parseInline(escapedString, { renderer });

			return out;
		},
		// **** keep **** //
		nextQuestion() {
			// add scroll here
			if (this.currentQSolution.nextQId == null || this.currentQSolution.nextQId == '') {
				this.$store.commit('appbarVisible', true);
				this.$store.commit('navbarVisible', true);
				return this.$router.back();
			}
			else {
				let body = {
					testInstanceId: this.practiceInfo.testInstanceId,
					testId: this.practiceInfo.testId,
					questionId: this.currentQSolution.nextQId,
				}

				this.$store.dispatch('nextPractice', body)
					.then(async () => {
						this.currentQ = this.practiceInfo.question;
						this.currentQResponse = _.cloneDeep(this.defualtCurrentQResponse);
						this.showSolution = false;
					})
				this.enableCheck = false;
				this.isNextQuestion = false;
				this.currentQDuration.startTimeStamp = Date.now();

				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});

				setTimeout(() => {
					window.MathJax.typesetPromise();
				}, 0);
			}

		},
		// ******** keep ******
		clearResponse() {
			this.currentQResponse.checkedOption = ""
			this.currentQResponse.checkedOptions = []
			this.currentQResponse.integerOption = ""
			this.currentQResponse.decimalOption = ""
			this.enableCheck = false;
		},
		showReportDialog() {
			this.reportDialog = true
		},

		// new methods
		validateQuestion(isSkipped) {
			this.currentQDuration.qId = this.currentQ.qId;
			this.currentQDuration.endTimeStamp = Date.now();

			let responseBody = {};
			if (isSkipped) {
				this.isSkipped = isSkipped;
				this.currentQResponse = _.cloneDeep(this.defualtCurrentQResponse);
				this.currentQResponse.qId = this.currentQ.qId;
				this.currentQResponse.difficultyLevel = this.currentQ.difficultyLevel == ""? "0" : this.currentQ.difficultyLevel;

				responseBody = {
					submit: false,
					topicId: this.currentQ.topicId,
					qDuration: this.currentQDuration,
					qResponse: this.currentQResponse
				}
			}
			else {
				this.currentQResponse.qId = this.currentQ.qId;
				this.currentQResponse.qType = this.currentQ.qType;
				this.currentQResponse.difficultyLevel = this.currentQ.difficultyLevel == ""? "0" : this.currentQ.difficultyLevel;
				this.isSkipped = isSkipped;
				responseBody = {
					submit: false,
					topicId: this.currentQ.topicId,
					qDuration: this.currentQDuration,
					qResponse: this.currentQResponse
				}
			}

			let body = {
				testId: this.practiceInfo.testId,
				testInstanceId: this.practiceInfo.testInstanceId,
				responseBody: responseBody
			}
			this.$store.dispatch("validatePracticeQuestion", body)
				.then(async () => {
					this.currentQSolution = this.validatePracticeResponse;
					this.showSolution = true;
				});
			this.isNextQuestion = true;
		},
		exitPractice() {
			this.$router.back();
		}
	},
	computed: {
		...mapState(["practiceInfo", "validatePracticeResponse", "practiceNudgeInfo"]),

		currentQ: {
			get() {
				return this.practiceInfo.question;
			}
		}
	},
	watch: {
		// currentQResponse:{
		// 	deep: true,
		// 	handler(newValue){
		// 		console.log("current Q response watcher", newValue);
		// 		let temp = newValue;
		// 		console.log("temp value is ", temp);
		// 		if (temp.checkedOption == '' && temp.checkedOptions === [] && temp.decimalOption === "" && temp.integerOption === ''){
		// 			// console.log("current Q response watcher", newValue);
		// 			this.enableCheck= false;
		// 		}
		// 		else{
		// 			this.enableCheck = true;
		// 		}
		// 		console.log("enableCheck", this.enableCheck);

		// 	}
		// }
	}
}
</script>


<style scoped>
.btn-rd{
	border-radius: 30px !important;

}
.bot-msg {
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
	border-radius: 30px;

}

.user-msg {
	background-color: rgb(var(--v-theme-primaryGreen)) !important;
	border-radius: 30px;

}

.chatbot-card {
	border-radius: 15px;
	border-color: transparent;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.chatbot-bg {
	background-color: white !important;
	;
}

.chatbot {
	position: fixed;
	right: 30px;
	bottom: 12%;
}

.chatbot-box {
	position: fixed;
	right: 20px;
	bottom: 12%;

}

.chatbot-header {
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
}

.slide-fade-enter-active {
	transition: all 0.3s linear;
}

.slide-fade-leave-active {
	transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(100px);
	opacity: 0;
}

.correct {
	background: #26B99AA6 !important;
	color: white !important;
	;
}

.correctQ {
	background: #26B99A;

}

.img {
	vertical-align: middle;
}

.success {
	background: #26B99AA6 !important;

}

.totalQ {
	background: rgba(255, 255, 255, 0.15);

}

.timer-color {
	color: #26B99A !important;
}

.bgW {
	height: 100% !important;
	width: 100%;
	background-color: white;
}

.bgWColor {
	width: 100%;
	background-color: white;
}

.bgP {
	height: 100% !important;
	width: 100%;
	background-color: #2B2B45;
}

.bgPColor {
	background-color: #2B2B45;

}

.unanswered {
	border: 2px solid #949494 !important;
}

.unansweredQ {
	background: rgba(255, 255, 255, 0.15);
}

.marked {
	border: 4px solid #FF894F !important;
}

.markedQ {
	border: 4px solid #FF894F !important;
}

.btn-grad {
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
	border-radius: 29.4079px;
}

.btn-prev {
	border-radius: 29.4079px;
}

.bgWhite {
	background-color: white !important
}

.disabledOpacity {
	opacity: var(--v-disabled-opacity);
}

.option {

	/* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
	border-radius: 10px;
	border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.subColor {
	color: rgba(255, 255, 255, 0.5) !important;
}

.v-sheet.on-hover {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: white;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.redTimer {
	color: #FF786E !important;
}

.w-70 {
	max-width: 70%;
}

.submitFont {
	font-size: 0.7841875rem;
	font-family: "Inter", sans-serif !important;

}

.blink {
	animation: blinker 1s step-start infinite;
}

.strike {
	position: absolute;
	width: calc(100%);
	height: 2px;
	background: rgb(var(--v-theme-primaryPurple));
	left: 0px;
	top: 50%;
	opacity: 1 !important;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.bx {
	background-color: rgb(var(--v-theme-navColor));
	border: 1px solid #E7E7E7;
	border-radius: 4px;
}

.card-bg-color {
	background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
	border-radius: 12px;
	background: #26253A;

}

.outline-color {
	border-color: rgba(0, 0, 0, 0.2) !important;

}

.bottom {
	position: absolute !important;
	bottom: 10px !important;
}

.v-overlay__content {
	position: absolute !important;
	bottom: 10px !important;

}

.layoutLarge {
	height: calc(100% - 160px);
}

.scroll {}

.blink {
	animation: blinker 1s step-start infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.selected {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: rgb(var(--v-theme-primaryPurple)) !important;
	color: white !important
}

.qNoClass {
	font-size: 0.875rem;
	font-family: "Inter", sans-serif !important;
}

.dialog-bx {
	position: fixed !important;
}
</style>
<style>
.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
	opacity: 1 !important;
}

.v-label {

	opacity: 1 !important;
}
</style>