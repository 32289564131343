<template>
    <v-row
    class="ma-0 pt-4 px-4"
    :class="{ heightBread: $vuetify.display.mdAndUp }"
  >
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="breadCrumbs" class="no-new-line t-h6Reg text-white  text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn
            v-if="breadCrumbs.length > 0"
            color="#26253A" class="ma-0 mr-2 text-primaryTextColor "
            size="x-small"
            @click="back()"
            icon="mdi-chevron-left"
          >
          </v-btn>
          <v-icon icon="mdi-trophy-outline" color="primary"></v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>
  <v-layout class="mb-0 pb-0" v-if="dataLoaded" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
    <v-container fluid class="d-flex mt-3 mt-md-0 mb-0 pb-0">
      <v-row v-if="!$vuetify.display.xs" class="mt-0 pt-0">
        <v-col class="mt-0 pt-0 justify-center align-center" :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
          <v-container fluid class="px-2 mt-0 pt-0">
            <v-row justify="center" class="mb-0" align="center">
              <v-col cols="12">
                <!-- <v-row class="t-h5 text-center mb-4" justify="center">
                  {{ currentTopic ? currentTopic.entityLabel : "" }}
                </v-row> -->
              </v-col>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-card elevation="0" :class="{
                        'on-hover': isHovering,
                        'report-container': $vuetify.display.mdAndUp,
                        'report-container-tablet': !$vuetify.display.mdAndUp,
                      }" v-bind="props">
                        <v-card-text class="pa-4">
                          <v-row class="pa-4">
                            <v-col cols="4">
                              <v-row>
                                <v-col>
                                  <div>
                                    <span class="font-weight-bold text-result text-white">{{
                                      getChallengeResult(challengeReportData) }}</span>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col align-self="center" class="d-flex">
                                  <div v-for="(item, index) in challengeReportData" :key="index" class="avatar-character">
                                    <div class="winner-character">
                                      <v-img v-if="item.winner" src="@/assets/winner.svg"></v-img>
                                    </div>
                                    <v-avatar :color="item.self ? 'primaryPurple' : 'primaryGreen'
                                      " size="78">
                                      <v-img v-if="item.challengeType == 'aigenerated' && !item.self " src="@/assets/mento_win.png"></v-img>
                                      <span v-else class="text-font-10rem">{{
                                        getInitials(item)
                                      }}</span>
                                    </v-avatar>
                                  </div>
                                </v-col>
                              </v-row>

                            </v-col>
                            <v-col cols="8">
                              <v-row justify="space-around">
                                <v-col cols="4">
                                  <span class="text-font-8rem text-start text-white">Summary</span>
                                </v-col>
                                <v-col cols="2"></v-col>
                                <v-col cols="3">
                                  <span class="text-font-8rem font-weight-bold text-start" :class="challengeReportData[0]?.self
                                    ? 'text-color-purple'
                                    : 'text-color-green'
                                    ">{{ getFirstName(challengeReportData[0]) }}</span>
                                </v-col>
                                <v-col cols="3">
                                  <span class="text-font-8rem font-weight-bold text-start" :class="challengeReportData[1]?.self
                                    ? 'text-color-purple'
                                    : 'text-color-green'
                                    ">{{ getFirstName(challengeReportData[1]) }}</span>
                                </v-col>
                              </v-row>
                              <template v-for="(item, i) in defaultReportDetails" :key="i">
                                <v-row justify="space-around">
                                  <v-col cols="4">
                                    <span class="text-subtitle-2 text-font-8rem font-weight-bold text-start">{{ item.name
                                    }}</span>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon :icon="item.icon" :color="item.color"></v-icon>
                                  </v-col>
                                  <v-col cols="3">
                                    <span class="text-subtitle-2 text-font-8rem font-weight-bold text-start">{{
                                      item.valuePlayer1 }}</span>
                                  </v-col>
                                  <v-col cols="3">
                                    <span class="text-subtitle-2 text-font-8rem font-weight-bold text-start">{{
                                      item.valuePlayer2 }}</span>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-row class="pa-4 text-center" align="center" justify="center">
                        <v-col cols="auto">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-sheet width="164" @click="onViewSolution" class="view-solution"
                              :class="{ 'on-hover': isHovering }" v-bind="props">
                              <span class="text-font-8rem text-white">
                                View solutions
                              </span>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else class="mt-0 pt-0">
        <v-col class="mt-0 pt-0 justify-center align-center" :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
          <v-container fluid class="px-2 mt-0 pt-0">
            <v-row justify="center" class="mb-0" align="center">
              <v-col cols="6" align-self="center">
                <span class="font-weight-bold text-result text-white">{{ getChallengeResult(challengeReportData) }}</span>
              </v-col>
              <v-col cols="3" dense v-for="(item, index) in challengeReportData" :key="index"
                class="avatar-character-mobile">
                <div class="winner-character-mobile">
                  <v-img v-if="item.winner" src="@/assets/winner.svg"></v-img>
                </div>
                <v-avatar :color="item.self ? 'primaryPurple' : 'primaryGreen'" size="45">
                  <v-img v-if="item.challengeType == 'aigenerated' && !item.self " src="@/assets/mento_win.png"></v-img>
                  <span v-else class="text-font-10rem">{{ getInitials(item) }}</span>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-hover v-slot="{ isHovering, props }">
                      <v-card elevation="0" :class="{
                        'on-hover': isHovering,
                      }" class="report-container-mobile" v-bind="props">
                        <v-card-text class="pa-4">
                          <v-row class="">
                            <v-col cols="12">
                              <!-- <v-row>
                                <v-col cols="5">
                                  <span
                                    class="text-font-8rem text-start text-color-grey"
                                    >Summary</span
                                  >
                                </v-col>
                                <v-col cols="1"></v-col>
                                <v-col cols="3">
                                  <span
                                    class="text-font-8rem text-start"
                                    :class="
                                      challengeReportData[0].self
                                        ? 'text-color-purple'
                                        : 'text-color-green'
                                    "
                                    >{{ getFirstName(challengeReportData[0]) }}</span
                                  >
                                </v-col>
                                <v-col cols="3">
                                  <span
                                    class="text-font-8rem text-start"
                                    :class="
                                      challengeReportData[1].self
                                        ? 'text-color-purple'
                                        : 'text-color-green'
                                    "
                                    >{{ getFirstName(challengeReportData[1]) }}</span
                                  >
                                </v-col>
                              </v-row> -->
                              <template v-for="(item, i) in defaultReportDetails" :key="i">
                                <v-row justify="space-around">
                                  <v-col cols="4">
                                    <span class="text-font-8rem font-weight-bold text-start">{{ item.name }}</span>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-icon :icon="item.icon" :color="item.color"></v-icon>
                                  </v-col>
                                  <v-col cols="3">
                                    <span class="text-font-8rem font-weight-bold text-start">{{ item.valuePlayer1
                                    }}</span>
                                  </v-col>
                                  <v-col cols="3">
                                    <span class="text-font-8rem font-weight-bold text-start">{{ item.valuePlayer2
                                    }}</span>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-row class="pa-4 text-center" align="center" justify="center">
                        <v-col cols="auto">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-sheet width="164" @click="onViewSolution" class="view-solution"
                              :class="{ 'on-hover': isHovering }" v-bind="props">
                              <span class="text-font-8rem text-white">
                                View solutions
                              </span>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: "ChallengeReportPage",
  data: () => ({
    breadCrumbs: [],
    testId: '',
    testInstanceId: '',
    challengeType: "",
    won: false,
    dataLoaded: false,
    direct: false,
    // challengeReportData:[],
    challengeId: "",
    defaultReportDetails: [
      {
        name: "Attempted",
        icon: "mdi-file-question",
        color: "primary",
        valuePlayer1: "0/0",
        valuePlayer2: "0/0",
      },
      {
        name: "Correct",
        icon: "mdi-check-circle",
        color: "success",
        valuePlayer1: "0",
        valuePlayer2: "0",
      },
      {
        name: "Incorrect",
        icon: "mdi-close-circle",
        color: "red",
        valuePlayer1: "0",
        valuePlayer2: "0",
      },
      {
        name: "Points",
        icon: "mdi-flash",
        color: "yellow",
        valuePlayer1: "0",
        valuePlayer2: "0",
      },
      {
        name: "Duration",
        icon: "mdi-clock-time-three-outline",
        color: "#536DFE",
        valuePlayer1: "0",
        valuePlayer2: "0",
      },
    ],
  }),
  components: {},
  async created() {

    this.creatNodes();
    if ("challengeId" in this.$route.params) {

      this.dataLoaded = false;
      this.$store.commit("challengeReportFromServer", false);
      let challengeId = this.$route.params.challengeId;
      this.challengeId = challengeId

      await this.$store.dispatch('getChallengeReport', { challengeId: challengeId });

      this.breadCrumbs.push({
        title: "Challenge Report",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "challenge",
        pageRoute: `/challenge/challengereport/${challengeId}`,
      }
      );

      console.log("challengeId", challengeId, this.challengeReportData);
      if (this.challengeReportData.length > 0) {

        if (this.challengeReportData[0].self) {
          this.testId = this.challengeReportData[0].testId;
          this.testInstanceId = this.challengeReportData[0].testInstanceId;
        } else {
          this.testId = this.challengeReportData[1].testId;
          this.testInstanceId = this.challengeReportData[1].testInstanceId;
        }
        this.challengeType = this.challengeReportData[0].challengeType

        let direct = this.$store.state.comingFromChallenge
        this.direct = direct

        for (let item of this.challengeReportData) {
          if (item.self) {
            if (item.winner) {
              this.$confetti.start();
              setTimeout(() => {
                this.$confetti.stop();

              }, 1000);
            }
            break
          }
        }

        if (this.challengeType == "usergenerated") {
          if (direct) {
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeReportPageOpenAfterCompletingChallenge, {
              challengeId: challengeId
            });
          }
          else {
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengesChallengeReportOpen, {
              challengeId: challengeId
            });

          }
        }
        else {
          if (direct) {
            recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeReportPageOpenAfterCompletingChallenge, {
              challengeId: challengeId
            });
          }
          else {
            recordAnalyticsEvent(AnalyticsEvents.v2AIChallengesChallengeReportOpen, {
              challengeId: challengeId
            });

          }
        }




        this.defaultReportDetails = this.defaultReportDetails.map((item) => {
          if (item.name == "Attempted") {
            item.valuePlayer1 =
              this.challengeReportData[0].challengeReport.attemptedQuestions +
              "/" +
              this.challengeReportData[0].challengeReport.totalQuestionInTest;
            item.valuePlayer2 =
              this.challengeReportData[1].challengeReport.attemptedQuestions +
              "/" +
              this.challengeReportData[1].challengeReport.totalQuestionInTest;
          } else if (item.name == "Correct") {
            item.valuePlayer1 =
              this.challengeReportData[0].challengeReport.correctQuestions;
            item.valuePlayer2 =
              this.challengeReportData[1].challengeReport.correctQuestions;
          } else if (item.name == "Incorrect") {
            item.valuePlayer1 =
              this.challengeReportData[0].challengeReport.incorrectQuestions;
            item.valuePlayer2 =
              this.challengeReportData[1].challengeReport.incorrectQuestions;
          } else if (item.name == "Points") {
            item.valuePlayer1 = this.challengeReportData[0].challengeReport.marks;
            item.valuePlayer2 = this.challengeReportData[1].challengeReport.marks;
          } else if (item.name == "Duration") {
            item.valuePlayer1 = this.convertTimeInSecondsToMinutes(this.challengeReportData[0].challengeReport.timespentInSeconds);
            item.valuePlayer2 = this.convertTimeInSecondsToMinutes(this.challengeReportData[1].challengeReport.timespentInSeconds);
          }
          return item;
        });
        this.dataLoaded = true;
        this.$store.commit("updateComingFromChallenge", false)
      }

    }
  },
  computed: {
    challengeReportData: {
      get() {
        return this.$store.state.challengeReportData;
      }
    }
  },

  watch: {},

  methods: {
    creatNodes() {
      this.breadCrumbs = [];

     
    },
    back() {
      this.$router.replace("/challenge/pastchallenges");

    },
    // checkIfYouWon(){
    //   for(item of this.challengeReportData)
    //   {
    //     if(item.self)
    //     {

    //     }
    //   }
    // },
    getInitials(item) {
      if (item.self) return "Me";
      return item.name.charAt(0).toUpperCase();
    },
    getFirstName(item) {
      if (item.self) return "Me";
      const allNames = item.name.trim().split(" ");
      return allNames[0];
    },
    getChallengeResult(players) {
      if (players[0].challengeStatus === 'winnertie') {
        return 'Match tie!';
      } else if (players[0].challengeStatus === 'noresult') {
        return 'No result!';
      } else {
        if (players[0].self) {
          if (players[0].challengeStatus === 'winner') {
            return 'You won'
          } else {
            return this.getFirstName(players[1]) + ' won!';
          }
        } else {
          if (players[1].challengeStatus === 'winner') {
            return 'You won!'
          } else {
            return this.getFirstName(players[0]) + ' won!';
          }
        }
      }
    },
    onViewSolution() {
      if (this.challengeType == "usergenerated") {
        if (this.direct) {
          recordAnalyticsEvent(AnalyticsEvents.v2ChallengeReportViewSolutionsAfterCompletingChallenge, {
            challengeId: this.challengeId,
            testId: this.testId,
            testInstanceId: this.testInstanceId
          })

        }
        else {
          recordAnalyticsEvent(AnalyticsEvents.v2PastChallengesChallengeReportViewSolutions, {
            challengeId: this.challengeId,
            testId: this.testId,
            testInstanceId: this.testInstanceId
          })

        }
      }
      else {
        if (this.direct) {
          recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeReportViewSolutionsAfterCompletingChallenge, {
            challengeId: this.challengeId,
            testId: this.testId,
            testInstanceId: this.testInstanceId
          })

        }
        else {
          recordAnalyticsEvent(AnalyticsEvents.v2AIPastChallengesChallengeReportViewSolutions, {
            challengeId: this.challengeId,
            testId: this.testId,
            testInstanceId: this.testInstanceId
          })

        }
      }
      this.$router.push("/testresult/" + this.testId + "?instance=" + this.testInstanceId + "&challenge=" + true);
    },
    convertTimeInSecondsToMinutes(timeInSeconds) {
      if (timeInSeconds < 0) return '-- : --';
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
};
</script>

<style scoped>
.heightBread {
  height: 80px;
}

.layoutLarge {
  min-height: calc(100% - 160px);
}

.skill-container {
  border-radius: 12px;
}

.view-solution {
  padding: 10px;
  box-sizing: border-box;

  background: rgba(38, 37, 58, 0.5);
  border: 1.5px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.report-container {
  background: rgb(var(--v-theme-reportBgColor));
  border-color: transparent;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  width: 60%;
  margin: 0 auto;
}

.report-container-tablet {
  background: rgb(var(--v-theme-reportBgColor));
  border-color: transparent;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  width: 100%;
}

.report-container-mobile {
  background: rgb(var(--v-theme-reportBgColor));
  border-color: transparent;
  border-radius: 12px;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  width: 100%;
}

.winner-character {
  height: 25px;
  width: 50px;
  text-align: center;
}

.winner-character-mobile {
  height: 10px;
  width: 35px;
  text-align: center;
  margin-bottom: 6px;
  margin-left: 5px;
}

.avatar-character {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.avatar-character-mobile {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
}

.avatar-character:first-child {
  margin-right: -25px;
  z-index: 1;
}

.text-font-8rem {
  font-size: 0.8rem;
  font-family: "Inter" !important;
  color: rgba(255, 255, 255, 0.7);
}

.text-font-10rem {
  font-size: 1rem;
  font-family: "Inter" !important;
}

.text-color-green {
  color: rgb(var(--v-theme-primaryGreen));
}

.text-color-purple {
  color: rgb(var(--v-theme-primaryPurple));
}

.text-color-grey {
  color: rgba(50, 50, 50, 0.5);
}

.text-result {
  font-family: 'Inter' !important;
  font-size: 1.125rem !important;
  line-height: 21px !important;
}
</style>
