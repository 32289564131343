<template>
    <div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { storeResourceLink } from "@/services/authService";

export default {
    name: 'StudentResourceContent',

    async created() {
        let route = this.$router.currentRoute.value;
        console.log(route);
        let fileId = route.params.fileId || null
        let contentId = route.params.contentId || null
        recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceContentScreenOpen, {
            customerId: this.courseId,
            folderId: fileId,
            contentId: contentId
        });
        if (fileId == null) {
            this.$router.replace('/')
        }
        if (this.userLoggedIn == 'true') {
            const folders = await this.$store.dispatch("getFilePaths", {
                folderId: fileId
            })
            const folderList = folders.folders;
            this.$store.commit("showContentMessage", !folders?.success ?? false);
            this.$store.commit("loadFolderBreadCrumbs", folderList);
            if(folders?.success){
                if(contentId){
                    this.$store.dispatch("getContentDetails", {
                        folderId: fileId,
                        contentId: contentId
                    }).then(res=> {
                        if(res){
                            this.$store.commit('setResourceContent', res);
                        }
                    });
                }
                // console.log("folders", folderList)
                if(folderList.length>0){
                    this.$store.commit("setResourceFolderId",  fileId );
                }else{
                    this.$store.commit("setResourceFolderId", "-1");
                }
                this.$store.commit("isResourceOpenFromLink", true);
            }

            this.$router.replace('/studentResource');
            }

        else {
            // store test id for after login
            console.log("route", route.fullPath)
            storeResourceLink(route.fullPath);
            this.$router.replace('/');
        }

    },
    data: () => ({

    }),

    computed: {
        ...mapState(["courseId", "userLoggedIn"]),

    },

    watch: {

    },

    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>