<template>
  <!-- Dialog for parent details -->
  <v-dialog
    :model-value="showParentDetailsDialog"
    style="backdrop-filter: blur(12.5px)"
    width="400"
    persistent
  >
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="" justify="space-between" align="center">
            <v-col cols="12" sm="auto" class="d-flex align-center">
              <v-avatar
                size="30"
                color="report-subCardBg"
                class="icon-bg ma-0 pa-0 mr-2"
              >
                <v-icon
                  icon="mdi-account"
                  color="#ffffff"
                  class=""
                  size="15"
                ></v-icon>
              </v-avatar>
              <span class="t-h125Reg wrap-text">Required: Parent Details</span>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-text class="pa-6 pb-1 pt-7 student-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense justify="center">
            <v-col cols="12">
              <v-text-field
                label="Parent Name *"
                variant="outlined"
                v-model="editedItem.guardianName"
                :rules="requiredRule"
                density="compact"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                label="Parent Email *"
                variant="outlined"
                v-model="editedItem.guardianEmail"
                :rules="emailRule"
                density="compact"
              >
              </v-text-field>
            </v-col>

            <v-col v-if="showErrorMsg" cols="12" class="errorMsg pb-6">
              <span>{{ errorMsg }}</span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="saveParentDetails"
          >Continue</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Snackbar on successfully saving -->
  <v-snackbar
    v-model="showParentDetailsSnackbar"
    :timeout="-1"
    color="cardBackgroundColor"
  >
    <span class="submit-message-text text-white">{{
      parentDetailsSnackbarMsg
    }}</span>
    <template v-slot:actions>
      <v-btn
        density="compact"
        color="#8e8ea7"
        @click.stop="showParentDetailsSnackbar = false"
        icon="mdi-close"
      ></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "ParentDetailsComponent",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.showErrorMsg = false;
    this.errorMsg = "";
  },
  data: () => ({
    formValue: false,
    showCreateNewCollectionBox: false,
    requiredRule: [(v) => !!v || "Required"],
    emailRule: [
      (v) =>
        !!(v || "").match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ||
        "Please enter a valid email",
    ],
    editedItem: {
      guardianName: "",
      guardianEmail: "",
    },
    defaultItem: {
      guardianName: "",
      guardianEmail: "",
    },
    errorMsg: "",
    showErrorMsg: false,
    showParentDetailsSnackbar: false,
    parentDetailsSnackbarMsg: "Parents detail successfully added",
  }),
  methods: {
    close() {
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.showParentDetailsDialog = false;
      this.$store.commit("captureParentInformation", false);
    },
    async saveParentDetails() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$store
          .dispatch("saveParentDetails", this.editedItem)
          .then((res) => {
            console.log("res", res);
            if (res.success) {
              this.close();
              this.showParentDetailsSnackbar = true;
            } else {
              this.errorMsg = res.message;
              this.showErrorMsg = true;
            }
          });
      }
    },
  },
  computed: {
    ...mapState(["captureParentInformation"]),
    showParentDetailsDialog: {
      get() {
        return this.show && this.captureParentInformation;
      },
    },
    watch: {
      "editedItem.guardianName"() {
        this.errorMsg = "";
        this.showErrorMsg = false;
      },
      "editedItem.guardianEmail"() {
        this.errorMsg = "";
        this.showErrorMsg = false;
      },
      showParentDetailsDialog() {
        this.errorMsg = "";
        this.showErrorMsg = false;
      },
    },
  },
};
</script>

<style scoped>
.student-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.student-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.student-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.tab:hover {
  background: rgba(83, 109, 254, 0.1) !important;
  /* color: white !important; */
  border-radius: 10px !important;
}

.tab-2 {
  border: 1px solid rgb(var(--v-theme-iconGrey));
  border-radius: 10px !important;
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  /* word-break: break-word; */
  /* or word-break: break-all; */
}

.errorMsg {
  font-size: 0.8rem;
  font-weight: 700;
  color: #ff786e;
}
</style>
