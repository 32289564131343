<template>
  <!-- Dialog for student assignment -->
  <v-dialog :model-value="openCollectionDialog" style="backdrop-filter: blur(12.5px)" width="400" persistent>
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="" justify="space-between" align="center">
            <v-col cols="9" sm="auto" class="d-flex align-center ">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-book-open-blank-variant-outline" color="#ffffff" class="" size="15"></v-icon>
              </v-avatar>
              <span class="t-h125Reg wrap-text">{{ getTitle() }}</span>
            </v-col>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close </v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-text class="pa-6 student-form-main">
        <v-form ref="form" validate-on="submit lazy"  v-model="formValue">

          <v-row dense justify="center">

            <v-col v-if="showCreateNewCollectionBox && showExistingCollections" cols="12">
              <v-btn prepend-icon="mdi-format-list-bulleted" variant="tonal" @click="selectExistingCollection()" class="w-100">
                  <span class="nav-text text-none">Select from Existing Collection</span>
              </v-btn>
            </v-col>

            <v-col v-if="!showCreateNewCollectionBox && showExistingCollections" cols="12">
              <v-autocomplete :items="collectionsList" item-title="collectionName" item-value="collectionId"
                v-model="editedCollection.collectionId" label="Select Collection *" density="compact" variant="outlined" :disabled="showCreateNewCollectionBox"  hide-details dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" class="mt-1" v-if="showExistingCollections">
              <v-row align="center" justify="space-between">
                <v-col cols="5">
                  <v-divider class="border-opacity-50" color="white"></v-divider>
                </v-col>
                <v-col cols="auto">
                  <span class="text-white">or</span>
                </v-col>
                <v-col cols="5">
                  <v-divider class="border-opacity-50" color="white"></v-divider>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="!showCreateNewCollectionBox && showExistingCollections" cols="12">
              <v-btn prepend-icon="mdi-plus" variant="tonal" @click="createNewCollection()" class="w-100">
                  <span class="nav-text text-none">Create New Collection</span>
              </v-btn>
            </v-col>
            

            <v-col v-if="showCreateNewCollectionBox || !showExistingCollections || editName" cols="12">
              <v-text-field label="Collection Name *" variant="outlined" v-model="editedCollection.collectionName" :rules="requiredRule" density="compact" hide-details>
              </v-text-field>
            </v-col>

            <v-col  cols="12" class="errorMsg">
              <span>{{errorMsg}}</span>
            </v-col>


          </v-row>

        </v-form>
      </v-card-text>
      <v-divider class="" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="saveCollection">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";

export default {
  name: "MyCollectionDialogComponent",
  props: {
    aiQuestionIds: {
        type: Array,
        default: () => [],
    },
    showExistingCollections: {
      type: Boolean,
      default: true,
    },
    editName: {
      type: Boolean,
      default: false,
    },
    collectionId: String,
    openCollectionDialog: Boolean,
    toolId: {
      type: String,
      default: '',
    }
  },
  async created(){
    this.showErrorMsg = false;
    this.errorMsg = "";
    if(this.editName){
      this.editedCollection.collectionName = this.collectionName;
    }
    else if(this.showExistingCollections ){
      // await this.$store.dispatch('getCollectionsList', {});
    }
    else{
      this.showCreateNewCollectionBox = true;
    }
  }, 
  data: () => ({
    formValue: false,
    showCreateNewCollectionBox: false,
    requiredRule: [(v) => !!v || "Required"],
    selectedCollection: null,
    editedCollection: {
      collectionId: "",
      collectionName: "",
      aiQuestionIds: [],
      aiQuestionIdsToBeAdded: [],
      aiQuestionIdsToBeRemoved: [],
      toolId: "",
      createMetaData: {}
    },
    defaultCollection: {
      collectionId: "",
      collectionName: "",
      aiQuestionIds: [],
      aiQuestionIdsToBeAdded: [],
      aiQuestionIdsToBeRemoved: [],
      toolId: "",
      createMetaData: {}
    },
    errorMsg: "",
    showErrorMsg: false,
  }),
  methods: {
    getTitle(){
      return this.showExistingCollections ? 'Add to collection' : this.editName ? 'Edit collection name' : 'Create a collection';
    },
    createNewCollection(){
      this.editedCollection = _.cloneDeep(this.defaultCollection);
      this.showCreateNewCollectionBox = true;
    },
    selectExistingCollection(){
      this.showCreateNewCollectionBox = false;
    },
    close(){
      this.selectedCollection = null;
      this.showCreateNewCollectionBox= false;
      this.editedCollection = _.cloneDeep(this.defaultCollection);
      this.$emit("closeCollectionDialog");
    },
    async saveCollection(){
      const { valid } = await this.$refs.form.validate();
      if(valid){
        this.editedCollection.toolId = this.toolId;
        if(this.showCreateNewCollectionBox){
          this.editedCollection.aiQuestionIds = this.aiQuestionIds;
          this.editedCollection = _.omit(this.editedCollection, ['aiQuestionIdsToBeAdded', 'aiQuestionIdsToBeRemoved']);          
          this.$store.dispatch('saveInCollection', this.editedCollection).then((res)=> {
            if( !(res.success  == 'false')){
              this.$emit("collectionMsg", {text: "Collection successfully created", subText: " to share it with your students for practice." });
              this.$emit("showCollectionSnackBar");
              recordAnalyticsEvent(AnalyticsEvents.v2AINewCollectionCreated, {collectionName: this.editedCollection.collectionName, fromTool: this.toolId == ''? false: true, toolId: this.toolId, aiQuestionIds: this.editedCollection.aiQuestionIds.join()});
              this.close();
            }
            else{
              this.errorMsg = res.error;
              this.showErrorMsg = true;
            }
          })
          
        }
        else{
          if(this.editName){
            this.editedCollection.collectionId = this.collectionId;
          }
          this.editedCollection.aiQuestionIdsToBeAdded= this.aiQuestionIds;
          this.editedCollection = _.omit(this.editedCollection, ['aiQuestionIds', 'toolId']);
          this.$store.dispatch('editCollection', this.editedCollection).then((res)=> {
            if(res.success){
              this.$emit("collectionMsg", {text: "Collection successfully updated.", subText: " to share it with your students for practice." });
              if(this.editName){
                this.$store.commit("updateCollectionName", {collectionId: this.editedCollection.collectionId, collectionName: this.editedCollection.collectionName });
              }
              this.$emit("showCollectionSnackBar");
              recordAnalyticsEvent(AnalyticsEvents.v2AICollectionEdited, {collectionId: this.editedCollection.collectionId, fromTool: this.toolId == ''? false: true, toolId: this.toolId, nameChanged: this.editName? this.editedCollection.collectionName : '', aiQuestionAdded: this.editedCollection.aiQuestionIdsToBeAdded, aiQuestionRemoved: this.editedCollection.aiQuestionIdsToBeRemoved});
              this.close();
            }
            else{
              this.errorMsg = res.error;
              this.showErrorMsg = true;
            }
          })
        }
      }
    },
    
    
  },
  computed: {
    collectionsList: {
      get(){
        let list = this.$store.state.collectionsList;
        list = _.orderBy(list, [collection => collection?.collectionName.toLowerCase()], "asc");
        return list;
      }
    },
    collectionName: {
      get(){
        let collection = "";
        collection =  _.find(this.collectionsList, (collection) => collection.collectionId == this.collectionId);
        let name = "";
        name = collection.collectionName;
        return name;
      }
    }
  },
  watch: {
    'editedCollection.collectionName'(){
      this.errorMsg= "";
      this.showErrorMsg= false;
    },
    'editedCollection.collectionId'(){
      this.errorMsg= "";
      this.showErrorMsg= false;
    },
    showCreateNewCollectionBox(){
      this.errorMsg= "";
      this.showErrorMsg= false;
    }
  }
  
}
</script>

<style scoped>
.student-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.student-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.student-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.tab:hover {
  background: rgba(83, 109, 254, 0.1) !important;
  /* color: white !important; */
  border-radius: 10px !important;
  
}

.tab-2{
  border: 1px solid rgb(var(--v-theme-iconGrey));
  border-radius: 10px !important;
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  /* word-break: break-word; */
  /* or word-break: break-all; */
}

.errorMsg {
  font-size: 0.8rem;
  font-weight: 700;
  color: #FF786E;
}

</style>