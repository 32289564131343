<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>
  <v-layout class="mb-0 pb-0" v-if="dataLoaded">
    <v-container fluid class="h-100 mt-md-0 mb-0 pb-0"
      :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
      <template v-if="isStudentView">
        <v-row align="center" class="ma-0 mb-6 pa-0">
          <v-col cols="4" sm="2" class="pa-0">
            <span class="main-heading">MAIN TEST</span>
          </v-col>
          <v-col cols="8" sm="10" class="pa-0">
            <v-divider :thickness="1" color="background"></v-divider>
          </v-col>
        </v-row>
        <v-card elevation="0" class="mb-7 pa-0 testCard test-card-border" @click="testInstancesData.alreadyInProgress ? resumeTest(testInstancesData) : testInstancesData.showRetest? startTest(testInstancesData): null">
          <v-card-text class="pa-3">
            <v-row class="ma-0" :align="$vuetify.display.xs ? 'start' : 'center'" justify="start" dense>
              <v-col cols="2" sm="1">
                <v-avatar :size="46" class="text-center">
                  <v-img :height="46" src="@/assets/test_practice.png" />
                </v-avatar>
              </v-col>
              <v-col class="pl-3" cols="10" sm="11">
                <v-row dense align="center">
                  <v-col :cols="$vuetify.display.xs && !testInstancesData.accessAllowed ? '10' : '12'" sm="8" md="6">
                    <v-row dense align="center" justify="center">
                      <v-col cols="12" class="pa-0 mt-1" dense>
                        <!-- <span class="t-1rem text-white">Test attempts</span> -->
                        <span class="t-1rem text-white mr-2">{{ testInstancesData.testName }}</span>
                        <v-icon v-if="!$vuetify.display.xs && !testInstancesData.accessAllowed" size="25"
                          color="primaryPurple">mdi-lock-outline</v-icon>
                      </v-col>
                      <v-col cols="12" class="pa-0 pt-1 text-grey">
                        <!-- <span class="t-para"
                          >You can use filters to check previous test
                          reports</span
                        > -->
                        <span class="t-para">{{ ` ${pastTestAttemptData.length} Attempts` }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="$vuetify.display.xs && !testInstancesData.accessAllowed" align-self='center' cols="2">
                    <v-icon :end="true" size="25" color="primaryPurple">mdi-lock-outline</v-icon>
                  </v-col>
                  <v-col cols="12" sm="4" md="6">
                    <v-row v-if="!$vuetify.display.xs" align="center" justify-sm="end" justify="start" class="pa-0" dense>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn
                          v-if="testInstancesData.alreadyInProgress"
                          append-icon="mdi-play"
                          class="t-btn text-white not-activity-btn text-none px-3"
                          density="default"
                          variant="flat"
                          @click.stop="resumeTest(testInstancesData)"
                          >
                          <template v-slot:append>
                            <v-icon color="primaryPurple"></v-icon>
                          </template>
                          Resume Test
                        </v-btn>
                        <v-btn v-else-if="testInstancesData.showRetest" append-icon="mdi-play" prepend-icon="mdi-replay"
                          class="t-btn text-white not-activity-btn text-none px-3" density="default" variant="flat"
                          @click.stop="startTest(testInstancesData)">
                          <template v-slot:append>
                            <v-icon color="primaryPurple"></v-icon>
                          </template>
                          <template v-slot:prepend>
                            <v-icon color="primaryPurple"></v-icon>
                          </template>
                          Retest
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="$vuetify.display.xs" cols="12">
                <v-row align="center" justify="start" class="pa-0" dense>
                  <v-col cols="12">
                    <v-btn
                      v-if="testInstancesData.alreadyInProgress"
                      append-icon="mdi-play"
                      class="t-btn text-white w-100 not-activity-btn text-none px-3"
                      density="default"
                      variant="flat"
                      @click.stop="resumeTest(testInstancesData)"
                      >
                      <template v-slot:append>
                        <v-icon color="primaryPurple"></v-icon>
                      </template>
                      Resume Test
                    </v-btn>
                    <v-btn v-else-if="testInstancesData.showRetest" append-icon="mdi-play" prepend-icon="mdi-replay"
                      class="t-btn text-white w-100 not-activity-btn text-none px-3" density="default" variant="flat"
                      @click.stop="startTest(testInstancesData)">
                      <template v-slot:append>
                        <v-icon color="primaryPurple"></v-icon>
                      </template>
                      <template v-slot:prepend>
                        <v-icon color="primaryPurple"></v-icon>
                      </template>
                      Retest
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-row align="center" class=" ma-0 mb-6 pa-0">
        <v-col cols="4" sm="2" class="pa-0">
          <span class="main-heading">TEST ATTEMPT</span>
        </v-col>
        <v-col cols="8" sm="10" class="pa-0">
          <v-divider :thickness="1" color="background"></v-divider>
        </v-col>
      </v-row>
      <v-card elevation="0" class="mb-5 pa-0 testCard" v-for="testAttempt in pastTestAttemptData"
        :key="testAttempt.testInstanceId" @click="onViewReport(testAttempt)">
        <v-card-text class="pa-3">
          <v-row class="pa-0 px-2 py-1">
            <v-col cols="2" sm="1">
              <v-avatar :size="46" class="text-center">
                <v-img :height="46" src="@/assets/test_attempt.png" />
              </v-avatar>
            </v-col>
            <v-col class="pl-3" cols="10" sm="11">
              <v-row dense align="center">
                <v-col cols="12" sm="7">
                  <v-row align="center" dense class="pl-2">
                    <v-col cols="12" class="pa-0 mt-1" dense>
                      <span class="t-h125 text-white">{{
                        testAttempt.status == "completed"
                        ? `${testAttempt.testInstanceLowerScore} - ${testAttempt.testInstanceUpperScore}`
                        : "-"
                      }}</span>
                    </v-col>
                    <v-col cols="12" sm="9" lg="6" class="pa-0 pt-1 text-grey">
                      <v-icon>mdi-clock-time-four</v-icon>
                      <span class="t-para ml-1">{{
                        getScoreDate(testAttempt)
                      }}</span>
                    </v-col>
                    <v-col cols="12" sm="auto" class="pa-0 pt-1 text-grey"
                      v-if="('assignmentId' in testAttempt) && testAttempt.assignmentId != null">
                      <v-icon>mdi-note-check-outline</v-icon>
                      <span class="t-para ml-1">
                        {{ testAttempt.assignmentTitle ?? "" }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col v-if="!$vuetify.display.xs" cols="12" sm="5">
                  <v-row align="center" justify="end" class="pa-0" :class="{ 'mt-1': $vuetify.display.xs }" dense>
                    <v-col cols="auto">
                      <v-btn prepend-icon="mdi-chart-pie" class="t-btn text-white result-btn text-none px-3"
                        density="default" variant="flat" @click.stop="onViewReport(testAttempt)">
                        <template v-slot:prepend>
                          <v-icon color="white"></v-icon>
                        </template>
                        View Results
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="ml-2">
                      <v-avatar size="30" class="text-center" :color="testAttempt.status == 'completed'
                        ? '#70BB9C'
                        : '#424159'
                        ">
                        <v-icon :size="25" icon="mdi-check" :color="testAttempt.status == 'completed'
                          ? '#fff'
                          : 'cardBackgroundColor'
                          "></v-icon>
                      </v-avatar>
                    </v-col>
                    <v-spacer v-if="$vuetify.display.xs"></v-spacer>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="$vuetify.display.xs" cols="12">
              <v-row align="center" justify="space-between" class="pa-0">
                <v-col cols="10">
                  <v-btn prepend-icon="mdi-chart-pie" class="t-btn text-white w-100 result-btn text-none px-3"
                    density="default" variant="flat" @click.stop="onViewReport(testAttempt)">
                    <template v-slot:prepend>
                      <v-icon color="white"></v-icon>
                    </template>
                    View Results
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-avatar size="30" class="text-center" :color="testAttempt.status == 'completed' ? '#70BB9C' : '#424159'
                    ">
                    <v-icon :size="25" icon="mdi-check" :color="testAttempt.status == 'completed'
                      ? '#fff'
                      : 'cardBackgroundColor'
                      "></v-icon>
                  </v-avatar>
                </v-col>
                <v-spacer v-if="$vuetify.display.xs"></v-spacer>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import { useDisplay } from "vuetify";
import _ from "lodash";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import {
  breadcrumbHandler,
} from "@/services/breadcrumbHandler";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "TestAttemptPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    dataLoaded: false,
    updatePastChallengeData: {},
    testId: "",
  }),
  components: {},
  async created() {
    breadcrumbHandler(this)
    this.dataLoaded = false;

    this.$store.commit("appbarVisible", true);
    this.$store.commit("navbarVisible", true);

    if ("testId" in this.$route.params) {
      this.testId = this.$route.params.testId;
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestAttemptScreenOpen, {
        testId: this.testId,
      });
      if (this.$route.params.studentId) {
        this.$store
          .dispatch("getUserTestInstances", { testId: this.testId, studentId: this.$route.params.studentId })
          .then(() => {
            this.dataLoaded = true;
          });
      } else {
        this.$store
          .dispatch("getUserTestInstances", { testId: this.testId })
          .then(() => {
            this.dataLoaded = true;
          });
      }

    }
  },
  computed: {
    ...mapState(["testInstancesData", "breadCrumbs", "studentName", "className"]),
    pastTestAttemptData: {
      get() {
        return this.testInstancesData.attempts;
      },

    },
    isStudentView: {
      get(){
        if(this.$route.fullPath.startsWith('/teacher') || this.$route.fullPath.startsWith('/parent')){
          return false;
        }
        return true;
      }
    },
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = [];
        breadCrumbs = this.breadCrumbs;
        if(breadCrumbs.length>3){
          breadCrumbs = breadCrumbs.slice(-3);
        }
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        if('studentId' in this.$route.params){
          let currentStudentName = this.studentName !== ''? this.studentName : 'Student';
          breadCrumbs[breadCrumbs.length-2].title = currentStudentName;
        }
        if('batchId' in this.$route.params){
          let currentClassName = this.className !== ''? this.className : 'Class Details';
          breadCrumbs[breadCrumbs.length-3].title = currentClassName;
        }
        return breadCrumbs
      }
    }
  },

  watch: {},

  methods: {
    creatNodes(breadCrumbs) {
      breadCrumbs = [];
      console.log(this.$route);
      let testId = ""
      let backRoute = {
        name : "",
        path : ""
      }
      if ("testId" in this.$route.params) {
        testId = this.$route.params.testId
      }

    
      breadCrumbs.push({
        title: "Practice Tests",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "practicetest",
        pageRoute: `/mocktest`,
      });

      breadCrumbs.push({
        title: "Test Attempts",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "testattempt",
        pageRoute: `/digisatresult/${testId}`,
      });

      return breadCrumbs;


    },
    breadCrumbRoute(item){
      this.$router.replace(item.pageRoute)
    },
    back() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestAttemptBackButtonClick, {
        testId: this.testId,
      });
      let page = this.displayBreadCrumbs[this.displayBreadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
    },
    startTest(item) {
      if (item.accessAllowed) {
        recordAnalyticsEvent(AnalyticsEvents.v2MockTestAttemptReTestButtonClick, {
          testId: this.testId,
        });
        const body = {
          testName: item.testName,
          testId: item.testId,
          moduleId: item.firstModuleId,
        };
        this.$store.commit("updateSelectedTest", body);
        this.$router.push(`/startmocktest/${body.testId}`);
      } else {
        //Add pinpoint
        recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogOpenForMockTestAttempt, {
          testId: this.testId,
        });
        this.$store.commit('openUpgradeDialog', true);
      }
    },
    resumeTest(item){
      if(item.accessAllowed){
        const body = {
          testName: item.testName,
          testId: item.testId,
          moduleId: item.inProgessModuleId,
          testInstanceId: item.testInstanceId
        };
        this.$store.commit("updateSelectedTest", body);
        this.$router.push(`/startmocktest/${item.testId}`);
      }else{       
        this.$store.commit('openUpgradeDialog', true);
      }
    },
    getScoreDate(testAttempt) {
      let date = new Date(testAttempt.testInstanceStartTimestamp);
      date = moment(date).format("Do MMM YYYY - hh:mm A");
      return date;
    },
    onViewReport(item) {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestAttemptViewReportButtonClick,
        { testId: item.testId, testInstanceId: item.testInstanceId }
      );
      
      this.$router.push(`${this.$route.fullPath}/${item.testInstanceId}`)

    },
  },
};
</script>

<style scoped>
.main-lg {
  padding-top: 40px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 30px;
  padding-left: 10%;
  padding-right: 10%;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.test-card-border {
  border: 2px solid rgb(var(--v-theme-primaryPurple)) !important;
}

.main-heading {
  color: #BFBFC6;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 700;
}

.heightBread {
  height: 80px;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 12px;
}

.not-activity-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.result-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}
</style>