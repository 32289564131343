<template>
    <v-dialog v-model="showPreview" max-width="800">
        <v-img @mouseleave="showPreview = false" :src="imageUrl"></v-img>
    </v-dialog>
    <v-container fluid class="assignment-card pa-8" v-if="dataReady">
        <v-form ref="form">
            <v-row align="center">
                <v-col cols="12" md="5">
                    <v-select label="Subjects" hide-details v-model="currentSubject" @update:modelValue="subjectUpdate"
                        theme="dark" item-title="subjectName" item-value="subjectId" :items="subjectList"
                        :rules="requiredRule"></v-select>

                </v-col>
                <v-col cols="12" md="5">

                    <v-select label="AI Model" v-model="aiModel" theme="dark" hide-details :rules="requiredRule"
                        item-title="name" item-value="value"
                        :items='[{ value: "chatgpt", name: "ChatGPT" }, { value: "gemini", name: "Gemini" }]'></v-select>
                </v-col>
                <v-col cols="10">
                    <v-textarea placeholder="Convert Image to text" rows="1" auto-grow v-model="imgToTextPrompt"
                        label="Convert Image to text" variant="outlined" :rules="requiredRule"></v-textarea>
                </v-col>


                <v-col cols="12" md="8">

                    <v-text-field label="Upload, paste or drop an image" placeholder="Upload, paste or drop image"
                        @keydown="handleKeyDown" v-model="textWithImages" variant="outlined" @drop="handleDrop"
                        @dragover.prevent @dragenter.prevent @paste="handlePaste" hide-details>
                        <template v-slot:append-inner>
                            <v-icon @click.stop="clearImage" v-if="this.imageFile != null"
                                class="mr-4">mdi-close-circle</v-icon>

                            <v-icon @click.stop="uploadImage">mdi-image</v-icon>
                        </template>
                    </v-text-field>

                    <v-file-input hide-details @update:modelValue="previewImage" ref="imgInput" v-model="imageFiles"
                        accept="image/*" variant="outlined" style="display: none;"></v-file-input>

                </v-col>
                <v-col cols="12" md="4" class="">
                    <v-btn @click="getTextFromImage()" height="45" width="200"
                        class="t-btn text-white  activity-btn text-none px-5">
                        Get Question
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>

                <v-col cols="12" v-if="sampleDataError.length > 0" class="text-center t-1rem">
                    {{ sampleDataError }}
                </v-col>
            </v-row>

            <v-row v-if="imageUrl.length > 0">
                <v-col>
                    <v-card @mouseover="showPreview = true" max-width="200" height="200" :image="imageUrl"
                        theme="dark"></v-card>

                </v-col>

            </v-row>
        </v-form>
        <v-row class="my-3" v-if="questions.length > 0">
            <v-col cols="12" v-if="questions.length > 0">
                <v-divider>

                </v-divider>
            </v-col>
        </v-row>

        <v-row v-if="questions.length > 0">
            <v-col cols="12">
                <v-expansion-panels bg-color="report-subCardBg" theme="dark">
                    <v-expansion-panel bg-color="report-subCardBg" title="System" theme="dark" :text="systemPrompt">
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-col cols="12" v-if="showSystemPrompt" class="mt-6">
            <v-textarea placeholder="System Prompt" rows="1" auto-grow v-model="systemPrompt" label="System Prompt"
                variant="outlined" :rules="requiredRule"></v-textarea>
        </v-col>

        <v-row v-for="q, index in questions" :key="index" class="htmlClassTest" :id="q.id">

            <v-col cols="12">
                <v-expansion-panels bg-color="report-subCardBg" theme="dark">


                    <v-expansion-panel bg-color="report-subCardBg" title="Human" theme="dark" :text="q.data.query">
                    </v-expansion-panel>


                    <v-expansion-panel bg-color="report-subCardBg" title="Raw Response" theme="dark"
                        :text="q.data.rawResponse">
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>



            <div v-if="q.data.error.length == 0">
                <v-col cols="12" class=" qNoClass font-weight-bold">
                    Question {{ index + 1 }}
                    <span v-if="'chosenQuestionId' in q.data">
                        (SampleQuestion : {{ q.data.chosenQuestionId }})
                    </span>
                </v-col>
                <v-col cols="12">
                    <div v-html="getMarkDownHtml(q.text)" class="text-white" theme="dark" style="white-space: pre-line;">
                    </div>
                </v-col>
                <v-col cols="12" v-for="option in ['a', 'b', 'c', 'd']" :key="option">
                    <div class="text-white" v-html="option + ') ' + getMarkDownHtml(q[option])" theme="dark"
                        style="white-space: pre-line;">

                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-white" v-html="'Answer : ' + getMarkDownHtml(q.answer)" theme="dark"
                        style="white-space: pre-line;">
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-white" v-html="'Solution : ' + getMarkDownHtml(q.solution)" theme="dark"
                        style="white-space: pre-line;">
                    </div>
                </v-col>
                <v-col cols="12" v-if="(index + 1) < questions.length">
                    <v-divider>

                    </v-divider>
                </v-col>
            </div>
            <div v-else>

                <v-col cols="12">
                    Error {{ q.sampleQuestionId }}
                </v-col>

            </div>
        </v-row>

        <v-row v-if="showText && customTextStatus == false">
            <v-col cols="12">
                <v-form ref="qform">
                    <v-textarea placeholder="Instructions to generate a question" rows="1" @click:appendInner="customText()"
                        auto-grow v-model="humanQuery" label="Query" append-inner-icon="mdi-send" variant="outlined"
                        @keydown.enter.prevent="customText()" :rules="requiredRule"></v-textarea>
                </v-form>

            </v-col>


        </v-row>

        <v-form>



            <v-row v-if="showHumanPrompt && !customTextStatus" class="mt-6">
                <v-col cols="12">
                    <v-textarea placeholder="Human Prompt" rows="1" auto-grow v-model="humanPrompt" label="Human Prompt"
                        variant="outlined" :rules="requiredRule"></v-textarea>
                </v-col>
            </v-row>


        </v-form>
        <v-col cols="12" md="4" class="">
            <v-btn @click="generateQuestion()" height="45" width="200"
                v-if="systemPrompt.length > 0 && showHumanPrompt > 0 && !customTextStatus && !loading"
                class="t-btn text-white  activity-btn text-none px-5">
                Generate
            </v-btn>
        </v-col>
        <v-row align-content="center" justify="center" v-if="imgToTextLoading">
            <v-col class="text-subtitle-1 text-center" cols="12">
                Getting Text
            </v-col>
            <v-col cols="3">
                <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center" v-if="loading">
            <v-col class="text-subtitle-1 text-center" cols="12">
                Getting Question {{ questions.length + 1 }}
            </v-col>
            <v-col cols="3">
                <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center" v-if="customTextStatus">
            <v-col class="text-subtitle-1 text-center" cols="12">
                Getting Question
            </v-col>
            <v-col cols="3">
                <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
// import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment';
import _ from "lodash";
import { securePostAPI } from '@/services/apiService';
import axios from 'axios';
import { getHeaders, getApiBaseUrl } from '@/services/authService';
import $ from "jquery";
import { uploadImageFile } from "@/services/storageService";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};

export default {
    name: 'MentoAIComponent',
    props: ["assignmentType", "batchId", "studentId"],
    data: () => ({
        dataReady: false,
        loading: false,
        currentSubject: "",
        questionCount: 1,
        showPreview: false,
        questions: [],
        messages: [],
        systemMessage: "",
        humanQuery: "",
        imgToTextLoading: false,
        imgToTextPrompt: "",
        customTextStatus: false,
        showText: false,
        requiredRule: [(v) => !!v || "Required"],
        sampleQuestionsCount: 0,
        systemPrompt: "",
        humanPrompt: "",
        sampleDataError: "",
        imageFiles: [],
        imageFile: null,
        textWithImages: "",
        imgFiles: "",
        imageUrl: "",
        qWithMaths: "readingandwriting",
        aiModel: "",
        showSystemPrompt: false,
        showHumanPrompt: false,
    }),
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
        return { xs, smAndUp, mdAndUp, lgAndUp };
    },
    async created() {
        this.dataReady = false
        await this.$store.dispatch("loadNewLearnNodes");

        this.dataReady = true
    },
    components: {},

    computed: {
        ...mapState(["newLearnNodes"]),
        subjectList: {
            get() {
                console.log(this.newLearnNodes);
                return this.newLearnNodes

            },
        },
    },

    methods: {
        handleKeyDown(event) {
            // Allow Ctrl + V (or Cmd + V)
            if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
                return;  // Allow the paste action to proceed
            }

            // Prevent any other keystrokes
            event.preventDefault();
        },
        imgFieldFocus(val) {
            if (val) {
                this.$refs.imgInput.click()

            }
        },
        clearImage() {
            this.imageFile = null
            this.textWithImages = ""
            this.imageUrl = ""
            this.imageFiles = []
        },
        async handlePaste(event) {
            event.preventDefault()
            console.log(event);
            console.log(event.clipboardData.files);
            const item = event.clipboardData.files[0];
            if (!item) {
                this.textWithImages = ""
                return
            }

            console.log(item);
            if (item.type.startsWith("image/")) {
                const imageUrl = await this.imageFileToBase64(item)
                console.log(imageUrl);
                this.insertImageInTextarea(imageUrl, item);
            }

        },
        async handleDrop(event) {
            console.log(event.dataTransfer.files);
            event.preventDefault(); // Prevent default behavior
            const imageFile = event.dataTransfer.files[0];
            if (imageFile.type.startsWith("image/")) {
                const imageUrl = await this.imageFileToBase64(imageFile);
                console.log(imageUrl);
                this.insertImageInTextarea(imageUrl, imageFile);
            }
        },
        imageFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        insertImageInTextarea(imageUrl, file) {
            this.imageUrl = imageUrl
            this.textWithImages = file.name;
            this.imageFile = file
        },
        uploadImage() {
            console.log("test");
            this.$refs.imgInput.click()
        },
        async subjectUpdate(currentSubject) {
            this.$store.commit('increamentLoader')
            let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/getImagePrompt"
            let response = await axios.post(url, {
                subjectId: currentSubject,
                subjectName: this.subjectList.find((e) => e.subjectId == currentSubject).subjectName,
                subjectType: this.subjectList.find((e) => e.subjectId == currentSubject).subjectType,
                aiModel: this.aiModel
            }, {
                headers: {
                    ...getHeaders()
                }
            })
            console.log(response);
            this.systemPrompt = response.data.systemMessage
            this.$store.commit('decreamentLoader')
            this.imgToTextPrompt = response.data.imgToTextPrompt
        },
        async getTextFromImage() {
            const { valid } = await this.$refs.form.validate();
            if (!valid) {
                return
            }
            if (this.imageFile == null) {
                this.sampleDataError = "No Image added, Please add an image"
                return
            }
            this.questions = []
            let self = this
            this.messages = []
            this.systemMessage = ""
            this.humanPrompt = ""
            this.showText = false
            this.showSystemPrompt = false
            $("html, body").animate({ scrollTop: $(document).height() }, 1000);

            this.showHumanPrompt = false
            this.imgToTextLoading = true

            let res = await uploadImageFile(this.imageFile)
            console.log("Res", res);



            let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/getTextFromImage"


            let response = await axios.post(url, {
                imgToTextPrompt: this.imgToTextPrompt,
                imgPath: res.key,
                aiModel: this.aiModel

            }, {
                headers: {
                    ...getHeaders()
                }
            })

            console.log(response);
            this.imgToTextLoading = false
            this.showSystemPrompt = true
            this.showHumanPrompt = true
            this.humanPrompt = response.data.text
            setTimeout(() => {
                $("html, body").animate({ scrollTop: $(document).height() }, 1000);

            }, 200);

        },
        previewImage(imageFiles) {
            console.log("test222");
            console.log(imageFiles);
            if (imageFiles.length > 0) {
                let imageFile = imageFiles[0];
                let imageUrl = URL.createObjectURL(imageFile);
                this.insertImageInTextarea(imageUrl, imageFile);
            }
            else {
                this.imageUrl = ''
                this.imageFile = null
                this.textWithImages = ""
            }
        },
        async getData() {
            this.sampleDataError = ""

            this.sampleQuestionsCount = 0
            this.questions = []
            let self = this
            this.messages = []
            this.systemMessage = ""
            this.systemPrompt = ""
            this.humanPrompt = ""
            this.showText = false


            console.log(this.currentDifficulty);
            this.$store.commit('increamentLoader')
            try {


                let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/getSampleData"
                let response = await axios.post(url, {
                    topicId: this.currentTopic,
                    topicName: this.topicsList.find((e) => e.entityId == this.currentTopic).entityLabel,
                    categoryName: this.categoriesList.find((e) => e.categoryId == this.currentCategory).categoryName,
                    categoryId: this.currentCategory,
                    subjectId: this.currentSubject,
                    subjectName: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectName,
                    subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                    difficulty: this.currentDifficulty,
                    aiModel: this.aiModel
                }, {
                    headers: {
                        ...getHeaders()
                    }
                })

                this.$store.commit('decreamentLoader')
                console.log(response);
                this.sampleQuestions = response.data.sampleQuestionIds
                this.systemPrompt = response.data.systemMessage
                this.systemMessage = response.data.systemMessage

                this.getHumanPrompt()
            } catch (error) {
                this.$store.commit('decreamentLoader')
                this.sampleDataError = "No Sample Data found"
            }

        },
        getMarkDownHtml(markdown) {
            console.log(markdown);
            let out = markdown
            const regex = /\\/gm;
            const escapedString = markdown.replace(regex, "\\\\");

            out = marked.parseInline(escapedString, { renderer });

            out = out.replace(/\\\\+/g, '\\');
            out = out.replace(/\\n/g, '<br>');


            // console.log(out);

            // console.log(out);
            return out;
        },
        addMessages(msg) {
            this.messages.push({
                "type": "human",
                "content": msg.query,
            })
            this.messages.push({
                "type": "ai",
                "content": msg.rawResponse,
            })
            console.log(this.messages);

        },
        addAIMessages(msg) {
            this.messages.push({
                "type": "ai",
                "content": msg.rawResponse,
            })
            console.log(this.messages);

        },
        async generateQuestion() {
            let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/customText"
            this.response = null
            this.loading = true
            let msgs = [{ type: "system", content: this.systemPrompt }, ...this.messages]
            this.showText = false
            console.log(this.messages);
            let response = await axios.post(url, {
                subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                messages: msgs,
                query: this.humanPrompt,
                aiModel: this.aiModel

            }, {
                headers: {
                    ...getHeaders()
                }
            })
            let id = 'q' + this.questions.length
            this.questions.push({ data: { ...response.data }, ...response.data.question, id: id })
            this.addMessages(response.data)
            this.loading = false
            this.humanPrompt = "Generate one more question"
            this.showText = true

            setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 20);

            setTimeout(() => {
                var targetElement = document.getElementById(id);
                targetElement.scrollIntoView({ behavior: 'smooth' });


            }, 200);
        },
        async customText() {
            const { valid } = await this.$refs.qform.validate();


            if (valid) {
                try {
                    let id = 'q' + this.questions.length
                    let systemMessage = { type: "system", content: this.systemPrompt }
                    let msgs = [systemMessage, ...this.messages]
                    console.log(msgs);
                    this.customTextStatus = true
                    setTimeout(() => {
                        $("html, body").animate({ scrollTop: $(document).height() }, 1000);

                    }, 200);

                    let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/customText"
                    let response = await axios.post(url, {
                        subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                        messages: msgs,
                        query: this.humanQuery,
                        aiModel: this.aiModel
                    }, {
                        headers: {
                            ...getHeaders()
                        }
                    })

                    console.log(response);

                    this.questions.push({ data: { ...response.data }, ...response.data.question, id: id })
                    this.addMessages(response.data)


                    this.customTextStatus = false
                    this.humanQuery = ""
                    setTimeout(() => {
                        window.MathJax.typesetPromise();
                    }, 10);

                    setTimeout(() => {
                        var targetElement = document.getElementById(id);
                        targetElement.scrollIntoView({ behavior: 'smooth' });


                    }, 200);
                }
                catch (error) {
                    console.log(error);
                }
            }
        },

    },
    watch: {

    }



}
</script>
  
<style scoped>
.assignment-card {
    border-radius: 12px !important;
    white-space: pre-line !important;
}

.main-lg {
    max-width: 1020px;
    margin: 20px auto;
}

.main-md {
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%;
}



.list-item {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    border-radius: 10px;
    background: rgb(var(--v-theme-report-subCardBg));

}

.skillsBlock {
    max-height: 300px;
    overflow: auto;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}

.qNoClass {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif !important;
}

.htmlclass .list-item.on-hover {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    /* 
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}
</style>