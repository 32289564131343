<template>
    <v-layout class="mb-0 pb-0" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
      <v-container fluid class="h-100 mt-3 mt-md-0 mb-0 pb-0"
        :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
        <v-row align="center" justify="start" dense>
          <v-col cols="12">
            <span class="t-h125 text-center mb-1 text-primaryTextColor">Mento AI Hub</span>
          </v-col>
          <v-col cols="12">
            <span class="t-1rem text-center mb-1 test-subtitle">Create AI based test with different topics, sub topics and
              difficulties.</span>
          </v-col>
        </v-row>
  
        <v-row justify="space-between" dense class="mt-6">
          <v-col cols="6" v-for="item, index in widgets" :key="index">
            <v-card  class="px-2 py-1 assignment-card fill-height card-border" max-width="500px"
            :class="{
           'disabledOpacity' : item.disabled ,
           'click' :  !item.disabled
          }">
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" sm="auto">
                    <v-row dense>
                      <v-avatar size="40" color="#3B3A50">
                          <v-img   height="30"
                          :src="item.icon">
                          </v-img> 
                      </v-avatar>
                    </v-row>
  
                  </v-col>
  
                  <v-col class="t-1rem text-white">
                    {{ item.title }}
                    <v-tooltip  location="top" content-class="custom-tooltip " color="warning">
                      <template #activator="{ props }">
                    <span>
                      <v-icon color="primaryPurple" v-if="item.disabled" v-bind="props">
                          mdi-information-slab-circle-outline
                      </v-icon>
                    </span>
                    </template>
                    <span>This feature is coming soon</span>
  
                    </v-tooltip>
                  </v-col>
  
                </v-row>
  
                <v-row dense class=" text-white">
                  <v-col :class="{
                    'ml-14': smAndUp
                  }">
                    <span>
                      {{ item.subtitile }}
                    </span>
  
                  </v-col>
  
                </v-row>
  
              </v-card-text>
            </v-card>
  
  
          </v-col>
  
        </v-row>
      </v-container>
    </v-layout>
  </template>
    
  <script>
  // import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
  import { useDisplay } from 'vuetify';
  import { mapState } from "vuex";
  import moment from 'moment';
  import _ from "lodash";
  import { securePostAPI } from '@/services/apiService';
  import axios from 'axios';
  import { getHeaders, getApiBaseUrl } from '@/services/authService';
  import $ from "jquery";
  
  const marked = require("marked");
  
  const renderer = new marked.Renderer();
  renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
  };
  
  export default {
    name: 'MentoAIHub',
    data: () => ({
      widgets: [
        {
          'title': 'SAT English practice questions',
          'subtitile': `Lorem ipsum dolor sit amet, consecteturadipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
          'icon': require("@/assets/englishhub.svg"),
          'path': 'enlgish'
        },
        {
          'title': 'SAT English practice questions with custom prompt',
          'subtitile': `Lorem ipsum dolor sit amet, consecteturadipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
          'icon': require("@/assets/englishprompthub.svg"),
          'call': 'engglishWIth',
          disabled : true
        }
  
      ]
    }),
    setup() {
      // Destructure only the keys we want to use
      const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
      return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {
      this.dataReady = false
      await this.$store.dispatch("loadNewLearnNodes");
      this.dataReady = true
    },
    components: {},
  
    computed: {
      ...mapState(["newLearnNodes"]),
    },
  
    methods: {
      openWidget(path) {
        console.log(path);
      }
    },
    watch: {
  
    }
  
  
  
  }
  </script>
    
  <style scoped>
  .assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border: 2px solid rgb(var(--v-theme-reportBgColor)) ;
    border-radius: 10px;
    white-space: pre-line !important;
  }
  
  .click {
      cursor:pointer
  }
  .disabledOpacity {
  }
  
  .card-border:hover{
      border: 2px solid #9461FC;
    }
  
  
    
    .custom-tooltip  { 
      background-color: #FFDC00 !important; /* Your desired background color */
      color: black !important; /* Your desired text color */
    }
  .main-lg {
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
  }
  
  .main-md {
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
  }
  </style>