<template>
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent no-click-animation :model-value="dialog" width="900" :height="$vuetify.display.xs ? '100%' : '80%'" :fullscreen="$vuetify.display.xs">
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar :size="30" class="text-center ma-0 pa-0 mr-2">
                <v-img :height="30" src="@/assets/icon_assignment.png" />
              </v-avatar>
              <span class="t-h125Reg ">{{ formTitle }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
                  <v-form ref="form" v-model="formValue">
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.title" label="Title" density="compact" variant="outlined" :rules="requiredRule" dense ref="AssignmentScreen__assignmentDialog--title"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pr-2">
                <v-text-field append-inner-icon="mdi-calendar" variant="outlined" dense density="compact" label="Start Date" :model-value="getLocalDate(editedItem.startDateTimestamp)" @click.stop="
                    openStartDateModal( editedItem.startDateTimestamp * 1000)" @click:appendInner="
      openStartDateModal(editedItem.dueDateTimestamp * 1000)" ref="AssignmentScreen__assignmentDialog--startDate">
                </v-text-field>
                <v-dialog v-model="startDateModal" width="auto">
                  <v-card outlined>
                    <v-card-text>
                      <DatePicker label="Select Datetime" v-model="startDateTime" :minute-increment="5">
                      </DatePicker>
                      <v-divider col></v-divider>
                    </v-card-text>

                    <v-card-actions class="mb-3 px-0">
                      <v-row justify="end" class="mb-1 mr-2">
                        <v-btn color="primary" @click="cancelStartDateModal()" text>
                          Cancel
                        </v-btn>
                        <v-btn color="primary" @click="saveStartDateModal()" text>
                          Save
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2">
                <v-text-field append-inner-icon="mdi-calendar" variant="outlined" dense density="compact" label="Due Date"
                :rules="[(v => editedItem.dueDateTimestamp >= editedItem.startDateTimestamp || 'Due Date cannot be smaller than start date')]" :model-value="getLocalDate(editedItem.dueDateTimestamp)" @click.stop = "openEndDateModal(editedItem.dueDateTimestamp * 1000)" @click:appendInner="
      openEndDateModal(editedItem.dueDateTimestamp * 1000)" ref="AssignmentScreen__assignmentDialog--endDate"></v-text-field>
                <v-dialog v-model="endDateModal" width="auto">
                  <v-card outlined>
                    <v-card-text>
                      <DatePicker label="Select Date" v-model="endDateTime" :minute-increment="5">
                      </DatePicker>
                      <v-divider col></v-divider>
                    </v-card-text>

                    <v-card-actions class="mb-3">
                      <v-row justify="end" class="mb-1 mr-2">
                        <v-btn color="primary" @click="cancelEndDateModal()" text>
                          Cancel
                        </v-btn>
                        <v-btn color="primary" @click="saveEndDateModal()" text>
                          Save
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" class="pr-2">
                <v-select :items="typeList" item-title="name" item-value="value" v-model="editedItem.type" label="Type"
                  variant="outlined" dense density="compact" :rules="requiredRule" :disabled="editedIndex != -1 || isPasteAssignment" ref="AssignmentScreen__assignmentDialog--type">

                </v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2" v-if="editedItem.type == 'practice'" >
                <div class="field-block px-3">
                  <span class="field-label-name">Assignment Scope</span>
                  <v-row class="pa-0 align-center">
                    <v-col cols="auto" class="pr-0 pl-1">
                      <v-radio-group v-model="editedItem.subType" inline  class="" :disabled="editedIndex != -1 || isPasteAssignment" hide-details @update:modelValue="changeSubType">
                        <v-radio label="Topic" value="topic"></v-radio>
                        <v-radio value="subtopic">
                          <template v-slot:label >
                            <v-list-item density="compact" class="ma-0 pa-0">
                              <v-list-item-title>
                                Sub-topic
                              </v-list-item-title>
                              <template v-slot:append>
                                  <v-badge class="mentoBadge" content="Beta" offset-x="24" offset-y="-12" text-color="white" color="transparent"></v-badge>
                              </template>
                            </v-list-item>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                      <v-tooltip location="top" max-width="300px" :disabled="false">
                        <template #activator="{ props }">
                          <v-icon small class="ml-2" color="iconGrey" v-bind="props">
                            mdi-information-variant-circle-outline
                          </v-icon>
                        </template>
                        <div v-if="editedItem.subType=='topic'">
                          <div class="t-h6Bold">1. Choose Topics:</div>
                          <div class="t-para mt-1">Use the dropdown list to select topics from Reading and Writing, and Math. Each topic selected will be displayed in a grid below the list.</div>
                          <div class="t-h6Bold mt-2">2. Specify Number of Questions by Difficulty:</div>
                          <div class="t-para mt-1">For each topic shown in the grid, input the number of questions you want for each difficulty level. Textboxes are labeled with both the difficulty level and the number of questions available for that level, e.g., "Easy (15)", "Medium (20)", "Hard (35)".</div>
                        </div>
                        <div v-else>
                          <div class="t-h6Bold">1. Choose Topics:</div>
                          <div class="t-para mt-1">From the Reading and Writing, and Math dropdown list, select your main topics. A grid with all sub-topics under each chosen topic will be displayed.</div>
                          <div class="t-h6Bold mt-2">2. Specify Number of Questions by Difficulty:</div>
                          <div class="t-para mt-1">In the grid, for each <strong>sub-topic</strong>, input the desired number of questions per difficulty level. Textboxes indicate the difficulty level and available questions for that <strong>sub-topic</strong>, for instance, "Easy (5)", "Medium (10)", "Hard (15)".</div>
                        </div>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>              
              </v-col>
            </v-row>
            <v-row dense class="align-center pa-0 ma-0 mt-n5">
              <v-col class="t-1remReg ma-0 py-0 d-flex align-center" cols="auto" ref="AssignmentScreen__assignmentDialog--hideAnswers">
                <span>Hide answers from student(s)</span>
                <v-switch  class="my-0 py-0 ml-4" color="primaryPurple" v-model="hideAssignmentSolution" hide-details density="compact" dense></v-switch>
              </v-col>
              <!-- <v-col cols="auto">
              </v-col> -->
            </v-row>
            <v-row v-if="editedItem.type == 'practice'" dense>
              <v-col cols="12" sm="6" class="pr-2">
                <v-select :items="englishTopicList" 
                  :item-title="(t)=>`${t.entityLabel}${(editedItem.subType=='subtopic' && !t.isSubTopicReady)?' (Coming Soon)':''}`" 
                  :item-props="(t)=>({
                    disabled: editedItem.subType=='subtopic' && !t.isSubTopicReady
                  })"
                  item-value="entityId"
                  v-model="editedItem.details.practice.englishTopics" label="Reading and writing topics" density="compact" dense variant="outlined"
                  :disabled="editedIndex != -1 || isPasteAssignment" multiple
                  @update:modelValue="changeEnglishTopic"
                  :rules="[(v) => (v.length>0 || editedItem.details.practice.mathTopics.length>0) ||  'Please select atleast one topic on either subjects']"
                  ref="englishTopic">
                  <template v-slot:selection="{ item, index }">
                    <span v-if="editedItem.details.practice.englishTopics.length ==
                      englishTopicList.length && index == 0
                      ">
                      All
                    </span>
                    <span v-else-if="editedItem.details.practice.englishTopics.length > 1 &&
                      index == 0
                      ">
                      +{{ editedItem.details.practice.englishTopics.length }}
                    </span>
                    <span v-else-if="index == 0" class="filter-selected-text">
                      {{ item.title }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pl-2">
                <v-select :items="mathTopicList" 
                  :item-title="(t)=>`${t.entityLabel}${(editedItem.subType=='subtopic' && !t.isSubTopicReady)?' (Coming Soon)':''}`" 
                  :item-props="(t)=>({
                    disabled: editedItem.subType=='subtopic' && !t.isSubTopicReady
                  })"
                  item-value="entityId"
                  v-model="editedItem.details.practice.mathTopics" label="Math topics" density="compact" dense variant="outlined"
                  :disabled="editedIndex != -1 || isPasteAssignment" @update:modelValue="changeMathTopic" multiple 
                  :rules="  [(v) => (v.length>0 || editedItem.details.practice.englishTopics.length>0) ||  'Please select atleast one topic on either subjects']"
                  ref="mathTopic">
                  <template v-slot:selection="{ item, index }">
                    <span v-if="editedItem.details.practice.mathTopics.length ==
                      mathTopicList.length && index == 0
                      ">
                      All
                    </span>
                    <span v-else-if="editedItem.details.practice.mathTopics.length > 1 &&
                      index == 0
                      ">
                      +{{ editedItem.details.practice.mathTopics.length }}
                    </span>
                    <span v-else-if="index == 0" class="filter-selected-text">
                      {{ item.title }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" v-if="editedItem.details.practice.englishTopics.length>0 || editedItem.details.practice.mathTopics.length>0">
                <div class="topic-questions-block pt-4 px-3" v-if="editedItem.subType=='topic'">
                  <span class="t-1remReg label-name">No. of Questions</span>
                  <v-row dense v-for="topic in editedItem.details.practice.englishTopics" :key="topic">
                    <v-col cols="12" sm="6" class="t-h6Reg">
                      {{ getEnglishTopicName(topic) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-2">
                      <v-row dense>
                        <v-col cols="4" class="pr-2">
                          <v-text-field v-model="editedItem.details.practice.englishTopicsQuestionCount[`${topic}`].easyQuestionCount" label="Easy" density="compact"
                            variant="outlined" :rules="requiredRule" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="editedItem.details.practice.englishTopicsQuestionCount[topic].mediumQuestionCount
                            " label="Medium" density="compact" variant="outlined" :rules="requiredRule" dense
                            :disabled="editedIndex != -1 || isPasteAssignment" @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pl-2">
                          <v-text-field v-model="editedItem.details.practice.englishTopicsQuestionCount[topic].hardQuestionCount" label="Hard" density="compact"
                            variant="outlined" :rules="requiredRule" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense v-for="topic in editedItem.details.practice.mathTopics" :key="topic">
                    <v-col cols="12" sm="6" class="t-h6Reg">
                      {{ getMathTopicName(topic) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-2">
                      <v-row dense>
                        <v-col cols="4" class="pr-2">
                          <v-text-field v-model="editedItem.details.practice.mathTopicsQuestionCount[`${topic}`].easyQuestionCount" label="Easy" density="compact"
                            variant="outlined" :rules="requiredRule" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="editedItem.details.practice.mathTopicsQuestionCount[topic].mediumQuestionCount
                            " label="Medium" density="compact" variant="outlined" :rules="requiredRule" dense
                            :disabled="editedIndex != -1 || isPasteAssignment" @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pl-2">
                          <v-text-field v-model="editedItem.details.practice.mathTopicsQuestionCount[topic].hardQuestionCount" label="Hard" density="compact"
                            variant="outlined" :rules="requiredRule" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="sub-topic-block">
                  <v-row dense v-for="topic in editedItem.details.practice.englishTopics" :key="topic" class='mb-7'>
                  <div class="topic-questions-block w-100 pt-4 px-3">
                  <span class="t-1remReg label-name"> {{ getEnglishTopicName(topic) }}</span>
                  <v-row dense v-for="(subTopic, key) in editedItem.details.practice.englishSubTopicsQuestionCount[topic]" :key="subTopic">
                    <v-col cols="12" sm="6" class="t-h6Reg">
                      {{ getSubTopicName(key) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-2">
                      <v-row dense>
                        <v-col cols="4" class="pr-2">
                          <v-text-field v-model="editedItem.details.practice.englishSubTopicsQuestionCount[topic][key].easyQuestionCount" :label="`Easy (${getEasyCount(key)})`" density="compact"
                            variant="outlined" :rules="checkQuestionLimit(getEasyCount(key))" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="editedItem.details.practice.englishSubTopicsQuestionCount[topic][key].mediumQuestionCount
                            " :label="`Medium (${getMediumCount(key)})`" density="compact" variant="outlined" :rules=" checkQuestionLimit(getMediumCount(key))" dense
                            :disabled="editedIndex != -1 || isPasteAssignment" @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pl-2">
                          <v-text-field v-model="editedItem.details.practice.englishSubTopicsQuestionCount[topic][key].hardQuestionCount" :label="`Hard (${getHardCount(key)})`" density="compact"
                            variant="outlined" :rules="checkQuestionLimit(getHardCount(key))" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                  </v-row>
                  <v-row dense v-for="topic in editedItem.details.practice.mathTopics" :key="topic" class='mb-7'>
                    <div class="topic-questions-block w-100 pt-4 px-3">
                  <span class="t-1remReg label-name"> {{ getMathTopicName(topic) }}</span>
                  <v-row dense v-for="(subTopic, key) in editedItem.details.practice.mathSubTopicsQuestionCount[topic]" :key="subTopic">
                    <v-col cols="12" sm="6" class="t-h6Reg">
                      {{ getSubTopicName(key) }}
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-2">
                      <v-row dense>
                        <v-col cols="4" class="pr-2">
                          <v-text-field v-model="editedItem.details.practice.mathSubTopicsQuestionCount[topic][key].easyQuestionCount" :label="`Easy (${getEasyCount(key)})`" density="compact"
                            variant="outlined" :rules="checkQuestionLimit(getEasyCount(key))" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="px-2">
                          <v-text-field v-model="editedItem.details.practice.mathSubTopicsQuestionCount[topic][key].mediumQuestionCount
                            " :label="`Medium (${getMediumCount(key)})`" density="compact" variant="outlined" :rules="checkQuestionLimit(getMediumCount(key))" dense
                            :disabled="editedIndex != -1 || isPasteAssignment" @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pl-2">
                          <v-text-field v-model="editedItem.details.practice.mathSubTopicsQuestionCount[topic][key].hardQuestionCount" :label="`Hard (${getHardCount(key)})`" density="compact"
                            variant="outlined" :rules="checkQuestionLimit(getHardCount(key))" dense :disabled="editedIndex != -1 || isPasteAssignment"
                            @update:modelValue="changePracticeAssignment" class="question-count-input"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                  </v-row>
                </div>
                
                  
              </v-col>
            </v-row>
            <v-row v-if="editedItem.type == 'mocktest'" dense>
              <v-col cols="12" sm="6" class="pr-2">
                <v-select
                  :items="testList"
                  item-title="testName"
                  item-value="testId"
                  v-model="editedItem.details.mockTestId"
                  label="Test Name"
                  density="compact"
                  dense
                  variant="outlined"
                  :rules="requiredRule"
                  :disabled="editedIndex != -1 || isPasteAssignment"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="editedItem.type == 'practicewithtag'" dense>
              <v-col cols="12">
                <v-radio-group v-model="practiceWithTagType" inline dense class="" v-if="isDevEnvironment" :disabled="editedIndex != -1 || isPasteAssignment">
                  <v-radio label="Tag" value="tag"></v-radio>
                  <v-radio label="Test" value="test"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" class="pr-2" v-if="practiceWithTagType == 'tag'">
                <v-autocomplete
                  :items="tagList"
                  v-model="editedItem.details.tagList"
                  label="Tags"
                  density="compact"
                  dense
                  variant="outlined"
                  :rules="requiredRule"
                  :disabled="editedIndex != -1 || isPasteAssignment"
                  multiple
                >
                <template v-slot:selection="{ item, index }">
                  <span v-if="editedItem.details.tagList.length ==
                    tagList.length && index == 0
                    ">
                    All
                  </span>
                  <span v-else-if="editedItem.details.tagList.length > 1 &&
                    index == 0
                    ">
                    +{{ editedItem.details.tagList.length }}
                  </span>
                  <span v-else-if="index == 0" class="filter-selected-text">
                    {{ item.title }}
                  </span>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pr-2" v-else-if="isDevEnvironment">
                <v-autocomplete
                  :items="normalTestList"
                  item-title="testName"
                  item-value="testId"
                  v-model="editedItem.details.testId"
                  label="Normal Test Name"
                  density="compact"
                  dense
                  variant="outlined"
                  :rules="requiredRule"
                  :disabled="editedIndex != -1 || isPasteAssignment"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      <v-divider :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn
          v-if="editedIndex != -1"
          class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="deleteAssignment()"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
        v-if="
        (editedItem.type == 'practice' && (editedItem.details.practice.englishTopics.length>0 || editedItem.details.practice.mathTopics.length>0))
        ||
        (editedItem.type == 'practicewithtag' && (editedIndex != -1 || isPasteAssignment ))
        "
        class="create-btn t-1remReg text-none mr-2 btn-border" density="default" variant="outlined" height="40"
                    width="180" dark :disabled="!formValue"   @click.stop="openQuestionsPage()">View
                    Questions</v-btn>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="close"
          >Cancel</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="save"
          >{{ editedIndex == -1 ? "Assign" : "Update" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  <v-dialog
    style="backdrop-filter: blur(12.5px)"
    persistent
    :model-value="dialogDelete"
    max-width="400px"
  >
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0"

        >
        <span class="assignment-form-title">Are you sure you want to delete 
        ' {{ editedItem.title }}' ?</span>
        </v-card-text
      >
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="closeDeleteAssignment()"
          >No</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="deleteItem()"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog fullscreen persistent :model-value="dialogViewQuestions" scrollable>
    <v-card>
      <v-card-text class="ma-0 pa-0">
        <ViewQuestionsComponent
          :questionsData="viewQuestionsData"
          :assignmentId="editedItem.assignmentId"
          :testId="editedItem.details.practiceTestId"
          :assignmentType="editedItem.type"
          @closeDialog="closeQuestionsPage"
        >
        </ViewQuestionsComponent>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-container v-if="dataLoaded" fluid class="pa-5">
    <v-row class="mb-5">
      <v-col cols="auto">
        <v-btn
          class="assignment-btn t-1remReg text-none mr-3 text-white"
          density="default"
          variant="flat"
          width="100"
          height="40"
          :class="{ 'assignment-activity-btn': current }"
          ref="StudentDetailPageScreen__studentsComponent--currentBtn"
          @click.stop="filterCurrentAssignments(true)"
          >Current</v-btn
        >
        <v-btn
          class="assignment-btn t-1remReg text-none text-white"
          density="default"
          variant="flat"
          width="80"
          height="40"
          :class="{ 'assignment-activity-btn': !current }"
          @click.stop="filterCurrentAssignments(false)"
          ref="StudentDetailPageScreen__studentsComponent--pastBtn"
          >Past</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
      <!-- <v-col cols="auto" v-if="checkIfCanAssignmentPasteHere()">
        <v-btn
          class="t-btn text-white activity-btn text-none t-1remReg"
          density="default"
          variant="flat"
          height="40"
          @click="pasteAssignment"
          >Paste Here</v-btn
        >
      </v-col> -->
      <v-col cols="auto">
        <v-btn
          class="t-btn text-white activity-btn text-none t-1remReg"
          density="default"
          variant="flat"
          height="40"
          @click="newItem"
          v-if="studentAssignmentActionsAllowed"
          ref="StudentDetailPageScreen__studentsComponent--createNewBtn"
          >Create New</v-btn
        >
      </v-col>
    </v-row>
    <div class="ma-3">
      <v-row
        v-for="(assignment, i) in assignmentList"
        :key="assignment.assignmentId"
        class="list-item text-white align-center"
        :ref="`StudentDetailPageScreen__studentsComponent--assignmentCard${i}`"
      >
        <v-col cols="12">
          <v-row class="align-center" dense>
            <v-col
              cols="12"
              md="4"
              lg="5"
              :class="{ 'mb-2': !$vuetify.display.mdAndUp }"
            >
              <v-row dense>
                <v-col
                  cols="12"
                  class="py-0 t-1remReg"
                  :class="{ 'text-center': !$vuetify.display.mdAndUp }"
                >
                  <span>{{ assignment.title }}</span>

                  <!-- Copy Assignment -->
                  <!-- <v-tooltip
                      location="top"
                      :open-on-click="
                        assignment.assignedTo.assignmentType != assignmentType
                      "
                    >
                      <template #activator="{ props }">
                        <v-btn v-if="isDevEnvironment" variant="text" class="ml-1" size="24" @click.stop="copyAssignmentAction(assignment)" icon="mdi-content-copy" v-bind="props"></v-btn>
                      </template>
                      <span>Copy Assignment</span>
                    </v-tooltip> -->
                  
                </v-col>
                <v-col
                  cols="12"
                  class="py-0 t-h6Reg text-primaryGrey"
                  :class="{ 'text-center': !$vuetify.display.mdAndUp }"
                >
                  <span class="mdi" :class="assignment.showAssignmentSolution? 'mdi-lock-open-variant-outline': 'mdi-lock-outline'"></span>
                  <span class="ml-1">{{ displayTypeName(assignment.type) }}</span>
                                  </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8" lg="7">
              <v-row justify-md="end" class="align-center">
                <v-col cols="6" sm="3">
                  <v-row align="center" dense>
                    <v-col cols="12" class="py-0 t-h6Reg">
                      <span>{{
                        displayLocalDate(assignment.startDate)
                      }}</span>
                    </v-col>
                    <v-col cols="12" class="py-0 t-h6Reg text-primaryGrey">
                      <span>Start</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-row align="center" justify="center" dense>
                    <v-col cols="12" class="py-0 t-h6Reg">
                      <span>{{
                        displayLocalDate(assignment.dueDate)
                      }}</span>
                    </v-col>
                    <v-col cols="12" class="py-0 t-h6Reg text-primaryGrey">
                      <span>Due</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" sm="4">
                  <v-row
                    align="center"
                    justify="center"
                    dense
                    v-if="assignmentType == 'batch'"
                  >
                    <v-col cols="12" class="py-0 t-h6Reg">
                      <span>{{
                        `${assignment.progressSummary.classAssignmentSummary.noOfStudentsCompleted}/${assignment.progressSummary.classAssignmentSummary.noOfStudentsAssigned}`
                      }}</span>
                    </v-col>
                    <v-col cols="12" class="py-0 t-h6Reg">
                      <span>Completed</span>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center" dense v-else>
                    <v-col cols="12" v-if="['practice', 'practicewithtag'].includes(assignment.type)">
                      <v-row dense align="center" justify="center">
                        <v-col
                          cols="6"
                          v-if="
                            assignment.progressSummary.studentPracticeSummary
                              .status == 'completed'
                          "
                        >
                          <v-row
                            align="center"
                            justify="center"
                            dense
                            @click="
                              openPracticeReport(
                                assignment.progressSummary
                                  .studentPracticeSummary
                              )
                            "
                            class="pointer-cursor"
                          >
                            <v-col cols="12" class="py-0 t-h6Reg">
                              <span>{{
                                `${assignment.progressSummary.studentPracticeSummary.correctQuestions}/${assignment.progressSummary.studentPracticeSummary.totalQuestions}`
                              }}</span>
                            </v-col>
                            <v-col
                              cols="12"
                              class="py-0 t-h6Reg text-primaryGrey"
                            >
                              <span>Correct</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="6"
                          v-else-if="
                            assignment.progressSummary.studentPracticeSummary
                              .status == 'inprogress'
                          "
                        >
                          <v-row
                            align="center"
                            justify="center"
                            dense
                            @click="
                              openPracticeReport(
                                assignment.progressSummary
                                  .studentPracticeSummary
                              )
                            "
                            class="pointer-cursor"
                          >
                            <v-col cols="12" class="py-0 t-h6Reg">
                              <span>{{
                                `${assignment.progressSummary.studentPracticeSummary.attemptedQuestions}/${assignment.progressSummary.studentPracticeSummary.totalQuestions}`
                              }}</span>
                            </v-col>
                            <v-col
                              cols="12"
                              class="py-0 t-h6Reg text-primaryGrey"
                            >
                              <span>Attempted</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" v-else class="py-0 t-h6Reg">
                          <span>-</span>
                        </v-col>
                        <v-col cols="3">
                          <v-tooltip
                            location="top"
                            v-if="
                            assignment.progressSummary.studentPracticeSummary
                                .status == 'completed'
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  size="30"
                                  v-bind="props"
                                    icon="mdi-check-circle-outline"
                                    color="success"
                                    @click="
                                    openPracticeReport(
                                      assignment.progressSummary
                                        .studentPracticeSummary
                                    )
                                  "
                                  ></v-icon>
                            </template>
                            <span>Completed</span>
                          </v-tooltip>
                          <v-tooltip
                            location="top"
                            v-if="
                            (
                            assignment.progressSummary.studentPracticeSummary
                              .status == 'inprogress'
                                )
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  v-bind="props"
                                    size="30"
                                    icon="mdi-progress-pencil"
                                    color="primary"
                                    @click="
                                    openPracticeReport(
                                      assignment.progressSummary
                                        .studentPracticeSummary
                                    )
                                  "
                    
                                  ></v-icon>
                            </template>
                            <span>In progress</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="3">
                          <v-tooltip
                            location="top"
                            v-if="
                              (assignment.progressSummary.studentPracticeSummary
                                .status == 'completed') || (assignment.progressSummary.studentPracticeSummary
                              .status == 'inprogress'
                                )
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  size="30"
                                  v-bind="props"
                                    icon="mdi-chart-box-outline"
                                    color="primaryPurple"
                                    @click="
                                      openPracticeReport(
                                        assignment.progressSummary
                                          .studentPracticeSummary
                                      )
                                    "
                              ></v-icon>
                            </template>
                            <span>View Reports</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-if="assignment.type == 'mocktest'">
                      <v-row dense align="center">
                        <v-col
                          cols="6"
                          v-if="
                            assignment.progressSummary
                              .studentPracticeTestSummary.status == 'completed'
                          "
                        >
                          <v-row
                            align="center"
                            justify="center"
                            dense
                            @click="
                              openPracticeTestReport(
                                assignment.progressSummary
                                  .studentPracticeTestSummary
                              )
                            "
                            class="pointer-cursor"
                          >
                            <v-col cols="12" class="py-0 t-h6Reg">
                              <span>{{
                                `${assignment.progressSummary.studentPracticeTestSummary.overAllScore.lowerScore} -
                                                              ${assignment.progressSummary.studentPracticeTestSummary.overAllScore.upperScore}`
                              }}</span>
                            </v-col>
                            <v-col
                              cols="12"
                              class="py-0 t-h6Reg text-primaryGrey"
                            >
                              <span>Score Range</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" v-else class="py-0 t-h6Reg">
                          <span>-</span>
                        </v-col>
                        <v-col cols="3">
                          <v-tooltip
                            location="top"
                            v-if="
                            assignment.progressSummary.studentPracticeTestSummary
                                .status == 'completed'
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  size="30"
                                  v-bind="props"
                                    icon="mdi-check-circle-outline"
                                    color="success"
                                    @click="
                                      openPracticeTestReport(
                                        assignment.progressSummary
                                          .studentPracticeTestSummary
                                      )
                                    "
                              ></v-icon>
                            </template>
                            <span>Completed</span>
                          </v-tooltip>
                          <v-tooltip
                            location="top"
                            v-if="
                            (
                            assignment.progressSummary.studentPracticeTestSummary
                              .status == 'inprogress'
                                )
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  v-bind="props"
                                    size="30"
                                    icon="mdi-progress-pencil"
                                    color="primary"
                                    @click="
                                      openPracticeTestReport(
                                        assignment.progressSummary
                                          .studentPracticeTestSummary
                                      )
                                    "
                                  ></v-icon>
                            </template>
                            <span>In progress</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="3">
                          <v-tooltip
                            location="top"
                            v-if="
                              (assignment.progressSummary
                                .studentPracticeTestSummary.status ==
                              'completed') || (assignment.progressSummary.studentPracticeTestSummary
                              .status == 'inprogress'
                                )
                            "
                          >
                            <template #activator="{ props }">
                              <v-icon
                                  size="30"
                                  v-bind="props"
                                    icon="mdi-chart-box-outline"
                                    color="primaryPurple"
                                    @click="
                                      openPracticeTestReport(
                                        assignment.progressSummary
                                          .studentPracticeTestSummary
                                      )
                                    "
                              ></v-icon>
                            </template>
                            <span>View Reports</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="auto">
                  <v-row :class="{'hideButtons': (!studentAssignmentActionsAllowed || assignment.subType=='ai')}" align="center">
                    <v-tooltip
                      location="top"
                      :open-on-click="
                        assignment.assignedTo.assignmentType != assignmentType
                      "
                    >
                      <template #activator="{ props }">
                        <v-icon
                          small
                          @click="
                            assignment.assignedTo.assignmentType ==
                            assignmentType
                              ? editItem(assignment)
                              : undefined
                          "
                          v-bind="props"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{
                        assignment.assignedTo.assignmentType == assignmentType
                          ? "Edit Assignment"
                          : "Please edit from class assignment view"
                      }}</span>
                    </v-tooltip>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-icon
                          v-bind="props"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </template>
                      <v-list density="compact" nav>
                        <v-tooltip
                      location="top"
                      :open-on-click="
                        assignment.assignedTo.assignmentType != assignmentType
                      "
                      :open-on-hover="false"
                    >
                      <template #activator="{ props }">
                        <v-list-item 
                          @click="
                            assignment.assignedTo.assignmentType ==
                            assignmentType
                              ? editItem(assignment)
                              : undefined
                          "
                          v-bind="props"
                          >
                          <div class="d-flex align-center">
                            <v-icon :height="20" :width="20" class="mr-2">
                              mdi-pencil
                            </v-icon>

                            <v-list-item-title>
                              Edit Assignment
                            </v-list-item-title>
                          </div>
                        </v-list-item>
                      </template>
                      <span>{{
                        assignment.assignedTo.assignmentType == assignmentType
                          ? ""
                          : "Please edit from class assignment view"
                      }}</span>
                    </v-tooltip>
                        
                   
                        <v-list-item   @click.stop="copyAssignmentAction(assignment)">
                          <div class="d-flex align-center">
                            <v-icon :height="20" :width="20" class="mr-2">
                              mdi-content-copy
                            </v-icon>

                            <v-list-item-title>
                              Copy Assignment
                            </v-list-item-title>
                          </div>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <!-- <v-col cols="auto">
          <v-row justify="end" align="center">
            <v-tooltip right>
              <template #activator="{ props }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(assignment)"
                  v-bind="props"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Assignment</span>
            </v-tooltip>
          </v-row>
        </v-col> -->
      </v-row>
      <v-row
        v-if="assignmentList.length <= 0"
        class="list-item text-white align-center"
        ref="StudentDetailPageScreen__studentsComponent--assignmentCard0"
      >
        <v-col cols="12" class="pa-3 text-center t-h6Reg">
          <span>No Assignment Found</span>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <!-- <v-snackbar v-model="showCopyMessage" color="cardBackgroundColor" location="top right">
    <span class="submit-message-text">Assignment Copied- <span class="text-primaryGrey">{{ copyAssignmentName }}</span></span>
    <template v-slot:actions>
      <v-tooltip>
        <template #activator="{ props }">
          <v-icon
            small
            class="mr-2"
            @click.stop="closeSnackBar()"
            v-bind="props"
            color="#8e8ea7"
          >
            mdi-close
          </v-icon>
        </template>
        <span></span>
      </v-tooltip>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar> -->
</template>

<script>
import moment from "moment-timezone";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import { nextTick } from "vue";
import ViewQuestionsComponent from "./ViewQuestionsComponent.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";

export default {
  name: "AssignmentComponent",
  props: ["assignmentType", "batchId", "studentId"],
  async created() {
    this.dataLoaded= false;
    this.$store.commit("increamentLoader");
    this.$store.commit("entitiesLoadedFromServer", false);
    this.$store.dispatch("loadEntities");
    this.$store.commit('resetTeacherAssignments')
    await this.filterCurrentAssignments(true);
    await this.$store.commit("testsLoadedFromServer", false)
    await this.$store.dispatch("loadAllTests")
    
    if(this.isDevEnvironment){
      await this.$store.dispatch("loadTags");
      await this.$store.dispatch("loadNormalTests");
    }
    if('batchId' in this.$route.params){
      this.pinpointBatchId = this.$route.params.batchId;
      this.fromClass = true;
    }
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabOpen, {
      courseId: this.courseId,
      fromClass : this.fromClass,
      batchId: this.pinpointBatchId,
      studentId: this.$route.params.studentId?? "",
    });
    this.$eventBus.emit('assignmentEvent', {'isPasteHere': this.checkIfCanAssignmentPasteHere()} );
    this.$store.commit("decreamentLoader");
    this.dataLoaded = true;
  },
  components: { DatePicker, ViewQuestionsComponent },
  data: () => ({
    currentScreenName: "StudentDetailPageScreen",
    dataLoaded: false,
    formValue: false,
    current: true,
    activeBtnIndex: 0,
    dialog: false,
    dialogViewQuestions: false,
    dialogDelete: false,
    startDateModal: false,
    endDateModal: false,
    startDateTime: {},
    endDateTime: {},
    defaultItem: {
      title: "",
      startDateTimestamp: "",
      dueDateTimestamp: "",
      type: "practice",
      subType: 'topic',
      details: {
        practiceTestId: "",
        mockTestId: "",
        tagList: [],
        practice: {
          mathTopics: [],
          mathTopicsQuestionCount:{},
          englishTopics: [],
          englishTopicsQuestionCount:{},
          englishSubTopicsQuestionCount:{},
          mathSubTopicsQuestionCount:{},
        },
        testId: ''
      },
      showAssignmentSolution: true
    },
    editedItem: {
      title: "",
      startDateTimestamp: "",
      dueDateTimestamp: "",
      type: "practice",
      subType: 'topic',
      details: {
        practiceTestId: "",
        mockTestId: "",
        tagList: [],
        practice: {
          mathTopics: [],
          mathTopicsQuestionCount:{},
          englishTopics: [],
          englishTopicsQuestionCount:{},
          englishSubTopicsQuestionCount:{},
          mathSubTopicsQuestionCount:{},
        },
        testId: ''
      },
      showAssignmentSolution: true
    },
    editedIndex: -1,
    requiredRule: [(v) => !!v || "Required"],
    viewQuestionsData: {},
    copyAssignmentName: "",
    showCopyMessage: false,
    isPasteAssignment: false,
    fromClass: false,
    pinpointBatchId: "",
    practiceWithTagType: 'tag'
  }),
  computed: {
    ...mapState(["newLearnNodes", "studentAssignmentActionsAllowed", "config", "copyAssignment", "courseId"]),
    ...mapGetters(['currentHighlightedElements']),
    formTitle() {
      return this.editedIndex === -1 ? `${this.isPasteAssignment ? 'Assignment Clone': 'New Assignment'}`  : "Edit Assignment" ;
    },
    typeList: {
      get() {
        const commonTypes = [
          { name: "Practice", value: "practice" },
          { name: "Practice Test", value: "mocktest" },
        ];

        const typeListForDev = [
          ...commonTypes,
          { name: "Practice(With tag)", value: "practicewithtag" },
        ];

        return (this.config.environment=="dev")?typeListForDev :commonTypes;
      },
    },
    testList: {
      get() {
        return _.orderBy(this.$store.state.tests, ["order"], "asc");
      },
    },
    tagList: {
      get() {
        return _.orderBy(this.$store.state.tagList);
      },
    },
    subjectList: {
      get() {
        return _.map(this.newLearnNodes, (item) =>
          _.pick(item, ["subjectId", "subjectName", "subjectType"])
        );
      },
    },
    englishTopicList: {
      get() {
        let item = _.find(this.newLearnNodes, (item) => {
          return item.subjectType == 'readingandwriting';
        });
        let topicData = [];
        if (item && item.categories!=null){
          for (let categories of item.categories) {
            topicData.push(...categories.entity);
          }
        }
        return topicData;
      },
    },
    mathTopicList: {
      get() {
        let item = _.find(this.newLearnNodes, (item) => {
          return item.subjectType == 'math';
        });
        let topicData = [];
        if (item && item.categories!=null) {
          for (let categories of item.categories) {
            topicData.push(...categories.entity);
          }
        }
        return topicData;
      },
    },
    subTopicList:{
      get(){
        const subTopicData = [];
        for(let item of this.newLearnNodes){
          if (item && item.categories!=null){

          for (let categories of item.categories) {
            for(let topic of categories.entity){
              subTopicData.push(...topic.subTopics);
            }
          }
        
          }}
        return _.orderBy(subTopicData, ['order','label'], ['asc', 'asc']);
      }
    },
    englishSubTopicList: {
      get() {
        let subTopicData = [];
        for (let topic of this.englishTopicList) {
          subTopicData.push({...topic.subTopics});
        }
        return subTopicData;
      },
    },
    mathSubTopicList: {
      get() {
        let subTopicData = [];
        for (let topic of this.mathTopicList) {
          subTopicData.push({...topic.subTopics});
        }
        return subTopicData;
      },
    },
    assignmentList: {
      get() {
        return _.orderBy(
          this.current ? this.$store.state.teacherCurrentAssignmentList : this.$store.state.teacherPastAssignmentList,
          ["dueDateTimeStamp", "title"],
          [this.current ? "asc" : "desc","asc"]
        );
      },
    },
    isDevEnvironment: {
      get(){
        return this.config.environment=="dev";
      }
    },
    normalTestList: {
      get() {
        return _.orderBy(this.$store.state.normalTestList, ['order'], 'asc');
      },
    },
    hideAssignmentSolution: {
      get(){
        return !this.editedItem.showAssignmentSolution;
      },
      set(val){
        this.editedItem.showAssignmentSolution = !val;
      }
    }
  },
  methods: {
    getAssignmentDetails() {
      if (this.assignmentType == "batch") {
        return "Here are the assignments you created so far.";
      } else {
        return "Here's how your student did on the content you assigned to them.";
      }
    },
    newItem() {
      
      this.dialog = true;
      this.editedItem = _.cloneDeep(this.defaultItem);
      this.editedIndex = -1;
      this.editedItem.startDateTimestamp = this.getStartTimeStamp(moment());
      this.editedItem.dueDateTimestamp = this.getStartTimeStamp(
        moment().add(6, "days")
      );
      // console.log(this.editedItem.startDateTimestamp);
      this.practiceWithTagType = "tag";
      this.$store.commit('updateCurrentScreenName', 'AssignmentScreen__assignmentDialog');
    },
    editItem(item) {
     
      // console.log("editItem", item);
      this.editedIndex = this.assignmentList.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.editedItem.startDateTimestamp = this.editedItem.startDateTimeStamp;
      this.editedItem.dueDateTimestamp = this.editedItem.dueDateTimeStamp;
      if (item.details.practice && item.details.practice.length > 0) {
        this.editedItem.subType = this.editedItem.subType || (this.editedItem.subType && this.editedItem.subType!= '')|| 'topic';
        const assignmentPractice = {
          mathTopics: [],
          mathTopicsQuestionCount:{},
          englishTopics: [],
          englishTopicsQuestionCount:{},
          englishSubTopicsQuestionCount:{},
          mathSubTopicsQuestionCount:{},
        }
        _.forEach(item.details.practice, practice=>{
          const subject = _.find(this.subjectList, {subjectId: practice.subjectId})
          if(subject.subjectType == 'readingandwriting'){
            
            if(item.subType == 'subtopic'){
              if(_.isEmpty(assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]])){
                assignmentPractice.englishTopics.push(practice.topicId);
                assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]]={};
              }
              assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]][[practice.subTopicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              }
            }else{
              assignmentPractice.englishTopics.push(practice.topicId);
              assignmentPractice.englishTopicsQuestionCount[[practice.topicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              };
            }
          }else{
            if(item.subType == 'subtopic'){
              if(_.isEmpty(assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]])){
                assignmentPractice.mathTopics.push(practice.topicId);
                assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]]={};
              }
              assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]][[practice.subTopicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              }
            }else{
              assignmentPractice.mathTopics.push(practice.topicId);
              assignmentPractice.mathTopicsQuestionCount[[practice.topicId]]={
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              };
            }
          }
        })
        this.editedItem.details.practice = assignmentPractice;
        this.$store.commit('updateCurrentScreenName', 'AssignmentScreen__assignmentDialog');
      }
      if(item.type=='practicewithtag'){
        if(!_.isEmpty(item.details.tagList)){
          this.practiceWithTagType = "tag";
        }else{
          this.practiceWithTagType = "test";
        }
      }
      // console.log("editItem-1", this.editedItem);
      // console.log(item.dueDateTimestamp, this.editedItem.dueDateTimestamp);
      this.editedItem.showAssignmentSolution = _.get(item, 'showAssignmentSolution', true);
      this.dialog = true;
    },
    deleteAssignment() {
      this.dialog = false;
      this.dialogDelete = true;
    },
    closeDeleteAssignment(){
      this.dialogDelete = false;
      this.dialog = true;
    },
    async deleteItem() {
      const reqData = {
        assignmentId: this.editedItem.assignmentId,
        isCurrent: this.current
      };
      await this.$store.dispatch("deleteTeacherAssignment", reqData);
      this.dialogDelete = false;
      this.editedItem = _.cloneDeep(this.defaultItem);
    },
    displayTypeName(type) {
      const foundType = _.find(this.typeList, { value: type });
      if (foundType) {
        return foundType.name;
      }
      return "";
    },
    displayLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      let displayDate = moment(date, "YYYY-MM-DD");

      // Check for today, yesterday, or other dates
      if (displayDate.isSame(moment(), "day")) {
        return "Today ";
      } else if (displayDate.isSame(moment().subtract(1, "days"), "day")) {
        return "Yesterday ";
      } else if (displayDate.isSame(moment().add(1, "days"), "day")) {
        return "Tomorrow ";
      } else {
        // Format as MM/DD/YYYY 12:00 AM
        return displayDate.format("ll");
      }
    },
    getLocalDateTime(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      let d = new Date(date * 1000);
      return moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("lll");
    },
    getLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      // console.log("getLocalDate", date);
      let d = new Date(date * 1000);
      // console.log("getLocalDate-1", d);
      return moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MM/DD/YYYY");
    },
    openStartDateModal(startDate) {
      this.startDateTime = startDate;
      this.startDateModal = true;
    },
    saveStartDateModal() {
      this.editedItem.startDateTimestamp = this.startDateTime / 1000;
      // console.log(this.editedItem.startDateTimestamp);
      this.closeStartDateModal();
      // this.editedItem.dueDateTimestamp = this.startDateTime;
      // this.editedItem.dueDateTimestamp = this.getStartTimeStamp(
      //   moment(this.editedItem.dueDateTimestamp).add(6, "d")
      // );
      // console.log(this.editedItem.dueDateTimestamp);
    },
    cancelStartDateModal() {
      // this.editedItem.startDate = "";
      this.closeStartDateModal();
    },
    cancelEndDateModal() {
      this.closeEndDateModal();
      // this.editedItem.endDate = "";
    },
    closeEndDateModal() {
      this.endDateModal = false;
    },

    closeStartDateModal() {
      this.startDateModal = false;
    },
    openEndDateModal(endDate) {
      this.endDateTime = endDate;
      this.endDateModal = true;
    },
    saveEndDateModal() {
      this.editedItem.dueDateTimestamp = this.endDateTime / 1000;
      this.closeEndDateModal();
    },
    selectedFromDate(item) {
      // console.log(item);
      if (this.editedItem.startDateTimestamp != this.fromDate) {
        this.editedItem.dueDateTimestamp = "";
        this.toDate = "";
      }
      this.editedItem.startDateTimestamp = this.fromDate;
      this.fromDateModal = false;
    },
    selectedToDate() {
      this.editedItem.dueDateTimestamp = this.toDate;
      this.toDateModal = false;
      if (_.isEmpty(this.fromDate) && _.isEmpty(this.toDate)) {
        console.log();
      }
    },
    close() {
      this.dialog = false;
      if(this.isPasteAssignment){
        this.isPasteAssignment = false;
      }
      this.$store.commit("updateCurrentScreenName", "StudentDetailPageScreen");
    },
    getStartTimeStamp(date) {
      let localDate = new Date(date); // current local date and time
      return Math.floor(localDate.getTime() / 1000);
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let assignedTo = {};
        if (this.assignmentType == "batch") {
          assignedTo = {
            assignmentType: this.assignmentType,
            batchId: this.batchId,
            allStudents: true,
            studentIds: [],
          };
        } else {
          assignedTo = {
            assignmentType: this.assignmentType,
            batchId: "",
            allStudents: false,
            studentIds: [this.studentId],
          };
        }
        const mathSubjectId = _.find(this.subjectList, {subjectType: 'math'})?.subjectId;
        const englishSubjectId = _.find(this.subjectList, {subjectType: 'readingandwriting'})?.subjectId;
        const assignmentPractice = [];
        if(this.editedItem.details.practice){
          _.forEach(this.editedItem.details.practice.englishTopics, topic => {
            if(this.editedItem.subType == 'subtopic'){
              _.forEach(this.editedItem.details.practice.englishSubTopicsQuestionCount[topic], (subTopic, key) => {
                assignmentPractice.push({
                  topicId: topic,
                  subjectId: englishSubjectId,
                  subTopicId: key,
                  ...subTopic
                })
              });
            }else{
              assignmentPractice.push({
                topicId: topic,
                subjectId: englishSubjectId,
                ...this.editedItem.details.practice.englishTopicsQuestionCount[[topic]]
              })
            }
          })
          _.forEach(this.editedItem.details.practice.mathTopics, topic => {
            if(this.editedItem.subType == 'subtopic'){
              _.forEach(this.editedItem.details.practice.mathSubTopicsQuestionCount[topic], (subTopic, key) => {
                assignmentPractice.push({
                  topicId: topic,
                  subjectId: mathSubjectId,
                  subTopicId: key,
                  ...subTopic
                })
              });
            }else{
              assignmentPractice.push({
                topicId: topic,
                subjectId: mathSubjectId,
                ...this.editedItem.details.practice.mathTopicsQuestionCount[[topic]]
              })
            }
          })
        }
        
        let reqData = {
          title: this.editedItem.title,
          type: this.editedItem.type,
          startDateTimestamp: Math.floor(
            moment(this.getDateToString(this.editedItem.startDateTimestamp))
              .startOf("d")
              .valueOf() / 1000
          ),
          dueDateTimestamp: Math.floor(
            moment(this.getDateToString(this.editedItem.dueDateTimestamp))
              .endOf("d")
              .valueOf() / 1000
          ),
          startDate: this.getDateToString(
            this.editedItem.startDateTimestamp
          ),
          dueDate: this.getDateToString(this.editedItem.dueDateTimestamp),
          details: {
            ...this.editedItem.details,
            practice: this.editedItem.details.practice
              ? assignmentPractice
              : null,
          },
          assignedTo: assignedTo,
          currentDate: moment().format("YYYY-MM-DD"),
          subType: this.editedItem.type == 'practice'? this.editedItem.subType : '',
          showAssignmentSolution: this.editedItem.showAssignmentSolution
        };

        if (this.editedIndex > -1) {
          reqData.assignmentId = this.editedItem.assignmentId;
          const editData = _.omit(reqData, ["assignedTo", "details", "type"]);
          recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabAssignmentEdited, {
            courseId: this.courseId,
            fromClass : this.fromClass,
            batchId: this.pinpointBatchId,
            studentId: this.$route.params.studentId?? "",
            assignmentId: this.editedItem.assignmentId,
          });
          this.$store.dispatch("updateTeacherAssignment", editData).then((res)=>{
            if(res){
              this.close();
            }
          });
        } else {
          recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabAssignmentCreated, {
            courseId: this.courseId,
            fromClass : this.fromClass,
            batchId: this.pinpointBatchId,
            studentId: this.$route.params.studentId?? "",
            isCopied: this.isPasteAssignment?? false,
          });
          this.$store.dispatch("addTeacherAssignment", reqData).then((res)=>{
            if(res){
              if(this.isPasteAssignment){
                this.$store.commit("copyAssignment", {});
              }
              this.close();
            }
          });
        }        
      }
    },
    async filterCurrentAssignments(current) {
      this.current = current;
      const reqData = {
        currentDate: moment().format("YYYY-MM-DD"),
        batchId: this.batchId,
        studentId: this.studentId,
        isCurrent: current
      };
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabTimeButtonSwitched, {
        courseId: this.courseId,
        fromClass : this.fromClass,
        batchId: this.pinpointBatchId,
        studentId: this.$route.params.studentId?? "",
        isCurrent: current,
      });
      
      this.$store.commit("clearTeacherAssignments");
      this.$store.dispatch("loadTeacherAssignments", reqData);
    },
    async isDisabledQuestionBtn() {
      const { valid } = await this.$refs.form.validate();
            return !valid;
    },
    async openQuestionsPage() {
      const data = _.cloneDeep(this.dummyQuestions);
      const { valid } = await this.$refs.form.validate();
      if(valid){
        const mathSubjectId = _.find(this.subjectList, {subjectType: 'math'})?.subjectId;
        const englishSubjectId = _.find(this.subjectList, {subjectType: 'readingandwriting'})?.subjectId;
        const assignmentPractice = [];
        if(this.editedItem.details.practice){
          _.forEach(this.editedItem.details.practice.englishTopics, topic => {
            if(this.editedItem.subType == 'subtopic'){
              _.forEach(this.editedItem.details.practice.englishSubTopicsQuestionCount[topic], (subTopic, key) => {
                assignmentPractice.push({
                  topicId: topic,
                  subjectId: englishSubjectId,
                  subTopicId: key,
                  ...subTopic
                })
              });
            }else{
              assignmentPractice.push({
                topicId: topic,
                subjectId: englishSubjectId,
                ...this.editedItem.details.practice.englishTopicsQuestionCount[[topic]]
              })
            }
          })
          _.forEach(this.editedItem.details.practice.mathTopics, topic => {
            if(this.editedItem.subType == 'subtopic'){
              _.forEach(this.editedItem.details.practice.mathSubTopicsQuestionCount[topic], (subTopic, key) => {
                assignmentPractice.push({
                  topicId: topic,
                  subjectId: mathSubjectId,
                  subTopicId: key,
                  ...subTopic
                })
              });
            }else{
              assignmentPractice.push({
                topicId: topic,
                subjectId: mathSubjectId,
                ...this.editedItem.details.practice.mathTopicsQuestionCount[[topic]]
              })
            }
          })
        }
        const reqData = {
          assignmentId: this.editedItem.assignmentId ?? "",
          details:{
            practice: assignmentPractice
          },
          title: this.editedItem.title,
          testId: this.editedItem.details.practiceTestId ?? "",
          subType: this.editedItem.type == 'practice'? this.editedItem.subType : ''
        };
        recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabViewQuestionsButtonClicked, {
          courseId: this.courseId,
          fromClass : this.fromClass,
          batchId: this.pinpointBatchId,
          studentId: this.$route.params.studentId?? "",
        });
        const response = await this.$store.dispatch(
          "practiceQuestionsAssignment",
          reqData
        );
        if (!_.isEmpty(response) && "practiceTestQuestionSummary" in response) {
          this.editedItem.details.practiceTestId = response.practiceTestId;
          this.viewQuestionsData.testInstanceQuestionSummary =
            response.practiceTestQuestionSummary;
          this.viewQuestionsData.testInstanceQuestionSummary.testName =
            this.editedItem.title;
          this.dialog = false;
          this.dialogViewQuestions = true;
        }
      }
    },
    closeQuestionsPage() {
      this.dialogViewQuestions = false;
      this.dialog = true;
    },
    changeMathTopic(item){
      this.$refs.englishTopic.validate();
      // For Topic Type
      const mathTopics = {};
      _.forEach(item, topic =>{
          mathTopics[[topic]] =
        _.isEmpty(this.editedItem.details.practice.mathTopicsQuestionCount[[topic]])?
        {
          easyQuestionCount: '3',
          mediumQuestionCount: '3',
          hardQuestionCount: '3',
        }: this.editedItem.details.practice.mathTopicsQuestionCount[[topic]];
      })
      this.editedItem.details.practice.mathTopicsQuestionCount = mathTopics;

      // For Sub Topic Type
      const mathSubTopics = {};
        _.forEach(item, topic =>{
        const subTopics = _.find(this.mathTopicList, {entityId: topic})?.subTopics??[];
        if(_.isEmpty(this.editedItem.details.practice.mathSubTopicsQuestionCount[[topic]])){
          mathSubTopics[[topic]] = {}
          _.forEach(subTopics, subTopic =>{
            mathSubTopics[[topic]][[subTopic.conceptKey]]={
              easyQuestionCount: '0',
              mediumQuestionCount: '0',
              hardQuestionCount: '0',
            }

          });
        }else{
          mathSubTopics[[topic]] = this.editedItem.details.practice.mathSubTopicsQuestionCount[[topic]];
        }
      });
      
      this.editedItem.details.practice.mathSubTopicsQuestionCount= mathSubTopics;
      this.changePracticeAssignment();
    },
    changeEnglishTopic(item){
      this.$refs.mathTopic.validate();
      // For Topic Type
      const englishTopics = {};
      _.forEach(item, topic =>{
        englishTopics[[topic]] =
        _.isEmpty(this.editedItem.details.practice.englishTopicsQuestionCount[[topic]])?
        {
          easyQuestionCount: 3,
          mediumQuestionCount: 3,
          hardQuestionCount: 3,
        }: this.editedItem.details.practice.englishTopicsQuestionCount[[topic]];
      })
      this.editedItem.details.practice.englishTopicsQuestionCount= englishTopics;

      // For Sub Topic Type
      const englishSubTopics = {};
        _.forEach(item, topic =>{
        const subTopics = _.find(this.englishTopicList, {entityId: topic})?.subTopics??[];
        if(_.isEmpty(this.editedItem.details.practice.englishSubTopicsQuestionCount[[topic]])){
          englishSubTopics[[topic]] = {}
          _.forEach(subTopics, subTopic =>{
            englishSubTopics[[topic]][[subTopic.conceptKey]]={
              easyQuestionCount: '0',
              mediumQuestionCount: '0',
              hardQuestionCount: '0',
            }

          });
        }else{
          englishSubTopics[[topic]] = this.editedItem.details.practice.englishSubTopicsQuestionCount[[topic]];
        }
      });
      
      this.editedItem.details.practice.englishSubTopicsQuestionCount= englishSubTopics;
      this.changePracticeAssignment();
    },
    changePracticeAssignment() {
      console.log("changePracticeAssignment", this.editedItem);
      this.editedItem.details.practiceTestId = "";
    },
    openPracticeTestReport(item) {
      this.$router.push(`${this.$route.fullPath}/digisatresult/${item.testId}`);
    },
    openPracticeReport(item) {
      this.$router.push(
        `/testresult/${item.testId}?instance=${item.testInstanceId}&report=${false}&quiz=${false}&teacher=${true}`
      );
    },
    getDateToString(time) {
      let d = new Date(time * 1000);
      return moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("YYYY-MM-DD");
    },
    getMathTopicName(id){
      console.log("getMathTopicName",  id)
      const topic = _.find(this.mathTopicList, {entityId: id});
      return topic.entityLabel??'';
    },
    getEnglishTopicName(id){
      console.log("getEnglishTopicName",id)
      const topic = _.find(this.englishTopicList, {entityId: id});
      return topic.entityLabel??'';
    },
    getSubTopicName(id){
      const subTopic = _.find(this.subTopicList, {conceptKey: id});
      if(subTopic){
        return  subTopic.label;
      }
      return '';
    },
    getEasyCount(id){
      const subTopic = _.find(this.subTopicList, {conceptKey: id});
      if(subTopic){
        return subTopic.easyQuestionCount??0
      }
      return 0;
    },
    getMediumCount(id){
      const subTopic = _.find(this.subTopicList, {conceptKey: id});
      if(subTopic){
        return subTopic.mediumQuestionCount??0
      }
      return 0;
    },
    getHardCount(id){
      const subTopic = _.find(this.subTopicList, {conceptKey: id});
      if(subTopic){
        return subTopic.hardQuestionCount??0
      }
      return 0;
    },
    copyAssignmentAction(item){
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabAssignmentCopied, {
        courseId: this.courseId,
        fromClass : this.fromClass,
        batchId: this.pinpointBatchId,
        studentId: this.$route.params.studentId?? "",
      });
      const assignment = _.cloneDeep(item);
      this.copyAssignmentName = item.title;
      this.showCopyMessage = true; 
      this.$store.commit("copyAssignment", assignment);
      this.$eventBus.emit('assignmentEvent', {} );
    },
    closeSnackBar(){
      this.showCopyMessage = false; 
      this.copyAssignmentName = "";
    },
    checkIfCanAssignmentPasteHere(){
      if(_.isEmpty(this.copyAssignment)){
                return false;
      }else{
        // console.log("checkIfCanAssignmentPasteHere", this.copyAssignment);
        if(this.assignmentType == "batch"){
          return this.copyAssignment.assignedTo.batchId!=this.batchId;
        }else{
          return !this.copyAssignment.assignedTo.studentIds?.includes(this.studentId);
        }
      }
    },
    pasteAssignment(){
      // console.log("pasteAssignment");
      this.editedItem = _.cloneDeep(this.copyAssignment);
      this.editedItem = _.omit(this.editedItem, ['assignmentId', 'assignedTo']);
      this.editedItem.startDateTimestamp = this.editedItem.startDateTimeStamp;
      this.editedItem.dueDateTimestamp = this.editedItem.dueDateTimeStamp;
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenAssignmentTabAssignmentPasted, {
        courseId: this.courseId,
        fromClass : this.fromClass,
        batchId: this.pinpointBatchId,
        studentId: this.$route.params.studentId?? "",
      });
      if (this.copyAssignment.details?.practice && this.copyAssignment.details?.practice.length > 0) {
        this.editedItem.subType = this.editedItem.subType || (this.editedItem.subType && this.editedItem.subType!= '')|| 'topic';
        const assignmentPractice = {
          mathTopics: [],
          mathTopicsQuestionCount:{},
          englishTopics: [],
          englishTopicsQuestionCount:{},
          englishSubTopicsQuestionCount:{},
          mathSubTopicsQuestionCount:{},
        }
        _.forEach(this.copyAssignment.details.practice, practice=>{
          const subject = _.find(this.subjectList, {subjectId: practice.subjectId})
          if(subject.subjectType == 'readingandwriting'){
            if(this.editedItem.subType == 'subtopic'){
              if(_.isEmpty(assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]])){
                assignmentPractice.englishTopics.push(practice.topicId);
                assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]]={};
              }
              assignmentPractice.englishSubTopicsQuestionCount[[practice.topicId]][[practice.subTopicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              }
            }else{
              assignmentPractice.englishTopics.push(practice.topicId);
              assignmentPractice.englishTopicsQuestionCount[[practice.topicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              };
            }
          }else{
            if(this.editedItem.subType == 'subtopic'){
              if(_.isEmpty(assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]])){
                assignmentPractice.mathTopics.push(practice.topicId);
                assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]]={};
              }
              assignmentPractice.mathSubTopicsQuestionCount[[practice.topicId]][[practice.subTopicId]]= {
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              }
            }else{
              assignmentPractice.mathTopics.push(practice.topicId);
              assignmentPractice.mathTopicsQuestionCount[[practice.topicId]]={
                easyQuestionCount: practice.easyQuestionCount + "",
                mediumQuestionCount: practice.mediumQuestionCount + "",
                hardQuestionCount: practice.hardQuestionCount + "",
              };
            }
          }
        })
        this.editedItem.details.practice = assignmentPractice;
      }
      if(this.editedItem.type=='practicewithtag'){
        if(!_.isEmpty(this.editedItem.details.tagList)){
          this.practiceWithTagType = "tag";
        }else{
          this.practiceWithTagType = "test";
        }
      }
      // console.log("editItem-1", this.editedItem);
      // console.log(item.dueDateTimestamp, this.editedItem.dueDateTimestamp);
      this.editedItem.showAssignmentSolution = _.get(this.editedItem, 'showAssignmentSolution', true);
      this.isPasteAssignment = true;
      this.dialog = true;
    },
    checkQuestionLimit(count){
      return [ ...this.requiredRule, v => (Number(v) >= 0) || 'Invalid', (v)=> (v<=count|| 'Not Available')];
    },
    changeSubType(){
      if(this.editedIndex==-1){
        this.editedItem.details.practiceTestId="";
      }
    },
    checkAssignmentActionsAllowed(){
     return this.studentAssignmentActionsAllowed
    }
  },
  mounted() {
    this.$store.commit("updateCurrentScreenName", 'StudentDetailPageScreen');
    this.$eventBus.on('pasteAssignment', () => {
      // console.log("pasteAssignment Event");
    this.pasteAssignment();
    })
  },
  unmounted() {
    this.$eventBus.emit('assignmentEvent', {} );
    this.$eventBus.off('pasteAssignment', () => {});
    this.$store.commit('updateCurrentScreenName', null);
  },
  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>

<style scoped>
.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.assignment-card {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px !important;
}

.assignment-form-main{
  overflow: auto !important;
}

.assignment-btn {
  border-radius: 12px !important;
  /* border: 2px solid rgb(var(--v-theme-primaryGrey)); */
  background: rgb(var(--v-theme-report-subCardBg));
  /* color: rgb(var(--v-theme-primaryGrey)); */
  /* background: transparent; */
  /* padding: 10px 15px !important; */
  cursor: pointer;
}

.pointer-cursor {
  cursor: pointer;
}

.tab {
  border-radius: 12px !important;
  background: rgb(var(--v-theme-report-subCardBg));
}

.assignment-activity-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
}

.create-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 12px;
  width: 150px;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.list-item {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  /* background: rgb(var(--v-theme-cardBackgroundColor)); */
  /* background-color: rgb(var(--v-theme-reportBgColor)); */
  background-color: rgb(var(--v-theme-report-subCardBg));
  padding: 5px 8px;
}

.list-item:not(:last-child) {
  margin-bottom: 5px !important;
}

.assignment-form {
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.22);
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.btn-border{
  border-radius: 29.4079px !important;
}

.border-color {
  border: 2px solid rgb(var(--v-theme-primaryGrey));
}

.delete-btn {
  border: 2px solid red !important;
}

.report-btn {
  background: rgb(var(--v-theme-practice-prompts-background)) !important;
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  letter-spacing: -0.15px;
}

.filter-selected-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .activity-btn-mobile {
  width: 40px;
  height: 30px;
} */

.topic-questions-block{
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.22);
  position: relative;
}

.label-name{
  position: absolute;
  top: -14px;
  background-color: rgb(var(--v-theme-reportBgColor));
  padding: 0 6px;

}

.field-block{
  border-radius: 4px !important;
  border: 1px solid rgb(118,118,118);
  position: relative;
}

.field-label-name{
  font-size: 0.75em !important;
  position: absolute;
  padding: 0 6px;
  transform: translateY(-50%);
  transform-origin: center;
  background-color: rgb(var(--v-theme-reportBgColor));
}

.sub-topic-block:last-child{
  margin-bottom: -12px !important;
}

.hideButtons{
  visibility: hidden !important;
}

</style>

<style>
.question-count-input > .v-input__control > .v-field > .v-field__field >  .v-field__input{
  height: 30px !important;
  min-height: 30px !important;
}

.question-count-input > .v-input__details{
  padding-top: 4px !important;
  min-height: auto !important;
}
.mentoBadge .v-badge__badge {
    color: #FEFEFE;
    font-family: Prompt;
    font-size: 10px !important;
}
</style>
