<template>
  <div ref="pdfjsexpressviewer">
  </div>
</template>

<script>
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { getPDFJSExpViewerLicenseKey } from '@/flavors';

export default {
  name: 'PDFJSExpressViewer',
  components: {
  },
  props: {
    fileUrl: String
  },
  data: () => ({

  }),
  computed: {
    getPDfFileURL: {
      get() {
        return this.fileUrl;
      }
    }
  },
  mounted: function () {
    this.$store.commit ('increamentLoader');
    WebViewer(
      {
        path: `${process.env.BASE_URL}lib/pdfjsexp`,
        licenseKey: getPDFJSExpViewerLicenseKey(),
        disabledElements: [
          'contextMenuPopup',
          'selectToolButton',
          'searchButton',
          'languageButton',
          'selectToolButton',
        ]
      },
      this.$refs.pdfjsexpressviewer
    ).then((instance) => {
      this.clearGlobalScopedVaribalesForPDFJSExpress();
      this.disablePDFJSFeatures(instance);
      this.webViewerInstance = instance;
      let data = null;
      fetch(this.getPDfFileURL, {
        mode: "cors",
        // credentials: 'include'
      })
        .then(async response => {
          if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            console.log(JSON.stringify(error));
            return Promise.reject(error);
          }
          data = await response.blob();
          instance.loadDocument(data);
        })
        .catch(error => {
          this.errorMessage = error;
          console.log("There was an error!", error);
        });

      const { Core, UI } = instance;

      /*UI.addEventListener(UI.Events.DOCUMENT_LOADED, e => {
        console.log(`UI event fired: ${JSON.stringify(e)}`);
        console.log("UI.UIEvents.DOCUMENT_LOADED | document loaded");
        //instance.closeElements(['loadingModal']);
      });
      UI.addEventListener(UI.Events.VIEWER_LOADED, e => {
        //const { types, authors, colors } = e.detail;
        //console.log(types, authors, colors);
        console.log(`UI event fired: ${JSON.stringify(e)}`);
        console.log("UI.UIEvents.VIEWER_LOADED | document loaded");

      });
      // adding an event listener for when a document is loaded
      Core.documentViewer.addEventListener("documentLoaded", () => {

        console.log("document loaded");

      });

      // adding an event listener for when the page number has changed
      Core.documentViewer.addEventListener(
        "pageNumberUpdated",
        (pageNumber) => {
          console.log(`Page number is: ${pageNumber}`);
        }
      );*/
      Core.documentViewer.addEventListener("finishedRendering", () => {
        //console.log("finishedRendering fired...");
        this.$store.commit ('decreamentLoader');
      });
      // adds a button to the header that on click sets the page to the next page
      UI.setHeaderItems((header) => {
        header.push({
          type: "actionButton",
          img: "https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg",
          onClick: () => {
            const currentPage = Core.documentViewer.getCurrentPage();
            const totalPages = Core.documentViewer.getPageCount();
            const atLastPage = currentPage === totalPages;
            if (atLastPage) {
              Core.documentViewer.setCurrentPage(1);
            } else {
              Core.documentViewer.setCurrentPage(currentPage + 1);
            }
          },
        });
      });
    });
  },
  methods: {
    clearGlobalScopedVaribalesForPDFJSExpress() {
      window.WebViewer = null;
      window.WebViewerInstance = null;
      window.Core = null;
      window.UI = null;
      window.DocumentViewer = null;
      window.AnnotationManager = null;
      window.Document = null;
      window.Annotations = null;
      window.Tools = null;
      window.PDFNet = null;
      //console.log(`nullifying global scope variables`)
    },
    disablePDFJSFeatures(pdfJSInstance) {
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Print]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Download]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Ribbons]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Annotations]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.FilePicker]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.LocalStorage]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.NotesPanel]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Redaction]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.TextSelection]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.TouchScrollLock]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.Copy]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.MultipleViewerMerging]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.ThumbnailMerging]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.MathSymbols]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.OutlineEditing]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.NotesPanelVirtualizedList]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.NotesShowLastUpdatedDate]);
      pdfJSInstance.UI.disableFeatures([pdfJSInstance.UI.Feature.MultiTab]);
    },
  }
}
</script>
<style scoped>
#pageContainer {
  position: absolute;
  margin: auto;
  height: "560px";
  width: "800px";
  overflow: scroll;
}

div {
  width: 100%;
  height: 100vh;
}
</style>