<template>
    <v-dialog v-model="showInfoTab" transition="dialog-top-transition" width="1024">
      <template v-slot:activator="{ props }">
        <v-btn 
          icon="mdi-information-variant" 
          class="info-icon t-btn text-none rounded-info-btn" 
          density="compact" 
          variant="flat"
          color="cardBackgroundColor"
          @click="showInfoTab= true"
          v-bind="props"
        >
        </v-btn>
      </template>
      
      
        <v-card style="max-width: 700px" class="info-card text-white py-2 align-self-center"  >

          <v-card-actions class="justify-end py-0">
            <v-btn 
              icon="mdi-close" 
              class="t-btn text-none rounded-info-btn mx-4" 
              density="compact" 
              variant="outlined"
              color="white"
              @click="showInfoTab = false"
            >
            </v-btn>
          </v-card-actions>
          
          <v-card-text class="pt-0 px-3">
            <v-container class="px-0">
              <v-row>
                <!-- Left Side -->
                <v-col v-if="smAndUp" cols="auto" class="align-self-stretch d-flex flex-column " >
                  
                      <v-avatar class="ma-0" size="86px">
                        <v-img src="@/assets/info_mento_excited.png"></v-img>
                      </v-avatar>
                    

                </v-col>
                
                <v-col class="align-self-stretch d-flex flex-column pt-0 pr-4" >
                  <v-row>                    
                    <v-col cols="12" class="mt-0">
                      <p class="t-h5 text-center text-sm-left">
                        {{infoTopic}}
                      </p>
                    </v-col>
                    
                    <v-col cols="12" class="mt-0">
                      <div class="t-h6Reg info-content text-center text-sm-left" v-html="infoDesc"></div>
                    </v-col>

                  </v-row>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          

        </v-card>

    </v-dialog>
</template>

<script>
import { useDisplay } from 'vuetify';

export default {
    components: {
    },
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

        return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
    },
    props: {
        infoTopic: String,
        infoDesc: String,
    },
    data: () => ({
        showInfoTab : false,
    }),

}
</script>

<style scoped>

.rounded-info-btn{
  border-radius: 50% !important;
}

.info-card{
  background-color: #26253A;
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 15px !important;
}

.info-content{
  line-height: 1.375rem !important;
  font-size: 1rem !important;
}

.v-overlay.v-overlay--active{
  background: rgba(0, 0, 0, 0.75) !important;
  backdrop-filter: blur(12.5px) !important;
}
</style>