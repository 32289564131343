<template>
  <v-layout class="overflow-visible" style="height: 56px;">
    <v-bottom-navigation active>
      <v-btn @click="nextPage()"><v-icon icon="mdi-arrow-up"></v-icon></v-btn>
      {{ pageNum }} / {{ numPages ? numPages : '∞' }}
      <v-btn @click="prevPage()"><v-icon icon="mdi-arrow-down"></v-icon></v-btn>
    </v-bottom-navigation>
  </v-layout>
  <div>
    <div ref="pageContainer" id="pageContainer" height="460" width="800">
      <pdf :src="getPdfFileSrc" :page="pageNum" style="width:100%;margin:20px auto;" @numpages="(num) => numPages = num">
        <template v-slot:loading>
          loading content here...
        </template>
      </pdf>
    </div>
  </div>
</template>

<script>
import pdf from 'pdfvuer'
import { mapState } from "vuex";

export default {
  components: {
    pdf
  },
  props: {
    fileUrl: String
  },
  data: () => ({
    pageNum: 1,
    numPages: 0,
  }),
  computed: {
    ...mapState(["app_s3privateUrl"]),
    getPdfFileSrc: {
      get() {
        let pdfFile = this.app_s3privateUrl+'app-pdfs/2023-02-09/1675958090071_OSENDSEM21928168.pdf';
        return {
          url: pdfFile,
          withCredentials: true,
        };
      }
    }
  },
  methods: {
    nextPage() {
      this.pageNum > 1 ? this.pageNum-- : 1;
    },
    prevPage() {
      this.pageNum < this.numPages ? this.pageNum++ : 1;
    },
  }
}
</script>
<style scoped>
#pageContainer {
  position: absolute;
  margin: auto;
  height: "560px";
  width: "800px";
  overflow: scroll;
}

div {
  width: 50%;
  height: 460px;
  /*79vh;*/
  min-width: 700px;
  /*400px;*/
}
</style>