<template>
  <v-row class="ma-0 pt-4 px-4 heightBread" align="center">
    <v-spacer></v-spacer>
    <v-col cols="auto" class="ma-0 pa-0">
      <SelfHelpGuideTogglerComponent />
    </v-col>
  </v-row>

  <v-container fluid class="ma-0 pa-4 main-lg">

    <v-container fluid class="container-width" v-if="studentsDetailLoaded">
      <v-row class="mb-6 mx-0">
        <v-col cols="12" class="pa-0">
          <v-card class="card-bg text-white" id="testingId" ref="StudentsListPage--searchBar">
            <v-card-text>
              <v-row align="center" justify="space-around">
                <v-col cols="auto" class="heading" >
                  Students
                  <span v-if="studentList.length > 0">
                    ({{ students.length }})</span
                  >
                </v-col>
                <v-divider vertical class="mx-3"></v-divider>
                <v-col class="pa-1 pr-3">
                  <v-text-field
                    density="compact"
                    variant="solo"
                    flat
                    label="Search by Name / Contact Details"
                    append-inner-icon="mdi-magnify"
                    class="search-underline"
                    bg-color="cardBackgroundColor2"
                    hide-details="true"
                    v-model="searchFilter"
                    v-on:input="debounceSearch"
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="pa-0 mx-4">
                  <div class="filter-dropdown">
                    <v-select
                      class="filter-box"
                      density="compact"
                      :items="sortList"
                      v-model="sortCategory"
                      label="Sort by"
                      item-title="sortName"
                      item-value="sortId"
                      variant="outlined"
                      hide-details
                      prepend-inner-icon="mdi-sort"
                    >
                    </v-select>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="paginatedStudentsList.length > 0">
        <template v-for="(item, i) in paginatedStudentsList" :key="i">
          <v-card
            class="card-bg card-border py-2 px-2 mb-3"
            @click="openStudentDetail(item)"
            :ref="i == 0 ? `StudentsListPage__studentsList--card${i}` : ''"
          >
            <v-card-text class="text-primaryTextColor pa-0">
              <v-row justify="space-between" align="center" class="ma-0 px-1">
                <!-- name -->
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center justify-start pa-1"
                >
                  <div class="mr-4">
                    <v-avatar
                      size="34"
                      color="report-subCardBg"
                      class="icon-bg"
                    >
                      <v-icon
                        icon="mdi-account"
                        color="#ffffff"
                        class=""
                        size="20"
                      ></v-icon>
                    </v-avatar>
                  </div>
                  <div class="pb-0 wrap-text">
                    <span v-if="item.studentName != 'null'" class="t-1remReg">{{
                      item.studentName
                    }}</span>
                    <span v-else class="t-1remReg"> - </span>
                  </div>
                </v-col>

                <!-- contact -->
                <v-col cols="4" class="d-flex flex-column align-start pa-1">
                  <div
                    v-if="showPhoneNumber(item.phoneNumber)"
                    class="pb-0 wrap-text"
                  >
                    <span class="t-h6Reg">{{ item.phoneNumber }}</span>
                  </div>
                  <div v-if="showEmail(item.email)" class="wrap-text">
                    <span class="t-h6Reg">{{ item.email }}</span>
                  </div>
                </v-col>

                <!-- class -->
                <v-col cols="2" class="d-flex flex-column align-start pa-1">
                  <v-tooltip location="top" max-width="400px">
                    <template #activator="{ props }">
                      <div class="pb-0" v-bind="props">
                        <span class="t-h6Reg text-capitalize">{{
                          getAssignedTo(item)
                        }}</span>
                      </div>
                      <div class="">
                        <span class="t-h6Reg parameter-style">Assigned To</span>
                      </div>
                    </template>
                    <span>{{ getAssignedToComplete(item) }}</span>
                  </v-tooltip>
                </v-col>

                <!-- last online -->
                <v-col cols="2" class="d-flex flex-column align-start pa-1">
                  <div class="pb-0">
                    <span class="t-h6Reg">{{
                      getTime(item.lastLoginTimeStamp)
                    }}</span>
                  </div>
                  <div class="">
                    <span class="t-para parameter-style">Last Online</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </template>

      <v-card
        v-if="students.length <= 0"
        class="card-bg card-border px-2 mb-3 py-2"
        elevation="0"
        ref="StudentsListPage__studentsList--card0"
      >
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <v-col
              cols="12"
              class="d-flex pa-3 align-center justify-center pa-1"
            >
              <div class="pb-0 wrap-text">
                <span class="t-h6Reg">No Student Assigned</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row v-if="totalPages > 1" class="ma-0" justify="center">
        <v-col cols="auto" class="px-0">
          <v-pagination
            v-model="currentPageNumber"
            :length="totalPages"
            total-visible="5"
            size="small"
            color="white"
            rounded="lg"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";

export default {
  name: "StudentsListPage",
  components: { SelfHelpGuideTogglerComponent },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  async created() {
    if (!this.isUserTeacher) {
      if (this.isUserAdmin) {
        this.$router.replace("/admin/1");
      } else {
        this.$router.replace("/learn");
      }
    }
    // this.$store.commit("updateCurrentScreenName", this.currentScreenName);
    this.$store.commit("updateStudentName", "");
    this.$store.dispatch("loadStudentList", {}).then((res) => {
      this.studentsDetailLoaded = true;
    });
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenOpen, {
      courseId: this.courseId,
      fromClass: false,
      batchId: "",
    });
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: 'StudentsListPageScreen',
    studentsDetailLoaded: false,
    options: "",
    searchFilter: "",
    sortCategory: null,
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
      { sortName: "Email (A to Z)", sortId: "emailAsc" },
      { sortName: "Email (Z to A)", sortId: "emailDesc" },
      { sortName: "Last Online (Recent)", sortId: "onlineRecent" },
      { sortName: "Last Online (Oldest)", sortId: "onlineOldest" },
    ],
    currentPageNumber: 1,
    itemsPerPage: 100
  }),
  methods: {
    debounceSearch() {
      if (this.searchFilter.trim() == "") {
        console.log("empty");
        this.$store.commit("clearSearchedStudentList");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        if (this.searchFilter.trim() != "") {
          recordAnalyticsEvent(
            AnalyticsEvents.v2TeacherViewStudentScreenStudentSearched,
            {
              courseId: this.courseId,
              searchedKeyword: this.searchFilter.trim(),
              fromClass: false,
              batchId: "",
            }
          );
          this.$store.dispatch("loadSearchedStudentList", {
            search: this.searchFilter.trim(),
          });
        }
      }, 700); // delay
    },
    getTime(time) {
      if (time == 0) {
        return "-";
      }
      let vardata = moment(time).fromNow();
      return vardata;
    },
    openStudentDetail(item) {
      let studentId = item.userId;
      this.$store.commit("updateStudentId", studentId);
      this.$store.commit("updateStudentName", item.studentName);
      recordAnalyticsEvent(
        AnalyticsEvents.v2TeacherViewStudentScreenStudentOpen,
        {
          courseId: this.courseId,
          studentId: studentId,
          fromClass: false,
          batchId: "",
        }
      );
      this.$router.push(`${this.$route.fullPath}/${studentId}/1`);
    },
    showPhoneNumber(item) {
      let phoneNumber = item + "";
      return !phoneNumber.includes("null");
    },
    showEmail(item) {
      let email = item + "";
      return !email.includes("null");
    },
    getAssignedTo(item) {
      let initialName = "",
        count = 0;
      if (!_.isEmpty(item.assignedToTutor) && item.assignedToTutor.length > 0) {
        initialName = item.assignedToTutor[0].tutorName;
        count =
          item.assignedToBatches?.length + item.assignedToTutor?.length - 1;
        if (count > 0) return `${initialName}, + ${count}`;
        else return `${initialName}`;
      } else if (
        !_.isEmpty(item.assignedToBatches) &&
        item.assignedToBatches.length > 0
      ) {
        initialName = item.assignedToBatches[0].batchName;
        count =
          item.assignedToBatches?.length + item.assignedToTutor?.length - 1;
        if (count > 0) return `${initialName}, + ${count}`;
        else return `${initialName}`;
      } else {
        return "-";
      }
    },
    getAssignedToComplete(item) {
      let namesList1 = [],
        namesList2 = [];
      if (
        (item.assignedToBatches == null ||
          item.assignedToBatches.length == 0) &&
        (item.assignedToTutor == null || item.assignedToTutor.length == 0)
      ) {
        return "Student is not assigned to any tutor or class";
      }
      if (item.assignedToTutor != null && item.assignedToTutor.length > 0) {
        namesList1 = item.assignedToTutor.map((e) => e.tutorName);
      }
      if (item.assignedToBatches != null && item.assignedToBatches.length > 0) {
        namesList2 = item.assignedToBatches.map((e) => e.batchName);
      }
      let namesList = [...namesList1, ...namesList2];
      return namesList.join(", ");
    },
    paginatedData(students) {
      const start = (this.currentPageNumber - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return students.slice(start, end);
    },
  },
  computed: {
    ...mapState([
      "studentList",
      "searchedStudentList",
      "isUserAdmin",
      "isUserTeacher",
      "courseId",
      "isUserParent",
      "showSelfHelpGuide"
    ]),
    ...mapGetters(['currentHighlightedElements']),
    students: {
      get() {
        let students = [];
        if (this.searchFilter.trim() != "") {
          students = this.searchedStudentList;
        } else {
          students = this.studentList;
        }
        switch (this.sortCategory) {
          case "nameAsc":
            students = _.orderBy(
              students,
              [(student) => student?.studentName?.toLowerCase()],
              "asc"
            );
            break;
          case "nameDesc":
            students = _.orderBy(
              students,
              [(student) => student?.studentName?.toLowerCase()],
              "desc"
            );
            break;
          case "emailAsc":
            students = _.orderBy(
              students,
              [(student) => student?.email?.toLowerCase()],
              "asc"
            );
            break;
          case "emailDesc":
            students = _.orderBy(
              students,
              [(student) => student?.email?.toLowerCase()],
              "desc"
            );
            break;
          case "onlineRecent":
            students = _.orderBy(
              students,
              [(student) => student?.lastLoginTimeStamp],
              "desc"
            );
            break;
          case "onlineOldest":
            students = _.orderBy(
              students,
              [(student) => student?.lastLoginTimeStamp],
              "asc"
            );
            break;

          default:
            students = _.orderBy(
              students,
              [(student) => student?.studentName?.toLowerCase()],
              "asc"
            );
        }
        return students;
      },
    },
    totalPages: {
      get() {
        return Math.ceil(this.students.length / this.itemsPerPage);
      },
    },
    paginatedStudentsList: {
      get() {
        return this.paginatedData(this.students);
      },
    },
    showSelfHelpGuide: {
      get(){
        return this.$store.state.showSelfHelpGuide;
      },
      set(value){
        this.$store.commit('toggleSelfHelpGuide', value);
      }
    }
  },
  watch: {
    options: {
      handler() {
        let itemsPerPage = this.options.itemsPerPage;
        let page = this.options.page;
        let max = itemsPerPage * page;
        let length = this.studentsData.length;
      },
      deep: true,
    },
    sortCategory: {
      handler() {
        this.currentPageNumber = 1;
      },
      deep: true,
    },
    students: {
      handler() {
        this.currentPageNumber = 1;
      },
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.container-width {
  max-width: 1080px;
}

/* .main-lg {
  padding-top: 100px !important;
} */

.card-bg {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.icon-bg {
  background: linear-gradient(90deg, #ab77ff 0%, #585ef3 100%);
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word;
  /* or word-break: break-all; */
}

.filter-dropdown {
  width: 150px !important;
}

.main-lg {
  padding-top: 32px !important;
}

.heightBread {
  height: 80px;
}
</style>
