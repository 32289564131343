<template>
  <v-container fluid v-if="studentsDetailLoaded == true" class="assignment-card pa-5 pb-2">

    <template v-if="studentsDetailLoaded == true">
      <template v-for="(item, i) in practiceTestReportDetails" :key="i">
  
        <v-card class="card-bg card-border mb-3 py-1"  @click="onViewSolution(item)" elevation="0" :ref="`studentTestAttempts__test--card${i}`">
          <v-card-text class="text-primaryTextColor pa-0">
            <v-row justify="space-between" align="center" class="ma-0 px-3">
  
              <!-- test name -->
              <v-col class="d-flex align-center pa-2" cols="5">
                <div class="mr-4">
                  <v-avatar size="36" color="#fff" class="icon-bg">
                    <v-icon icon="mdi-laptop" color="white" class="" size="18"></v-icon>
                  </v-avatar>
                </div>
  
                <div class="d-flex flex-column align-start">
                  <div class="pb-0">
                    <span class="t-1remReg">{{ item.testName }}</span>
                  </div>
                  <div class="d-flex align-center justify-start">
                    <v-icon icon="mdi-clock-time-three" color="iconGrey" size="14" class="mr-1"></v-icon>
                    <span class="t-h6Reg parameter-style">{{ getDateTimeFromTimeStamp(item.testAttemptedDate) }}</span>
                  </div>
                </div>
              </v-col>
  
              <!-- test score range -->
              <v-col class="d-flex flex-column align-center pa-2" cols="4">
                <div class="pb-0">
                  <span class="t-1remReg">{{ item.overAllScore.lowerScore }} - {{ item.overAllScore.upperScore }}</span>
                </div>
                <div class="d-flex align-center justify-space-between parameter-style">
                  <span class="t-h6Reg mr-2">RW {{ item.readingAndWritingScoreRange.lowerScore }} - {{ item.readingAndWritingScoreRange.upperScore }} </span>
  
                  <span class="t-h6Reg ml-2">Math {{ item.mathScoreRange.lowerScore }} - {{ item.mathScoreRange.upperScore }}</span>
                </div>
              </v-col>
  
              <!-- view result -->
              <v-col cols="3" class="d-flex align-center justify-end pa-2">
                <v-btn
                  prepend-icon="mdi-chart-pie"
                  class="t-btn text-white result-btn text-none px-3"
                  density="default"
                  variant="flat"
                >
                  <template v-slot:prepend>
                    <v-icon color="white"></v-icon>
                  </template>
                  View Result
                </v-btn>
              
              </v-col>
  
            </v-row>
  
          </v-card-text>
        </v-card>
      </template>
  
      <v-row
          v-if="practiceTestReportDetails?.length <= 0"
          class="card-bg card-border ma-0 mb-3 text-white align-center" ref="studentTestAttempts__test--card0"
        >
        <v-col cols="12" class="pa-3 text-center t-h6Reg">
          <span>No Practice Test Found</span>
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapGetters, mapState } from "vuex";
import moment from 'moment'
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
export default {
  name: 'PracticeTestReportComponent',
  props: ["studentId"],
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  created() {
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);
    if(this.$route.fullPath.startsWith('/parent')){
      this.isOpenedFromParentView = true;
    }
    this.studentsDetailLoaded= false;
    this.$store.dispatch('loadStudentPracticeTestReport', {studentId : this.studentId} )
    this.studentsDetailLoaded = true;
    if(this.isOpenedFromParentView){
      recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildTestAttemptsTabOpen, {
        courseId: this.courseId,
        childId: this.studentId,
      });
    }
    else{
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenTestAttemptsTabOpen, {
        courseId: this.courseId,
        studentId: this.studentId,
        fromClass: this.$route.params.batchId? true: false,
        batchId: this.$route.params.batchId? this.$route.params.batchId: "",
      });
    }
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit('updateCurrentScreenName', null);
  },
  data: () => ({
    studentsDetailLoaded: false,
    options: "",
    searchFilter: '',
    isOpenedFromParentView: false,
    currentScreenName: 'studentTestAttempts'
  }),
  computed: {
    ...mapState(["studentPracticeTestReport", "className", "courseId"]),
    ...mapGetters(['currentHighlightedElements']),
    practiceTestReportDetails: {
      get(){
        return this.studentPracticeTestReport.testDetails
      }
    }
  },
  methods: {
    debounceSearch() {
      if (this.searchFilter.trim() == "") {
        console.log("empty");
        this.$store.commit("clearSearchedMentoUserData");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        if (this.searchFilter.trim() != "") {
          console.log("searched is ", this.searchFilter);
        }
      }, 700); // delay
    },
    getTime(time) {
      let vardata = moment(time * 1000).fromNow()
      return vardata;
    },
    getDateTimeFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM, hh:mm A");
      return dateString
    },
    onViewSolution(item) {
      if(this.isOpenedFromParentView){
        recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildTestAttemptsTabReportOpen, {
          courseId: this.courseId,
          testId: item.testId,
          testInstanceId: item.testInstanceId,
          childId: this.studentId,
        });
      }
      else{
        recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenTestAttemptsTabReportOpen, {
          courseId: this.courseId,
          testId: item.testId,
          testInstanceId: item.testInstanceId,
          studentId: this.studentId,
          fromClass: this.$route.params.batchId? true: false,
          batchId: this.$route.params.batchId? this.$route.params.batchId: "",
        });
      }
      this.$router.push(`${this.$route.fullPath}/digisatresult/${item.testId}/${item.testInstanceId}`);
    },
  },
  watch: {
    options: {
      handler() {
        let itemsPerPage = this.options.itemsPerPage;
        let page = this.options.page;
        let max = itemsPerPage * page;
        let length = this.studentsData.length;
        console.log(length / max);
      },
      deep: true
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },

  }



}
</script>

<style scoped>
.assignment-card {
  border-radius: 12px !important;
}

.icon-bg{
	background: linear-gradient(90deg, #AB77FF 0%, #585EF3 100%);
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.card-bg {
  /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
  background-color: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;

}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  /* pointer-events: none; */
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

/* .check-report {
  padding: 8px 10px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 600px;
  pointer-events: auto;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.check-report:hover {
  cursor: pointer;
  border-color: rgb(var(--v-theme-primaryPurple));
  background: linear-gradient(93deg, #AB77FF 0.66%, #585EF3 99.02%);
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
} */

.result-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  border-radius: 12px;
  /* background: rgb(var(--v-theme-cardBackgroundColor)); */
  background-color: rgb(var(--v-theme-report-subCardBg));
}
</style>