// default config used to provide a base structure for
// environment and dynamic configs
const configDefault = {
    // quizAPI Config
    quizApi: {
        // Base url of gatweay (internal) apis - Like save history, get userid etc.
        baseUrl: "", // from DevTest account
        apiKey: ""
    }
};

export const config = configDefault;
