<template>
  <v-container class="bg-background ma-0 pa-4">
    <v-row class="ma-0 pa-0" align="center">
      <v-col class="ma-0 pa-0">
        <v-icon icon="mdi-laptop-account" color="primary" class="mr-2"></v-icon>
        <span>My Classes</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-text-field elevation="0" v-model="searchText" clearable clear-icon="$close" density="compact"
          variant="underlined" label="Search..." single-line hide-details append-inner-icon="mdi-magnify"
          @click:clear="clearSearch" v-on:input="debounceSearch" class="search-box"></v-text-field>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-container class="mt-4 ma-0 pa-0">
      <template v-for="(item, i) in classesList" :key="i">
        <v-hover v-slot="{ isHovering, props }">
          <v-card elevation="0" :class="{ 'on-hover': isHovering }" class="my-2 pa-0" v-bind="props">
            <v-container class="ma-0 px-4 py-2">
              <v-row class="ma-0 pa-0" justify="center">
                <v-col cols="1">
                  <v-avatar rounded="0" size="small">
                    <v-img v-if="item.classType == 'Online'" height="32" src="assets/images/live_v2.svg"></v-img>
                    <v-img v-if="item.classType == 'Offline'" height="32" src="assets/images/class_v2.svg"></v-img>
                    <v-img v-if="item.classType == 'Other'" height="32" src="assets/images/class_v2.svg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="11">
                  <v-row align="center">
                    <v-col cols="12" lg="10">
                      <v-row dense>
                        <v-col cols="12">
                          <span class="heading-block">
                            {{ item.title }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="date-block">
                            {{ item.meetingTimeString }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <span class="description-block">
                            {{ item.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-btn color="primary" v-if="item.classType == 'Online'" elevation="0"
                        @click="getUserInvite(item)">Join Class</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </template>
    </v-container>
  </v-container>
</template>

<script>
//import { mapState } from "vuex";
import moment from "moment-timezone";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";

export default {
  name: "MyClassesPage",

  data: () => ({
    searchText: '',
    filterSearchVal: '',
  }),
  components: {

  },
  async created() {
    const dateTime = new Date();
    const duration = -dateTime.getTimezoneOffset();
    const reqData = {
      offsetInMinutes: duration,
      appTimeZone: moment
        .tz(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        .zoneAbbr(),
    };
    console.log(reqData);
    this.$store.commit("classesListLoadedFromServer", false);
    this.$store.dispatch("loadClassesList", reqData);
    recordAnalyticsEvent(AnalyticsEvents.v2MyClassesPageOpen);
  },
  computed: {
    //...mapState(["learnHierarchyNodes", "learnHierarchyChildNodes", "userCurrentDomain"]),
    classesList: {
      get() {
        let classMeetings = this.$store.state.classesList;
        if (this.filterSearchVal) {
          classMeetings = classMeetings?.filter(
            item =>
              item.title
                ?.toLowerCase()
                .includes(this.filterSearchVal.toLowerCase()) ||
              item.description?.toLowerCase().includes(this.filterSearchVal.toLowerCase())
          );
        }
        return classMeetings;
      },
    },
  },
  mounted() { },
  watch: {
    userCurrentDomain() {
      //console.log(`watch LearnPage.vue | userCurrentDomain changed from ${oldVal} to ${newVal}`);
      if (this.userCurrentDomain) {
        // this.currentParentNodeId = this.userCurrentDomain;
        // this.$store.dispatch("clearLearnHierarchyNodes").then(() => {
        //   this.$store.dispatch("loadLearnHierarchyRootNodes");
        // });
        // this.learnNodesBreadcrumbs = [];
        // this.learnNodesBreadcrumbs.push(
        //   {
        //     title: "Learn",
        //     disabled: false,
        //     parentNodeIdKeyinChildNodesMap: this.currentParentNodeId,
        //   });
      }
    },
  },
  methods: {
    getImageUrl(imagePath) {
      const imagSrc = this.$store.state.app_s3url + imagePath;
      console.log("imagSrc", imagSrc);
      return "https://mystudypod.devtest.thestudypod.com/assets/assets/images/meet.png";
    },
    getUserInvite(item) {
      this.$store.commit("classesListLoadedFromServer", false);
      this.$store.dispatch('getUserInvite', {
        'meetingId': item.meetingId,
        'meetingType': item.meetingType,
        'zoomAccount': item.zoomAccount,
      }).then((zoomMeetingInvite) => {
        if (zoomMeetingInvite) {
          const url = zoomMeetingInvite.join_url;
          recordAnalyticsEvent(AnalyticsEvents.v2MyClassesJoinZoomMeeting, { meetingId: item.meetingId });
          window.open(url);
        }
        this.$store.commit("classesListLoadedFromServer", true);
      })
    },
    clearSearch() {
      this.filterSearchVal = this.searchText;
      //console.log(`clearSearch called:: this.filterSearchVal: ${this.filterSearchVal}`);
    },
    debounceSearch: _.debounce(function () {
      this.filterSearchVal = this.searchText;
      if (this.filterSearchVal) {
        recordAnalyticsEvent(AnalyticsEvents.v2MyClassesSearch, { searchText: this.filterSearchVal });
      }
      //console.log(`debounceSearch called: filterSearchVal:: ${this.filterSearchVal}`);
    }, 500),
  },
};
</script>

<style scoped>
.search-box {
  max-width: 300px;
}

.v-card {
  border: 1px solid rgb(var(--v-theme-secondary));
}

.v-card.on-hover {
  cursor: pointer;
  border: 1px solid rgb(var(--v-theme-primary));
  background-color: #fff;
}

.heading-block {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #282828;
}

.date-block {
  font-weight: 500;
  font-size: 12.8px;
  line-height: 15px;
  color: #888888;
}

.description-block {
  font-weight: 400;
  font-size: 12.8px;
  line-height: 15px;
  color: #515151;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
<style>
.no-new-line>a.v-breadcrumbs-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: none;
}

.no-new-line>li.v-breadcrumbs-item {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: none;
}
</style>
