import { initializeApp } from "firebase/app";
import store from "../store";
import verFlavor from "../appFlavorAndVersion"
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getHeaders } from './authService';
import { useNotification } from "@kyvg/vue3-notification";
import router from "@/router";

export async function createFirebaseApp() {

    var flavour = verFlavor.APP_FLAVOR

    await store.dispatch("updateFirebaseInfo", {
        "appPlatform": "web",
        "appFlavour": flavour.toLowerCase(),
    })

    let firebaseInfo = store.getters.firebaseInfo
    const app = initializeApp({
        apiKey: firebaseInfo['apiKey'],
        appId: firebaseInfo['appId'],
        messagingSenderId: firebaseInfo['messagingSenderId'],
        projectId: firebaseInfo['projectId'],
        authDomain: firebaseInfo['authDomain'],
        storageBucket: firebaseInfo['storageBucket'],
        measurementId: firebaseInfo['measurementId'],
    });
    
    let vapidkey = firebaseInfo.vapikey

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(async (serviceWorker) => {
            console.log("proceed", serviceWorker);

            const messaging = getMessaging();
            onMessage(messaging, (payload) => {
                console.log('Message received. ', payload);
                const { notify } = useNotification()

                notify({
                    group: 'foo',
                    title: payload.notification.title,
                    text: payload.notification.body,
                    duration: 3000,
                    id: "1",
                    ignoreDuplicates: true,
                    data : {
                        notificationRoute : payload.data.notificationRoute

                    }
                });
                handleNotify(payload)
            });
            let res = await getFCMToken(messaging, vapidkey, serviceWorker)
            console.log(res);
            if (res == null) {
                console.log("trying again in 3 seconds");
                setTimeout(async () => {
                    res = await getFCMToken(messaging, vapidkey, serviceWorker)
                    console.log(res);
    
                }, 3000);
    
            }
        });



    }

}
export function handleNotify(payload) {
    console.log(router.currentRoute.value);

    let currentRoute = router.currentRoute.value.path
    let notificationRoute = payload.data.notificationRoute

    console.log(currentRoute);
    if (currentRoute == notificationRoute) {
        store.commit("updateTabData", notificationRoute )
        return;
    }
    store.commit("updateNavBarItems", { data: notificationRoute, show: true })

}
// function handleNotify(payload) {
//     console.log(router.currentRoute.value);

//     let currentRoute = router.currentRoute.value.path
//     for (let item of Object.keys(payload.data ?? [])) {
//         console.log(item);
//         console.log(currentRoute);
//         if (currentRoute.startsWith(item)) {
//             return;
//         }
//         store.commit("updateNavBarItems", { data: item, show: true })
//     }
// }
// async function getFCMToken(messaging, vapidkey, swRegistration) {
//     getToken(messaging, {
//         vapidKey: vapidkey, serviceWorkerRegistration: swRegistration,
//     }).then(async (currentToken) => {
//         if (currentToken) {
//             // Send the token to your server and update the UI if necessary
//             console.log("Token is:", currentToken);

//             await store.dispatch("sendFCMTokenToFirebase", {
//                 appSimulated: false,
//                 appPlatform: "web",
//                 ...getHeaders(),
//                 token: currentToken,

//             })
//             return "success"
//             // ...
//         } else {
//             // Show permission request UI
//             console.log('No registration token available. Request permission to generate one.');
//             // ...
//         }
//     }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//         return "error"
//         // ...
//     });
// }

async function getFCMToken(messaging, vapidkey, swRegistration) {
    try {

        let res = await getToken(messaging, {
            vapidKey: vapidkey, serviceWorkerRegistration: swRegistration,
        })

        await store.dispatch("sendFCMTokenToFirebase", {
            appSimulated: false,
            appPlatform: "web",
            ...getHeaders(),
            token: res,
        })
        return res
    }
    catch (e) {
        console.log(e);
        return null
    }
}