<template>
  <v-card v-if="showSelfHelpGuideCompute" elevation="2" class="self-help-card pa-5 pb-3">
    <v-card-text class="pa-0 ma-0">
      <v-row class="ma-0 justify-space-between">
        <v-col cols="" class="pa-0">
          <!-- <v-avatar v-if="!!currentSelfHelpStepDetail?.icon" size="40" class="mb-4">
            <v-img src="@/assets/mento_logo_rounded_2.svg" alt=""/>
          </v-avatar> -->
          <p v-if="!!currentSelfHelpStepDetail?.title" class="t-1rem">{{ currentSelfHelpStepDetail.title }}</p>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-icon @click="closeSelfHelpGuide()"> mdi-close</v-icon>
        </v-col>
      </v-row>
  
      <v-row v-if="!!currentSelfHelpStepDetail?.description" class="t-h6Reg ma-0 mt-4">
        <v-col class="pa-0">
          {{ currentSelfHelpStepDetail.description }}
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pa-0 ma-0 mt-4">
      <v-row class="t-1rem ma-0"  align="center">
        <v-col v-if="prevSelfHelpStepId != null" cols="auto" class="pa-0">
          <v-btn class="refresh-btn t-btn text-none" density="default" variant="outlined" dark
            @click.stop="prevStep()">
            Previous
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>
        
        <v-col v-if="nextSelfHelpStepId != null" cols="auto" class="pa-0">
          <v-btn class="refresh-btn t-btn text-none" density="default" variant="outlined" dark
            @click.stop="nextStep()">
            Next
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
      
</template>

<script>
import { useNotification } from "@kyvg/vue3-notification";
import { mapGetters, mapState } from "vuex";
export default {
  name: 'SelfHelpGuideComponent',
  async created(){
    this.loadPage = false;
  },
  mounted(){
    this.loadPage= true
  },
  data: () => ({
    loadPage: false,
  }),
  computed: {
    ...mapState(['showSelfHelpGuide', 'currentScreenName', 'currentStepId', 'loaderCount']),
    ...mapGetters(['currentSelfHelpStepDetail', 'prevSelfHelpStepId', 'nextSelfHelpStepId', 'showSelfHelpGuideCompute']),
  },
  methods: {
    closeNotification(item) {
      const notification = useNotification()
      notification.notify.close(item.id)
    },
    getIcon(src){
      return `../components/assests/${src}`;
    },
    nextStep() {
      this.$store.dispatch('goToNextSelfHelpStep');
    },
    prevStep() {
      this.$store.dispatch('goToPrevSelfHelpStep');
    },
    closeSelfHelpGuide(){
      this.$store.commit('toggleSelfHelpGuide', false);
      let reqData = {
        preferenceItemName : 'turnOnSelfHelpGuide',
        preferenceItemValue : false,
      }
      this.$store.dispatch('setUserPreferences', reqData);
    }
  },
  watch: {
  }

}
</script>

<style scoped>

.self-help-card{
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.22);
  margin: 0;
  background-color: rgb(var(--v-theme-cardBackgroundColor2)) !important;
  color: white;
  max-width: 350px;
  min-height: 200px;
}

@media only screen and (max-width: 600px) {
  .self-help-card{
    bottom: 5.5rem;
    margin-right: 0.5rem;
  }
}



</style>