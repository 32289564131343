<template>
    <v-container v-if="skillsLoaded" fluid>

        <v-row class="ma-0 pa-0" justify="space-between">
            <v-col class=" " cols="12" md="6" v-for="subject, index in studentSkills.skillDetails" :key="index">


                <v-row class="ma-0 pa-0 t-h5Reg mb-5 mx-1">
                    {{ subject.subjectName }}
                </v-row>
                <v-hover v-slot="{ isHovering, props }" v-for="topic, index in subject.topicSkills" :key="index">

                <v-row class="ma-0 pa-0 list-item pa-3 my-4  pl-4 pl-sm-5  pl-lg-6  "  v-bind="props"  justify="space-between" align="center"
                    :class="{ 'on-hover': isHovering }" @click="getAllReports(topic)" :ref="`StudentSkillsPageScreen__card--${subject.subjectType}${index}`"> 

                    <v-col class="ma-0 pa-0 t-h6Reg" cols="5" md="6" lg="6">
                        {{ topic.topicName }}
                    </v-col>
                    <!-- <v-spacer></v-spacer> -->
                    <v-col cols="6" sm="4" md="6" lg="5" class="ma-0 pa-0 ">
                        <v-row class="ma-0 pa-0 mr-0" align="center" justify="space-between" >
                            <div class="d-flex align-center">
                                <div class="skill-box" :class="{
                                    'not-started-skill-box' : topic.skill=='Not Started',
                                    'beginner-skill-box': topic.skill == 'beginner'  ,
                                    'intermediate-skill-box':
                                        topic.skill == 'intermediate' ,
                                    'advanced-skill-box': topic.skill == 'advanced' ,
                                    'mastery-skill-box': topic.skill == 'mastery',
                                }"></div>
                                <span class="text-capitalize ml-1  t-h6Reg">{{
                                    topic.skill
                                }}</span>
                            </div>
                            <v-icon class="text-primaryTextColor" icon="mdi-chevron-right"></v-icon>
                        </v-row>

                    </v-col>
                </v-row>
            </v-hover>

            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapGetters, mapState } from "vuex";
import moment from 'moment'
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
export default {
name: 'SkillsComponent',
props: ["assignmentType", "batchId", "studentId"],
setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
},
created() {
    this.skillsLoaded = false;
    if(this.$route.fullPath.startsWith('/parent')){
      this.isOpenedFromParentView = true;
    }
    this.$store.dispatch('loadStudentSkills', { studentId: this.studentId })
    this.skillsLoaded = true;
    if(this.isOpenedFromParentView){
        recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildSkillsTabOpen, {
            courseId: this.courseId,
            childId: this.studentId,
        });
    }
    else{
        recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenSkillsTabOpen, {
            courseId: this.courseId,
            studentId: this.studentId,
            fromClass: this.$route.params.batchId? true: false,
            batchId: this.$route.params.batchId? this.$route.params.batchId: "",
        });
    }

},
mounted(){
  this.$store.commit("updateCurrentScreenName", this.currentScreenName);
},
unmounted(){
  this.$store.commit('updateCurrentScreenName', null);
},
computed: {
    ...mapState(['courseId']),
    ...mapGetters(['currentHighlightedElements']),
    studentSkills: {
        get() {
            return this.$store.state.studentSkills
        }
    }
},
data: () => ({
    skillsLoaded: false,
    isOpenedFromParentView: false,
    currentScreenName: 'StudentSkillsPageScreen',
}),
methods: {
    getAllReports(item) {
        this.$store.commit("updateCurrentTopicId", item.topicId);
        this.$store.commit("updateCurrentTopicName", item.topicName);
        this.$store.commit("updateCurrentTopicSkill", item.skill);
        if(this.isOpenedFromParentView){
            recordAnalyticsEvent(AnalyticsEvents.v2ParentViewChildSkillsTabReportOpen, {
                courseId: this.courseId,
                childId: this.studentId,
                topicId: item.topicId,
            });
        }
        else{
            recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenSkillsTabReportOpen, {
                courseId: this.courseId,
                studentId: this.studentId,
                topicId: item.topicId,
                fromClass: this.$route.params.batchId? true: false,
                batchId: this.$route.params.batchId? this.$route.params.batchId: "",
            });
        }
        this.$router.push(`${this.$route.fullPath}/learnreports/${item.topicId}`);

        // this.$router.push('/learnreports/' + item.topicId);
    }

},
watch: {
  currentHighlightedElements: {
    handler(newVal, oldVal) {
      this.$nextTick(() => {
        updateHighlightedElements(oldVal, newVal, this.$refs);
      });
    },
    immediate: true,
    deep: true,
  },
}



}
</script>
  
<style scoped>
.assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border-radius: 12px !important;
}

.main-lg {
    max-width: 1020px;
    margin: 20px auto;
}

.main-md {
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%;
}

.card-bg {
    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    background-color: rgb(var(--v-theme-reportBgColor));
    
    border-radius: 12px;

}

.card-border {
    border: 2px solid transparent;
    transition: all 0.4s ease;
    /* pointer-events: none; */
    -webkit-transition: all 0.4s ease;
    cursor: pointer;
}

.search-underline {
    border-bottom: 1px solid #ffffff62;
}

.heading {
    font-weight: 600 !important;
    font-size: 1.25rem;
    line-height: 1.25;
}

.parameter-style {
    opacity: 0.4;
}

/* .check-report {
    padding: 8px 10px;
    box-sizing: border-box;
    background: rgba(38, 37, 58, 0.5);
    border: 2px solid rgb(var(--v-theme-primaryPurple));
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 600px;
    pointer-events: auto;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
  }
  
  .check-report:hover {
    cursor: pointer;
    border-color: rgb(var(--v-theme-primaryPurple));
    background: linear-gradient(93deg, #AB77FF 0.66%, #585EF3 99.02%);
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  } */

.result-btn {
    border: 2px solid rgba(255, 255, 255, 0.22);
    border-radius: 12px;
    /* background: rgb(var(--v-theme-cardBackgroundColor)); */
    background-color: rgb(var(--v-theme-reportBgColor));
}

.skill-box {
    height: 30px;
    width: 26px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-right: 5px;
}

.beginner-skill-box {
    background: #c8c8c8;

}
.not-started-skill-box {
    border: 1px solid #c8c8c8;

}
.intermediate-skill-box {
    border: 1px solid #6a6ef9;
    background: linear-gradient(to bottom, white 50%, #6a6ef9 50%);
}

.advanced-skill-box {
    border: 1px solid #26b99a;
    background: #26b99a;
}

.mastery-skill-box {
    border: 1px solid #7b56c8;
    background: #7b56c8;
}

.list-item {
    cursor: pointer;
    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    border-radius: 10px;
    background: rgb(var(--v-theme-report-subCardBg));

}
.list-item.on-hover  {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
/* 
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}



</style>