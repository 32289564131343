<template>
  <v-container v-if="orgDetailsLoaded" fluid class="assignment-card pa-5">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="main-heading pa-0">
        <span>
          {{organizationDetails.customerName}}
        </span>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-4 ga-3">
      <v-col v-if="organizationDetails?.email != null && organizationDetails?.email != ''" class="card-bg t-1remReg" cols="auto">
        <v-icon icon="mdi-email" color="#ffffff" size="20" class="mr-2"></v-icon>
        <span>
          {{ organizationDetails.email }}
        </span>
      </v-col>
      <v-col v-if="organizationDetails?.contactNumber != null && organizationDetails?.contactNumber != ''" class="card-bg t-1remReg" cols="auto">
        <v-icon icon="mdi-phone" color="#ffffff" size="20" class="mr-2"></v-icon>
        <span>{{ organizationDetails.contactNumber }}</span>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-8 pa-0">
      <v-col class="t-h5Reg pa-0">
        <span>
          License Details
        </span>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    
    <v-row class="ma-0 mt-4 ga-3" v-if="(organizationDetails?.licenseDetails?.studentTrialLicenseCount > 0) || (organizationDetails.licenseDetails.tutorTrialLicenseCount > 0) ">
      <v-col class="t-1remReg ma-0 pl-0" cols="2">
        <span>Trial License</span>
      </v-col>
      <v-col v-if="organizationDetails?.licenseDetails?.studentTrialLicenseCount != null && organizationDetails?.licenseDetails?.studentTrialLicenseCount > 0" class="card-bg t-1remReg ma-0" cols="3">
        <span>Students- {{organizationDetails?.licenseDetails?.studentTrialLicenseUsed}}/{{ organizationDetails.licenseDetails.studentTrialLicenseCount }}</span>
      </v-col>
      <v-col v-if="organizationDetails?.licenseDetails?.tutorTrialLicenseCount != null && organizationDetails?.licenseDetails?.tutorTrialLicenseCount > 0" class="card-bg t-1remReg ma-0" cols="3">
        <span>Tutors- {{organizationDetails.licenseDetails.tutortrialLicenseUsed}}/{{organizationDetails.licenseDetails.tutorTrialLicenseCount}}</span>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-4 ga-3" v-if="(organizationDetails.licenseDetails.studentPaidLicenseCount > 0) || (organizationDetails.licenseDetails.tutorPaidLicenseCount > 0)">
      <v-col class="t-1remReg ma-0 pl-0" cols="2">
        <span>Paid License</span>
      </v-col>
      <v-col v-if="organizationDetails.licenseDetails.studentPaidLicenseCount != null && organizationDetails.licenseDetails.studentPaidLicenseCount > 0" class="card-bg t-1remReg ma-0" cols="3">
        <span>Students- {{organizationDetails.licenseDetails.studentPaidLicenseUsed}}/{{organizationDetails.licenseDetails.studentPaidLicenseCount}}</span>
      </v-col>
      <v-col v-if="organizationDetails.licenseDetails.tutorPaidLicenseCount != null && organizationDetails.licenseDetails.tutorPaidLicenseCount > 0" class="card-bg t-1remReg ma-0" cols="3">
        <span>Tutors- {{organizationDetails.licenseDetails.tutorPaidLicenseUsed}}/{{organizationDetails.licenseDetails.tutorPaidLicenseCount}}</span>
      </v-col>
    </v-row>

    <template v-if="organizationDetails.isWhiteLabel">
      <v-row class="ma-0 mt-8 pa-0">
        <v-col class="t-h5Reg pa-0">
          <span>
            White Label Details
          </span>
        </v-col>
      </v-row>
      <v-divider class="mt-2"></v-divider>
  
      <v-row class="ma-0 mt-4 ga-3">
        <v-col class="t-1remReg ma-0 pl-0" cols="2">
          <span>Logo</span>
        </v-col>
        <v-col cols="auto" class="pa-0 ma-0 d-flex align-center">
          <v-img class="logo-img" :src="getImageSrc(organizationDetails.image)"></v-img>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-4 ga-3">
        <v-col class="t-1remReg ma-0 pl-0" cols="2">
          <span>Custom URL</span>
        </v-col>
        <v-col class="card-bg t-1remReg ma-0" cols="auto">
          <span>{{organizationDetails.appUrl}}</span>
        </v-col>
      </v-row>  
    </template>


    <v-row class="ma-0 mt-8 pa-0">
      <v-col class="t-h5Reg pa-0">
        <span>
          Settings
        </span>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>

    <v-row class="ma-0 ga-3 align-center">
      <v-col class="t-1remReg ma-0 pl-0" cols="auto">
        <span>Show practice tests to students</span>
      </v-col>
      <v-col class="t-1remReg ma-0" cols="auto">
        <v-switch color="primaryPurple" v-model="editedItem.isDisplayAdaptiveTest" hide-details density="compact" dense @update:modelValue="changeAdaptiveTest"></v-switch>
      </v-col>
    </v-row>

    <v-row class="ma-0 ga-3 align-center">
      <v-col class="t-1remReg ma-0 pl-0" cols="auto">
        <span>Send Assignment Reminders To Student</span>
      </v-col>
      <v-col class="t-1remReg ma-0" cols="auto">
        <v-switch color="primaryPurple" v-model="editedItem.sendAssignmentRemindersToStudent" :disabled="editedItem.sendAssignmentRemindersToParent" hide-details density="compact" dense @update:modelValue="changeSendAssignmentEmail"></v-switch>
      </v-col>
    </v-row>

    <v-row class="ma-0 ga-3 align-center">
      <v-col class="t-1remReg ma-0 pl-0" cols="auto">
        <span>Send Assignment Reminders To Parent</span>
      </v-col>
      <v-col class="t-1remReg ma-0" cols="auto">
        <v-switch color="primaryPurple" v-model="editedItem.sendAssignmentRemindersToParent" hide-details density="compact" dense @update:modelValue="changeSendEmailToParent"></v-switch>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapState } from "vuex";
import _ from 'lodash';
import orgSignInLogo from "@/assets/mentomind/mentomind_white2.svg";
export default {
  name: 'OrganizationDetailComponent',
  data: () => ({
    currentScreenName: 'OrganizationDetailScreen',
    orgDetailsLoaded: false,
    editedItem:{
      isDisplayAdaptiveTest: true,
      sendAssignmentRemindersToStudent: true,
      sendAssignmentRemindersToParent: false
    }
  }),
  created() {
    this.$store.commit("updateOrganizationDetails", "");
    this.$store.dispatch('loadOrganizationDetails', { courseId: this.courseId }).then(res => {
      this.orgDetailsLoaded = true;
      this.editedItem = {
        isDisplayAdaptiveTest: this.organizationDetails.isDisplayAdaptiveTest,
        sendAssignmentRemindersToStudent: this.organizationDetails.sendAssignmentRemindersToStudent,
        sendAssignmentRemindersToParent: this.organizationDetails.sendAssignmentRemindersToParent
      }
    })
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  methods: {
    getImageSrc(path) {
      return this.$store.state.app_s3url + path;
    },
    changeAdaptiveTest(){
      // Add Pinpoint
      this.$store.dispatch('updateCustomerDetails', this.editedItem);
    },
    changeSendAssignmentEmail(){
      // Add Pinpoint
      this.$store.dispatch('updateCustomerDetails', this.editedItem);
    },
    changeSendEmailToParent(value){
      if(value){
          this.editedItem.sendAssignmentRemindersToStudent = value;
      }
      this.$store.dispatch('updateCustomerDetails', this.editedItem);
      // Add Pinpoint    
    },
  },
  computed: {
    ...mapState(['courseDetails', 'organizationDetails', 'courseId']),
  }
  
}
</script>

<style scoped>
.assignment-card{
  border-radius: 12px !important;
}

.card-bg {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
}

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.logo-img{
  width: 184px;
}
.logo-img>img {
  object-fit: scale-down !important;
}

.nav-logo {
  height: 46px !important;
  width: 184px !important;
}

</style>