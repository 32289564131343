<template>

  <v-row v-if="pageLoaded && showSelfHelpGuideFeature" class="ma-0 " :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end" align="center">
    <!-- INFO COMPONENT -->
    <v-col cols="auto" >
      <InfoComponentLearnPage :currentSubjectType="currentSubjectType" />
    </v-col>
    <v-col cols="auto" class="mr-4">
      <SelfHelpGuideTogglerComponent/>
    </v-col>
    <v-divider></v-divider>
  </v-row>

  <v-container style="position: relative" fluid class="ma-0 pa-4 bg-transparent">
    <InfoComponentLearnPage v-if="pageLoaded && !showSelfHelpGuideFeature" :showAbsolutePosition="true" />
    <div class="mt-4" :class="{
      'entity-container': lg,
      'entity-container-xl': xl,
      'entity-container-xxl': xxl,
    }">
      <v-container fluid class="bg-transparent">
        <v-row class="mb-4" align="center" justify="space-between">
          <v-col cols="auto" md="6">
            <span class="text-white t-h125">Master each topic and boost your SAT/PSAT scores!</span>
          </v-col>

          <v-col cols="12" sm="auto" class="py-0 ma-0">
            <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex" ref="LearnPageScreen--subjectToggleBtn">

              <v-btn v-for="tab in subjectsList" :key="tab.value" @click="changeCategory(tab.value)" :value="tab.value"
                class="py-2 px-3 flex-grow-1"
                :class="[tab.value == currentSubjectType ? 'active-button' : 'normal-button']"
                :variant="tab.value == currentSubjectType ? 'elevated' : 'plain'" elevation="0">
                <span class="text-none button-text t-h6Reg">{{ tab.name }}</span>
              </v-btn>

            </v-sheet>
          </v-col>

        </v-row>

        <v-row no-gutters v-if="learnNodes.length > 0" :justify="mdAndDown ? 'center' : 'space-between'">

          <v-sheet class="bg-transparent mb-4 w-100">
            <template v-for="category in subjectData.categories" :key="category.categoryId">
              <v-fab-transition>

                <v-sheet class="bg-category pa-3 mb-4 w-100">
                  <!-- Category Heading -->
                  <v-row justify="space-between" class="ma-0 pa-3 pt-1">
                    <v-col cols="auto" class="pt-2 pa-0">
                      <span class="t-1rem text-primaryTextColor">{{ category.categoryName }}</span>
                    </v-col>
                    <v-col cols="auto" class="pt-2 pa-0">
                      <span class="t-1remReg text-primaryTextColor">{{ category.categoryDescription }}</span>
                    </v-col>
                  </v-row>
                  <!-- Category Cards -->
                  <v-sheet class="d-flex flex-wrap align-center justify-center bg-transparent justify-sm-start">
                    <v-sheet v-for="(item, i) in category.entity" :key="i"
                      class="ma-1 pa-2 bg-transparent width-adjustment">
                      <v-hover v-slot="{ isHovering, props }">
                        <v-card variant="flat" class="entity-item" :class="{ 'on-hover': isHovering }" v-bind="props"
                          @click="item.isReady ? learnNodeClicked(item, category.categoryId, $event) : null" style="background: #353448;" :ref="i == 0? `LearnPageScreen--topicCard-${currentSubjectType}-0` : ''" :id="i == 0? `LearnPageScreen--topicCard-${currentSubjectType}-0` : ''">
                          <v-card-text class="pa-0 h-100 d-flex flex-column">
                            <v-row justify="center" align="center" class="py-2 ma-0 flex-grow-0 "
                              :style="item.isReady ? 'background: linear-gradient(93deg, #AB77FF -1.51%, #585EF3 100.54%);' : 'background: linear-gradient(180deg, #7A819B 0%, #5C649A 100%);'">
                                
                              <v-col cols="2"  class=" justify-end text-center pa-0 mr-2">
                                <v-img height="38" :src="getSkillLevelIcon(item.isReady, item.skill)" />
                              </v-col>
                              <v-col cols="6" class="pa-0 justify-center">
                                <span class="t-h6Reg text-white">
                                  {{ item.isReady ? getSkillName(item.skill) : 'Coming soon' }}
                                </span>
                                <v-icon class="text-primaryTextColor" icon="mdi-chevron-right"></v-icon>
                                
                                
                              </v-col>
                              <v-col v-if="!item.isAccessAllowed" cols="2" class="justify-end">
                                <v-icon size="25" color="background2" >mdi-lock-outline</v-icon>
                              </v-col>
                              <v-col v-else cols="2" class="justify-end">
                                <v-icon size="25"></v-icon>
                              </v-col>
                            </v-row>

                            <v-row class="flex-grow-1 ma-0 pa-3">
                              <v-col cols="12" class="text-center pa-0 d-flex justify-center align-center">
                                <span class="text-white t-h6Reg">
                                  {{ item.entityLabel }}
                                </span>
                              </v-col>
                            </v-row>

                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-sheet>
                  </v-sheet>
                </v-sheet>

              </v-fab-transition>

            </template>
          </v-sheet>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>
  
<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { checkSubscription } from "@/services/subscriptionService";

import { mapGetters, mapState } from 'vuex';
import { useDisplay } from 'vuetify';
import _ from "lodash";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
import InfoComponentLearnPage from "@/components/infoComponent/InfoComponentLearnPage.vue";
export default {
  name: "LearnPage",
  components: { SelfHelpGuideTogglerComponent, InfoComponentLearnPage },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
    return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
  },
  created() {
    this.pageLoaded = false;
    this.$store.commit("updateNavBarItems", { data: "/learn", show: false })

    recordAnalyticsEvent(AnalyticsEvents.v2LearnScreenOpen);
    if (this.userCurrentDomain) {
      this.currentParentNodeId = this.userCurrentDomain;
      this.$store.dispatch("loadNewLearnNodes");
    }
    // if (localStorage.getItem("newUser") == "true" && localStorage.getItem("newLearn") == "true") {
    //   this.showInfoTab = true;
    // }
    this.pageLoaded = true;
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: 'LearnPageScreen',
    pageLoaded: false,
    searchText: "",
    filterSearchVal: "",
    showInfoTab: false,
    subjectType: 'readingAndWriting',
    subjectsList: [
      {
        name: 'Reading and Writing',
        value: 'readingandwriting'
      },
      {
        name: 'Math',
        value: 'math'
      },
    ],
  }),
  computed: {
    ...mapState(["newLearnNodes", "userCurrentDomain", "currentSubjectType",]),
    ...mapGetters(['currentHighlightedElements', "showSelfHelpGuideFeature"]),
    learnNodes: {
      get() {
        let nodesToDisplay = this.newLearnNodes;
        if (this.filterSearchVal) {
          nodesToDisplay = nodesToDisplay?.filter(item => item.entityLabel?.toLowerCase()
            .includes(this.filterSearchVal.toLowerCase()));
        }
        nodesToDisplay.map(item => {
          if (!item.skill) {
            item.skill = "Beginner";
          }
          else {
            item.skill = item.skill.charAt(0).toUpperCase() + item.skill.substr(1).toLowerCase();
          }
        });
        return nodesToDisplay;
      }
    },
    subjectData: {
      get() {
        let item = _.filter(this.newLearnNodes, (item) => {
          return item.subjectType == this.currentSubjectType;
        })
        return item[0];
      }
    }
  },
  methods: {
    onClick() { },
    learnNodeClicked(item, categoryId) {
      if(item.isAccessAllowed){
          // console.log("www",item);
          // let sub = checkSubscription({"accessAllowed" : false})
          // if(!sub)
          // {
          //   return
          // }
            let subjectId= '';
          this.newLearnNodes.forEach(element => {
            if(element.subjectType == this.currentSubjectType){
              subjectId = element.subjectId;
            }
          });
          recordAnalyticsEvent(AnalyticsEvents.v2LearnScreenTopicClicked, {
            subjectId: subjectId,
            categoryId: categoryId,
            topicId: item.entityId,
            topicName: item.entityLabel,
            currentSkill: item.skill,
            isReady: item.isReady
          });
          this.$router.push(`/learn/${item.entityId}`);
        }else{
          //Add pinpoint
          recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogOpenForPractice, {
            topicId: item.entityId,
          });
          this.$store.commit('openUpgradeDialog', true);
        }
    },
    changeCategory(value) {
      let subjectId= '';
      this.newLearnNodes.forEach(element => {
        if(element.subjectType == value){
          subjectId = element.subjectId;
        }
      });
      recordAnalyticsEvent(AnalyticsEvents.v2LearnScreenSubjectChanged, {
        subjectId: subjectId,
      });
      this.$store.commit("updateCurrentSubjectType", value);

    },
    categoryActive(value) {
      if (value == this.moduleCategory) {
        return true;
      }
      return false;
    },
    getSkillName(skill) {
      skill = skill.toLowerCase();
      if (skill == "notset") {
        return "Get started";
      }
      else {
        return this.getCapitalizedText(skill);
      }
    },
    getSkillLevelTextColor(skillLevel) {
      skillLevel = skillLevel.toLowerCase();
      let color = "#323232";
      if (skillLevel) {
        if (skillLevel != "notset") {
          color = "#FFFFFF";
        }
      }
      return color;
    },
    getSkillLevelColor(skillLevel) {
      skillLevel = skillLevel.toLowerCase();
      let color = "#D1D1D1";
      if (skillLevel) {
        if (skillLevel == "beginner") {
          color = "#FF894F";
        }
        else if (skillLevel == "intermediate") {
          color = "#55B56A";
        }
        else if (skillLevel == "advanced") {
          color = "#01ACBE";
        }
        else if (skillLevel == "mastery") {
          color = "#1975CF";
        }
      }
      return color;
    },
    getSkillLevelIcon(isReady, skillLevel) {
      skillLevel = skillLevel.toLowerCase();
      let icon = "assets/new_images/medal_coming_soon.svg";
      if (isReady && skillLevel) {
        if (skillLevel == "notset") {
          icon = "assets/new_images/medal_start_enable.svg";
        }
        else if (skillLevel == "beginner") {
          icon = "assets/new_images/medal_beginner_enable.svg";
        }
        else if (skillLevel == "intermediate") {
          icon = "assets/new_images/medal_intermediate_enable.svg";
        }
        else if (skillLevel == "advanced") {
          icon = "assets/new_images/medal_advanced_enable.svg";
        }
        else if (skillLevel == "mastery") {
          icon = "assets/new_images/medal_mastery_enable.svg";
        }
      }
      return this.getIconSrc(icon);
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    getCapitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    },
    isEven(number) {
      if (number % 2 === 0) {
        return true;
      }
      else {
        return false;
      }
    },
    closeInfoTab() {
      // localStorage.setItem("newLearn", false);
      // localStorage.setItem("newUser", false);
      this.showInfoTab = false;
    }
  },
  watch: {
    userCurrentDomain() {
      if (this.userCurrentDomain) {
        this.$store.dispatch("clearNewLearnNodes").then(() => {
          this.$store.dispatch("loadNewLearnNodes");
        });
      }
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  
<style scoped>

.t-86rem {
  font-size: 0.86rem !important;
}

.bg-transparent {
  background-color: transparent;
}

.toggler-outer-bg {
  border-radius: 200px !important;
  /* border: 2px solid rgba(255, 255, 255, 0.12); */
  background: #2A2941;
}

.active-button {
  border-radius: 200px;
  /* background-color: rgb(var(--v-theme-background)) !important; */
  /* background-color: #494960 !important; */
  /* border: 1.4px solid var(--Purple-G-1, #AB77FF); */
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
  border-radius: 200px;
  background: none;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
  border: 2px solid transparent;
}

.button-text {
  letter-spacing: -0.08px;
}

/* .normal-button:hover{
    background: none;
    color: rgb(var(--v-theme-primaryTextColor)) !important;
  } */

.entity-container {
  padding-left: 8%;
  padding-right: 8%;
  background-color: transparent;
}

.entity-container-xl {
  padding-left: 17%;
  padding-right: 17%;
  background-color: transparent;
}

.entity-container-xxl {
  padding-left: 28%;
  padding-right: 28%;
  background-color: transparent;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-navColor));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.skill-container {
  display: inline-flex;
  width: fit-content;
  border-radius: 8px;
  padding: 5px 10px;
}

.entity-item {

  border-radius: 12px;
  height: 170px;
  width: 230px;
}

@media only screen and (max-width: 600px) {
  .width-adjustment {
    width: 100%;
  }

  .entity-item {
    width: 100%;
    height: 140px;
  }
}



.v-overlay.v-overlay--active {
  background: rgba(0, 0, 0, 0.75) !important;
  backdrop-filter: blur(12.5px) !important;
}

.bg-category {
  border-radius: 12px;
  background: #26253A;
}

.lock-topic{
  border-color: white;
}

.heightBread {
    height: 80px;
}

</style>
