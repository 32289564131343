<template>
  <div class="hello">
    <!-- <h1>Login</h1>

    <h3 v-if="missingToken">Missing Token</h3>
    <h3 v-if="invalidToken">Invalid Token</h3> -->

  </div>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { getDeviceId, getTempUserId, getResourceLink, clearResourceLink } from "@/services/authService";
import _ from "lodash";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { signInLoad, teacherAssignment, apiCallsOnSigningUp } from "@/services/signinService";

export default {
  name: 'SSOPage',

  async created() {
    let deviceId = getDeviceId();
    if (
      !deviceId ||
      deviceId == "" ||
      deviceId.toLowerCase() == "undefined"
    ) {
      try {
        deviceId = crypto.randomUUID();
      } catch (err) {
        console.log(
          `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
        );
        deviceId = uuidv4();
      }
    }
    if ('code' in this.$route.query) {
      const code = this.$route.query.code;
      const state = JSON.parse(window.atob(this.$route.query.state));
      this.$store.commit('updateTeacherTestId', state.testId)
      this.$store.commit('shareCollectionLinkOpened', {
        requestId: state.requestId
      })


      if (state?.differentDomain) {
        const newState = {
          userType: state.userType,
          differentDomain: false,
          url: ''
        }
        const newStateString = window.btoa(JSON.stringify(newState))
        window.open(state.url + `/ssoPage?code=${code}&state=${newStateString}`, '_self');
      } else {
        const userType = state.userType;
        if (userType === 'new') {
          recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleNewUserCodeReceived, {
            tempUserId: getTempUserId()
          });
        } else {
          recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleExistingUserCodeReceived, {
            tempUserId: getTempUserId()
          });
        }
        this.$store.dispatch("googleUserSSO", {
          tempUserId: getTempUserId(),
          deviceId: deviceId,
          newUser: userType === 'new',
          redirectUri: _.get(this.config, 'web.redirect_uris', [])[0],
          code: code,
          courseId: this.courseId,
        }).then(async (res) => {
          if (res.isVerified) {
            this.$store.commit("storeLoggedInUserDetails", res);
            this.$store.commit('appbarVisible', true);
            this.$store.commit('navbarVisible', true);
            this.$store.commit("pricingModelsLoadedFromServer", false);
            await signInLoad();


            if (userType === 'new') {
              recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleNewUserSSOSuccess, {
                tempUserId: getTempUserId(),
                userId: res.userId
              });
              localStorage.removeItem('isInputFormShow');
              await apiCallsOnSigningUp();
              let teacherAssignmentRes = await teacherAssignment()
              if (teacherAssignmentRes == null) {
                const redirectResourceLink = getResourceLink() ?? null
                if(redirectResourceLink){
                  clearResourceLink();
                  this.$router.replace(redirectResourceLink);
                }else{
                  this.$router.replace('/');
                }                
              }

            } else {
              recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleExistingUserSSOSuccess, {
                tempUserId: getTempUserId(),
                userId: res.userId
              });

              if (res.newUser) {
                await apiCallsOnSigningUp();
                let teacherAssignmentRes = await teacherAssignment()
                if (teacherAssignmentRes == null) {
                  const redirectResourceLink = getResourceLink() ?? null
                  if(redirectResourceLink){
                    clearResourceLink();
                    this.$router.replace(redirectResourceLink);
                  }else{
                    this.$router.replace('/learn');
                  }
                }
              } else {
                await apiCallsOnSigningUp();
                let teacherAssignmentRes = await teacherAssignment()
                if (teacherAssignmentRes == null) {
                  const redirectResourceLink = getResourceLink() ?? null
                  if(redirectResourceLink){
                    clearResourceLink();
                    this.$router.replace(redirectResourceLink);
                  }else{
                    this.$router.replace('/');
                  }
                }
              }
            }
            

          } else {
            if ('error' in res) {
              if (userType === 'new') {
                this.$store.commit("setLoginError", res.error);
                this.$router.replace('/welcome');
              } else {
                this.$store.commit("setExistingLoginError", res.error);
                this.$router.replace('/login');
              }

            }
          }
        });
      }
    }

  },
  data: () => ({
    missingToken: false,
    invalidToken: false
  }),

  computed: {
    ...mapState(["userInfo", "loginError", "onBoardingResponse", "config", "isTeacherViewSelected", "courseId"]),

  },

  watch: {

  },

  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
