<template>
  <v-container fluid class="pa-5">
    <!-- <v-row>
      <v-col cols="12" class="pb-1 t-h4Reg text-white mt-1">
        Assignment Progress
      </v-col>
      <v-col cols="12" class="py-0 text-primaryGrey t-h6Reg">
        Here's how the student did on content you assigned to them. You can tap
        an assignment name to get more detailed reports.
      </v-col>
    </v-row> -->

    <v-row class="">
      <v-col cols="auto">
        <v-btn
          class="assignment-btn t-1remReg text-none mr-3 text-white"
          density="default"
          variant="flat"
          width="100"
          height="40"
          :class="{ 'assignment-activity-btn': current }"
          @click.stop="filterCurrentAssignments(true)"
          >Current</v-btn
        >
        <v-btn
          class="assignment-btn t-1remReg text-none text-white"
          density="default"
          variant="flat"
          width="80"
          height="40"
          :class="{ 'assignment-activity-btn': !current }"
          @click.stop="filterCurrentAssignments(false)"
          >Past</v-btn
        >
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers.concat(assignmentProgressHeaders)"
          :items="assignmentProgressData"
          :footer-props="{
            'items-per-page-options': [25, 50, 100, -1],
          }"
          item-key="userId"
          class="table-bg table-scroll"
        >
          <template
            v-for="field in assignmentProgressHeaders.children"
            v-slot:[`header.${field.value}`]="{}"
            :key="field.value"
          >
            <div :key="field.title" class="my-2 mx-0">
              <span>{{ field.title.substring(0, 40) }}</span>
              <v-tooltip v-if="field.title.length > 40">
                <template #activator="{ props }">
                  <v-icon small class="mr-2" v-bind="props">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>{{ field.title }}</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-for="field in assignmentProgressHeaders.children"
            v-slot:[`item.${field.value}`]="{ item }"
            :key="field.value"
          >
            <v-row
              v-if="item[field.value] && item[field.value].type == 'practice'"
            >
              <v-col cols="12" class="py-0 t-h6Reg">
                <span>{{
                  `${
                    item[field.value].progressSummary.studentPracticeSummary
                      .correctQuestions
                  }/${
                    item[field.value].progressSummary.studentPracticeSummary
                      .totalQuestions
                  }`
                }}</span>
              </v-col>
              <v-col cols="12" class="py-0 t-h6Reg text-primaryGrey">
                <span>Correct</span>
              </v-col>
            </v-row>
            <v-row v-else-if="item[field.value]">
              <v-col cols="12" class="py-0 t-h6Reg">
                <span>{{
                  `${
                    item[field.value].progressSummary.studentPracticeTestSummary
                      .overAllScore.lowerScore
                  } -
                                  ${
                                    item[field.value].progressSummary
                                      .studentPracticeTestSummary.overAllScore
                                      .upperScore
                                  }`
                }}</span>
              </v-col>
              <v-col cols="12" class="py-0 t-h6Reg text-primaryGrey">
                <span>Score Range</span>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class=" "> - </v-col>
            </v-row>
          </template>

          <template v-slot:no-data>
            <div class="text-primaryTextColor text-center w-100 t-h6Reg cell-table no-right-border">No Assignment progress available</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import _ from "lodash";
import HtmlRender from "@/components/Test/HtmlRender";
import axios from "axios";
import {
  getLocalForageItem,
  setLocalForageItem,
} from "@/services/localforageService";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
export default {
  name: "AssignmentProgressComponent",
  props: ["batchId"],
  async created() {
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenAssignmentProgressTabOpen, {
      courseId: this.courseId,
      batchId: this.batchId,
    });
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenAssignmentProgressTabTimeButtonSwitched, {
      courseId: this.courseId,
      batchId: this.batchId,
      isCurrent: true,
    });
    this.filterCurrentAssignments(true);
  },
  components: {},
  data: () => ({
    showData: true,
    current: true,
    headers: [
      {
        title: "Student Name",
        sortable: true,
        value: "studentName",
        width: "200px",
        minWidth: "200px",
        headerProps: { class: "text-primaryTextColor t-1remReg header-table " },
        cellProps: { class: "text-primaryTextColor t-h6Reg cell-table" },
      },
    ],
  }),
  computed: {
    ...mapState(["courseId"]),
    assignmentProgressHeaders: {
      get() {
        if (!this.showData) {
          return [];
        } else {
          let assignmentHeaders = [];
          // assignmentHeaders.concat({
          //   title: 'Student Name',
          //   value: 'name'
          // })

          assignmentHeaders = _.map(
            this.$store.state.assignmentReports.columns,
            (column, index) =>({
              title: column["colLabel"],
              align: "start",
              sortable: true,
              value: column["colKey"],
              width: "150px",
              minWidth: "150px",
              headerProps: { class: "text-primaryTextColor t-1remReg header-table " + (index == this.$store.state.assignmentReports.columns.length -1 ? ' no-right-border': '')},
              cellProps: { class: "text-primaryTextColor t-h6Reg cell-table " + (index == this.$store.state.assignmentReports.columns.length -1 ? ' no-right-border': '')},
            })
          );
          // assignmentHeaders = _.map(
          //   this.$store.state.assignmentReports.columns,
          //   (column) => ({
          //     title: column["colLabel"],
          //     value: column["colKey"],
          //   })
          // );

          console.log("asmt header", assignmentHeaders);
          return {
            title: "Assignments",
            align: "center",
            children: assignmentHeaders,
            headerProps: { class: "text-primaryTextColor t-1remReg header-table no-right-border" },
          };
        }
      },
    },
    assignmentProgressData: {
      get() {
        return this.assignmentProgressHeaders.children.length> 0?  this.$store.state.assignmentReports.studentAssignmentList : [];

        // return this.showRealDataInTeacherView ? this.$store.state.topicWiseSummaryData.studentList : [];
      },
    },
  },
  methods: {
    filterCurrentAssignments(current) {
      this.current = current;
      const reqData = {
        data: {
          startDateTimestamp: 0,
          endDateTimestamp: 0,
        },
        batchId: this.batchId,
      };
      if (this.current) {
        reqData.data.startDateTimestamp = this.getStartTimeStamp(
          moment().subtract(7, "days")
        );
        reqData.data.endDateTimestamp = this.getStartTimeStamp(
          moment().subtract(7, "days").add(1, "year")
        );
      } else {
        reqData.data.startDateTimestamp = this.getStartTimeStamp(
          moment().subtract(7, "days").subtract(1, "year")
        );
        reqData.data.endDateTimestamp = this.getStartTimeStamp(
          moment().subtract(7, "days")
        );
      }
      console.log("filterCurrentAssignments", reqData);
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenAssignmentProgressTabTimeButtonSwitched, {
        courseId: this.courseId,
        batchId: this.batchId,
        isCurrent: this.current,
      });
      this.$store.commit("clearTeacherAssignments");
      this.$store.dispatch("loadAssignmentReport", reqData);
    },
    getStartTimeStamp(date) {
      let localDate = new Date(date); // current local date and time
      return Math.floor(localDate.getTime() / 1000);
    },
  },
};
</script>

<style scoped>
.assignment-card {
  background: rgb(var(--v-theme-report-subCardBg));
}

.assignment-btn {
  border-radius: 12px !important;
  /* border: 2px solid rgb(var(--v-theme-primaryGrey)); */
  background: rgb(var(--v-theme-report-subCardBg));
  /* color: rgb(var(--v-theme-primaryGrey)); */
  /* background: transparent; */
  /* padding: 10px 15px !important; */
  cursor: pointer;
}

.assignment-activity-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
}
</style>

<style>
.table-bg {
  background-color: rgb(var(--v-theme-report-subCardBg));
  color: #ffffff !important;
  border-radius: 8px !important;
}

/* .table-bg > .v-table > .v-table__wrapper> table > thead > tr > th  {
  font-size: 600 !important;
} */

.table-bg .v-table__wrapper {
  scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important;
  border-radius: 10px !important;
  overflow: auto !important;
}

.table-bg .v-table__wrapper::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.table-bg .v-table__wrapper::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.table-bg .v-table__wrapper::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.table-bg .v-table__wrapper::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}


</style>
