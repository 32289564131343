<template>
    <v-row class="ma-0 pt-4 px-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }" v-if="breadCrumbs.length > 1">
        <v-col cols="12" class="ma-0 pa-0">

            <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
                <template v-slot:prepend>
                    <v-btn v-if="breadCrumbs.length > 1" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
                        size="x-small" @click="back()" icon="mdi-chevron-left">
                    </v-btn>
                    <v-icon icon="mdi-trophy-outline" color="primaryPurple"></v-icon>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

    </v-row>

    <v-layout class="mb-0 pb-0" v-if="dataLoaded && !showError" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
        <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

            <v-row class="mt-0 pt-0">

                <v-col class=" mt-0 pt-0 justify-center align-center text-center"
                    :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
                    <v-container fluid="" class="px-2 mt-0 pt-0">
                        <v-row justify="center" class="mb-0" align="center">
                            <v-col cols="12  mb-0 pb-0 ">
                                <v-row justify="center">
                                    <v-col cols="12" sm="8" md="6" lg="4" xl="3">

                                        <v-hover v-slot="{ isHovering, props }">

                                            <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                                                class=" py-1 testCard py-lg-9 py-xl-12" v-bind="props">
                                                <v-card-text>
                                                    <v-row justify="start" justify-md="center" class="small-card"
                                                        align="center">
                                                        <v-col cols="auto" md="12" class="">
                                                            <v-avatar size="large">
                                                                <v-img src="@/assets/file.png"></v-img>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="8" md="10" class="">
                                                            <v-row justify="start" justify-md="center" class="small-card">
                                                                <span class="t-h6 text-center text-primaryTextColor">
                                                                    {{ quizTemplate.templateName }}
                                                                </span>
                                                            </v-row>

                                                        </v-col>
                                                    </v-row>



                                                    <v-row justify="start" justify-md="center"
                                                        class="mt-5  small-card align-center" align="center"
                                                        style="margin-left: 0px;">
                                                        <v-col cols="auto">
                                                            <v-avatar size="x-small">
                                                                <v-img src="@/assets/challenge_templates/clock.png"></v-img>
                                                            </v-avatar>
                                                            <span class="t-subtitle text-primaryTextColor">
                                                                {{ quizTemplate.durationInMinutes }} mins
                                                            </span>


                                                        </v-col>
                                                        <v-col offset="0" offset-sm="1" cols="auto" class="">
                                                            <v-avatar size="x-small">
                                                                <v-img src="@/assets/challenge_templates/q.png"></v-img>
                                                            </v-avatar>
                                                            <span class="t-subtitle text-primaryTextColor">
                                                                {{ quizTemplate.mediumQuestions + quizTemplate.hardQuestions
                                                                    +
                                                                    quizTemplate.easyQuestions }}
                                                                questions
                                                            </span>

                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                            </v-card>
                                        </v-hover>
                                    </v-col>

                                </v-row>

                            </v-col>
                        </v-row>
                        <v-row class=" my-12">
                            <v-divider color="white">

                            </v-divider>
                        </v-row>
                        <v-row justify="center" class=" mt-0 pt-0 mb-2 mb-md-4">
                            <v-col cols="auto" class="t-h5 mt-0 pt-0 text-primaryTextColor">
                                Getting Challenge Ready...
                            </v-col>
                        </v-row>

                        <v-row justify="center" class=" mx-sm-2 ">
                            <v-col cols="5" md="4" lg="3" xl="2" class="pa-0">
                                <v-card elevation=0 class="py-6 py-md-12 fill-height testCard px-3">
                                    <v-card-text class="">


                                        <v-row justify="center" class="">
                                            <v-col cols="12" class="text-center my-0 py-0 ">

                                                <v-avatar class="my-0 py-0 text-primaryTextColor" :class="{
                                                    't-h4': $vuetify.display.mdAndUp,
                                                    't-h6': $vuetify.display.mdAndDown
                                                }" :size="$vuetify.display.mdAndUp ? '90' : '60'" color="#844DDE">

                                                    {{ getInitials(userName) }}
                                                </v-avatar>


                                            </v-col>
                                            <v-col class="my-0 mt-6 py-0 text-primaryTextColor" col="12">
                                                {{ userName }}
                                            </v-col>
                                        </v-row>



                                    </v-card-text>

                                </v-card>
                            </v-col>

                            <v-col cols="2" md="1" class="pa-0">
                                <v-card elevation=0 class=" fill-height testCardTransparent mx-0 px-0 d-flex align-center ">
                                    <v-card-text class="mx-0 px-0 d-flex flex-column">


                                        <v-row justify="center" class=" my-0  py-0  mx-0 px-0" align="center">
                                            <v-col cols="auto"
                                                class=" my-0  py-0 mx-0 px-0 text-center t-1rem text-primaryTextColor">

                                                VS


                                            </v-col>
                                        </v-row>



                                    </v-card-text>

                                </v-card>

                            </v-col>
                            <v-col cols="5" md="4" lg="3" xl="2" class="pa-0">
                                <v-card elevation=0 class="py-6 py-md-12 fill-height testCard px-3">
                                    <v-card-text class="">


                                        <v-row justify="center" class="">
                                            <v-col cols="12" class="text-center my-0 py-0">

                                                <v-avatar class="my-0 py-0" :size="$vuetify.display.mdAndUp ? '90' : '60'"
                                                    color="primaryGreen"
                                                    v-if="acceptChallengeInfo.hostName != null && acceptChallengeInfo.hostName == 'Mento'"
                                                    :class="{
                                                        't-h4': $vuetify.display.mdAndUp,
                                                        't-h6': $vuetify.display.mdAndDown
                                                    }">

                                                    <v-img src="@/assets/mento_ai.png">

                                                    </v-img>
                                                </v-avatar>

                                                <v-avatar v-else class="my-0 py-0 text-primaryTextColor " :class="{
                                                    't-h4': $vuetify.display.mdAndUp,
                                                    't-h6': $vuetify.display.mdAndDown
                                                }" :size="$vuetify.display.mdAndUp ? '90' : '60'" color="primaryGreen">

                                                    {{ getInitials(acceptChallengeInfo.hostName != null ?
                                                        acceptChallengeInfo.hostName :
                                                        'HostName') }}
                                                </v-avatar>


                                            </v-col>
                                            <v-col class="my-0 mt-6 py-0 text-primaryTextColor" col="12">
                                                {{ acceptChallengeInfo.hostName != null ? acceptChallengeInfo.hostName :
                                                    'HostName'
                                                }}
                                            </v-col>
                                        </v-row>



                                    </v-card-text>

                                </v-card>
                            </v-col>

                        </v-row>
                    </v-container>

                </v-col>

            </v-row>

        </v-container>
    </v-layout>

    <v-layout class="mb-0 pb-0" v-if="dataLoaded && showError" :class="{ 'layoutLarge': true }">
        <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

            <v-row class="mt-0 pt-0">

                <v-col class=" mt-0 pt-0 justify-center align-center text-center" :class="{ 'd-flex flex-column': true }">
                    <v-container fluid="" class="px-2 mt-0 pt-0">
                        <span class="t-h5 text-primaryTextColor">
                            {{ this.error }}
                        </span>
                    </v-container>

                </v-col>

            </v-row>

        </v-container>
    </v-layout>
</template>
          
<script>
import { getLoggedInUserName } from "@/services/authService";
import { setLocalForageItem } from "@/services/localforageService";
import axios from "axios";
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";



export default {
    name: 'ParticipantWaitingRoom',
    data: () => ({
        dataLoaded: false,
        roomCode: "",
        downloadingTest: null,
        testDownloaded: false,
        challengeData: null,
        currentGeneratedChallenge: null,
        error: null,
        status: "",
        breadCrumbs: [],
        showError: false

    }),
    components:
    {


    },
    async unmounted() {
        console.log('destroyed')
        if (this.ablyChannel != null) {
            this.ablyChannel.presence.unsubscribe()

        }
    },
    async created() {
        let sounds = {
        selfIncrease: new Audio(require("@/assets/audio/me_correct.mp3")),
        selfDecrease: new Audio(require("@/assets/audio/me_incorrect.mp3")),
        otherUserIncrease: new Audio(require("@/assets/audio/other_correct.mp3")),
        otherUserDecrease: new Audio(require("@/assets/audio/other_incorrect.mp3"))
      }

      this.$store.commit("updateChallengeSounds", sounds)
        let type = this.$route.query.type;
        this.dataLoaded = false

        if (type == "ai") {
            let challengeId = ""
            if ("challengeId" in this.$route.params) {
                challengeId = this.$route.params.challengeId
                this.creatNodes(type, challengeId)

            }
            recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeWaitingRoomScreenOpen, {
                challengeType: "aigenerated",
                challengeId: challengeId
            });
            this.dataLoaded = false
            this.$store.commit("acceptChallengeInfoLoadedFromServer", false);
            this.$store.commit("challengeGeneratedFromServer", false);

            await this.$store.dispatch("acceptAIChallenge", { challengeId: challengeId })
            const res = this.acceptChallengeInfo
            if (res.success == false) {
                console.log(res.message);
                this.error = res.message
                this.showError = true
                this.dataLoaded = true
                return
            }
            this.dataLoaded = true
            this.$store.commit("aiInstanceStepsLoadedFromServer", false);

            await this.$store.dispatch("loadAIInstanceSteps", {
                challengeId: challengeId, testId: this.acceptChallengeInfo.testId,
                testInstanceId: this.acceptChallengeInfo.testInstanceId
            })

            await this.generateAIChallenge()
            if (this.currentGeneratedChallenge.success == false) {
                this.downloadingTest = false
            }
            else {
                await this.updateTestData()
                recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeStarted, {
                    challengeType: "aigenerated",
                    challengeId: challengeId,
                    testId: this.acceptChallengeInfo.testId, 
                    testInstanceId: this.acceptChallengeInfo.testInstanceId
                });
                this.$store.commit("updateUserType", "participant")

                this.$store.commit("updateTestRoutingAllowed", true)
                this.$router.replace('/challenge/challengestartingscreen')
            }
        }

        if (type == "room") {


            if (this.ablyRealTime != null) {
                this.ablyRealTime.close()
            }
            let code = this.$route.query.code;
            this.roomCode = code
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeParticipantWaitingRoomScreenOpen, {
                challengeCode: code,
                challengeType: "usergenerated"
            });
            this.creatNodes(type, code)

            this.dataLoaded = false
            this.$store.commit("acceptChallengeInfoLoadedFromServer", false);

            await this.$store.dispatch("acceptChallenge", { code: this.roomCode })
            const res = this.acceptChallengeInfo
            if (res.success == false) {
                console.log(res.message);
                this.showError = true
                this.error = res.message
                this.dataLoaded = true

                return
            }
            this.dataLoaded = true

            this.$store.commit("clearCurrentTestState")
            this.$store.commit("challengeGeneratedFromServer", false);
            const self = this

            this.$store.commit("updateAblyRealtime")
            this.ablyRealTime.connection.on('connected', async () => {
                console.log('Connected to Ably!');
                this.$store.commit("updateAblyChannel", code)
                this.ablyChannel.presence.enter({
                    name: getLoggedInUserName(),
                    ready: false,
                    participant: true,
                    host: false,
                    submitted: false
                })


                this.ablyChannel.subscribe(async (message) => {
                    this.ablyChannel.presence.get(async (err, members) => {
                        if (err) {
                            return console.error(`Error retrieving presence data: ${err}`);
                        }
                        self.$store.commit("updateAblyParticipants", members)
                    })
                    console.log(message);
                    if (message.name == "testInfo") {
                        this.challengeData = message.data
                        await this.generateChallenge()
                        if (this.currentGeneratedChallenge.success == false) {
                            this.downloadingTest = false
                        }
                        else {
                            await this.updateTestData()
                        }
                    }

                    if (message.name == "startTest") {
                        this.subscribeForHostChallengeStartEvent(message)
                    }
                });

            });
        }
    },
    computed: {
        ...mapState(["selectedChallenge", "ablyRealTime", "ablyParticipants", "startTestInfo", "acceptChallengeInfo", "ablyChannel"]),
        quizTemplate: {
            get() {
                return this.acceptChallengeInfo.quizTemplate
            }
        },
        userName: {
            get() {
                return this.$store.state.userState.name
            }
        }, displayBreadCrumbs: {
            get() {
                return [this.breadCrumbs[this.breadCrumbs.length - 1]]
            }
        }
    },

    watch: {

    },

    methods: {
        async updateTestData() {
            if (!this.testDownloaded) {
                this.downloadingTest = true
                this.status = "Downloading Test"

                const testJson = await axios.get(this.currentGeneratedChallenge.testurl)
                // console.log(testJson);
                this.$store.commit("setTestJson", testJson.data)
                this.$store.commit("updateQResponsesState")


                setLocalForageItem('qDurations', [])
                setLocalForageItem('qResponses', [])
                setLocalForageItem('serverLastAttemptSavedTimeStamp', '')
                this.downloadingTest = false
                this.testDownloaded = true
                this.status = "Waiting For host to start"
                if (this.ablyChannel != null) {
                    this.ablyChannel.presence.update({
                        name: getLoggedInUserName(),
                        ready: true,
                        participant: true,
                        host: false,
                        submitted: false

                    })
                }

            }
        },

        async generateChallenge() {
            this.$store.commit("updateCurrentTestId", this.challengeData.testId)
            this.$store.commit("updateCurrentChallengeId", this.challengeData.challengeId)

            await this.$store.dispatch("generateChallengeForParticipant", {
                challengeId: this.challengeData.challengeId,
                testId: this.challengeData.testId,
                type: "room"
            })
            this.currentGeneratedChallenge = this.startTestInfo

        },
        async generateAIChallenge() {
            this.$store.commit("updateCurrentTestId", this.acceptChallengeInfo.testId)
            this.$store.commit("updateCurrentChallengeId", this.acceptChallengeInfo.challengeId)

            await this.$store.dispatch("generateChallengeForParticipant", {
                challengeId: this.acceptChallengeInfo.challengeId,
                testId: this.acceptChallengeInfo.testId,
                type: "ai"
            })
            this.currentGeneratedChallenge = this.startTestInfo

        },
        getTestStatus() {
            if (this.downloadingTest == null) {
                return ""
            }
            if (this.downloadingTest == true) {
                return "Downloading Challenge"
            }
            if (this.testDownloaded) {
                return ""
            }
        },
        async subscribeForHostChallengeStartEvent(message) {
            console.log("startTest", message);
            this.$store.commit("updateUserType", "participant")

            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeParticipantChallengeStarted, {
                challengeId: this.acceptChallengeInfo.challengeId,
                testId: this.currentGeneratedChallenge.testId,
                testInstanceId: this.currentGeneratedChallenge.testInstanceId
            });
            this.$store.commit("updateTestRoutingAllowed", true)
            this.$router.replace('/challenge/challengestartingscreen')


        },
        creatNodes(type, data) {
            this.breadCrumbs.push(
                {
                    title: "Challenge",
                    disabled: false,
                    parentNodeIdKeyinChildNodesMap: "challenge",
                    pageRoute: `/challenge`

                });
            this.breadCrumbs.push(
                {
                    title: "Join",
                    disabled: false,
                    parentNodeIdKeyinChildNodesMap: "join",
                    pageRoute: `/challenge/joinchallenge`

                });
            if (type == "ai") {
                this.breadCrumbs.push(
                    {
                        title: "Participant Waiting Room",
                        disabled: false,
                        parentNodeIdKeyinChildNodesMap: "participantwaitingroom",
                        pageRoute: `/challenge/participantwaitingroom/${data}?type=ai`

                    });
            }
            if (type == "room") {
                this.breadCrumbs.push(
                    {
                        title: "Participant Waiting Room",
                        disabled: false,
                        parentNodeIdKeyinChildNodesMap: "participantwaitingroom",
                        pageRoute: `/challenge/participantwaitingroom?code=${data}&type=room`

                    });
            }

        },
        back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
            this.$router.replace(page)
        },
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        }
    }
}
</script>
          
<style scoped>
.testCard {
    border-radius: 12px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background: rgb(var(--v-theme-cardBackgroundColor));

}

.testCardTransparent {
    background-color: transparent;

}

.start-bg {
    background: #D1FFF5;
}

.join-bg {
    background: #FFE3D5;
}

.roomCodeCard {
    border-radius: 12px;
    background-color: rgb(var(--v-theme-navColor));
    color: black;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}


.heightBread {
    height: 80px;
}

.bgwhite {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
    border-radius: 10px;
}

.layoutLarge {
    height: calc(100% - 80px);
}
</style>
          