<template>
  <!-- Quiz Loader -->
  <v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="downloadingTest"
    class="align-center justify-center px-2">
    <v-card elevation="0" class="start-quiz-loading d-flex justify-center align-center pa-2">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
            src="@/assets/mento_loading_icon.png"></v-img>
        </v-col>
        <v-col cols="12" class="start-quiz-loading-title text-center">
          {{ downloadingTestTitle }}
        </v-col>
        <v-col class="mt-2" cols="6">
          <v-progress-linear color="progress" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>

  <!-- Practice Report -->
  <v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="showPracticeReport"
    class="align-center justify-center px-2">
    <v-card elevation="0" class="practice-report-dialog pa-2">

      <v-card-actions class="justify-end pa-0">
        <v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-4" density="compact" variant="outlined"
          color="white" @click="closePractice(!checkTheProgress())">
        </v-btn>
      </v-card-actions>

      <v-card-text class="pa-0">
        <v-row justify="center" align="center">

          <v-col cols="12" class="pt-0 mt-n3 mt-sm-0 pb-sm-3 pb-0">
            <v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
              src="@/assets/mento_loading_icon.png"></v-img>
          </v-col>

          <template v-if="checkTheProgress()">

            <v-col cols="12" class="start-quiz-loading-title text-center">
              {{ practiceReport.quizMessageTitle }}
            </v-col>
            <v-col cols="12" class="start-quiz-loading-subtitle  pt-0 pb-sm-6 text-center">
              {{ practiceReport.quizMessageSubtitle }}
            </v-col>
            <v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
              @click="startTest({ activityId: practiceReport.recommendedActivityId }, 'skillUpgradeQuiz', true)">
              Start quiz
            </v-btn>

          </template>

          <template v-else>
            <v-col cols="12" class="start-quiz-loading-title text-center">
              {{ practiceReport.practiceMessageTitle }}
            </v-col>
            <v-col cols="12" class="start-quiz-loading-subtitle  pt-0 pb-sm-6 text-center">
              {{ practiceReport.practiceMessageSubtitle }}
            </v-col>
            <v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
              @click="startPractice(true)">
              Start practice
            </v-btn>
          </template>
        </v-row>

      </v-card-text>
    </v-card>
  </v-overlay>

  <!-- NAVBAR -->
  <v-row class="ma-0 pt-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="space-between">
    <!-- BREADCRUMB -->
    <v-col cols="auto" class="ma-0 pa-0">
      <v-breadcrumbs :items="breadcrumbItems" density="compact"
        class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:prepend>
          <v-btn v-if="breadcrumbItems.length > 0" color="#26253A" class="ma-0 mr-2 text-white" size="x-small"
            icon="mdi-chevron-left" @click="back()"></v-btn>
        </template>
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col>
    </v-col>
    <!-- INFO COMPONENT -->
    <v-col cols="auto" class="mr-4">
      <info-component :infoTopic="infoTopic"></info-component>
    </v-col>
  </v-row>

  <v-container fluid class="ma-0 pa-4">
    <div :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''"
      v-if="activityLoaded && newLearnNodeActivity">

      <div>
        <v-row align="center" justify="space-between" class="mb-4">
          <v-col cols="12">
            <span class="t-h5 text-white font-weight-bold">Keep practicing to learn and get ready for next skill level</span><br />
          </v-col>
        </v-row>

        <div :class="newLearnNodeActivity.practiceProgressDetails?.recommended ? 'recommended-container' : ''">
          <div :class="newLearnNodeActivity.practiceProgressDetails?.recommended ? 'recommended-box' : ''">
            <v-row v-if="newLearnNodeActivity.practiceProgressDetails?.recommended" align="center" justify="center"
              no-gutters>
              <v-col>
                <v-avatar size="20" color="transparent" class="pa-1">
                  <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="ml-1 mr-1">
                <span class="ai-chip">AI</span>
              </v-col>
              <v-col cols="auto">
                <span class="text-white text-recommended">
                  Recommended
                </span>
              </v-col>
            </v-row>
          </div>

          <v-card elevation="0" class="mt-2 mb-9 bx "
            :class="newLearnNodeActivity.practiceProgressDetails?.recommended ? 'recommended-border recommended-card' : ''">

            <v-card-text class="pa-3">
              <v-row class="ma-0 pa-0" align="center" justify="space-between" dense>

                <v-col cols="12">
                  <v-row align="center" justify="start" class="pb-4">
                    <v-col>
                      <span class="t-subtitle text-purple-grey text-recommended  pr-2">Your Skill Level:</span>

                      <span class="pr-2">
                        <v-avatar size="30" class="text-center">
                          <v-img height="30"
                            :src="getSkillLevelIcon(newLearnNodeActivity.skill, newLearnNodeActivity.skill, 'Achieved')" />
                        </v-avatar>
                      </span>

                      <span class="t-subtitle text-white text-small pr-2">{{ getSkillName(newLearnNodeActivity.skill)
                      }}</span>

                    </v-col>
                  </v-row>
                  <v-divider color="white" class="mt-0 mb-4"></v-divider>
                </v-col>

                <v-col cols="auto" class="mr-sm-3">
                  <v-row align="center" justify="center">
                    <v-col cols="auto">
                      <v-avatar size="30" class="text-center">
                        <v-img height="30"
                          :src="getSkillLevelIcon(newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill, newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill, 'Achieved')" />
                      </v-avatar>
                    </v-col>
                    <v-col v-show="smAndUp" cols="auto" class="pl-0">
                      <span class="t-xs text-white font-weight-bold">{{
                        getSkillName(newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill) }}</span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="mr-sm-3">
                  <v-row>

                    <v-col>
                      <v-row align="center">

                        <v-col cols="12" class="">
                          <v-progress-linear
                            :model-value="getPercentage(newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel)"
                            :max="totalMarks" rounded height="4" bg-color="navColor" color="primaryPurple">
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>
                </v-col>

                <v-col cols="auto" class="mr-sm-3">
                  <v-row align="center" justify="center">
                    <v-col cols="auto">
                      <v-avatar size="30" class="text-center">
                        <v-img height="30"
                          :src="getSkillLevelIcon(newLearnNodeActivity.practiceProgressDetails.nextPracticeSkill, newLearnNodeActivity.practiceProgressDetails.nextPracticeSkill, 'Achieved')" />
                      </v-avatar>
                    </v-col>
                    <v-col v-show="smAndUp" cols="auto" class="pl-0 mr-3">
                      <span class="t-xs text-white font-weight-bold">{{
                        getSkillName(newLearnNodeActivity.practiceProgressDetails.nextPracticeSkill) }}</span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="auto" class="">
                  <v-btn v-if="mdAndUp" prepend-icon="mdi-play" class="t-btn text-white activity-btn activity-btn-desktop text-none"
                    density="default" variant="flat" @click="startPractice()">
                    <template v-slot:prepend>
                      <v-icon color="white"></v-icon>
                    </template>
                    Practice
                  </v-btn>
                  <v-btn v-else-if="smAndUp" icon="mdi-play" class="activity-btn text-white text-none" variant="elevated"
                    density="default" @click="startPractice()"></v-btn>
                  <v-btn v-else icon="mdi-play" class="activity-btn activity-btn-mobile text-white text-none"
                    variant="elevated" density="compact" @click="startPractice()"></v-btn>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </div>

      </div>

      <!-- lgAndUp -->
      <div v-if="lgAndUp">
        <v-row align="center" justify="space-between" class="mb-4">
          <v-col>
            <span class="t-h5 text-white font-weight-bold">Take a quiz to unlock skill level </span><br />
          </v-col>

          <v-col cols="auto">
            <v-btn prepend-icon="mdi-poll" class="t-btn text-white report-btn text-none" density="default" variant="flat"
              @click="getAllReports(newLearnNodeActivity.topicId)">
              <template v-slot:prepend>
                <v-icon color="white"></v-icon>
              </template>
              All Reports
            </v-btn>
          </v-col>

          <v-col v-if="newLearnNodeActivity.skill != 'notset' && !newLearnNodeActivity.showProgress" cols="auto"
            class="pt-1 mr-3 pb-1 br" style="background: #26253A;">
            <v-row align="center" justify="end">
              <v-col cols="auto" class="my-1">
                <v-img width="33"
                  :src="getSkillLevelIcon(newLearnNodeActivity.skill, newLearnNodeActivity.skill, 'Achieved')" />
              </v-col>
              <v-col cols="auto" class="pl-0">
                <span class="t-subtitle text-white text-small">{{ getSkillName(newLearnNodeActivity.skill) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card v-if="newLearnNodeActivity.skillVerificationQuiz" elevation="0" class="my-2 pa-0 bx" :class="{
          'recommended-border': newLearnNodeActivity.skillVerificationQuiz.recommended,
        }">
          <v-card-text class="pa-3">
            <v-row class="ma-0 pa-0" align="center" justify="start" dense>
              <v-col class="pa-0" cols="1">
                <v-avatar size="45" class="text-center">
                  <v-img height="45" :src="getIconSrc('assets/images/skill_verification_wand.svg')" />
                </v-avatar>
              </v-col>
              <v-col cols="auto">
                <span class="t-h6 text-white font-weight-bold">{{
                  newLearnNodeActivity.skillVerificationQuiz &&
                  newLearnNodeActivity.skillVerificationQuiz.name
                  ? newLearnNodeActivity.skillVerificationQuiz.name
                  : ""
                }}</span>
              </v-col>
              <v-col cols="auto" v-if="newLearnNodeActivity.skillVerificationQuiz.recommended">
                <v-row align="center" justify="center" class="pa-1 recommended" no-gutters>
                  <v-col cols="1">
                    <v-avatar size="20" color="transparent" class="pa-1">
                      <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="auto" class="ml-3 mr-1">
                    <span class="ai-chip">AI</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-white text-recommended">
                      Recommended
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pl-0">
                <span class="t-subtitle text-white text-small">{{ getSkillName(newLearnNodeActivity.skill) }}</span>
              </v-col>
              <v-col cols="auto">
                <v-btn prepend-icon="mdi-play" class="t-btn text-white activity-btn activity-btn-desktop text-none" density="default"
                  variant="flat" @click="startTest(newLearnNodeActivity.skillVerificationQuiz, 'skillVerificationQuiz')">
                  <template v-slot:prepend>
                    <v-icon color="white"></v-icon>
                  </template>
                  Start
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <template v-for="(item, i) in newLearnNodeActivity.skillUpgradeQuiz" :key="i">
          <v-card elevation="0" class="my-2 pa-0 bx" :class="{
            'recommended-border': item.recommended,
          }">
            <v-card-text class="pa-3">
              <v-row class="ma-0 pa-0" align="center" justify="start" dense>
                <v-col class="pa-0" cols="1">
                  <v-avatar size="45" class="text-center">
                    <v-img height="45" :src="getSkillLevelIcon(
                      item.skillLevelNeededToAchiveMilestone,
                      newLearnNodeActivity.skill,
                      item.status
                    )
                      " />
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <span class="text-white font-weight-bold t-h6">{{
                    item.skillLevelNeededToAchiveMilestone
                    ? getCapitalizedText(
                      item.skillLevelNeededToAchiveMilestone
                    )
                    : ""
                  }}</span>
                </v-col>
                <v-col cols="auto" v-if="item.recommended">
                  <v-row align="center" justify="center" class="pa-1 recommended" no-gutters>
                    <v-col cols="1">
                      <v-avatar size="20" color="transparent" class="pa-1">
                        <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="auto" class="ml-3 mr-1">
                      <span class="ai-chip">AI</span>
                    </v-col>
                    <v-col cols="auto">
                      <span class="text-white text-recommended">
                        Recommended
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                
                <v-col cols="auto">
                  <v-btn prepend-icon="mdi-play" class="t-btn text-white activity-btn activity-btn-desktop text-none" density="default"
                    variant="flat" @click="startTest(item, 'skillUpgradeQuiz')">
                    <template v-slot:prepend>
                      <v-icon color="white"></v-icon>
                    </template>
                    Start
                  </v-btn>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </div>

      <!-- smAndUp -->

      <div v-if="smAndUp && !lgAndUp">
        <v-row align="center" justify="space-between" class="mb-4">
          <v-col>
            <span class="t-h5 text-white font-weight-bold">Take a quiz to unlock skill level</span><br />
          </v-col>
          <v-col cols="auto">
            <v-btn prepend-icon="mdi-poll" class="t-btn text-white report-btn text-none" density="default" variant="flat"
              @click="getAllReports(newLearnNodeActivity.topicId)">
              <template v-slot:prepend>
                <v-icon color="white"></v-icon>
              </template>
              All Reports
            </v-btn>
          </v-col>
          <v-col v-if="newLearnNodeActivity.skill != 'notset' && !newLearnNodeActivity.showProgress" cols="auto"
            class="pt-1 mr-3 pb-1 br" style="background: #26253A;">
            <v-row align="center" justify="end">
              <v-col cols="auto" class="my-1">
                <v-img width="33"
                  :src="getSkillLevelIcon(newLearnNodeActivity.skill, newLearnNodeActivity.skill, 'Achieved')" />
              </v-col>
              <v-col cols="auto" class="pl-0">
                <span class="t-subtitle text-white text-small">{{ getSkillName(newLearnNodeActivity.skill) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div :class="newLearnNodeActivity.skillVerificationQuiz?.recommended ? 'recommended-container' : ''">
          <v-card v-if="newLearnNodeActivity.skillVerificationQuiz" elevation="0"
            :class="newLearnNodeActivity.skillVerificationQuiz.recommended ? 'my-2 pa-0 bx recommended-border recommended-card' : 'my-2 pa-0 bx'">
            <v-card-text class="pa-3">
              <v-row class="ma-0 pa-0" align="center" justify="start" dense>
                <v-col class="pa-0" cols="1">
                  <v-avatar size="45" class="text-center">
                    <v-img height="40" :src="getIconSrc('assets/images/skill_verification_wand.svg')" />
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <span class="text-white font-weight-bold t-h6">{{
                    newLearnNodeActivity.skillVerificationQuiz &&
                    newLearnNodeActivity.skillVerificationQuiz.name
                    ? newLearnNodeActivity.skillVerificationQuiz.name
                    : ""
                  }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn prepend-icon="mdi-play" class="t-btn text-white activity-btn activity-btn-desktop text-none" density="default"
                    v-if="mdAndUp" variant="flat"
                    @click="startTest(newLearnNodeActivity.skillVerificationQuiz, 'skillVerificationQuiz')">
                    <template v-slot:prepend>
                      <v-icon color="white"></v-icon>
                    </template>
                    Start
                  </v-btn>
                  <v-btn v-else icon="mdi-play" class="activity-btn text-white" variant="elevated" density="default"
                    @click="startTest(newLearnNodeActivity.skillVerificationQuiz, 'skillVerificationQuiz')"></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div v-if="newLearnNodeActivity.skillVerificationQuiz?.recommended" class="recommended-box">
            <v-row align="center" justify="center" no-gutters>
              <v-col>
                <v-avatar size="20" color="transparent" class="pa-1">
                  <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="ml-1 mr-1">
                <span class="ai-chip">AI</span>
              </v-col>
              <v-col cols="auto">
                <span class="text-white text-recommended">
                  Recommended
                </span>
              </v-col>
            </v-row>
          </div>
        </div>
        <template v-for="(item, i) in newLearnNodeActivity.skillUpgradeQuiz" :key="i">
          <div :class="item.recommended ? 'recommended-container' : ''">
            <v-card elevation="0"
              :class="item.recommended ? 'my-2 pa-0 bx recommended-border recommended-card' : 'my-2 pa-0 bx'">
              <v-card-text class="pa-3">
                <v-row class="ma-0 pa-0" align="center" justify="start" dense>
                  <v-col class="pa-0" cols="1">
                    <v-avatar size="45" class="text-center">
                      <v-img height="40" :src="getSkillLevelIcon(
                        item.skillLevelNeededToAchiveMilestone,
                        newLearnNodeActivity.skill,
                        item.status
                      )
                        " />
                    </v-avatar>
                  </v-col>
                  <v-col cols="auto">
                    <span class="t-h6 text-white font-weight-bold">{{
                      item.skillLevelNeededToAchiveMilestone
                      ? getCapitalizedText(
                        item.skillLevelNeededToAchiveMilestone
                      )
                      : ""
                    }}</span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <!-- <v-col v-if="item.attempted && mdAndUp" cols="auto">
                    <v-row align="center" justify="end">
                      <v-col cols="auto" class="pa-0">
                        <v-btn icon="mdi-poll" height="30" width="35" density="default" class="text-white"
                          style="background: rgba(217, 217, 217, 0.1);" variant="flat" @click="getQuizReports(item)"
                          :rounded="10" border>
                        </v-btn>
                      </v-col>
                      <v-col class="text-white t-subtitle mr-2">Report</v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="item.attempted && !mdAndUp" cols="2" md="2" sm="2">
                    <v-btn icon="mdi-poll" density="default" class="text-white text-none"
                      style="background: rgba(217, 217, 217, 0.1);" variant="flat" @click="getQuizReports(item)" border>
                    </v-btn>
                  </v-col> -->
                  <v-col cols="auto">
                    <v-btn prepend-icon="mdi-play" class="t-btn text-white activity-btn activity-btn-desktop text-none" density="default"
                      v-if="mdAndUp" variant="flat" @click="startTest(item, 'skillUpgradeQuiz')">
                      <template v-slot:prepend>
                        <v-icon color="white"></v-icon>
                      </template>
                      Start
                    </v-btn>
                    <v-btn v-else icon="mdi-play" class="activity-btn text-white text-none" variant="elevated"
                      density="default" @click="startTest(item, 'skillUpgradeQuiz')"></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div v-if="item.recommended" class="recommended-box">
              <v-row align="center" justify="center" no-gutters>
                <v-col>
                  <v-avatar size="20" color="transparent" class="pa-1">
                    <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="ml-1 mr-1">
                  <span class="ai-chip">AI</span>
                </v-col>
                <v-col cols="auto">
                  <span class="text-white text-recommended">
                    Recommended
                  </span>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </div>

      <!-- xs -->

      <div v-if="xs">
        <v-row align="center" justify="space-between" class="mb-4">
          <v-col>
            <span class="t-h5 text-white font-weight-bold">Take a quiz to unlock skill level</span><br />
          </v-col>
          <v-col cols="auto">
            <v-btn icon="mdi-poll" class="report-btn activity-btn-mobile text-white text-none" variant="elevated"
              density="compact" @click="getAllReports(newLearnNodeActivity.topicId)">
            </v-btn>
          </v-col>
          <v-col v-if="newLearnNodeActivity.skill != 'notset' && !newLearnNodeActivity.showProgress" cols="auto"
            class="pt-1 ml-3 pb-1 br" style="background: #26253A;">
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-img width="30" style="margin: 2px 0px;"
                  :src="getSkillLevelIcon(newLearnNodeActivity.skill, newLearnNodeActivity.skill, 'Achieved')" />
              </v-col>
              <v-col cols="auto" class="pl-0">
                <span class="t-subtitle text-white t-xs">{{ getSkillName(newLearnNodeActivity.skill) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div :class="newLearnNodeActivity.skillVerificationQuiz?.recommended ? 'recommended-container' : ''">
          <v-card v-if="newLearnNodeActivity.skillVerificationQuiz" elevation="0"
            :class="newLearnNodeActivity.skillVerificationQuiz.recommended ? 'my-2 pa-0 bx recommended-border recommended-card' : 'my-2 pa-0 bx'">
            <v-card-text class="pa-3">
              <v-row class="ma-0 pa-0" align="center" justify="start" dense>
                <v-col class="pa-0" cols="auto">
                  <v-avatar size="45" class="text-center">
                    <v-img height="40" :src="getIconSrc('assets/images/skill_verification_wand.svg')" />
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="pl-2">
                  <span class="text-white font-weight-bold t-xs">{{
                    newLearnNodeActivity.skillVerificationQuiz &&
                    newLearnNodeActivity.skillVerificationQuiz.name
                    ? newLearnNodeActivity.skillVerificationQuiz.name
                    : ""
                  }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn icon="mdi-play" class="activity-btn activity-btn-mobile text-white text-none" variant="elevated"
                    density="compact"
                    @click="startTest(newLearnNodeActivity.skillVerificationQuiz, 'skillVerificationQuiz')"></v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div v-if="newLearnNodeActivity.skillVerificationQuiz?.recommended" class="recommended-box">
            <v-row align="center" justify="center" no-gutters>
              <v-col>
                <v-avatar size="20" color="transparent" class="pa-1">
                  <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="auto" class="ml-1 mr-1">
                <span class="ai-chip">AI</span>
              </v-col>
              <v-col cols="auto">
                <span class="text-white text-recommended">
                  Recommended
                </span>
              </v-col>
            </v-row>
          </div>
        </div>
        <template v-for="(item, i) in newLearnNodeActivity.skillUpgradeQuiz" :key="i">
          <div :class="item.recommended ? 'recommended-container' : ''">
            <v-card elevation="0"
              :class="item.recommended ? 'my-2 pa-0 bx recommended-border recommended-card' : 'my-2 pa-0 bx'">
              <v-card-text class="pa-3">
                <v-row class="ma-0 pa-0" align="center" justify="start" dense>
                  <v-col class="pa-0" cols="auto">
                    <v-avatar size="45" class="text-center">
                      <v-img height="40" :src="getSkillLevelIcon(
                        item.skillLevelNeededToAchiveMilestone,
                        newLearnNodeActivity.skill,
                        item.status
                      )
                        " />
                    </v-avatar>
                  </v-col>
                  <v-col cols="auto" class="pl-2">
                    <span class="text-white font-weight-bold text-small">{{
                      item.skillLevelNeededToAchiveMilestone
                      ? getCapitalizedText(
                        item.skillLevelNeededToAchiveMilestone
                      )
                      : ""
                    }}</span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <!-- <v-col v-if="item.attempted" cols="auto px-0 mr-2">
                    <v-btn icon="mdi-poll" width="35" height="30" density="compact" class="text-white text-none"
                      style="background: rgba(217, 217, 217, 0.1);" variant="flat" @click="getQuizReports(item)"
                      :rounded="6" border>
                    </v-btn>
                  </v-col> -->
                  <v-col cols="auto">
                    <v-btn icon="mdi-play" class="activity-btn activity-btn-mobile text-white" variant="elevated"
                      density="compact" @click="startTest(item, 'skillUpgradeQuiz')"></v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div v-if="item.recommended" class="recommended-box">
              <v-row align="center" justify="center" no-gutters>
                <v-col>
                  <v-avatar size="20" color="transparent" class="pa-1">
                    <v-img :src="getIconSrc('assets/images/recommended.svg')"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="ml-1 mr-1">
                  <span class="ai-chip">AI</span>
                </v-col>
                <v-col cols="auto">
                  <span class="text-white text-recommended">
                    Recommended
                  </span>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
// import _ from "lodash";
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import axios from "axios";
import { getLocalForageItem, setLocalForageItem } from "@/services/localforageService";
import InfoComponent from "../infoComponent/InfoComponent.vue";

export default {
  name: "LearnActivitiesPage",
  props: ["entityId"],
  components: {
    InfoComponent
  },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  async beforeRouteUpdate(to, from) {
    // react to route changes...
    console.log("route changed to", to);
    console.log("route changed from", from);
  },
  async created() {
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);
    if (this.entityId && this.userCurrentDomain) {
      this.$store.commit("updateCurrentTopicId", this.entityId);
      this.$store
        .dispatch("loadNewLearnNodeActivity", { entityId: this.entityId })
        .then((res) => {
          if (res) {
            this.breadcrumbItems.push({
              title: res.topicName ?? "",
              disabled: false,
              href: this.entityId,
            });
            this.activityLoaded = true;
            console.log("State", this.newLearnNodeActivity);
            this.infoTopic = this.newLearnNodeActivity.topicName;
          }
          recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityScreenOpen, {
            topicId: this.newLearnNodeActivity.topicId,
            topicName: this.newLearnNodeActivity.topicName,
            currentSkill: this.newLearnNodeActivity.skill,
          });
        });
    }

    if (this.practiceReport) {
      console.log("practiceInfo is ", this.practiceReport);
    }

  },
  data: () => ({
    activityLoaded: false,
    downloadingTest: false,
    downloadingTestTitle: "Unpacking this quiz!",
    totalMarks: 100,
    currentTestId: "",
    currentTest: {
      "testurl": "",
      "testmetadata": {
        "allowSkippingQuestion": null,
        "randomizedQuestionOrder": null,
        "testDurationInMinutes": null,
        "testRemainingDurationInMinutes": null,
        "totalQuestion": null,
        "instructions": "",
        "syllabus": "",
        "sections": [
        ]

      }
    },
    currentPractice: {

    },
    skillVerificationIcon: "assets/images/skill_verification_wand.svg",
    activityItem: {
      topicId: "t_1623135116091",
      topicName: "Properties of Solids and Liquids",
      skill: "",
      skillVerificationQuiz: {
        activityId: "A1",
        name: "Word in context",
      },
      skillUpgradeQuiz: [
        {
          activityId: "A2",
          status: "",
          skillLevelNeededToAchiveMilestone: "Intermediate",
          order: 1,
          attempted: false,
        },
        {
          activityId: "A3",
          status: "",
          skillLevelNeededToAchiveMilestone: "Advanced",
          order: 2,
          attempted: false,
        },
        {
          activityId: "A4",
          status: "",
          skillLevelNeededToAchiveMilestone: "Expert",
          order: 3,
          attempted: false,
        },
      ],
    },
    infoTopic: '',
    breadcrumbItems: [],
  }),

  methods: {
    getSkillLevelIcon(skillLevel, currentSkill, status) {
      skillLevel = skillLevel.toLowerCase();
      const isAchieved = (status == 'Achieved');
      let icon = "assets/images/medal_beginner_enable.svg";
      if (skillLevel) {
        if (skillLevel == "intermediate") {
          icon = "assets/images/medal_intermediate_enable.svg";
        } else if (skillLevel == "advanced") {
          icon = "assets/images/medal_advanced_enable.svg";
        } else if (skillLevel == "mastery") {
          icon = "assets/images/medal_mastery_enable.svg";
        }
        if (currentSkill) {
          if (skillLevel == "intermediate" && (currentSkill == "beginner" || currentSkill == "notset") && !isAchieved) {
            icon = "assets/images/medal_intermediate_disable.svg";
          }
          else if (skillLevel == "advanced" && (currentSkill == "intermediate" || currentSkill == "beginner" || currentSkill == "notset") && !isAchieved) {
            icon = "assets/images/medal_advanced_disable.svg";
          }
          else if (skillLevel == "mastery" && (currentSkill == "advanced" || currentSkill == "intermediate" || currentSkill == "beginner" || currentSkill == "notset") && !isAchieved) {
            icon = "assets/images/medal_mastery_disable.svg";
          }
        } else {
          if (skillLevel == "beginner" && !isAchieved) {
            icon = "assets/images/medal_beginner_disable.svg";
          }
          else if (skillLevel == "intermediate") {
            icon = "assets/images/medal_intermediate_disable.svg";
          } else if (skillLevel == "advanced" && !isAchieved) {
            icon = "assets/images/medal_advanced_disable.svg";
          } else if (skillLevel == "mastery" && !isAchieved) {
            icon = "assets/images/medal_mastery_disable.svg";
          }
        }
      }
      return this.getIconSrc(icon);
    },
    getSkillName(skill) {
      skill = skill.toLowerCase();
      if (skill == "notset") {
        return "Get started";
      }
      else {
        return this.getCapitalizedText(skill);
      }
    },
    checkTheProgress() {
      if (!this.practiceReport.recommendedActivityId || this.practiceReport.recommendedActivityId == null || this.practiceReport.recommendedActivityId == "") {
        return false;
      }
      return true;
    },
    getQuizReports(item) {
      this.$store.commit("updateCurrentActivityId", item.activityId);
      this.$router.push('/quizreports/' + item.skillLevelNeededToAchiveMilestone + '/' + item.activityId);
    },

    /**** */
    getAllReports(item) {
      this.$router.push(`${this.$route.fullPath}/learnreports/${item}`);
    },
    getCapitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    async startTest(item, activityType, fromPractice = false) {
      if (fromPractice) {
        recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryStartQuizButtonClicked, {
          topicId: this.newLearnNodeActivity.topicId,
          topicName: this.newLearnNodeActivity.topicName,
          currentSkill: this.newLearnNodeActivity.skill,
          isReady: true,
          activityId: item.activityId,
          activityType: activityType,
          recommended: true
        });
      }
      else {
        console.log("startTest", item);
        recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityQuizStart, {
          topicId: this.newLearnNodeActivity.topicId,
          topicName: this.newLearnNodeActivity.topicName,
          currentSkill: this.newLearnNodeActivity.skill,
          isReady: true,
          activityId: item.activityId,
          activityType: activityType,
          attempted: item.attempted ? item.attempted : false,
          recommended: item.recommended
        });
      }
      this.downloadingTest = true;
      this.downloadingTestTitle = "Unpacking this quiz!"
      this.$store.commit("clearCurrentTestState")
      this.$store.commit('startTestLoadedFromServer', false)
      this.$store.commit("updateCurrentTestId", item.activityId);

      let qResponses = await getLocalForageItem('qResponses') ?? [];
      let qDurations = await getLocalForageItem('qDurations') ?? [];
      let serverLastAttemptSavedTimeStamp = await getLocalForageItem('serverLastAttemptSavedTimeStamp') ?? '';

      let body = {
        qDurations: qDurations, qResponses: qResponses, serverLastAttemptSavedTimeStamp: serverLastAttemptSavedTimeStamp,
        testType: 'quiz'
      }
      this.$store.dispatch("startTest", { testId: item.activityId, ...body })
        .then(async () => {
          this.currentTest = this.startTestInfo;
          if (this.currentTest.success == false) {
            this.downloadingTest = false;
          } else {
            const testJson = await axios.get(this.currentTest.testurl);
            // console.log(testJson);
            this.$store.commit("setTestJson", testJson.data);
            this.$store.commit("updateQResponsesState");

            await setLocalForageItem("qDurations", []);
            await setLocalForageItem("qResponses", []);
            await setLocalForageItem("serverLastAttemptSavedTimeStamp", "");
            this.downloadingTest = false;
            this.$store.commit("updateCurrentTestId", this.startTestInfo.testId);
            this.$store.commit("updateCurrentActivityId", item.activityId);
            this.currentTestId = this.startTestInfo.testId;
            this.openTestQuestion();
          }
        });
      this.$store.commit('showPracticeReport', false);
      this.$store.commit('getPracticeReport', {});
    },
    async startPractice(fromPractice = false) {
      this.downloadingTestTitle = "Unpacking this practice!";
      this.downloadingTest = true;
      let topicId = this.newLearnNodeActivity.topicId;
      if (fromPractice) {
        recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryStartNewPracticeButtonClicked, {
          topicId: this.newLearnNodeActivity.topicId,
          topicName: this.newLearnNodeActivity.topicName,
          currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
          currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
          recommended: true,
        });
      }
      else {
        recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityPracticeStart, {
          topicId: this.newLearnNodeActivity.topicId,
          topicName: this.newLearnNodeActivity.topicName,
          currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
          currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
          recommended: this.newLearnNodeActivity.practiceProgressDetails.recommended,
        });
      }

      this.$store.dispatch("startPractice", topicId)
        .then(async () => {
          this.currentPractice = this.practiceInfo;
          if (this.currentPractice.success == false) {
            console.log("not working");
            this.downloadingTest = false;
          }
          else {
            this.$store.commit("getTopicName", this.practiceInfo.testName);
            this.downloadingTest = false;
            this.$store.commit('appbarVisible', false);
            this.$store.commit('navbarVisible', false);
            this.$store.commit("showNotification", false);
            this.$store.commit("updateTestRoutingAllowed", true);
            this.$router.push(`/practicequestionpage`);
          }
        });

      this.$store.commit('showPracticeReport', false);
      this.$store.commit('getPracticeReport', {});
    },

    async openTestQuestion() {
      //call sync timer api 

      let status = "start"
      if (this.currentTest.alreadyInProgress == true) {
        status = "resume"
      }

      //   if (this.isPractice == true) {
      //     recordAnalyticsEvent(AnalyticsEvents.v2TestBeginPracticeTest, {
      //       testId: this.currentTestId,
      //       testName: this.startTestInfo.testmetadata.testName,
      //       testInstanceId: this.startTestInfo.testInstanceId
      //     });
      //   }
      //   else if (this.currentTest.alreadyInProgress == true) {
      //     recordAnalyticsEvent(AnalyticsEvents.v2TestResumeTest, {
      //       testId: this.currentTestId,
      //       testName: this.startTestInfo.testmetadata.testName,
      //       testInstanceId: this.startTestInfo.testInstanceId
      //     });
      //   }
      //   else {
      //     recordAnalyticsEvent(AnalyticsEvents.v2TestBeginTest, {
      //       testId: this.currentTestId,
      //       testName: this.startTestInfo.testmetadata.testName,
      //       testInstanceId: this.startTestInfo.testInstanceId
      //     });
      //   }

      this.$store.commit('appbarVisible', false);
      this.$store.commit('navbarVisible', false);
      this.$store.commit("showNotification", false);
      this.$store.dispatch("updateSyncTimer", { state: status }).then(() => {
        this.$store.commit('updateCurrentTestState', {
          sectionIndex: 0,
          qIndex: 0
        })
        let remainingTime = this.$store.state.syncTimerData.testRemainingDurationInSeconds
        if (remainingTime == 0) {
          //   this.startButtonDisabled = true
          if ("message" in this.syncTimerData) {
            this.currentTest.error = this.syncTimerData.message
          }
          else {
            this.currentTest.error = "Test Time is Over"
          }
        }
        else {
          this.$store.commit("updateTestRoutingAllowed", true)
          this.$router.push(`/testquestionpage?type=quiz`)

        }
        // this.startTestButtonLoading = false
      })
    },
    back() {
      recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityBackButtonClicked, {
        topicId: this.entityId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
      });
      this.$router.back();
    },
    getPercentage(score) {
      let x = score * 100;
      return x < 0 ? 0 : x;
    },
    closePractice(fromPractice) {
      let recommendedPracticeOrQuiz = fromPractice? 'practiceTest': 'skillUpgradeQuiz' ;
      recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryCloseButtonClicked, {
        topicId: this.newLearnNodeActivity.topicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
        currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
        recommendedPracticeOrQuiz: recommendedPracticeOrQuiz ,
      });

      this.$store.commit('showPracticeReport', false);
      this.$store.commit('getPracticeReport', {});
    }
  },
  computed: {
    ...mapState(["newLearnNodes", "newLearnNodeActivity", "userCurrentDomain", "syncTimerData", "practiceReport", "showPracticeReport"]),
    learnNodeActivity: {
      get() {
        return this.newLearnNodeActivity;
      },
    },
    startTestInfo: {
      get() {
        const data = this.$store.state.startTestInfo
        // console.log(data);
        return data
      }
    },
    practiceInfo: {
      get() {
        const data = this.$store.state.practiceInfo
        return data;
      }
    }
  },
  watch: {
    newLearnNodeActivity() {
      console.log("Changed", this.newLearnNodeActivity);
    },
  },
};
</script>
<style scoped>
.recommended-container {
  position: relative;
  margin-top: 40px;
}

.recommended-card {
  /* position: relative; */
}

.recommended-box {
  position: absolute;
  top: -32px;
  right: 10px;
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  padding: 5px;
  border-radius: 8px 8px 0px 0px;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
}

.activity-btn-desktop{
  width: 120px;
}
.report-btn {
  background: #23223A !important;
}

.activity-btn-mobile {
  width: 40px;
  height: 30px;
}

.main-lg {
  padding-top: 20px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}

.primary-container {
  border: 1px solid #e7e7e7;
  background: linear-gradient(90deg, #e1f5fe 0%, #e9ebff 100%);
  border-radius: 12px;
  color: white;
}

.skill-container {
  background-color: rgb(var(--v-theme-navColor));
  border-radius: 8px;
  color: black;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-navColor));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.v-btn {
  border-radius: 12px;
}

.bx {
  background-color: #26253A;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  border-radius: 12px;
}

.recommended-border {

  background: linear-gradient(#26253A, #26253A) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.dialog-bx {
  position: fixed !important;
}

.start-quiz-loading {
  background-color: #3D3D54 !important;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 300px;
  width: 480px;
}

.practice-report-dialog {
  background-color: #3D3D54 !important;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  height: 320px;
  width: 480px;
}

.start-quiz-loading-title {
  font-weight: 700;
  letter-spacing: 0.05em;
  font-size: 25px !important;
}

.start-quiz-loading-subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  color: #c4c4ce;
}

@media only screen and (max-width: 600px) {
  .start-quiz-loading {
    height: 200px;
    width: 300px;
  }

  .practice-report-dialog {
    height: 240px;
    width: 300px;
  }

  .start-quiz-loading-img {
    width: 75px !important;
  }

  .start-quiz-loading-title {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 300px) {
  .practice-report-dialog {
    height: auto;
    padding-bottom: 28px !important;
    width: 95%;
    margin: 0 auto;
  }
}



.text-small {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.8rem !important;
}

.t-xs {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.75rem !important;
}

.recommended {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  font-size: 0.64rem;
}

.ai-chip {
  background: linear-gradient(91.85deg, #7946E1 0.46%, #1E24AD 99.41%);
  color: white;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 700;
  line-height: 12.39px;
  font-size: 0.64rem !important;
  border-radius: 26px;
  padding: 4px;
}

.text-recommended {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.64rem !important;
}

.br {
  border-radius: 24.5px;
}

.heightBread {
  height: 80px;
}

.text-purple-grey {
  color: #A4A4CD !important;
}
</style>
