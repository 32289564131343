<template>
  <v-dialog v-model="domainDialog" max-width="500px" persistent>
    <template v-slot:activator="{ props }">
      <v-btn class="ml-0 text-subtitle-2" color="domain-button-color" variant="flat" rounded="xl" v-bind="props">
        {{ userCurrentDomainName }}
      </v-btn>
    </template>
    <v-card class="pa-4">
      <v-card-title class="d-flex">
        <span class="font-weight-bold text-1">Choose your goal</span>
        <v-spacer></v-spacer>
        <v-icon @click="domainDialog = false" icon="mdi-close" justify="end"></v-icon>
      </v-card-title>

      <v-card-text>
        <v-row dense class="justify-center align-center">
          <v-col cols="12">
            <v-list item-key="key" nav>
              <v-list-item v-for="(item) in domainsList" :key="item.key" :value="item" class="non-active"
                active-class="active" rounded="lg" :active="selectedDomainKey === item.key" @click="getDomain(item)">
                <template v-slot:prepend>
                  <v-avatar rounded="0" size="small">
                    <v-img v-if="item.image" height="32" :src="item.image"></v-img>
                    <v-icon v-else icon="mdi-folder"></v-icon>
                  </v-avatar>
                </template>

                <v-list-item-title class="text-subtitle-2 domain-title" v-text="item.label"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" @click="save()">Save</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { getLoggedInUserCurrentDomain } from "@/services/authService";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: "DomainSelection",
  created() {
    //console.log(`Created called ... reached #1`);
    if (!this.domainsList || this.domainsList.length == 0) {
      //console.log(`Created called ... reached #2`);
      this.$store.dispatch("loadDomainsList").then(() => {
        let domainIdFromLocalStorage = getLoggedInUserCurrentDomain();

        //if the userCurrentDomain in state is not defined but it is stored in the localStorage
        // assign the state value form the localStorage
        if (this.domainsList.length > 0
          && this.userCurrentDomain == ''
          && domainIdFromLocalStorage != '') {
          //console.log(`Created called ... reached #3`);
          //console.log(`assign the state value form the localStorage via storeUserCurrentDomainFromLocalStorage`);
          this.$store.commit("storeUserCurrentDomainFromLocalStorage", domainIdFromLocalStorage)
          this.selectedDomainKey = domainIdFromLocalStorage;
        }

        if (!this.userCurrentDomainName) {
          let domainName = this.getDomainNameFromId(this.userCurrentDomain);
          this.$store.commit("storeUserCurrentDomainName", domainName);
        }
        //console.log(`Created called ... reached #4`);
      });
    } else if (this.userCurrentDomain && !this.userCurrentDomainName) { // currentDomainId is known but not the currentDomainName 
      //console.log(`Created called ... reached #22`);
      let domainName = this.getDomainNameFromId(this.userCurrentDomain);
      this.$store.commit("storeUserCurrentDomainName", domainName);
    } else if (!this.userCurrentDomain || this.userCurrentDomain == '') { // user has not chosen his domian yet
      //console.log(`Created called ... reached #5`);
      this.domainDialog = true;
    }

    if (this.userCurrentDomain && !this.selectedDomainKey) {
      //console.log(`Created called ... reached #6`);
      this.selectedDomainKey = this.userCurrentDomain;
    }

  },
  data: () => ({
    domainDialog: false,
    selectedDomainKey: '',
    selectedDomainName: '',
  }),
  computed: {
    ...mapState(["domainsList", "userCurrentDomain", "userCurrentDomainName"]),
  },
  watch: {
    /*userCurrentDomain(newVal, oldVal) {
      console.log(`watch DomainSelection.vue| userCurrentDomain changed from ${oldVal} to ${newVal}`);
    },*/
  },
  methods: {
    selectedDomainChanged: function () {
      console.log("currently Selected Domain: ", this.selectedDomainKey);
    },
    save() {
      //console.log(`save | selectedDomainKey: ${JSON.stringify(this.selectedDomainKey)}`);
      recordAnalyticsEvent(AnalyticsEvents.v2DomainChanged,
        {
          oldDomainId: this.userCurrentDomain, newDomainId: this.selectedDomainKey,
          oldDomainName: this.userCurrentDomainName, newDomainName: this.selectedDomainName
        });
      this.$store.dispatch("storeUserCurrentDomain", { domainId: this.selectedDomainKey }).then(() => {
        this.$store.commit("storeUserCurrentDomainName", this.selectedDomainName);
        this.domainDialog = false;
      });
    },
    getDomain(item) {
      this.selectedDomainKey = item.key;
      this.selectedDomainName = item.label;
    },
    getDomainNameFromId(domainId) {
      let domianName = '';
      if (this.domainsList && this.domainsList.length > 0) {
        let val = this.domainsList.find(
          e => e.key == domainId
        );
        if (val) {
          domianName = val.label;
        }
      }
      return domianName;
    }
  }
};
</script>

<style scoped>
.active {
  background: rgb(var(--v-theme-primary)) !important;
  color: white !important;
}

.non-active {
  background: #F3F2F3;
  color: #000;
}

.domain-title {
  white-space: break-spaces;
}
</style>
