<template>
  <v-row class="otp-input-row" :class="{ 'otp-space': mdAndUp }" no-gutters>
    <v-col v-for="(digit, index) in otpDigits" :key="index" cols="auto" class="otp-input-col">
      <v-text-field hide-details="true" :style="getInputStyle(index)" style="color: black" v-model="otpDigits[index]"
        type="number" :maxlength="1" variant="elevated" :ref="`otpInput${index}`" @input="handleInput(index)"
        @keydown="handleKeyDown($event, index)" @keydown.backspace="handleBackspace(index)" @paste="handlePaste"
        @click="handleClick($event, index)" dense bg-color="#fff"
        class="otp-input noautofill-bgcolor ma-0"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp };
  },
  props: {
    showInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      otpDigits: ["", "", "", "", "", ""], // Initialize an array of 6 empty strings for 6 OTP digits
      otpRes: null,
    };
  },
  methods: {
    handleInput(index) {
      if (index > 0 && !this.otpDigits[index - 1]) {
        return; // Return early if the previous box is not filled
      }

      const inputValue = this.otpDigits[index];
      if (inputValue && index < this.otpDigits.length - 1) {
        const nextInput =
          this.$refs[`otpInput${index + 1}`][0].$el.querySelector("input");
        nextInput.focus(); // Move focus to the next input field
      }

      // Handle OTP submission or further actions if needed
      const otp = this.otpDigits.join("");
      if (otp.length === this.otpDigits.length) {
        this.$emit("otp-change", otp);
      }
    },

    handleKeyDown(event, index) {
      if (this.otpDigits[index] && event.key !== "Backspace") {
        event.preventDefault(); // Prevent the default behavior when the box is already filled
      }
    },
    handleBackspace(index) {
      if (!this.otpDigits[index] && index > 0) {
        const prevInput =
          this.$refs[`otpInput${index - 1}`][0].$el.querySelector("input");
        prevInput.focus(); // Move focus to the previous input field
      }
    },
    handleClick(event, index) {
      if (this.otpDigits[index] !== "") {
        event.target.select(); // Select the text in the input field
      }
    },
    handlePaste(event) {
      event.preventDefault(); // Prevent the default paste behavior

      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text/plain");

      // Extract only the numbers from the pasted data
      const numbersOnly = pastedData.replace(/\D/g, "");

      // Split the numbers and populate the OTP digits
      for (let i = 0; i < this.otpDigits.length; i++) {
        if (numbersOnly[i]) {
          this.otpDigits[i] = numbersOnly[i];
        } else {
          this.otpDigits[i] = "";
        }
      }

      // Emit the complete OTP if all digits are filled
      const otp = this.otpDigits.join("");
      if (otp.length === this.otpDigits.length) {
        this.$emit("otp-change", otp);
      }
    },

    getInputStyle(index) {
      if (this.showInvalid && this.otpDigits[index] !== "") {
        return "border: 2.5px solid #FF786E; border-radius: 6px;"; // Change the border color to red for incorrect OTP
      }
      return "border: 2.5px solid #FF786E00; border-radius: 6px;"; // Default background color
    },
  },
};
</script>

<style scoped>
.otp-input-row {
  justify-content: center;
}

.otp-input-col {
  margin: 2px;
}

.otp-input {
  width: 45px;
  /* Adjust the width as needed */
  text-align: center;
}

.otp-space {
  gap: 0.875rem;
}

@media only screen and (max-width: 600px) {}
</style>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
