<template>
    <v-container fluid class="assignment-card pa-8" v-if="dataReady">

        <v-form ref="form">


            <v-row>
                <v-col cols="12" md="4">
                    <v-select label="Subjects" hide-details v-model="currentSubject"
                        @update:modelValue="subjectUpdate()" theme="dark" item-title="subjectName"
                        item-value="subjectId" :items="subjectList" :rules="requiredRule"></v-select>

                </v-col>

                <v-col cols="12" md="4">
                    <v-select label="Categories" hide-details v-model="currentCategory"
                        @update:modelValue="categoryUpdate()" theme="dark" item-title="categoryName"
                        item-value="categoryId" :items="categoriesList" :rules="requiredRule"></v-select>

                </v-col>
                <v-col cols="12" md="4">
                    <v-select label="Topics" v-model="currentTopic" @update:modelValue="topicUpdate" hide-details
                        theme="dark" item-title="entityLabel" item-value="entityId" :items="topicsList"
                        :rules="requiredRule"></v-select>
                </v-col>
            </v-row>
            <v-row align="center">
                <!-- <v-col cols="12" md="4">
                    <v-text-field type="number" v-model="questionCount" hide-details label="Quesion Count" theme="dark"
                        :rules="requiredRule">

                    </v-text-field>
                </v-col> -->
                <v-col cols="12" md="4">

                    <v-select label="AI Model" v-model="aiModel" theme="dark" hide-details :rules="requiredRule"
                        item-title="name" item-value="value"
                        :items='[{ value: "chatgpt", name: "ChatGPT" }, { value: "gemini", name: "Gemini" }]'></v-select>
                </v-col>

                <v-col cols="12" md="4">
                    <v-select label="Difficulty" :disabled="currentTopic.length == 0"
                        @update:modelValue="difficultyUpdate" v-model="currentDifficulty" theme="dark" hide-details
                        :rules="requiredRule" :items='["Easy", "Medium", "Hard"]'></v-select>

                </v-col>
                <v-col cols="12" md="4">
                    <v-btn @click="getData()" height="45" width="220"
                        class="t-btn text-white  activity-btn text-none px-8">
                        Get Sample Data
                    </v-btn>

                </v-col>

                <v-col cols="12" v-if="sampleDataError.length > 0" class="text-center t-1rem">
                    {{ sampleDataError }}
                </v-col>
            </v-row>

        </v-form>
        <v-row class="my-3" v-if="questions.length > 0">
            <v-col cols="12" v-if="questions.length > 0">
                <v-divider>

                </v-divider>
            </v-col>
        </v-row>

        <v-row v-if="questions.length > 0">
            <v-col cols="12">
                <v-expansion-panels bg-color="report-subCardBg" theme="dark">
                    <v-expansion-panel bg-color="report-subCardBg" title="System" theme="dark" :text="systemPrompt">
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-col cols="12" v-if="showSystemPrompt" class="mt-6">
            <v-textarea placeholder="System Prompt" rows="1" auto-grow v-model="systemPrompt" label="System Prompt"
                variant="outlined" :rules="requiredRule"></v-textarea>
        </v-col>

        <v-row v-for="q, index in questions" :key="index" class="htmlClassTest" :id="q.id">

            <v-col cols="12">
                <v-expansion-panels bg-color="report-subCardBg" theme="dark">


                    <v-expansion-panel bg-color="report-subCardBg" title="Human" theme="dark" :text="q.data.query">
                    </v-expansion-panel>


                    <v-expansion-panel bg-color="report-subCardBg" title="Raw Response" theme="dark"
                        :text="q.data.rawResponse">
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>


            <div v-if="q.data.error.length == 0">
                <div v-for="qItem, index in q.questions" :key="index">



                    <v-col cols="12" class=" qNoClass font-weight-bold">
                        Question {{ qItem.qOrder }}
                        <span v-if="'chosenQuestionId' in q.data">
                            (SampleQuestion : {{ q.data.chosenQuestionId }})
                        </span>
                    </v-col>
                    <v-col cols="12">
                        <div v-html="getMarkDownHtml(qItem.questionText)" class="text-white" theme="dark"
                            style="white-space: pre-line;">
                        </div>
                    </v-col>
                    <v-col cols="12" v-for="option in ['a', 'b', 'c', 'd']" :key="option">
                        <div class="text-white" v-html="option + ') ' + getMarkDownHtml(qItem[option])" theme="dark"
                            style="white-space: pre-line;">

                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-white" v-html="'Answer : ' + getMarkDownHtml(qItem.answer)" theme="dark"
                            style="white-space: pre-line;">
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-white" v-html="'Solution : ' + getMarkDownHtml(qItem.solution)" theme="dark"
                            style="white-space: pre-line;">
                        </div>
                    </v-col>


                </div>
                <v-col cols="12" v-if="(index + 1) < questions.length">
                    <v-divider>

                    </v-divider>
                </v-col>
            </div>

            <div v-else>

                <v-col cols="12">
                    Error {{ q.sampleQuestionId }}
                </v-col>

            </div>
        </v-row>


        <v-row v-if="showText && customTextStatus == false">
            <v-col cols="12">
                <v-form ref="qform">
                    <v-textarea placeholder="Instructions to generate a question" rows="1"
                        @click:appendInner="customText()" auto-grow v-model="humanQuery" label="Query"
                        append-inner-icon="mdi-send" variant="outlined" @keydown.enter.prevent="customText()"
                        :rules="requiredRule"></v-textarea>
                </v-form>

            </v-col>


        </v-row>

        <v-form>


            <v-col cols="12" md="4" class="">
                <v-btn @click="getHumanPrompt()" height="45" width="220"
                    v-if="systemPrompt.length > 0 && humanPrompt.length == 0 && !customTextStatus && !loading"
                    :loading="loading" class="t-btn text-white  activity-btn text-none px-8">
                    Generate question
                </v-btn>
            </v-col>

            <v-col cols="12" v-if="showHumanPrompt">
                <v-textarea placeholder="Human Prompt" rows="1" auto-grow v-model="humanPrompt" label="Human Prompt"
                    variant="outlined" :rules="requiredRule"></v-textarea>
            </v-col>

        </v-form>
        <v-row v-if="streaming" class="streaming-box pa-2 mt-2 mx-2">

            <v-col class="htmlClassTestPre">
                <span v-html="getMarkDownHtml(streamingContent)">
                </span>
                <span>
                    <v-icon class="ic" size="17" color="secondaryBackground">mdi-circle</v-icon>
                </span>
            </v-col>

        </v-row>
        <v-col cols="12" md="5" class="">
            <v-btn @click="streamAIRespones()" height="45" 
                v-if="systemPrompt.length > 0 && humanPrompt.length > 0 && !customTextStatus && !loading"
                class="t-btn text-white  activity-btn text-none ">
                Generate with reference Question
            </v-btn>
        </v-col>
        <v-row align-content="center" justify="center" v-if="loading">
            <v-col class="text-subtitle-1 text-center" cols="12">
                Getting Question
            </v-col>
            <v-col cols="3">
                <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center" v-if="customTextStatus">
            <v-col class="text-subtitle-1 text-center" cols="12">
                Getting Question
            </v-col>
            <v-col cols="3">
                <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment';
import _ from "lodash";
import { securePostAPI } from '@/services/apiService';
import axios from 'axios';
import { getHeaders, getApiBaseUrl } from '@/services/authService';
import $ from "jquery";
import { LambdaClient, InvokeCommand, InvokeWithResponseStreamCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import { Auth } from "aws-amplify";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};

export default {
    name: 'MentoAIComponent',
    props: ["assignmentType", "batchId", "studentId"],
    data: () => ({
        dataReady: false,
        loading: false,
        currentCategory: "",
        streaming: false,
        streamingContent: '',
        currentNumberOfQuestions: 0,
        showSystemPrompt: false,
        showHumanPrompt: false,
        currentTopic: "",
        currentDifficulty: "",
        currentSubject: "",
        questionCount: 1,
        questions: [],
        usedQuestions: [],
        sampleQuestions: [],
        messages: [],
        systemMessage: "",
        humanQuery: "",
        customTextStatus: false,
        showText: false,
        requiredRule: [(v) => !!v || "Required"],
        sampleQuestionsCount: 0,
        systemPrompt: "",
        humanPrompt: "",
        sampleDataError: "",
        aiModel: 'chatgpt'
    }),
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
        return { xs, smAndUp, mdAndUp, lgAndUp };
    },
    async created() {
        this.dataReady = false
        await this.$store.dispatch("loadNewLearnNodes");
        this.dataReady = true
    },
    components: {},

    computed: {
        ...mapState(["newLearnNodes"]),
        subjectList: {
            get() {
                console.log(this.newLearnNodes);
                return this.newLearnNodes

            },
        },
        categoriesList: {
            get() {
                let data = this.subjectList.find((e) => e.subjectId == this.currentSubject)
                console.log(data);
                if (data) {
                    return data.categories

                }

                return []

            },
        },
        topicsList: {
            get() {
                let data = this.categoriesList.find((e) => e.categoryId == this.currentCategory)
                console.log(data);
                if (data) {
                    return data.entity
                }
                return []
            }

        }
    },

    methods: {
        async streamAIRespones() {
            let response = null
            this.streamingContent = ''
            this.streaming = true
            console.log("streaming");

            let id = 'q' + this.questions.length
            let sampleData = {
                "questions": [],
                "error": '',
                "rawResponse": '',
                "query": this.humanPrompt,
                "currentNumberOfQuestions": 0
            }
            this.questions.push({ data: { ...sampleData }, questions: sampleData.questions, id: id })


            this.loading = true
            let msgs = [{ role: "system", content: this.systemPrompt }, ...this.messages]
            this.showText = false

            let credentials = await Auth.currentCredentials()
            console.log(credentials);
            console.log(Auth.essentialCredentials(credentials));
            const client = new LambdaClient({
                credentials: Auth.essentialCredentials(credentials),
                region: 'us-east-1',
                httpOptions: {
                    connectTimeout: 900000,
                    timeout: 900000
                }

            });
            const input = { // InvocationRequest
                FunctionName: "mentoAITestHarness", // required
                InvocationType: "RequestResponse",
                Payload: JSON.stringify({ messages: msgs, query: this.humanPrompt, currentNumberOfQuestions: this.currentNumberOfQuestions })
            };
            const command = new InvokeWithResponseStreamCommand(input);
            const streamResponse = await client.send(command)

            this.humanPrompt = ""
            this.showHumanPrompt = false


            for await (const event of streamResponse.EventStream) {
                // Each event will likely contain the 'PayloadChunk' property 
                const chunk = event.PayloadChunk?.Payload;
                const textChunk = new TextDecoder().decode(chunk);


                const regex = /<chunk>(.*?)<\/chunk>/s;
                let match = textChunk.match(regex);


                if (match) {
                    const extractedText = match[1];
                    response = JSON.parse(extractedText.trim())
                    console.log("mmmm", extractedText);

                }

                else {
                    this.streamingContent += textChunk

                }


            }

            this.streaming = false

            this.questions[this.questions.length - 1] = { data: { ...response }, questions: response.questions, id: id }
            this.currentNumberOfQuestions = response.currentNumberOfQuestions
            this.addMessages(response)

            this.showText = true

            setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 20);

            setTimeout(() => {
                var targetElement = document.getElementById(id);
                targetElement.scrollIntoView({ behavior: 'smooth' });


            }, 200);

            this.loading = false
        },

        categoryUpdate() {
            this.currentTopic = ""
        },
        subjectUpdate() {
            this.currentCategory = ""
            this.currentTopic = ""
        },
        topicUpdate() {
            this.currentDifficulty = ""
        },
        async difficultyUpdate(val) {

        },
        async getData() {
            this.sampleDataError = ""
            const { valid } = await this.$refs.form.validate();
            if (!valid) {
                return
            }
            this.sampleQuestionsCount = 0
            this.questions = []
            let self = this
            this.messages = []
            this.systemMessage = ""
            this.systemPrompt = ""
            this.humanPrompt = ""
            this.showText = false
            this.currentNumberOfQuestions = 0

            this.showHumanPrompt = false
            this.showSystemPrompt = false

            console.log(this.currentDifficulty);
            this.$store.commit('increamentLoader')
            try {


                let url = "https://45wsfvypceirppbhpasvcp7xf40vkkeh.lambda-url.us-east-1.on.aws/getSampleData"
                let response = await axios.post(url, {
                    topicId: this.currentTopic,
                    topicName: this.topicsList.find((e) => e.entityId == this.currentTopic).entityLabel,
                    categoryName: this.categoriesList.find((e) => e.categoryId == this.currentCategory).categoryName,
                    categoryId: this.currentCategory,
                    subjectId: this.currentSubject,
                    subjectName: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectName,
                    subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                    difficulty: this.currentDifficulty,
                    aiModel: this.aiModel
                }, {
                    headers: {
                        ...getHeaders()
                    }
                })

                this.$store.commit('decreamentLoader')
                console.log(response);
                this.sampleQuestions = response.data.sampleQuestionIds
                this.systemPrompt = response.data.systemMessage
                this.systemMessage = response.data.systemMessage



                this.showSystemPrompt = true

                this.getHumanPrompt()
            } catch (error) {
                this.$store.commit('decreamentLoader')
                this.sampleDataError = "No Sample Data found"
            }

        },
        getMarkDownHtml(markdown) {
            // console.log(markdown);
            let out = markdown
            const regex = /\\/gm;
            const escapedString = markdown.replace(regex, "\\\\");

            out = marked.parseInline(escapedString, { renderer });

            out = out.replace(/\\\\+/g, '\\');
            out = out.replace(/\\n/g, '<br>');


            // console.log(out);

            // console.log(out);
            return out;
        },
        addMessages(msg) {
            this.messages.push({
                "role": "user",
                "content": msg.query,
            })
            this.messages.push({
                "role": "assistant",
                "content": msg.rawResponse,
            })
            console.log(this.messages);

        },
        addAIMessages(msg) {
            this.messages.push({
                "type": "ai",
                "content": msg.rawResponse,
            })
            console.log(this.messages);

        },
        async customText() {
            const { valid } = await this.$refs.qform.validate();


            if (valid) {
                try {
                    let systemMessage = { role: "system", content: this.systemPrompt }
                    let msgs = [systemMessage, ...this.messages]
                    console.log(msgs);
                    this.customTextStatus = true

                    let response = null


                    setTimeout(() => {
                        $("html, body").animate({ scrollTop: $(document).height() }, 1000);

                    }, 200);



                    this.streamingContent = ''
                    this.streaming = true
                    console.log("streaming");

                    let id = 'q' + this.questions.length
                    let sampleData = {
                        "questions": [],
                        "error": '',
                        "rawResponse": '',
                        "query": this.humanQuery,
                        "currentNumberOfQuestions": 0
                    }
                    this.questions.push({ data: { ...sampleData }, questions: sampleData.questions, id: id })


                    this.showText = false

                    let credentials = await Auth.currentCredentials()
                    console.log(credentials);
                    console.log(Auth.essentialCredentials(credentials));
                    const client = new LambdaClient({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1',
                        httpOptions: {
                            connectTimeout: 900000,
                            timeout: 900000
                        }

                    });
                    const input = { // InvocationRequest
                        FunctionName: "mentoAITestHarness", // required
                        InvocationType: "RequestResponse",
                        Payload: JSON.stringify({ messages: msgs, query: this.humanQuery, currentNumberOfQuestions: this.currentNumberOfQuestions })
                    };
                    const command = new InvokeWithResponseStreamCommand(input);
                    const streamResponse = await client.send(command)

                    this.humanPrompt = ""
                    this.showHumanPrompt = false


                    for await (const event of streamResponse.EventStream) {
                        // Each event will likely contain the 'PayloadChunk' property 
                        const chunk = event.PayloadChunk?.Payload;
                        const textChunk = new TextDecoder().decode(chunk);


                        const regex = /<chunk>(.*?)<\/chunk>/s;
                        let match = textChunk.match(regex);


                        if (match) {
                            const extractedText = match[1];
                            response = JSON.parse(extractedText.trim())
                            // console.log("mmmm", extractedText);

                        }

                        else {
                            this.streamingContent += textChunk

                        }


                    }

                    this.streaming = false


                    console.log(response);
                    this.questions[this.questions.length - 1] = { data: { ...response }, questions: response.questions, id: id }
                    this.currentNumberOfQuestions = response.currentNumberOfQuestions
                    this.addMessages(response)


                    this.customTextStatus = false
                    this.showText = true

                    this.humanQuery = ""
                    setTimeout(() => {
                        window.MathJax.typesetPromise();
                    }, 10);

                    setTimeout(() => {
                        var targetElement = document.getElementById(id);
                        targetElement.scrollIntoView({ behavior: 'smooth' });


                    }, 200);
                }
                catch (error) {
                    console.log(error);
                }
            }
        },
        async viewResult() {
            // const { valid } = await this.$refs.form.validate();
            // if (!valid) {
            //     return
            // }

            let url = "https://45wsfvypceirppbhpasvcp7xf40vkkeh.lambda-url.us-east-1.on.aws/aiquestion"
            this.response = null
            this.loading = true


            let response = await axios.post(url, {
                topicId: this.currentTopic,
                topicName: this.topicsList.find((e) => e.entityId == this.currentTopic).entityLabel,
                categoryName: this.categoriesList.find((e) => e.categoryId == this.currentCategory).categoryName,
                categoryId: this.currentCategory,
                difficulty: this.currentDifficulty,
                questionCount: this.questionCount,
                subjectId: this.currentSubject,
                subjectName: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectName,
                subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                aiModel: this.aiModel

            }, {
                headers: {
                    ...getHeaders()
                }
            })

            this.questions.push({ data: { ...response.data }, ...response.data.question })
            this.addMessages(response.data)

            this.loading = false
            setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 10);
            setTimeout(() => {
                $("html, body").animate({ scrollTop: $(document).height() }, 1000);

            }, 200);


        },
        async getHumanPrompt() {
            this.$store.commit('increamentLoader')

            let url = "https://45wsfvypceirppbhpasvcp7xf40vkkeh.lambda-url.us-east-1.on.aws/getHumanPrompt"
            this.response = null
            let currentQ = this.sampleQuestions[this.sampleQuestionsCount]

            this.sampleQuestionsCount++

            if (this.sampleQuestionsCount == this.sampleQuestions.length) {
                this.sampleQuestionsCount = 0

            }

            let response = await axios.post(url, {
                topicId: this.currentTopic,
                topicName: this.topicsList.find((e) => e.entityId == this.currentTopic).entityLabel,
                categoryName: this.categoriesList.find((e) => e.categoryId == this.currentCategory).categoryName,
                categoryId: this.currentCategory,
                difficulty: this.currentDifficulty,
                questionCount: this.questionCount,
                subjectId: this.currentSubject,
                subjectName: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectName,
                subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                sampleQuestionId: currentQ

            }, {
                headers: {
                    ...getHeaders()
                }
            })
            this.humanPrompt = response.data
            this.showHumanPrompt = true
            this.$store.commit('decreamentLoader')

            setTimeout(() => {
                $("html, body").animate({ scrollTop: $(document).height() }, 1000);

            }, 200);

        },
        async generateQuestion() {
            let url = "https://45wsfvypceirppbhpasvcp7xf40vkkeh.lambda-url.us-east-1.on.aws/aiResponse"
            this.response = null
            this.loading = true
            let msgs = [{ type: "system", content: this.systemPrompt }, ...this.messages]
            this.showText = false
            console.log(this.messages);
            let response = await axios.post(url, {
                topicId: this.currentTopic,
                topicName: this.topicsList.find((e) => e.entityId == this.currentTopic).entityLabel,
                difficulty: this.currentDifficulty,
                subjectType: this.subjectList.find((e) => e.subjectId == this.currentSubject).subjectType,
                messages: msgs,
                query: this.humanPrompt,
                categoryName: this.categoriesList.find((e) => e.categoryId == this.currentCategory).categoryName,
                categoryId: this.currentCategory,
                aiModel: this.aiModel,
                currentNumberOfQuestions: this.currentNumberOfQuestions
            }, {
                headers: {
                    ...getHeaders()
                }
            })
            let id = 'q' + this.questions.length
            this.questions.push({ data: { ...response.data }, questions: response.data.questions, id: id })
            this.currentNumberOfQuestions = response.data.currentNumberOfQuestions
            this.addMessages(response.data)
            this.loading = false
            this.humanPrompt = ""
            this.showHumanPrompt = false
            this.showText = true

            setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 20);

            setTimeout(() => {
                var targetElement = document.getElementById(id);
                targetElement.scrollIntoView({ behavior: 'smooth' });


            }, 200);
        }
    },
    watch: {

    }



}
</script>

<style scoped>
.ic {
    animation: grow 2s ease-in-out infinite alternate;
    font-size: 30px;

}

@keyframes grow {
    0% {
        transform: scale(1);
    }

    /* Initial state */
    50% {
        transform: scale(1.2);
    }

    /* Slightly larger */
    100% {
        transform: scale(1);
    }

    /* Back to original */
}


.assignment-card {
    border-radius: 12px !important;
    white-space: pre-line !important;
}

.main-lg {
    max-width: 1020px;
    margin: 20px auto;
}

.main-md {
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%;
}

.streaming-box {
    border: 1px solid rgb(var(--v-theme-primaryPurple)) !important;
    min-height: 200px !important;
    border-radius: 10px !important;
}

.list-item {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    border-radius: 10px;
    background: rgb(var(--v-theme-report-subCardBg));

}

.skillsBlock {
    max-height: 300px;
    overflow: auto;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}

.t-1remReg {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif !important;
}

.htmlclass .list-item.on-hover {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    /* 
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}

.htmlClassTestPre {
    font-family: "Inter", sans-serif !important;
    font-size: 1rem !important;
    white-space: pre-line !important;
}
</style>