<template>
<div v-if="isQuestionReviewer">
    <v-row>
        <v-col>
            <span class="text-subtitle-2">Review Details:</span>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" :color="l1ReviewStatusColor" v-bind="props" variant="tonal" size="small" density="comfortable">L1</v-chip>
                </template>
                {{ l1ReviewStatusTooltipText }}
            </v-tooltip>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" :color="l2ReviewStatusColor" v-bind="props" variant="tonal" size="small" density="comfortable">L2</v-chip>
                </template>
                {{ l2ReviewStatusTooltipText }}
            </v-tooltip>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" :color="l3ReviewStatusColor" v-bind="props" variant="tonal" size="small" density="comfortable">L3</v-chip>
                </template>
                {{ l3ReviewStatusTooltipText }}
            </v-tooltip>
        </v-col>
        <v-col>
            <span class="text-subtitle-2">Attempt(s) Breakdown:</span>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" color="success" v-bind="props" variant="tonal" size="small" density="comfortable">
                        <v-icon start icon="mdi-check-circle-outline" color="success" v-bind="props"></v-icon>
                        {{ numberOfCorrectAttempts }}
                    </v-chip>
                </template>
                <div class="text-subtitle-2 ml-1">
                Correct attempts
                </div>
                <ul class="text-subtitle-2 ml-2" >
                    <li class="text-subtitle-2" v-for="userAttempt in this.listOfUsersCorrectlyAttempted" v-bind:key="userAttempt.userName">
                        {{ userAttempt.userName }}
                    </li>
                </ul>
            </v-tooltip>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" color="error" v-bind="props" variant="tonal" size="small" density="comfortable">
                        <v-icon start icon="mdi-close-circle-outline" color="error" v-bind="props"></v-icon>
                        {{ numberOfWrongAttempts }}
                    </v-chip>
                </template>
                Wrong attempts
            </v-tooltip>
            <v-tooltip location="bottom">
                <template v-slot:activator="{ props }">
                    <v-chip class="ml-2" color="grey-lighten-1" v-bind="props" variant="tonal" size="small" density="comfortable">
                        <v-icon start icon="mdi-checkbox-blank-circle-outline" color="grey-lighten-1" v-bind="props"></v-icon>
                        {{ numberOfSkips }}
                    </v-chip>
                </template>
                Skipped
            </v-tooltip>
        </v-col>
     </v-row>
     <v-row v-if="sortedReviewFeedbacks.length > 0">
        <v-col> 
            <v-expansion-panels >
                <v-expansion-panel title="Feedback details" class="condensed">
                    <v-expansion-panel-text>
                        <v-virtual-scroll :height="80"
                            :items="sortedReviewFeedbacks">
                            <template v-slot:default="{ item }">
                                <div class="text-subtitle-2">
                                    <v-tooltip location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <v-chip class="ml-0" color="info" v-bind="props" variant="tonal" size="small" density="comfortable">
                                                <v-icon start icon="mdi-account-eye-outline" color="info" v-bind="props"></v-icon>
                                                {{ item.feedbackDateLocal }}
                                            </v-chip>
                                            <v-chip v-if="item.isProd" class="ml-1" color="green" v-bind="props" variant="tonal" size="small" density="comfortable">
                                                Prod
                                            </v-chip>
                                        </template>
                                        {{ item.userName }} | {{item.feedbackDatetimeLocal}} 
                                    </v-tooltip>
                                    {{item.feedback}} 
                                </div>
                                <div class="text-subtitle-2" v-if="item.adminNotes">
                                    <v-tooltip location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <v-chip class="ml-6" color="success" v-bind="props" variant="tonal" size="small" density="comfortable">
                                                <v-icon v-if="false" start icon="mdi-account-eye-outline" color="success" v-bind="props"></v-icon>
                                                - {{ item.updatedBy }}
                                            </v-chip>
                                        </template>
                                        Response from: {{ item.updatedBy }}
                                    </v-tooltip>
                                    {{item.adminNotes}}
                                </div>
                                <v-divider></v-divider>
                            </template>
                        </v-virtual-scroll>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            
        </v-col>
     </v-row>   
</div>
</template>
  
<script>
import _ from "lodash";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
    name: "QuestionReviewFeedbackDetails",
    data: () => ({
    }),
    props: {
        qId: String,
    },
    components: {},
    async created() {
       
        //console.log(`isQuestionReviewer: ${this.isQuestionReviewer}`);
        //console.log(`isLevel1Reviewer: ${this.isLevel1Reviewer}`);
        //console.log(`isLevel2Reviewer: ${this.isLevel2Reviewer}`);
        //console.log(`isLevel3Reviewer: ${this.isLevel3Reviewer}`);
        
        var reqObj = {
                qId: this.qId
            };
        this.$store.dispatch("loadQuestionReviewFeedbackDetails",reqObj).then(() => {
            //console.log(`current qId: ${JSON.stringify(this.qId)}`);
            //console.log(`QuestionReviewFeedbackDetails: ${JSON.stringify(this.questionReviewFeedbackDetails)}` );
        });
       
    },
    computed: {
        ...mapState(["questionReviewFeedbackDetails"]),
        ...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
        l1ReviewStatusColor: {
			get() {
				var retVal = "grey-lighten-1";
                var currentStatus = this.questionReviewFeedbackDetails.level1ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "success";
				} else if( currentStatus == "failed" ) {
                    retVal = "red";
                } else if( currentStatus== "failedfixed" ) {
                    retVal = "info";
                }
				return retVal;
			},
		},
        l2ReviewStatusColor: {
			get() {
				var retVal = "grey-lighten-1";
                var currentStatus = this.questionReviewFeedbackDetails.level2ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "success";
				} else if( currentStatus == "failed" ) {
                    retVal = "red";
                } else if( currentStatus == "failedfixed" ) {
                    retVal = "info";
                }
				return retVal;
			},
		},
        l3ReviewStatusColor: {
			get() {
				var retVal = "grey-lighten-1";
                var currentStatus = this.questionReviewFeedbackDetails.level3ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "success";
				} else if( currentStatus == "failed" ) {
                    retVal = "red";
                } else if( currentStatus == "failedfixed" ) {
                    retVal = "info";
                }
				return retVal;
			},
		},
        l1ReviewStatusTooltipText: {
            get() {
				var retVal = "Not Reviewed | Level 1";
                var currentStatus = this.questionReviewFeedbackDetails.level1ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "Approved | Level 1";
				} else if( currentStatus == "failed" ) {
                    retVal = "Corrections Needed | Level 1";
                } else if( currentStatus == "failedfixed" ) {
                    retVal = "Fixed | Ready for review again | Level 1";
                }
				return retVal;
			},
        },
        l2ReviewStatusTooltipText: {
            get() {
				var retVal = "Not Reviewed | Level 2";
                var currentStatus = this.questionReviewFeedbackDetails.level2ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "Approved | Level 2";
				} else if( currentStatus == "failed" ) {
                    retVal = "Corrections Needed | Level 2";
                } else if( currentStatus == "failedfixed" ) {
                    retVal = "Fixed | Ready for review again | Level 2";
                }
				return retVal;
			},
        },
        l3ReviewStatusTooltipText: {
            get() {
				var retVal = "Not Reviewed | Level 3";
                var currentStatus = this.questionReviewFeedbackDetails.level3ReviewStatus;
				if( currentStatus == "passed") {
					retVal = "Approved | Level 3";
				} else if( currentStatus == "failed" ) {
                    retVal = "Corrections Needed | Level 3";
                } else if( currentStatus == "failedfixed" ) {
                    retVal = "Fixed | Ready for review again | Level 3";
                }
				return retVal;
			},
        },
        numberOfCorrectAttempts : {
            get() {
				var retVal = 0;
                var attemptDetails = this.questionReviewFeedbackDetails.userAttemptDetails;
                try{
                    const count = _.sumBy(attemptDetails,'corectAttemptCount');
                    retVal = count;
                }
                catch (err) {
                    console.error(err);
                }
                return retVal;
			},
        },
        listOfUsersCorrectlyAttempted :{
            get() {
				let correctAttempts = _.filter(this.questionReviewFeedbackDetails.userAttemptDetails, 
                                    (item) => item.corectAttemptCount > 0);
                return correctAttempts;
			},

        },
        numberOfWrongAttempts : {
            get() {
				var retVal = 0;
                var attemptDetails = this.questionReviewFeedbackDetails.userAttemptDetails;
                try{
                    const count = _.sumBy(attemptDetails,'inCorrectAttemptCount');
                    retVal = count;
                }
                catch (err) {
                    console.error(err);
                }
                return retVal;
			},
        },
        numberOfSkips : {
            get() {
				var retVal = 0;
                var attemptDetails = this.questionReviewFeedbackDetails.userAttemptDetails;
                try{
                    const count = _.sumBy(attemptDetails,'skippedCount');
                    retVal = count;
                }
                catch (err) {
                    console.error(err);
                }
                return retVal;
			},
        },
        sortedReviewFeedbacks : {
            get() {
                return _.orderBy(
                    this.questionReviewFeedbackDetails.questionFeedback,
                    [
                    function(o) {
                        return moment.utc(o.feedbackDatetime, 'MM/DD/YYYY hh:mm:ss a').unix();
                    }
                    ],
                    ["desc"]
                )
            }
        }
    },

    watch: {},

    methods: {
    },
};
</script>

<style scoped>

.v-expansion-panel.condensed :deep(.v-expansion-panel-title) {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: auto;
}

</style>
