<template>
    <v-row>
        <transition-group name="list">

        <v-col v-for="(item) in sortedRoomParticipants" :key="item.clientId" cols=12  class="my-0 py-0 ">
            <v-row align="center" class="my-0 py-0">

                <v-col  cols="auto" class=" my-1 my-md-2 py-0">
                    <v-avatar :size="$vuetify.display.mdAndUp?'default' : '30'" v-if="item.type == 'ai'" :color="item.participant ? 'primaryPurple' : 'primaryGreen'"
                        class="avatarText">
                        <v-img src="@/assets/mento_ai.png"></v-img>
                    </v-avatar>
                    <v-avatar :size="$vuetify.display.mdAndUp?'default' : '30'" v-else :color="item.participant ? 'primaryPurple' : 'primaryGreen'"
                        class="avatarText text-primaryTextColor font-weight-bold">
                        {{ getInitials(item.name) }}
                    </v-avatar>
                </v-col>


                <!-- <v-col v-else cols="auto" class="">
                    <v-avatar size="30" v-if="item.type == 'ai'" :color="item.participant ? 'primaryPurple' : 'primaryGreen'"
                        class="avatarText">
                        <v-img src="@/assets/mento_ai.png"></v-img>
                    </v-avatar>
                    <v-avatar size="30" v-else :color="item.participant ? 'primaryPurple' : 'primaryGreen'"
                        class="avatarText text-primaryTextColor font-weight-bold">
                        {{ getInitials(item.name) }}
                    </v-avatar>
                    
                </v-col> -->
                
                <v-col class="ml-0 pl-0 my-1 my-md-2 py-0">
                    <!-- {{ totalMarks }} -->
                    <v-progress-linear :model-value="item.score < 0 ? 0 : item.score" :max="totalMarks" rounded height="10"
                        :color="item.participant ? 'primaryPurple' : 'primaryGreen'">

                        <!-- <template v-slot:default="{ value }">
                            <strong class="text-white"> {{ item.score }} - {{ Math.ceil(value) }}/100
                            </strong>
                        </template> -->
                    </v-progress-linear>

                </v-col>
                <v-col cols="auto" v-if="!item.available"  class="my-1 my-md-2 py-0">
                    <v-icon :color="iconColor">
                        mdi-network-off-outline
                    </v-icon>
                </v-col>
            </v-row>
        </v-col>
        </transition-group>
    </v-row>
</template>
  
<script>

import _ from "lodash";
import { useDisplay } from "vuetify";

export default {
    name: "ScoreBar",
    setup() {
    // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp } = useDisplay();

        return { xs, smAndUp, mdAndUp };
    },
    data: () => ({
    }),
    props: {
        roomParticipants: {
            type: Array,
            default: () => []
        },
        totalMarks: Number,
        iconColor: String
    },
    components: {},
    async created() {
        console.log("Scorebar");
        console.log("totalmarks", this.totalMarks);
    },
    computed: {
        sortedRoomParticipants: {
            get() {
                let roomParticipants = _.orderBy(this.roomParticipants, ['score'],['desc']);
                return roomParticipants
            }
        }
    },

    watch: {},

    methods: {
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        },
    },
};
</script>
  
<style scoped>
.list-enter, .list-leave-to {
  opacity: 0;
}

.list-enter-active, .list-leave-active {
  transition: opacity 0.5s ease;
}

.list-move {
  transition: transform 0.5s ease-out;
}

.avatarText-p{
    font-size: 1rem !important;
}

</style>
  