<template>

    <v-dialog v-model="qDialog" persistent z-index="1200" fullscreen scrollable transition="scale-transition"
        origin="center center" eager :retain-focus="false">
        <v-card>

            <v-card-text>
                <v-form>

                    <v-row dense class="ma-0 pa-0">

                        <v-col cols="12" class="t-938remReg text-black ma-0 pa-0">
                            <span class="edtr-required">
                                Question Text
                            </span>
                            <rte-editor toolbar="bullist" :required="true" :initialVData="editedItem.questionDesc"
                                v-model="editedItem.questionDesc" class="mt-1"></rte-editor>

                        </v-col>
                    </v-row>

                    <v-row dense justify="space-between" class=" mt-5">
                        <v-col cols="12" class="t-938remReg text-black ">
                            <span class="edtr-required">
                                Option A
                            </span>
                            <div>
                                <rte-editor v-model="editedItem.a" :initialVData="editedItem.a" :height="120"
                                    :required="true" class="mt-1"></rte-editor>

                            </div>

                        </v-col>
                        <v-col cols="12" class="t-938remReg text-black ">
                            <span class="edtr-required">
                                Option B
                            </span>
                            <rte-editor v-model="editedItem.b" :initialVData="editedItem.b" :height="120"
                                :required="true" class="mt-1"></rte-editor>

                        </v-col>
                    </v-row>


                    <v-row dense justify="space-between" class=" mt-5">
                        <v-col cols="12" class="t-938remReg text-black">
                            <span class="edtr-required">
                                Option C
                            </span>
                            <rte-editor v-model="editedItem.c" :initialVData="editedItem.c" class="mt-1"
                                :required="true" :height="120"> </rte-editor>

                        </v-col>
                        <v-col cols="12" class="t-938remReg text-black ">
                            <span class="edtr-required">
                                Option D
                            </span>
                            <rte-editor v-model="editedItem.d" :initialVData="editedItem.d" class="mt-1" :height="120"
                                :required="true"></rte-editor>

                        </v-col>
                    </v-row>
                    <v-row dense class=" mt-3">

                        <v-col cols="12" class="t-938remReg text-black  ma-0 pa-0">
                            <span class="edtr-required">
                                Solution
                            </span>
                            <rte-editor toolbar="bullist" v-model="editedItem.solutionText"
                                :initialVData="editedItem.solutionText" class="mt-1" :required="true"></rte-editor>

                        </v-col>
                    </v-row>
                </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Close Dialog" @click="saveQuestion"></v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
    <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
        <v-col cols="12" class="ma-0 pa-0">
            <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>

                <template v-slot:prepend>
                    <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
                        size="x-small" @click="back()" icon="mdi-chevron-left">
                    </v-btn>
                </template>
                <template v-slot:title="{item}">
                    <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
    </v-row>
    <v-layout class="mb-0 pb-0  pb-4" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
        <v-container fluid class="h-100 mt-3 mt-md-0 mb-0 pb-0"
            :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
            <div>

                <v-expansion-panels rounded="xl" multiple v-model="panels">
                    <v-expansion-panel rounded="xl" color="reportBgColor" bg-color="reportBgColor" :focusable="false"
                        :static="true" selected-class="assignment-card" class="reportBgColor mt-2 rounded-sm"
                        v-for="q, index in aiQuestions" :key="index">

                        <template v-slot:title>
                            <v-row align="center">
                                <v-col cols="auto">
                                    <v-avatar size="40" color="transparent">
                                        <v-img height="40" :src='require("@/assets/epq.svg")'>
                                        </v-img>
                                    </v-avatar>
                                </v-col>
                                <v-col class="t-1rem ml-0 pl-0">
                                    Question number {{ q.order }}

                                </v-col>
                                <v-col class="text-right">

                                    <span class="mx-2">
                                        <v-icon @click.stop="editQuestion(q.questionItem)">mdi-pencil-outline</v-icon>

                                    </span>
                                    <span class="mx-2">
                                        <v-avatar size="20" :color="difficultyColor(q.questionItem.difficultyLevel)">

                                        </v-avatar>
                                    </span>
                                </v-col>
                            </v-row>

                        </template>

                        <template v-slot:text>
                            <QuestionComponent :question="q.questionItem"></QuestionComponent>
                        </template>
                    </v-expansion-panel>

                </v-expansion-panels>
                <v-row align-content="center" justify="center" v-if="loadingQuestions">
                    <v-col class="text-subtitle-1 text-center text-white" cols="12">
                        Getting Question
                    </v-col>
                    <v-col cols="3">
                        <v-progress-linear color="primaryPurple" indeterminate rounded height="6"></v-progress-linear>
                    </v-col>
                </v-row>
            </div>

        </v-container>
    </v-layout>

</template>

<script>
// import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import _ from "lodash";
import { getLoggedInUserCurrentDomain } from "@/services/authService";
import QuestionComponent from "../ai/QuestionComponent.vue";

import {
    breadcrumbHandler,
} from "@/services/breadcrumbHandler";
import { LambdaClient, InvokeCommand, InvokeWithResponseStreamCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import { Auth } from "aws-amplify";
import rteEditor from '@/shared/components/rte-editor.vue';
import { securePostAPI } from '@/services/apiService';
export default {
    name: 'AIQuestionsList',
    data: () => ({
        panels: [],
        loadingQuestions: false,
        aiQuestions: [],
        questionsCount: 0,
        qDialog: false,
        editedItem: {
            "questionPartitionKey": "",
            "aiQuestionId": "",
            "questionDesc": "wwwwww",
            "questionTypeId": "",
            "a": "222as",
            "b": "",
            "c": "",
            "d": "",
            "answerSingleChoice": "",
            "difficultyLevel": "",
            "domainId": "",
            "insertedBy": "",
            "insertedTimeStamp": 0,
            "categoryId": "",
            "solutionText": "",
            "subjectId": "",
            "topicId": ""
        },
        defaultItem: {
            "questionPartitionKey": "",
            "aiQuestionId": "",
            "questionDesc": "",
            "questionTypeId": "",
            "a": "",
            "b": "",
            "c": "",
            "d": "",
            "answerSingleChoice": "",
            "difficultyLevel": "",
            "domainId": "",
            "insertedBy": "",
            "insertedTimeStamp": 0,
            "categoryId": "",
            "solutionText": "",
            "subjectId": "",
            "topicId": ""
        }
    }),
    setup() {

        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {
        this.loadingQuestions = true
        this.questionCount = parseInt(this.aiQuestionsInput.easy) + parseInt(this.aiQuestionsInput.hard) + parseInt(this.aiQuestionsInput.intermediate)
        breadcrumbHandler(this);
        if (this.$store.state.aiQuestions.length > 0) {
            this.loadingQuestions = false
            this.aiQuestions = this.$store.state.aiQuestions
            return
        }
        this.invokeAILambda(this.aiQuestionsInput)

    },
    components: {
        QuestionComponent,
        rteEditor
    },


    computed: {
        ...mapState(["breadCrumbs", "aiQuestionsInput"]),
        displayBreadCrumbs: {
            get() {
                let breadCrumbs = [];
                breadCrumbs = this.breadCrumbs;

                return breadCrumbs
            }
        },

    }
    ,
    methods: {
        async saveQuestion() {
            console.log(this.editedItem);
            console.log("Test");
            let item = _.cloneDeep(this.editedItem)
            this.editedItem = Object.assign({}, this.defaultItem);
            let response = await securePostAPI(item, 'ai/savequestion')

            var foundQ = this.aiQuestions.find(x => x.questionItem.aiQuestionId == item.aiQuestionId);
            Object.assign(foundQ, item);
            console.log(response);

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.qDialog = false

        },
        editQuestion(item) {
            this.editedItem = Object.assign({}, item);
            console.log(this.editedItem);
            this.qDialog = true


        },
        back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
            this.$router.replace(page)
        },
        breadCrumbRoute(item){
            this.$router.replace(item.pageRoute)
        },
        difficultyColor(difficultyLevel){
            if(difficultyLevel==2)
            {
                return 'easyColor'
            }
            if (difficultyLevel == 5) {
                return 'intermediateColor'
            }
            if (difficultyLevel == 8) {
                return 'hardColor'
            }
        },
        async invokeAILambda(data) {


            let credentials = await Auth.currentCredentials()
            console.log(credentials);
            console.log(Auth.essentialCredentials(credentials));
            const client = new LambdaClient({
                credentials: Auth.essentialCredentials(credentials),
                region: 'us-east-1',
                httpOptions: {
                    connectTimeout: 900000,
                    timeout: 900000
                }

            });
            const input = { // InvocationRequest
                FunctionName: "aiQuestionGenerator", // required
                InvocationType: "RequestResponse",
                Payload: JSON.stringify(data)
            };
            const command = new InvokeWithResponseStreamCommand(input);
            const response = await client.send(command)


            console.log(response);
            for await (const event of response.EventStream) {
                // Each event will likely contain the 'PayloadChunk' property 
                const chunk = event.PayloadChunk?.Payload;
                console.log(chunk)
                if (chunk) {
                    const textChunk = new TextDecoder().decode(chunk);
                    const regex = /<chunk>(.*?)<\/chunk>/g;
                    let match;

                    while ((match = regex.exec(textChunk)) !== null) {
                        // The string you want is in match[1]
                        console.log(JSON.parse(match[1]))
                        // store.commit("addAIQuestion", JSON.parse(match[1]))
                        this.aiQuestions.push(JSON.parse(match[1]))
                        this.panels.push(this.aiQuestions.length - 1)
                        this.$store.commit("addAIQuestion", JSON.parse(match[1]))
                        if (this.aiQuestions.length == this.questionCount) {
                            this.loadingQuestions = false
                        }
                    }

                }

            }
        }


    },
    watch: {


    }



}
</script>

<style scoped>
.heightBread {
    height: 80px;
}

.custom-theme {
    --v-theme-error: blue !important;
    --v-primary-base: #f44336;
    /* Your desired primary color */

}

.assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border-radius: 10px;
    white-space: pre-line !important;
    border: 1px solid #9461FC;

}

.click {
    cursor: pointer
}

.disabledOpacity {}

.card-border:hover {}

.subcard-bg {
    background: rgb(var(--v-theme-report-subCardBg));
    border-radius: 10px;
    white-space: pre-line !important;
}

.solution-text {
    color: rgba(191, 191, 198, 1);
}

.v-expansion-panel-title--active {
    background-color: rgb(var(--v-theme-reportBgColor)) !important;
}

.v-expansion-panel-title__overlay {
    opacity: 1 !important;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}


.main-lg {
    padding-top: 40px;
    padding-left: 10%;
    padding-right: 10%;
}

.main-md {
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.layoutLarge {
    height: calc(100% - 100px);
}

.edtr-required:after {
    content: "  *";
    color: red;
}
</style>