<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>
  <v-container fluid class="ma-0 pa-4">
    <div :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''" v-if="activityLoaded">
      <v-row class="mb-4 mx-0">
        <v-col cols="12" class="pb-2 pa-0">
          <span class="t-h5 text-white">
            {{ currentTopicName != ''? currentTopicName : 'All Reports'}}
          </span>
        </v-col>
        <v-col cols="12" class="pt-0 pa-0">
          <span class="t-1remReg text-white text-capitalize">
            {{ currentTopicSkill != ''? 'Current Skill - ' + currentTopicSkill : 'Check Test Results and Solutions'}}
          </span>
        </v-col>
      </v-row>

      <template v-if="quizReports.length > 0">

        <template v-for="(item, i) in quizReports" :key="i">
          
            <v-card class="report-card mb-4">
              <v-card-text class="text-primaryTextColor">
                <v-row justify="space-between" align="center">
                  <v-col cols="auto" class="mr-lg-16">
                    <v-row justify="start" align="center">
                      <v-col cols="auto">
                        <v-avatar :size="46" class="text-center">
                          <v-img :height="46" src="@/assets/test_practice.png" />
                        </v-avatar>
                      </v-col>
                      <v-col cols="auto">
                        <v-row justify="start" class="d-flex flex-column">
                          <div class="pb-0 pl-0">
                            <span class="t-1rem">{{ item.quizReport.isPractice ? 'Practice' : 'Quiz'}}</span>
                          </div>
                          <div class="pt-1 pl-0">
                            <span class="t-h6Reg parameter-style">{{
                              getDateTimeFromTimeStamp(item.quizReport.startTimeStamp)}}</span>
                          </div>
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-col>

                  <!-- large -->
                  <v-col v-if="lgAndUp">
                    <div class="d-flex justify-space-between align-center">
                      <!-- correct -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-check-circle" color="#55B56A" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.correctQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style pt-1">Correct</span>
                        </div>

                      </div>
                      <!-- incorrect -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-close-circle" color="#F44336" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.incorrectQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Incorrect</span>
                        </div>

                      </div>
                      <!-- skipped -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-checkbox-blank-circle" color="#EB8A31" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.totalQuestionInTest - item.quizReport.attemptedQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Skipped</span>
                        </div>

                      </div>
                      <!-- quiz time -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ !item.quizReport.isPractice ? formatTime(item.quizReport.quizTimeInMinutes) : 'NA' }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Quiz time</span>
                        </div>

                      </div>
                      <!-- my time -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{
                            convertTimeInSecondsToMinutes(item.quizReport.timespentInSeconds)
                          }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">My time</span>
                        </div>

                      </div>
                    </div>
                  </v-col>

                  <v-col v-if="smAndUp" cols="auto" class="ml-lg-16 pl-0 pl-lg-3">
                    
                      <v-sheet @click="onViewSolution(item)"
                        class="view-past-solutions t-subtitle d-flex justify-center align-center"
                        >
                        <v-icon icon="mdi-lightbulb-on" color="#ffffff" class="mr-2" size="20"></v-icon>
                        <span class="t-1remReg text-solution text-white">Check Solutions</span>
                      </v-sheet>
                    
                  </v-col>
                </v-row>

                <!-- medium -->
                <v-row v-if="!lgAndUp && smAndUp">
                  <v-col class="pr-6">
                    <div class="d-flex jusitify-start justify-sm-space-between align-center flex-wrap">
                      <!-- correct -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-check-circle" color="#55B56A" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.correctQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style pt-1">Correct</span>
                        </div>

                      </div>
                      <!-- incorrect -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-close-circle" color="#F44336" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.incorrectQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Incorrect</span>
                        </div>

                      </div>
                      <!-- skipped -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-checkbox-blank-circle" color="#EB8A31" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ item.quizReport.totalQuestionInTest -
                            item.quizReport.attemptedQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Skipped</span>
                        </div>

                      </div>
                      <!-- quiz time -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{ !item.quizReport.isPractice ?
                            formatTime(item.quizReport.quizTimeInMinutes) : 'NA' }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">Quiz time</span>
                        </div>

                      </div>
                      <!-- my time -->
                      <div>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med">{{
                            convertTimeInSecondsToMinutes(item.quizReport.timespentInSeconds)
                          }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style">My time</span>
                        </div>

                      </div>
                    </div>
                  </v-col>
                </v-row>

                <!-- small -->
                <v-row v-if="!smAndUp">
                  <v-col>
                    <v-row class="jusitify-start justify-sm-space-between align-center flex-wrap">
                      <!-- correct -->
                      <v-col>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-check-circle" color="#55B56A" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med text-no-wrap">{{ item.quizReport.correctQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style pt-1 text-no-wrap">Correct</span>
                        </div>

                      </v-col>
                      <!-- incorrect -->
                      <v-col>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-close-circle" color="#F44336" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med text-no-wrap">{{ item.quizReport.incorrectQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style text-no-wrap">Incorrect</span>
                        </div>

                      </v-col>
                      <!-- skipped -->
                      <v-col>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-checkbox-blank-circle" color="#EB8A31" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med text-no-wrap">{{ item.quizReport.totalQuestionInTest -
                            item.quizReport.attemptedQuestions }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style text-no-wrap">Skipped</span>
                        </div>

                      </v-col>
                      <!-- quiz time -->
                      <v-col>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med text-no-wrap">{{ !item.quizReport.isPractice ?
                            formatTime(item.quizReport.quizTimeInMinutes) : 'NA' }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style text-no-wrap">Quiz time</span>
                        </div>

                      </v-col>
                      <!-- my time -->
                      <v-col>

                        <div class="d-flex align-center justify-start pb-0">
                          <v-icon icon="mdi-clock-time-three-outline" color="#A174FD" size="16" class="mr-1"></v-icon>
                          <span class="t-h1125Med text-no-wrap">{{
                            convertTimeInSecondsToMinutes(item.quizReport.timespentInSeconds)
                          }}</span>
                        </div>
                        <div class="pt-1">
                          <span class="t-h6Reg parameter-style text-no-wrap">My time</span>
                        </div>

                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="!smAndUp">
                  <v-col cols="12">
                    
                      <v-sheet @click="onViewSolution(item)"
                        class="view-past-solutions t-subtitle d-flex justify-center align-center"
                        >
                        <v-icon icon="mdi-lightbulb-on" color="#ffffff" class="mr-2" size="20"></v-icon>
                        <span class="t-1remReg text-solution text-white">Check Solutions</span>
                      </v-sheet>
                    
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
        </template>
      </template>
      <template v-else>
        <v-card class="report-card mb-4">
          <v-card-text class="text-primaryTextColor">
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <span class="t-1remReg">
                  No report is available. Please get started with practicing.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </div>
  </v-container>
</template>
  
<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment';
import {
  breadcrumbHandler,
} from "@/services/breadcrumbHandler";

export default {
  name: 'QuizReports',
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  async created() {
    breadcrumbHandler(this);
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);

    if ('topicId' in this.$route.params) {
      this.topicId = this.$route.params.topicId;
    }

    if('entityId' in this.$route.params){
      this.topicId = this.$route.params.entityId;
    }

    if (!this.currentTopicId) {
      console.log("No topicId")
      this.$router.back();
    } else {

      if (this.currentTopicId && this.newLearnNodes) {
        let current = {};
        this.newLearnNodes.forEach(item => {
          if (item["entityId"] == this.currentTopicId) {
            current = item;
          }
        });
        this.currentTopic = current;
        this.breadcrumbItems.push({
          title: this.newLearnNodeActivity.topicName + " - All Reports",
          disabled: false,
          href: this.currentTopicId,
        });
      }
      let payload = {
        topicId: this.topicId
      }
      if( 'studentId' in this.$route.params){
        console.log("studentId is", this.$route.params.studentId);
        payload.studentId = this.$route.params.studentId;
      }
      this.$store.dispatch("loadAllReports", payload).then(res => {
        this.quizReports = res;
        this.activityLoaded = true;
      });

      recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityAllReportsOpen, {
        topicId: this.currentTopicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
      });
    }
    if (this.newLearnNodeActivity && this.newLearnNodeActivity.skillUpgradeQuiz) {
      this.newLearnNodeActivity.skillUpgradeQuiz.forEach(item => {
        if (item["activityId"] == this.currentActivityId) {
          this.currentActivity = item;
        }
      });
    }
  },
  data: () => ({
    currentTopic: {},
    currentActivity: {},
    activityId: "",
    activityName: "",
    topicId: "",
    breadcrumbItems: [],
    quizReports: [],
    activityLoaded: false,
  }),
  methods: {
    breadCrumbRoute(item){
      this.$router.replace(item.pageRoute)
    },
    back() {
			let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
			this.$router.back();
		},
    getIconSrc(url) {
      return window.location.protocol.replace("quizreports/", "") + "/" + url;
    },
    convertTimeInSecondsToMinutes(timeInSeconds) {
      if (timeInSeconds < 0) return '-- : --';
      console.log("result", timeInSeconds < 0);
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    formatTime(minutes) {
      const formattedMinutes = String(Math.floor(minutes)).padStart(2, '0');
      const formattedSeconds = '00';

      return `${formattedMinutes}:${formattedSeconds}`;
    },
    getDateTimeFromTimeStamp(timestamp) {
      let date = new Date(timestamp);
      var dateString = moment(date).format("D MMM, hh:mm A");
      return dateString
    },

    onViewSolution(item) {
      let activityType = item.quizReport?.isPractice ? "practiceTest" : "skillUpgradeQuiz";
      console.log("item", item);
      recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityReportOpen, {
        topicId: this.currentTopicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
        activityType: activityType,
        testId: item.testId,
        testInstanceId: item.testInstanceId,
      });
      console.log("currentActivity", this.currentActivity);
      if(this.$route.fullPath.startsWith('/parent')){
        this.$router.push(`/testresult/${item.testId}?instance=${item.testInstanceId}&report=${false}&quiz=${!item.quizReport?.isPractice}&parent=${true}`);
      }
      else if(this.$route.fullPath.startsWith('/teacher')){
        this.$router.push(`/testresult/${item.testId}?instance=${item.testInstanceId}&report=${false}&quiz=${!item.quizReport?.isPractice}&teacher=${true}`);
      }
      else{
        this.$router.push(`/testresult/${item.testId}?instance=${item.testInstanceId}&report=${false}&quiz=${!item.quizReport?.isPractice}`);
      }
    },
    getCapitalizedText(text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    },
  },
  computed: {
    ...mapState(["newLearnNodes", "newLearnNodeActivity", "currentActivityId", "currentTopicId", "currentQuizReport", "breadCrumbs","studentName", "className", "topicName", "currentTopicName", "currentTopicSkill"]),
    displayBreadCrumbs: {
      get() {				
        let breadCrumbs = this.breadCrumbs;
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        if ('topicId' in this.$route.params) {
          breadCrumbs = breadCrumbs.slice(-3);
          if('batchId' in this.$route.params){
            let currentClassName = this.className !== ''? this.className : 'Class Details';
            breadCrumbs[breadCrumbs.length-3].title = currentClassName;
          }
          if('studentId' in this.$route.params){
            let currentStudentName = this.studentName !== ''? this.studentName : 'Student';
            breadCrumbs[breadCrumbs.length-2].title = currentStudentName;
          }
          if(this.currentTopicName != ""){
            let currentTopicName = this.currentTopicName !== ''? this.currentTopicName : 'Reports';
            breadCrumbs[breadCrumbs.length-1].title = currentTopicName;
          }
        }
        else{
          breadCrumbs = breadCrumbs.slice(-2);
        }
        return breadCrumbs;
      }
    },
  },
}
</script>
  
<style scoped>
.main-lg {
  max-width: 1020px !important;
  margin: 20px auto !important;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.report-card {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;
  border: 2px solid transparent;
  pointer-events: none;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.report-card:hover {
  border: 2px solid #9461FC;
}

.parameter-style {
  opacity: 0.7;
}

.skill-container {
  border: 0.5px solid rgba(231, 231, 231, 0.25);
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  border-radius: 14px;
  box-sizing: border-box;
}

.view-solution {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 10px 14px;
}

.report-container {
  border-color: transparent;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
  width: 100%;
  background-color: #26253A;
}

.view-past-solutions {
  padding: 8px 10px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 600px;
  pointer-events: auto;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.view-past-solutions:hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  /* background-color: rgb(var(--v-theme-primaryPurple)); */
  background: linear-gradient(93deg, #AB77FF 0.66%, #585EF3 99.02%);
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.text-solution {
  letter-spacing: -0.16px;
}
</style>
<style></style>