<template>
  <v-container fluid class="pa-8">
    <v-row dense>
      <v-col cols="12">
        <v-card class="card-bg text-white">
          <v-card-text>
            <v-row align="center" justify="space-around" class="">
              <v-col cols="12" sm="2" class="heading"> Filters </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="testList"
                  item-title="testName"
                  item-value="testId"
                  v-model="filterItem.testId"
                  label="Practice Test *"
                  density="compact"
                  dense
                  variant="outlined"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="subjectList"
                  item-title="subjectName"
                  item-value="subjectId"
                  v-model="filterItem.subjectId"
                  label="Subject *"
                  density="compact"
                  dense
                  variant="outlined"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn
                  class="create-btn t-1remReg text-none px-3"
                  density="default"
                  variant="outlined"
                  height="40"
                  width="180"
                  dark
                  :disabled="isDisabledQuestionBtn()"
                  @click.stop="generate()"
                  >View</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="showData" dense>
      <!-- <v-col lg="12" md="12" sm="12" class="">
        <span class="table-header"> Topic wise summary </span>
        <v-divider></v-divider>
      </v-col> -->
      <v-col lg="12" md="12" sm="12">
        <v-data-table
          dense
          :headers="headers.concat(topicHeaders)"
          :items="batchTopicReports"
          :footer-props="{
            'items-per-page-options': [25, 50, 100, -1],
          }"
          item-key="userId"
          class="table-bg striped-rows"
        >
          <template
            v-for="field in topicHeaders"
            #[`header.${field.value}`]="{}"
            :key="field.value"
          >
            <div :key="field.title" class="my-2 mx-0">
              <span>{{ field.title.substring(0, 40) }}</span>
              <v-tooltip right v-if="field.title.length > 40">
                <template #activator="{ props }">
                  <v-icon small class="mr-2" v-bind="props">
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>{{ field.title }}</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-for="field in topicHeaders"
            #[`item.${field.value}`]="{ item }"
            :key="field.value"
          >
          <v-row v-if="item[field.value]">
              <v-col cols="12" class="py-0 t-h6Reg" :class="
                item[field.value].percentage > 66
                  ? 'text-green'
                  : item[field.value].percentage > 33
                  ? 'text-orange'
                  : 'text-red'
              ">
                <span>{{
                item[field.value].totalCorrectQuestions +
                "/" +
                item[field.value].totalAttemptedQuestion
              }}</span>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12"> - </v-col>
            </v-row>
          </template>

          <template #no-data>
            <span>No Data available</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
export default {
  name: "TopicWiseSummaryComponent",
  props: ["batchId"],
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  async created() {
    this.$store.dispatch("loadAllTests");
    this.$store.dispatch("loadNewLearnNodes");

    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenTestSummaryTabOpen, {
      courseId: this.courseId,
      batchId: this.batchId,
    });
  },
  data: () => ({
    showData: false,
    options: "",
    searchFilter: "",
    selectedUserId: "",
    selectedStudent: {},
    filterItem: {
      testId: "",
      subjectId: "",
    },

    headers: [
      {
        title: "Student Name",
        sortable: true,
        filterable: true,
        value: "studentName",
        width: "200px",
        minWidth: "200px",
        headerProps: { class: 'text-primaryTextColor t-1remReg header-table' },
        cellProps: { class: 'text-primaryTextColor t-h6Reg cell-table' },
      },
    ],
  }),
  computed: {
    ...mapState(["newLearnNodes", "courseId"]),
    testList: {
      get() {
        return _.orderBy(this.$store.state.tests, ["order"], "asc");
      },
    },
    subjectList: {
      get() {
        return _.map(this.newLearnNodes, (item) =>
          _.pick(item, ["subjectId", "subjectName", "subjectType"])
        );
      },
    },
    students: {
      get() {
        let students = [];
        if (this.searchFilter.trim() != "") {
          students = this.searchedStudentList;
          console.log("here", students);
        } else {
          students = this.studentList;
        }
        return students;
      },
    },
    topicWiseSummaryData: {
      get() {
        return this.$store.state.topicWiseReports;
      },
    },
    batchTopicReports: {
      get() {
        return this.topicWiseSummaryData.studentList;
      },
    },
    topicHeaders: {
      get() {
        if (!this.showData) {
          return [];
        } else {
          let topicHeaders = [];
          topicHeaders = _.map(this.topicWiseSummaryData.columns, (topic, index) => ({
            title: topic["colLabel"],
            align: "start",
            sortable: true,
            value: topic["colKey"],
            width: "150px",
            minWidth: "150px",
            headerProps: { class: 'text-primaryTextColor t-1remReg header-table ' + (index == this.topicWiseSummaryData.columns.length -1 ? 'no-right-border': ''  )},
            cellProps: { class: 'text-primaryTextColor t-h6Reg cell-table ' + (index == this.topicWiseSummaryData.columns.length -1 ? 'no-right-border': ''  )},
          }));

          return topicHeaders;
        }
      },
    },
  },
  methods: {
    isDisabledQuestionBtn() {
      return !_.every(this.filterItem, (e) => !_.isEmpty(e));
    },
    generate() {
      const reqData = {
        batchId: this.batchId,
        data: this.filterItem,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenTestSummaryTabViewButtonClicked, {
        courseId: this.courseId,
        batchId: this.batchId,
        testId: this.filterItem.testId,
        subjectId: this.filterItem.subjectId,
      }); 
      this.$store.dispatch("loadTopicWiseReport", reqData).then((res) => {
        this.showData = true;
      });
    },
    getTime(time) {
      let vardata = moment(time * 1000).fromNow();
      return vardata;
    },
    openStudentDetail(item) {
      let studentId = item.studentId;
      // recordAnalytics here
      // this.$router.push("teacher/student/"+ studentId +"/practicetestreport");
      this.$router.push("/teacher/student/a");
    },
  },

  watch: {
    options: {
      handler() {
        let itemsPerPage = this.options.itemsPerPage;
        let page = this.options.page;
        let max = itemsPerPage * page;
        let length = this.studentsData.length;
        console.log(length / max);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.assignment-card {
  background: #303146;
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.card-bg {
  background-color: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;
}
.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}
.heading {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}
.page-layout {
  min-height: calc(100vh - 50px);
  background: linear-gradient(180deg, #3e4ea3 250px, #f5f6fa 250px);
}
.filter-block {
  border: 1px solid white;
  border-radius: 12px;
  color: white !important;
  width: fit-content;
  background: transparent;
  margin: 20px auto;
  position: relative;
  padding: 10px 15px 5px;
}
.filter-header {
  position: absolute;
  padding: 0 10px 0 7px;
  top: -14px;
  left: 10px;
  background: #404ca4;
  font-size: 1rem;
}
.generate-btn {
  background: transparent !important;
  border: 1px solid white !important;
  border-radius: 7px;
}
.body-container {
  background: white;
  min-height: calc(100% - 120px);
  max-height: 100%;
  border-radius: 12px !important;
}
.table-header {
  font-size: 1.15rem !important;
}

.text-green {
  color: green;
}
.text-orange {
  color: orange;
}
.text-red {
  color: red;
}

.border {
  /* border: thin solid rgba(0, 0, 0, 0.12); */
}

.create-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 12px;
  width: 150px;
}

</style>

<style>

/* .striped-rows > .v-data-table__wrapper > table > tbody > tr:nth-child(even),
.striped-rows > .v-data-table__wrapper > table > thead > tr {
  background-color: #f8f4fc !important;
} */

.header-table{
  font-weight: 600 !important;
  border-right: 1px solid #ffffff3b !important;
  border-bottom: 1px solid #ffffff3b !important;
}

.cell-table{
  border-right: 1px solid #ffffff3b !important;
  
}

.no-right-border{
  border-right: none !important;
}

.table-bg .v-table__wrapper > table > tbody > tr:not(:last-child) > td{
  border-bottom: 1px solid #ffffff3b !important;
}

</style>
