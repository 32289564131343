<template>
    <v-overlay persistent :model-value="showLoader" class="align-center justify-center" style="z-index: 2500;">
        <v-progress-circular :size="100" :width="7" color="progress" indeterminate></v-progress-circular>
    </v-overlay>
</template>
    
<script>
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";


export default {
    name: 'SSOChallengePage',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        delay: {
            type: Number,
            default: 400
        },
    },
    async created() {



    },
    data: () => ({
        showLoader: false,
        timeout: null
    }),

    computed: {
        ...mapState(["aiChallengeSSOInfoError", "loaderCount"]),

    },

    watch: {
        show(show) {
            if (show) {
                this.showLoader = true
                clearTimeout(this.timeout)
            }
            else {
                this.timeout = setTimeout(() => {
                    this.showLoader && (this.showLoader = false)
                }, this.delay);
            }
        }
    },

    methods: {
        acceptChallenge() {
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeAcceptAIChallenge, {
                challengeId: this.aiChallengeSSOInfo.challengeId
            });
            this.$router.replace(`/challenge/participantwaitingroom/${this.aiChallengeSSOInfo.challengeId}?type=ai`)

        },
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
    