<template>
  <v-container style="position: relative" fluid class="ma-0 pa-4 bg-transparent">
    <!-- <div class="pl-4 mt-4 t-para">
      <v-row justify="center">
        <v-col sm="5" md="5">
          <v-text-field
import NewPopup from "../newLogin/NewPopup.vue";
            bg-color="secondary"
            v-model="searchText"
            variant="flat"
            label="Browse topics.."
            append-inner-icon="mdi-magnify"
            single-line
            clearable
            hide-details
            @click:append-inner="onClick"
            @click:clear="clearSearch" 
            v-on:input="debounceSearch"
          ></v-text-field>
        </v-col>
      </v-row>
    </div> -->
    
    <v-dialog persistent v-model="showInfoTab" transition="dialog-top-transition" width="1024">
      <template v-slot:activator="{ props }">
        <v-btn 
          icon="mdi-information-variant" 
          class="info-icon t-btn text-none rounded-full" 
          density="compact" 
          variant="flat"
          color="cardBackgroundColor"
          v-bind="props"
          @click="showInfoTab= true"
        >
        </v-btn>
      </template>
      
        <v-card class="info-card text-white pa-4"  >

          <v-card-actions class="justify-end pa-0">
            <v-btn 
              icon="mdi-close" 
              class="t-btn text-none rounded-full mx-6" 
              density="compact" 
              variant="outlined"
              color="white"
              @click="closeInfoTab"
            >
            </v-btn>
          </v-card-actions>
          
          <v-card-text class="pt-0 px-3 px-sm-6 pt-0">
            <v-container class="px-0 pt-0">
              <v-row>
                <!-- Left Side -->
                <v-col cols="12" sm="5" class="align-self-stretch d-flex flex-column " >
                  <v-row no-gutters>
                    <!-- <v-col cols="12" class="d-flex justify-center justify-sm-start align-center">
                      <v-avatar class="ma-0" size="45px">
                        <v-img src="@/assets/info_sat.svg"></v-img>
                      </v-avatar>
                    </v-col> -->
                    
                    <v-col cols="12" class="mt-3">
                      <p class="t-h5 text-center text-sm-left">Digital SAT</p>                  
                    </v-col>

                    <v-col cols="12" class="mt-3 mt-sm-0">
                      <p class="t-h6Reg info-content text-center text-sm-left">
                        In the Digital SAT English test, students will have 64 minutes to complete 54 questions. These questions are divided into two sections of 27 questions each, with 32 minutes allotted for each section.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row v-if="smAndUp">
                    <v-col class="pb-0" align-self="end">
                      <v-img src="@/assets/info_mento_bulb.png" class="info-img-bulb"></v-img>

                    </v-col>
                  </v-row>
                </v-col>

                <!-- Right Side -->
                <v-col cols="12" sm="7" class="mt-4 mt-sm-3">
                  <v-row justify="space-between">
                    <v-col cols="auto">
                      <p class="t-h6">Question Types</p>
                    </v-col>
                    
                    <v-col cols="auto" class="text-right">
                      <p class="t-h6">Distribution</p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pb-0">
                      <v-card elevation="0" class="info-syllabus-card text-white">
                        <v-card-text class="pa-0">
                          <v-row class="ma-0 pa-0" justify="center" align="center"> 
                            <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                              <p >Word in Context</p>
                              <p>Text Structure & Purpose</p>
                              <p>Cross Text Connections</p>
                            </v-col>
                            <v-col cols="4" class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                              <p class="text-white">13 - 15 Questions</p>
                              <p class="text-progress">~28% Weightage</p>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row >
                    <v-col class="pb-0">
                      <v-card elevation="0" class="info-syllabus-card text-white">
                        <v-card-text class="pa-0">
                          <v-row class="ma-0 pa-0" justify="center" align="center"> 
                            <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                              <p>Central Ideas & Details</p>
                              <p>Command of Evidence (Both Textual & Competitive)</p>
                              <p>Inferences</p>
                            </v-col>
                            <v-col cols="4" class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                              <p class="text-white">12 - 14 Questions</p>
                              <p class="text-progress">~ 26% Weightage</p>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="pb-0">
                      <v-card elevation="0" class="info-syllabus-card text-white">
                        <v-card-text class="pa-0">
                          <v-row class="ma-0 pa-0" justify="center" align="center"> 
                            <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                              <p>Boundaries</p>
                              <p>Form, Structure, and Sense</p>
                            </v-col>
                            <v-col cols="4" class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                              <p class="text-white">11 - 15 Questions</p>
                              <p class="text-progress">~ 26% Weightage</p>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col class="pb-0">
                      <v-card elevation="0" class="info-syllabus-card text-white">
                        <v-card-text class="pa-0">
                          <v-row class="ma-0 pa-0" justify="center" align="center"> 
                            <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                              <p>Rhetorical Synthesis</p>
                              <p>Transitions</p>
                            </v-col>
                            <v-col cols="4" class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                              <p class="text-white">8 - 12 Questions</p>
                              <p class="text-progress">~ 20% Weightage</p>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          

        </v-card>

    </v-dialog>


    <div 
      class="mt-4"
      :class="{
        'entity-container': lg,
        'entity-container-xl': xl,
        'entity-container-xxl': xxl,
      }">
        <v-container fluid class="bg-transparent">
            <v-row class="text-center mb-4" align="center" justify="center">
              <v-col cols="12">
                <span class="text-white t-h125">Master each topic and boost your SAT scores!</span>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="learnNodes.length > 0" :justify="mdAndDown ? 'center' : 'space-between'">
                <v-sheet class="d-flex flex-wrap align-center justify-center bg-transparent">
                  <v-sheet
                    v-for="(item, i) in learnNodes" 
                    :key="i"
                    class="ma-1 pa-2 bg-transparent"
                  >
                      <v-hover v-slot="{ isHovering, props }">
                        <v-card 
                        class="entity-item" 
                        :class="{ 'on-hover': isHovering }" 
                        v-bind="props"
                        @click="item.isReady ? learnNodeClicked(item, $event) : null"
                        :style="item.isReady ? isEven(i) ? 'background: linear-gradient(180deg, #BF98FF 0%, #656CFF 100%);' : 'background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);' : 'background: #26253A;'"
                        >
                          <v-card-text class="py-0">
                            <v-row align="center" justify="center">
                              <v-col cols="12">
                                <v-col cols="12" align-self="center" class="text-center" style="padding-top: 19px; padding-bottom: 8px;">
                                    <v-img height="50" :src="getSkillLevelIcon(item.isReady, item.skill)" />
                                </v-col>
                                <v-col cols="12" class="text-center pt-0 px-0" align-self="start">
                                    <span class="text-white t-86rem">
                                        {{ item.entityLabel }}
                                    </span>
                                </v-col>
                                <v-col cols="auto" class="text-center pt-0">
                                  <v-row style="background: rgba(255, 255, 255, 0.15);" align="center" justify="center" class="skill-container" dense no-gutters>

                                    <v-col cols="auto">
                                      <v-avatar size="20" color="transparent" class="pa-1">
                                        <v-img :src="getIconSrc(item.isReady ?'assets/images/recommended.svg' : 'assets/images/coming_soon.svg')"></v-img>
                                      </v-avatar>
                                    </v-col>
                                    <v-col cols="auto">
                                      <span class="skill-box pa-2 text-white">
                                          {{ item.isReady ?  getSkillName(item.skill) : 'Coming soon' }}
                                      </span>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-hover>
                  </v-sheet>
                </v-sheet>
            </v-row>
        </v-container>
    </div>
  </v-container>
</template>

<script>
import {AnalyticsEvents, recordAnalyticsEvent} from "@/services/awsPinpointAnalyticsService";
import { mapState } from 'vuex';
import { useDisplay } from 'vuetify';
import _ from "lodash";

export default {
    name: "LearnPage",
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {
    this.$store.commit("updateNavBarItems", {data : "/learn", show: false})

        recordAnalyticsEvent(AnalyticsEvents.v2LearnScreenOpen);
        if (this.userCurrentDomain) {
            this.currentParentNodeId = this.userCurrentDomain;
            this.$store.dispatch("loadNewLearnNodes");
        }
        // if (localStorage.getItem("newUser") == "true" && localStorage.getItem("newLearn") == "true") {
        //     this.showInfoTab = true;
        // }
    },
    data: () => ({
        searchText: "",
        filterSearchVal: "",
        showInfoTab: false,
    }),
    computed: {
        ...mapState(["newLearnNodes", "userCurrentDomain"]),
        learnNodes: {
            get() {
                let nodesToDisplay = this.newLearnNodes;
                if (this.filterSearchVal) {
                    nodesToDisplay = nodesToDisplay?.filter(item => item.entityLabel?.toLowerCase()
                        .includes(this.filterSearchVal.toLowerCase()));
                }
                nodesToDisplay.map(item => {
                    if (!item.skill) {
                        item.skill = "Beginner";
                    }
                    else {
                        item.skill = item.skill.charAt(0).toUpperCase() + item.skill.substr(1).toLowerCase();
                    }
                });
                return nodesToDisplay;
            }
        }
    },
    methods: {
        onClick() { },
        learnNodeClicked(item) {
            console.log(item);
            recordAnalyticsEvent(AnalyticsEvents.v2LearnScreenTopicClicked, {
                topicId: item.entityId,
                topicName: item.entityLabel,
                currentSkill: item.skill,
                isReady: item.isReady
            });
            this.$router.push(`/learn/${item.entityId}`);
        },
        clearSearch() {
            this.filterSearchVal = this.searchText;
        },
        debounceSearch: _.debounce(function () {
            this.filterSearchVal = this.searchText;
            if (this.filterSearchVal) {
                // recordAnalyticsEvent(AnalyticsEvents.v2LearnSearch, { searchText: this.filterSearchVal });
            }
        }, 500),
        getSkillName(skill) {
            skill = skill.toLowerCase();
            if (skill == "notset") {
                return "Get started";
            }
            else {
                return this.getCapitalizedText(skill);
            }
        },
        getSkillLevelTextColor(skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let color = "#323232";
            if (skillLevel) {
                if (skillLevel != "notset") {
                    color = "#FFFFFF";
                }
            }
            return color;
        },
        getSkillLevelColor(skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let color = "#D1D1D1";
            if (skillLevel) {
                if (skillLevel == "beginner") {
                    color = "#FF894F";
                }
                else if (skillLevel == "intermediate") {
                    color = "#55B56A";
                }
                else if (skillLevel == "advanced") {
                    color = "#01ACBE";
                }
                else if (skillLevel == "mastery") {
                    color = "#1975CF";
                }
            }
            return color;
        },
        getSkillLevelIcon(isReady, skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let icon = "assets/images/medal_coming_soon.svg";
            if (isReady && skillLevel) {
                if (skillLevel == "notset") {
                    icon = "assets/images/medal_start_enable.svg";
                }
                else if (skillLevel == "beginner") {
                    icon = "assets/images/medal_beginner_enable.svg";
                }
                else if (skillLevel == "intermediate") {
                    icon = "assets/images/medal_intermediate_enable.svg";
                }
                else if (skillLevel == "advanced") {
                    icon = "assets/images/medal_advanced_enable.svg";
                }
                else if (skillLevel == "mastery") {
                    icon = "assets/images/medal_mastery_enable.svg";
                }
            }
            return this.getIconSrc(icon);
        },
        getIconSrc(url) {
            return require(`@/${url}`);
        },
        getCapitalizedText(text) {
            return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        },
        isEven(number) {
            if (number % 2 === 0) {
                return true;
            }
            else {
                return false;
            }
        },
        closeInfoTab() {
            // localStorage.setItem("newLearn", false);
            // localStorage.setItem("newUser", false);
            this.showInfoTab = false;
        }
    },
    watch: {
        userCurrentDomain() {
            if (this.userCurrentDomain) {
                this.$store.dispatch("clearNewLearnNodes").then(() => {
                    this.$store.dispatch("loadNewLearnNodes");
                });
            }
        }
    },
};
</script>

<style scoped>

.t-86rem{
  font-size: 0.86rem !important;
}
.bg-transparent{
  background-color: transparent;
}
.entity-container{
    padding-left: 8%;
    padding-right: 8%;
    background-color: transparent;
}

.entity-container-xl{
    padding-left: 17%;
    padding-right: 17%;
    background-color: transparent;
}

.entity-container-xxl{
    padding-left: 28%;
    padding-right: 28%;
    background-color: transparent;
}
.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-navColor));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.skill-container{
    display: inline-flex;
    width: fit-content;
    border-radius: 8px;
    padding: 5px 10px;
}
.skill-box{
    border-radius: 8px;
    font-family: "Inter", sans-serif !important;
    font-size: 0.8rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
}
.entity-item{
    /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
    border-radius: 16px;
    height: 160px;
    width: 230px;
}

.info-icon{
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.info-card{
  background-color: #26253A;
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 15px !important;
}

.info-content{
  line-height: 1.375rem !important;
}

.info-img-bulb{
  width: 10.13088rem;
  height: 13.13088rem;
}

.info-syllabus-card{
  border-radius: 0.9375rem !important;
  background: #45455F;
}

.info-syllabus-marks{
  gap: 0.56rem !important;
  background: #313145;
}

.info-syllabus-topic{
  gap: 0.56rem !important;
  font-size: 0.8rem !important;
}

.v-overlay.v-overlay--active{
  background: rgba(0, 0, 0, 0.75) !important;
  backdrop-filter: blur(12.5px) !important;
}
</style>
<style>
</style>