<template>
  <v-tooltip location="top">
    <template v-slot:activator="{ props: tooltip }">
      <div v-if="showSelfHelpGuideFeature" class="self-help-button-container" v-bind="mergeProps(tooltip, $attrs)">
        <v-icon icon="mdi-tooltip" class="mx-2 t-1rem"></v-icon>
        <span class="mr-2 t-h6Reg text-white">Help Guide</span>
        <div class="self-help-toggler">
          <input type="checkbox" id="toggle" v-model="showSelfHelpGuide">
          <label for="toggle"></label>
        </div>
      </div>    
    </template>
    <span>You can always turn on the guide by clicking here.</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mergeProps } from 'vue';

export default {
 name: 'SelfHelpGuideTogglerComponent',
 computed: {
  ...mapState(["showSelfHelpGuide"]),
  ...mapGetters(["showSelfHelpGuideFeature"]),
  showSelfHelpGuide: {
    get(){
      return this.$store.state.showSelfHelpGuide;
    },
    set(value){
      this.$store.commit('toggleSelfHelpGuide', value);
      let reqData = {
        preferenceItemName : 'turnOnSelfHelpGuide',
        preferenceItemValue : value,
      }
      this.$store.dispatch('setUserPreferences', reqData);
    }
  }
 },
 methods: {
  mergeProps
 }
}
</script>

<style scoped>
.self-help-toggler {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.self-help-toggler input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.self-help-toggler label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.2s;
  border-radius: 34px;
}

.self-help-toggler label:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked state */
.self-help-toggler input:checked + label {
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
}

.self-help-toggler input:checked + label:before {
  transform: translateX(12px);
}

.self-help-button-container{
  display: flex;
  justify-content: space-betwee;
  align-items: center;
  border: 2px solid #ffffff1f;
  background: #44445D;
  border-radius: 50px;
  padding: 8px;
  color: #ffffff99;
}

</style>