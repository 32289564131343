<template>
  <div>
    <v-dialog
      style="backdrop-filter: blur(12.5px); z-index: 3000"
      v-model="showUnsavedChangesDialog"
      width="fit-content"
    >
      <v-card elevation="0" class="assignment-form text-white" max-width="400px">
        <v-card-text class="text-white">
          <v-row dense class="t-1remReg mb-1 mx-1" justify="center">
            <v-col class="text-center"> Are you sure you want to leave? </v-col>
            <v-col class="text-right" cols="auto">
              <v-icon @click.stop="showUnsavedChangesDialog = false" size="20"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
          <v-row
            dense
            class="t-subtitle text-center mx-2 mb-3 mx-sm-auto"
            justify="center"
          >
            You may have some unsaved changes on this page.
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0 ma-0">
          <v-spacer></v-spacer>
          <v-btn
            class="cancel-btn px-4 t-btn text-none text-primaryTextColor mr-2"
            density="default"
            variant="flat"
            height="40"
            @click.stop="showUnsavedChangesDialog = false"
            >Don't leave</v-btn
          >
          <v-btn
            class="save-btn px-4 t-btn text-none text-primaryTextColor ml-2"
            density="default"
            variant="flat"
            height="40"
            @click.stop="closeNotesDialog()"
            >Leave anyway</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      style="backdrop-filter: blur(12.5px); z-index: 3000"
      v-model="errorDialog"
      width="fit-content"
    >
      <v-card elevation="0" class="assignment-form text-white" max-width="400px">
        <v-card-text class="text-white">
          <v-row dense class="t-1remReg mb-1 mx-1" justify="center">
            <v-col class="text-center"> Error </v-col>
            <v-col class="text-right" cols="auto">
              <v-icon @click.stop="closeNotesDialog()" size="20">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row
            dense
            class="t-subtitle text-center mx-2 mb-3 mx-sm-auto"
            justify="center"
          >
            {{ errorMessage }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      fullscreen
      no-click-animation
      persistent
      v-model="showNotesDialog"
      scrollable
    >
      <v-card class="canvasSolidBackground">
        <v-layout>
          <div id="popupMenu" class="popMenu">
            <v-card class="pa-2 px-2">
              <v-row class="px-2 py-2">
                <v-icon
                  icon="mdi-trash-can-outline"
                  color="black"
                  size="25"
                  @click="deleteSelectedShape()"
                >
                </v-icon>
              </v-row>
            </v-card>
          </div>

          <v-app-bar color="#ffffff" :elevation="4" height="36">
            <v-app-bar-title class="mx-4">
              <v-sheet
                class="d-flex flex-row justify-space-between align-center w-100 transparent-background"
              >
                <!-- Here -->
                <v-sheet class="transparent-background">
                  <v-btn-group
                    v-if="isOpenedFromTeacherView"
                    density="compact"
                    color="white"
                    mandatory
                    rounded="0"
                    class="mr-2 toolbar-left-border"
                  >
                    <v-tooltip v-for="tool in tools" :key="tool.toolId" location="bottom">
                      <template #activator="{ props }">
                        <v-btn
                          class="pa-0"
                          min-width="48"
                          v-bind="props"
                          @click="selectTool(tool.toolId)"
                          :color="tool.toolId == selectedTool ? 'primaryPurple' : 'white'"
                        >
                          <v-icon
                            :icon="`${tool.toolIcon}`"
                            size="large"
                            :color="
                              tool.toolId == selectedTool ? 'white' : 'secondaryTextColor'
                            "
                          ></v-icon>
                        </v-btn>
                        <v-divider v-if="tool.showDivider" vertical></v-divider>
                      </template>
                      <span>
                        {{ tool.toolName }}
                      </span>
                    </v-tooltip>

                    <v-menu>
                      <template v-slot:activator="{ props: menu }">
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props: tooltip }">
                            <v-btn
                              class="pa-0"
                              min-width="48"
                              v-bind="mergeProps(menu, tooltip)"
                              @click="showColorMenu = !showColorMenu"
                            >
                              <div class="d-flex align-center flex-column justify-center">
                                <v-icon icon="mdi-circle" :color="selectedColor"></v-icon>
                              </div>
                            </v-btn>
                            <!-- <v-divider></v-divider> -->
                          </template>
                          <span> Choose the color for the given tool </span>
                        </v-tooltip>
                      </template>

                      <v-color-picker
                        class="colorPicker"
                        v-model="selectedColor"
                        hide-sliders
                        hide-canvas
                        show-swatches
                        hide-inputs
                        :swatches="colorSwatches"
                        width="fit-content"
                      ></v-color-picker>
                    </v-menu>

                    <v-menu>
                      <template v-slot:activator="{ props: menu }">
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props: tooltip }">
                            <v-btn
                              class="pa-0"
                              min-width="48"
                              v-bind="mergeProps(menu, tooltip)"
                            >
                              <v-icon
                                icon="mdi-format-line-weight"
                                color="secondaryTextColor"
                                size="large"
                              ></v-icon>
                            </v-btn>
                            <v-divider vertical></v-divider>
                          </template>
                          <span>Change line thickness</span>
                        </v-tooltip>
                      </template>

                      <v-list>
                        <v-list-item
                          width="200"
                          density="compact"
                          v-for="(strokeWidthNumber, index) in strokeWidthList"
                          :key="index"
                          @click="selectStrokeWidth(strokeWidthNumber)"
                          :style="{
                            backgroundColor:
                              selectedStrokeWidth == strokeWidthNumber
                                ? '#A174FD50'
                                : '#FFFFFF',
                          }"
                        >
                          <v-list-item-title class="d-flex justify-center align-center">
                            <v-sheet
                              :color="
                                selectedStrokeWidth == strokeWidthNumber
                                  ? 'primaryPurple'
                                  : 'secondaryTextColor'
                              "
                              :height="strokeWidthNumber"
                              class="w-100"
                              tile
                            ></v-sheet>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-btn-group>
                </v-sheet>

                <v-sheet class="transparent-background">
                  <v-btn-group
                    density="compact"
                    v-model="selectedTool"
                    color="white"
                    mandatory
                    rounded="0"
                  >
                    <v-tooltip location="bottom">
                      <template #activator="{ props }">
                        <v-btn
                          class="pa-0"
                          min-width="48"
                          v-bind="props"
                          @click="scaleDown"
                        >
                          <v-icon
                            icon="mdi-magnify-minus-outline"
                            size="x-large"
                          ></v-icon>
                        </v-btn>
                        <v-divider vertical></v-divider>
                      </template>
                      Zoom out
                    </v-tooltip>
                    <v-sheet class="py-1 px-2 bg-white" min-width="48">
                      <span class="t-h6Reg">
                        {{ zoomLevelsAllowed[currentZoomLevelIndex] * 100 }}%
                      </span>
                    </v-sheet>
                    <v-divider vertical></v-divider>
                    <v-tooltip location="bottom">
                      <template #activator="{ props }">
                        <v-btn
                          class="pa-0"
                          min-width="48"
                          v-bind="props"
                          @click="scaleUp"
                        >
                          <v-icon icon="mdi-magnify-plus-outline" size="x-large"></v-icon>
                        </v-btn>
                      </template>
                      Zoom in
                    </v-tooltip>
                  </v-btn-group>
                </v-sheet>

                <v-sheet class="transparent-background">
                  <v-btn
                    v-if="isOpenedFromTeacherView"
                    variant="flat"
                    class="t-btn text-capitalize button-disabled-style"
                    :color="unsavedChanges ? 'primaryPurple' : 'iconGrey'"
                    :disabled="!unsavedChanges"
                    width="110"
                    elevation="0"
                    @click="saveNotes()"
                    rounded="0"
                  >
                    Save
                  </v-btn>
                  <v-tooltip location="bottom">
                    <template #activator="{ props }">
                      <v-icon
                        v-bind="props"
                        @click="closeNotesDialogCheck()"
                        color="secondaryTextColor"
                        class="mx-2"
                      >
                        mdi-close</v-icon
                      >
                    </template>
                    <span>Close</span>
                  </v-tooltip>
                </v-sheet>
              </v-sheet>
            </v-app-bar-title>
          </v-app-bar>
          <v-main class="main-div" id="main-div">
            <div id="canvasStage" class="ccccP"></div>
          </v-main>
        </v-layout>
      </v-card>
      <v-snackbar
        v-model="showNotesSnackbar"
        :timeout="-1"
        color="cardBackgroundColor"
        location="bottom"
      >
        <span class="text-white">{{ snackbarNotesMsg }}</span>
        <template v-slot:actions>
          <v-btn
            density="compact"
            color="#8e8ea7"
            @click.stop="showNotesSnackbar = false"
            icon="mdi-close"
          ></v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import * as htmlToImage from "html-to-image";
import {
  dataURLtoFile,
  uploadFile,
  uploadFiles,
  uploadJsonFiles,
} from "@/services/storageService";
import { v4 as uuidv4 } from "uuid";
import moment from "moment-timezone";
import { getLoggedInUserId } from "@/services/authService";

import { mapState } from "vuex";
import { mergeProps } from "vue";
import axios from "axios";
import { LazyBrush } from "lazy-brush";
import Konva from "konva"; // Import Konva.js

import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";

export default {
  name: "ScribbleComponent",
  props: {
    // capturedImageUrl: {
    //   type: String,
    //   required: true,
    // },
    screenshotElement: {
      type: Object,
      required: true,
    },
    testInstanceId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: true,
    },

    isTeacherNotesAttached: {
      type: Boolean,
      required: true,
      default: false,
    },
    isOpenedFromTeacherView: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  // props: ['capturedImageUrl', 'screenshotElement'],
  data: () => ({
    errorMessage: "We are facing some issues right now. Please try again later",
    errorDialog: false,
    showNotesDialog: false,
    konvaDetails: null,
    stage: null,
    layer: null,
    userDrawLayer: null,
    lastPointerPosition: null,
    selectedCursor: `url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' transform='rotate(90)' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z'/%3E %3C/svg%3E"), pointer`,
    lastLine: null,
    lastLinePoints: null,
    image: null,
    dataLoaded: false,
    strokeScaleEnabled: false,
    transformer: null,
    popupMenu: null,
    canvasWidth: window.innerWidth,
    canvasHeight: window.innerHeight,
    canvasOriginalWidth: window.innerWidth,
    canvasOriginalHeight: window.innerHeight,
    hitStrokeWidth: 24,
    currentTextNode: null,
    eraserCursor: false,
    tools: [
      {
        toolId: "selector",
        toolName: "Selector",
        toolIcon: "mdi-cursor-default-outline",
        showDivider: true,
      },
      {
        toolId: "pen",
        toolName: "Draw",
        toolIcon: "mdi-brush-outline",
        showDivider: false,
      },
      {
        toolId: "highlighter",
        toolName: "Highlighter",
        toolIcon: "mdi-marker",
        showDivider: false,
      },
      {
        toolId: "eraser",
        toolName: "Erase",
        toolIcon: "mdi-eraser",
        showDivider: true,
      },
      {
        toolId: "textbox",
        toolName: "Text Box",
        toolIcon: "mdi-format-textbox",
        showDivider: true,
      },
      {
        toolId: "line",
        toolName: "Line",
        toolIcon: "mdi-vector-line",
        showDivider: false,
      },
      {
        toolId: "arrow",
        toolName: "Arrow",
        toolIcon: "mdi-arrow-top-right-thin",
        showDivider: true,
      },
      {
        toolId: "rectangle",
        toolName: "Rectangle",
        toolIcon: "mdi-rectangle-outline",
        showDivider: false,
      },
      {
        toolId: "circle",
        toolName: "Circle",
        toolIcon: "mdi-circle-outline",
        showDivider: false,
      },
      {
        toolId: "ellipse",
        toolName: "Ellipse",
        toolIcon: "mdi-ellipse-outline",
        showDivider: true,
      },
    ],
    selectedTool: "pen",
    isDrawing: false,
    lazyBrush: null,
    lazyBrushPoints: [],
    selectedShapeName: "",
    capturedImageUrl: "",
    currentTextArea: null,
    toolbarTop: 50, // Initial position
    toolbarLeft: 50, // Initial position
    startX: 0,
    startY: 0,
    zoomLevelsAllowed: [0.5, 0.75, 1, 1.25, 1.5, 2],
    currentZoomLevelIndex: 2,
    currentShape: null,
    shapePosStart: null,
    shapePostNow: null,
    showColorMenu: false,
    colorSwatches: [
      ["#0010FF", "#27D7FF", "#E9E9E9"],
      ["#64C521", "#C2ED6E", "#CCCCCC"],
      ["#FFCA00", "#FEEF80", "#AAAAAA"],
      ["#FF7700", "#FFC59D", "#777777"],
      ["#F93637", "#FF8F9D", "#444444"],
      ["#AD4084", "#FF9DFF", "#000000"],
    ],
    selectedColor: "#0010FF",
    strokeWidthList: [1, 2, 4, 6, 8, 12],
    selectedStrokeWidth: 2,
    unsavedChanges: false,
    showNotesSnackbar: false,
    snackbarNotesMsg: "",
    showUnsavedChangesDialog: false,
    stageDomElement: null,
    readyForNodesMoveFromDrawToViewLayer: false,
    temp_lastTime: performance.now(),
    temp_frameCount: 0,
    temp_mousemovecount: 0,
  }),
  created() {
    this.showNotesDialog = true;
  },
  async mounted() {
    try {
      this.dataLoaded = false;
      this.stageDomElement = document.getElementById("canvasStage");
      if (this.isTeacherNotesAttached) {
        let konvaDetails = await this.$store.dispatch("getTeacherNotes", {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
        });

        this.konvaDetails = konvaDetails;
        console.log("konvaDetails", konvaDetails, this.konvaDetails.success ?? false);
        //console.log("konvaDetails", konvaDetails);
        if ((this.konvaDetails.success ?? false) == false) {
          this.$store.commit("resetLoader");
          this.errorDialog = true;
          let event = {
            userType: this.isUserTeacher ? "teacher" : "student",
            isOpenedFromTeacherView: this.isOpenedFromTeacherView,
            testInstanceId: this.testInstanceId,
            questionId: this.questionId,
            notesFromServer: true,
            successfullyOpened: false,
            errorMessage: this.errorMessage,
          };
          recordAnalyticsEvent(AnalyticsEvents.v2NotesScreenOpened, event);

          return;
        }

        if (konvaDetails.success == true) {
          // assign image url
          await this.assignImageUrl(konvaDetails.item.teacherBaseLayerImageUrl);

          // getting image dimensions
          let dimensions = await this.getImageDimensions();
          this.canvasWidth = dimensions.width;
          this.canvasHeight = dimensions.height;
          this.canvasOriginalWidth = dimensions.width;
          this.canvasOriginalHeight = dimensions.height;

          // setting background image
          this.setBackgroundImage();

          // get already existing teacher notes
          let json = await this.fetchData(
            konvaDetails.item.teacherDrawingLayerDetailsUrl
          );

          // setting up the stage
          this.stage = Konva.Node.create(json, "canvasStage");
          this.stage.width(this.canvasWidth);
          this.stage.height(this.canvasHeight);
          this.stage.scaleX(1);
          this.stage.scaleY(1);

          // getting the first layer element
          this.layer = this.stage.getLayers()[0];

          this.cacheAllNodeOfViewLayer();

          if (!this.stage.getLayers()[1]) {
            this.userDrawLayer = new Konva.Layer({ name: "userDrawLayer" });
            this.stage.add(this.userDrawLayer);
          } else {
            this.userDrawLayer = this.stage.getLayers()[1];
          }

          // add transformer change here
          let tr = null;

          for (let node of this.layer.getChildren()) {
            if (node instanceof Konva.Transformer) {
              //console.log("tr", node);
              tr = node;
              break;
            }
          }

          if (tr == null) {
            // creating a transformer property
            this.transformer = new Konva.Transformer({
              ignoreStroke: true,
              padding: 8,
            });
            // adding transformer to the layer
            this.layer.add(this.transformer);
          } else {
            this.transformer = tr;
          }

          let event = {
            userType: this.isUserTeacher ? "teacher" : "student",
            isOpenedFromTeacherView: this.isOpenedFromTeacherView,
            testInstanceId: this.testInstanceId,
            questionId: this.questionId,
            notesFromServer: true,
            successfullyOpened: true,
          };
          recordAnalyticsEvent(AnalyticsEvents.v2NotesScreenOpened, event);
        }
      } else {
        // assign image url
        await this.assignImageUrl();

        // getting image dimensions
        let dimensions = await this.getImageDimensions();
        this.canvasWidth = dimensions.width;
        this.canvasHeight = dimensions.height;
        this.canvasOriginalWidth = dimensions.width;
        this.canvasOriginalHeight = dimensions.height;
        //console.log("img width, height", this.canvasWidth, this.canvasHeight);

        // setting background image
        this.setBackgroundImage();

        // creating a new stage
        this.stage = new Konva.Stage({
          container: "canvasStage",
          width: this.canvasWidth,
          height: this.canvasHeight,
        });

        //console.log(
        //  "stage w, ",
        //  this.canvasWidth,
        //  this.canvasHeight,
        //  this.stage.size(),
        //  this.stage.scale()
        //);

        // creating a new layer
        this.layer = new Konva.Layer();

        // creating a user drawing layer - the shapes will be drawn on this layer
        this.userDrawLayer = new Konva.Layer({ name: "userDrawLayer" });

        // adding the layer to stage
        this.stage.add(this.layer, this.userDrawLayer);

        // creating a transformer property
        this.transformer = new Konva.Transformer();
        // adding transformer to the layer
        this.layer.add(this.transformer);

        this.unsavedChanges = true;

        let event = {
          userType: this.isUserTeacher ? "teacher" : "student",
          isOpenedFromTeacherView: this.isOpenedFromTeacherView,
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          notesFromServer: false,
          successfullyOpened: true,
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesScreenOpened, event);
      }

      this.lazyBrush = new LazyBrush({
        enabled: true,
        radius: 1,
      });
      //console.log("lazy brush", this.lazyBrush);

      this.transformer.ignoreStroke(true);
      this.transformer.padding(10);

      const self = this;
      this.$store.commit("resetLoader");

      // creating the popup menu
      this.popupMenu = document.getElementById("popupMenu");
      this.popupMenu.style.position = "absolute";

      // hiding the popup menu initially
      this.hideTransformerPopupMenu();

      //
      // var x1, y1, x2, y2;

      // adding mouseDown, touchStart event listener to start tool functionality
      let posStart, posNow;

      if (this.isOpenedFromTeacherView) {
        this.stage.on("mousedown touchstart", function (e) {
          // if no tool selected, don't initiate drawing
          e.evt.preventDefault();

          //console.log(e);
          let pos = self.stage.getRelativePointerPosition();

          if (self.selectedTool != null) {
            // flag to start drawing
            self.isDrawing = true;
          }

          // Konva internal function to get pointer position

          // starting tool specific switch case for mouse down
          switch (self.selectedTool) {
            case "selector": {
              break;
            }

            case "circle": {
              self.addCircle(pos);
              break;
            }
            case "rectangle": {
              self.addRectangle(pos);
              break;
            }
            case "ellipse": {
              self.addEllipse(pos);
              break;
            }
            case "arrow": {
              self.addArrow(pos);
              break;
            }
            case "line": {
              self.addLine(pos);
              break;
            }

            case "pen": {
              self.createKonvaLine(pos);
              break;
            }
            case "highlighter": {
              self.createKonvaLine(pos);
              break;
            }
            case "eraser": {
              self.handleEraser(e);
              break;
            }

            // case "textbox": {
            //   self.createTextBox(pos);
            //   break;
            // }

            default: {
              break;
            }
          }
          if (self.selectedTool != "selector") {
            self.unsavedChanges = true;
          }
        });

        // adding mouseUp and touchEnd event listener to stop functionality
        this.stage.on("mouseup touchend", function (e) {
          e.evt.preventDefault();
          if (
            self.currentShape instanceof Konva.Line &&
            (self.selectedTool == "pen" || self.selectedTool == "highlighter")
          ) {
            console.log(
              `MouseMove | points :: ${self.temp_mousemovecount} | ${
                self.lastLinePoints.length / 2
              }`
            );
            self.lastLinePoints = self.smoothenKonvaPointsViaLazyBrush(
              self.lastLinePoints
            );
            //self.lastLine.bezier(true);

            //check if the nodes can be moved from userDrawLayer to layer(view layer) in batch of 21
            if (self.userDrawLayer.getChildren().length > 20) {
              self.readyForNodesMoveFromDrawToViewLayer = true;
            }
            requestAnimationFrame(() => self.drawKonvaLine());
          }
          self.currentShape = null;
          self.isDrawing = false;
        });

        // adding mouseMove and touchMove event listener to actually start drawing
        this.stage.on("mousemove touchmove", function (e) {
          e.evt.preventDefault();
          if (self.selectedTool == "selector") {
            if (e.target instanceof Konva.Stage) {
              self.stageDomElement.style.cursor = "crosshair";
            } else {
              self.stageDomElement.style.cursor = "move";
            }
          } else if (self.selectedTool == "eraser") {
            if (e.target instanceof Konva.Stage) {
              self.stageDomElement.style.cursor = "default";
            } else {
              self.stageDomElement.style.cursor = self.selectedCursor;
              //console.log(self.stageDomElement.style.cursor);
            }
          } else {
            self.stageDomElement.style.cursor = "default";
          }

          // don't do anything if not drawing
          if (!self.isDrawing) {
            return;
          }

          switch (self.selectedTool) {
            case "circle": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleCircleMove(pos);
              break;
            }
            case "rectangle": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleRectangleMove(pos);
              break;
            }
            case "ellipse": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleEllipseMove(pos);
              break;
            }
            case "arrow": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleArrowMove(pos);
              break;
            }
            case "line": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleLineMove(pos);
              break;
            }
            case "pen": {
              let pos = self.stage.getRelativePointerPosition();
              self.handleKonvaLineMove();
              break;
            }
            case "highlighter": {
              self.handleKonvaLineMove();
              break;
            }
            case "eraser": {
              self.handleEraser(e);
              break;
            }
          }
        });

        this.stage.on("click tap", function (e) {
          // if click on stage / empty area - remove transfromer
          e.evt.preventDefault();
          //console.log("tap", e);

          let pos = self.stage.getRelativePointerPosition();

          if (self.currentTextArea != null) {
            self.removeTextArea();
            return;
          }

          if (!self.selectedTool) {
            self.selectedTool = "selector";
          }

          if (e.target instanceof Konva.Text && self.selectedTool != "selector") {
            self.showTextBox(e.target, pos);
            return;
          }

          //console.log("tapp", e);
          switch (self.selectedTool) {
            case "selector": {
              if (e.target.nodeType === "Stage") {
                //console.log('Clicked on empty area / stage');

                self.clearTransformerSelectedNodes();
              } else {
                //console.log('Clicked on a shape');
                //first clear any existing transformation selected shapes
                self.clearTransformerSelectedNodes();
                e.target.draggable(true);
                let selectedShape = e.target;
                self.transformer.nodes([selectedShape]);
                self.showTransformerPopupMenu();
              }
              break;
            }
            case "textbox": {
              self.createTextBox(self.stage.getRelativePointerPosition());
              break;
            }
            default:
              self.clearTransformerSelectedNodes();
              break;
          }
        });

        window.addEventListener("mouseup", (event) => {
          if (self.selectedTool == "highlighter" || self.selectedTool == "pen") {
            self.isDrawing = false;
          }
        });

        this.transformer.on("transformstart dragstart", function () {
          //console.log('Transformation or dragging started');
          self.hideTransformerPopupMenu();
          self.unsavedChanges = true;
        });

        this.transformer.on("transformend dragend", function (e) {
          //console.log('Transformation or dragging ended');
          self.showTransformerPopupMenu();
        });

        window.addEventListener("keydown", (event) => {
          if (event.key === "Backspace" || event.key === "Delete") {
            // Check for Delete key (keyCode 46)
            if (self.transformer.nodes().length > 0) {
              for (let node of self.transformer.nodes()) {
                node.destroy();
              }
              self.transformer.nodes([]);
              self.hideTransformerPopupMenu();
            }
          }
        });
      }
    } catch (error) {
      console.log("error", error);
      this.$store.commit("resetLoader");
      this.errorDialog = true;
    }
  },
  methods: {
    mergeProps,
    enableEraserCursorFn(event) {
      console.log(event, "xyz");
      if (event.target instanceof Konva.Shape) {
        this.enableEraserCursor = true;
      }
    },
    removeTextArea() {
      if (this.currentTextArea && this.currentTextArea != null) {
        const stageParentDiv = document.getElementById("canvasStage");
        //console.log(this.currentTextArea);
        this.currentTextArea.blur();
        if (this.currentTextNode != null) {
          this.currentTextNode.show();
        }

        this.currentTextNode.text(this.currentTextArea.value);
        this.currentTextNode.width(
          parseFloat(this.currentTextArea.style.width.replace("px", ""))
        );
        stageParentDiv.removeChild(this.currentTextArea);
        this.currentTextArea = null;
        this.currentTextNode = null;
        this.transformer.show();
        this.unsavedChanges = true;
      }
    },
    showTextBox(textNode, pos) {
      textNode.hide();
      this.transformer.hide();
      this.currentTextNode = textNode;
      const stageParentDiv = document.getElementById("canvasStage");
      let textarea = document.createElement("textarea");
      this.selectedTool = "textbox";
      stageParentDiv.appendChild(textarea);
      //console.log(textarea);

      var textPosition = textNode.getAbsolutePosition();

      // then lets find position of stage container on the page:
      var stageBox = this.stage
        .container()
        .getBoundingClientRect({ relativeTo: stageParentDiv });

      // so position of textarea will be the sum of positions above:
      var areaPosition = {
        x: stageBox.left + textPosition.x,
        y: stageBox.top + textPosition.y,
      };

      textarea.value = textNode.text();
      textarea.style.position = "absolute";
      textarea.style.top = pos.y + "px";
      textarea.style.left = pos.x - textNode.width() / 2 + "px";
      textarea.style.width = textNode.width() + "px";
      textarea.style.resize = "both";
      textarea.style.overflow = "auto";
      textarea.style.color = this.selectedColor;
      textarea.id = textNode._id;
      textarea.focus();
      this.isDrawing = false;
      this.currentTextArea = textarea;
      //console.log("addedddd");
    },
    createTextBox(pos) {
      //console.log("draw textbox");
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };

      var textNode = new Konva.Text({
        text: "Some text here",
        x: pos.x,
        y: pos.y,
        fontSize: 18,
        fill: this.selectedColor,
      });

      textNode.offsetX(textNode.width() / 2);
      textNode.offsetY(textNode.height() / 2);
      this.currentShape = textNode;
      this.userDrawLayer.add(textNode);
      this.showTextBox(textNode, pos);
    },
    handleRectangleMove(pos) {
      //console.log("draw rectangle");

      this.shapePostNow = { x: pos.x, y: pos.y };

      // get the rect with posStart & posNow as its corners, using
      // Mat.min & max to ensure proper rect.
      const posRect = {
        x1: Math.min(this.shapePosStart.x, this.shapePostNow.x),
        y1: Math.min(this.shapePosStart.y, this.shapePostNow.y),
        x2: Math.max(this.shapePosStart.x, this.shapePostNow.x),
        y2: Math.max(this.shapePosStart.y, this.shapePostNow.y),
      };

      this.currentShape.x(posRect.x1);
      this.currentShape.y(posRect.y1);
      this.currentShape.width(posRect.x2 - posRect.x1);
      this.currentShape.height(posRect.y2 - posRect.y1);
    },
    handleCircleMove(pos) {
      //console.log("draw circle");

      this.shapePostNow = { x: pos.x, y: pos.y };

      const posRect = {
        x1: Math.min(this.shapePosStart.x, this.shapePostNow.x),
        y1: Math.min(this.shapePosStart.y, this.shapePostNow.y),
        x2: Math.max(this.shapePosStart.x, this.shapePostNow.x),
        y2: Math.max(this.shapePosStart.y, this.shapePostNow.y),
      };

      let centerPointX = (this.shapePosStart.x + this.shapePostNow.x) / 2;
      let centerPointY = (this.shapePosStart.y + this.shapePostNow.y) / 2;
      this.currentShape.radius(posRect.x2 - posRect.x1);

      // this.currentShape.x(centerPointX);
      // this.currentShape.y(centerPointY);
      let xRadius = posRect.x2 - posRect.x1;
      // let yRadius = posRect.y2 - posRect.y1;
      // let radius = Math.max(xRadius, yRadius);
      // this.currentShape.radius(xRadius / 2);
    },
    handleEllipseMove(pos) {
      //console.log("drawing ellipsis");

      this.shapePostNow = { x: pos.x, y: pos.y };

      const posRect = {
        x1: Math.min(this.shapePosStart.x, this.shapePostNow.x),
        y1: Math.min(this.shapePosStart.y, this.shapePostNow.y),
        x2: Math.max(this.shapePosStart.x, this.shapePostNow.x),
        y2: Math.max(this.shapePosStart.y, this.shapePostNow.y),
      };

      let centerPointX = (this.shapePosStart.x + this.shapePostNow.x) / 2;
      let centerPointY = (this.shapePosStart.y + this.shapePostNow.y) / 2;

      this.currentShape.x(centerPointX);
      this.currentShape.y(centerPointY);

      this.currentShape.radiusX((posRect.x2 - posRect.x1) / 2);
      this.currentShape.radiusY((posRect.y2 - posRect.y1) / 2);
    },
    handleArrowMove(pos) {
      //console.log("drawing arrow");

      this.shapePostNow = { x: pos.x, y: pos.y };

      this.currentShape.points([
        this.shapePosStart.x,
        this.shapePosStart.y,
        this.shapePostNow.x,
        this.shapePostNow.y,
      ]);
    },
    handleLineMove(pos) {
      // console.log("drawing  line");

      this.shapePostNow = { x: pos.x, y: pos.y };

      this.currentShape.points([
        this.shapePosStart.x,
        this.shapePosStart.y,
        this.shapePostNow.x,
        this.shapePostNow.y,
      ]);
    },
    addRectangle(pos) {
      //console.log("draw rec");
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };
      let rect = new Konva.Rect({
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0,
        stroke: this.selectedColor,
        strokeWidth: this.selectedStrokeWidth,
        strokeScaleEnabled: this.strokeScaleEnabled,
        hitStrokeWidth: this.hitStrokeWidth,
      });
      this.currentShape = rect;
      // add the shape to the layer
      this.userDrawLayer.add(rect);
    },
    addCircle(pos) {
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };
      let circle = new Konva.Circle({
        x: pos.x,
        y: pos.y,
        radius: 0,
        stroke: this.selectedColor,
        strokeWidth: this.selectedStrokeWidth,
        strokeScaleEnabled: this.strokeScaleEnabled,
        hitStrokeWidth: this.hitStrokeWidth,
      });

      this.userDrawLayer.add(circle);
      this.currentShape = circle;
      //console.log("add circle", circle);
    },
    addEllipse(pos) {
      //console.log("draw ellipse");
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };
      let ellipse = new Konva.Ellipse({
        x: pos.x,
        y: pos.y,
        radiusX: 0,
        radiusY: 0,
        stroke: this.selectedColor,
        strokeWidth: this.selectedStrokeWidth,
        strokeScaleEnabled: this.strokeScaleEnabled,
        hitStrokeWidth: this.hitStrokeWidth,
      });
      this.currentShape = ellipse;
      // add the shape to the layer
      this.userDrawLayer.add(ellipse);
    },
    addArrow(pos) {
      //console.log("draw arrow");
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };
      let arrow = new Konva.Arrow({
        points: [pos.x, pos.y],
        stroke: this.selectedColor, // Outline color
        strokeWidth: this.selectedStrokeWidth, // Outline thickness
        fill: "white", // Fill color (use 'transparent' for hollow)
        tension: 0, // Set tension to 0 for straight lines
        lineJoin: "round", // Join type for arrow shape
        lineCap: "round", // Cap type for arrow shape
        pointerLength: this.selectedStrokeWidth + 20, // Length of the pointer head
        pointerWidth: this.selectedStrokeWidth + 20, // Width of the pointer head,
        hitStrokeWidth: this.hitStrokeWidth,
      });
      this.currentShape = arrow;
      // add the shape to the layer
      this.userDrawLayer.add(arrow);
    },
    addLine(pos) {
      // console.log("draw line");
      this.shapePosStart = { x: pos.x, y: pos.y };
      this.shapePostNow = { x: pos.x, y: pos.y };
      let line = new Konva.Line({
        points: [pos.x, pos.y],
        stroke: this.selectedColor,
        strokeWidth: this.selectedStrokeWidth,
        strokeScaleEnabled: this.strokeScaleEnabled,
        hitStrokeWidth: this.hitStrokeWidth,
      });
      this.currentShape = line;
      this.userDrawLayer.add(line);
    },
    async fetchData(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error:", error);
        this.$store.commit("resetLoader");
        this.errorDialog = true;
      }
    },
    selectTool(tool) {
      //console.log("ttol", tool);
      this.removeTextArea();
      this.selectedTool = tool;
      let event = {
        testInstanceId: this.testInstanceId,
        questionId: this.questionId,
        tool: tool,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesToolSelected, event);
    },
    selectStrokeWidth(strokeWidth) {
      this.selectedStrokeWidth = strokeWidth;
      let event = {
        testInstanceId: this.testInstanceId,
        questionId: this.questionId,
        tool: "strokeWidth",
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesToolSelected, event);
    },
    closeNotesDialogCheck() {
      if (this.unsavedChanges) {
        this.showUnsavedChangesDialog = true;
      } else {
        this.closeNotesDialog();
      }
    },
    closeNotesDialog() {
      this.$emit("closeNotesDialog");
    },
    getImageDimensions() {
      let self = this;
      return new Promise(function (resolved, rejected) {
        var i = new Image();

        i.onload = function () {
          resolved({ width: i.width, height: i.height });
        };
        i.src = self.capturedImageUrl;
      });
    },
    setBackgroundImage() {
      const backgroundImageStyle = `url(${this.capturedImageUrl})`;
      const element = document.getElementById("canvasStage");
      element.style.backgroundImage = backgroundImageStyle;
      element.style.width = `${this.canvasWidth}px`;
      element.style.height = `${this.canvasHeight}px`;
      // console.log('wh', element.style.width, element.style.height);
      // element.style.border = '2px solid green';

      const stageParentDiv = document.getElementById("canvasStage");
      stageParentDiv.style.width = `${this.canvasWidth}px`;
      stageParentDiv.style.height = `${this.canvasHeight}px`;
      stageParentDiv.style.position = "relative";

      //console.log("image width, canvas width", this.canvasWidth, this.canvasHeight);
      const self = this;
    },
    async saveNotes() {
      try {
        this.$store.commit("increamentLoader");
        if (this.isTeacherNotesAttached) {
          let uuid = this.konvaDetails.item.mentoAppTeacherNotesId;
          let storageDetails = await uploadJsonFiles(
            this.stage.toJSON(),
            "mentoAppTeacherNotes",
            uuid
          );
          let konvaDetailsUpdate = await this.$store.dispatch("updateTeacherNotes", {
            teacherDrawingLayerDetails: storageDetails.key,
            testInstanceId: this.testInstanceId,
            questionId: this.questionId,
            mentoAppTeacherNotesId: uuid,
          });

          if ((konvaDetailsUpdate.success ?? false) == false) {
            this.errorDialog = true;
            let event = {
              testInstanceId: this.testInstanceId,
              questionId: this.questionId,
              action: "update",
              successfullySaved: false,
              errorMessage: "Could not save the notes",
            };
            recordAnalyticsEvent(AnalyticsEvents.v2NotesSaveButtonClicked, event);
          } else {
            let event = {
              testInstanceId: this.testInstanceId,
              questionId: this.questionId,
              action: "update",
              successfullySaved: true,
            };
            recordAnalyticsEvent(AnalyticsEvents.v2NotesSaveButtonClicked, event);
          }
        } else {
          let uuid = uuidv4();
          let storageDetails = await uploadJsonFiles(
            this.stage.toJSON(),
            "mentoAppTeacherNotes",
            uuid
          );

          let fileName = uuid + ".png";
          let file = dataURLtoFile(this.capturedImageUrl, fileName);
          //console.log(file);
          let storageImg = await uploadFiles(file, "mentoAppTeacherNotes");

          //console.log(storageDetails);

          let konvaDetails = await this.$store.dispatch("saveTeacherNotes", {
            testInstanceId: this.testInstanceId,
            questionId: this.questionId,
            teacherUserId: getLoggedInUserId(),
            studentUserId: this.studentId,
            teacherDrawingLayerDetails: storageDetails.key,
            studentDrawingLayerDetails: "",
            teacherBaseLayerImage: storageImg.key,
            studentBaseLayerImage: "",
            mentoAppTeacherNotesId: uuid,
          });
          //console.log(konvaDetails);
          this.konvaDetails = konvaDetails;
          if ((this.konvaDetails.success ?? false) == false) {
            this.errorDialog = true;
            let event = {
              testInstanceId: this.testInstanceId,
              questionId: this.questionId,
              action: "add",
              successfullySaved: false,
              errorMessage: "Could not save the notes",
            };
            recordAnalyticsEvent(AnalyticsEvents.v2NotesSaveButtonClicked, event);
          } else {
            let event = {
              testInstanceId: this.testInstanceId,
              questionId: this.questionId,
              action: "add",
              successfullySaved: true,
            };
            recordAnalyticsEvent(AnalyticsEvents.v2NotesSaveButtonClicked, event);
          }
        }
        this.$emit("upateTeacherNote");
        this.unsavedChanges = false;
        this.snackbarNotesMsg = "Changes to notes are saved.";
        this.showNotesSnackbar = true;
        this.$store.commit("resetLoader");
      } catch (error) {
        console.log("error", error);
        this.$store.commit("resetLoader");
        this.errorDialog = true;
        let event = {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          action: "save",
          successfullySaved: false,
          errorMessage: error,
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesSaveButtonClicked, event);
      }
    },
    async assignImageUrl(url = null) {
      if (url == null) {
        const images = this.screenshotElement.querySelectorAll("img");

        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          const imageUrl = image.src + "?ver=" + this.$store.state.appVersion;
          if (imageUrl.includes("base64")) {
            continue;
          }
          console.log("imgg", imageUrl);
          const base64Url = await this.convertImageToBase64(imageUrl);
          if (base64Url) {
            image.src = base64Url;
            console.log("base64", base64Url);
          }
          // image.src = image.src + "?test1";
        }
        this.capturedImageUrl = await htmlToImage.toPng(this.screenshotElement, {
          pixelRatio: 1,
        });
      } else {
        this.capturedImageUrl = url;
      }
    },
    async convertImageToBase64(imageUrl) {
      try {
        const response = await axios.get(imageUrl, {
          responseType: "arraybuffer",
        });
        console.log("imggg", response);
        const arrayBuffer = response.data;
        const byteString = Array.from(new Uint8Array(arrayBuffer))
          .map((x) => String.fromCharCode(x))
          .join("");
        const base64 = btoa(byteString);
        console.log(base64);
        const mimeType = response.headers["content-type"];
        let event = {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          successfullyConverted: true,
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);
        return `data:${mimeType};base64,${base64}`;
      } catch (error) {
        console.error("Error fetching image:", imageUrl, error);
        this.$store.commit("resetLoader");
        this.errorDialog = true;
        let event = {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          successfullyConverted: false,
          errorMessage: error,
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);

        return null; // Or set a default image on error
      }
    },
    clearTransformerSelectedNodes() {
      for (let item of this.transformer.nodes()) {
        item.draggable(false);
      }
      const textareas = document.querySelectorAll("textarea");

      // Iterate through each textarea and remove it
      textareas.forEach((textarea) => {
        textarea.remove();
      });
      this.transformer.nodes([]);
      this.hideTransformerPopupMenu();
    },
    hideTransformerPopupMenu() {
      this.popupMenu.style.display = "none";
    },
    showTransformerPopupMenu() {
      const transformerRect = this.transformer.getClientRect();
      const containerRect = this.stage.container().getBoundingClientRect(); // relative to  ??

      let popuoMenuTop = containerRect.top + transformerRect.y;
      let popuoMenuLeft =
        containerRect.left + transformerRect.x + transformerRect.width / 2 + 15;

      if (popuoMenuTop < 50) {
        popuoMenuTop = 50;
      }

      this.popupMenu.style.top = `${popuoMenuTop}px`;
      this.popupMenu.style.left = `${popuoMenuLeft}px`;
      this.popupMenu.style.display = "block";
    },
    deleteSelectedShape() {
      let shape = this.transformer.nodes()[0];
      this.transformer.nodes([]);
      shape.destroy();
      // Hide the menu initially
      this.hideTransformerPopupMenu();
      this.unsavedChanges = true;
    },
    clearDrawing() {
      this.selectedTool = null;
      const childrenToDestroy = this.layer.getChildren();
      this.layer.destroyChildren();
    },
    moveAllShapesFromDrawLayerToViewLayer() {
      let layerNodes = this.userDrawLayer.getChildren();
      let numOfObjectsToMove = layerNodes.length;
      let numOfMovedNodes = 0;
      for (let i = numOfObjectsToMove - 1; i >= 0; i--) {
        let node = layerNodes[i];
        if (!(node instanceof Konva.Transformer)) {
          node.cache({ pixelRatio: 2, imageSmoothingEnabled: false });
          numOfMovedNodes++;
        }
        node.moveTo(this.layer);
      }
      console.log(`moved: ${numOfMovedNodes}`);
    },
    cacheAllNodeOfViewLayer() {
      console.log(`cacheAllNodeOfViewLayer...`);
      let layerNodes = this.layer.getChildren();
      let numOfNodesCached = 0;
      for (const node of layerNodes) {
        if (!(node instanceof Konva.Transformer)) {
          node.cache();
          numOfNodesCached++;
        }
      }
      console.log(`cached: ${numOfNodesCached}`);
    },
    smoothenKonvaPointsViaLazyBrush(points) {
      //console.log(`smoothenKonvaPointsViaLazyBrush - input: ${JSON.stringify(points)}`)
      let smoothedPoints = [];
      const lazy = this.lazyBrush;
      smoothedPoints.push(points[0], points[1]);
      let hasmoved = false;
      let xVal = 0;
      let yVal = 0;
      let pointsLen = points.length;
      for (let i = 2; i < pointsLen - 2; i++) {
        xVal = points[i];
        yVal = points[++i];
        hasmoved = lazy.update({ x: xVal, y: yVal });
        if (hasmoved) {
          const brush = lazy.getBrushCoordinates();
          smoothedPoints.push(brush.x, brush.y);
        }
      }

      lazy.update({ x: points[pointsLen - 2], y: points[pointsLen - 1] }, { both: true });

      let lastPoint = lazy.getBrushCoordinates();
      smoothedPoints.push(lastPoint.x, lastPoint.y);

      //console.log(`smoothenKonvaPointsViaLazyBrush - output: ${JSON.stringify(smoothedPoints)}`)
      return smoothedPoints;
    },
    drawKonvaLine() {
      // called by requestAnimationFrame
      //update points in the Konva line
      this.lastLine.points(this.lastLinePoints);

      //move the nodes from userDrawLayer to layer(view layer) in batch of 21
      if (this.readyForNodesMoveFromDrawToViewLayer) {
        this.moveAllShapesFromDrawLayerToViewLayer();
        this.readyForNodesMoveFromDrawToViewLayer = false;
      }

      // Performance measurement
      this.temp_frameCount++;
      const now = performance.now();
      if (now - this.temp_lastTime >= 1000) {
        let fps = Math.round((this.temp_frameCount * 1000) / (now - this.temp_lastTime));
        console.log(`FPS:: ${fps}`);
        this.temp_frameCount = 0;
        this.temp_lastTime = now;
      }

      //request another call before next browser re-paint
      if (this.isDrawing) {
        requestAnimationFrame(() => this.drawKonvaLine());
      }
    },
    handleKonvaLineMove() {
      this.temp_mousemovecount++;
      let pos = this.stage.getRelativePointerPosition();
      this.lastLinePoints.push(pos.x, pos.y);
    },
    createKonvaLine(pos) {
      this.temp_mousemovecount = 1;
      this.lazyBrushPoints = [];
      let strokeColor = this.selectedColor;
      let strokeWidth = this.selectedStrokeWidth;

      if (this.selectedTool == "highlighter") {
        strokeColor = strokeColor + "50";
        strokeWidth = 20;
      }

      this.lastLine = new Konva.Line({
        stroke: strokeColor,
        strokeWidth: strokeWidth,
        globalCompositeOperation: "source-over",

        points: [pos.x, pos.y],
        hitStrokeWidth: 24,
        lineJoin: "round", // Join type for arrow shape
        lineCap: "round",
        strokeScaleEnabled: true,
        shadowEnabled: false,
        shadowForStrokeEnabled: false,
        draggable: false,
      });
      this.lastLinePoints = [pos.x, pos.y];

      // adding to layer
      this.currentShape = this.lastLine;
      this.userDrawLayer.add(this.lastLine);
      requestAnimationFrame(() => this.drawKonvaLine());
    },
    startDragHandle(event) {
      event.preventDefault(); // Prevent text selection
      this.handleToolbarDown(event);
    },
    getUnscaledMousePosition(event) {
      const mouseX = event.offsetX / this.stage.scaleX();
      const mouseY = event.offsetY / this.stage.scaleY();
      return { x: mouseX, y: mouseY };
    },
    scaleUp() {
      if (this.currentZoomLevelIndex < this.zoomLevelsAllowed.length - 1) {
        this.currentZoomLevelIndex++;
        let newScale = this.zoomLevelsAllowed[this.currentZoomLevelIndex];

        this.setScaleOfStageAndBackgroundImage(newScale);

        let event = {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          tool: "scaleUp",
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesToolSelected, event);
      }
    },
    scaleDown() {
      if (this.currentZoomLevelIndex > 0) {
        this.currentZoomLevelIndex--;
        let newScale = this.zoomLevelsAllowed[this.currentZoomLevelIndex];

        this.setScaleOfStageAndBackgroundImage(newScale);

        let event = {
          testInstanceId: this.testInstanceId,
          questionId: this.questionId,
          tool: "scaleDown",
        };
        recordAnalyticsEvent(AnalyticsEvents.v2NotesToolSelected, event);
      }
    },
    setScaleOfStageAndBackgroundImage(newScale) {
      const stageParentDiv = document.getElementById("canvasStage");

      //apply new scale to background div
      let sw = this.canvasOriginalWidth * newScale;
      let sh = this.canvasOriginalHeight * newScale;

      // Update background image size to match this.stage scaling
      stageParentDiv.style.width = `${sw}px`;
      stageParentDiv.style.height = `${sh}px`;

      // set the new size of the stage to match background div size
      this.stage.width(sw);
      this.stage.height(sh);

      // Apply new scale to stage
      this.stage.scaleX(newScale);
      this.stage.scaleY(newScale);
    },
    handleEraser(event) {
      if (event.target instanceof Konva.Shape) {
        event.target.destroy();
      }
    },
  },
  watch: {
    selectedTool(val) {
      if (val == "highlighter" || val == "pen") {
        this.strokeScaleEnabled = true;
      } else {
        this.strokeScaleEnabled = false;
      }
    },
  },
  computed: {
    ...mapState(["studentId", "isUserTeacher"]),
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.bgScreen {
  background-color: #ffffff;
  background-size: contain !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.popMenu {
  background-color: white;
  z-index: 2500 !important;
  min-width: 30px;
  display: none;
}

.backButton-div {
  position: fixed;
  top: 2rem;
  right: 3rem;
  z-index: 1000;
  background-color: #ffffff00;
}

.backButton {
  border-radius: 12px;
  /* box-shadow: 0 5px 25px #0000001a; */
  background-color: #ffffff;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

.canvasSolidBackground {
  background: #eeeeee !important;
  /* min-width: 100vw;
  min-height: 100vh; */
}

.main-div {
  overflow: auto;
}

.main-div {
  overflow: auto;
}

#canvasStage {
  margin: 0 auto;
  background-size: contain;
}

.transparent-background {
  background-color: #00000000;
}

.drag-handle {
  cursor: move;
}

.transparent-background {
  background-color: #00000000;
}

.drag-handle {
  cursor: move;
}

.eraserClass {
  cursor: progress !important;
}

.toolbar-left-border {
  border-left: 1px solid #0000001c;
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  /* width: 100px; */
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}
</style>

<style>
.button-disabled-style .v-btn__overlay {
  display: none !important;
}

.colorPicker .v-color-picker-swatches__color {
  width: 25px !important;
  height: 25px !important;
  max-height: 25px !important;
  margin: 4px !important;
}

.colorPicker .v-color-picker-swatches__swatch {
  margin-bottom: 0 !important;
  /* margin-top: 2px !important; */
}
</style>
