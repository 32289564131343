<template>
  <div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { createFirebaseApp } from "@/services/firebaseMessagingService";
import { getDeviceId } from "@/services/authService";
import { v4 as uuidv4 } from "uuid";

export default {
  name: 'SSOChallengePage',

  async created() {
    this.$store.commit('increamentLoader')

    let route = this.$router.currentRoute.value;
    console.log(route);
    let aicid = route.query.aicid
    console.log("aicid", aicid);
    if (this.userLoggedIn == 'true') {
      await this.$store.dispatch("getChallengeInfo", { aicid: aicid })
      console.log(this.aiChallengeSSOInfo);

    }
    else {

      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
        localStorage.setItem('deviceId', deviceId)
      }

      const response = await this.$store
        .dispatch("onBoardingStart", {
          newUser: false,
          deviceId: deviceId,
          courseId: this.courseId,
        })

      console.log("boarding res", response);
      // this.$store.commit("setAPIUrlsForFlavorAndVersion", response.appurls);
      await this.$store.dispatch("getUserInfoForChallenge", { aicid: aicid })
      await createFirebaseApp()


    }

    if (this.aiChallengeSSOInfoError != null) {
      this.$store.commit('decreamentLoader')

      this.$router.replace('/');
    }
    else {
      this.$store.commit('decreamentLoader')

      this.acceptChallenge()

    }


  },
  data: () => ({

  }),

  computed: {
    ...mapState(["userState", "userLoggedIn", "loginError", "aiChallengeSSOInfo", "aiChallengeSSOInfoError", "courseId"]),

  },

  watch: {

  },

  methods: {
    acceptChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeAcceptAIChallenge, {
        challengeId: this.aiChallengeSSOInfo.challengeId
      });
      this.$router.replace(`/challenge/participantwaitingroom/${this.aiChallengeSSOInfo.challengeId}?type=ai`)

    },
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  