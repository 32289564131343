<template>
    <v-card color="background">
        <v-card-text class="pa-3">
            <v-row justify="space-between" class="t-h5 px-3 mb-3 align-center header-container">
                
                <v-col class="ml-0 pl-0" cols="auto">
                    {{ feedbackOrQuestionReiewTitleText }}
                </v-col>
                <v-col class="text-right">
                    <v-icon size ="24" @click="closeDialog()">
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-divider class=" mt-2 mb-2">

                </v-divider>
            </v-row>  -->
            <v-row v-if="isQuestionReviewer">
                <v-col>
                    <QuestionReviewFeedbackDetails :q-id="qId" ></QuestionReviewFeedbackDetails>
                </v-col>
            </v-row>
            <v-row v-if="isQuestionReviewer">
                <v-divider class=" mt-2 mb-2">

                </v-divider>
            </v-row> 
            <v-row dense justify="start" v-if="isQuestionReviewer">
                <v-col cols="12" class="ml-2">
                    If the question meets our quality standards, please press the following button to approve.
                </v-col>
            </v-row>
            <v-row dense justify="start" v-if="isQuestionReviewer">
                <v-col cols="4" class="ml-2" v-if="isLevel1Reviewer">
                    <v-btn class="text-capitalize" rounded color="success" variant="outlined"
                        :disabled="isApproveBtnDisabled(1)"
                        prepend-icon="mdi-check-circle"
                        @click="approveQuestionReviewForLevel(1)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="success"></v-icon>
                        </template>

                        Approve | Level 1
                    </v-btn>
                </v-col>
                <v-col cols="4" class="ml-2" v-if="isLevel2Reviewer">
                    <v-btn class="text-capitalize" rounded color="success" variant="outlined"
                        :disabled="isApproveBtnDisabled(2)"
                        prepend-icon="mdi-check-circle"
                        @click="approveQuestionReviewForLevel(2)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="success"></v-icon>
                        </template>

                        Approve | Level 2
                    </v-btn>
                </v-col>
                <v-col cols="4" class="ml-2" v-if="isLevel3Reviewer">
                    <v-btn class="text-capitalize" rounded color="success" variant="outlined"
                        :disabled="isApproveBtnDisabled(3)"
                        prepend-icon="mdi-check-circle"
                        @click="approveQuestionReviewForLevel(3)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="success"></v-icon>
                        </template>
                        Approve | Level 3
                    </v-btn>
                </v-col>
                <v-col v-if="errorMessageForApprove.length>0" class="text-red">
                    {{ errorMessageForApprove }}
                </v-col>
                <v-col v-if="successMessageForApprove.length>0" class="text-success">
                    {{ successMessageForApprove }}
                </v-col>
            </v-row>
            <v-row dense v-if="isQuestionReviewer">
                <v-divider class=" mt-2 mb-2">

                </v-divider>
            </v-row> 
            <v-row dense v-if="isQuestionReviewer">
                <v-col cols="12">
                    <v-form ref="form" validate-on="submit lazy" v-model="formValue">
                        <v-row dense>
                            <v-col cols="12">
                                <v-autocomplete 
                                    density="compact" :items="patternList" v-model="editedItem.patternIds" label="Patterns *"
                                    :item-title="(item)=>`(${item.code}) ${item.label}`" item-value="patternId" multiple variant="outlined" 
                                   
                                    :rules="[(v) => (v.length>0) ||  'Please select atleast one pattern']"
                                    >

                                    <template v-slot:selection="{ item, index }">
                                    <span v-if="editedItem.patternIds.length == patternList.length && index == 0">All</span>
                                    <span v-else-if="editedItem.patternIds.length > 1 && index == 0"> +{{
                                        editedItem.patternIds.length }}</span>
                                    <span v-else-if="index == 0"> {{ item.title }} </span>
                                    <!-- <span v-else-if="index == 0 && item.title.length > 8"> {{ item.title.substring(0, 8) }}... </span> -->
                                    </template>

                                </v-autocomplete>
                            </v-col>
                            <v-col cols="4">
                                <v-select
                                    :items="difficultyLevels"
                                    item-title="label"
                                    item-value="key"
                                    v-model="editedItem.difficulty"
                                    label="Difficulty *"
                                    density="compact"
                                    dense
                                    variant="outlined"
                                    :rules="requiredRule"
                                ></v-select>
                            </v-col>
                            <v-col cols="4"></v-col>
                            <v-col cols="4">
                                <v-btn
                                class="text-capitalize" rounded color="info" variant="outlined"
                                @click.stop="addQuestionMapping()">
                                   Update Pattern/Difficulty
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col v-if="errorMessageForMapping.length>0" class="text-red ml-2" cols="12">
                    {{ errorMessageForMapping }}
                </v-col>

                <v-col v-if="successMessageForMapping.length>0" class="text-success ml-2" cols="12">
                    {{ successMessageForMapping }}
                </v-col>
                
                <v-divider class=" mt-2 mb-2">

                </v-divider>
            </v-row> 
             <v-row dense justify="start" v-if="isQuestionReviewer">
                <v-col cols="12" class="ml-2">
                    Otherwise, please provide your detailed improvement feedback in the space provided below. Your insights are valuable for enhancing question quality.
                </v-col>
            </v-row>
            <v-row dense>
                <v-col :cols="isQuestionReviewer ? 5 : 12" class="ml-1 pa-0">
                    <v-checkbox class="ma-0 pa-0 t-h6Reg" v-model="reportResponse.questionIncorrect" color="primaryPurple"
                        density="compact" hide-details >
                        <template v-slot:label>
                            Question text errors
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" color="grey-lighten-1" v-bind="props">
                                        mdi-information-slab-circle-outline
                                    </v-icon>
                                </template>
                                For inaccuracies or issues within the question's wording or presentation.
                            </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col :cols="isQuestionReviewer ? 5 : 12" class="ml-1 pa-0">
                    <v-checkbox class="ma-0 pa-0 t-h6Reg" v-model="reportResponse.optionsIncorrect" color="primaryPurple"
                        hide-details density="compact" >
                        <template v-slot:label>
                            Answer options errors
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" color="grey-lighten-1" v-bind="props">
                                        mdi-information-slab-circle-outline
                                    </v-icon>
                                </template>
                                For errors or problems in the text of the multiple-choice options.
                            </v-tooltip>
                        </template>    
                    </v-checkbox>
                </v-col>
                <v-col :cols="isQuestionReviewer ? 5 : 12" class="ml-1 pa-0">
                    <v-checkbox class="ma-0 pa-0 t-h6Reg" v-model="reportResponse.answerIncorrect" color="primaryPurple"
                        hide-details density="compact" >
                        <template v-slot:label>
                            Incorrect answer
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" color="grey-lighten-1" v-bind="props">
                                        mdi-information-slab-circle-outline
                                    </v-icon>
                                </template>
                                When the marked answer does not align with the accurate solution to the question.
                            </v-tooltip>
                        </template>    
                    </v-checkbox>
                </v-col>
                <v-col :cols="isQuestionReviewer ? 5 : 12" class="ml-1 pa-0">
                    <v-checkbox class="ma-0 pa-0 t-h6Reg" v-model="reportResponse.solutionIncorrect" color="primaryPurple"
                        hide-details density="compact" >
                        <template v-slot:label>
                            Solution explanation issues
                            <v-tooltip location="bottom">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" color="grey-lighten-1" v-bind="props">
                                        mdi-information-slab-circle-outline
                                    </v-icon>
                                </template>
                                For errors, omissions, or unclear explanations in the solution or rationale provided for the answer.
                            </v-tooltip>
                        </template>    
                    </v-checkbox>

                </v-col>
                <v-col cols="12" class="mt-4 px-2">
                    <v-textarea label="Additional feedback" rows="4" color="primaryPurple" variant="outlined"
                        v-model="reportResponse.feedback" density="compact" ></v-textarea>
                </v-col>
            </v-row>

            <v-row dense v-if="isQuestionReviewer">
                <v-col v-if="successMessageForSubmit.length>0" class="text-success">
                    {{ successMessageForSubmit }}
                </v-col>
                <!-- <v-spacer></v-spacer> -->
                <v-col align="right" v-if="isLevel1Reviewer" >
                    <v-btn class="text-capitalize" rounded color="info" variant="outlined"
                        prepend-icon="mdi-note-edit-outline"
                        @click="reportCorrectionsWithLevel(1)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="warning"></v-icon>
                        </template>

                        Submit - Corrections | Level 1
                    </v-btn>
                </v-col>
                <v-col align="right" v-if="isLevel2Reviewer">
                    <v-btn class="text-capitalize" rounded color="info" variant="outlined"
                        prepend-icon="mdi-note-edit-outline"
                        @click="reportCorrectionsWithLevel(2)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="warning"></v-icon>
                        </template>

                        Submit - Corrections | Level 2
                    </v-btn>
                </v-col>
                <v-col align="right" v-if="isLevel3Reviewer">
                    <v-btn class="text-capitalize" rounded color="info" variant="outlined"
                        prepend-icon="mdi-note-edit-outline"
                        @click="reportCorrectionsWithLevel(3)"
                        >
                        <template v-slot:prepend>
                            <v-icon color="warning"></v-icon>
                        </template>

                        Submit - Corrections | Level 3
                    </v-btn>
                </v-col>
            </v-row>

            <v-row dense v-if="!isQuestionReviewer">
                <v-spacer></v-spacer>
                <v-col align="right">
                    <v-btn class="t-btn text-capitalize btn-grad  text-primaryTextColor" variant="outlined"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'" elevation="0" @click="reportFeedback()">
                        Submit
                        <template v-slot:append>
                            <v-icon color="white">mdi-send</v-icon>
                        </template>
                    </v-btn>
                </v-col>

            </v-row>
        </v-card-text>

    </v-card>
</template>
  
<script>

import { mapState, mapGetters } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import QuestionReviewFeedbackDetails from "@/components/questionReview/QuestionReviewFeedbackDetails.vue";
import {orderBy} from 'lodash';

export default {
    name: "ReportComponent",
    data: () => ({
        draggable: false,
        formValue: false,
        editedItem: {
            qId: "",
            patternIds: [],
            difficulty: "",
        },
        reportResponse: {
            answerIncorrect: false,
            feedback: "",
            optionsIncorrect: false,
            qId: "",
            questionIncorrect: false,
            solutionIncorrect: false
        },
        errorMessageForMapping: "",
        errorMessageForApprove: "",
        requiredRule: [(v) => !!v || "Required"],
        successMessageForMapping: "",
        successMessageForApprove: "",
        successMessageForSubmit: "",
    }),
    props: {
        qId: String,
        testType: String,
        testId: String,
        testInstanceId: String,
        qObj: Object,
        isAIQuestion: {
            type: Boolean,
            default: false,
        }
    },
    components:	{
		QuestionReviewFeedbackDetails,
	},
    async created() {
        recordAnalyticsEvent(AnalyticsEvents.v2QuizAndPracticeFeedbackFormOpened, {
            testId: this.testId,
            testType: this.testType,
            testInstanceId: this.testInstanceId,
            questionId: this.qId,
        });
        if(this.isQuestionReviewer){
            this.$store.dispatch("loadPatternsList", {});
            
		}
        
        //console.log(`userState val: ${JSON.stringify(this.userState)}`);
        //console.log(`isQuestionReviewer: ${this.isQuestionReviewer}`);
        //console.log(`isLevel1Reviewer: ${this.isLevel1Reviewer}`);
        //console.log(`isLevel2Reviewer: ${this.isLevel2Reviewer}`);
        //console.log(`isLevel3Reviewer: ${this.isLevel3Reviewer}`);
       
    },
    computed: {
        ...mapState(["userState","questionReviewFeedbackDetails"]),
        ...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
        feedbackOrQuestionReiewTitleText: {
			get() {
				var retVal = "Report a problem" ////"Report a Problem" for users/students
				if(this.isQuestionReviewer){
					retVal = "Review Feedback & Corrections | " + this.qId; // for internal Question reviewers
				}
				return retVal;
			},
		},
        patternList:{
            get(){
                let patternList = this.$store.state.patternList;
                patternList = patternList.filter((pattern)=> pattern.subjectId == this.qObj.subjectId);
                return orderBy(patternList, ['code'], ['asc']);
            }
        },
        difficultyLevels :{
            get(){
                const difficultyLevels = [{ label: "<Not Set>", key: "-1" }];
                for (var j = 1; j <= 10; j++) {
                    difficultyLevels.push({ label: j.toString(), key: j.toString() });
                }
                difficultyLevels[2].label = "2 - Easy";
                difficultyLevels[5].label = "5 - Medium";
                difficultyLevels[8].label = "8 - Hard";
                return difficultyLevels
            }
        }
    },

    watch: {
        questionReviewFeedbackDetails(val){
            if(val){
                this.editedItem.difficulty = val.difficultyLevel;
                this.editedItem.patternIds = val.patternIds??[];
            }
        }
    },

    methods: {
        closeDialog() {
            recordAnalyticsEvent(AnalyticsEvents.v2QuizAndPracticeFeedbackFormCloseButtonClicked, {
                testId: this.testId,
                testType: this.testType,
                testInstanceId: this.testInstanceId,
                questionId: this.qId,
            });
            this.$store.commit("lastTimeStampOfPatternList", 0);
            this.$emit("closeDialog")
        },
        reportFeedback() {
            this.successMessageForSubmit = "";
            this.reportResponse.qId = this.qId
            this.reportResponse.testInstanceId = this.testInstanceId
            this.reportResponse.testId = this.testId
            this.reportResponse.testType = this.testType
            //here
            if(this.isAIQuestion){
                this.reportResponse.isAIQuestion= this.isAIQuestion;
            }
            console.log(this.reportResponse);

            recordAnalyticsEvent(AnalyticsEvents.v2QuizAndPracticeFeedbackFormSubmitButtonClicked, {
                testId: this.testId,
                testType: this.testType,
                testInstanceId: this.testInstanceId,
                questionId: this.qId,
            });

            this.$store.dispatch("updateFeedback", this.reportResponse).then(() => {
                if(!this.isQuestionReviewer){
                    this.$store.commit("lastTimeStampOfPatternList", 0);
                    this.$emit("closeDialog");
                }else{
                    const reqObj = {
                        qId: this.qId
                    }
                    this.$store.dispatch("loadQuestionReviewFeedbackDetails",reqObj);
                    this.successMessageForSubmit = "Corrections submitted successfully. To close this please press 'X' on top.";
                }
            });
        },
        reportCorrectionsWithLevel(reviewLevel) {
            var reqObj = {
                qId: this.qId
            };
            switch (reviewLevel) {
                case 1:
                    reqObj.level1ReviewStatus = "failed";
                    break;
                case 2:
                    reqObj.level2ReviewStatus = "failed";
                    break;
                case 3:
                    reqObj.level3ReviewStatus = "failed";
                    break;
            }
            this.$store.dispatch("updateQuestionReviewStatus",reqObj).then(() => {
                this.reportFeedback();
            });
        },
        approveQuestionReviewForLevel(reviewLevel) {
            this.successMessageForApprove = "";
            this.errorMessageForApprove = "";
            if(reviewLevel==3 && this.editedItem.patternIds.length==0){
                this.errorMessageForApprove = "Cannnot approve as Pattern is not assigned.";
            }else{
                var reqObj = {
                    qId: this.qId
                };
                switch (reviewLevel) {
                    case 1:
                        reqObj.level1ReviewStatus = "passed";
                        break;
                    case 2:
                        reqObj.level2ReviewStatus = "passed";
                        break;
                    case 3:
                        reqObj.level3ReviewStatus = "passed";
                        break;
                }
                //console.log(`approveQuestionReviewForLevel reqObj: ${JSON.stringify(reqObj)}`);
                this.$store.dispatch("updateQuestionReviewStatus",reqObj).then(() => {
                    if(!this.isQuestionReviewer){
                        this.$store.commit("lastTimeStampOfPatternList", 0);
                        this.$emit("closeDialog");
                    }else{
                        this.$store.dispatch("loadQuestionReviewFeedbackDetails",reqObj);
                        this.successMessageForApprove = "Approved successfully. To close this please press 'X' on top.";
                    }
                });
            }
            
        },
        isApproveBtnDisabled(reviewLevel) {
            let isDisabled = false;
            try {
                switch (reviewLevel) {
                    case 1:
                        if( this.questionReviewFeedbackDetails.level1ReviewStatus === "passed") {
                            isDisabled = true;
                        }
                        break;
                    case 2:
                        if( this.questionReviewFeedbackDetails.level2ReviewStatus === "passed") {
                            isDisabled = true;
                        }
                        break;
                    case 3:
                        if( this.questionReviewFeedbackDetails.level3ReviewStatus === "passed") {
                            isDisabled = true;
                        }
                    break;
                }
            }
            catch (err) {
                console.error(err);
            }
            
            return isDisabled;
        },
        async addQuestionMapping(){
            this.errorMessageForMapping = "";
            this.successMessageForMapping = "";
            const { valid } = await this.$refs.form.validate();
            if(valid){
                
                // Add a pinpoint
                this.editedItem.qId= this.qId;
                this.$store.dispatch("addQuestionMapping",this.editedItem).then((response) => {
                    console.log('addQuestionMapping', response);
                    if('error' in response){
                        this.errorMessageForMapping = response['error'];
                    }else{
                        if(!this.isQuestionReviewer){
                            this.$store.commit("lastTimeStampOfPatternList", 0);
                            this.$emit("closeDialog");
                        }else{
                            this.successMessageForMapping = "Pattern/Difficulty updated successfully. To close this please press 'X' on top.";
                        }
                    }
                   
                });
            }
        },
        draggableShow(){
            this.draggable = true;
        },
        draggableNotShow(){
            this.draggable = false;
        },
        draggableStart(){
            this.$emit("draggableStart");
        },
        draggableEnd(){
            this.$emit("draggableEnd");
        },
    },
};
</script>
  
<style scoped>
.header-container{
    background: #35354E;
    /* border-radius: 16px; */
    color: white;
    /* margin: 0px; */
}
.btn-grad {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 29.4079px;
}
.cursorMove{
    cursor: move;
}
</style>
  