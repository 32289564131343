import axios from 'axios';
import { getHeaders, getApiBaseUrl, getHeadersWithCustomerId } from './authService';
import store from "../store";
import { getFlavourBaseAPIURL, getCognitoAuthConfigForAmplify } from '@/flavors';
import verFlavor from "@/appFlavorAndVersion";
import { redirectPricePage } from './subscriptionService';
import { LambdaClient, InvokeCommand, InvokeWithResponseStreamCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import { Auth } from "aws-amplify";


export async function securePostAPI(data, path, showLoading = true, requiredSubscription = true) {
  store.commit ('increamentLoader');
  return axios.post((store.getters.apiBaseUrl || getApiBaseUrl()) + path, { ...data, username: store.getters.username }, {
    headers: getHeaders()
  }).then(res => {
    store.commit ('decreamentLoader');
    return redirectPricePage(res.data, requiredSubscription);
  }).catch(err => {
    store.commit ('decreamentLoader');
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function securePostAPIWithoutHeadersWithoutOverlay(data, path) {
  return axios.post((store.getters.apiBaseUrl || getApiBaseUrl()) + path, data).then(res => {
    return res.data
  }).catch(err => {
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function securePostAPIWithCustomerId(data, path, showLoading = true, requiredSubscription = true) {
  // console.log("url", store.getters.apiBaseUrl);
  store.commit ('increamentLoader');
  return axios.post((store.getters.apiBaseUrl || getApiBaseUrl()) + path, { ...data, username: store.getters.username }, {
    headers: getHeadersWithCustomerId()
  }).then(res => {
    store.commit ('decreamentLoader');
    return redirectPricePage(res.data, requiredSubscription);
  }).catch(err => {
    store.commit ('decreamentLoader');
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function securePostAPIWithCustomerIdWithoutOverlay(data, path, requiredSubscription = true) {
  return axios.post((store.getters.apiBaseUrl || getApiBaseUrl()) + path, { ...data, username: store.getters.username }, {
    headers: getHeadersWithCustomerId()
  }).then(res => {
    return redirectPricePage(res.data, requiredSubscription);
  }).catch(err => {
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function securePostAPIWithoutOverlay(data, path, signal = null) {
  return axios.post((store.getters.apiBaseUrl || getApiBaseUrl()) + path, { ...data, username: store.getters.username }, {
    signal: signal,
    headers: getHeaders()
  }).then(res => {
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}
export async function securePostAPIWithoutOverlayWithoutData(path) {
  return axios.post(store.getters.apiBaseUrl + path, {
    headers: getHeaders()
  }).then(res => {
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    console.log("err", err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function secureGetAPI(data, path) {
  store.commit ('increamentLoader');
  return axios.get((store.getters.apiBaseUrl || getApiBaseUrl()) + path, {
    headers: getHeaders(),
  }).then(res => {
    store.commit ('decreamentLoader');
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    store.commit ('decreamentLoader');
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}
export async function secureGetAPIWithCustomerId(data, path) {
  store.commit ('increamentLoader');
  return axios.get((store.getters.apiBaseUrl || getApiBaseUrl()) + path, {
    headers: getHeadersWithCustomerId(),
  }).then(res => {
    store.commit ('decreamentLoader');
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    store.commit ('decreamentLoader');
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function secureDeleteAPI(data, path) {
  store.commit ('increamentLoader');
  return axios.delete((store.getters.apiBaseUrl || getApiBaseUrl()) + path, {
    headers: getHeaders(),
  }).then(res => {
    store.commit ('decreamentLoader');
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    store.commit ('decreamentLoader');
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function secureGetAPIWithCredentials(data, path) {
  /*
  return axios.get(store.getters.apiBaseUrl + path, {
    headers: getHeaders(),
    withCredentials: true,
  }).then(res => {
    
    return res.data
  }).catch(err => {
    
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });*/
  fetch((store.getters.apiBaseUrl || getApiBaseUrl()) + path, {
    credentials: 'include',
    headers: getHeaders()
  })
    .then(async response => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        console.log(JSON.stringify(error));
        return Promise.reject(error);
      }

      return redirectPricePage(data);

      // return data;
    })
    .catch(error => {
      this.errorMessage = error;
      console.log("There was an error!", error);
    });
}


export async function secureGetAPIWithoutOverlay(data, path) {
  return axios.get((store.getters.apiBaseUrl || getApiBaseUrl()) + path, {
    headers: getHeaders(),
  }).then(res => {
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

// special function to be called initially once to retrieve api urls and other properties from server.]
export async function fetchAPIUrls() {
  store.commit ('increamentLoader');
  
  let req = { appFlavour: verFlavor.APP_FLAVOR, versionNumber: verFlavor.APP_VERSION }
  return axios.post(getFlavourBaseAPIURL() + 'mentoapp/onboarding/url', req, {
    headers: getHeaders(),
  }).then(res => {
    store.commit ('decreamentLoader');
    
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    store.commit ('decreamentLoader');
    
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}

export async function fetchStartAPIUrls(reqData, path) {
  store.commit ('increamentLoader');
  
  let req = { appFlavour: verFlavor.APP_FLAVOR, versionNumber: verFlavor.APP_VERSION, ...reqData }
  return axios.post(getFlavourBaseAPIURL() + path, req, {
    headers: getHeaders(),
  }).then(res => {
    store.commit ('decreamentLoader');
    
    return redirectPricePage(res.data);
    // return res.data
  }).catch(err => {
    store.commit ('decreamentLoader');
    
    console.log(err);
    return { error: `${err.code} | ${err.message}` }
  });
}



export async function invokeAILambda(data) {


  let credentials = await Auth.currentCredentials()
  console.log(credentials);
  console.log(Auth.essentialCredentials(credentials));
  const client = new LambdaClient({
    credentials: Auth.essentialCredentials(credentials),
    region: 'us-east-1',
    httpOptions: {
      connectTimeout: 900000,
      timeout: 900000
    }

  });
  const input = { // InvocationRequest
    FunctionName: "aiQuestionGenerator", // required
    InvocationType: "RequestResponse",
    Payload: JSON.stringify(data)
  };
  const command = new InvokeWithResponseStreamCommand(input);
  const response = await client.send(command)


  console.log(response);
  for await (const event of response.EventStream) {
    // Each event will likely contain the 'PayloadChunk' property 
    const chunk = event.PayloadChunk?.Payload;
    console.log(chunk)
    if (chunk) {
      const textChunk = new TextDecoder().decode(chunk);
      const regex = /<chunk>(.*?)<\/chunk>/g;
      let match;

      while ((match = regex.exec(textChunk)) !== null) {
        // The string you want is in match[1]
        console.log(JSON.parse(match[1]))
        store.commit("addAIQuestion", JSON.parse(match[1]))
      }

    }

  }
}





export const generateStream = async () => {
  const response = await fetch(
    'https://wg66mfbqf3br3cgks2jsh5etom0okaiq.lambda-url.us-east-1.on.aws/',
    {
      method: 'GET',
    }
  )
  if (response.status !== 200) throw new Error(response.status.toString())
  if (!response.body) throw new Error('Response body does not exist')
  return getIterableStream(response.body)
}


export async function* getIterableStream(
  body
) {
  const reader = body.getReader()
  const decoder = new TextDecoder()

  while (true) {
    const { value, done } = await reader.read()
    if (done) {
      break
    }
    const decodedChunk = decoder.decode(value, { stream: true })
    yield decodedChunk
  }
}


export async function invokeAIImageLambda(data) {


  let credentials = await Auth.currentCredentials()
  console.log(credentials);
  console.log(Auth.essentialCredentials(credentials));
  const client = new LambdaClient({
    credentials: Auth.essentialCredentials(credentials),
    region: 'us-east-1',
    httpOptions: {
      connectTimeout: 900000,
      timeout: 900000
    }

  });
  const input = { // InvocationRequest
    FunctionName: "aiQSolver", // required
    InvocationType: "RequestResponse",
    Payload: JSON.stringify(data)
  };
  const command = new InvokeCommand(input);
  const response = await client.send(command)

  const textChunk = new TextDecoder().decode(response.Payload);
  let x = JSON.parse(textChunk)
  console.log(x.body);
  return x.body

}
