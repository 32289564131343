<template>
  <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }" justify="space-between"
    align="center">
    <v-col class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{ item }">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{
            item.title
          }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col cols="auto" class="ma-0 pa-0">
      <SelfHelpGuideTogglerComponent />
    </v-col>
    <v-divider></v-divider>
  </v-row>

  <v-container fluid class="pb-4 main-lg">
    <v-container fluid class="pa-0 container-width">
      <v-row>
        <v-col>
          <v-card class="main-card text-white mb-4">
            <v-card-title class="px-5 pt-4 pb-0">
              <span v-if="studentName !== ''" class="main-heading">
                {{ studentName }}
              </span>
              <span v-else class="main-heading">Student</span>
              <div class="t-h6Reg parameter-style">
                Here's what this student is currently working on and how they're doing.
              </div>
              <v-divider></v-divider>
            </v-card-title>
            <v-card-text class="pa-5 pt-4">
              <v-row class="ma-0">
                <v-col cols="auto" class="mr-1 tab t-1remReg text-white" :class="{ 'selected-tab': tab.id == tabIndex }"
                  v-for="tab in tabs" :key="tab.id" @click.stop="changeTab(tab)"
                  :ref="`StudentDetailPageScreen__tabs--${tab.uniqueId}`">
                  {{ tab.name }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="main-card text-white">
            <v-card-text class="pa-0">
              <v-row class="ma-0" v-if="tabIndex == '1'">
                <AssignmentComponent assignmentType="student" :studentId="studentId"></AssignmentComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '2'">
                <PracticeTestReportComponent :studentId="studentId"></PracticeTestReportComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '3'">
                <SkillsComponent assignmentType="student" :studentId="studentId"></SkillsComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '4'">
                <MentoAIComponent assignmentType="student" :studentId="studentId"></MentoAIComponent>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '5'">
                <AIQuestion></AIQuestion>
              </v-row>
              <v-row class="ma-0" v-else-if="tabIndex == '6'">
                <AIQuestionWithImages></AIQuestionWithImages>
              </v-row>
              <v-row class="ma-0 pa-5" v-else-if="tabIndex == '7'">
                <AIInsights :studentId="studentId"></AIInsights>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import { useDisplay } from "vuetify";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import AssignmentComponent from "./AssignmentComponent.vue";
import SkillsComponent from "./SkillsComponent.vue";
import MentoAIComponent from "../teacherApp/MentoAIComponent.vue";
import AIQuestion from "../teacherApp/AIQuestion.vue";
import AIQuestionWithImages from "../teacherApp/AIQuestionWithImages.vue";
import _ from "lodash";
import { breadcrumbHandler } from "@/services/breadcrumbHandler";
import { mapGetters, mapState } from "vuex";

import PracticeTestReportComponent from "./PracticeTestReportComponent.vue";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
import AIInsights from "@/components/ai/AIInsights.vue";

export default {
  name: "StudentDetailPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  components: {
    AssignmentComponent,
    PracticeTestReportComponent,
    SkillsComponent,
    MentoAIComponent,
    AIQuestion,
    AIQuestionWithImages,
    SelfHelpGuideTogglerComponent,
    AIInsights
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      {
        name: "Assignment",
        id: "1",
        uniqueId: 'assignment'
      },
      {
        name: "Test Attempts",
        id: "2",
        uniqueId: 'testAttempts'
      },
      {
        name: "Skills",
        id: "3",
        uniqueId: 'skills',
      }
    ],
    studentId: "",
    tabIndex: "1",
  }),
  async beforeRouteUpdate(to, from) {
    this.studentId = this.$route.params.studentId;
  },
  async created() {
    if (!this.isUserTeacher) {
      if (this.isUserAdmin) {
        this.$router.replace("/admin/1");
      } else {
        this.$router.replace("/learn");
      }
    }
    this.$store.commit("appbarVisible", true);
    this.$store.commit("navbarVisible", true);
    breadcrumbHandler(this);
    console.log("here");
    // if (this.config.environment == "dev") {
    //   this.tabs.push({
    //     name: "Mento AI",
    //     id: "4",
    //   });

    //   this.tabs.push({
    //     name: "AI Question",
    //     id: "5",
    //   });

    //   this.tabs.push({
    //     name: "AI Question With Images",
    //     id: "6",
    //   });
    // }
    if (this.config.environment != "prod") {
      this.tabs.push({
        name: "AI Insights",
        id: "7",
        uniqueId: 'aiinsights',
      },);
    }


    // this.createNodes()
    console.log(this.$route.params);
    this.$store.commit("clearTeacherAssignments");

    if ("studentId" in this.$route.params) {
      this.studentId = this.$route.params.studentId;
    }

    this.tabIndex = this.$route.params.studentTab;
    if (this.$route.params.studentTab > this.tabs.length) {
      this.$router.replace("1");
      this.tabIndex = 1;
    }
    this.selectedTab = _.find(this.tabs, { id: this.tabIndex });

    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentTabSwitched, {
      courseId: this.courseId,
      tabName: this.selectedTab.name,
      fromClass: this.$route.params.batchId ? true : false,
      batchId: this.$route.params.batchId ? this.$route.params.batchId : "",
    });
  },
  computed: {
    ...mapState([
      "studentName",
      "className",
      "breadCrumbs",
      "config",
      "isUserTeacher",
      "isUserAdmin",
      "courseId",
    ]),
    ...mapGetters(['currentHighlightedElements']),
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = this.breadCrumbs;
        breadCrumbs[breadCrumbs.length - 1].disabled = true;

        if ("batchId" in this.$route.params) {
          let currentClassName = this.className !== "" ? this.className : "Class Details";
          breadCrumbs[breadCrumbs.length - 2].title = currentClassName;
        }

        return breadCrumbs;
      },
    },
    showSelfHelpGuide: {
      get() {
        return this.$store.state.showSelfHelpGuide;
      },
      set(value) {
        this.$store.commit('toggleSelfHelpGuide', value);
      }
    }
  },

  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    createNodes() {
      // this.breadCrumbs = [];
      let currentClassName = this.className !== "" ? this.className : "Class Details";
      console.log("here breadcrumb", this.className);
      // if('batchId' in this.$route.params)
      // {
      //   this.breadCrumbs.push({
      //     title: "Classes",
      //     disabled: false,
      //     parentNodeIdKeyinChildNodesMap: "classes",
      //     pageRoute: `/classes/`,
      //   });
      //   this.breadCrumbs.push({
      //     title: currentClassName,
      //     disabled: false,
      //     parentNodeIdKeyinChildNodesMap: "students",
      //     pageRoute: `/students/`,
      //   });
      // }
      // else{
      //   this.breadCrumbs.push({
      //     title: "Students",
      //     disabled: false,
      //     parentNodeIdKeyinChildNodesMap: "students",
      //     pageRoute: `/students/`,
      //   });
      // }
    },
    // back() {
    //   if('batchId' in this.$route.params)
    //   {
    //     let batchId= this.$route.params.batchId;
    //     this.$router.replace(`/teacher/classes/${batchId}/1`)
    //     return
    //   }
    //   this.$router.replace(`/teacher/students`);
    // },
    breadCrumbRoute(item) {
      this.$router.replace(item.pageRoute);
    },
    back() {
      let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute;
      this.$router.replace(page);
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    changeTab(tab) {
      console.log("this.tabIndex", this.tabIndex, tab);
      this.tabIndex = tab.id;
      this.selectedTab = tab;
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentTabSwitched, {
        courseId: this.courseId,
        tabName: tab.name,
        fromClass: this.$route.params.batchId ? true : false,
        batchId: this.$route.params.batchId ? this.$route.params.batchId : "",
      });
      this.$router.replace(`${tab.id}`);
    },
  },
};
</script>
<style scoped>
.heightBread {
  height: 80px;
}

.container-width {
  max-width: 1080px !important;
}

.main-lg {
  padding-top: 20px;
  /* padding-left: 12%;
  padding-right: 12%; */
}

.main-md {
  padding-top: 60px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  /* background: rgb(var(--v-theme-cardBackgroundColor)); */
  background-color: rgb(var(--v-theme-cardBackgroundColor2));
}

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.tab {
  background: rgb(var(--v-theme-report-subCardBg));
  padding: 10px 15px;
  margin-right: 12px !important;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 12px;
}

.selected-tab {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
  border: 2px solid transparent;
}

.parameter-style {
  opacity: 0.4;
}
</style>
