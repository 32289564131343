<template>
<div>

    <v-card-title>
        <v-row v-if="showFirstRow">
            <v-col>
                <span class="profileCardTitle t-h6Bold">
                    Trial Teacher Mode
                </span>
            </v-col>
        
        </v-row>

    </v-card-title>
    <v-card-text>
        <v-row dense class="text-white mx-1" >
                <span class="t-1remReg font-weight-medium">
                    Explore the following features with a trial account :
                </span>
        
        </v-row>
        <v-row dense align="center" class="t-1remReg font-weight-medium text-white">
            <v-list  bg-color="transparent">
                <v-list-item title="Mento AI Hub" slim prepend-icon="mdi-brain" class="teacher" lines="false"
                    subtitle="Generate questions via AI and assign them to your students"></v-list-item>
                <v-list-item class="teacher" title="Assignments" lines="false" slim prepend-icon="mdi-laptop"
                    subtitle="Create assignments, assign to yourself, see how the reports and skill tracking look like."></v-list-item>
            </v-list>
        </v-row>
        <v-row align="center" class="px-3 my-1 mt-6" :justify="showFirstRow?'start': 'end'">
            <v-btn class="t-btn text-white activity-btn text-none px-5" density="default" variant="flat" width="230"
                height="35" @click="enableTeacherMode">
                Enable trial teacher mode
            </v-btn>
        </v-row>
    </v-card-text>
</div>


</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import _ from "lodash";
import {
    AnalyticsEvents,
    recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import moment from "moment-timezone";

export default {
    name: 'TeacherModeComponent',
    props : {
        showFirstRow : Boolean
    },
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

        return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
    },
    data() {
        return {
            showData: false,
        };
    },
    created() {

        console.log("isUserTeacher", this.isUserTeacher);
    },
    methods: {
       async enableTeacherMode() {
           await this.$store.dispatch("enableTrialTeacherMode", {})
           await this.$store.dispatch("onBoardingUserDetails")
           this.$store.commit("updateTeacherModeDialog", false)
        },
        closeDialog() {
            this.$store.commit("updateTeacherModeDialog", false)
        }
    },
    components: {
        // ProfileFields
    },
    computed: {
        ...mapState(["profileDetails", "pricingModels", "isUserTeacher", "teacherModeDialog"]),

    },
};
</script>

<style scoped>
.main-lg {
    padding-top: 50px;
    padding-left: 20%;
    padding-right: 20%;
}

.main-md {
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
}

.testCard {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background: rgb(var(--v-theme-cardBackgroundColor));
    cursor: pointer;
}

.profileCardTitle {
    color: rgb(var(--v-theme-report-extraText));
    text-transform: uppercase;
}

.layoutLarge {
    height: 100%;
}

.text-field-name {
    opacity: 0.7;
}

.test-subtitle {
    color: #8e8ea7;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 10px;
    width: 100px;
}

.not-activity-btn {
    border: 2px solid rgb(var(--v-theme-primaryPurple));
    border-radius: 10px;
    background: rgb(var(--v-theme-cardBackgroundColor));
    width: 100px;
}
.v-list-item-subtitle {
    display: inline-block !important;
}
/* .teacher .v-list-item__spacer{
    display: none !important;
  } */
</style>