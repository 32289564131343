<template>
  <!-- Dialog for student assignment -->
  <section>
  <v-dialog style="backdrop-filter: blur(12.5px)" :model-value="dialog" width="400" persistent>
    <v-card elevation="0" class="student-form text-white">
      <v-toolbar class="student-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-account" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">Change Customer</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close" class="mr-4">
                mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 student-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">

          <v-row dense>
            <v-col cols="12">
              <v-autocomplete :items="customersList" item-title="courseName" item-value="courseId"
                v-model="currentCustomer" label="Change Customer" density="compact" variant="outlined" dense>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="updateCourseId">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-bottom-navigation selected-class="selected-nav" bg-color="#2B2B44" v-if="showNav && xs" :elevation="2" height="75"
    grow>
    <v-btn v-for="tab in navigationData.slice(0, 3)" :key="tab.value" :value="tab.value" @click="toggleDrawer()"
      :active="navItemActive(tab.path)" :to="tab.path" class="tab" :ref="'uniqueId' in tab? tab?.uniqueId: ''">
      <v-badge color="report-red" floating dot v-model="tab.badge">
        <v-icon :height="20" :width="20"><v-img :src="navItemActive(tab.path) ? tab.activeIcon : tab.icon"></v-img>
        </v-icon>
      </v-badge>
      <span class="text-subtitle-2 nav-text">{{ tab.title }}</span>
    </v-btn>
    <v-btn id="menu-activator" class="tab">
      <v-icon :height="20" :width="20" class="pb-1"><v-img src="@/assets/more.svg"></v-img></v-icon>
      <span class="text-subtitle-2 nav-text">More</span>
    </v-btn>
    <v-menu location="top" activator="#menu-activator">
      <v-list class="profile-bg">

        <v-list-item @click="changeView(!isTeacherViewSelected)" v-if="isUserTeacher && !isUserParent">

          <v-list-item-title class="nav-text">Go to {{ isTeacherViewSelected ? 'Student' : 'Teacher' }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-for="tab in navigationData.slice(3)" :key="tab.value" class="tab" @click="toggleDrawer()"
          :active="navItemActive(tab.path)" :to="tab.path" active-class="selected-nav" :ref="'uniqueId' in tab? tab?.uniqueId: ''">
          <div class="tablet-tab py-2">

            <v-icon :height="20" :width="20">
              <v-img :src="navItemActive(tab.path) ? tab.activeIcon : tab.icon">
              </v-img> </v-icon>

            <v-list-item-title class="text-white"><span class="nav-text">{{ tab.title }}</span></v-list-item-title>
          </div>
          <template v-slot:append>
            <v-badge  v-if="tab.value == 'action' && showAssignments"  color="primaryPurple" inline :content="assignmentsCount"></v-badge>
            <v-badge  v-if="tab.value == 'aihub'" class="mentoBadge" content="Beta" offset-x="6" offset-y="-12" text-color="white" color="transparent"></v-badge>
          </template>
        </v-list-item>

        <v-list-item v-for="tab in moreNavigationData" :key="tab.value" class="tab" :active="navItemActive(tab.path)"
          :to="tab.path" active-class="selected-nav">
          <div class="tablet-tab py-2">

            <v-icon :height="20" :width="20">
              <v-img :src="navItemActive(tab.path) ? tab.activeIcon : tab.icon">
              </v-img> </v-icon>

            <v-list-item-title class="text-white">
              <span class="nav-text">{{ tab.title }}</span>

            </v-list-item-title>
          </div>
          <template v-slot:append>
            <v-badge color="report-red" inline dot v-model="tab.badge"></v-badge>
          </template>
        </v-list-item>

        <v-list-item class="nav-spacer" link @click="signOut()">
          <template v-slot:prepend>
            <v-icon :height="20" :width="20" class="mr-3">
              <v-img src="@/assets/logout.svg">
              </v-img> </v-icon>
          </template>
          <v-list-item-title class="nav-text"> Sign out </v-list-item-title>
        </v-list-item>

        <div class="text-caption version-text nav-text px-4">
          V {{ appVersion }}.{{ appBuildNumber }}
        </div>
      </v-list>
    </v-menu>
  </v-bottom-navigation>

  <v-navigation-drawer color="#3D3D54" v-else-if="showNav && !xs" :rail="!mdAndUp" :rail-width="200" :width="200"
    class="no-border" permanent app>
    <v-list-item class="t-logo pa-2 mb-1" :class="{ 'px-0': isWhiteLabelApp }">
      <v-row align="center" justify="center" v-if="!isWhiteLabelApp">
        <v-col>
          <img class="logo-img" :src="orgLogo"/>
        </v-col>
      </v-row>
      <v-row align="center" class="ma-0 pa-0" justify="center" v-else>
        <v-col cols="12" class="pa-0 ma-0 px-2">
          <img class="logo-img" :style="`background-color: ${orgLogoBackgroundColor};border-radius:6px;`" :src="orgLogo"/>
        </v-col>
      </v-row>
    </v-list-item>

    <v-row class="ma-0" v-if="isUserTeacher && !isUserParent">
      <v-col cols="12" sm="auto" class="py-0 ma-0 w-100">
        <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex">

          <v-btn @click="changeView(true)" class="py-2 px-3 flex-grow-1"
            :class="[isTeacherViewSelected ? 'active-button' : 'normal-button']"
            :variant="isTeacherViewSelected ? 'elevated' : 'plain'" elevation="0">
            <span class="text-none button-text t-h6Reg">Teacher</span>
          </v-btn>
          <v-btn @click="changeView(false)" class="py-2 px-3 flex-grow-1"
            :class="[!isTeacherViewSelected ? 'active-button' : 'normal-button']"
            :variant="!isTeacherViewSelected ? 'elevated' : 'plain'" elevation="0">
            <span class="text-none button-text t-h6Reg">Student</span>
          </v-btn>

        </v-sheet>
      </v-col>
    </v-row>



    <v-divider class="mt-1"></v-divider>

    <div v-if="isUserSuperAdmin" class="mt-3 mb-2 mx-2 tab tab-2">
      <div @click="getCustomers()" class="tablet-tab  px-4 py-3">
        <div class="text-white w-100 d-flex justify-center align-center">
          <span class="nav-text">{{ getCustomerName() }}</span>
        </div>
      </div>
    </div>

    <v-list open-strategy="single" class="t-nav">
      <template v-for="tab in navigationData" :key="tab.value">
        <v-list-item  class="mb-2  tab" @click="toggleDrawer()"
          :active="navItemActive(tab.path)" :to="tab.path" active-class="selected-nav" :ref="'uniqueId' in tab? tab?.uniqueId: ''"  >
          <div class="tablet-tab py-2">
  
  
            <v-icon :height="20" :width="20">
              <v-img :src="navItemActive(tab.path) ? tab.activeIcon : tab.icon"></v-img>
            </v-icon>
  
            <v-list-item-title class="text-white w-100 d-flex justify-space-between align-center">
              <span class="nav-text">{{ tab.title }}</span>
            </v-list-item-title>
          </div>
          <template v-slot:append>
            <v-badge v-if="tab.value == 'action' && showAssignments" color="primaryPurple" inline
              :content="assignmentsCount"></v-badge>
  
              <v-badge  v-if="tab.value == 'aihub'" class="mentoBadge" content="Beta" offset-x="24" offset-y="-12" text-color="white" color="transparent"></v-badge>
          </template>
  
        </v-list-item>
      </template>

      <v-list-item class="mb-2  tab" id="web-more-activator" ref="NavigationDrawer__navItem--more">
        <div class="tablet-tab py-2  cursor-pointer">

          <v-icon :height="20" :width="20">
            <v-img :height="15" :width="20" src="@/assets/more.svg">
            </v-img> </v-icon>

          <v-list-item-title class="text-white"><span class="nav-text">More</span></v-list-item-title>
        </div>
      </v-list-item>
      <v-menu location="bottom" class="" activator="#web-more-activator">
        <v-list class="profile-bg more-bg">

          <v-list-item v-for="tab in moreNavigationData" :key="tab.value" class="tab no-radius" :active="navItemActive(tab.path)" :to="tab.path" active-class="selected-nav no-radius">
            <div class="tablet-tab py-2">

              <v-icon :height="20" :width="20">
                <v-img :src="navItemActive(tab.path) ? tab.activeIcon : tab.icon">
                </v-img> </v-icon>

              <v-list-item-title class="text-white"><span class="nav-text">{{ tab.title }}</span></v-list-item-title>
            </div>
            <template v-slot:append>
              <v-badge color="primaryPurple" inline dot v-model="tab.badge"></v-badge>
            </template>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-list>

    <template v-slot:append>
      <div v-if="showUserUpgradeButton && !this.isUserParent" v-ripple.center class="mt-3 mb-3 mx-3 tab-3">
        <div  class="tablet-tab px-4 py-3" @click="showUpgrade">
          <div class="text-white w-100 d-flex justify-center align-center">
            <span class="nav-text">Upgrade</span>
          </div>
        </div>
      </div>
            
      <v-menu location="top" activator="#menu-activator-web">
        <v-list class="profile-bg">
          <v-list-item class="nav-spacer" link @click="signOut()">
            <template v-slot:prepend>
              <v-icon :height="20" :width="20" class="mr-3">
                <v-img src="@/assets/logout.svg">
                </v-img> </v-icon>
            </template>

            <v-list-item-title class="nav-text"> Sign out </v-list-item-title>
          </v-list-item>
          <div class="text-caption version-text nav-text px-4" @click="test">
            V {{ appVersion }}.{{ appBuildNumber }}
          </div>
        </v-list>
      </v-menu>
      <v-divider class="my-1"></v-divider>


      <v-btn id="menu-activator-web" :ripple="false" color="grey" variant="text" class="mt-1 mb-3 more-tab"
        @click="showProfile">
        <v-avatar size="34" color="#844DDE" class="mr-4">
          <span class="text-none nav-text">
            {{ getInitials(userName) }}
          </span>
        </v-avatar>
        <div end class="text-none nav-text">{{ getFirstName(userName) }}</div>
        <v-icon small end class="my-3">{{ showMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
      <v-divider class=""></v-divider>
      <div v-if="showPoweredBy" cols="12" class="pa-0 ma-0 mb-2 pl-3">
          <span @click.stop="openMento()" class="t-small-subtitle pointer"><span class="text-grey">Powered By</span> <span
              class="text-white">Mento<span class="text-primaryPurple">Mind</span></span></span>
      </div>
    </template>
  </v-navigation-drawer>
</section>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import { useDisplay } from "vuetify";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import verFlavor from "@/appFlavorAndVersion";
import { storeCourseId } from "@/services/authService";
import moment from "moment-timezone";
import _ from "lodash";
import orgSignInLogo from "@/assets/mento_logo_long.svg";
import { hidePoweredByMentoMind, isWhiteLabelApp } from "@/services/whiteLabelService";
import { checkNavListForFeatureFlags } from "@/services/featureFlagsHandlar";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
export default {
  name: "NavigationDrawer",
  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp, xs } = useDisplay();

    return { xs, mdAndUp };
  },
  props: {
    show: Boolean,
  },
  components: {

  },

  async created() {
    this.currentSavedCustomer = this.courseId;
    this.checkWhiteLabelApp();
  },
  mounted(){
  },
  data: () => ({
    showMenu: false,
    dialog: false,
    currentCustomer: "",
    defaultCustomer: "",
    currentSavedCustomer: "",
    formValue: false,
    isWhiteLabelApp: false,
  }),
  computed: {
    ...mapState([
      "appVersion",
      "appBuildNumber",
      "navDrawer",
      "navDrawerVisible",
      "testSeriesState",
      "userLoggedIn",
      "userState",
      "config",
      "userPackages",
      "moreNavigationData",
      "isTeacherViewSelected",
      "teacherViewNavigationData",
      "isUserTeacher",
      "isUserAdmin",
      "isUserParent",
      "adminViewNavigationData",
      "parentViewNavigationData",
      "courseDetails",
      "assignments",
      "assignmentsCount",
      "courseId",
      "isUserSuperAdmin",
      "profileDetails",
      "isUserParent",
      "highlightedElements",
      "showSelfHelpGuide",
    ]),
    ...mapGetters(['currentHighlightedElements']),
    orgLogo: {
      get(){
        if(this.isWhiteLabelApp){
          return this.$store.state.app_s3url + this.courseDetails.image;
        }else{
          return orgSignInLogo;
        }
      }
    },
    orgLogoBackgroundColor: {
      get(){
        if(this.isWhiteLabelApp && (this.courseDetails.wlLogoBackground == 'solid')){
          return this.courseDetails.wlLogoBackgroundColor;
        }else{
          return 'transparent';
        }
      }
    },
    customersList: {
      get() {
        return _.orderBy(this.$store.state.customersList, [customer => customer.courseName?.toLowerCase()], "asc");
      }
    },
    showAssignments: {
      get() {
        let data = this.assignments;
        if (typeof data != "object") {
          data = {};
        }
        if ("isAssignedToTutorBatch" in data) {
          return (data.isAssignedToTutorBatch && data.items.length > 0 && this.assignmentsCount > 0) ? true : false;
        }
        else {
          return false;
        }
      }
    },
    showNav: {
      get() {
        return this.navDrawerVisible & this.show;
      },
    },
    currentRoute: {
      get() {
        return this.$route.path;
      },
    },
    navigationData: {
      get() {
        const navigationData = (this.config.environment=="dev") ? this.$store.state.navigationDataDev : this.$store.state.navigationData;
        let teacherViewNavigationData =  this.teacherViewNavigationData
        if(this.isUserParent ?? true){
          let navArr = checkNavListForFeatureFlags(this.parentViewNavigationData, 'parent');
          return navArr;
        }
        else if (this.isUserAdmin) {
          if (this.isUserTeacher) {
            if(this.isTeacherViewSelected){
              let navArr1 = checkNavListForFeatureFlags(this.adminViewNavigationData, 'admin');
              let navArr2 = checkNavListForFeatureFlags(this.teacherViewNavigationData, 'teacher');
              let navArr = [...navArr1, ...navArr2];
              return navArr;
            }
            else{
              let navArr3 = checkNavListForFeatureFlags(navigationData, 'students');
              return navArr3;
            }
          }
          else {
            let navArr = checkNavListForFeatureFlags(this.adminViewNavigationData, 'admin');
            return navArr;
          }
        }
        else {
          if(this.isTeacherViewSelected){
            let navArr1 = checkNavListForFeatureFlags(this.teacherViewNavigationData, 'teacher');
            return navArr1;
          }
          else{
            let navArr2 = checkNavListForFeatureFlags(navigationData, 'students')
            return navArr2;
          }
        }

      }
    },
    userName: {
      get() {
        return this.userState.name
      }
    },
    showPoweredBy: {
      get() {
        return !_.isEmpty(this.courseDetails) && !hidePoweredByMentoMind();
      }
    },
    showUserUpgradeButton: {
      get(){
        let showUpgradeButton = true;
        if(!_.isEmpty(this.profileDetails)){
          for(let packageDetail of this.profileDetails?.packageDetails){
            showUpgradeButton = showUpgradeButton && (!packageDetail.isActive || (packageDetail.priceType == 'free'));
          }
        }
        return showUpgradeButton;
      }
    }
  },


  watch: {

    notificationItem: {
      handler(newValue) {
        // if(newValue.challenge)

      },
      deep: true
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    getCustomers() {
      this.currentCustomer = this.currentSavedCustomer;
      this.dialog = true;
    },
    close() {
      this.defaultCustomer = this.currentSavedCustomer;
      this.dialog = false;
    },
    updateCourseId() {
      storeCourseId(this.currentCustomer);
      this.currentSavedCustomer = this.currentCustomer;
      this.$router.go();
      this.close();
    },
    getCustomerName() {
      for (let x of this.customersList) {
        if (this.currentSavedCustomer == x.courseId) {
          return x.courseName;
        }
      }
      return "Change Customer"
    },
    navItemActive(item) {
      if (this.currentRoute.startsWith(item)) {
        return true;
      }
      return false;
    },
    toggleDrawer() {
    },
    toggleDrawerForTest() {
      this.$store.commit("updateTestSeriesState", {
        submissionData: this.testSeriesState.submissionData,
        clear: true,
      });
    },
    signOut() {
      recordAnalyticsEvent(AnalyticsEvents.v2Signout);
      this.$store.dispatch("logout").then(() => {
        this.showMenu = false;
        // localStorage.setItem('newUser', false);
        // localStorage.setItem('newLearn', false);
        this.$router.push('/');
        this.$store.commit("getLoggedInUserDetails")
        if (this.isWhiteLabelApp) {
          this.$store.dispatch("loadCourseDetails", { appUrl: window.location.origin })
        }
      });
    },
    showProfile() {
      this.showMenu = !this.showMenu;
    },
    getFirstName(fullName) {
      const allNames = fullName.trim().split(" ");
      return allNames[0];
    },
    getInitials(fullName) {
      const allNames = fullName.trim().split(' ');
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
      return initials;
    },
    showUpgrade() {
      this.$store.commit('openUpgradeDialog', true);
    },
    async changeView(val) {
      await this.$router.replace(val ? '/' : '/learn');
      this.$store.commit('switchingViewData', val);
      if(val == false){
        this.$store.commit("assignmentsLoadedFromServer", false)
        this.$store.dispatch("loadAssignments", {
          isCurrent: true,
          currentDate: moment().format("YYYY-MM-DD")
        })
      }
    },
    openMento() {
      window.open('https://mentomind.ai/');
    },
    checkWhiteLabelApp(){
      this.isWhiteLabelApp = isWhiteLabelApp();
    }
  },
};
</script>

<style scoped>
.t-nav {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: black !important;
  margin: 0 10px !important;
}

.tab{
  border-radius: 10px !important;
  
  @media only screen and (max-width: 600px) {
    border-radius: 0px !important;
  }
}

.tab:hover {
  background: rgba(83, 109, 254, 0.1) !important;
  /* color: white !important; */
  border-radius: 10px !important;

  @media only screen and (max-width: 600px) {
    border-radius: 0px !important;
  }
}

.tab-2 {
  border: 1px solid rgb(var(--v-theme-iconGrey));
  border-radius: 10px !important;
}

.tab-3 {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 10px !important;
}

.profile-bg {
  background: #2B2B44 !important;
  border-radius: 10px !important;
  color: white !important;
  margin: 18px !important;
}

.more-tab {
  width: calc(100% - 20px) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  justify-content: flex-start !important;
}

.tablet-tab {
  align-items: center !important;
  display: flex !important;
  gap: 10px;
  cursor: pointer;
  /* justify-content: center !important; */
  /* flex-direction: column !important; */

}
.tablet-tab-2 {
  align-items: center !important;
  display: flex !important;
  gap: 10px;
  cursor: pointer;
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
}

.nav-text {
  font-family: "Inter", sans-serif !important;
  color: white !important;
  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.notification-text {
  background-color: #EA4B67;
  border-radius: 50px;
  font-family: "Inter", sans-serif !important;
  color: white !important;
  font-size: 0.7rem !important;
  line-height: 1;
  padding: 4px 8px;
  font-weight: 500;
}

.version-text {
  font-size: 0.64rem !important;
}

.selected-nav {
  /* background: rgba(208, 221, 237, 0.3) !important; */
  color: black !important;
  border-radius: 10px !important;

  @media only screen and (max-width: 600px) {
    border-radius: 0px !important;
  }

  /* margin: 10px 10px 10px 10px !important; */
}

.more-bg {
  margin-top: 10px !important;
}

.no-radius {
  border-radius: 0px !important;
}

.no-radius:hover {
  border-radius: 0px !important;
}

.upgrade-plan {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  color: white !important;
  border-radius: 10px !important;
}

/* .selected-nav:hover {
  background: transparent !important;
} */

.selected-tab {
  color: brown;
}

.cursor-pointer {
  cursor: pointer !important;
}

.imgSvg {}

.toggler-outer-bg {
  border-radius: 200px !important;
  /* border: 2px solid rgba(255, 255, 255, 0.12); */
  background: #2A2941;
}

.active-button {
  border-radius: 200px;
  /* background-color: rgb(var(--v-theme-background)) !important; */
  /* background-color: #494960 !important; */
  /* border: 1.4px solid var(--Purple-G-1, #AB77FF); */
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
  border-radius: 200px;
  background: none;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
  border: 2px solid transparent;
}

.button-text {
  letter-spacing: -0.08px;
}

.logo-img {
  /*height: 46px !important;*/
  width: 184px !important;
  object-fit: contain;
}

.no-border {
  border: none !important;
}


.student-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.student-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.student-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.student-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.student-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.student-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.student-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}
</style>

<style>
.tab>.v-list-item__append>.v-list-item__spacer {
  display: none !important;
}


.nav-spacer>.v-list-item__prepend>.v-list-item__spacer {
  display: none !important;
}

.mentoBadge .v-badge__badge {
	color: #FEFEFE;
	font-family: Prompt;
	font-size: 10px !important;
}
</style>
