<template>
  <v-layout class="mb-0 pb-0 d-flex flex-column" v-if="dataLoaded" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
    <v-row v-if="dataLoaded" class="ma-0" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end" align="center">
      <v-col cols="auto" class="pa-0">
        <SelfHelpGuideTogglerComponent class="mt-6 mr-8"/>
      </v-col>
    </v-row>
    <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

      <v-row class="mt-0 pt-0">

        <v-col class=" mt-0 pt-0 justify-center align-center text-center"
          :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
          <v-container fluid="" class="px-2 mt-0 pt-0">
            <v-row justify="center" class="mb-0" align="center">
              <v-col cols="12">
                <Transition name="slide-fade">

                  <v-row class="text-center mb-1" justify="center" v-if="aiBar">
                    <v-col cols="12" sm="9" md="10" lg="6" xl="6">

                      <v-card class="ai fill-height py-8 px-6">
                        <v-row justify="space-between" align="center">
                          <v-col cols="12" md="6">
                            <v-row align="center" class="small-card">
                              <v-col cols="auto">
                                <v-avatar size="30">
                                  <v-img src="@/assets/mento_ai.png">

                                  </v-img>

                                </v-avatar>
                              </v-col>
                              <v-col cols="auto">
                                <v-row class=" t-h6 text-white">
                                  {{ this.aiChallenge.message }}
                                </v-row>
                                <v-row class="t-small-subtitle text-white ">
                                  {{ this.aiChallenge.description }}
                                </v-row>
                              </v-col>
                            </v-row>

                          </v-col>
                          <v-col cols="12" sm="auto" class="ml-10 ml-md-0 small-card">
                            <v-row class="small-card" align="center">
                              <v-col cols="auto">
                                <v-btn @click="acceptChallenge" flat color="white"
                                  :size="$vuetify.display.smAndUp ? 'default' : 'small'"
                                  class="text-none t-btn btn-rd py-2 py-md-2 " :class="{
                                    'fill-height': $vuetify.display.smAndDown
                                  }">
                                  Accept
                                </v-btn>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn @click="rejectChallenge" flat color="white" variant="outlined"
                                  :size="$vuetify.display.smAndUp ? 'default' : 'small'"
                                  class="text-none t-btn btn-rd py-2 py-md-2 " :class="{
                                    'fill-height': $vuetify.display.smAndDown
                                  }">
                                  Dismiss
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </Transition>
              </v-col>
              <v-col cols="12  my-5">
                <v-row class="t-h125 text-center mb-1 text-primaryTextColor" justify="center">
                  Create or join a challege

                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12" sm="9" md="5" lg="3" xl="3">

                    <v-hover v-slot="{ isHovering, props }">

                      <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                        class=" py-4 testCard start-bg py-lg-9 py-xl-12" v-bind="props" @click="startChallenge" ref="ChallengePage__type--newChallenge">
                        <v-card-text class="d-flex flex-column fill-height">
                          <v-row justify="start" justify-md="center" class="px-1 small-card" align="center">
                            <v-col cols="auto" md="12" class="text-start text-md-center">
                              <v-avatar size="60">
                                <v-img src="@/assets/file.png"></v-img>
                              </v-avatar>
                            </v-col>
                            <v-col cols="8" md="10" class="text-start text-md-center small-card">
                              <v-row justify="start" justify-md="center" class="small-card">
                                <span class="t-h6 text-start text-md-center small-card text-primaryTextColor">
                                  Create a challenge
                                </span>
                              </v-row>
                              <v-row justify="start" justify-md="center" class="small-card">
                                <span
                                  class="t-subtitle text-start text-md-center small-card my-2 mt-4  text-cardSecondaryTextColor">

                                  Start a challenge and invite a friend to join

                                </span>
                              </v-row>
                            </v-col>
                          </v-row>


                          <!-- <v-spacer></v-spacer>

                          <v-row justify="center" class="mt-5 px-1">
                            <v-col cols="11" sm="10" class="text-center my-0 py-0">
                              <v-btn variant="flat" :block="!$vuetify.display.mdAndUp" width="180" color="primaryGreen"
                                class="t-btn" @click="startChallenge()">Start</v-btn>

                            </v-col>

                          </v-row> -->
                        </v-card-text>

                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" sm="9" md="5" lg="3" xl="3">

                    <v-hover v-slot="{ isHovering, props }">

                      <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                        class=" py-4 testCard join-bg fill-height py-lg-9 py-xl-12" v-bind="props" @click="joinChallenge" ref="ChallengePage__type--joinChallenge">
                        <v-card-text class="d-flex flex-column fill-height">
                          <v-row justify="start" justify-md="center" class="px-1 small-card" align="center">
                            <v-col cols="auto" md="12" class="text-start text-md-center ">
                              <v-avatar size="60">
                                <v-img src="@/assets/cap.png"></v-img>
                              </v-avatar>
                            </v-col>
                            <v-col cols="8" md="10" class="text-start text-md-center small-card">
                              <v-row justify="start" justify-md="center" class="small-card ">
                                <span class="t-h6 text-start text-md-center small-card text-primaryTextColor">
                                  Join a challenge
                                </span>
                              </v-row>
                              <v-row justify="start" justify-md="center" class="small-card">
                                <span
                                  class="t-subtitle text-start text-md-center small-card my-2 mt-4 text-cardSecondaryTextColor">

                                  Join a challenge created by a friend
                                </span>
                              </v-row>
                            </v-col>
                          </v-row>


                          <!-- <v-spacer></v-spacer>

                          <v-row justify="center" class="mt-5 px-1">
                            <v-col cols="11" sm="10" class="text-center my-0 py-0">
                              <v-btn variant="flat" :block="!$vuetify.display.mdAndUp" width="180"
                                class="t-btn" color="primaryOrange" @click="joinChallenge()">Join</v-btn>

                            </v-col>

                          </v-row> -->
                        </v-card-text>

                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="12" sm="9" md="5" lg="3" xl="3">

                    <v-hover v-slot="{ isHovering, props }">

                      <v-card elevation=0 :class="{ 'on-hover': isHovering }"
                        class=" py-4 testCard join-bg fill-height py-lg-9 py-xl-12" v-bind="props" @click="mentoChallenge" ref="ChallengePage__type--challengeMento">
                        <v-card-text class="d-flex flex-column fill-height">
                          <v-row justify="start" justify-md="center" class="px-1 small-card" align="center">
                            <v-col cols="auto" md="12" class="text-start text-md-center ">
                              <v-avatar size="60">
                                <v-img src="@/assets/mento_ai.png"></v-img>
                              </v-avatar>
                            </v-col>
                            <v-col cols="8" md="10" class="text-start text-md-center small-card">
                              <v-row justify="start" justify-md="center" class="small-card ">
                                <span class="t-h6 text-start text-md-center small-card text-primaryTextColor">
                                  Challenge your AI companion
                                </span>
                              </v-row>
                              <v-row justify="start" justify-md="center" class="small-card">
                                <span
                                  class="t-subtitle text-start text-md-center small-card my-2 mt-4 text-cardSecondaryTextColor">
                                  Get started now
                                </span>
                              </v-row>
                            </v-col>
                          </v-row>


                          <!-- <v-spacer></v-spacer>

                          <v-row justify="center" class="mt-5 px-1">
                            <v-col cols="11" sm="10" class="text-center my-0 py-0">
                              <v-btn variant="flat" :block="!$vuetify.display.mdAndUp" width="180"
                                class="t-btn" color="primaryOrange" @click="joinChallenge()">Join</v-btn>

                            </v-col>

                          </v-row> -->
                        </v-card-text>

                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="auto">
                <v-hover v-slot="{ isHovering, props }">
                  <v-sheet @click="viewPastChallenges" class="view-past-challenges t-subtitle"
                    :class="{ 'on-hover': isHovering }" v-bind="props" ref="ChallengePage__btn--pastChallengeReports">
                    <span class="text-font-8rem text-white">View past challenges</span>
                  </v-sheet>
                </v-hover>
              </v-col>
            </v-row>

          </v-container>
        </v-col>

      </v-row>
    </v-container>
  </v-layout>
</template>
        
<script>
import { mapGetters, mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useNotification } from "@kyvg/vue3-notification";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { v4 as uuidv4 } from "uuid";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";

export default {
  name: 'ChallengePage',
  data: () => ({
    currentScreenName: "ChallengePage",
    breadCrumbs: [],
    drawer: false,
    dataLoaded: false,
    aiBar: false,
  }),
  components: { SelfHelpGuideTogglerComponent },
  async created() {

    this.$store.commit("updateNavBarItems", { data: "/challenge", show: false })

    recordAnalyticsEvent(AnalyticsEvents.v2ChallengeScreenOpen, {
    });
    this.creatNodes()
    this.$store.commit("aiChallengeLoadedFromServer", false)
    await this.$store.dispatch("loadAIChallenge")
    if ("challengeId" in this.aiChallenge) {
      this.aiBar = true
    }
    this.dataLoaded = true
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  computed: {
    ...mapState(["navDrawer", "aiChallenge", "updateTab"],),
    ...mapGetters(['currentHighlightedElements']),
  },

  watch: {
    updateTab: {
      async handler(newValue)  {
        if (newValue == "/challenge") {
          this.$store.commit("updateTabData", null)
         await this.loadAIChallenge()
        }
      },
      deep: true
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
   async loadAIChallenge() {  
      this.$store.commit("aiChallengeLoadedFromServer", false)
      await this.$store.dispatch("loadAIChallenge")
      if ("challengeId" in this.aiChallenge) {
        this.aiBar = true
      }
    },
    startChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeCreateChallenge, {
      });
      this.$router.push('/challenge/challenges')
    },
    joinChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeJoinChallenge, {
      });
      this.$router.push('/challenge/joinchallenge')
    },
    rejectChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeDismissAIChallenge, {
        challengeId: this.aiChallenge.challengeId

      });
      this.aiBar = false
      this.$store.dispatch("rejectAIChallenge", { challengeId: this.aiChallenge.challengeId })
    },
    acceptChallenge() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeAcceptAIChallenge, {
        challengeId: this.aiChallenge.challengeId
      });
      this.$router.push(`/challenge/participantwaitingroom/${this.aiChallenge.challengeId}?type=ai`)

    },
    creatNodes() {
      this.breadCrumbs = [];

      this.breadCrumbs.push(
        {
          title: "Challenge",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: "challenge",
          pageRoute: `/challenge`

        });
    },
    viewPastChallenges() {
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeViewPastChallenges, {
      });
      this.$router.push('/challenge/pastchallenges')
    },
    mentoChallenge(){
      const challengeId = uuidv4();
      recordAnalyticsEvent(AnalyticsEvents.v2ChallengeMentoChallenge, {
        challengeId: challengeId
      });
      this.$router.replace(`/challenge/participantwaitingroom/${challengeId}?type=ai`)
    }
  }
}
</script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;

}



.v-card.on-hover {
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  /* border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.ai {

  background: linear-gradient(180deg, #BF98FF 0%, #656CFF 100%);
  border-radius: 10px;
}

.bg {
  background-color: red;
}

.heightBread {
  height: 80px;
}

.layoutLarge {
  height: 100%;
}

.text-font-8rem {
  font-size: 0.8rem;
  font-family: "Inter" !important;
  color: rgba(255, 255, 255, 0.7);
}

.view-past-challenges {
  padding: 10px 25px;
  box-sizing: border-box;
  background: rgba(38, 37, 58, 0.5);
  border: 1.5px solid rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.view-past-challenges.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: rgb(var(--v-theme-primaryPurple));
  background-color: rgb(var(--v-theme-primaryPurple));
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.small-card {}

@media only screen and (max-width: 335px) {
  .small-card {
    text-align: center !important;
    justify-content: center !important;
    margin-left: 0px !important;
  }
}
</style>
        
<style></style>