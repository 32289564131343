<template>
  <v-dialog persistent v-model="showInfoTab" transition="dialog-top-transition" width="1024">
    <template v-slot:activator="{ props }">
      <v-btn icon="mdi-information-variant" class="t-btn text-none rounded-full" :class="{'info-icon': showAbsolutePosition}" density="compact"
        variant="flat" color="cardBackgroundColor" v-bind="props" @click="showInfoTab = true">
      </v-btn>
    </template>

    <v-card v-if="currentSubjectType == 'readingandwriting'" class="info-card text-white pa-4">

      <v-card-actions class="justify-end pa-0">
        <v-btn icon="mdi-close" class="t-btn text-none rounded-full mx-6" density="compact" variant="outlined"
          color="white" @click="closeInfoTab">
        </v-btn>
      </v-card-actions>

      <v-card-text class="pt-0 px-3 px-sm-6 pt-0">
        <v-container class="px-0 pt-0">
          <v-row>
            <!-- Left Side -->
            <v-col cols="12" sm="5" class="align-self-stretch d-flex flex-column ">
              <v-row no-gutters>
                <!-- <v-col cols="12" class="d-flex justify-center justify-sm-start align-center">
                      <v-avatar class="ma-0" size="45px">
                        <v-img src="@/assets/info_sat.svg"></v-img>
                      </v-avatar>
                    </v-col> -->

                <v-col cols="12" class="mt-3">
                  <p class="t-h5 text-center text-sm-left">Digital SAT (Reading and Writing)</p>
                </v-col>

                <v-col cols="12" class="mt-3 mt-sm-0">
                  <p class="t-h6Reg info-content text-center text-sm-left">
                    In the Digital SAT English test, students will have 64 minutes to complete 54 questions. These
                    questions are divided into two sections of 27 questions each, with 32 minutes allotted for each
                    section.
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="smAndUp">
                <v-col class="pb-0" align-self="end">
                  <v-img src="@/assets/info_mento_bulb.png" class="info-img-bulb"></v-img>

                </v-col>
              </v-row>
            </v-col>

            <!-- Right Side -->
            <v-col cols="12" sm="7" class="mt-4 mt-sm-3">
              <v-row justify="space-between">
                <v-col cols="auto">
                  <p class="t-h6">Question Types</p>
                </v-col>

                <v-col cols="auto" class="text-right">
                  <p class="t-h6">Distribution</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0">
                  <v-card elevation="0" class="info-syllabus-card text-white">
                    <v-card-text class="pa-0">
                      <v-row class="ma-0 pa-0" justify="center" align="center">
                        <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                          <p>Word in Context</p>
                          <p>Text Structure & Purpose</p>
                          <p>Cross Text Connections</p>
                        </v-col>
                        <v-col cols="4"
                          class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                          <p class="text-white">13 - 15 Questions</p>
                          <p class="text-progress">~28% Weightage</p>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0">
                  <v-card elevation="0" class="info-syllabus-card text-white">
                    <v-card-text class="pa-0">
                      <v-row class="ma-0 pa-0" justify="center" align="center">
                        <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                          <p>Central Ideas & Details</p>
                          <p>Command of Evidence (Both Textual & Competitive)</p>
                          <p>Inferences</p>
                        </v-col>
                        <v-col cols="4"
                          class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                          <p class="text-white">12 - 14 Questions</p>
                          <p class="text-progress">~ 26% Weightage</p>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0">
                  <v-card elevation="0" class="info-syllabus-card text-white">
                    <v-card-text class="pa-0">
                      <v-row class="ma-0 pa-0" justify="center" align="center">
                        <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                          <p>Boundaries</p>
                          <p>Form, Structure, and Sense</p>
                        </v-col>
                        <v-col cols="4"
                          class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                          <p class="text-white">11 - 15 Questions</p>
                          <p class="text-progress">~ 26% Weightage</p>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pb-0">
                  <v-card elevation="0" class="info-syllabus-card text-white">
                    <v-card-text class="pa-0">
                      <v-row class="ma-0 pa-0" justify="center" align="center">
                        <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                          <p>Rhetorical Synthesis</p>
                          <p>Transitions</p>
                        </v-col>
                        <v-col cols="4"
                          class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                          <p class="text-white">8 - 12 Questions</p>
                          <p class="text-progress">~ 20% Weightage</p>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>


    </v-card>

    <!-- v-else  -->
    <v-card v-if="currentSubjectType == 'math'" class="info-card info-card-math text-white pa-4">

      <v-card-actions class="justify-end pa-0">
        <v-btn icon="mdi-close" class="t-btn text-none rounded-full mx-6" density="compact" variant="outlined"
          color="white" @click="closeInfoTab">
        </v-btn>
      </v-card-actions>

      <v-card-text class="pt-0 px-3 px-sm-6 pt-0">
        <v-container class="px-0 pt-0">
          <v-row>
            <!-- Left Side -->
            <v-col cols="12" sm="3" class="align-self-stretch d-flex flex-column ">
              <v-row no-gutters class="flex-grow-0">
                <!-- <v-col cols="12" class="d-flex justify-center justify-sm-start align-center">
                      <v-avatar class="ma-0" size="45px">
                        <v-img src="@/assets/info_sat.svg"></v-img>
                      </v-avatar>
                    </v-col> -->

                <v-col cols="12" class="mt-3">
                  <p class="t-h5 text-center text-sm-left">Digital SAT (Math)</p>
                </v-col>

                <v-col cols="12" class="mt-3">
                  <p class="t-h6Reg info-content text-center text-sm-left">
                    In the Digital SAT Math test, students will have 70 minutes to complete 44 questions. These questions are divided into two sections of 22 questions each, with 35 minutes allotted for each section
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="smAndUp" class="flex-grow-0">
                <v-col class="pb-0" align-self="end">
                  <v-img src="@/assets/info_mento_bulb.png" class="info-img-bulb"></v-img>

                </v-col>
              </v-row>
            </v-col>

            <!-- Right Side -->
            <v-col cols="12" sm="9" class="mt-4 mt-sm-3 overflow-y-auto overflow-x-hidden right-side-overflow">
              <v-row justify="space-between">
                <v-col cols="auto">
                  <p class="t-h6">Question Types</p>
                </v-col>

                <v-col cols="auto" class="text-right">
                  <p class="t-h6">Distribution</p>
                </v-col>
              </v-row>

              <section class="mt-3" :class="{ 'dialog-slide': smAndUp }">
                <v-row>
                  <v-col class="pb-0">
                    <v-card elevation="0" class="info-syllabus-card text-white">
                      <v-card-text class="pa-0">
                        <v-row class="ma-0 pa-0" justify="center" align="center">
                          <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                            <p>Linear equations in one variable</p>
                            <p>Linear equations in two variables</p>
                            <p>Linear functions</p>
                            <p>Systems of two linear equations in two variables</p>
                            <p>Linear inequalities in one or two variables</p>
                          </v-col>
                          <v-col cols="4"
                            class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                            <p class="text-white">13 - 15 Questions</p>
                            <p class="text-progress">~35% Weightage</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-0">
                    <v-card elevation="0" class="info-syllabus-card text-white">
                      <v-card-text class="pa-0">
                        <v-row class="ma-0 pa-0" justify="center" align="center">
                          <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                            <p>Equivalent expressions</p>
                            <p>
                              Nonlinear equations in one variable and systems of equations in
                              two variables
                            </p>
                            <p>Nonlinear functions</p>
                          </v-col>
                          <v-col cols="4"
                            class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                            <p class="text-white">13 - 15 Questions</p>
                            <p class="text-progress">~ 35% Weightage</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-0">
                    <v-card elevation="0" class="info-syllabus-card text-white">
                      <v-card-text class="pa-0">
                        <v-row class="ma-0 pa-0" justify="center" align="center">
                          <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                            <p>Ratios, rates, proportional relationships, and units</p>
                            <p>Percentages</p>
                            <p>
                              One-variable data: distributions and measures of center and
                              spread
                            </p>
                            <p>Two-variable data: models and scatterplots</p>
                            <p>Probability and conditional probability</p>
                            <p>Inference from sample statistics and margin of error</p>
                            <p>
                              Evaluating statistical claims: observational studies and
                              experiments
                            </p>
                          </v-col>
                          <v-col cols="4"
                            class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                            <p class="text-white">5 - 7 Questions</p>
                            <p class="text-progress">~ 15% Weightage</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-0">
                    <v-card elevation="0" class="info-syllabus-card text-white">
                      <v-card-text class="pa-0">
                        <v-row class="ma-0 pa-0" justify="center" align="center">
                          <v-col cols="8" class="d-flex flex-column info-syllabus-topic t-para pa-4">
                            <p>Area and volume</p>
                            <p>Lines, angles, and triangles</p>
                            <p>Right triangles and trigonometry</p>
                            <p>Circles</p>
                          </v-col>
                          <v-col cols="4"
                            class="info-syllabus-marks align-self-stretch pa-4 d-flex flex-column justify-center align-center">
                            <p class="text-white">5 - 7 Questions</p>
                            <p class="text-progress">~ 15% Weightage</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </section>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>


    </v-card>

  </v-dialog>
</template>

<script>
import { useDisplay } from 'vuetify';
export default {
  name: 'InfoComponentLearnPage',
  props: {
    "currentSubjectType": {
      type: String,
    }, 
    "showAbsolutePosition": {
      type: Boolean,
      default: false,
    }
  },
  setup() {
      // Destructure only the keys we want to use
      const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
      return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
  data: () => ({
    showInfoTab: false,
  }),
  methods: {
    closeInfoTab() {
        this.showInfoTab = false;
      }
  }
  }
</script>

<style scoped>
.info-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.info-card {
  background-color: #26253A;
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 15px !important;
}

.info-content {
  line-height: 1.375rem !important;
}

.info-img-bulb {
  width: 10.13088rem;
  height: 13.13088rem;
}

.info-syllabus-card {
  border-radius: 0.9375rem !important;
  background: #45455F;
}

.info-syllabus-marks {
  gap: 0.56rem !important;
  background: #313145;
}

.info-syllabus-topic {
  gap: 0.56rem !important;
  font-size: 0.8rem !important;
}


.right-side-overflow{
  max-height: 80vh;

}

.right-side-overflow::-webkit-scrollbar{
  width: 6px;
}

.right-side-overflow::-webkit-scrollbar-track {
  background-color: #313145;
  border-radius: 10px;
}

.right-side-overflow::-webkit-scrollbar-thumb {
  background-color: #45455F !important;
  cursor: pointer;
  border-radius: 10px;

}

@media only screen and (max-width: 600px) {
  .right-side-overflow{
    height: 100%;
    overflow: auto;
  }
}
</style>