import { createStore } from 'vuex'
import initialState from "@/store/state";
import getters from "@/store/getters";
import mutations from "@/store/mutations";
import actions from "@/store/actions";

export default createStore({
  state: initialState,
  getters: getters,
  mutations: mutations,
  actions: actions,
  
})
