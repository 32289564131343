<template>
  <v-layout v-if="dataLoaded" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
    <v-container fluid class="mt-3 main-lg container-width">
      <v-row align="center" justify="start" dense class="mb-2">
        <v-col cols="auto">
          <span class="t-h125 text-center mb-1 text-primaryTextColor">Classes <span v-if="classes.length>0"> ({{ classes.length }})</span></span>
        </v-col>
      </v-row>
      <template v-if="classes.length > 0">
        <v-card
          elevation="0"
          class="my-2 pa-0 main-card card-bg card-border"
          v-for="item in classes"
          :key="item.batchId"
          @click="openClassDetails(item)"
        >
          <v-card-text class="pa-2 text-primaryTextColor">
            <v-row
              class="ma-0"
              align="center"
              justify="start"
              dense
              :class="{ 'pt-1': $vuetify.display.xs }"
            >
              <v-col cols="auto" sm="1">
          
          <v-avatar :size="40" class="icon-bg">
            <v-icon icon="mdi-human-male-board" color="white" class="" size="24"></v-icon>
          </v-avatar>
              </v-col>
              <v-col cols="10" sm="11">
                <v-row dense align="center" class="pa-0">
                  <v-col cols="9" sm="10">
                    <span class="t-1remReg text-white mr-2">{{
                      item.batchName
                    }}</span>
                  </v-col>
                  <!-- No of students -->
                  <v-col cols="3" sm="2" class="d-flex flex-column align-center pa-1">
                    <div class="pb-0">
                      <span class="t-h6Reg">{{ item.numberOfStudents }}</span>
                    </div>
                    <div class="">
                      <span class="t-h6Reg parameter-style">{{ item.numberOfStudents <= 1 ? 'Student' : 'Students'}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <v-card v-if="classes.length <= 0" class="card-bg card-border px-2 mb-3 py-2" elevation="0">
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <v-col cols="12" class="d-flex pa-3 align-center justify-center pa-1">
              <div class="pb-0 wrap-text">
                <span class="t-h6Reg">No Class Assigned</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
	</v-container>
  </v-layout>
</template>
<script>
import { useDisplay } from "vuetify";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { mapState } from "vuex";

export default {
  name: "ClassesListPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    dataLoaded: false,
  }),
  async created() {
    if(!this.isUserTeacher ){
      if(this.isUserAdmin){
        this.$router.replace('/admin/1');
      }
      else{
        this.$router.replace('/learn');
      }
    }
    this.$store.commit("updateClassName", "");
    this.$store.commit("updateNavBarItems", {
      data: "/teacher/classes",
      show: false,
    });
    await this.$store.dispatch("getTeacherClassesData");
    this.dataLoaded = true;

    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenOpen, {
        courseId: this.courseId,
    });
  },
  computed: {
    ...mapState(['isUserTeacher', 'isUserAdmin', 'courseId']),
    classes: {
      get() {
        return this.$store.state.teacherClassList;
      },
    },
  },

  watch: {},

  methods: {
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    openClassDetails(item) {
      this.$store.commit("updateClassName", item.batchName);
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewClassScreenClassOpen, {
          courseId: this.courseId,
          batchId: item.batchId
      });
      this.$router.push("classes/" + item.batchId + "/1");
    },
  },
};
</script>
<style scoped>
.layoutLarge {
  height: 100%;
}

.container-width{
  max-width: 1080px;
}

.main-lg {
  padding-top: 100px;
}

.main-md {
  padding-top: 100px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.main-card-header {
  background: #2b2b44;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.main-heading {
  font-size: 1.5rem;
  color: rgb(var(--v-theme-primaryPurple));
}

.assignment-card {
  background: #303146;
}

.card-bg {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;
}
.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

/* .card-border:hover{
  border: 2px solid #9461FC;
} */

.icon-bg{
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
}

.parameter-style {
  opacity: 0.4;
}
</style>
