<template>
    <v-card color="background h-100">
        <v-card-text class="pa-3 h-100">
            <v-row justify="space-between" class="t-h5 px-3 mb-3 align-center header-container">

                <v-col class="ml-0 pl-0" cols="auto">
                    Bookmark
                </v-col>
                <v-col class="text-right">
                    <v-icon size="24" @click="closeDialog()">
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="space-between" class="pa-0 mb-4 px-4" v-if="editNote">

                <v-col class="ma-0 pa-0" cols="12">
                    Bookmark Notes
                </v-col>
                <v-col class="ma-0 pa-0 mt-1" cols="12">
                    <v-textarea v-model="messageInputText" placeholder="Write notes for this bookmark..." hide-details
                        autofocus density="compact" variant="outlined" rows="4" row-height="0px"
                        @keydown.enter.shift.exact.prevent="messageInputText += '\n'" :disabled="!editNote">
                    </v-textarea>

                </v-col>
            </v-row>
            <v-row justify="space-between" class="pa-0 px-4 mb-4" v-if="editNote">
                <v-col class="ma-0 pa-0" cols="12">
                    Bookmark Tag
                </v-col>
                <v-col class="ma-0 pa-0 mt-1" cols="12">
                    <v-btn-toggle v-model="tag" :color="tag" group variant="outlined" divided class="button-group">
                        <v-btn v-for="tab in tagList" :key="tab.value" :value="tab.value" @click="changeTag(tab.value)">
                            <div class="d-flex align-center flex-column justify-center">
                                <span class="text-none button-text t-h6Reg">{{ tab.text }}</span>
                                <v-sheet :color="tab.value" height="4" width="54" tile></v-sheet>
                            </div>
                        </v-btn>
                    </v-btn-toggle>

                </v-col>
            </v-row>

            <v-row class="list-item text-white align-center pa-0 ma-0 py-1" dense v-if="bookmark && !editNote">
                <v-col cols="12" class=" py-2 ma-0 pl-2">
                    <v-row dense class="pa-0 ma-0 pl-1" :class="`border-left-${tag}`">
                        <v-col cols="12" class="pl-1 py-0 t-h6Reg text-black">
                            {{ (messageInputText.trim().replace(' ', '').length == 0) ? 'Bookmark added' :
                            messageInputText }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="pa-0 ma-0">
                <v-spacer v-if="editNote"></v-spacer>

                <v-col cols="auto" class="ma-0 pa-0" v-if="bookmark && !editNote">
                    <v-btn prepend-icon="mdi-pencil-outline" style="text-transform: none;" size="default"
                                density="comfortable"  variant="text" @click.stop="editNote = true"
                                 color="primary">
                                Edit Bookmark
                            </v-btn>

                </v-col>

                <v-col cols="auto" class="ma-0 pa-0" v-if="bookmark && !editNote">
                    <v-btn prepend-icon="mdi-delete-outline" style="text-transform: none;" size="default"
                            density="comfortable" variant="text"
                            @click.stop="removeBookmark(false)" color="error">
                            Remove Bookmark
                        </v-btn>

                </v-col>

                <v-col cols="auto" class="ma-0 pa-0 mr-2" v-if="bookmark && editNote">
                    <v-btn class="t-btn text-white cancel-btn text-none w-100" density="default" variant="outlined"
                        @click="editNote = false">
                        <span class="text-decoration-none text-none t-h6Reg text-black">
                            Cancel
                        </span>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-0" v-if="editNote">
                    <v-btn class="t-btn text-white bookmark-btn text-none w-100" density="default" variant="outlined"
                        color="primaryPurple" @click="messageSendHandler">
                        <span class="text-decoration-none text-none t-h6Reg text-black">
                            {{ bookmark ? 'Save' : 'Add Bookmark' }}

                        </span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>

import { mapState, mapGetters } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import QuestionReviewFeedbackDetails from "@/components/questionReview/QuestionReviewFeedbackDetails.vue";

export default {
    name: "BookmarkComponent",
    data: () => ({
        messageInputText: "",
        tag: "",
        bookmark: true,
        requiredRule: [(v) => !!v || "Required"],
        tagList: [
            { text: 'Red', value: 'red' },
            { text: 'Green', value: 'green' },
            { text: 'Blue', value: 'blue' }
        ],
        editNote: true,
    }),
    props: {
        qId: String,
        testType: String,
        testId: String,
        testInstanceId: String,
        qObj: Object,
        bookmarkObj: Object
    },
    components: {},
    async created() {
        recordAnalyticsEvent(AnalyticsEvents.v2QuestionBookmarkToolScreenOpen, {
            qId: this.qId
        });
        
    },
    computed: {
        ...mapState([]),
    },
    watch: {
        bookmarkObj:{
            handler(val){
                this.bookmark = val?.bookmark ?? false;
                this.tag = val?.tag ?? '';
                this.messageInputText = val?.notes?.message ?? '';
                this.editNote = !this.bookmark;
            },
            immediate: true,
            deep: true,
        }
    },

    methods: {
        closeDialog() {
            recordAnalyticsEvent(AnalyticsEvents.v2QuestionBookmarkToolScreenClose, {
                qId: this.qId
            });
            this.$emit("closeDialog")
        },
        async removeBookmark(value) {
            recordAnalyticsEvent(AnalyticsEvents.v2QuestionBookmarkToolRemoveButtonClicked, {
                qId: this.qId
            });
            await this.$store.dispatch('updateBookmark', {
                ...this.bookmarkObj,
                notes: {},
                tag: "",
                bookmark: value
            });
            this.closeDialog();
        },
        changeTag(value) {
            console.log("changeTag ", value)
            // if (this.bookmarkObj.tag == value) {
            //     this.tag = ""
            // }
        },
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if (index === 0 || index === allNames.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials[0];
        },
        async messageSendHandler() {
            let val = this.messageInputText;

            let notes = {
                message: val,
                messageTimestamp: parseInt(Date.now() / 1000)
            }
            let bookmarkObj = {
                ...this.bookmarkObj,
                tag: this.tag ?? "",
                notes: notes
            }
            if (this.bookmark) {
                recordAnalyticsEvent(AnalyticsEvents.v2QuestionBookmarkToolEditButtonClicked, {
                    qId: this.qId
                });
                await this.$store.dispatch('updateBookmark', {
                    ...bookmarkObj
                })
            } else {
                recordAnalyticsEvent(AnalyticsEvents.v2QuestionBookmarkToolAddButtonClicked, {
                    qId: this.qId
                });
                await this.$store.dispatch('addBookmark', {
                    qId: this.qId,
                    testId: this.testId,
                    testInstanceId: this.testInstanceId,
                    bookmark: true,
                    ...bookmarkObj
                })
                this.bookmark = true;
            }
            this.editNote = false;
        },


    },
};
</script>

<style scoped>

.header-container {
    background: #35354E;
    color: white;
}

.button-group {
    height: 40px;
}


.button-text {
    letter-spacing: -0.08px;
}

.bookmark-btn {
    border: 2px solid rgb(var(--v-theme-primaryPurple)) !important;
    border-radius: 29.4079px !important;
}

.cancel-btn {
    border: 2px solid #9e9e9e !important;
    border-radius: 29.4079px !important;
    color: #9e9e9e !important;
}

.list-item {
    border-radius: 12px;
    padding: 5px 8px;
    background: transparent;
}

.border-left-red {
    border-left: 4px solid #F44336;
}

.border-left-green {
    border-left: 4px solid #4CAF50;
}

.border-left-blue {
    border-left: 4px solid #2196F3;
}
</style>