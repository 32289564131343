<template>
  <v-card-text>
    <v-container>
      <!-- USER NAME -->
      <v-row>
        <v-text-field
          :model-value="profileInfo.userName"
          label="User Name"
          variant="outlined"
          readonly
        >
        </v-text-field>
      </v-row>

      <!-- IDENTITY TYPE -->
      <v-row>
        <v-text-field
          :model-value="profileInfo.identityValue"
          :label="
            profileInfo.identityType === 'ROLLNUMBER'
              ? 'Roll Number'
              : 'Email Id'
          "
          variant="outlined"
          readonly
        >
        </v-text-field>
      </v-row>
    </v-container>

    <!-- IF IN ORGANIZATION -->
    <template v-if="profileInfo.organization">
      <p>
        You are currently connected to
        {{ profileInfo.organization.organizationName }} system
      </p>
      <br />
      <v-container>
        <!-- NAME -->
        <v-row>
          <v-text-field
            :model-value="profileInfo.organization.name"
            label="Name"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- ROLL NUMBER -->
        <v-row>
          <v-text-field
            label="Roll Number"
            :model-value="profileInfo.organization.rollNumber"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- ACTIVATION KEY -->
        <v-row>
          <v-text-field
            label="Activation Key"
            :model-value="profileInfo.organization.activationToken"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- EMAIL -->
        <v-row>
          <v-text-field
            label="Email"
            :model-value="profileInfo.organization.email"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- STATUS -->
        <v-row>
          <v-text-field
            label="Status"
            :model-value="
              profileInfo.organization.status ? 'Active' : 'Inactive'
            "
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- CLASS -->
        <v-row>
          <v-text-field
            label="Class"
            :model-value="profileInfo.organization.batch"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- GRADE -->
        <v-row>
          <v-text-field
            label="Grade"
            :model-value="profileInfo.organization.course"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>

        <!-- SCHOOL -->
        <v-row>
          <v-text-field
            label="School"
            :model-value="profileInfo.organization.center"
            variant="outlined"
            readonly
          >
          </v-text-field>
        </v-row>
      </v-container>
    </template>
  </v-card-text>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["profileInfo"]),
    },

};
</script>

<style scoped>
</style>