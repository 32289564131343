<template>
    <v-layout class="mb-0 pb-0" :class="{ 'layoutLarge': true }">
        <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

            <v-row class="mt-0 pt-0">

                <v-col class=" mt-0 pt-0 justify-center align-center text-center" :class="{ 'd-flex flex-column': true }">
                    <v-container fluid="" class="px-2 mt-0 pt-0">
                        <v-row justify="center">
                            <v-col cols="12" sm="8" md="5" lg="4" xl="3">
                                <v-card elevation=0 class="py-6 py-sm-14 fill-height testCard px-3 start-bg">
                                    <v-card-text class="">
                                        <v-row justify="center" class="text-center mb-2">
                                            <v-avatar size="80">
                                                <v-img src="@/assets/trophy.png">

                                                </v-img>
                                            </v-avatar>
                                        </v-row>
                                        <v-row class="t-h6 text-center text-primaryTextColor" justify="center">
                                            Player with the highest score
                                            wins the challenge!
                                        </v-row>

                                

                                        <v-row justify="center" class="text-center t-h4 text-primaryTextColor mt-12">
                                            {{ timer }}
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>

                    </v-container>
                </v-col>

            </v-row>

        </v-container>
    </v-layout>
</template>
          
<script>

import { mapState } from "vuex";



export default {
    name: 'ChallengeStartingScreen',
    data: () => ({
        timer: 3,
        intervalId: null

    }),
    components:
    {


    },
    async unmounted() {

    },
    async created() {
        this.$store.commit('appbarVisible', false);
        this.$store.commit('navbarVisible', false);
        this.$store.commit("showNotification", false);
        await this.$store.dispatch("updateSyncTimer", { state: "start" })

        await this.updateTestData()
        this.intervalId = setInterval(() => {
            this.timer = this.timer - 1
        }, 1000);
    },
    computed: {
        ...mapState(["selectedChallenge", "ablyRealTime", "ablyParticipants", "startTestInfo", "acceptChallengeInfo"]),
        quizTemplate: {
            get() {
                return this.acceptChallengeInfo.quizTemplate
            }
        },
    },

    watch: {
        timer(val) {
            if (val == 0) {
                clearInterval(this.intervalId)
                this.$router.replace('/challenge/challengequestionpage')
            }

        },
    },

    methods: {
        async updateTestData() {


            this.$store.commit('updateCurrentTestState', {
                sectionIndex: 0,
                qIndex: 0
            })



        }
    }
}
</script>
          
<style scoped>
.testCard {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background: rgb(var(--v-theme-cardBackgroundColor));
    cursor: pointer;

}

.roomCodeCard {
    border-radius: 12px;
    background-color: rgb(var(--v-theme-navColor));
    color: black;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.layoutLarge {
    height: calc(100% - 100px);
}
</style>
          