<template>
  <v-row v-if="dataLoaded" class="ma-0" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end" align="center">
    <v-col cols="auto" class="pa-0">
      <SelfHelpGuideTogglerComponent class="mt-6 mr-8"/>
    </v-col>
  </v-row>

  <v-layout
    class="mb-0 pb-0"
    v-if="dataLoaded"
    :class="{ layoutLarge: $vuetify.display.mdAndUp }"
  >
    <v-container fluid class="h-100 mt-3 mt-md-0 mb-0 pb-0"
    :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''"
    >
      <v-row align="center" justify="start" dense>
        <v-col cols="auto">
          <span class="t-h125 text-center mb-1 text-primaryTextColor">Adaptive Practice Tests</span>
        </v-col>
      </v-row>
      <v-row align="center" justify="start" class="mt-2 mb-7">
        <v-col class="py-0">
          <span class="t-1rem test-subtitle">Dive into the Ultimate Test Experience with AI-Predicted SAT Scores</span>
        </v-col>
      </v-row>
      <v-card elevation="0" class="my-2 pa-0 testCard" v-for="(test, i) in testList" :key="test.testId" @click="
       getCheck(test, 'showSolution', true)? (test.showResult? viewResults(test): test.alreadyInProgress? resumeTest(test) : startTest(test)) : null" :ref="`PracticeTestPage--testCard${i}`">
        <v-card-text class="pa-3">
          <v-row
            class="ma-0"
            :align="$vuetify.display.xs ? 'start' : 'center'"
            justify="start"
            dense
            :class="{ 'pt-1': $vuetify.display.xs }"
          >
            <v-col cols="2" sm="1">
              <v-avatar :size="46" class="text-center">
                <v-img
                  :height="46"
                  src="@/assets/test_practice.png"
                />
              </v-avatar>
            </v-col>
            <v-col class="pl-3" :class="{ 'pl-4': $vuetify.display.xs }" cols="10" sm="11">
              <v-row dense align="center" class="pa-0">
                <v-col :cols="$vuetify.display.xs && !test.isAccessAllowed?'10':'12'" sm="8" md="6">
                  <v-row dense align="center" justify="center">
                    <v-col cols="12" class="pa-0 mt-1 d-flex align-center" dense>
                      <span class="t-1remReg text-white mr-2">{{test.testName}}</span>
                      <v-icon v-if="!$vuetify.display.xs && !test.isAccessAllowed" size="25" color="primaryPurple">mdi-lock-outline</v-icon>
                    </v-col>
                    <v-col v-if="test.noOfCompletedInstances>0" cols="12" class="pa-0 pt-1 text-grey">
                      <span class="mdi mdi-check-circle"></span>
                      <span class="t-para">{{` ${test.noOfCompletedInstances??0} Completed`}}</span>
                    </v-col>
                    <!-- <v-col  cols="1" align-self="end">
                      <v-icon size="25" color="primaryPurple">mdi-lock-outline</v-icon>
                    </v-col> -->
                  </v-row>
                  
                </v-col>
                <v-col v-if="$vuetify.display.xs && !test.isAccessAllowed" align-self='center' cols="2">
                  <v-icon :end="true"
                  size="25" color="primaryPurple" >mdi-lock-outline</v-icon>
                </v-col>
                <v-col cols="12" sm="4" md="6">
                  <v-row
                    v-if="!$vuetify.display.xs"
                    align="center"
                    justify-sm="end"
                    justify="start"
                    class="pa-0"
                    dense
                  >
                    <v-col cols="auto">
                      <v-tooltip
                        v-if="!getCheck(test, 'showSolution', true) && test.showResult"
                        location="top"
                        :open-on-click="true"
                      >
                        <template #activator="{ props }">
                          <v-btn
                            prepend-icon="mdi-lock-outline"
                            class="t-btn text-white result-btn text-none px-3"
                            density="default"
                            variant="flat"
                            v-bind="props"
                            >
                            <template v-slot:prepend>
                              <v-icon color="primaryPurple"></v-icon>
                            </template>
                            View Results
                          </v-btn>
                        </template>
                        <span>Please request your teacher to unlock this result</span>
                      </v-tooltip>
                      <v-btn
                        v-else-if="test.showResult"
                        prepend-icon="mdi-chart-pie"
                        class="t-btn text-white result-btn text-none px-3"
                        density="default"
                        variant="flat"
                        @click.stop="viewResults(test)"
                        >
                        <template v-slot:prepend>
                          <v-icon color="primaryPurple"></v-icon>
                        </template>
                        View Results
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        v-if="test.alreadyInProgress"
                        append-icon="mdi-play"
                        class="t-btn text-white not-activity-btn text-none px-3"
                        density="default"
                        variant="flat"
                        @click.stop="resumeTest(test)"
                        >
                        <template v-slot:append>
                          <v-icon color="primaryPurple"></v-icon>
                        </template>
                        Resume Test
                      </v-btn>
                      <v-btn
                        v-else-if="(test.noOfCompletedInstances + test.noOfInProgressInstances)<=0"
                        append-icon="mdi-play"
                        class="t-btn text-white activity-btn text-none px-3"
                        density="default"
                        variant="flat"
                        @click.stop="startTest(test)"
                        >
                        <template v-slot:append>
                          <v-icon  color="white"></v-icon>
                        </template>
                        Start Test
                      </v-btn>
                      <v-btn
                        v-if="test.showRetest"
                        append-icon="mdi-play"
                        prepend-icon="mdi-replay"
                        class="t-btn text-white not-activity-btn text-none px-3"
                        density="default"
                        variant="flat"
                        @click.stop="startTest(test, true)"
                        >
                        <template v-slot:append>
                          <v-icon color="primaryPurple"></v-icon>
                        </template>
                        <template v-slot:prepend>
                          <v-icon color="primaryPurple"></v-icon>
                        </template>
                        Retest
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- <v-row dense v-else-if="!$vuetify.display.xs && !test.isAccessAllowed" justify="end">
                    <v-col cols="auto">
                      <v-icon size="25" color="primaryPurple">mdi-lock-outline</v-icon>
                    </v-col>    
                  </v-row>  -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="$vuetify.display.xs"
            align="center"
            justify="end"
            class="pa-0"
            dense
          >
            <v-col cols="12" v-if="(test.noOfCompletedInstances + test.noOfInProgressInstances)<=0">
              <v-btn
                append-icon="mdi-play"
                class="t-btn text-white w-100 activity-btn text-none px-3"
                density="default"
                variant="flat"
                @click.stop="startTest(test)"
                >
                <template v-slot:append>
                  <v-icon  color="white"></v-icon>
                </template>
                Start Test
              </v-btn>
            </v-col>
            <v-col cols="6" 
                v-if="test.showResult && !getCheck(test, 'showSolution', true)" >
              <v-tooltip
                location="top"
                :open-on-click="true"
              >
                <template #activator="{ props }">
                  <v-btn
                    prepend-icon="mdi-lock-outline"
                    class="t-btn text-white result-btn text-none px-3 w-100"
                    density="default"
                    variant="flat"
                    v-bind="props"
                    >
                    <template v-slot:prepend>
                      <v-icon color="primaryPurple"></v-icon>
                    </template>
                    View Results
                  </v-btn>
                </template>
                <span>Please request your teacher to unlock this result</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6" 
                v-else-if="test.showResult" >
              <v-btn
                prepend-icon="mdi-chart-pie"
                class="t-btn text-white w-100 result-btn text-none px-3"
                density="default"
                variant="flat"
                @click.stop="viewResults(test)"
                >
                <template v-slot:prepend>
                  <v-icon color="primaryPurple"></v-icon>
                </template>
                View Results
              </v-btn>
            </v-col>
            <v-col :cols="test.showResult?6:12" 
                v-if="(test.noOfCompletedInstances + test.noOfInProgressInstances)>0">
                <v-btn
                  v-if="test.alreadyInProgress"
                  append-icon="mdi-play"
                  class="t-btn text-white w-100 not-activity-btn text-none px-3"
                  density="default"
                  variant="flat"
                  @click.stop="resumeTest(test)"
                  >
                  <template v-slot:append>
                    <v-icon color="primaryPurple"></v-icon>
                  </template>
                  Resume Test
                </v-btn>
              <v-btn
                v-if="test.showRetest"
                append-icon="mdi-play"
                prepend-icon="mdi-replay"
                class="t-btn text-white w-100 not-activity-btn text-none px-3"
                density="default"
                variant="flat"
                @click.stop="startTest(test, true)"
                >
                <template v-slot:append>
                  <v-icon color="primaryPurple"></v-icon>
                </template>
                <template v-slot:prepend>
                  <v-icon color="primaryPurple"></v-icon>
                </template>
                Retest
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row v-if="testList.length==0" align="center" justify="start" class="mt-5 mb-7" ref="PracticeTestPage--testCard0">
        <v-col class="py-0">
          <span class="t-1rem test-subtitle">Your teacher hasn't enabled practice tests for you yet !</span>
        </v-col>
      </v-row>

    </v-container>
  </v-layout>
</template>

<script>
import { useDisplay } from "vuetify";
import _ from "lodash";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { mapGetters } from "vuex";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";

export default {
  name: "TestModulePage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    currentScreenName: 'PracticeTestPage',
    breadCrumbs: [],
    drawer: false,
    dataLoaded: false,
    aiBar: false,
    dummyTestList: [
      {
        "testId":"1",
        "testName":"test 1",
        "subjects":[],
        "order":2,
        "questionsCount":100,
        "testDurationInMinutes":180,
        "startTimeStamp":67676767,
        "endTimeStamp":878787,     
        "status":"",    // inprogress/completed/new
        "syllabus":"",
        "instructions":"",
        "firstModuleId":"1",
        "locked":true
      },
      {
        "testId":"2",
        "testName":"test 2",
        "subjects":[],
        "order":1,
        "questionsCount":100,
        "testDurationInMinutes":180,
        "startTimeStamp":67676767,
        "endTimeStamp":878787,     
        "status":"",    // inprogress/completed/new
        "syllabus":"",
        "instructions":"",
        "firstModuleId":"2",
        "locked":true
      },
    ]
  }),
  components: { SelfHelpGuideTogglerComponent },
  async created() {
    this.$store.commit('appbarVisible', true);
    this.$store.commit('navbarVisible', true);
    this.$store.commit("updateNavBarItems", {data : "/mocktest", show: false})

    this.$store.commit("testsLoadedFromServer", false)
    await this.$store.dispatch("getAllTests")
    recordAnalyticsEvent(AnalyticsEvents.v2MockTestScreenOpen, {});
    this.creatNodes();
    this.dataLoaded = true;
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  computed: {
    ...mapGetters(['currentHighlightedElements']),
    testList: {
      get(){
        return _.orderBy(this.$store.state.tests, ['order'], 'asc');
      }
    }
  },

  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    creatNodes() {
      this.breadCrumbs = [];

      this.breadCrumbs.push({
        title: "Practice Test",
        disabled: false,
        parentNodeIdKeyinChildNodesMap: "mocktest",
        pageRoute: `/mocktest`,
      });
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    startTest(item, reTest = false) {
      if(item.isAccessAllowed){
        if(reTest){
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReTestButtonClicked, { testId: item.testId});
        }else{
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestStartTestButtonClicked, { testId: item.testId});
        }
        let body = _.cloneDeep(item)
        body['moduleId'] = item.firstModuleId;
        body['testInstanceId']="";
        this.$store.commit("updateSelectedTest", body);
        this.$router.push(`/startmocktest/${item.testId}`);
      }else{
        //Add pinpoint
        recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogOpenForMocktest, {
          testId: item.testId,
        });
        this.$store.commit('openUpgradeDialog', true);
      }
    },
    resumeTest(item){
      if(item.isAccessAllowed){
        let body = _.cloneDeep(item);
        body['moduleId'] = item.inProgessModuleId;
        this.$store.commit("updateSelectedTest", body);
        this.$router.push(`/startmocktest/${item.testId}`);
      }else{       
        this.$store.commit('openUpgradeDialog', true);
      }
    },
    viewResults(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestViewReportButtonClicked, { testId: item.testId});
      this.$router.push(`${this.$route.fullPath}/digisatresult/${item.testId}`);
    },
    openUpgradePopup(){
      this.$store.commit('openUpgradeDialog', true);
    },
    getCheck(mainObject, value, defaultValue){
      return _.get(mainObject, value, defaultValue);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-lg {
  padding-top: 50px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.layoutLarge {
  height: 100%;
}

.test-subtitle {
  color: #8e8ea7;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.not-activity-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.result-btn{
  border: 2px solid rgba(255, 255, 255, 0.22);
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}
</style>

<style></style>
