<template>
    <v-row class="ma-0 pt-4 px-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }">
        <v-col cols="12" class="ma-0 pa-0">

            <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
                <template v-slot:prepend>
                    <v-btn v-if="breadCrumbs.length > 1" color="breadCrumbBackColor" class="ma-0 mr-2 text-primaryTextColor"
                        size="x-small" @click="back()" icon="mdi-chevron-left">
                    </v-btn>
                    <v-icon icon="mdi-trophy-outline" color="primaryPurple"></v-icon>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

    </v-row>

    <v-layout class="mb-0 pb-0" v-if="dataLoaded" :class="{ 'layoutLarge': $vuetify.display.mdAndUp }">
        <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

            <v-row class="mt-0 pt-0">

                <v-col class=" mt-0 pt-0 justify-center align-center text-center"
                    :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }">
                    <v-container fluid="" class="px-2 mt-0 pt-0">
                        <v-row justify="center" class="mb-0" align="center">
                            <v-col cols="12 mb-5">
                                <v-row class="t-h125 text-center mb-1 text-primaryTextColor" justify="center">
                                    Choose challenge type


                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row justify="center">
                                    <v-col v-for="(item, i) in userChallenges" :key="i" cols="12" sm="10" md="5" lg="4"
                                        xl="3">

                                        <v-hover v-slot="{ isHovering, props }">

                                            <v-sheet elevation=0 :class="{ 'on-hover': isHovering }"
                                                @click="selectChallenge(item)" class=" py-1 testCard fill-height py-lg-9 py-xl-12"
                                                v-bind="props">
                                                <v-card-text>
                                                    <v-row justify="start" justify-md="center" class="small-card"
                                                        align="center">
                                                        <v-col cols="auto" md="12"   class="small-cardCol">
                                                            <v-row align="center"  justify="start" justify-md="center" class="imgH pl-8 pl-md-0 small-cardCol">
                                                                <v-avatar :size="icons[i % 2].size">
                                                                <v-img
                                                                    :src="require(`@/assets/${icons[i % 2].main}`)"></v-img>
                                                            </v-avatar>
                                                            </v-row>
                                                            
                                                        </v-col>
                                                        <v-col cols="auto" md="10" class="ml-1 ml-md-0">
                                                            <v-row justify="start" justify-md="center" align="center"
                                                                class="small-card">
                                                                <span class="t-h6 text-center text-primaryTextColor">
                                                                    {{ item.templateName }}
                                                                </span>
                                                            </v-row>

                                                        </v-col>
                                                    </v-row>



                                                    <v-row justify="start" justify-md="center"
                                                        class="mt-5  small-card align-center text-primaryTextColor"
                                                        style="margin-left: 1px;" align="center">
                                                        <v-col cols="auto">
                                                            <v-avatar size="x-small" rounded="0" class="mr-1">
                                                                <v-img
                                                                    :src="require(`@/assets/${icons[i % 2].sub1}`)"></v-img>
                                                            </v-avatar>
                                                            <span>
                                                                {{ item.durationInMinutes }} mins
                                                            </span>


                                                        </v-col>
                                                        <v-col offset="1" cols="auto" class="">
                                                            <v-avatar size="x-small" rounded="0" class="mr-2">
                                                                <v-img
                                                                    :src="require(`@/assets/${icons[i % 2].sub2}`)"></v-img>
                                                            </v-avatar>
                                                            <span>
                                                                {{ item.mediumQuestions + item.hardQuestions +
                                                                    item.easyQuestions }}
                                                                questions
                                                            </span>

                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>

                                            </v-sheet>
                                        </v-hover>
                                    </v-col>

                                </v-row>

                            </v-col>
                        </v-row>

                    </v-container>
                </v-col>

            </v-row>
        </v-container>
    </v-layout>
</template>
          
<script>
import { mapState } from "vuex";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";



export default {
    name: 'ChallengeTemplates',
    data: () => ({
        dataLoaded: false,
        currentChallengeId: '',
        breadCrumbs: [{
            title: "Challenge",
            disabled: false,
            parentNodeIdKeyinChildNodesMap: "challenge",
            pageRoute: `/challenge`

        },
        {
            title: "Challenge Types",
            disabled: false,
            parentNodeIdKeyinChildNodesMap: "Select Challenge",
            pageRoute: `/challenge/challenges`

        }
        ],
        icons: [
            {
                main: 'challenge_templates/rookie.png',
                sub1: 'challenge_templates/clock.svg',
                sub2: 'challenge_templates/q.svg',
                size:40
            },
            {
                main: 'challenge_templates/legend.png',
                sub1: 'challenge_templates/clock_green.svg',
                sub2: 'challenge_templates/q_green.svg',
                size : 60
            },],
        colors: [
            '#BFE0FF',
            '#F9E6CF'
        ]

    }),
    components:
    {


    },
    async created() {
        recordAnalyticsEvent(AnalyticsEvents.v2ChallengeTypeScreenOpen, {
        });
        this.createNodes()
        this.$store.commit("challengesLoadedFromServer", false);

        await this.$store.dispatch("loadChallenges", null)
        this.currentChallengeId = this.challenges.challengeId
        this.dataLoaded = true
    },
    computed: {
        ...mapState(["challenges"]),
        userChallenges: {
            get() {
                if ("challengeTemplates" in this.challenges) {
                    return this.challenges.challengeTemplates
                }
                else {
                    return []
                }
            }
        },
        displayBreadCrumbs: {
            get() {
                return [this.breadCrumbs[this.breadCrumbs.length - 1]]
            }
        }

    },

    watch: {

    },

    methods: {
        selectChallenge(item) {
            let quizTemplateId = item.quizTemplateId
            recordAnalyticsEvent(AnalyticsEvents.v2ChallengeTypeScreenSelectChallenge, {
                challengeId : this.challengeId,
                quizTemplateId : quizTemplateId
        });
            this.$router.push(`/challenge/hostwaitingroom/${this.currentChallengeId}/${quizTemplateId}`)
        },
        getIcons(index) {
            return this.icons[index]
        },
        getColors(index) {
            return this.colors[index]
        },
        createNodes() {

        },
        back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
            this.$router.replace(page)
        }
    }
}
</script>
          
          <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.testCard {
    border-radius: 12px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background: rgb(var(--v-theme-cardBackgroundColor));

    cursor: pointer;
}

.heightBread {
    height: 80px;
}
.imgH{
    min-height: 90px !important;
}
.start-bg {
    background: #BFE0FF;

}

.layoutLarge {
    height: calc(100% - 160px);
}

.v-sheet.on-hover {
    /* border: 1px solid rgb(var(--v-theme-primary)); */
    /* border-color: transparent;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}

.small-card {}

@media only screen and (max-width: 335px) {
    .small-card {
        text-align: center !important;
        justify-content: center !important;
    }
    .small-cardCol {
        /* columns: 1 !important; */
        width: 100% !important;
        justify-content: center !important;

        text-align: center !important;

    }
}
</style>
          