<template>
    <v-dialog
    style="backdrop-filter: blur(12.5px)"
    persistent
    :model-value="dialogCancel"
    max-width="400px"
  >
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 600 : '95%'">
      <v-card-text class="text-white pa-0 ma-0"

        >
        <div class="assignment-form-title">
          We’re sorry to see you considering cancellation. Remember, with your <strong>{{ packageDetail.name }}</strong> subscription, you benefit from comprehensive support to excel in your SAT prep.
        </div>
        <div class="assignment-form-title mt-3">
          If you cancel now, you can continue using <strong>{{ packageDetail.name }}</strong> until your subscription expire on <strong>{{displayLocalDate(packageDetail.packageEndDate) }}</strong> with no further charges to your account.
        </div>
        </v-card-text
      >
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="closeCancelConfirmation()"
          >Keep My Subscription</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="cancelSubscription()"
          >Confirm Cancellation</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-layout class="mb-0 pb-0" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
    <v-container v-if="showData" fluid class="h-100 mt-3 mt-md-0 mb-0 pb-0"
      :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
      <v-row align="center" justify="start" dense>
        <v-col cols="auto">
          <span class="t-h125 text-center mb-1 text-primaryTextColor">My Profile</span>
        </v-col>
      </v-row>
      <v-row align="center" justify="start" class="mt-2 mb-7">
        <!-- <v-col class="py-0">
          <span class="t-1rem test-subtitle">You Change Profile, Payment and Billing Details</span>
        </v-col> -->
      </v-row>

      <v-card elevation="0" class="my-2 pa-0 testCard pa-1">
        <v-card-title>
          <span class="profileCardTitle t-h6Bold">
            Profile Details
          </span>
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/profile_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Name</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ this.name }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              >
                Update
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/phone_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Phone Number</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ this.phone }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              >
                Update
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/email_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Email Address</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ this.email }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              >
                Update
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="customerDetails && !isUserParent" elevation="0" class="my-2 pa-0 testCard pa-1">
        <v-card-title>
          <span class="profileCardTitle t-h6Bold">
            Tutor Organization Details
          </span>
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/profile_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Name</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ customerDetails.name ? customerDetails.name :
                    '-' }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              >
                Update
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/phone_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Phone Number</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ customerDetails.phone ? customerDetails.phone
                    :
                    '-' }}</span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              >
                Update
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/email_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Email Address</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ customerDetails.email ?
                    customerDetails.email : '-' }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="packageDetails.length>0 && !isUserParent" elevation="0" class="my-2 pa-0  testCard pa-1 mb-6">
        <v-card-title>
          <span class="profileCardTitle t-h6Bold">
            License Details
          </span>
        </v-card-title>
        <v-card-text v-for="packageDetail, index in packageDetails" :key="packageDetail.packageId">
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/profile_icon.svg')" />
            </v-col>
            <v-col cols="8">
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">License Name</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ packageDetail.name }}</span>
                </v-col>
                <v-col cols="12" class="pa-0"  v-if="!packageDetail.isActive || packageDetail.status != 'active'">
                  <span class="t-para text-red">Subscription {{ packageDetail.status == 'usercancelled' && packageDetail.isActive ? 'cancelled' : 'expired' }} </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" class="justify-end d-flex" v-if="packageDetail.isActive && packageDetail.status == 'active' && packageDetail?.subscriptionType == 'recurring'">
              <v-btn
              v-if="packageDetail && packageDetail?.subscriptionType == 'recurring'"
              class="t-btn delete-btn not-activity-btn text-none text-primaryTextColor "
              density="default"
              variant="flat"
              @click.stop="openCancelConfirmation(packageDetail)"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="3" class="justify-end d-flex" v-if="showUserUpgradeButton">
              <!-- <v-btn
              class="t-btn text-white activity-btn text-none"
              density="default"
              variant="flat"
              @click="renewSubscription(packageDetail)"
              >
                Renew
              </v-btn> -->
              <v-btn
              class="t-btn text-white activity-btn text-none"
              density="default"
              variant="flat"
              @click="openUpgradePopup"
              >
                Upgrade
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" :src="getIconSrc('assets/phone_icon.svg')" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">End Date</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{
                    displayLocalDate(packageDetail.packageEndDate) }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider v-if="index!=(packageDetails.length-1)" class="mt-2" :thickness="2" color="background"></v-divider>
        </v-card-text>
      </v-card>



      <!--  <v-card elevation="0" class=" mt-5 mb-2 pa-0 testCard pa-1">
        <v-card-title>
          <span class="profileCardTitle t-h6Bold">
            Subscription Details
          </span>
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" src="@/assets/upgrade_plan.png" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">{{ userPlan.name }}</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">{{userPlan.active? 'Subscribed': ''}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" v-if="!userPlan.active">
              <v-btn
              class="t-btn text-white activity-btn text-none"
              density="default"
              variant="flat"
              @click="openUpgradePopup"
              >
                Upgrade
              </v-btn>
            </v-col>
          </v-row>
         <v-divider class="my-4" color="grey"></v-divider>
          <v-row align="center">
            <v-col cols="auto">
              <v-img height="50" src="@/assets/add_on.png" />
            </v-col>
            <v-col>
              <v-row dense>
                <v-col cols="12" class="pa-0">
                  <span class="t-1remReg font-weight-medium text-white">0 Tokens Left</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <span class="t-h6Reg text-field-name text-white">Not Purchased</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" >
              <v-btn
              class="t-btn text-white not-activity-btn text-none"
              density="default"
              variant="flat"
              
              >
              Purchased
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>  -->

    </v-container>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import _ from "lodash";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import moment from "moment-timezone";
export default {
  name: 'MyAccount',
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data() {
    return {
      showData: false,
      packageDetail: {},
      dialogCancel: false
    };
  },
  created() {
    recordAnalyticsEvent(AnalyticsEvents.v2ProfileScreenOpen);
    this.$store.dispatch("getProfileDetails", {}).then(() => {
      this.showData = true;
    });
  },
  methods: {
    openUpgradePopup(){
      recordAnalyticsEvent(AnalyticsEvents.v2ProfileScreenPackageUpgradeButtonClicked);
      this.$store.commit('openUpgradeDialog', true);
    },
    getIconSrc(url) {
      return require(`@/${url}`);
    },
    displayLocalDate(date) {
      if (date == null || date.length == 0) {
        return "";
      }
      date = date * 1000;
      let displayDate = moment(date).format("YYYY-MM-DD");
      return moment(displayDate).format("ll");
    },
    openCancelConfirmation(packageDetail){
      this.dialogCancel = true;
      this.packageDetail = _.cloneDeep(packageDetail)
    },
    closeCancelConfirmation(){
      this.dialogCancel = false;
      this.packageDetail = {};
    },
    cancelSubscription(){
      recordAnalyticsEvent(AnalyticsEvents.v2ProfileScreenCancelButtonClicked, {
        packageId: this.packageDetail.packageId,
      });
      const self = this;
      this.$store.dispatch("cancelSubscription", {
        packageId: this.packageDetail.packageId
      }).then((res) => {
        if(res.success){
          self.closeCancelConfirmation();
          
          this.$store.dispatch("getProfileDetails", {});
        }
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async renewSubscription(packageDetail){
      recordAnalyticsEvent(AnalyticsEvents.v2ProfileScreenRenewButtonClicked, {
        packageId: packageDetail.packageId,
      });
      const packageIds = [
        packageDetail.packageId
      ];
      const response = await this.$store.dispatch("createCheckoutSession", { "packages": packageIds, "route": this.$route.path })
      if (response["url"]) {
        window.open(response["url"], "_self");
      }
    }
  },
  computed: {
    ...mapState(["profileDetails", "pricingModels", "isUserTeacher", "isUserAdmin", "config", "isUserParent"]),
    name: {
      get() {
        return _.isEmpty(this.profileDetails.name) ? "-" : this.profileDetails.name;
      }
    },
    email: {
      get() {
        return _.isEmpty(this.profileDetails.email) ? "-" : this.profileDetails.email;
      }
    },
    phone: {
      get() {
        return _.isEmpty(this.profileDetails.phone) ? "-" : this.profileDetails.phone;
      }
    },
    customerDetails: {
      get() {
        return _.isEmpty(this.profileDetails.customerDetails) ? null : this.profileDetails.customerDetails;
      }
    },
    packageDetails: {
      get() {
        const activePackageDetails = [];
        for(let packageDetail of this.profileDetails.packageDetails){
          if(packageDetail.isActive && (packageDetail.priceType != 'free')){
            activePackageDetails.push(packageDetail);
          }
        }
        return _.isEmpty(activePackageDetails) ? this.profileDetails.packageDetails: activePackageDetails;
      }
    },
    showUserUpgradeButton: {
      get(){
        let showUpgradeButton = true;
        for(let packageDetail of this.profileDetails.packageDetails){
          showUpgradeButton = showUpgradeButton && (!packageDetail.isActive || (packageDetail.priceType == 'free'));
        }
        return showUpgradeButton;
      }
    }
    // userPlan: {
    //   get(){
    //     let planDetails = _.get(this.profileDetails, "details", [])
    //     let activePlan = {};
    //     if(planDetails.length>0){
    //       activePlan = {
    //           name: planDetails[0].packageDetail.name ?? '',
    //           active: true

    //         }
    //     }else{
    //       let data = this.pricingModels.items ?? []
    //       data = data.filter((e) => e.priceType == 'free')
    //       if(data.length > 0){
    //         activePlan = {
    //           name: data[0].name ?? '',
    //           active: false

    //         }
    //       }

    //     }

    //     return activePlan;
    //   }
    // },
  },
};
</script>

<style scoped>
.main-lg {
  padding-top: 50px;
  padding-left: 20%;
  padding-right: 20%;
}

.main-md {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.profileCardTitle {
  color: rgb(var(--v-theme-report-extraText));
  text-transform: uppercase;
}

.layoutLarge {
  height: 100%;
}

.text-field-name {
  opacity: 0.7;
}

.test-subtitle {
  color: #8e8ea7;
}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 10px;
  width: 100px;
}

.not-activity-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 10px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  width: 100px;
}

.delete-btn {
  border: 2px solid red !important;
}

.assignment-form {
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.22);
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  /* width: 100px; */
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  /* width: 100px; */
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

/* .teacher .v-list-item__spacer{
  display: none !important;
} */
</style>