import localForage from "localforage";
import {
	AnalyticsEvents,
	recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import store from "../store";


localForage.config({
    driver: [ localForage.INDEXEDDB,localForage.LOCALSTORAGE],
    name: 'VMC_sw',
    storeName: 'test_attempt_details',
});

let storageInUse = 'localforage';

let memoryStorage = {};


export async function setLocalForageItem(key, val) {
    try {
        if (storageInUse === 'localforage') {
            await localForage.setItem(key, val);
        } else if (storageInUse === 'localStorage') {

            localStorage.setItem(key, JSON.stringify(val));

        } else {
            memoryStorage[key] = val;
        }
    } catch (err) {
        let stackTrace;

        if (err && err.stack) {
            stackTrace = JSON.stringify(err.stack);
        } else if (err) {
            stackTrace = JSON.stringify(err);
        } else {
            stackTrace = 'No error information available';
        }

        stackTrace = stackTrace.slice(0, 199);
        console.log(`Error with ${storageInUse}:`, err);

        if (storageInUse === 'localforage') {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorSetInLocalForageFailed, {
                message: `Error with ${storageInUse}`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            //console.log("error stringify", JSON.stringify(err.stack));
            storageInUse = 'localStorage';
            return setLocalForageItem(key, val);  

        } else if (storageInUse === 'localStorage') {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorSetInLocalStorageFailed, {
                message: `Error with ${storageInUse}`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            storageInUse = 'memoryStorage';
            return setLocalForageItem(key, val);  
        } else {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorSetInMemoryStorageFailed, {
                message: `Error with ${storageInUse} and all storage methods failed.`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            console.error("All storage methods failed.");
            
        }
    }
}

export async function getLocalForageItem(key, defaultVal = []) {
    try {
        if (storageInUse === 'localforage') {

            const value = await localForage.getItem(key);
            return value;

        } else if (storageInUse === 'localStorage') {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : defaultVal;
        } else {
            return memoryStorage[key] || defaultVal;
        }
    } catch (err) {
        let stackTrace;

        if (err && err.stack) {
            stackTrace = JSON.stringify(err.stack);
        } else if (err) {
            stackTrace = JSON.stringify(err);
        } else {
            stackTrace = 'No error information available';
        }

        stackTrace = stackTrace.slice(0, 199);

        console.log(`Error with ${storageInUse}:`, err.stack);
        if (storageInUse === 'localforage') {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorGetInLocalForageFailed, {
                message: `Error with ${storageInUse}`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            storageInUse = 'localStorage';
            return getLocalForageItem(key);  
        } else if (storageInUse === 'localStorage') {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorGetInLocalStorageFailed, {
                message: `Error with ${storageInUse}`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            storageInUse = 'memoryStorage';
            return getLocalForageItem(key);  
        } else {
            recordAnalyticsEvent(AnalyticsEvents.v2ErrorGetInMemoryStorageFailed, {
                message: `Error with ${storageInUse} and all storage methods failed.`,
                error: stackTrace,
            });
            store.dispatch("recordError", {Error: JSON.stringify(err.stack), API: storageInUse});
            console.error("All storage methods failed.");
            return defaultVal;
        }
    }
}