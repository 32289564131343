<template>
    <div class="topPosition">
      <!-- Video Snackbar -->
      <!-- <v-snackbar :model-value="showVideoSnackbar" timeout="2200">
        <span class="text-center">All Videos have been uploaded</span>
        <template v-slot:actions>
          <v-btn color="pink" @click="closeSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar> -->

      <!-- Pdf Snackbar -->
      <v-snackbar :model-value="showPdfSnackbar" timeout="2200" color="cardBackgroundColor" content-class="tab-2">
        <span class="text-center">All Files have been uploaded</span>
        <template v-slot:actions>
          <v-btn color="pink" text @click="closePDFSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!-- Zip Snackbar -->
      <!-- <v-snackbar :model-value="showZipSnackbar" timeout="2200">
        <span class="text-center">Zip file has been uploaded</span>
        <template v-slot:actions>
          <v-btn color="pink" @click="closeZipSnackbar">
            Close
          </v-btn>
        </template>
      </v-snackbar> -->

      <!-- <v-snackbar v-model="snackbar">
        <span>Internet not connected, video uploading is paused</span>
        <template v-slot:actions>
          <v-btn color="pink" text @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar> -->

      <!-- Video Progress -->
      <!-- <v-card
        v-if="showVideoProgress"
        class="progress"
        :class="{
          scroll: !showThumbnails
        }"
      >
        <v-expansion-panels v-model="collapsed">
          <v-expansion-panel>
            <v-expansion-panel-title color="blue lighten-1" class="px-3"
              >Uploading {{ uploadingVideos.length===1? 'video': `${uploadingVideos.length} videos` }}
  
              <template v-slot:actions>
                <v-icon v-if="collapsed == 0"> mdi-minus </v-icon>
                <v-icon v-else> mdi-plus </v-icon>
              </template>
              <v-spacer></v-spacer>
  
              <v-btn
                v-if="showVideoProgress && !showThumbnails"
                @click.stop="cancelVideoUploading"
                icon
                x-small
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </v-expansion-panel-title>
            <v-expansion-panel-text id="innerExPan">
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-if="showThumbnails">
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                    >
                      <v-col class="text-subtitle-1 text-center" cols="12">
                        Generating Thumbnails
                      </v-col>
                      <v-col cols="10">
                        <v-progress-linear
                          color="deep-purple accent-4"
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-list-item>
  
                  <div v-if="!showThumbnails">
                    <div
                      v-for="(item, index) in uploadingVideos"
                      :key="item.fileName"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.fileName"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-progress-circular
                            :rotate="180"
                            :size="50"
                            :width="5"
                            color="blue lighten-1"
                            :model-value="item.videoUploadingProgress"
                          >
                            {{ Math.floor(item.videoUploadingProgress) }}
                          </v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index != uploadingVideos.length - 1">
                      </v-divider>
                    </div>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->

      <!-- Pdf Progress -->
      <v-card v-if="showPdfProgress" class="progress">
        <v-card-text class="pa-0 progress-expansion">
          <v-expansion-panels v-model="collapsed" tile>
          <v-expansion-panel tile>
            <v-expansion-panel-title color="cardBackgroundColor" 
              >
              <span class="px-3">Uploading {{ uploadingPdfs.length===1? 'PDF': `${uploadingPdfs.length} PDFs` }}</span>
  
              <template v-slot:actions >
                <v-icon v-if="collapsed == 0" class="mr-2"> mdi-minus </v-icon>
                <v-icon v-else class="mr-2"> mdi-plus </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text id="innerExPan">
              <v-list class="ma-0 pa-0">
                    <v-list-item
                      v-for="(item, index) in uploadingPdfs"
                      :key="index"
                      class="ma-0 pa-0"
                      >
                        <v-list-item-title
                          v-text="item.fileName"
                        ></v-list-item-title>
                        <v-list-item-action>
                          <v-progress-circular
                            :rotate="180"
                            :size="50"
                            :width="5"
                            color="primaryPurple"
                            :model-value="item.pdfUploadingProgress"
                          >
                            {{ Math.floor(item.pdfUploadingProgress) }}
                          </v-progress-circular>
                        </v-list-item-action>
                         <v-divider class="my-1" v-if="index != uploadingPdfs.length - 1">
                      </v-divider>
                      </v-list-item>
                     

              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-card-text>
        
      </v-card>

      <!-- Zip Progress -->
      <!-- <v-card v-if="showZipProgress" class="progress">
        <v-expansion-panels v-model="collapsed">
          <v-expansion-panel>
            <v-expansion-panel-title color="blue lighten-1" class="px-3"
              >Uploading {{ uploadingPdfs.length===1? 'Zip file': `${uploadingPdfs.length} Zip files` }}
  
              <template v-slot:actions>
                <v-icon v-if="collapsed == 0"> mdi-minus </v-icon>
                <v-icon v-else> mdi-plus </v-icon>
              </template>
              <v-spacer></v-spacer>
  
              <v-btn v-if="showZipProgress" icon x-small>
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </v-expansion-panel-title>
            <v-expansion-panel-text id="innerExPan">
              <v-list dense>
                <v-list-item-group color="primary">
                  <div>
                    <div
                      v-for="(item, index) in uploadingPdfs"
                      :key="item.fileName"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.fileName"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-progress-circular
                            :rotate="180"
                            :size="50"
                            :width="5"
                            color="blue lighten-1"
                            :model-value="item.videoUploadingProgress"
                          >
                            {{ Math.floor(item.pdfUploadingProgress) }}
                          </v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index != uploadingPdfs.length - 1">
                      </v-divider>
                    </div>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->

      <!-- CSV Progress -->
      <!-- <v-card v-if="showCsvProgress" class="progress">
        <v-expansion-panels v-model="collapsed">
          <v-expansion-panel>
            <v-expansion-panel-title color="blue lighten-1" class="px-3"
              >Uploading {{ uploadingPdfs.length===1? 'Csv file': `${uploadingPdfs.length} Csv files` }}
  
              <template v-slot:actions>
                <v-icon v-if="collapsed == 0"> mdi-minus </v-icon>
                <v-icon v-else> mdi-plus </v-icon>
              </template>
              <v-spacer></v-spacer>
  
              <v-btn v-if="showCsvProgress" icon x-small>
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </v-expansion-panel-title>
            <v-expansion-panel-text id="innerExPan">
              <v-list dense>
                <v-list-item-group color="primary">
                  <div>
                    <div
                      v-for="(item, index) in uploadingPdfs"
                      :key="item.fileName"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.fileName"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-progress-circular
                            :rotate="180"
                            :size="50"
                            :width="5"
                            color="blue lighten-1"
                            :model-value="item.videoUploadingProgress"
                          >
                            {{ Math.floor(item.pdfUploadingProgress) }}
                          </v-progress-circular>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index != uploadingPdfs.length - 1">
                      </v-divider>
                    </div>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->
      
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { fileUploadCancel } from "../services/storageService";
  
  export default {
    name: "uploadingComponent",
    data: () => ({
      snackbar: false,
      collapsed: undefined
    }),
    created() {
      // const self = this;
      // addEventListener("offline", function () {
      //   self.$store.state.uploadingVideos.forEach((e) => {
      //       console.log(e.fileName, e.paused)
      //     if (e.paused == false) {
      //       e.uploadVideo.pause();
      //       e.paused = true;
      //       console.log(e.fileName, e.paused)
      //     }
      //   });
      //   self.snackbar = true;
      //   console.log("offline");
      // });
      // window.addEventListener("online", function () {
      //   self.$store.state.uploadingVideos.forEach((e) => {
      //     if (e.paused == true) {
      //       e.uploadVideo.resume();
      //       e.paused = true;
      //     }
      //   });
      //   console.log("online");
      // });
    },
    computed: {
      ...mapState([

        // "showVideoProgress",
        // "uploadingVideos",
        // "showThumbnails",
        // "showVideoSnackbar",

        "showPdfProgress",
        "uploadingPdfs",
        "showPdfSnackbar",

        // "showZipProgress",
        // "showZipSnackbar",
        // "showCsvProgress"
      ])
    },
    methods: {
      // closeSnackbar() {
      //   this.$store.state.showVideoSnackbar = false;
      // },
      closePDFSnackbar() {
        this.$store.commit("showSuccessPdfSnackbar", false);
        // this.$store.state.showPdfSnackbar = false;
      },

      // closeZipSnackbar(){
      //   this.$store.state.showZipSnackbar = false;
      // },
      // cancelVideoUploading() {
      //   this.uploadingVideos.forEach(e => {
      //     if (e.uploadVideo.isInProgress) {
      //       console.log("cancel");
  
      //       fileUploadCancel(e.uploadVideo);
      //       this.$store.commit("clearVideoItems");
      //       this.$store.commit("showVideoProgress", false);
      //     } else {
      //       console.log("delete files");
      //     }
      //   });
      // },

      cancelPdfUploading() {
        this.uploadingPdfs.forEach(e => {
          if (e.uploadPdf.isInProgress) {
            console.log("cancel");
  
            fileUploadCancel(e.uploadPdf);
            this.$store.commit("clearPdfsItems");
            this.$store.commit("showPdfProgress", false);
          } else {
            console.log("delete files");
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .progress-expansion {
    background: rgb(var(--v-theme-cardBackgroundColor)) !important;
    cursor: pointer; 
    border-radius: 4px !important;
    border: 2px solid rgba(255, 255, 255, 0.22) !important;
  }
  .v-expansion-panel-text >>> .v-expansion-panel-text__wrap {
    padding: 0 !important;
  }
  #innerExPan > * {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  .v-expansion-panel-title {
    padding-right: 0px !important;
    padding-left: 0px !important;
    border-radius: 3px !important;
  }
  
  .progress {
    bottom: 20px !important;
    right: 20px !important;
    position: fixed !important;
    width: 25%;
  }
  .scroll {
    overflow-y: auto;
    max-height: 80vh;
  }
  .topPosition {
    z-index: 999;
    position: relative;
  }
  </style>
  