<template>
  <v-app-bar color="breadCrumbBackColor">

    <v-app-bar-title>
      <template v-slot:text>
        <span class>
          {{ startTestInfo.testmetadata.testName }}

        </span>
        <span v-if="currentSection.visibleOnApp">
          | {{ currentSection.sectionName }}
        </span>

      </template>


    </v-app-bar-title>


    <!-- <v-spacer></v-spacer> -->

    <v-icon v-if="!timeOver" class="mr-2" :color="remaningTimeInSeconds < 60 ? '#FF786E' : 'primaryPurple'">
      mdi-clock-outline</v-icon>
    <div v-if="!timeOver" class="t-h6 text-primaryTextColor"
      :class="{ 'redTimer blink': remaningTimeInSeconds < 120, 'mr-5': mdAndUp }">
      {{ getRemainingTime }}
    </div>
    <v-menu :class="mdAndUp ? 'mr-5' : 'mr-2'">
      <template v-slot:activator="{ props }">
        <v-btn color="white" icon="mdi-dots-vertical" v-bind="props">
        </v-btn>
      </template>
      <v-list>

        <v-list-item @click="exitTest()">
          <v-list-item-title>Exit challenge</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>

  <div :class="{ 'bgW': !submitScreen, 'bgP pt-8': submitScreen }">

    <div v-if="dataLoaded" class="h-100">

      <v-dialog v-model="warningDialog" class="dialog-bx" width="auto">

        <v-card>
          <v-card-title class="t-h5 text-first-capitalize">
            {{ this.warningTimeLeft }} mins remaning
            <v-divider class="mt-3">

            </v-divider>
          </v-card-title>
          <v-card-text class="mb-3">Only {{ this.warningTimeLeft }} mins are left to complete the test</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" variant="text" @click="warningDialog = false">

              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <div v-if="submitScreen" class="h-100">

        <v-layout class="mb-0 pb-0" :class="{ 'layoutLarge': $vuetify.display.smAndUp }">
          <v-container fluid class="d-flex h-100 mt-3 mt-md-0 mb-0 pb-0">

            <v-row class="mt-0 pt-0">

              <v-col class=" mt-0 pt-0 justify-center align-center text-center"
                :class="{ 'd-flex flex-column': $vuetify.display.smAndUp }">
                <v-container fluid="" class="px-2 mt-0 pt-0">
                  <v-row justify="center">
                    <v-col cols="12">

                      <v-alert type="warning" title="Please try submitting again" v-if="showSubmitAlert"
                        class="mx-8 mt-4 mb-8 text-start"
                        text="There was an issue, please try submitting again"></v-alert>

                      <div class="mb-8">
                        <v-row class="align-center  mt-4 mb-0 pb-0" justify="center" v-if="submitted">
                          <v-col cols="12" class="t-1rem text-center mb-0  pb-0">
                            <v-icon size="30" color="primaryGreen">
                              mdi-check-decagram
                            </v-icon>
                          </v-col>
                        </v-row>
                        <v-row class="align-center mt-0 pt-0" justify="center" v-if="submitted">
                          <v-col cols="12" class="t-h125 font-weight-bold  text-primaryTextColor text-center mt-0 pt-0">
                            <span>
                              Your challenge is completed !
                            </span>

                          </v-col>
                        </v-row>
                        <!-- <v-row v-if="!timeOver" class="align-center" justify="center">
                        <v-col cols="12" class="text-center t-h5 timer text-primaryGreen">
                          Remaining Time
                        </v-col>
                      </v-row>
                      <v-row v-if="!timeOver" class="align-center mt-0 pt-0" justify="center">
                        <v-col cols="12" class="  text-center t-h6 font-weight-bold timer-color">
                          {{ getRemainingTime }}

                        </v-col>
                      </v-row> -->
                        <v-row class="align-center  my-4" justify="center" v-if="!submitted && timeOver">
                          <v-col cols="12" class="t-h125 font-weight-bold  text-primaryTextColor text-center">
                            <span>
                              Challenge time is over, please submit the Challenge
                            </span>



                          </v-col>
                        </v-row>
                        <v-row class="align-center" justify="center" v-if="!submitted && timeOver">
                          <v-col cols="12" class=" mt-n5 text-center t-h6Reg subColor">
                            <span>
                              Challenge will be auto submitted in {{ submitFormattedTime }}
                            </span>

                          </v-col>
                        </v-row>

                        <v-row justify="center" class="mt-4">

                          <v-col cols="12">
                            <v-row dense justify="center" align="center" class="mb-2">

                              <v-col cols="12" class="t-1rem text-center">
                                <v-avatar size="25" class="ml-2" v-if="$vuetify.display.smAndUp">
                                  <v-img src="@/assets/trophy.png">

                                  </v-img>
                                </v-avatar>
                                <span class="t-1rem text-primaryTextColor ">
                                  Leaderboard
                                </span>


                              </v-col>
                            </v-row>
                            <v-row dense justify="center">
                              <v-col cols="12" sm="9" md="8" lg="6" xl="4">
                                <v-card class="card-bg-color px-4" elevation="0">
                                  <v-card-text class="py-5">
                                    <v-row class="" justify="center">

                                      <v-col cols="12">

                                        <ScoreBar :room-participants="roomParticipants" :total-marks="totalMarks"
                                          iconColor="white">
                                        </ScoreBar>
                                      </v-col>
                                    </v-row>
                                    <!-- <v-row>

                              <v-col cols="auto" class="">
                                <v-avatar size="40" variant="outlined" class="mr-2 correct ">
                                  {{ questionCounts.answered }}
                                </v-avatar><span>
                                  Answered
                                </span>
                              </v-col>
                              <v-col cols="auto" class="">
                                <v-avatar size="40" variant="outlined" class="mr-2 unanswered">
                                  {{ questionCounts.unanswered }}

                                </v-avatar><span>
                                  Not answered
                                </span>
                              </v-col><v-col cols="auto" class="">
                                <v-avatar size="40" variant="outlined" class="mr-2 marked">
                                  {{ questionCounts.marked }}

                                </v-avatar><span>
                                  Marked for review
                                </span>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-divider class="my-5">

                              </v-divider>
                            </v-row>

                            <v-row class="my-4" v-for="item, sIndex in qResponsesState" :key="sIndex">
                              <v-col v-if="item.visibleOnApp" cols="12" class="t-h5Reg">
                                {{ item.sectionName }}
                              </v-col>

                              <v-row>

                                <v-col cols="auto" class="mx-2" v-for="item, qIndex in item.responses" :key="qIndex">

                                  <v-avatar style="cursor: pointer;"
                                    @click="timeOver ? null : openQFromReview(sIndex, qIndex)" size="40"
                                    variant="outlined" :class="{
                                      'correct': checkIfQuestionAnswered(item),
                                      'marked': item.markedForReview,
                                      'unanswered': !checkIfQuestionAnswered(item)
                                    }">
                                    {{ item.qNo }}

                                  </v-avatar>

                                </v-col>
                              </v-row>
                            </v-row> -->

                                  </v-card-text>

                                </v-card>
                              </v-col>
                            </v-row>

                          </v-col>
                        </v-row>

                        <v-row class="align-center mt-8" justify="center" v-if="submitted == false">
                          <v-col cols="auto">
                            <v-btn class=" t-btn text-none btn-grad  text-primaryTextColor"
                              :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                              :width="$vuetify.display.mdAndUp ? '130' : 'auto'" elevation="0" @click="submitTest()"
                              variant="flat">
                              Yes, submit
                            </v-btn>
                          </v-col>
                          <!-- <v-col cols="auto">
              <v-btn @click="backFromReview()" :color="!timeOver ? 'secondaryBackground' : undefined" :disabled="timeOver"
                class="t-btn  btn-rd" width="140" variant="flat">
                No, Wait
              </v-btn>
            </v-col> -->
                        </v-row>
                        <v-row class="align-center  mt-4" justify="center" v-if="submitted">
                          <v-col cols="12" class="t-h5Reg text-center text-primaryTextColor ">
                            <span v-if="submissionStatus == false">
                              <div v-if="startTestInfo.type == 'ai'">
                                Waiting for mento to complete…

                              </div>
                              <div v-else>
                                Waiting for the other player to complete…

                              </div>
                            </span>
                            <span v-if="submissionStatus == true">
                              Redirecting to challenge reports
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>

                </v-container>
              </v-col>

            </v-row>

          </v-container>
        </v-layout>
      </div>


      <div v-else class="">
        <!-- Feedback/report form-->

        <DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false" :test-type="challengeType"
          :q-id="currentQ.qId" :test-id="currentTestId" :test-instance-id="startTestInfo.testInstanceId"
          :q-obj="currentQ"></DragableFeedbackDialog>

        <!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
          <FeedbackComponent @closeDialog="reportDialog = false" :test-type="challengeType" :q-id="currentQ.qId"
            :test-id="currentTestId" :test-instance-id="startTestInfo.testInstanceId" :currentQ="currentQ">
          </FeedbackComponent>
        </v-dialog> -->
        <v-container fluid class="">
          <v-row justify="start" :class="{ 'w-70': $vuetify.display.mdAndUp }">
            <v-col cols="12">
              <v-card elevation="0" color="white" class="pb-14 pt-6" scrollable>


                <section class=" mx-5">
                  <div class="mb-12">
                    <v-row class="align-center">
                      <span class="mx-3 qNoClass font-weight-bold">
                        Question {{ currentQ.qNo }}
                      </span>

                      <div v-if="currentQ.qType == '1' || currentQ.qType == '2'" class="ml-5">
                        <div class="" v-if="currentQResponse.crossedOutOptions">
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn elevation="0" theme="dark" v-bind="props" size="small"
                                @click="hideCrossedOutOptions()" color="primaryPurple"
                                icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                        <div class="" v-else>
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn size="small" v-bind="props" class="outline-color" variant="outlined"
                                @click="showCrossedOutOptions()" icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                      </div>
                      <v-spacer>

                      </v-spacer>
                      <v-tooltip text="Report a problem" location="end">
                        <template v-slot:activator="{ props }">
                          <v-btn size="small" v-bind="props" variant="outlined" @click="showReportDialog()"
                            icon="mdi-message-alert-outline" class="outline-color"></v-btn>
                        </template>
                      </v-tooltip>
                    </v-row>

                  </div>

                  <section>

                    <div v-html="getMarkDownHtml(currentQ.qText)" class="htmlClassTest">
                    </div>

                  </section>

                  <section>
                    <div v-html="getMarkDownHtml(currentQ.qTextForSecondColumn)" class="htmlClassTest mt-1"></div>
                  </section>


                  <div class="mt-15">

                    <!-- case 1 -->
                    <section v-if="currentQ.qType == '1'">
                      <v-radio-group v-model="currentQResponse.checkedOption" @update:modelValue="radioGroupUpdate()">

                        <v-row v-for="option in currentQ.options" :key="option.key" align="center">
                          <v-col cols="">
                            <v-hover v-slot="{ isHovering, props }">

                              <v-sheet class="pa-0  ma-0  bx" :class="{
                                'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': currentQResponse.checkedOption == option.key
                              }" v-bind="props" style="position: relative;">
                                <div class="strike"
                                  v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
                                </div>
                                <v-radio density="compact" color="white"
                                  class="ma-0 pa-0 w-100  custom-radio testOption" dense :value="option.key" :class="{
                                    'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
                                  }">
                                  <template v-slot:label>
                                    <v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
                                      <v-col class="ma-0 pa-0" cols="auto">
                                        <v-avatar elevation="2" size="25" class="t-h6 " variant="outlined" :class="{
                                          'outline-color-white  text-primaryPurple': currentQResponse.checkedOption == option.key,
                                          'outline-color-grey  ': !(currentQResponse.checkedOption == option.key),

                                        }">
                                          {{ option.key.toUpperCase() }}
                                        </v-avatar>
                                      </v-col>
                                      <v-col class="ma-0 pa-0">
                                        <div class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
                                          v-html="getMarkDownHtml(option.value)"> </div>
                                      </v-col>

                                    </v-row>

                                  </template>

                                </v-radio>
                              </v-sheet>
                            </v-hover>
                          </v-col>
                          <v-col cols="auto" class="px-0 mx-0" v-if="currentQResponse.crossedOutOptions">
                            <v-avatar class="outline-color text-capitalize text-decoration-line-through"
                              variant="outlined" elevation="0" size="24"
                              v-if="!checkOptionExistsInCrossedList(option.key)"
                              @click="updateCrossedOptions(option.key, true)" style="cursor: pointer;">
                              {{ option.key }}
                              <!-- <div class="strike pr-3" >

                            </div> -->
                            </v-avatar>
                            <v-avatar v-else class="outline-color " elevation="0" size="24"
                              @click="updateCrossedOptions(option.key, false)" style="cursor: pointer;">
                              <v-icon>
                                mdi-rotate-right
                              </v-icon>
                            </v-avatar>

                          </v-col>
                        </v-row>

                      </v-radio-group>
                    </section>


                    <section v-if="currentQ.qType == '2'">

                      <v-row v-for="option in currentQ.options" :key="option.key" align="center">
                        <v-col cols="">
                          <v-hover v-slot="{ isHovering, props }">


                            <v-sheet v-bind="props" class="pa-0 pl-2 ma-0 bx" :class="{
                              'on-hover': isHovering && $vuetify.display.smAndUp, 'selected': getMultichoiceSelected(option.key)
                            }" style="position: relative;">
                              <div class="strike"
                                v-if="checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions">
                              </div>
                              <v-checkbox density="compact" multiple hide-details size color="white"
                                v-model="currentQResponse.checkedOptions" class=" tqp ma-0 pa-0 w-100 testOption pr-3"
                                dense :value="option.key" @update:modelValue="checkBoxesUpdate" :class="{
                                  'disabledOpacity': checkOptionExistsInCrossedList(option.key) && currentQResponse.crossedOutOptions
                                }">

                                <template v-slot:label>

                                  <div class="htmlClassTest htmlClassTestOption ml-1 py-4 w-100"
                                    v-html="getMarkDownHtml(option.value)"> </div>

                                </template>
                              </v-checkbox>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                        <v-col cols="auto" class="px-0 mx-0" v-if="currentQResponse.crossedOutOptions">
                          <v-avatar class="outline-color text-capitalize text-decoration-line-through"
                            variant="outlined" elevation="0" size="24"
                            v-if="!checkOptionExistsInCrossedList(option.key)"
                            @click="updateCrossedOptions(option.key, true)">
                            {{ option.key }}
                            <!-- <div class="strike pr-3" >

                            </div> -->
                          </v-avatar>
                          <v-avatar v-else class="outline-color " elevation="0" size="24"
                            @click="updateCrossedOptions(option.key, false)">
                            <v-icon>
                              mdi-rotate-right
                            </v-icon>
                          </v-avatar>

                        </v-col>
                      </v-row>

                    </section>

                    <section v-if="currentQ.qType == '3'">

                      <v-row>
                        <v-col cols="8" md="3" lg="2">
                          <v-text-field label="Answer" v-model="currentQResponse.integerOption"
                            variant="outlined"></v-text-field>

                        </v-col>

                      </v-row>

                    </section>

                    <section v-if="currentQ.qType == '4'">

                      <v-row>
                        <v-col cols="8" md="3" lg="2">
                          <v-text-field label="Answer" v-model="currentQResponse.decimalOption"
                            variant="outlined"></v-text-field>

                        </v-col>

                      </v-row>

                    </section>
                  </div>



                </section>
              </v-card>
            </v-col>
            <!-- <v-col cols="3">
            
          </v-col> -->
          </v-row>
        </v-container>

      </div>

      <v-footer v-if="!submitScreen" app class="bgWhite px-4 pt-4 pb-6 ma-0" elevation="2">
        <v-row v-if="$vuetify.display.mdAndUp" justify="space-between" class="  px-8 align-center">

          <v-col cols="auto" class=" align-center">
            <v-btn :size="$vuetify.display.smAndUp ? 'large' : 'default'"
              :width="$vuetify.display.mdAndUp ? '130' : '125'" class="t-btn text-none text-black"
              @click="clearResponse" elevation="0" color="transparent">
              Clear response
              <template v-slot:prepend>
                <v-icon color="primaryPurple">mdi-rotate-right</v-icon>
              </template>
            </v-btn>

          </v-col>
          <v-col cols="auto">
            <span class="t-1remReg">
              {{ currentQ.qNo }} of {{ currentTestJson[currentTestState.sectionIndex].questions.length }}
            </span>
          </v-col>
          <v-col cols="auto" class=" align-center">
            <v-btn size="large" width="130" class=" t-btn text-none btn-grad  text-primaryTextColor" elevation="0"
              @click="lastQuestion ? submitTest() : nextQuestion()">
              <span v-if="lastQuestion">
                Submit
              </span>
              <span v-else>
                Next
              </span>
              <template v-slot:append>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </template>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else justify="space-between" class="  px-5 align-center" align="center">
          <Transition name="slide-fade">

            <v-col cols="12" v-if="menuToggle">

              <ScoreBar :room-participants="roomParticipants" :total-marks="totalMarks" iconColor="black">
              </ScoreBar>
            </v-col>
          </Transition>
          <v-col cols="12">
            <v-row class align="center" justify="space-between">

              <v-col class cols="auto">


                <v-btn color="secondaryBackground" density="compact" elevation="0" size="large"
                  :icon="menuToggle == true ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                  @click="menuToggle = !menuToggle"></v-btn>
                <v-avatar size="30" class="ml-1">
                  <v-img src="@/assets/trophy.png">

                  </v-img>
                </v-avatar>

                <!-- <span class="leader ml-3">
                  Leaderboard
                </span> -->

                <v-btn v-if="$vuetify.display.smAndUp" size="large" class="t-btn text-none text-black ml-3"
                  @click="clearResponse" elevation="0" color="transparent">
                  Clear response
                  <template v-slot:prepend>
                    <v-icon color="primaryPurple">mdi-rotate-right</v-icon>
                  </template>
                </v-btn>
                <v-icon v-else class="ml-3" color="primaryPurple" @click="clearResponse" elevation="0">
                  mdi-rotate-right
                </v-icon>
              </v-col>
              <!-- <v-col cols="auto">
                <v-btn v-if="$vuetify.display.smAndUp" size="large" class="t-btn  text-black"
                  @click="clearResponse" elevation="0" color="transparent">
                  Clear Response
                  <template v-slot:prepend>
                    <v-icon color="primaryPurple">mdi-rotate-right</v-icon>
                  </template>
                </v-btn>
                <v-btn v-else class="" density="compact" color="" icon="mdi-rotate-right" @click="clearResponse"
                  elevation="0">
                </v-btn>
              </v-col> -->
              <v-col cols="auto">
                <span class="t-1remReg">
                  {{ currentQ.qNo }} of {{ currentTestJson[currentTestState.sectionIndex].questions.length }}
                </span>
              </v-col>
              <v-col class="text-end  align-center " cols="auto">
                <v-btn class=" t-btn text-none btn-grad  text-primaryTextColor"
                  :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                  :width="$vuetify.display.smAndUp ? '130' : 'auto'" elevation="0"
                  @click="lastQuestion ? submitTest() : nextQuestion()">
                  <span v-if="lastQuestion">
                    Submit
                  </span>
                  <span v-else>
                    Next
                  </span>
                  <template v-slot:append>
                    <v-icon color="white">mdi-chevron-right</v-icon>
                  </template>
                </v-btn>


              </v-col>

            </v-row>


          </v-col>

        </v-row>
      </v-footer>
    </div>

    <div class="menu w-30" v-if="$vuetify.display.mdAndUp && !submitScreen">

      <v-container fluid class="cir">
        <v-row class="pr-4" :class="{
          'pl-2': menuToggle
        }">

          <v-col cols="12">
            <v-btn class="menu-button text-primaryTextColor" density="compact" elevation="0" size="40"
              :icon="menuToggle == true ? 'mdi-chevron-right' : 'mdi-chevron-left'"
              @click="menuToggle = !menuToggle"></v-btn>
            <v-avatar size="30" class="ml-3">
              <v-img src="@/assets/trophy.png">

              </v-img>
            </v-avatar>
            <span class="leader ml-3 text-primaryTextColor">
              Leaderboard
            </span>

          </v-col>
          <Transition name="slide-fade">



            <v-col cols="12" v-if="menuToggle">
              <ScoreBar :room-participants="roomParticipants" :total-marks="totalMarks" iconColor="white">
              </ScoreBar>
            </v-col>
          </Transition>
        </v-row>
      </v-container>

    </div>
  </div>
</template>
      
<script>
import { mapState } from "vuex";
import _ from "lodash";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import ScoreBar from "@/components/challenge/ScoreBar.vue"
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";

import { useDisplay } from "vuetify";
const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: 'ChallengeQuestionPage',
  setup() {
    // Destructure only the keys we want to use
    const { mdAndDown, xs, mdAndUp } = useDisplay();

    return { xs, mdAndDown, mdAndUp };
  },
  data: () => ({
    questionDialog: false,
    submitTimer: null,
    submitTimerTime: 60,
    baseLineClockDrift: 0,
    lastQuestion: false,
    timeOver: false,
    dataLoaded: false,
    remaningTimeInSeconds: 0,
    warningDialog: false,
    warningTimeLeft: 10,
    serverTimeCounter: 0,
    intervalId: null,
    serverTime: null,
    reviewScreen: false,
    instructionsDialog: false,
    submitted: false,
    testInstructionsDialog: false,
    showSubmitAlert: false,
    waitingRoomParticipants: [],
    menuToggle: false,
    submitScreen: false,
    roomParticipants: [],
    timeoutIds: [],
    submissionStatus: false,
    reportDialog: false,
    curentQDuration: {
      qId: '',
      startTime: '',
      endTime: '',
      sentToServer: false
    },
    mentoScore: 0,
    currentQResponse: {
      qId: "",
      qType: "",
      checkedOption: "",
      checkedOptions: [],
      integerOption: "",
      decimalOption: "",
      markedForReview: false,
      crossedOutOptions: false,
      crossedOutOptionsList: []
    },
    defualtCurrentQResponse: {
      qId: "",
      qType: "",
      checkedOption: "",
      checkedOptions: [],
      integerOption: "",
      decimalOption: "",
      markedForReview: false,
      crossedOutOptions: false,
      crossedOutOptionsList: []
    }
  }),
  components:
  {
    ScoreBar,
    DragableFeedbackDialog
  },
  unmounted() {
    this.$store.commit("updateShowBackground", true)

    if (this.startTestInfo.type == "ai") {
      for (let item of this.timeoutIds) {
        clearTimeout(item)
      }
    }

    if (this.ablyChannel != null) {
      this.ablyChannel.presence.unsubscribe()

      this.ablyChannel.presence.leave()
    }
    if (this.ablyRealTime != null) {
      console.log("close ably")
      setTimeout(() => {
        this.ablyRealTime.close()
      }, 1000);
    }

    if (this.submitted == false) {
      if (this.startTestInfo.type == "ai") {

        recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeLeftChallengeWithoutCompleting, {
          challengeId: this.currentChallengeId,
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          userType: this.userType
        });
      }
    }
  },
  async created() {
    this.$store.commit("updateShowBackground", false)
    if (this.startTestInfo.type == "ai") {
      
      this.roomParticipants.push({
        score: 0,
        name: this.userName,
        clientId: this.userId,
        available: true,
        participant: true,
        host: false,
        submitted: false,
        type: "real"
      })

      this.roomParticipants.push({
        score: 0,
        name: "Mento",
        clientId: "Mento",
        available: true,
        participant: false,
        host: true,
        submitted: false,
        type: "ai"
      })

      this.setAITimer()
    }
    else {

      this.roomParticipants = this.ablyParticipants.map((e) => {
        return {
          score: 0,
          name: e.data.name,
          clientId: e.clientId,
          available: true,
          participant: e.data.participant,
          host: e.data.host,
          submitted: false,
          self: e.clientId == this.userId
        }
      })
      console.log(this.roomParticipants);
      const self = this
      await this.ablyChannel.presence.subscribe((msg) => {
        console.log(msg);

        this.ablyChannel.presence.get(async (err, members) => {

          if (err) {
            return console.error(`Error retrieving presence data: ${err}`);
          }
          self.$store.commit("updateAblyParticipantsWhileAttempting", members)
          this.updateRoomParticipants()
          if (this.submitted) {
            console.log("check submit");
            this.submissionStatus = this.checkParticipantsSubmitted()
            if (this.submissionStatus == true) {
              this.$store.commit("updateComingFromChallenge", true)
              this.$router.replace(`/challenge/challengereport/${this.currentChallengeId}?direct=true`)
            }
          }
        });
        this.userLeaveAnalytics(msg)
      });
    }
    this.remaningTimeInSeconds = this.syncTimerData.testRemainingDurationInSeconds
    this.startTimer()
    this.$store.commit("updateTestRoutingAllowed", false)


    this.serverTime = this.syncTimerData.serverTimeStamp
    this.curentQDuration.startTime = this.serverTime
    this.baseLineClockDrift = Math.abs(this.getCurrentServerTime() - this.getCurrentClientTimeInSeconds())
    // console.log(this.getCurrentServerTime());
    // console.log(this.getCurrentClientTimeInSeconds());
    this.setQIdAndQTypeAfterPrevAndNext()
    this.dataLoaded = true
    this.menuToggle = true
    setTimeout(() => {
      window.MathJax.typesetPromise();
    }, 0);
  },
  computed: {
    ...mapState(["ablyParticipants", "submissionInfo", "ablyParticipantsWhileAttempting", "currentTestJson", "currentTestState", "startTestInfo", "qResponsesState",
      "syncTimerData", "currentTestId", "submitError", "ablyChannel", "userType", "currentChallengeId", "challengeSounds", "ablyRealTime", "aiInstanceSteps", "participantScoreWithAI"]),
    currentQ: {
      get() {
        return this.currentTestJson[this.currentTestState.sectionIndex].questions[this.currentTestState.qIndex]
      }
    },
    currentSection: {
      get() {
        return this.startTestInfo.testmetadata.sections[this.currentTestState.sectionIndex]
      }
    },
    totalMarks: {
      get() {
        return this.startTestInfo.testmetadata.totalMarks
      }
    },
    userName: {
      get() {
        return this.$store.state.userState.name
      }
    },
    userId: {
      get() {
        return this.$store.state.userState.userId
      }
    },
    questionCounts: {
      get() {
        let res = {
          answered: 0,
          marked: 0,
          unanswered: 0
        }

        let arr = []

        for (let item of this.qResponsesState) {
          arr.push(...item.responses)
        }


        for (let item of arr) {
          if (item.markedForReview) {
            res.marked++
          }
          if (this.checkIfQuestionAnswered(item)) {
            res.answered++
          }
          else {
            res.unanswered++
          }
        }
        return res
      }
    },
    prevButtonDisabled: {
      get() {
        return this.currentTestState.qIndex == 0 && this.currentTestState.sectionIndex == 0 ? true : false
      }
    },
    getRemainingTime: {
      get() {
        if (this.intervalId == null) {
          return ''
        }
        const res = this.toHoursAndMinutes(this.remaningTimeInSeconds)
        if (this.mdAndDown) {
          return `${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`
        } else {
          return `${this.minTwoDigits(res.h)} : ${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`
        }
      }
    },
    submitFormattedTime: {
      get() {
        if (this.submitTimer == null) {
          return ''
        }
        const res = this.toHoursAndMinutes(this.submitTimerTime)
        return ` ${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`
      }
    },
    challengeType: {
      get() {
        if ("type" in this.startTestInfo && this.startTestInfo.type == "ai") {
          return "aichallenge"
        }
        else {
          return "challenge"
        }
      }
    }

  },

  watch: {
    remaningTimeInSeconds(val) {

      if (val == 0) {
        clearInterval(this.intervalId)
        this.submitScreen = true
        // window.alert("time over")
        this.intervalId = null
        this.updateNextQDurationAndSendLastQToServer()
        this.timeOver = true
        this.reviewScreen = true
        //this.submitTest()
        this.submitTimer = setInterval(() => {
          this.submitTimerTime = (this.submitTimerTime - 1)
        }, 1000);


      }
    },
    submitTimerTime(val) {

      if (val == 0) {
        clearInterval(this.submitTimer)
        // console.log("submit test");
        recordAnalyticsEvent(AnalyticsEvents.v2TestSubmitTest, {
          testId: this.currentTestId,
          testName: this.startTestInfo.testmetadata.testName,
          testInstanceId: this.startTestInfo.testInstanceId
        });

        this.$store.commit("updateTestRoutingAllowed", true)

        this.$store.dispatch('submitChallengeWithoutResponse').then(() => {
          // this.$router.replace('/testsubmission')
          this.submitted = true
          this.submitAnalytics()
          if (this.startTestInfo.type == "ai") {
            this.$store.commit("updateParticipantScoreWithAI", this.submissionInfo.challengeScore)
            this.roomParticipants[0].submitted = true
            this.submissionStatus = this.checkParticipantsSubmitted()
            if (this.submissionStatus == true) {
              this.$store.commit("updateComingFromChallenge", true)

              this.$router.replace(`/challenge/challengereport/${this.currentChallengeId}`)
            }
          }
          else {
            this.ablyChannel.presence.update({ score: this.submissionInfo.challengeScore, submitted: true })
          }
        })

      }
    },

    participantScoreWithAI: {
      handler(newVal, oldVal) {
        let item = { self: true }
        this.playSound(item, newVal, oldVal)

        this.roomParticipants[0].score = this.participantScoreWithAI
      },
      deep: true
    },

    mentoScore: {
      handler(newVal, oldVal) {
        let item = { self: false }
        this.playSound(item, newVal, oldVal)

      },
      deep: true
    },
    currentTestState: {
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        if (this.currentTestState.qIndex == this.currentTestJson[this.currentTestState.sectionIndex].questions.length - 1) {

          if (this.currentTestState.sectionIndex == this.currentTestJson.length - 1) {
            console.log("last");
            this.lastQuestion = true
          }
        }
      },
      deep: true
    }
  },

  methods: {
    radioGroupUpdate() {
      console.log("update");
      let currentVal = this.currentQResponse.checkedOption
      this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== currentVal)

    },
    checkBoxesUpdate(val) {
      console.log("update checkbox", val);
      let currentVals = this.currentQResponse.checkedOptions
      this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !currentVals.includes(item))

    },
    showCrossedOutOptions() {
      this.currentQResponse.crossedOutOptions = true
      // let val = null
      if (this.currentQ.qType == "1") {
        this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== this.currentQResponse.checkedOption)
      }

      if (this.currentQ.qType == "2") {

        this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => !this.currentQResponse.checkedOptions.includes(item))

      }

    },
    hideCrossedOutOptions() {
      this.currentQResponse.crossedOutOptions = false
    },
    checkOptionExistsInCrossedList(val) {
      let find = this.currentQResponse.crossedOutOptionsList.find((e) => e == val) ?? null
      if (find == null) {
        return false
      }
      return true

    },
    updateCrossedOptions(val, add) {
      if (add) {
        this.currentQResponse.crossedOutOptionsList.push(val)
        if (this.currentQ.qType == "1") {
          if (this.currentQResponse.checkedOption == val) {
            this.currentQResponse.checkedOption = ""
          }
        }
        if (this.currentQ.qType == "2") {
          console.log(val)
          if (this.currentQResponse.checkedOptions.includes(val)) {
            this.currentQResponse.checkedOptions = this.currentQResponse.checkedOptions.filter(item => item !== val)
          }
        }
      }
      else {
        this.currentQResponse.crossedOutOptionsList = this.currentQResponse.crossedOutOptionsList.filter(item => item !== val)

      }
      console.log(this.currentQResponse);
    },
    showReportDialog() {

      // recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
      //   testId: this.currentTestId,
      //   testName: this.startTestInfo.testmetadata.testName,
      //   testInstanceId: this.startTestInfo.testInstanceId,
      //   sectionId: this.currentSection.sectionId,

      // });
      this.reportDialog = true
    },
    setAITimer() {
      let steps = this.aiInstanceSteps
      // let i = 0
      for (let i = 0; i < steps.length; i++) {
        this.timeoutIds[i] = setTimeout(() => {
          this.roomParticipants[1].score = steps[i].score
          this.mentoScore = steps[i].score
          if (i == steps.length - 1) {
            console.log("mento ai submit");
            this.roomParticipants[1].submitted = true

            recordAnalyticsEvent(AnalyticsEvents.v2AIMentoChallengeCompleted, {
              challengeId: this.currentChallengeId,
              testId: this.currentTestId,
              testInstanceId: this.startTestInfo.testInstanceId,
              userType: this.userType
            });


            this.submissionStatus = this.checkParticipantsSubmitted()
            if (this.submissionStatus == true) {
              this.$store.commit("updateComingFromChallenge", true)

              this.$router.replace(`/challenge/challengereport/${this.currentChallengeId}?direct=true`)
            }
          }
        }, steps[i].offset * 1000);


      }

    },
    updateAITimer(i, realAnswerTimer){
      let steps = this.aiInstanceSteps;
      if(realAnswerTimer< steps[i].offset){
        clearTimeout(this.timeoutIds[i]);
        const offset = Math.floor(Math.random() * 10) + 1
        this.timeoutIds[i] = setTimeout(() => {
          this.roomParticipants[1].score = steps[i].score
          this.mentoScore = steps[i].score
          if (i == steps.length - 1) {
            console.log("mento ai submit");
            this.roomParticipants[1].submitted = true

            recordAnalyticsEvent(AnalyticsEvents.v2AIMentoChallengeCompleted, {
              challengeId: this.currentChallengeId,
              testId: this.currentTestId,
              testInstanceId: this.startTestInfo.testInstanceId,
              userType: this.userType
            });

            this.submissionStatus = this.checkParticipantsSubmitted()
            if (this.submissionStatus == true) {
              this.$store.commit("updateComingFromChallenge", true)
              this.$router.replace(`/challenge/challengereport/${this.currentChallengeId}?direct=true`)
            }
          }
        }, offset * 1000);
      }
    },
    // updateAIScores(type){
    // let data = this.roomParticipants

    // },
    userLeaveAnalytics(message) {
      if (message.action == "leave") {
        let user = ""
        if ("host" in message.data && message.data.host) {
          user = "host"
        }
        else {
          user = "participant"
        }
        if ("submitted" in message.data && !message.data.submitted) {

          recordAnalyticsEvent(AnalyticsEvents.v2ChallengeLeftChallengeWithoutCompleting, {
            challengeId: this.currentChallengeId,
            testId: this.currentTestId,
            testInstanceId: this.startTestInfo.testInstanceId,
            userType: user,
            ...message.data
          });

        }

      }
    },
    updateRoomParticipants() {
      console.log("update");
      let currentMembers = this.ablyParticipantsWhileAttempting
      console.log(currentMembers);

      let list = this.roomParticipants
      let i = 0

      for (let item of this.roomParticipants) {
        let exists = currentMembers.find((e) => e.clientId == item.clientId) ?? null
        if (exists != null) {
          list[i].available = true
          console.log("e");
          if ("score" in exists.data) {
            let prevScore = list[i].score
            let newScore = exists.data.score

            this.playSound(list[i], newScore, prevScore,)
            // console.log("prev score", list[i].score, list[i]);

            list[i].score = exists.data.score
            // console.log("new score", list[i].score);

          }
          if ("submitted" in exists.data) {
            list[i].submitted = exists.data.submitted

          }
        }
        else {
          list[i].available = false
        }
        i = i + 1
      }

      this.roomParticipants = list

    },
    playSound(item, newScore, prevScore) {
      console.log(item);
      console.log("new", newScore);
      console.log("prev", prevScore);
      if (newScore > prevScore) {
        if (item.self) {
          console.log("self plus sound");
          this.challengeSounds.selfIncrease.play()
        }
        else {
          console.log("other user plus sound");
          this.challengeSounds.otherUserIncrease.play()
        }
      }
      else if (newScore < prevScore) {
        if (item.self) {
          console.log("self minus sound");
          this.challengeSounds.selfDecrease.play()
        }
        else {
          console.log("other user minus sound");
          this.challengeSounds.otherUserDecrease.play()
        }
      }
      // if ("participant" in item && item.participant) {
      //     console.log("participant plus sound");
      //     this.challengeSounds.p1.play()
      //   }
      //   else if(newScore < prevScore) {
      //     console.log("participant  minus sound");
      //     this.challengeSounds.pn.play()
      //   }
      // }

      // if ("host" in item && item.host) {
      //   if (newScore > prevScore) {
      //     console.log("host plus sound");
      //     this.challengeSounds.h1.play()
      //   }
      //   else if(newScore < prevScore) {

      //     console.log("host  minus sound");
      //     this.challengeSounds.hn.play()
      //   }
      // }
    },
    getMultichoiceSelected(key) {
      return this.currentQResponse.checkedOptions.includes(key)
    },
    getCurrentClientTimeInSeconds() {
      return Math.floor(Date.now() / 1000)
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        this.remaningTimeInSeconds = (this.remaningTimeInSeconds - 1)
        this.serverTimeCounter += 1
      }, 1000);
    },
    minTwoDigits(n) {
      return (n < 10 ? '0' : '') + n;
    },
    openQFromDialog(sIndex, qIndex) {

      // don't do anything if on the same question, needs discussion

      this.updateNextQDurationAndSendLastQToServer()
      this.$store.commit('updateCurrentTestState', {
        qIndex: qIndex,
        sectionIndex: sIndex
      })

      this.setQIdAndQTypeAfterPrevAndNext()
      this.questionDialog = false

      setTimeout(() => {
        window.MathJax.typesetPromise();

      }, 0);
    },
    openQFromReview(sIndex, qIndex) {
      // this.updateNextQDurationAndSendLastQToServer()

      // don't do anything if on the same question, needs discussion

      this.curentQDuration.startTime = this.getCurrentServerTime()

      this.$store.commit('updateCurrentTestState', {
        qIndex: qIndex,
        sectionIndex: sIndex
      })

      this.setQIdAndQTypeAfterPrevAndNext()
      this.reviewScreen = false

      setTimeout(() => {
        window.MathJax.typesetPromise();

      }, 0);
    },
    getMarkDownHtml(markdown) {
      if (markdown == null) {
        return ""
      }
      let markDownText = markdown
      let out = false;
      const regex = /\\/gm;
      const escapedString = markDownText.replace(regex, "\\\\");

      out = marked.parseInline(escapedString, { renderer });

      return out;
    }, nextQuestion() {

      this.updateNextQDurationAndSendLastQToServer()

      if (this.currentTestState.qIndex == this.currentTestJson[this.currentTestState.sectionIndex].questions.length - 1) {

        if (this.currentTestState.sectionIndex == this.currentTestJson.length - 1) {
          console.log("last");
          this.lastQuestion = true
        }
        else {
          this.$store.commit('updateCurrentTestState', {
            qIndex: 0,
            sectionIndex: this.currentTestState.sectionIndex + 1
          })
        }
      }
      else {

        // changes ui to next question
        this.$store.commit('updateCurrentTestState', {
          qIndex: this.currentTestState.qIndex + 1,
          sectionIndex: this.currentTestState.sectionIndex
        })
      }
      this.setQIdAndQTypeAfterPrevAndNext()


      setTimeout(() => {
        window.MathJax.typesetPromise();

      }, 0);


    },
    prevQuestion() {

      this.updateNextQDurationAndSendLastQToServer()


      if (this.currentTestState.qIndex == 0) {

        if (this.currentTestState.sectionIndex == 0) {
          console.log("first section");
        }
        else {
          this.$store.commit('updateCurrentTestState', {
            qIndex: this.currentTestJson[this.currentTestState.sectionIndex - 1].questions.length - 1,
            sectionIndex: this.currentTestState.sectionIndex - 1
          })
        }
      }
      else {

        // changes ui to next question
        this.$store.commit('updateCurrentTestState', {
          qIndex: this.currentTestState.qIndex - 1,
          sectionIndex: this.currentTestState.sectionIndex
        })
      }



      this.setQIdAndQTypeAfterPrevAndNext()

      setTimeout(() => {
        window.MathJax.typesetPromise();

      }, 0);


    },
    updateNextQDurationAndSendLastQToServer() {
      this.checkClockDrift()
      const lastAttemptQDuration = _.cloneDeep(this.curentQDuration)
      const lasAttemptQResponse = _.cloneDeep(this.currentQResponse)
      // 
      if (!Array.isArray(lasAttemptQResponse.checkedOptions)) {
        lasAttemptQResponse.checkedOptions = [lasAttemptQResponse.checkedOptions] //[null]
      }
      if (Array.isArray(lasAttemptQResponse.checkedOption)) {
        lasAttemptQResponse.checkedOption = lasAttemptQResponse.checkedOption[0] //[null]
      }

      lastAttemptQDuration.endTime = this.getCurrentServerTime()

      this.curentQDuration.startTime = this.getCurrentServerTime()

      if (this.startTestInfo.type == "ai") {
        this.updateAITimer(this.currentTestState.qIndex, this.serverTimeCounter);
      }

      this.$store.dispatch('updateQuestionDurationAndResponseChallenge', { qDuration: lastAttemptQDuration, qResponse: lasAttemptQResponse })
    },
    toHoursAndMinutes(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);

      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return { h: parseInt(hours), m: parseInt(minutes), s: parseInt(seconds) };
    },
    getCurrentServerTime() {
      return this.serverTime + this.serverTimeCounter;
    },
    setQIdAndQTypeAfterPrevAndNext() {
      this.currentQResponse = this.qResponsesState[this.currentTestState.sectionIndex].responses[this.currentTestState.qIndex]

      this.curentQDuration.qId = this.currentTestJson[this.currentTestState.sectionIndex].questions[this.currentTestState.qIndex].qId
      this.curentQDuration.sectionId = this.currentTestJson[this.currentTestState.sectionIndex].sectionId

    },
    clearResponse() {
      this.currentQResponse.checkedOption = ""
      this.currentQResponse.checkedOptions = []
      this.currentQResponse.integerOption = ""
      this.currentQResponse.decimalOption = ""
    },
    getTypeOfQuestionCounts() {
      let res = {
        answered: 0,
        marked: 0,
        unanswered: 0
      }

      for (let item of this.qResponsesState) {
        if (item.markedForReview) {
          res.marked++
        }
        if (this.checkIfQuestionAnswered(item)) {
          res.answered += res.answered
        }
        else {
          res.unanswered += res.unanswered
        }

        return res
      }
    },
    checkIfQuestionAnswered(q) {
      if (q.qType == 1) {
        return q.checkedOption == null || q.checkedOption.length == 0 ? false : true
      }
      if (q.qType == 2) {
        return q.checkedOptions == null || q.checkedOptions.length == 0 ? false : true
      }
      if (q.qType == 3) {
        return q.integerOption == null || q.integerOption.length == 0 ? false : true
      }
      if (q.qType == 4) {
        return q.decimalOption == null || q.decimalOption.length == 0 ? false : true

      }
    },
    checkClockDrift() {
      // console.log(this.getCurrentClientTimeInSeconds());
      // console.log(this.getCurrentServerTime());
      let currentDrift = Math.abs(this.getCurrentClientTimeInSeconds() - this.getCurrentServerTime())
      // console.log(currentDrift);
      // console.log(this.baseLineClockDrift);

      if (Math.abs(currentDrift - this.baseLineClockDrift) >= 10) {
        console.log("drift limit exceeded, get time form server");
        this.resetTimer()
      }
    },
    backFromReview() {
      this.curentQDuration.startTime = this.getCurrentServerTime()
      this.setQIdAndQTypeAfterPrevAndNext()
      this.reviewScreen = false
      setTimeout(() => {
        window.MathJax.typesetPromise();

      }, 0);
      // get request : with testInstanceId
    },
    resetTimer() {
      this.$store.dispatch("updateSyncTimer", { state: "sync" }).then(() => {

        clearInterval(this.intervalId)
        this.remaningTimeInSeconds = this.syncTimerData.testRemainingDurationInSeconds
        this.serverTime = this.syncTimerData.serverTimeStamp
        this.serverTimeCounter = 0

        this.baseLineClockDrift = Math.abs(this.getCurrentServerTime() - this.getCurrentClientTimeInSeconds())
        this.startTimer()
      })

    },
    showInstructions() {
      recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
        testId: this.currentTestId,
        testName: this.startTestInfo.testmetadata.testName,
        testInstanceId: this.startTestInfo.testInstanceId,
        sectionId: this.currentSection.sectionId,

      });
      this.instructionsDialog = true
    },
    submitTestWithourReponse() {
      recordAnalyticsEvent(AnalyticsEvents.v2TestSubmitTest, {
        testId: this.currentTestId,
        testName: this.startTestInfo.testmetadata.testName,
        testInstanceId: this.startTestInfo.testInstanceId
      });
      this.showSubmitAlert = false
      this.$store.commit("updateTestRoutingAllowed", true)
      this.$store.commit("updateSubmitError", "")

      this.$store.dispatch('submitChallenge').then(() => {
        console.log(this.submitError)
        this.submitScreen = true

        if (this.submitError.length == 0) {
          //this.$router.replace('/testsubmission')
          this.submitted = true

        }
        else {
          this.showSubmitAlert = true
        }
      })
    },
    async submitTest() {
      this.checkClockDrift()
      const lastAttemptQDuration = _.cloneDeep(this.curentQDuration)
      const lasAttemptQResponse = _.cloneDeep(this.currentQResponse)
      // 
      if (!Array.isArray(lasAttemptQResponse.checkedOptions)) {
        lasAttemptQResponse.checkedOptions = [lasAttemptQResponse.checkedOptions] //[null]
      }
      if (Array.isArray(lasAttemptQResponse.checkedOption)) {
        lasAttemptQResponse.checkedOption = lasAttemptQResponse.checkedOption[0] //[null]
      }

      lastAttemptQDuration.endTime = this.getCurrentServerTime()

      recordAnalyticsEvent(AnalyticsEvents.v2TestSubmitTest, {
        testId: this.currentTestId,
        testName: this.startTestInfo.testmetadata.testName,
        testInstanceId: this.startTestInfo.testInstanceId
      });
      this.showSubmitAlert = false
      this.$store.commit("updateTestRoutingAllowed", true)
      this.$store.commit("updateSubmitError", "")
      
      if (this.startTestInfo.type == "ai") {
        this.updateAITimer(this.currentTestState.qIndex, this.serverTimeCounter);
      }

      this.$store.dispatch('submitChallenge', { qDuration: lastAttemptQDuration, qResponse: lasAttemptQResponse }).then(() => {
        console.log(this.submitError)
        this.submitScreen = true

        if (this.submitError.length == 0) {
          //this.$router.replace('/testsubmission')
          if (this.startTestInfo.type == "ai") {
            this.$store.commit("updateParticipantScoreWithAI", this.submissionInfo.challengeScore)
            this.roomParticipants[0].submitted = true
            this.submissionStatus = this.checkParticipantsSubmitted()
            if (this.submissionStatus == true) {
              this.$store.commit("updateComingFromChallenge", true)

              this.$router.replace(`/challenge/challengereport/${this.currentChallengeId}?direct=true`)
            }
          }
          else {
            this.ablyChannel.presence.update({ score: this.submissionInfo.challengeScore, submitted: true })
          }
          this.submitted = true
          this.submitAnalytics()
        }
        else {
          this.showSubmitAlert = true
        }
      })

    },
    submitAnalytics() {
      if (this.startTestInfo.type == "ai") {
        recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeCompleted, {
          challengeId: this.currentChallengeId,
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          userType: this.userType
        });
      }
      else {
        recordAnalyticsEvent(AnalyticsEvents.v2ChallengeCompleted, {
          challengeId: this.currentChallengeId,
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          userType: this.userType
        });
      }
    },
    showTestInstructionsDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2TestInstructionsDuringTest, {
        testId: this.currentTestId,
        testName: this.startTestInfo.testmetadata.testName,
        testInstanceId: this.startTestInfo.testInstanceId
      });
      this.testInstructionsDialog = true
    }
    ,
    exitTest() {
      if (this.startTestInfo.type == "ai") {

        if (this.submitted) {
          recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeExitChallengeButtonClickedAfterCompleting, {
            testId: this.currentTestId,
            testName: this.startTestInfo.testmetadata.testName,
            testInstanceId: this.startTestInfo.testInstanceId
          });
        }
        else {
          recordAnalyticsEvent(AnalyticsEvents.v2AIChallengeExitChallengeButtonClickedBeforeCompleting, {
            testId: this.currentTestId,
            testName: this.startTestInfo.testmetadata.testName,
            testInstanceId: this.startTestInfo.testInstanceId
          });

        }
      }
      else {
        if (this.submitted) {
          recordAnalyticsEvent(AnalyticsEvents.v2ChallengeExitChallengeButtonClickedAfterCompleting, {
            testId: this.currentTestId,
            testName: this.startTestInfo.testmetadata.testName,
            testInstanceId: this.startTestInfo.testInstanceId
          });
        }
        else {
          recordAnalyticsEvent(AnalyticsEvents.v2ChallengeExitChallengeButtonClickedBeforeCompleting, {
            testId: this.currentTestId,
            testName: this.startTestInfo.testmetadata.testName,
            testInstanceId: this.startTestInfo.testInstanceId
          });

        }
      }
      // this.$router.back()
      // we should always go back to challenge
      this.$router.replace("/challenge")

    },
    openQuestionDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2TestQuestionListPopupOpen, {
        testId: this.currentTestId,
        testName: this.startTestInfo.testmetadata.testName,
        testInstanceId: this.startTestInfo.testInstanceId
      });
      this.questionDialog = true
    },
    getInitials(fullName) {
      const allNames = fullName.trim().split(' ');
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
      return initials;
    },
    checkParticipantsSubmitted() {
      console.log(this.roomParticipants);
      for (let item of this.roomParticipants) {
        if (item.available) {
          if ("submitted" in item) {
            if (item.submitted == false) {
              return false
            }
          }
          else {
            return false
          }
        }
      }
      return true

    }
  },

}
</script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}

.correct {
  background: #26B99AA6 !important;
  color: white !important;
  ;
}

.img {
  vertical-align: middle;
}

.cir {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 36px 0px 0px 36px;

}

.success {
  background: #26B99AA6 !important;

}

.timer-color {
  color: #26B99AA6 !important;
}

.unanswered {
  border: 2px solid #949494 !important;
}

.marked {
  border: 2.5px solid #FF894F !important;
}

.menu-button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20.069px;
}

.option {

  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 10px;
  border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.bgTransParent {
  background-color: transparent;
}

.disabledOpacity {
  opacity: var(--v-disabled-opacity);
}

.strike {
  position: absolute;
  width: calc(100%);
  height: 2px;
  background: rgb(var(--v-theme-primaryPurple));
  left: 0px;
  top: 50%;
  opacity: 1 !important;
}

.bgW {
  height: 100% !important;
  width: 100%;
  background-color: white;
}

.bgP {
  height: 100% !important;
  width: 100%;
  background-color: #2B2B45;
}

.bgWhite {
  background-color: white !important
}

.bgW {
  height: 100%;
  width: 100%;
  background-color: white !important;
  z-index: 2;
}

.w-30 {
  max-width: 30%;
}

.w-20 {
  max-width: 20%;
}

.w-70 {
  max-width: 70%;
}

.w-80 {
  max-width: 80%;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.selected {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
  color: white !important
}

.bx {
  background-color: #f1f1f1;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
}

.subColor {
  color: rgba(255, 255, 255, 0.5) !important;
}


.layoutLarge {
  height: calc(100% - 160px);
}

.testCard {
  border-radius: 12px;
  background-color: white;
  color: black;
  border: 1px solid #E7E7E7;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.outline-color-grey {
	border-color: #010101b3 !important;
	border: 1px solid;
	color: #010101b3 !important;

}

.outline-color-white {
	border-color: white !important;
	background-color: white;
}

.leader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  color: black;
}

.card-bg-color {
  background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
  border-radius: 12px;
  background: #26253A;

}

.outline-color {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.redTimer {
  color: #FF786E !important;
}

.scroll {}

.menu {
  position: fixed;
  top: 30%;
  right: 0%;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: linear 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.qNoClass {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif !important;
}

.dialog-bx {
  position: fixed !important;
}

.btn-grad {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 29.4079px;
}
</style>
<style>
.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.testOption .v-selection-control--inline {

  min-width: 100% !important;
}
.custom-radio .v-selection-control__wrapper {
	display: none;
}
.testOption .v-label {

  width: 100% !important;
  opacity: 1 !important;
}

.v-label {

  opacity: 1 !important;
}
</style>