<template>
  <v-row v-if="dataLoaded" class="ma-0" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="end" align="center">
    <v-col cols="auto" class="pa-0">
      <SelfHelpGuideTogglerComponent class="mt-6 mr-8"/>
    </v-col>
  </v-row>
  <v-container fluid class="pb-4 main-lg">
    <v-container fluid class="container-width">
      <v-row>
        <v-col>
          <v-card class="main-card text-white mb-4">
            <v-card-title class="px-5 pt-4 pb-0">
              <span class="main-heading">Bookmark</span>
              <div class="t-h6Reg main-description">
                Here you can find your bookmarks
              </div>
              <v-divider class="mt-2"></v-divider>
            </v-card-title>
            <v-card-text class="pa-5 pt-4">
              <v-row class="ma-0">
                <v-col
                  cols="auto"
                  class="mr-1 tab t-1remReg text-white"
                  :class="{ 'selected-tab': tab.id == tabIndex }"
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click.stop="changeTab(tab)"
                  :ref="`StudentSavedPageScreen__tabs--${tab.uniqueId}`"
                >
                {{ getTabName(tab) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
      
          <v-card class="main-card text-white">
            <v-card-text class="pa-0">
              <v-row class="ma-0" v-if="tabIndex == '1'"> 
                <BookmarkComponent></BookmarkComponent>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  </template>
  <script>
  import { useDisplay } from "vuetify";
  import { mapGetters, mapState } from "vuex";
  import _ from "lodash";
  import {
    AnalyticsEvents,
    recordAnalyticsEvent,
  } from "@/services/awsPinpointAnalyticsService";
  import BookmarkComponent from "./BookmarkComponent.vue";
  import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
  import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
  export default {
    name: "SavedPage",
    setup() {
      // Destructure only the keys we want to use
      const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();
  
      return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
    },
    components: {
        BookmarkComponent,
        SelfHelpGuideTogglerComponent
    },
    data: () => ({
      dataLoaded: false,
      selectedTab: null,
      batchId: '',
      tabs: [
        {
          name: "Bookmark",
          id: "1",
          uniqueId: 'bookmark'
        }
      ],
      tabIndex: '1',
    }),
    async created() {
      this.$store.commit('appbarVisible', true);
      this.$store.commit('navbarVisible', true);
      this.$store.commit('switchingViewData', false);
 
      this.tabIndex = this.$route.params.savedTab;
      if(this.tabIndex > this.tabs.length){
        this.$router.replace('1');
        this.tabIndex = 1;
      }
      this.selectedTab = _.find(this.tabs, {id: this.tabIndex});
      this.dataLoaded = true;
  
    },
    computed: {
      ...mapState(['questionBookmarkList']),
      ...mapGetters(['currentHighlightedElements']),
    },
  
    watch: {
      currentHighlightedElements: {
        handler(newVal, oldVal) {
          this.$nextTick(() => {
            updateHighlightedElements(oldVal, newVal, this.$refs);
          });
        },
        immediate: true,
        deep: true,
      },
    },
  
    methods: {
      changeTab(tab) {
        console.log("this.tabIndex", this.tabIndex, tab);
        this.tabIndex = tab.id;
        this.selectedTab = tab;
        this.$router.replace(`${tab.id}`);
      },
      getTabName(tab){
        let name = '';
        if(tab.id == '1'){
          name = tab.name + ' (' + this.questionBookmarkList.length +')' ;
        }
        return name;
      }
    },
  };
  </script>
  <style scoped>

  .heightBread {
    height: 80px;
  }
  
  .container-width {
    max-width: 1080px;
  }
  
  .main-lg {
    padding-top: 20px;
  }
  
  .main-card {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background: rgb(var(--v-theme-cardBackgroundColor));
  }
  
  .main-heading {
    font-size: 1.25rem;
    color: rgb(var(--v-theme-white));
  }

  .main-description{
    white-space: normal !important;
    line-height: 1.5;
  }
  
  .tab {
    background: rgb(var(--v-theme-report-subCardBg));
    padding: 10px 15px;
    margin-right: 12px !important;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 12px;
  }
  
  .selected-tab {
    background: linear-gradient(#303146, #303146) padding-box,
      linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
    border: 2px solid transparent;
  }
  </style>
  