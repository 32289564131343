<template>
  <v-dialog style="backdrop-filter: blur(12.5px)" persistent :model-value="openUpgradeDialog && dataLoaded" scrollable
    :fullscreen="pricingModels.allowStudentsToBuyPackage && userPackages.length > 0" :width="(pricingModels.allowStudentsToBuyPackage  && userPackages.length > 0)? '100%': '400'">
    <v-card v-if="pricingModels.allowStudentsToBuyPackage && userPackages.length > 0" elevation="0" class="upgrade-card justify-center align-center pa-2">
      <v-card-text class="pa-4 pt-1 w-100 subscriptionDialogScrollbar">
        <v-row class="ma-0 pa-0">
        <v-col class="pa-0" justify="end" align="end"><v-btn icon="mdi-close"
            class="t-btn text-none rounded-info-btn ma-0 pa-0" density="compact" variant="outlined"
            color="primaryTextColor" @click="closeUpgradeDialog">
          </v-btn></v-col>
      </v-row>
      <v-card v-if="pricingModels.showQuarnteeMessage" class="message-card" elevation="0"
        :class="{'mt-2': $vuetify.display.smAndDown}"
      >
        <v-card-text class="pa-0 ma-0">
          <v-row class="align-center pa-0 ma-0" justify="space-between">
            <v-col :cols="$vuetify.display.xs ? 12 : 9" class="pa-0 ma-0">
              <v-row v-if="pricingModels.quarnteeMessageTitle?.length>0" class="t-h125 pa-0 ma-0 mb-3">{{ pricingModels.quarnteeMessageTitle }}</v-row>
              <v-row v-if="pricingModels.quarnteeMessageSubTitle?.length>0" class="t-1remReg text-grey pa-0 ma-0 mb-2">{{ pricingModels.quarnteeMessageSubTitle }}</v-row>
              <v-row v-if="pricingModels.quarnteeMessageTnCMessage?.length>0" class="pa-0 ma-0"><span class="t-1remMed text-primaryPurple pointer-cursor" @click="openTnCPage()">{{ pricingModels.quarnteeMessageTnCMessage }}</span></v-row>
            </v-col>
            <v-col cols="auto" class="justify-end d-flex pa-0 ma-0 pr-2">
              <v-img v-if="pricingModels.quarnteeMessageIcon?.length>0" class="img-block" :src="getImagSrc(pricingModels.quarnteeMessageIcon)"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- <v-row>
        <v-col cols="12" align="center" justify="center" class="ma-0 pa-0 mb-12">
          <span class="text-white main-title">Upgrade Learning</span>
        </v-col>
       

      </v-row> -->
      <v-row v-if="userPackages?.length>1" class="switch-plan-block mt-4 mb-4">
          <v-col cols="auto" v-for="model, index in userPackages" :key="index+'_'" class="pa-0 ma-0 px-3 py-2" :class="{'selected-plan': selectedIndex == index}"
          @click="selectPlan(index, model)"
          >
            {{ model.packageType }}
          </v-col>
      </v-row>
      <div v-else style="height: 84px;"></div>
      <v-row class="ma-0 pa-0" align="start" justify="center">
        <v-col cols="12" sm="6" md="4" v-for="model, index in packageItems" :key="index">
          <v-card class="elite-card" :class="{ 'selected-card': selectedPlanIndex == index }"
            @click="selectItem(index, model)" elevation="0">
            <v-card-text class="pa-0">
              <v-container class="px-5 py-6 ma-0">
                <v-row dense align="center" justify="space-between">
                  <v-col class="t-h5">{{ model.name }}</v-col>
                  <!-- <v-spacer></v-spacer> -->
                  
                </v-row>
                <v-row dense align="center" class="mt-3">
                  <v-col cols="auto" class=" py-0 price-text  " :class="{
                    'text-red text-decoration-line-through': model.discountedPrice
                  }">
                    {{ getFormattedPrice(model.price, model.currency) }}</v-col>
                  <v-col cols="auto" class="price-text pa-0 ma-0 ml-2 " v-if="model.discountedPrice">{{
                    getFormattedPrice(model.discountedPrice ? model.discountedPrice : model.price, model.currency)
                  }}</v-col>
                  <v-col cols="auto" class="t-h6 text-grey mr-1">{{ model.priceDurationText }}</v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" align-self="end" v-if="purchasedPackages.includes(model?.packageId) || model.recommended"
                    class="t-h6 text-primaryPurple" :class="{ 'recommended-text text-white': !purchasedPackages.includes(model?.packageId) && model.recommended}">{{ purchasedPackages.includes(model?.packageId) ? 'Subscribed' : 'Recommended' }}</v-col>
                </v-row>
                <v-row v-if="model.discountedPrice" class="pa-0">
                  <v-col cols="auto" class="py-0 pr-1 t-para text-grey">Save:</v-col>

                  <v-col cols="auto" class="pa-0 t-para text-green">{{ (model.saveText ?? model.discountPercentage) ?? ""
                  }}</v-col>
                </v-row>
                <v-row dense :class="{ 'mt-5': !model.discountedPrice }">
                  <v-col class="t-1remReg mt-5">What’s Included ?</v-col>
                </v-row>


                <v-row dense align="center" class="mt-4" v-for="feature, index in model.features" :key="index">
                  <v-col cols="auto">
                    <v-avatar size="25" class="text-center" color="primaryPurple">
                      <v-icon :size="20" icon="mdi-check" color="fff"></v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="t-h6">{{ feature.title }}
                    <span v-if="'subData' in feature">
                      <br /><span class="text-grey">{{ feature.subData }}</span>
                    </span>

                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="mt-2 t-subtitleGrey text-center" v-if="pricingModels.upgradeExistingPackageMessage?.length>0">
        {{ pricingModels.upgradeExistingPackageMessage }}
      </div>

      <v-row class="mt-3" dense>
        <v-col align="center" justify="center">
          <v-btn class="t-btn text-white continue-btn text-none" width="250" density="default" variant="flat"
            :disabled="purchasedPackages.includes(selectedPlan?.packageId) || this.selectedPlanIndex==-1" @click="purchasePackage">
            Continue</v-btn>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>

    <v-card v-else elevation="0" class="upgrade-card justify-center align-center pa-4 pb-8">
      <v-card-text class="pa-0 w-100">
        <v-row class="ma-0 pa-0">
          <v-col class="pa-0" justify="end" align="end"><v-btn icon="mdi-close"
              class="t-btn text-none rounded-info-btn ma-0 pa-0" density="compact" variant="outlined"
              color="primaryTextColor" @click="closeUpgradeDialog">
            </v-btn></v-col>
        </v-row>
        <v-row justify="center" align="center" class="pa-0 ma-0">
          <v-col cols="12" class="ma-0 pa-0">
            <v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="88"
              src="@/assets/upgrade_plan.png"></v-img>
          </v-col>
          <v-col cols="10" class="t-1remReg text-center text-white ma-0 pa-0 mt-3">
            {{ pricingModels.allowStudentsToBuyPackage ? 'Contact admin to upgrade plan' : pricingModels.message }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useDisplay } from "vuetify";
import { mapState } from 'vuex';
import { findIndex } from 'lodash';
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
export default {
  name: "SubscriptionUpgradeDialog",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    dataLoaded: false,
    selectedPlanIndex: -1,
    selectedPlan: null,
    purchasedPackages: [],
    selectedIndex: -1,
    packageItems: []
  }),
  async created() {
    console.log("created called here")
    this.dataLoaded = false;
    const checkSub = await this.$store.dispatch("createCheckSub", {})
    this.purchasedPackages = checkSub;
    this.$store.commit("updatePricingModels", {});
    this.$store.commit("pricingModelsLoadedFromServer", false);
    await this.$store.dispatch("getPricingModels", {courseId: this.courseId });
    this.dataLoaded = true;
    if(this.userPackages.length>0){
      this.selectedIndex = findIndex(this.userPackages, {packageType: this.defaultSelectedTab});
      this.packageItems = this.userPackages[this.selectedIndex].packages;
      if(this.packageItems.length>0){
        this.selectedPlanIndex = findIndex(this.packageItems, {recommended: true});
        this.selectedPlanIndex= (this.selectedPlanIndex >=0) ? this.selectedPlanIndex : 0;
        this.selectedPlan = this.packageItems[this.selectedPlanIndex];
      }
    }
  },
  async mounted() {
    // if(this.userPackages.length>0){
    //   this.selectedIndex = findIndex(this.userPackages, {packageType: this.defaultSelectedTab});
    //   this.packageItems = this.userPackages[this.selectedIndex].packages
    //   if(this.packageItems.length>0){
    //     this.selectedPlanIndex = findIndex(this.packageItems, {recommended: true});
    //     this.selectedPlanIndex= (this.selectedPlanIndex >=0) ? this.selectedPlanIndex : 0;
    //     this.selectedPlan = this.packageItems[this.selectedPlanIndex];
    //   }
    // }
  },
  // watch: {
    // openUpgradeDialog:function(newVal, oldval){
    //   if(newVal!=oldval && newVal){
    //     this.$store.dispatch("getPricingModels", {courseId: this.courseId }).then(()=>{});
    //     this.$store.dispatch("createCheckSub", {}).then((val)=>{
    //       this.purchasedPackages = val;
    //     })
    //   }
    // }
  // },
  computed: {
    ...mapState(["openUpgradeDialog", "pricingModels", "pricingModelsLoadedFromServer", "courseId", "childUserId"]),
    pricingModelsComputed: {
      get() {
        let data = this.packageItems ?? []
        data = data.filter((e) => e.priceType != 'free')
        return data
      }
    },
    userPackages: {
      get(){
        const userPackages = this.pricingModels.userPackages ?? [];
        const filterUserPackage = [];
        for( let userPackage of userPackages){
          filterUserPackage.push({
            ...userPackage,
            packages: userPackage.packages.filter((e) => e.priceType != 'free')
          })
        }
        return filterUserPackage;
      }
    },
    defaultSelectedTab: {
      get(){
        let defaultPackageType = this.pricingModels.defaultPackageType;
        const foundModel = this.userPackages.find((model)=> (model.packageType == defaultPackageType));
        if(!foundModel){
          defaultPackageType = this.userPackages.length > 0 ? this.userPackages[0].packageType : "";
        }
        return defaultPackageType;
      }
    }
  },
  methods: {
    getImagSrc(path) {
      return this.$store.state.app_s3url + path;
    },
    selectPlan(index, model) {
      this.selectedIndex = index;
      this.packageItems = model.packages;
      if(this.packageItems.length>0){
        this.selectedPlanIndex = findIndex(this.packageItems, {recommended: true});
        this.selectedPlanIndex= (this.selectedPlanIndex >=0) ? this.selectedPlanIndex : 0;
        this.selectedPlan = this.packageItems[this.selectedPlanIndex];
      }
    },
    getFormattedPrice(price, currency) {
      const userLocale = navigator.language || navigator.userLanguage || 'en-US'; // Default to 'en-US' if the browser doesn't provide the user's locale.
      const formatter = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: currency // Replace with the appropriate default currency code
      });
      const formattedCurrency = formatter.format(price); // Replace 1000 with your desired number
      return formattedCurrency

    },
    async selectItem(index, model) {
      this.selectedPlanIndex = index;
      this.selectedPlan = model;
    },
    closeUpgradeDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogClose, {});
      this.$store.commit('openUpgradeDialog', false);
    },

    async purchasePackage() {
      recordAnalyticsEvent(AnalyticsEvents.v2PricingDialogPackageUpgradeButtonClicked, {
        packageId: this.selectedPlan.packageId,
      });
      const packageIds = [
        this.selectedPlan.packageId
      ];
      let userId = this.childUserId ?? null;
      console.log("userid ", this.childUserId, userId)
      const response = await this.$store.dispatch("createCheckoutSession", { "packages": packageIds, "route": this.$route.path, "userId": userId  })
      if (response["url"]) {
        window.open(response["url"], "_self");
        // this.$store.commit('openUpgradeDialog', false);
      }
    },
    openTnCPage(){
      if(this.pricingModels.quarnteeMessageTnCLink?.length){
        window.open(this.pricingModels.quarnteeMessageTnCLink);
      }
    }
  },
};
</script>

<style scoped>
.rounded-info-btn {
  border-radius: 50% !important;
}

.main-lg {
  padding-top: 20px;
  /* padding-left: 17%;
    padding-right: 17%; */
}

.main-md {
  padding-top: 30px;
  /* padding-left: 10%;
    padding-right: 10%; */
}

.message-card{
  background: rgb(var(--v-theme-background2));
  border-radius: 10px;
  color: white;
  padding: 16px;
  margin: 0 5%;
}

.switch-plan-block{
  width: fit-content;
  background: #2A2941;
  border-radius: 8px;
  color: white;
  padding: 6px;
  margin: 0 auto;
  cursor: pointer;
}

.selected-plan{
  border-radius: 8px;
  background: rgb(var(--v-theme-primaryPurple));
}

.img-block{
  width: 84px !important;
  height: 84px !important;
}

.main-title {
  font-family: Inter;
  font-size: 1.625rem;
  font-weight: 700;
}

.starter-card {
  border-radius: 10px;
  background: #3f3f58;
  color: white;
  border: 2px solid #3f3f58;
  /* width: 315px; */
}

.advanced-card {
  border-radius: 10px;
  background: #2a2941;
  color: white;
  border: 2px solid #2a2941;
  /* width: 315px; */
}

.elite-card {
  border-radius: 10px;
  background: #2a2941;
  color: white;
  border: 2px solid #2a2941;
  /* width: 315px; */
}

.selected-card {
  border-color: rgb(var(--v-theme-primaryPurple));
}

.most-popular-plan {
  padding: 6px 10px !important;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
}

.price-text {
  font-family: "Inter", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.recommended-text{
  background-color: #FBBC05 !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  color: white !important;
}

.most-popular-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 12px;
}

.normal-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
  background: transparent;
}

.continue-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
}

.upgrade-card {
  /* width: 360px; */
  border-radius: 12px;
  /* border: 3px solid #ab77ff; */
  background: #232238;
}

.add_on-title {
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.add_on_feature {
  border-radius: 12px;
  border: 2px solid #9461fc;
  background: #2d2c42;
}
</style>

<style>
.subscriptionDialogScrollbar::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.subscriptionDialogScrollbar::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.subscriptionDialogScrollbar::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.subscriptionDialogScrollbar::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}


</style>
