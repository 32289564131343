import _ from "lodash";

export default {
  startTestInfo(state) {
    return state.startTestInfo;
  },
  loading(state) {
    return state.loading;
  },

  apiBaseUrl(state) {
    return state.app_apiURL;
  },
  currentTestId(state) {
    return state.currentTestId
  },
  currentChallengeId(state) {
    return state.currentChallengeId
  },
  currentDomainName(state) {
    return state.userCurrentDomainName;
  },
  testRoutingAllowed(state) {
    return state.testRoutingAllowed
  },
  ablyChannel(state) {
    return state.ablyChannel
  },
  ablyRealTime(state) {
    return state.ablyRealTime
  },
  firebaseInfo(state) {
    return state.firebaseInfo
  },
  defaultOrganizationIdForFlavor(state) {
    return state.app_defaultOrganizationIdForFlavor;
  },
  selectedTest(state) {
    return state.selectedTest;
  },
  username(state) {
    return state.userState.name;
  },
  config(state) {
    state.config
  },
  isQuestionReviewer(state) {
    var reviewLevels = state.userState.reviewLevels;
    var retVal = false;
    if (state.config.questionReviewAllowed === "true"
      && reviewLevels
      && (reviewLevels.indexOf(1) > -1
        || reviewLevels.indexOf(2) > -1
        || reviewLevels.indexOf(3) > -1)
    ) {
      retVal = true;
    }
    return retVal;
  },
  isLevel1Reviewer(state) {
    var reviewLevels = state.userState.reviewLevels;
    var retVal = false;
    if (reviewLevels && reviewLevels.indexOf(1) > -1) {
      retVal = true;
    }
    return retVal;
  },
  isLevel2Reviewer(state) {
    var reviewLevels = state.userState.reviewLevels;
    var retVal = false;
    if (reviewLevels && reviewLevels.indexOf(2) > -1) {
      retVal = true;
    }
    return retVal;
  },
  isLevel3Reviewer(state) {
    var reviewLevels = state.userState.reviewLevels;
    var retVal = false;
    if (reviewLevels && reviewLevels.indexOf(3) > -1) {
      retVal = true;
    }
    return retVal;
  },

  //self help guide
  selfHelpGuideScript(state) {
    return state.selfHelpGuideData;
  },
  showSelfHelpGuideFeature(state, getters){
    return (!!getters.selfHelpGuideScript && !_.isEmpty(getters.selfHelpGuideScript)) && !_.isEmpty(getters.currentSelfHelpStepDetail);
  },
  showSelfHelpGuideCompute(state, getters){
    return state.showSelfHelpGuide && !!state.currentScreenName && (state.loaderCount == 0) && getters.showSelfHelpGuideFeature; 
  },
  currentSelfHelpStepDetail(state, getters){
    return getters.selfHelpGuideScript?.[state.currentScreenName]?.[state.currentSelfHelpStepId] ?? null;
  },
  currentHighlightedElements(state, getters){
    let obj = {};
    obj.highlightedElements = _.cloneDeep(getters.currentSelfHelpStepDetail?.highlightedElements) ?? [];
    obj.currentScreenName = state.currentScreenName;
    obj.showSelfHelpGuide = getters.showSelfHelpGuideCompute;
    return obj;
  },
  prevSelfHelpStepId(state, getters){
    return getters.currentSelfHelpStepDetail?.prevStepId ?? null;
  },
  nextSelfHelpStepId(state, getters){
    return getters.currentSelfHelpStepDetail?.nextStepId ?? null;
  },

};
