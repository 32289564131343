<template>
  <v-container v-if="studentsDetailLoaded" fluid class="assignment-card">
    <v-row class="mb-6 ma-0">
      <v-col cols="12" class="pa-0">
        <v-card class="card-bg text-white" elevation="0">
          <v-card-text>
            <v-row align="center" justify="space-around" class="">
              <v-col cols="auto" class="heading"> Filter </v-col>
              <v-divider vertical class="mx-4"></v-divider>
              <v-col class="pa-1 pr-3">
                <v-text-field density="compact" variant="solo" flat label="Search by Name / Contact Details"
                  append-inner-icon="mdi-magnify" class="search-underline" bg-color="report-subCardBg"
                  hide-details="true" v-model="searchFilter" v-on:input="debounceSearch" single-line></v-text-field>
              </v-col>
              <v-col cols="auto" class=" pa-0 mx-4">
                <div class="filter-dropdown">
                  <v-select class="filter-box" density="compact" :items="sortList" v-model="sortCategory" label="Sort by"
                    item-title="sortName" item-value="sortId" variant="outlined" hide-details prepend-inner-icon="mdi-sort">
                  </v-select>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="paginatedStudentsList.length > 0" >
      <template v-for="(item, i) in paginatedStudentsList" :key="i">
        <v-card class="card-bg card-border px-2 mb-3 py-2" @click="openStudentDetail(item)" elevation="0">
          <v-card-text class="text-primaryTextColor pa-0">
            <v-row justify="space-between" align="center" class="ma-0 px-1">
  
              <!-- name -->
              <v-col cols="12" sm="4" class="d-flex align-center justify-start pa-1">
                <div class="mr-4">
                  <v-avatar size="34" color="report-subCardBg" class="icon-bg">
                    <v-icon icon="mdi-account" color="#ffffff" class="" size="20"></v-icon>
                  </v-avatar>
                </div>
                <div class="pb-0 wrap-text">
                  <span v-if="item.studentName != 'null'" class="t-1remReg">{{ item.studentName }}</span>
                  <span v-else class="t-1remReg"> - </span>
                </div>
              </v-col>
  
              <!-- contact -->
              <v-col cols="4" class="d-flex flex-column align-start pa-1">
                <div v-if="showPhoneNumber(item.phoneNumber)" class="pb-0 wrap-text">
                  <span class="t-h6Reg">{{ item.phoneNumber }}</span>
                </div>
                <div v-if="showEmail(item.email)" class="wrap-text">
                  <span class="t-h6Reg">{{ item.email }}</span>
                </div>
              </v-col>
  
              <!-- class -->
              <v-col cols="2" class="d-flex flex-column align-start pa-1">
                <v-tooltip location="top" max-width="400px">
                  <template #activator="{ props }">
                    <div class="pb-0" v-bind="props">
                      <span class="t-h6Reg text-capitalize">{{
                        getAssignedTo(item)
                      }}</span>
                    </div>
                    <div class="">
                      <span class="t-h6Reg parameter-style">Assigned To</span>
                    </div>
                  </template>
                  <span>{{getAssignedToComplete(item)}}</span>
                </v-tooltip>
              </v-col>
  
              <!-- last online -->
              <v-col cols="2" class="d-flex flex-column align-start pa-1">
                <div class="pb-0">
                  <span class="t-h6Reg">{{ getTime(item.lastLoginTimeStamp) }}</span>
                </div>
                <div class="">
                  <span class="t-h6Reg parameter-style">Last Online</span>
                </div>
              </v-col>
  
  
  
            </v-row>
  
          </v-card-text>
        </v-card>
      </template>
    </template>

    <v-card v-if="students.length <= 0" class="card-bg card-border px-2 mb-3 py-2" @click="openStudentDetail(item)" elevation="0">
      <v-card-text class="text-primaryTextColor pa-0">
        <v-row justify="space-between" align="center" class="ma-0 px-1">
          <v-col cols="12" class="d-flex pa-3 align-center justify-center pa-1">
            <div class="pb-0 wrap-text">
              <span class="t-h6Reg">No Student Assigned</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="totalPages>1" class="ma-0" justify="center">
      <v-col cols="auto" class="pa-0">
        <v-pagination v-model="currentPageNumber" :length="totalPages" total-visible="5" size="small" color="white" rounded="lg"></v-pagination>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment'
import _ from "lodash";
export default {
  name: 'ClassStudentsListComponent',
  props: ["batchId"],
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
    return { xs, smAndUp, mdAndUp, lgAndUp };
  },
  async created() {
    this.studentsDetailLoaded = false;
    this.$store.commit("updateStudentName", "");
    this.$store.dispatch('loadBatchStudentList', { batchId: this.batchId }).then(res => {
      this.studentsDetailLoaded = true;
    })
    recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenOpen, {courseId: this.courseId, fromClass: true, batchId: this.batchId});
  },
  data: () => ({
    studentsDetailLoaded: false,
    options: "",
    searchFilter: '',
    sortCategory: null,
    sortList: [
      { sortName: "Name (A to Z)", sortId: "nameAsc" },
      { sortName: "Name (Z to A)", sortId: "nameDesc" },
      { sortName: "Email (A to Z)", sortId: "emailAsc" },
      { sortName: "Email (Z to A)", sortId: "emailDesc" },
      { sortName: "Last Online (Recent)", sortId: "onlineRecent" },
      { sortName: "Last Online (Oldest)", sortId: "onlineOldest" }
    ],
    currentPageNumber: 1,
    itemsPerPage: 100,
  }),
  methods: {
    debounceSearch() {
      if (this.searchFilter.trim() == "") {
        console.log("empty");
        this.$store.commit("clearSearchedStudentList");
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // your action
        if (this.searchFilter.trim() != "") {
          console.log("searched is ", this.searchFilter);
          recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenStudentSearched, {
            courseId: this.courseId,
            searchedKeyword: this.searchFilter.trim(),
            fromClass: true,
            batchId: this.batchId,
          });
          this.$store.dispatch("loadSearchedStudentList", {
            search: this.searchFilter.trim()
          });
        }
      }, 700); // delay
    },
    getTime(time) {
      if (time == 0) {
        return '-'
      }
      let vardata = moment(time).fromNow()
      return vardata;
    },
    openStudentDetail(item) {
      let studentId = item.userId;
      recordAnalyticsEvent(AnalyticsEvents.v2TeacherViewStudentScreenStudentOpen, {
        courseId: this.courseId,
        studentId: studentId,
        fromClass: true,
        batchId: this.batchId,
      });
      this.$store.commit("updateStudentName", item.studentName);
      console.log("current path is ", this.$route)
      this.$router.push(`${this.$route.fullPath}/student/${studentId}/1`);
    },
    showPhoneNumber(item){
      let phoneNumber= item+"";
      return !phoneNumber.includes('null')
    },
    showEmail(item){
      let email = item+"";
      return !email.includes('null')
    },
    getAssignedTo(item){
      let initialName = "", count = 0;
      if(item.assignedToTutor!= null && item.assignedToTutor.length > 0){
        initialName = item.assignedToTutor[0].tutorName;
        count = item.assignedToBatches?.length + item.assignedToTutor?.length -1;
        if(count > 0)
          return `${initialName}, + ${count}`;
        else
          return `${initialName}`;
      }
      else if(item.assignedToBatches!= null && item.assignedToBatches.length > 0){
        initialName = item.assignedToBatches[0].batchName;
        count = item.assignedToBatches?.length + item.assignedToTutor?.length -1;
        if(count > 0)
          return `${initialName}, + ${count}`;
        else
          return `${initialName}`;
      }
      else{
        return '-';
      }
    },
    getAssignedToComplete(item){
      let namesList1 = [], namesList2= [];
      if((item.assignedToBatches == null || item.assignedToBatches.length == 0) && (item.assignedToTutor == null || item.assignedToTutor.length == 0)){
        return 'Student is not assigned to any tutor or class';
      }
      if(item.assignedToTutor != null && item.assignedToTutor.length > 0){
        namesList1 = item.assignedToTutor.map((e) => e.tutorName);
      }
      if(item.assignedToBatches != null &&item.assignedToBatches.length > 0){
        namesList2 = item.assignedToBatches.map((e) => e.batchName);
      }
      let namesList = [...namesList1, ...namesList2];
      return namesList.join(", ")
    },
    paginatedData(students){
      const start = (this.currentPageNumber-1)*this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return students.slice(start, end);
    },
  },
  computed: {
    ...mapState(["studentList", "searchedStudentList"]),
    students: {
      get() {
        let students = [];
        if (this.searchFilter.trim() != "") {
          students = this.searchedStudentList;
          console.log("here", students)
        }
        else {
          students = this.studentList;
        }
        switch (this.sortCategory) {
          case "nameAsc":
            students = _.orderBy(students, [student => student?.studentName?.toLowerCase()], "asc");
            break;
          case "nameDesc":
            students = _.orderBy(students, [student => student?.studentName?.toLowerCase()], "desc");
            break;
          case "emailAsc":
            students = _.orderBy(students, [student => student?.email?.toLowerCase()], "asc");
            break;
          case "emailDesc":
            students = _.orderBy(students, [student => student?.email?.toLowerCase()], "desc");
            break;
          case "onlineRecent":
            students = _.orderBy(students, [student => student?.lastLoginTimeStamp], "desc");
            break;
          case "onlineOldest":
            students = _.orderBy(students, [student => student?.lastLoginTimeStamp], "asc");
            break;

          default:
            students = _.orderBy(students, [student => student?.studentName?.toLowerCase()], "asc");
        }
        return students;
      },
    },
    totalPages: {
      get(){
        return Math.ceil(this.students.length/this.itemsPerPage);
      }
    },
    paginatedStudentsList: {
      get(){
        return this.paginatedData(this.students);
      }
    },
  },
  watch: {
    options: {
      handler() {
        let itemsPerPage = this.options.itemsPerPage;
        let page = this.options.page;
        let max = itemsPerPage * page;
        let length = this.studentsData.length;
        console.log(length / max);
      },
      deep: true
    },
    sortCategory: {
      handler(){
        this.currentPageNumber = 1;
      },
      deep: true
    },
    students: {
      handler(){
        this.currentPageNumber = 1;
      }
    }
  }



}
</script>

<style scoped>
.assignment-card {
  border-radius: 12px !important;
}

.main-lg {
  max-width: 1020px;
  margin: 20px auto;
}

.main-md {
  padding-top: 20px;
  padding-left: 1%;
  padding-right: 1%;
}

.card-bg {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px;

}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover{
  border: 2px solid #9461FC;
}

.icon-bg{
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
}

.search-underline {
  border-bottom: 1px solid #ffffff62;
}

.heading {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 1.25;
}

.parameter-style {
  opacity: 0.4;
}

.wrap-text {
  /* Apply CSS to make text wrap */
  white-space: pre-line;
  /* Apply CSS to break words */
  word-break: break-word; /* or word-break: break-all; */
}

.filter-dropdown {
  width: 150px !important;
}
</style>