<template>
    <div>
        <NewPopup />
    </div>
</template>

<script>
    import NewPopup from "../newLogin/NewPopup.vue";
    export default {
        name: 'WelcomeMento',
        components: {NewPopup}
    }
</script>

<style scoped>

</style>