<template>
  <v-overlay persistent :model-value="downloadingTest" class="align-center justify-center">

    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="text-h6 font-weight-bold text-center text-white" cols="12">
        Downloading Test
      </v-col>
      <v-col cols="12">
        <v-progress-linear color="deep-purple-accent-4" indeterminate rounded height="6"
          :style="{width: $vuetify.display.xs ? '250px':'500px'}"></v-progress-linear>
      </v-col>
    </v-row>

  </v-overlay>
  <v-container fluid v-if="!downloadingTest" class="h-100">




    <v-app-bar color="breadCrumbBackColor">

      <v-toolbar-title class="ml-10 fontClass  text-white">{{ currentTest.testmetadata.testName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="backToTests" class="mr-4  text-white"> mdi-close</v-icon>

    </v-app-bar>

    <v-alert type="error" title="Test Cannot Be Started" v-if="currentTest.error != null && currentTest.error.length > 0"
      class="mx-8 mt-4" :text="currentTest.error"></v-alert>


    <section v-if="downloadingTest == false" class="mainSection">

      <v-card elevation="0" class="my-2 pa-5 testCard">
        <v-card-text>
          <v-row
            dense
            align="center"
          >
            <v-col cols="1" class="pa-0 ma-0">
              <v-avatar :size="$vuetify.display.xs? 28: 46" >
                <v-img
                  :height="$vuetify.display.xs? 28: 46"
                  src="@/assets/test_practice.png"
                />
              </v-avatar>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="t-h6Reg  text-white">
              <v-btn
                append-icon="mdi-play"
                class="t-btn text-white activity-btn text-none px-3"
                density="default"
                variant="flat"
                :loading="startTestButtonLoading"
                :disabled="startButtonDisabled"
                @click="openTestQuestion()"
                >
                <template v-slot:append>
                  <v-icon  color="white"></v-icon>
                </template>
                {{isResumeTest? 'Resume Test': 'Start Test'}}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <HtmlRender
              class="text-instruction"
              :html-string="currentTest.testmetadata.instructions"
            ></HtmlRender>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="0" class="my-2 mt-5 pa-5 testCard">
        <v-card-text class="text-white">
          <v-row>
            <span class="t-h5">Scores</span>
          </v-row>
          <v-row>
            <span class="mt-2 t-1remReg">After the practice test, view your score breakdown and receive personalised study tips for better efficiency and higher scores.</span>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="0" class="my-2 mt-5 pa-5 testCard">
        <v-card-text class="text-white">
          <v-row>
            <span class="t-h5">No Lockdown mode </span>
          </v-row>
          <v-row>
            <span class="mt-2 t-1remReg">We don’t lock down practice tests. On test day, Bluebook prevents you from accessing other programs or apps.</span>
          </v-row>
        </v-card-text>
      </v-card>


    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import HtmlRender from "@/components/Test/HtmlRender";
import axios from "axios";
import {get, isEmpty} from "lodash";
import { getLocalForageItem, setLocalForageItem } from "@/services/localforageService";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: "StartMockTestPage",

  async created() {
    if(!('assignment'  in this.$route.query))
      {
        this.$store.commit('updateCurrentAssignmentId', "")
        this.$store.commit('showStudentAssignmentSolution', true);

      }
    this.$store.commit("clearCurrentTestState");

    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
    this.$store.commit("showNotification", false);
    this.$store.commit("startTestLoadedFromServer", false);

    // console.log(this.$route);
    if ("testId" in this.$route.params) {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestInstructionScreenOpen, { testId: this.$route.params.testId });
      const test = this.$route.params.testId;
      this.currentTestId = test;
      // let practice = "false";
      // if ("practice" in this.$route.query) {
      //   practice = this.$route.query.practice;
      //   this.isPractice = practice === "true" ? true : false;
      // }
      this.$store.commit("updateCurrentTestId", test);
      // console.log(test);
      this.downloadingTest = true;
      let qResponses = (await getLocalForageItem("qResponses")) ?? [];
      let qDurations = (await getLocalForageItem("qDurations")) ?? [];
      let serverLastAttemptSavedTimeStamp =
        (await getLocalForageItem("serverLastAttemptSavedTimeStamp")) ?? "";
      let testInstanceId = get(this.selectedTest, 'testInstanceId', '')
      
      let body = {
        qDurations: qDurations,
        qResponses: qResponses,
        serverLastAttemptSavedTimeStamp: serverLastAttemptSavedTimeStamp,
        testInstanceId: testInstanceId,
      };

      this.$store
        .dispatch("startTestModule", { testId: test, moduleId: this.selectedTest.moduleId,  ...body })
        .then(async () => {
          this.currentTest = this.startTestInfo;
          if (this.currentTest.success == false) {
            this.downloadingTest = false;
          } else {
            const testJson = await axios.get(this.currentTest.moduleUrl);
            console.log(testJson.data);
            this.$store.commit("setTestJson", testJson.data);
            this.$store.commit("updateQResponsesState");

            if(!isEmpty(testInstanceId)){
              this.isResumeTest = true;
            }
            setLocalForageItem("qDurations", []);
            setLocalForageItem("qResponses", []);
            setLocalForageItem("serverLastAttemptSavedTimeStamp", "");
            this.downloadingTest = false;
            this.startButtonDisabled = false;
          }
        });
    }
  },
  data: () => ({
    downloadingTest: false,
    startButtonDisabled: true,
    startTestButtonLoading: false,
    currentTestId: "",
    isPractice: null,
    isResumeTest: false,
    currentTest: {
      moduleUrl: "",
      testmetadata: {
        allowSkippingQuestion: null,
        randomizedQuestionOrder: null,
        testDurationInMinutes: null,
        testRemainingDurationInMinutes: null,
        totalQuestion: null,
        instructions: "",
        syllabus: "",
        sections: [],
      },
    },
  }),
  components: {
    HtmlRender,
  },
  computed: {
    ...mapState(["syncTimerData","selectedTest"]),
    startTestInfo: {
      get() {
        const data = this.$store.state.startTestInfo;
        // console.log(data);
        return data;
      },
    },
  },

  watch: {},

  methods: {
    async openTestQuestion() {
      //call sync timer api
      this.startTestButtonLoading = true;
      let status = this.isResumeTest ? "resume" : "start";

    recordAnalyticsEvent(AnalyticsEvents.v2MockTestInstructionScreenBeginTest, {
      testId: this.currentTestId,
      testInstanceId: this.startTestInfo.testInstanceId,
    });
      // if (this.currentTest.alreadyInProgress == true) {
      //   status = "resume";
      // }

      // if (this.isPractice == true) {
      //   recordAnalyticsEvent(AnalyticsEvents.v2TestBeginPracticeTest, {
      //     testId: this.currentTestId,
      //     testName: this.startTestInfo.testmetadata.testName,
      //     testInstanceId: this.startTestInfo.testInstanceId,
      //   });
      // } else if (this.currentTest.alreadyInProgress == true) {
      //   recordAnalyticsEvent(AnalyticsEvents.v2TestResumeTest, {
      //     testId: this.currentTestId,
      //     testName: this.startTestInfo.testmetadata.testName,
      //     testInstanceId: this.startTestInfo.testInstanceId,
      //   });
      // } else {
      //   recordAnalyticsEvent(AnalyticsEvents.v2TestBeginTest, {
      //     testId: this.currentTestId,
      //     testName: this.startTestInfo.testmetadata.testName,
      //     testInstanceId: this.startTestInfo.testInstanceId,
      //   });
      // }

      this.$store.dispatch("updateModuleSyncTimer", { state: status }).then(() => {
        this.$store.commit("updateCurrentTestState", {
          sectionIndex: 0,
          qIndex: 0,
        });
        this.$store.commit("updateTestRoutingAllowed", true);
        this.$router.replace(`/testmodulequestionpage`);
        this.startTestButtonLoading = false;
      });
    },
    startTestText(item) {
      let text = "Begin test"
      if (item.alreadyInProgress == true) {
        text = "Resume test"
      }
      return text
    },
    backToTests() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestInstructionScreenCloseButtonClicked, {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId
        });
        if('assignment' in this.$route.query)
      {
        this.$router.replace('/action')
        return
      }
      this.$router.back()
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-pad {
  padding-top: 15px;
  padding-bottom: 30px;
}

.fontH6 {
  font-family: 'Inter', sans-serif !important;

}

.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.mainSection{
  margin-left: 25%;
  margin-right: 25%;
}

@media screen and (max-width: 900px) {
  .mainSection{
    margin-left: 20%;
    margin-right: 20%;
  }
};

@media screen and (max-width: 600px) {
  .mainSection{
    height: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.text-instruction{
  color: white !important;
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
}

</style>
  