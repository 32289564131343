import verFlavor from "./appFlavorAndVersion";

export function getFlavourBaseAPIURL() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return 'https://api-app.devtest.thestudypod.com/';

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return 'https://api-app.thestudypod.com/appurls/';
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return 'https://api-usapp.thestudypod.com/appurls/';

    default:
      return 'https://api-app.thestudypod.com/appurls/';
  }
}

export function getAppTitle() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return 'MentoMind Dev';

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return 'MentoMind';

    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return "MentoMind Educator";

    default:
      return 'MentoMind';
  }
}

export function getAppBarTitle() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return 'MentoMind Dev';

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return 'MentoMind';

    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return "MentoMind Educator";

    default:
      return 'MentoMind';
  }
}

export function getPDFJSExpViewerLicenseKey() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return 'vy8ly7iPNmESAMZUdScp';

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return 't1chO6EuoIoTH0PaEVzt';

    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return "t1chO6EuoIoTH0PaEVzt";

    default:
      return 'vy8ly7iPNmESAMZUdScp';
  }
}

export function getCognitoAuthConfigForAmplify() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return {
        identityPoolId: 'us-east-1:58debbd9-a5ed-476f-b932-cf3fd6446144',
        IdentityPoolName: 'flutter_identitypool_324f8dd7__dev',
        region: 'us-east-1'
      };

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return {
        identityPoolId: 'us-east-1:5fcb2703-c47c-43f4-ac58-f7efb1821892',
        IdentityPoolName: 'pinpointAnalytics_MentoApp',
        region: 'us-east-1'
      };

    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return {
        identityPoolId: 'us-east-1:5fcb2703-c47c-43f4-ac58-f7efb1821892',
        IdentityPoolName: 'pinpointAnalytics_MentoApp',
        region: 'us-east-1'
      };

    default:
      return {
        identityPoolId: 'us-east-1:58debbd9-a5ed-476f-b932-cf3fd6446144',
        IdentityPoolName: 'flutter_identitypool_324f8dd7__dev',
        region: 'us-east-1'
      };
  }
}

export function getPinPointConfig() {
  switch (verFlavor.APP_FLAVOR) {
    case verFlavor.FlavorType.DEV:
      return {
        appId: 'a74d147714aa49498730cb9fc60a7924', // Amazon Pinpoint App Client ID
        region: 'us-east-1', // Amazon service region
        mandatorySignIn: false,
      };

    case verFlavor.FlavorType.PROD:
    case verFlavor.FlavorType.MENTOMINDUSPROD:
    case verFlavor.FlavorType.B2C:
      return {
        appId: '2f3e8f5172294079af879eb27f35bcff', // Amazon Pinpoint App Client ID
        region: 'us-east-1', // Amazon service region
        mandatorySignIn: false,
      };

    case verFlavor.FlavorType.DEMOPROD:
    case verFlavor.FlavorType.MMEDUCATOR:
      return {
        appId: '2f3e8f5172294079af879eb27f35bcff', // Amazon Pinpoint App Client ID
        region: 'us-east-1', // Amazon service region
        mandatorySignIn: false,
      };

    default:
      return {
        appId: 'a74d147714aa49498730cb9fc60a7924', // Amazon Pinpoint App Client ID
        region: 'us-east-1', // Amazon service region
        mandatorySignIn: false,
      };
  }
}