<template>
	<!-- Quiz Loader -->
	<v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="downloadingTest"
		class="align-center justify-center px-2">
		<v-card elevation="0" class="start-quiz-loading d-flex justify-center align-center pa-2">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
						src="@/assets/mento_loading_icon.png"></v-img>
				</v-col>
				<v-col cols="12" class="start-quiz-loading-title text-center">
					{{ downloadingTestTitle }}
				</v-col>
				<v-col class="mt-2" cols="6">
					<v-progress-linear color="progress" indeterminate rounded height="6"></v-progress-linear>
				</v-col>
			</v-row>
		</v-card>
	</v-overlay>

	<!-- Practice Report -->
	<v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="showPracticeReport"
		class="align-center justify-center px-2">
		<v-card elevation="0" class="practice-report-dialog pa-2">

			<v-card-actions class="justify-end pa-0">
				<v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-4" density="compact" variant="outlined"
					color="primaryTextColor" @click="closePractice(!checkTheProgress())">
				</v-btn>
			</v-card-actions>

			<v-card-text class="pa-0">
				<v-row justify="center" align="center">

					<v-col cols="12" class="pt-0 mt-n3 mt-sm-0 pb-sm-3 pb-0">
						<v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
							src="@/assets/mento_loading_icon.png"></v-img>
					</v-col>

					<template v-if="checkTheProgress()">

						<v-col cols="12" class="start-quiz-loading-title text-center">
							{{ practiceReport.quizMessageTitle }}
						</v-col>
						<v-col cols="12" class="start-quiz-loading-subtitle  pt-0 pb-sm-6 text-center">
							{{ practiceReport.quizMessageSubtitle }}
						</v-col>

						<v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
							@click="startTest({ activityId: practiceReport.recommendedActivityId }, 'skillUpgradeQuiz', true)">
							Start quiz
						</v-btn>

					</template>

					<template v-else>
						<v-col cols="12" class="start-quiz-loading-title text-center">
							{{ practiceReport.practiceMessageTitle }}
						</v-col>
						<v-col cols="12" class="start-quiz-loading-subtitle pt-0 pb-sm-6 text-center">
							{{ practiceReport.practiceMessageSubtitle }}
						</v-col>
						<v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
							@click="startPractice(true)">
							Start practice
						</v-btn>
					</template>
				</v-row>

			</v-card-text>
		</v-card>
	</v-overlay>

	<!-- Overlay for New Journey-->
	<v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="showStart"
		class="align-center justify-center px-2 mx-auto">
		<v-card elevation="0" class="decision-box d-flex justify-center align-center pa-2 flex-column"
			:width="smAndUp ? 544 : '95%'" :height="smAndUp ? 354 : '100%'">
			<v-card-text>
				<v-row justify="center" align="center">
					<v-col class="decision-box-img-col" cols="12">
						<v-img class="mx-auto" aspect-ratio="1" width="110" src="@/assets/mento_loading_icon.png"></v-img>
					</v-col>
					<v-col cols="12" class="d-flex justify-end align-center mb-0">
						<v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-0 mx-sm-4" density="compact"
							variant="outlined" color="primaryTextColor" @click="showStart = false">
						</v-btn>
					</v-col>
					<v-col cols="12" color="primaryTextColor" class="decision-box-title text-center pb-0">
						Choose Your Path
					</v-col>
					<v-col cols="12" class="decision-box-subtitle text-center">
						Let's find the best place to start
					</v-col>
					<v-col cols="12" class="options ma-0 pa-0 d-flex align-center flex-sm-row flex-column justify-center">
						<v-hover v-slot="{ isHovering, props }">
							<v-sheet @click="startPractice()" :width="smAndUp ? 221 : '100%'" :height="smAndUp ? 142 : '100%'"
								class="ma-2 mt-2 px-4 py-7 decision-box-options d-flex justify-center align-center"
								:class="{ 'on-hover': isHovering }" v-bind="props">
								<v-row justify="center" class="decision-box-options-row" align-content="center">
									<v-col class="ma-0 pa-0 text-center" cols="12" md="10">
										<v-avatar class="ma-0" size="56px" rounded="0">
											<v-img src="@/assets/practice_assets/sat_journey_icon.svg"></v-img>
										</v-avatar>
									</v-col>
									<v-col class="ma-0 pa-0" cols="12">
										<v-card-title class="decision-box-options-title ma-0 pa-0 text-center text-wrap mt-4">
											Start from scratch
										</v-card-title>
									</v-col>
								</v-row>
							</v-sheet>

						</v-hover>

						<v-hover v-slot="{ isHovering, props }">
							<v-sheet @click="startTest(newLearnNodeActivity.skillVerificationQuiz, 'skillVerificationQuiz')"
								:width="smAndUp ? 221 : '100%'" :height="smAndUp ? 142 : '100%'"
								class="ma-2 mt-2 px-4 py-7 decision-box-options d-flex justify-center align-center"
								:class="{ 'on-hover': isHovering }" v-bind="props">
								<v-row justify="center" class="decision-box-options-row" align-content="center">
									<v-col class="ma-0 pa-0 text-center" cols="12">
										<v-avatar class="ma-0" size="56px" rounded="0">
											<v-img src="@/assets/practice_assets/know_your_skill_icon.svg"></v-img>
										</v-avatar>
									</v-col>
									<v-col class="ma-0 pa-0" cols="12">
										<v-card-title class="decision-box-options-title ma-0 pa-0 text-center text-wrap mt-4">
											Find my starting point
										</v-card-title>
									</v-col>
								</v-row>
							</v-sheet>
						</v-hover>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-overlay>

	<!-- Overlay for Warning -->
	<v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="showWarning"
		class="align-center justify-center px-2">
		<v-card elevation="0" class="warning-prompt-box d-flex justify-center align-center pa-2 pa-sm-4 "
			:width="smAndUp ? 544 : '95%'" :height="smAndUp ? '100%' : '100%'">
			<v-card-text>
				<v-row justify="center" align="center">
					<v-col cols="12" class="d-flex justify-end align-center mb-0 pt-0">
						<v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-0" density="compact" variant="outlined"
							color="primaryTextColor" @click="showWarning = false">
						</v-btn>
					</v-col>
					<v-col cols="12" class="pt-0">
						<v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="100"
							src="@/assets/mento_loading_icon.png"></v-img>
					</v-col>
					<v-col cols="12" sm="12" class="warning-prompt-title text-center pt-0">
						Great choice exploring further! Remember, our AI recommends {{ showWarningText() }} to best suit your current
						learning pathway. Feel free to take this or stick with our personalized recommendation to build up your skills
						step by step.
					</v-col>
					<v-col class="mt-2 text-center pt-0" cols="6">
						<v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat"
							@click="startTestFromWarn()">
							Start quiz
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-overlay>

	<!-- NAVBAR -->
	<v-row v-if="activityLoaded" class="ma-0 pt-4 pl-md-4" :class="{ 'heightBread': $vuetify.display.mdAndUp }" justify="space-between">
		<!-- BREADCRUMB -->
		<v-col class="ma-0 pa-0">
      <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor breadcrumb-radius" size="x-small"
            @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
				<template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
		<!-- INFO COMPONENT -->
		<v-col cols="auto" class="mr-4">
			<!-- <info-component :infoTopic="infoTopic"></info-component> -->
			<info-component :infoTopic="infoTopic" :infoDesc="infoDesc"></info-component>
			<SelfHelpGuideTogglerComponent class="ml-4"/>
		</v-col>
		<v-divider></v-divider>
	</v-row>

	<!-- MAIN -->
	<v-container v-if="activityLoaded" fluid class="ma-0 pa-4 mb-10">
		<v-row justify="space-between" align="start" class="mx-md-10 mx-lg-16">
			<v-col>
				<v-row class="mb-8">
					<v-col class="pb-0" cols="12">
						<span class="text-white t-h125"> {{ newLearnNodeActivity.topicName }}: Your Step-by-Step Learning Pathway
						</span><br />
					</v-col>
					<v-col class="pb-0" cols="12">
						<span class="t-1remReg text-white"> Unlock Levels, Adaptive Learning, Achieve Mastery </span><br />
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="auto">
				<v-btn v-if="mdAndUp" prepend-icon="mdi-poll" class="t-btn text-white report-btn text-none mr-4" density="default"
					variant="flat" @click="getAllReports(newLearnNodeActivity)">
					<template v-slot:prepend>
						<v-icon color="primaryTextColor"></v-icon>
					</template>
					Check All Reports
				</v-btn>

				<v-btn v-if="!mdAndUp" icon="mdi-poll" class="report-btn activity-btn-mobile text-white text-none mr-2"
					variant="elevated" density="compact" @click="getAllReports(newLearnNodeActivity)">
				</v-btn>
			</v-col>
		</v-row>


		<!-- Large Screen -->
		<div :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''" v-if="activityLoaded && newLearnNodeActivity && smAndUp"
			class="relative-position">
			<div>
				<!-- Beginner -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="info-card"
									:class="[getBoxHighlight(beginner_no) ? 'info-card-beginner' : 'info-card-normal']">
									<v-card-text class="pa-2 text-center text-sm-left">
										<span class="t-1rem">Beginner </span>
										<span v-if="smAndUp" class="t-1remReg"> - Learn the basics and Foundational concepts</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="4" sm="2" class="ma-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center">
													<v-img height="76" :src="getSkillLevelIcon(beginner_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(beginner_no, 'start')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(beginner_no, 'start') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start" @click="startJourney()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>
									<span class="t-h6Bold">Start Journey</span>

								</div>
							</v-col>

						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="5" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${beginnerHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-beginner"></div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(beginner_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(beginner_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>


				<!-- Intermediate -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="info-card"
									:class="[getBoxHighlight(intermediate_no) ? 'info-card-intermediate' : 'info-card-normal']">
									<v-card-text class="pa-2 text-center text-sm-left">
										<span class="t-1rem">Intermediate </span>
										<span v-if="smAndUp" class="t-1remReg"> - Build on your knowledge and skills.</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="4" sm="2" class="ma-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(intermediate_no, newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(intermediate_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>

								<v-card v-if="!mdAndUp && newUserShowWarningInPractice" elevation="0"
									class="warn-user-card warn-user-mobile">
									<v-card-text class="pa-2 pa-lg-2 text-center">
										<v-row>
											<v-col cols="12" class="px-0 pb-0">
												<span><v-icon icon="mdi-information"></v-icon></span>
											</v-col>
											<v-col class="pt-2">
												<span class="t-h6Reg"> Feel free to explore, but remember, AI recommendations lead to the best
													preparation! </span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(intermediate_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(intermediate_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[0], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span v-if="smAndUp" class="t-h6Bold">Start Quiz</span>
									<span v-else class="t-h6Bold">Start</span>
								</div>
							</v-col>

							<v-col v-if="mdAndUp && newUserShowWarningInPractice" cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="warn-user-card warn-user-card-normal">
									<v-card-text class="pa-2 pa-lg-2 text-center text-sm-left">
										<v-row>
											<v-col cols="auto" class="pl-3 pr-0">
												<span><v-icon icon="mdi-information"></v-icon></span>
											</v-col>
											<v-col>
												<span class="t-h6Reg"> Feel free to explore, but remember, AI recommendations lead to the best
													preparation! </span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="5" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${intermediateHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-intermediate">
										</div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(intermediate_no, 'practice')" cols="auto"
								class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(intermediate_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>

				<!-- Advanced -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="info-card"
									:class="[getBoxHighlight(advanced_no) ? 'info-card-advanced' : 'info-card-normal']">
									<v-card-text class="pa-2 pa-lg-2 text-center text-sm-left">
										<span class="t-1rem">Advanced </span>
										<span v-if="smAndUp" class="t-1remReg"> - Dive deeper into complex topics and techniques</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="4" sm="2" class="ma-0 pa-0 d-flex justify-center align-center relative-position">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(advanced_no, newLearnNodeActivity.skillUpgradeQuiz[1], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(advanced_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>

							</v-col>

							<v-col v-if="showQuizOrPracticeButton(advanced_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(advanced_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[1], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span v-if="smAndUp" class="t-h6Bold">Start Quiz</span>
									<span v-else class="t-h6Bold">Start</span>
								</div>
							</v-col>


						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="5" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${advancedHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-advanced"></div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(advanced_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(advanced_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>

				<!-- Mastery -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="info-card"
									:class="[getBoxHighlight(mastery_no) ? 'info-card-mastery' : 'info-card-normal']">
									<v-card-text class="pa-2 pa-lg-2 text-center text-sm-left">
										<span class="t-1rem">Mastery </span>
										<span v-if="smAndUp" class="t-1remReg"> - Become an expert in the topic area</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="4" sm="2" class="ma-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(mastery_no, newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(mastery_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(mastery_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(mastery_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span v-if="smAndUp" class="t-h6Bold">Start Quiz</span>
									<span v-else class="t-h6Bold">Start</span>
								</div>
							</v-col>
							<v-col v-if="showQuizOrPracticeButton(mastery_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(mastery_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span v-if="smAndUp" class="t-h6Bold">Practice More</span>
									<span v-else class="t-h6Bold">Practice</span>
								</div>
							</v-col>

						</v-row>
					</v-sheet>


				</div>
			</div>

		</div>
		<!-- Small Screen -->
		<div :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''" v-if="activityLoaded && newLearnNodeActivity && !smAndUp"
			class="relative-position">
			<div>
				<!-- Beginner -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							
							<v-col offset="1" cols="4" class="my-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center">
													<v-img height="76" :src="getSkillLevelIcon(beginner_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(beginner_no, 'start')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(beginner_no, 'start') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start" @click="startJourney()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>
									<span class="t-h6Bold">Start Journey</span>

								</div>
							</v-col>

						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="2" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${beginnerHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-beginner"></div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(beginner_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(beginner_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
									
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>


				<!-- Intermediate -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col offset="1" cols="4" class="my-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(intermediate_no, newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(intermediate_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>

								<v-card v-if="!mdAndUp && newUserShowWarningInPractice" elevation="0"
									class="warn-user-card warn-user-mobile">
									<v-card-text class="pa-2 pa-lg-2 text-center">
										<v-row>
											<v-col cols="12" class="px-0 pb-0">
												<span><v-icon icon="mdi-information"></v-icon></span>
											</v-col>
											<v-col class="pt-2">
												<span class="t-h6Reg"> Feel free to explore, but remember, AI recommendations lead to the best
													preparation! </span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(intermediate_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(intermediate_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[0], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Start Quiz</span>
								</div>
							</v-col>

							<v-col v-if="mdAndUp && newUserShowWarningInPractice" cols="4" sm="5" class="ma-0 pa-0">
								<v-card elevation="0" class="warn-user-card warn-user-card-normal">
									<v-card-text class="pa-2 pa-lg-2 text-center text-sm-left">
										<v-row>
											<v-col cols="auto" class="pl-3 pr-0">
												<span><v-icon icon="mdi-information"></v-icon></span>
											</v-col>
											<v-col>
												<span class="t-h6Reg"> Feel free to explore, but remember, AI recommendations lead to the best
													preparation! </span>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="2" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${intermediateHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-intermediate">
										</div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(intermediate_no, 'practice')" cols="auto"
								class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(intermediate_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>

				<!-- Advanced -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col offset="1" cols="4" class="my-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(advanced_no, newLearnNodeActivity.skillUpgradeQuiz[1], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(advanced_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>

							</v-col>

							<v-col v-if="showQuizOrPracticeButton(advanced_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(advanced_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[1], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Start Quiz</span>
								</div>
							</v-col>


						</v-row>
					</v-sheet>

					<v-sheet class="sheet-bg-none progress-bar-sheet">
						<v-row class="ml-0 ml-sm-n2">
							<v-col cols="2" offset="2" class="pa-0 progress-bar-col d-flex justify-center">
								<div class="progress-bar-outer-div">
									<div class="progress-bar-inner-div" :style="{ height: `${advancedHeight}%` }">
										<div class="progress-bar-innermost-div progress-bar-innermost-div-advanced"></div>
									</div>
								</div>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(advanced_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(advanced_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble practice-speech-bubble"
									:style="{ top: 'calc(' + getButtonHeight() + '% - 23px)' }" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Continue Practice</span>
								</div>
							</v-col>
						</v-row>
					</v-sheet>

				</div>

				<!-- Mastery -->
				<div>
					<v-sheet class="sheet-bg-none">
						<v-row justify="start" align="center" class="row-z-index ml-0 ml-sm-n2">
							<v-col offset="1" cols="4" class="my-0 pa-0 d-flex justify-center align-center">
								<v-card elevation="0" class="sheet-bg-none">
									<v-card-text>
										<v-row>
											<v-col class="pa-0">
												<v-avatar v-ripple.center size="76" class="text-center badges"
													@click="checkStartQuizForBadge(mastery_no, newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
													<v-img height="76" :src="getSkillLevelIcon(mastery_no)" />
												</v-avatar>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>

							<v-col v-if="showQuizOrPracticeButton(mastery_no, 'quiz')" cols="auto" class="ma-0 pa-0"
								:class="{ 'add-animation': showQuizOrPracticeButton(mastery_no, 'quiz') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start"
									@click="startTest(newLearnNodeActivity.skillUpgradeQuiz[2], 'skillUpgradeQuiz')">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Start Quiz</span>
								</div>
							</v-col>
							<v-col v-if="showQuizOrPracticeButton(mastery_no, 'practice')" cols="auto" class="ma-0 pa-0 practice-col"
								:class="{ 'add-animation': showQuizOrPracticeButton(mastery_no, 'practice') && this.showButton }">
								<div v-ripple.center class="speech-bubble speech-bubble-start" @click="startPractice()">
									<div class="ai-recommended t-h6Bold">
										<v-icon icon="mdi-star-four-points" class="star-icon-style"></v-icon>
										<span> AI Recommended</span>
									</div>

									<span class="t-h6Bold">Practice More</span>
									
								</div>
							</v-col>

						</v-row>
					</v-sheet>


				</div>
			</div>

		</div>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import axios from "axios";
import _ from "lodash";
import { getLocalForageItem, setLocalForageItem } from "@/services/localforageService";
import InfoComponent from "../infoComponent/InfoComponent.vue";
import {
  breadcrumbHandler,
} from "@/services/breadcrumbHandler";
import SelfHelpGuideTogglerComponent from '@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue';
export default {
	name: "LearnActivitiesPage",
	props: ["entityId"],
	components: {
		InfoComponent, SelfHelpGuideTogglerComponent
	},
	setup() {
		// Destructure only the keys we want to use
		const { xs, lg, xl, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

		return { xs, lg, xl, smAndUp, mdAndUp, lgAndUp, xlAndUp };
	},
	async beforeRouteUpdate(to, from) {
		// react to route changes...
		console.log("route changed to", to);
		console.log("route changed from", from);
	},
	async created() {
		this.$store.commit('appbarVisible', true);
		this.$store.commit('navbarVisible', true);
		if (this.entityId && this.userCurrentDomain) {
			this.$store.commit("updateCurrentTopicId", this.entityId);
			this.$store
				.dispatch("loadNewLearnNodeActivity", { entityId: this.entityId })
				.then((res) => {
					if (!_.isEmpty(res)) {
						breadcrumbHandler(this);
						this.activityLoaded = true;
						console.log("State", this.newLearnNodeActivity);
						this.infoTopic = this.newLearnNodeActivity.topicName;
						this.infoDesc = this.newLearnNodeActivity.topicDescription;

						if (localStorage.getItem("newUserShowWarningInPractice") == "true") {
							this.showNewUserWarning = true;
							localStorage.setItem("newUserShowWarningInPractice", false);
						}


						if (this.showPracticeReport == true && !_.isEmpty(this.practiceReport)) {
							this.calculateInitialValue();
							this.showInitialValues();
						}
						else {
							this.calculateFinalValue();
							this.showFinalValues();
						}
						
					}
					recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityScreenOpen, {
						topicId: this.newLearnNodeActivity.topicId,
						topicName: this.newLearnNodeActivity.topicName,
						currentSkill: this.newLearnNodeActivity.skill,
					});
				});

		}

	},
	data: () => ({
		activityLoaded: false,
		downloadingTest: false,
		showStart: false,
		downloadingTestTitle: "Unpacking this quiz!",
		totalMarks: 100,
		currentTestId: "",
		currentTest: {
			"testurl": "",
			"testmetadata": {
				"allowSkippingQuestion": null,
				"randomizedQuestionOrder": null,
				"testDurationInMinutes": null,
				"testRemainingDurationInMinutes": null,
				"totalQuestion": null,
				"instructions": "",
				"syllabus": "",
				"sections": [
				]

			}
		},
		currentPractice: {

		},
		skillVerificationIcon: "assets/images/skill_verification_wand.svg",
		activityItem: {
			topicId: "t_1623135116091",
			topicName: "Properties of Solids and Liquids",
			skill: "",
			skillVerificationQuiz: {
				activityId: "A1",
				name: "Word in context",
			},
			skillUpgradeQuiz: [
				{
					activityId: "A2",
					status: "",
					skillLevelNeededToAchiveMilestone: "Intermediate",
					order: 1,
					attempted: false,
				},
				{
					activityId: "A3",
					status: "",
					skillLevelNeededToAchiveMilestone: "Advanced",
					order: 2,
					attempted: false,
				},
				{
					activityId: "A4",
					status: "",
					skillLevelNeededToAchiveMilestone: "Expert",
					order: 3,
					attempted: false,
				},
			],
		},
		infoTopic: '',
		infoDesc: '',
		breadcrumbItems: [],
		beginner_no: 0,
		intermediate_no: 1,
		advanced_no: 2,
		mastery_no: 3,
		showWarning: false,
		warnTestObj: null,
		initialHeightValue: 30,
		initialRecommendedSkill: '',
		finalRecommendedSkill: '',
		animationStarted: false,
		showButton: false,
		newUserShowWarningInPractice: false,
		finalHeightValue: 0,
		initialScore: 30,
		finalScore: 0,
		beginnerHeight: 0,
		intermediateHeight: 0,
		advancedHeight: 0,
		transitionDuration: 1000, // Duration of the transition

	}),

	methods: {
		checkSkill(type, level) {
			if (!level || level == undefined || level == null) {
				return null;
			}
			level = level.toLowerCase();
			let string = '';
			if (type == 'skillVerification') {
				return 's-0';
			}

			if (type == 'quiz') {
				string = 'q-';
			}
			if (type == 'practice') {
				string = 'p-';
			}

			if (level == 'beginner') {
				string += '0';
			}
			else if (level == 'intermediate') {
				string += '1';
			}
			else if (level == 'advanced') {
				string += '2';
			}
			else if (level == 'mastery') {
				string += '3';
			}
			return string;

		},
		calculateInitialValue() {
			let oldSkillLevel = this.practiceReport.oldPracticeSkillLevel;
			let oldSkillValue = this.practiceReport.oldPracticeSkillScoreToNextLevel;
			if (oldSkillLevel == 'beginner' || oldSkillLevel == 'notset') {
				this.initialScore = 0 + (oldSkillValue * 100);
			}
			else if (oldSkillLevel == 'intermediate') {
				this.initialScore = 100 + (oldSkillValue * 100);
			}
			else if (oldSkillLevel == 'advanced') {
				this.initialScore = 200 + (oldSkillValue * 100);
			}
			else if (oldSkillLevel == 'mastery') {
				this.initialScore = 300;
			}
		},
		calculateFinalValue() {
			let x = this.currentRecommendedSkill;
			let xType = this.currentRecommendedSkill.charAt(0);
			let finalSkillLevel;
			let finalSkillValue = this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel;
			if (xType == 'p') {
				finalSkillLevel = this.currentPracticeSkillLevel;
			}
			else {
				finalSkillLevel = this.currentRecommendedSkill;
			}

			let skillType = finalSkillLevel.charAt(0);
			let skillIndex = Number(finalSkillLevel.charAt(2));

			if (skillType == 'p') {
				if (skillIndex == 0) {
					this.finalScore = 0 + (finalSkillValue * 100);
				}
				else if (skillIndex == 1) {
					this.finalScore = 100 + (finalSkillValue * 100);
				}
				else if (skillIndex == 2) {
					this.finalScore = 200 + (finalSkillValue * 100);
				}
				else if (skillIndex == 3) {
					this.finalScore = 300;
				}
			}

			if (skillType == 'q') {
				if (skillIndex == 0) {
					this.finalScore = 0;
				}
				else if (skillIndex == 1) {
					this.finalScore = 100;
				}
				else if (skillIndex == 2) {
					this.finalScore = 200;
				}
				else if (skillIndex == 3) {
					this.finalScore = 300;
				}
			}

		},
		getSkillLevelIcon(currentIndex) {
			let currentRecommendedSkill = this.currentRecommendedSkill;
			let skillType = currentRecommendedSkill.charAt(0);
			let skillIndex = Number(currentRecommendedSkill.charAt(2));

			let icon = "assets/practice_assets/medal_beginner_enable.svg";

			let enabled_icons = [
				"assets/practice_assets/medal_beginner_enable.svg",
				"assets/practice_assets/medal_intermediate_enable.svg",
				"assets/practice_assets/medal_advance_enable.svg",
				"assets/practice_assets/medal_mastery_enable.svg",
			]
			let disabled_icons = [
				"assets/practice_assets/medal_beginner_enable.svg",
				"assets/practice_assets/medal_intermediate_disable.svg",
				"assets/practice_assets/medal_advance_disable.svg",
				"assets/practice_assets/medal_mastery_disable.svg",
			]

			if (currentIndex < skillIndex) {
				icon = enabled_icons[currentIndex];
			}
			else if (currentIndex == skillIndex && skillType == 'p') {
				icon = enabled_icons[currentIndex];
			}
			else {
				icon = disabled_icons[currentIndex];
			}
			return this.getIconSrc(icon);
		},
		getBoxHighlight(currentIndex) {
			let currentRecommendedSkill = this.currentRecommendedSkill;
			let skillType = currentRecommendedSkill.charAt(0);
			let skillIndex = Number(currentRecommendedSkill.charAt(2));
			let bool = false;
			if (currentIndex < skillIndex) {
				bool = true;
			}
			else if (currentIndex == skillIndex && skillType == 'p') {
				bool = true;
			}
			else {
				bool = false;
			}
			return bool;
		},
		showInitialValues() {
			this.showButton = false;
			const initial = Math.min(300, Math.max(0, this.initialScore));
			this.beginnerHeight = (initial <= 100) ? initial : 100;
			this.intermediateHeight = (initial > 100) ? (initial <= 200) ? initial - 100 : 100 : 0;
			this.advancedHeight = (initial > 200) ? initial - 200 : 0;
		},
		showFinalValues() {
			const final = Math.min(300, Math.max(0, this.finalScore));
			this.beginnerHeight = (final <= 100) ? final : 100;
			this.intermediateHeight = (final > 100) ? (final <= 200) ? final - 100 : 100 : 0;
			this.advancedHeight = (final > 200) ? final - 200 : 0;
			this.showButton = true;
		},

		getButtonHeight() {
			let height = this.finalScore % 100;
			return height;
		},
		startJourney() {
			return this.showStart = true;
		},
		checkStartQuizForBadge(currentIndex, item, activityType, fromPractice = false) {
			let currentRecommendedSkill = this.currentRecommendedSkill;
			let skillType = currentRecommendedSkill.charAt(0);
			let skillIndex = Number(currentRecommendedSkill.charAt(2));

			if (currentIndex <= skillIndex) {
				return this.startTest(item, activityType, fromPractice = false);
			}
			else {
				this.warnTestObj = [
					item,
					activityType,
					fromPractice
				]
				return this.showWarningPrompt();
			}
		},
		showWarningPrompt() {
			return this.showWarning = true;
		},
		startTestFromWarn() {
			if (this.warnTestObj == null || this.warnTestObj == [] || this.warnTestObj == {} || _.isEmpty(this.warnTestObj)) {
				console.log("no test found");
				this.showWarning = false;
			}
			else {
				this.startTest(this.warnTestObj[0], this.warnTestObj[1], this.warnTestObj[2]);
				this.warnTestObj = [];
				this.showWarning = false;
			}
		},
		showWarningText() {
			let currentRecommendedSkill = this.currentRecommendedSkill;
			let skillType = currentRecommendedSkill.charAt(0);
			let skillIndex = Number(currentRecommendedSkill.charAt(2));
			let msg = '';

			let labels = [
				'beginner', 'intermediate', 'advanced', 'mastery'
			];

			if (skillType == 'p') {
				msg = 'you to practice first';
			}
			else {
				msg = `the ${labels[skillIndex]} level quiz`;
			}

			return msg;
		},
		showQuizOrPracticeButton(currentIndex, buttonType) {
			let currentRecommendedSkill = this.currentRecommendedSkill;
			let skillType = currentRecommendedSkill.charAt(0);
			if (skillType == 'p') {
				currentRecommendedSkill = this.currentPracticeSkillLevel;
			}
			else {
				currentRecommendedSkill = this.currentRecommendedSkill;
			}
			skillType = currentRecommendedSkill.charAt(0);
			let skillIndex = Number(currentRecommendedSkill.charAt(2));
			let bool = false;

			if (buttonType == 'start' && currentIndex == skillIndex && skillType == 's') {
				bool = true;
			}
			else if (buttonType == 'quiz' && currentIndex == skillIndex && skillType == 'q') {
				bool = true;
			}
			else if (buttonType == 'practice' && currentIndex == skillIndex && skillType == 'p') {
				bool = true;
			}
			else {
				bool = false;
			}

			if (this.showPracticeReport == true || this.showButton == false) {
				return false;
			}
			else return bool
		},
		// not in use
		getSkillName(skill) {
			skill = skill.toLowerCase();
			if (skill == "notset") {
				return "Get started";
			}
			else {
				return this.getCapitalizedText(skill);
			}
		},
		checkTheProgress() {
			if (!this.practiceReport.recommendedActivityId || this.practiceReport.recommendedActivityId == null || this.practiceReport.recommendedActivityId == "") {
				return false;
			}
			return true;
		},
		getAllReports(item) {
			this.$store.commit("updateCurrentTopicName", item.topicName);
      this.$store.commit("updateCurrentTopicSkill", item.skill);
			this.$router.push(`${this.$route.fullPath}/learnreports`);
		},
		getIconSrc(url) {
			return require(`@/${url}`);
		},
		async startTest(item, activityType, fromPractice = false) {
			if (fromPractice) {
				recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryStartQuizButtonClicked, {
					topicId: this.newLearnNodeActivity.topicId,
					topicName: this.newLearnNodeActivity.topicName,
					currentSkill: this.newLearnNodeActivity.skill,
					isReady: true,
					activityId: item.activityId,
					activityType: activityType,
					recommended: true
				});
			}
			else {
				recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityQuizStart, {
					topicId: this.newLearnNodeActivity.topicId,
					topicName: this.newLearnNodeActivity.topicName,
					currentSkill: this.newLearnNodeActivity.skill,
					isReady: true,
					activityId: item.activityId,
					activityType: activityType,
					attempted: item.attempted ? item.attempted : false,
					recommended: item.recommended
				});
			}
			this.downloadingTest = true;
			this.downloadingTestTitle = "Unpacking this quiz!"
			this.$store.commit("clearCurrentTestState")
			this.$store.commit('startTestLoadedFromServer', false)
			this.$store.commit("updateCurrentTestId", item.activityId);
			this.$store.commit("updateSkill", this.currentRecommendedSkill)

			let qResponses = await getLocalForageItem('qResponses') ?? [];
			let qDurations = await getLocalForageItem('qDurations') ?? [];
			let serverLastAttemptSavedTimeStamp = await getLocalForageItem('serverLastAttemptSavedTimeStamp') ?? '';

			let body = {
				qDurations: qDurations, qResponses: qResponses, serverLastAttemptSavedTimeStamp: serverLastAttemptSavedTimeStamp,
				testType: 'quiz'
			}
			this.$store.dispatch("startTest", { testId: item.activityId, ...body })
				.then(async () => {
					this.currentTest = this.startTestInfo;
					if (this.currentTest.success == false) {
						this.downloadingTest = false;
					} else {
						const testJson = await axios.get(this.currentTest.testurl);
						this.$store.commit("setTestJson", testJson.data);
						this.$store.commit("updateQResponsesState");

						await setLocalForageItem("qDurations", []);
						await setLocalForageItem("qResponses", []);
						await setLocalForageItem("serverLastAttemptSavedTimeStamp", "");
						this.downloadingTest = false;
						this.$store.commit("updateCurrentTestId", this.startTestInfo.testId);
						this.$store.commit("updateCurrentActivityId", item.activityId);
						this.currentTestId = this.startTestInfo.testId;
						this.openTestQuestion();
					}
				});
			this.$store.commit('showPracticeReport', false);
			this.$store.commit('getPracticeReport', {});
		},
		async startPractice(fromPractice = false) {
			this.downloadingTestTitle = "Unpacking this practice!";
			this.downloadingTest = true;
			let topicId = this.newLearnNodeActivity.topicId;
			if (fromPractice) {
				recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryStartNewPracticeButtonClicked, {
					topicId: this.newLearnNodeActivity.topicId,
					topicName: this.newLearnNodeActivity.topicName,
					currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
					currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
					recommended: true,
				});
			}
			else {
				recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityPracticeStart, {
					topicId: this.newLearnNodeActivity.topicId,
					topicName: this.newLearnNodeActivity.topicName,
					currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
					currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
					recommended: this.newLearnNodeActivity.practiceProgressDetails.recommended,
				});
			}

			this.$store.dispatch("startPractice", topicId)
				.then(async () => {
					this.currentPractice = this.practiceInfo;
					if (this.currentPractice.success == false) {
						console.log("not working");
						this.downloadingTest = false;
					}
					else {
						this.$store.commit("getTopicName", this.practiceInfo.testName);
						this.downloadingTest = false;
						this.$store.commit('appbarVisible', false);
						this.$store.commit('navbarVisible', false);
						this.$store.commit("showNotification", false);
						this.$store.commit("updateTestRoutingAllowed", true);
						this.$router.push(`/practicequestionpage`);
					}
				});

			this.$store.commit('showPracticeReport', false);
			this.$store.commit('getPracticeReport', {});
		},

		async openTestQuestion() {
			let status = "start"
			if (this.currentTest.alreadyInProgress == true) {
				status = "resume"
			}

			this.$store.commit('appbarVisible', false);
			this.$store.commit('navbarVisible', false);
			this.$store.commit("showNotification", false);
			this.$store.dispatch("updateSyncTimer", { state: status }).then(() => {
				this.$store.commit('updateCurrentTestState', {
					sectionIndex: 0,
					qIndex: 0
				})
				let remainingTime = this.$store.state.syncTimerData.testRemainingDurationInSeconds
				if (remainingTime == 0) {
					//   this.startButtonDisabled = true
					if ("message" in this.syncTimerData) {
						this.currentTest.error = this.syncTimerData.message
					}
					else {
						this.currentTest.error = "Test Time is Over"
					}
				}
				else {
					this.$store.commit("updateTestRoutingAllowed", true)
					this.$router.push(`/testquestionpage?type=quiz`)

				}
				// this.startTestButtonLoading = false
			})
		},
		back() {
			recordAnalyticsEvent(AnalyticsEvents.v2LearnTopicActivityBackButtonClicked, {
				topicId: this.entityId,
				topicName: this.newLearnNodeActivity.topicName,
				currentSkill: this.newLearnNodeActivity.skill,
			});
			let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
      this.$router.replace(page)
			this.$router.back();
		},
		breadCrumbRoute(item){
      this.$router.replace(item.pageRoute)
    },
		getPercentage(score) {
			let x = score * 100;
			return x < 0 ? 0 : x;
		},
		closePractice(fromPractice) {
			let recommendedPracticeOrQuiz = fromPractice ? 'practiceTest' : 'skillUpgradeQuiz';
			recordAnalyticsEvent(AnalyticsEvents.v2PracticeSummaryCloseButtonClicked, {
				topicId: this.newLearnNodeActivity.topicId,
				topicName: this.newLearnNodeActivity.topicName,
				currentPracticeSkill: this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill,
				currentPracticeSkillScore: this.newLearnNodeActivity.practiceProgressDetails.skillScoreToNextLevel,
				recommendedPracticeOrQuiz: recommendedPracticeOrQuiz,
			});

			this.$store.commit('showPracticeReport', false);
			this.$store.commit('getPracticeReport', {});
			// this.startAnimation();
		},
		startAnimation() {
			this.animationStarted = true;
		},
		async updateProgress() {
			const initial = Math.min(300, Math.max(0, this.initialScore));
			const final = Math.min(300, Math.max(0, this.finalScore));

			if (final > initial) {
				this.showBox = false;

				if (final > 100 && initial <= 100) {
					this.beginnerHeight = 100;
				}
				else if (final <= 100) {
					await this.delay(this.transitionDuration);
					this.beginnerHeight = final;
				}

				if (final > 100 && final <= 200) {
					await this.delay(this.transitionDuration);
					this.intermediateHeight = final - 100;
				}
				else if (final > 200 && initial < 200) {
					await this.delay(this.transitionDuration);
					this.intermediateHeight = 100;
				}

				if (final > 200) {
					await this.delay(this.transitionDuration);
					this.advancedHeight = final - 200;
				}

			}

			// ----------------------------------------
			else if (final < initial) {
				if (final > 200) {
					await this.delay(this.transitionDuration);
					this.advancedHeight = final - 200;
				}
				if (final <= 200 && initial > 200) {
					await this.delay(this.transitionDuration);
					this.advancedHeight = 0;
				}

				if (final <= 200 && final >= 100) {
					await this.delay(this.transitionDuration);
					this.intermediateHeight = final - 100;
				}
				if (final <= 100 && initial > 100) {
					await this.delay(this.transitionDuration);
					this.intermediateHeight = 0;
				}

				if (final < 100) {
					await this.delay(this.transitionDuration);
					this.beginnerHeight = final;
				}


			}
			this.showButton = true;
		},
		delay(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
	},
	computed: {
		...mapState(["newLearnNodes", "newLearnNodeActivity", "userCurrentDomain", "syncTimerData", "practiceReport", "showPracticeReport", "breadCrumbs"]),
		displayBreadCrumbs: {
      get() {				
        let breadCrumbs = this.breadCrumbs;
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        
        breadCrumbs[breadCrumbs.length-1].title = this.newLearnNodeActivity.topicName ?? "Topic";
        return breadCrumbs;
      }
    },
		learnNodeActivity: {
			get() {
				return this.newLearnNodeActivity;
			},
		},
		startTestInfo: {
			get() {
				const data = this.$store.state.startTestInfo
				return data
			}
		},
		practiceInfo: {
			get() {
				const data = this.$store.state.practiceInfo
				return data;
			}
		},
		currentRecommendedSkill: {
			get() {
				let item = '';
				let currentPracticeSkill = this.newLearnNodeActivity.skill.toLowerCase();
				if (currentPracticeSkill == 'notset' && this.newLearnNodeActivity.skillVerificationQuiz?.recommended == true) {
					item = 's-0';
					return item;
				}
				if (this.newLearnNodeActivity.practiceProgressDetails.recommended == true) {

					if (currentPracticeSkill == 'beginner') {
						item = 'p-0';
					}
					else if (currentPracticeSkill == 'intermediate') {
						item = 'p-1';
					}
					else if (currentPracticeSkill == 'advanced') {
						item = 'p-2';
					}
					else if (currentPracticeSkill == 'mastery') {
						item = 'p-3';
					}

					return item;
				}
				_.forEach(this.newLearnNodeActivity.skillUpgradeQuiz, function (value, index) {
					if (value.recommended == true) {
						item = `q-${index + 1}`
					}
				})

				return item;
			},
		},
		currentPracticeSkillLevel: {
			get() {
				let item = '';
				let currentPracticeSkill = this.newLearnNodeActivity.practiceProgressDetails.currentPracticeSkill.toLowerCase();

				if (currentPracticeSkill == 'beginner') {
					item = 'p-0';
				}
				else if (currentPracticeSkill == 'intermediate') {
					item = 'p-1';
				}
				else if (currentPracticeSkill == 'advanced') {
					item = 'p-2';
				}
				else if (currentPracticeSkill == 'mastery') {
					item = 'p-3';
				}

				return item;
			}

		},

	},
	watch: {
		newLearnNodeActivity() {
			console.log("Changed", this.newLearnNodeActivity);
		},
		showPracticeReport(val) {
			if (val == false) {
				this.calculateFinalValue();
				this.updateProgress();
			}
		}
	},
};
</script>

<style scoped>
/* New Style */

.btnStyle {
	border-radius: 12px !important;
	background: rgb(var(--v-theme-cardBackgroundColor));
	transition: all 0.3s ease;
	border: 4px solid rgb(var(--v-theme-primaryPurple)) !important;
	color: rgb(var(--v-theme-primaryTextColor)) !important;
	border-color: transparent !important;
	cursor: pointer;
}

.rounded-info-btn {
	border-radius: 50% !important;
}

.decision-box {
	border-radius: 12px;
	border: 3px solid rgb(var(--v-theme-practice-prompts-border));
	background: rgb(var(--v-theme-practice-prompts-background));
	margin: 0 auto;
	overflow: visible !important;
}

.decision-box-img-col {
	position: absolute;
	top: -65px;
}

.decision-box-title {
	color: rgb(var(--v-theme-primaryTextColor));
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
	/* 25px */
	letter-spacing: -0.25px;
}

.decision-box-subtitle {
	color: rgb(var(--v-theme-practice-decision-box-subtitle));
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.decision-box-options {
	width: 221px;
	height: 142px;
	border-radius: 12px;
	background: rgb(var(--v-theme-practice-prompts-option-box));
}

.decision-box-options-row {
	min-width: 125px;
}

.decision-box-options-title {
	color: rgb(var(--v-theme-primaryTextColor));
	text-align: center;
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.17px;
}

.badges {
	cursor: pointer;
}

.warning-prompt-box {
	border-radius: 12px;
	border: 3px solid rgb(var(--v-theme-practice-prompts-border));
	background: rgb(var(--v-theme-practice-prompts-background));
	margin: 0 auto;
}

.warning-prompt-title {
	font-size: 1.25rem;
	line-height: 1.25;
	color: rgb(var(--v-theme-primaryTextColor));
	font-weight: 400;
}

.on-hover {
	cursor: pointer;
	background: rgb(var(--v-theme-practice-prompts-option-box)) !important;
	color: rgb(var(--v-theme-primaryTextColor)) !important;
	filter: drop-shadow(0px 0px 25px rgba(var(--v-theme-practice-hover-drop-shadow)));
	border: 2px solid rgb(var(--v-theme-primaryPurple)) !important;
}

.sheet-bg-none {
	background-color: rgba(var(--v-theme-background) 00);
}

.relative-position {
	position: relative;
}

.progress-bar-sheet {
	margin-bottom: -18px;
}

.row-z-index {
	position: relative;
	z-index: 200;
}

.info-card {
	border-radius: 12px;
	color: rgb(var(--v-theme-primaryTextColor));
}

.info-card-normal {
	background: rgb(var(--v-theme-practice-info-card-background));
	border: 2px solid rgb(var(--v-theme-practice-info-card-border));
}

.info-card-beginner {
	background: rgb(var(--v-theme-practice-info-card-background-beginner));
	border: 2px solid rgb(var(--v-theme-practice-info-card-border-beginner));
}

.info-card-intermediate {
	border: 2px solid rgb(var(--v-theme-practice-info-card-border-intermediate));
	background: rgb(var(--v-theme-practice-info-card-background-intermediate));
}

.info-card-advanced {
	border: 2px solid rgb(var(--v-theme-practice-info-card-border-advanced));
	background: rgb(var(--v-theme-practice-info-card-background-advanced));
}

.info-card-mastery {
	border: 2px solid rgb(var(--v-theme-practice-info-card-border-mastery));
	background: rgb(var(--v-theme-practice-info-card-background-mastery));
}

.warn-user-card {
	border-radius: 12px;
	color: rgb(var(--v-theme-primaryTextColor));
	border: 1px solid rgb(var(--v-theme-practice-warn-prompt-border));
	background: #ea4b6733;
	animation: fadeOutAnimation ease 4s;
	animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 1
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.warn-user-mobile {
	position: absolute;
	top: 79px;
	width: 300px;
}

.speech-bubble {
	position: relative;
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
	border-radius: 12px;
	padding: 15px;
	width: 164px;
	height: 40px;
	color: rgb(var(--v-theme-primaryTextColor));
	z-index: 500;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
	cursor: pointer;
}

.speech-bubble:before {
	cursor: pointer;
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 16px 8px 0;
	border-color: transparent #AB77FF transparent transparent;
	left: -15px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 0;
}

.practice-speech-bubble {
	position: absolute;
	left: -20px;
}

.ai-recommended {
	position: absolute;
	top: -25px;
	color: rgb(var(--v-theme-ai-recommended-text));
}

.practice-col {
	position: relative;
}

.star-icon-style {
	font-size: 10px;
}

.speech-bubble p {
	margin: 0;
}

.progress-bar-col {
	position: relative;
	top: -3px;
	z-index: 0;
}

.progress-bar-outer-div {
	height: 157px;
	width: 12px;
	background: linear-gradient(90deg, #232238 29.94%, rgba(62, 61, 85, 0.00) 144.16%);
	border-radius: 40px;
	overflow: hidden;
}

.progress-bar-inner-div {
	width: 12px;
	background-color: rgb(var(--v-theme-background));
	overflow: hidden;
	border-radius: 40px;
	position: relative;
	transition: height 1000ms ease-in-out;
}

.progress-bar-innermost-div {
	height: 157px;
	width: 12px;
}

.progress-bar-innermost-div-beginner {
	background: linear-gradient(180deg, #8669B5 23.42%, #4A4CAE 91.72%);
}

.progress-bar-innermost-div-intermediate {
	background: linear-gradient(180deg, #4A4CAE 23.42%, #15725E 91.72%);
}

.progress-bar-innermost-div-advanced {
	background: linear-gradient(180deg, #15725E 6.31%, #603DA9 101.4%);
}
.recommended-container {
	position: relative;
	margin-top: 40px;
}

.recommended-card {
	/* position: relative; */
}

.recommended-box {
	position: absolute;
	top: -32px;
	right: 10px;
	background: rgba(255, 255, 255, 0.15);
	color: rgb(var(--v-theme-primaryTextColor));
	padding: 5px;
	border-radius: 8px 8px 0px 0px;
}

.activity-btn {
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
}

.activity-btn-desktop {
	width: 120px;
}

.report-btn {
	background: rgb(var(--v-theme-practice-prompts-background)) !important;
	border: 2px solid rgb(var(--v-theme-primaryPurple));
	letter-spacing: -0.15px;
}

.activity-btn-mobile {
	width: 40px;
	height: 30px;
}

.main-lg {
	padding-top: 20px;
	padding-left: 20%;
	padding-right: 20%;
}

.main-md {
	padding-top: 20px;
	padding-left: 10%;
	padding-right: 10%;
}



.v-sheet.on-hover {
	cursor: pointer;
	border-color: transparent;
	background-color: rgb(var(--v-theme-navColor));
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.v-btn {
	border-radius: 12px;
}

.breadcrumb-radius{
	border-radius: 50px;
}
.start-quiz-loading {
	border-radius: 12px;
	border: 3px solid rgb(var(--v-theme-practice-prompts-border));
	background: rgb(var(--v-theme-practice-prompts-background));
	color: rgb(var(--v-theme-primaryTextColor));
	border-radius: 15px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	height: 300px;
	width: 480px;
}

.practice-report-dialog {
	border-radius: 12px;
	border: 3px solid rgb(var(--v-theme-practice-prompts-border));
	background: rgb(var(--v-theme-practice-prompts-background));
	color: rgb(var(--v-theme-primaryTextColor));
	border-radius: 15px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	height: 320px;
	width: 480px;
}

.start-quiz-loading-title {
	font-weight: 700;
	letter-spacing: 0.05em;
	font-size: 25px !important;
}

.start-quiz-loading-subtitle {
	font-size: 0.875rem;
	font-weight: 600;
	color: rgb(var(--v-theme-practice-decision-box-subtitle));
}


.text-small {
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-size: 0.8rem !important;
}

.t-xs {
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-size: 0.75rem !important;
}

.recommended {
	background: rgba(255, 255, 255, 0.15);
	border-radius: 8px;
	font-size: 0.64rem;
}

.ai-chip {
	background: linear-gradient(91.85deg, #7946E1 0.46%, #1E24AD 99.41%);
	color: rgb(var(--v-theme-primaryTextColor));
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-weight: 700;
	line-height: 12.39px;
	font-size: 0.64rem !important;
	border-radius: 26px;
	padding: 4px;
}

.text-recommended {
	font-family: "Inter", sans-serif !important;
	font-style: normal !important;
	font-size: 0.64rem !important;
}

.br {
	border-radius: 24.5px;
}

.heightBread {
	height: 80px;
}

.v-overlay__content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.add-animation {
	animation: fadeInAnimation ease 1.5s;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}


@media only screen and (max-width: 600px) {
	.start-quiz-loading {
		height: 200px;
		width: 300px;
	}

	.practice-report-dialog {
		height: 240px;
		width: 300px;
	}

	.start-quiz-loading-img {
		width: 75px !important;
	}

	.start-quiz-loading-title {
		font-size: 20px !important;
	}

	.speech-bubble-start {
		width: 140px !important;
		margin-left: -5px !important;
	}

	.speech-bubble-start.speech-bubble:before {
		display: none;
	}

	.practice-speech-bubble {
		position: absolute;
		left: 0px;
	}

	.ai-recommended {
		font-size: 0.64rem !important;
	}

	.warning-prompt-title {
		font-size: 1.125rem;
	}
}

@media only screen and (max-width: 300px) {
	.practice-report-dialog {
		height: auto;
		padding-bottom: 28px !important;
		width: 95%;
		margin: 0 auto;
	}

	.start-quiz-loading {
		width: 95% !important;
		margin: 0 auto;
	}

	.warn-user-mobile {
		width: 250px;
	}
}
</style>