<template>
 <div >
<span></span>
<span></span>
<span></span>
</div>
       
</template>
  
<script>

import _ from "lodash";
import { useDisplay } from "vuetify";

export default {
    name: "ChatBotTyping",
    setup() {
    // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp } = useDisplay();

        return { xs, smAndUp, mdAndUp };
    },
    data: () => ({
    }),
    props: {
       
    },
    components: {},
    async created() {
        
    },
    computed: {
        
    },

    watch: {},

    methods: {

    },
};
</script>
  
<style scoped>
div {
  /* margin: 32px; */
  display:inline-block;
}
div span {
  width: 8px;
  height: 8px;
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
  display: inline-block;
  margin: 1px;
  border-radius: 50%;
}
div span:nth-child(1) {
  -webkit-animation: bounce 1s infinite;
          animation: bounce 1s infinite;
}
div span:nth-child(2) {
  -webkit-animation: bounce 1s infinite 0.2s;
          animation: bounce 1s infinite 0.2s;
}
div span:nth-child(3) {
  -webkit-animation: bounce 1s infinite 0.4s;
          animation: bounce 1s infinite 0.4s;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
  