import {
  storeTestId,
  storeUserInfo,
  storeUserOrganizationId,
  storeLoggedInFlag,
  logoutUser,
  storeUserCurrentDomain,
  isUserLoggedIn,
  getLoggedInUserInfo,
  getHeaders,
  setApiBaseUrl,
  getTeacherView,
  getAdminView,
  getCourseId,
  getSuperAdminView,
  storeCourseId,
  getParentView,
} from "@/services/authService";
import _ from "lodash";
var Ably = require("ably");
import verFlavor from "@/appFlavorAndVersion";
import moment from "moment";

export default {
  resetStateToDefault(state) {
    console.log(`resetStateToDefault called`);
    state.domainsList = []; //list of domians/goals in the organization
    state.userCurrentDomain = "";
    state.userCurrentDomainName = "";
    state.currentSubjectType = "readingandwriting";
    state.customersList = [];

    state.learnHierarchyChildNodes = new Map(); //maps of Learn hierechy nodes (app hierarchy nodes)
    state.cloudFrontCookiesResponse = "";

    state.loginError = ""; // indicates if any error is returned by sso token validation api
    state.navDrawer = true;
    state.loading = false;
    state.openUpgradeDialog = false;

    //test
    state.userTestCollections = [];
    state.userTestCollectionsLoadedFromServer = false;
    state.tests = [];
    state.testsLoadedFromServer = false;
    state.startTestLoadedFromServer = false;
    state.startTestInfo = {};
    state.currentTestJson = [];
    state.qResponsesState = [];
    state.currentTestState = {
      sectionIndex: 0,
      qIndex: 0,
    };

    //classes
    state.classesList = [];
    state.userMessages = [];
    state.classesListLoadedFromServer = false;
    state.userLoggedIn = "false";

    // new
    state.isProfileVisible = false;
    state.profileInfo = {};
    state.deleteProfileError = "";
    state.courseId = "";

    //practice
    state.practiceInfo = {};
    state.topicName = "";
    state.validatePracticeResponse = {};
    state.practiceReport = {};
    state.showPracticeReport = false;

    //teacher view
    state.studentPracticeTestReport = [];
    state.studentName = "";
    state.className = "";
    state.isTeacherViewSelected = false;
    state.isUserTeacher = false;
    state.studentList = [];
    state.searchedStudentList = [];
    state.teacherClassList = [];
    state.classStudentList = [];
    state.classPracticTests = [];
    state.assignmentReports = [];
    state.topicWiseReports = [];

    //admin dashboard
    (state.organizationDetails = {}), (state.currentTopicName = "");
    state.currentTopicSkill = "";

    state.linkSnackbarError = null;
    state.linkSnackbarState = false;

    state.adminStudentList = [];
    state.searchedAdminStudentList = [];
    state.studentPackages = [];

    state.tutorList = [];
    state.tutorPackages = [];
    state.searchedTutorList = [];

    state.classList = [];
    state.searchedClassList = [];

    state.packageList = [];

    // notification
    state.showNotification = false;
    state.notificationsList = [];

    // parent details
    state.captureParentInformation = false;
  },

  updateNavDrawer(state, val) {
    state.navDrawer = val;
  },

  loading(state, val) {
    state.loading = val;
  },

  openUpgradeDialog(state, val) {
    state.openUpgradeDialog = val;
  },

  updateCurrentQuizReport(state, val) {
    state.currentQuizReport = val;
  },

  setAPIUrlsForFlavorAndVersion(state, val) {
    state.app_defaultOrgnizationIdForFlavor = val.organizationId;
    state.app_apiURL = val.apiurl + "/";
    state.app_botConfigurationUrl = val.botConfigurationUrl;
    state.app_boturl = val.boturl;
    state.app_s3url = val.s3url + "/";
    state.app_s3privateUrl = val.s3privateUrl + "/";
    state.app_aiApiUrl = val.aiApiUrl??"https://aifunctions.devtest.thestudypod.com" + "/";
    if (val.domainId && val.domainId.length > 0) {
      state.userCurrentDomain = val.domainId;
      storeUserCurrentDomain(val.domainId);
    }
    if (val.organizationId && val.organizationId.length > 0) {
      storeUserOrganizationId(val.organizationId);
    }
    setApiBaseUrl(state.app_apiURL);
  },
  storeLoggedInUserDetails(state, val) {
    storeUserInfo(val);
    storeLoggedInFlag(true);
    state.userLoggedIn = "true";
    state.userState = val;
    if (val.domainId && val.domainId.length > 0) {
      state.userCurrentDomain = val.domainId;
      storeUserCurrentDomain(val.domainId);
      state.isTeacherViewSelected = val["isTeacher"];
      state.isUserTeacher = val["isTeacher"];
      state.isUserAdmin = val["isAdmin"];
      state.isUserSuperAdmin = val["isSuperAdmin"];
      state.courseId = val["courseId"];
      state.isUserParent = val["isParent"] ?? false;
    }
  },
  getLoggedInUserDetails(state) {
    state.userLoggedIn = isUserLoggedIn() ?? "false";
    if (state.userLoggedIn == "true") {
      state.appBarVisible = true;
      state.navDrawerVisible = true;
      state.showNotification = true;
      state.userState = getLoggedInUserInfo();
      state.isTeacherViewSelected = getTeacherView() == "true";
      state.isUserTeacher = getTeacherView() == "true";
      state.isUserAdmin = getAdminView() == "true";
      state.isUserSuperAdmin = getSuperAdminView() == "true";
      state.courseId = getCourseId();
      state.isUserParent = getParentView() == "true";
    }
  },
  updateShowBackground(state, val) {
    state.showBackground = val;
  },
  logout() {
    logoutUser();
  },
  setLoginError(state, val) {
    state.loginError = val;
  },
  clearLoginError(state) {
    state.loginError = "";
  },
  setExistingLoginError(state, val) {
    state.existingLoginError = val;
  },
  clearExistingLoginError(state) {
    state.existingLoginError = "";
  },
  setProfileDeleteError(state, val) {
    state.deleteProfileError = val;
  },
  storeUserCurrentDomain(state, val) {
    //console.log(`mutating userCurrentDomain: ${val}`);
    state.userCurrentDomain = val;
    storeUserCurrentDomain(val);
  },
  storeUserCurrentDomainName(state, val) {
    state.userCurrentDomainName = val;
  },
  storeUserCurrentDomainFromLocalStorage(state, val) {
    //console.log(`mutating userCurrentDomain from storeUserCurrentDomainFromLocalStorage: ${val}`);
    state.userCurrentDomain = val;
  },
  loadDomainsList(state, val) {
    state.domainsList = val;
  },

  clearLearnHierarchyNodes(state) {
    state.learnHierarchyChildNodes.clear();
  },

  loadLearnHierarchyChildNodes(state, val) {
    state.learnHierarchyChildNodes.set(val.parentNodeId, val.children);
  },
  loadNewLearnNodes(state, val) {
    state.newLearnNodes = val;
  },
  loadNewLearnNodeActivity(state, val) {
    state.newLearnNodeActivity = val;
  },
  clearNewLearnNodeActivity(state) {
    state.newLearnNodeActivity = {};
  },
  clearNewLearnNodes(state) {
    state.newLearnNodes = [];
  },
  updateCurrentSubjectType(state, val) {
    state.currentSubjectType = val;
  },
  loadUserTestCollections(state, val) {
    state.userTestCollections = val;
  },
  userTestCollectionsLoadedFromServer(state, val) {
    state.userTestCollectionsLoadedFromServer = val;
  },
  updateSelectedTest(state, val) {
    state.selectedTest = val;
  },
  updateCurrentTestId(state, val) {
    state.currentTestId = val;
  },
  updateCurrentActivityId(state, val) {
    state.currentActivityId = val;
  },
  updateActivityQuizReporst(state, val) {
    state.currentActivityQuizReports = val;
  },
  updateCurrentTopicId(state, val) {
    console.log("Updating topic Id", val);
    state.currentTopicId = val;
  },
  updateCurrentTopicName(state, val) {
    state.currentTopicName = val;
  },
  updateCurrentTopicSkill(state, val) {
    console.log("current skill is ", val);
    state.currentTopicSkill = val;
  },
  updateCloudFrontCookieResponse(state, val) {
    state.cloudFrontCookiesResponse = val;
  },
  updateSyncTimer(state, val) {
    state.syncTimerData = val;
  },
  updateSubmissionInfo(state, val) {
    state.submissionInfo = val;
  },
  updateTestRoutingAllowed(state, val) {
    state.testRoutingAllowed = val;
  },
  loadTests(state, val) {
    state.tests = val;
  },
  testsLoadedFromServer(state, val) {
    state.testsLoadedFromServer = val;
  },
  startTest(state, val) {
    state.startTestInfo = val;
  },
  startTestModule(state, val) {
    const sectionData = _.map(
      _.get(val, "testmetadata.sections", []),
      (section) => ({
        ...section,
        // qResponses: []
      })
    );
    _.set(val, "testmetadata.sections", sectionData);
    state.startTestInfo = val;
  },
  getTestReportData(state, val) {
    state.testReportData = val;
  },
  getTestInstancesData(state, val) {
    state.testInstancesData = val;
  },
  getTestCategoriesData(state, val) {
    state.testCategoriesData = val;
  },
  getTestTopicsData(state, val) {
    state.testTopicsData = val;
  },
  getPracticeInfo(state, val) {
    state.practiceInfo = val;
  },
  getTopicName(state, val) {
    state.topicName = val;
  },
  validatePractice(state, val) {
    state.validatePracticeResponse = val;
  },
  getPracticeReport(state, val) {
    state.practiceReport = val;
  },
  showPracticeReport(state, val) {
    state.showPracticeReport = val;
  },
  startTestLoadedFromServer(state, val) {
    state.startTestLoadedFromServer = val;
  },
  setTestJson(state, val) {
    state.currentTestJson = val;
  },
  updateCurrentTestState(state, val) {
    state.currentTestState = val;
  },
  updateQResponsesState(state) {
    let sections = state.startTestInfo.testmetadata.sections;
    let editedCurrentTestJson = [];

    /// sort question within the sections

    let sIndex = 0;

    for (let item of sections) {
      let qOrder = sections[sIndex].qOrder;

      let sortedQ = [];

      var foundSectionInTestJson = state.currentTestJson.find(
        (x) => x.sectionId == item.sectionId
      );
      editedCurrentTestJson.push(foundSectionInTestJson);

      for (let q of foundSectionInTestJson.questions) {
        var foundQItemInQOrder = qOrder.find((x) => x.qId == q.qId) ?? null;

        sortedQ.push({
          ...q,
          qOrder: foundQItemInQOrder == null ? 0 : foundQItemInQOrder.order,
        });
      }
      editedCurrentTestJson[sIndex].questions = _.orderBy(
        sortedQ,
        ["qOrder"],
        ["asc"]
      );
      sIndex++;
    }

    state.currentTestJson = editedCurrentTestJson;
    // console.log("check", state.currentTestJson);

    let currentTestJson = [];
    let qResponsesState = [];

    let testJson = state.currentTestJson;
    let sectionIndex = 0;
    let qIndex = 0;

    // console.log(testJson);

    for (let sec of sections) {
      qIndex = 0;

      currentTestJson.push({
        sectionId: sec.sectionId,
        index: sectionIndex,
        sectionName: sec.sectionName,
        questions: [],
      });

      qResponsesState.push({
        sectionId: sec.sectionId,
        index: sectionIndex,
        sectionName: sec.sectionName,
        visibleOnApp: sec.visibleOnApp,
        responses: [],
      });
      // console.log(testJson, sectionIndex);

      for (let qItem of testJson[sectionIndex].questions) {
        currentTestJson[sectionIndex].questions.push({
          qNo: qIndex + 1,
          index: qIndex,
          sectionName: sec.sectionName,

          ...qItem,
        });

        var foundQResponse =
          sec.qResponses.find((x) => x.qId == qItem.qId) ?? null;

        if (foundQResponse == null) {
          qResponsesState[sectionIndex].responses.push({
            qNo: qIndex + 1,
            index: qIndex,
            qId: qItem.qId,
            qType: qItem.qType,
            topicId: qItem.topicId,
            difficultyLevel: qItem.difficultyLevel,
            sectionId: qItem.sectionId,
            sectionName: sec.sectionName,
            checkedOption: "",
            checkedOptions: [],
            integerOption: "",
            decimalOption: "",
            markedForReview: false,
            crossedOutOptions: false,
            crossedOutOptionsList: [],
          });
        } else {
          qResponsesState[sectionIndex].responses.push({
            qNo: qIndex + 1,
            index: qIndex,
            qId: qItem.qId,
            qType: qItem.qType,
            topicId: qItem.topicId,
            difficultyLevel: qItem.difficultyLevel,
            sectionName: sec.sectionName,
            ...foundQResponse,
            sectionId: qItem.sectionId,
            crossedOutOptions: false,
            crossedOutOptionsList: [],
          });
        }

        qIndex++;
      }

      sectionIndex++;
    }

    state.qResponsesState = qResponsesState;
    state.currentTestJson = currentTestJson;
    console.log(currentTestJson);
  },
  loadTestReports(state, val) {
    state.testReports = val;
  },
  testReportsLoadedFromServer(state, val) {
    state.testReportsLoadedFromServer = val;
  },
  clearTestReports(state) {
    state.testReports = {};
  },
  loadTestResult(state, val) {
    state.currentTestResult = val;

    state.currentTestResultJson =
      state.currentTestResult.testInstanceQuestionSummary.sections;
  },
  testResultLoadedFromServer(state, val) {
    state.testsLoadedFromServer = val;
  },
  clearTestResult(state) {
    state.currentTestResult = {};
    state.currentTestResultJson = [];
  },
  clearCurrentTestState(state) {
    state.qResponsesState = [];
    state.currentTestState = {
      sectionIndex: 0,
      qIndex: 0,
    };
    state.startTestInfo = {};
    state.currentTestJson = [];
    state.syncTimerData = {};
    state.submissionInfo = {};
    state.currentChallengeId = "";
    state.currentTestId = "";
  },
  updateFeedback(state, val) {
    state.feedbackResonse = val;
  },
  updateQuestionReviewStatus(state, val) {
    state.updateQuestionReviewStatusResponse = val;
  },
  loadQuestionReviewFeedbackDetails(state, val) {
    //console.log(`mutation questionFeedback: ${JSON.stringify(val.questionFeedback)}`);
    for (var itemNo in val.questionFeedback) {
      var feedback = val.questionFeedback[itemNo];
      //console.log(`mutation feedback1 : ${JSON.stringify(feedback)}`);
      var utcDateVal = moment.utc(
        feedback.feedbackDatetime,
        "MM/DD/YYYY hh:mm:ss a"
      );
      feedback.feedbackDatetimeLocal = utcDateVal
        .local()
        .format("MMM-DD h:mmA");
      feedback.feedbackDateLocal = utcDateVal.local().format("MMM-DD");
      //console.log(`mutation feedback2 : ${JSON.stringify(feedback)}`);
    }
    state.questionReviewFeedbackDetails = val;
  },
  lastTimeStampOfPatternList(state, val) {
    state.lastTimeStampOfPatternList = val;
  },
  loadPatternsList(state, val) {
    state.patternList = val;
  },
  loadClassesList(state, val) {
    state.classesList = val;
  },
  loadUserMessages(state, val) {
    state.userMessages = val;
  },
  userMessagesLoadedFromServer(state, val) {
    state.userMessagesLoadedFromServer = val;
  },
  userMessageUpdated(state, val) {
    state.userMessageUpdated = val;
  },

  toggleDrawer(state, val) {
    state.navDrawer = val;
  },

  navbarVisible(state, val) {
    state.navDrawerVisible = val;
  },

  profileVisible(state, val) {
    state.isProfileVisible = val;
  },
  fetchProfileInfo(state, val) {
    state.profileInfo = val;
  },

  appbarVisible(state, val) {
    state.appBarVisible = val;
  },

  updateTestSeriesState(state, val) {
    state.testSeriesState = val;
  },
  updateSubmitError(state, val) {
    state.submitError = val;
  },
  classesListLoadedFromServer(state, val) {
    state.classesListLoadedFromServer = val;
  },

  // challenges
  updateChallengeSounds(state, val) {
    state.challengeSounds = val;
  },
  updateComingFromChallenge(state, val) {
    state.comingFromChallenge = val;
  },
  loadAIChallenge(state, val) {
    state.aiChallenge = val;
  },
  aiChallengeLoadedFromServer(state, val) {
    state.aiChallengeLoadedFromServer = val;
  },
  loadAIInstanceSteps(state, val) {
    let x = atob(val);
    let array = JSON.parse(x);
    state.aiInstanceSteps = array;
    console.log(array);
  },
  aiInstanceStepsLoadedFromServer(state, val) {
    state.aiInstanceStepsLoadedFromServer = val;
  },
  rejectAIChallenge(state, val) {
    state.rejectedAIChallenge = val;
  },
  loadChallenges(state, val) {
    state.challenges = val;
  },
  challengesLoadedFromServer(state, val) {
    state.challengesLoadedFromServer = val;
  },
  selectChallenge(state, val) {
    state.selectedChallenge = val;
  },
  selectedChallengeLoadedFromServer(state, val) {
    state.selectedChallengeLoadedFromServer = val;
  },
  updateUserType(state, val) {
    state.userType = val;
  },
  updateAblyRealtime(state) {
    const url = state.app_apiURL + "mentoapp/ablyToken";
    state.ablyRealTime = new Ably.Realtime({
      authUrl: url,
      authHeaders: {
        ...getHeaders(),
      },
    });
  },
  updateAblyChannel(state, val) {
    const channel = state.ablyRealTime.channels.get(val);
    state.ablyChannel = channel;
  },
  updateAblyParticipants(state, val) {
    state.ablyParticipants = val;
  },
  updateAblyParticipantsWhileAttempting(state, val) {
    state.ablyParticipantsWhileAttempting = val;
  },
  challengeGeneratedFromServer(state, val) {
    state.challengeGeneratedFromServer = val;
  },
  generateChallenge(state, val) {
    state.generatedChallenge = val;
  },
  updateCurrentChallengeId(state, val) {
    state.currentChallengeId = val;
  },
  updateParticipantScoreWithAI(state, val) {
    console.log("score");

    state.participantScoreWithAI = val;
  },
  acceptChallengeInfoLoadedFromServer(state, val) {
    state.acceptChallengeInfoLoadedFromServer = val;
  },
  acceptChallenge(state, val) {
    state.acceptChallengeInfo = val;
  },
  challengeReportFromServer(state, val) {
    state.challengeReportFromServer = val;
  },
  challengeReport(state, val) {
    state.challengeReportData = val;
  },
  pastChallengesFromServer(state, val) {
    state.pastChallengesFromServer = val;
  },
  loadPastChallenges(state, val) {
    state.pastChallengesData = val;
  },
  updateChallengeSSOInfo(state, val) {
    state.aiChallengeSSOInfo = val;
  },
  setChallengeSSOInfoError(state, val) {
    state.aiChallengeSSOInfoError = val;
  },

  //onboarding
  loadOnBoardingInfo(state, val) {
    state.onBoardingResponse = val;
    state.domainsList = _.get(val, "domains", []);
  },
  loadOnBoardingQuestions(state, val) {
    state.onBoardingQuestions = val;
  },
  setConfigValues(state, val) {
    state.config = val;
  },

  getCustomersList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.customersList = val;
  },
  //firebase
  updateFirebaseInfo(state, val) {
    state.firebaseInfo = val;
  },
  updateSWRegistration(state, val) {
    state.swRegistration = val;
  },
  updateNavBarItems(state, val) {
    let data = (state.config.environment=="dev")
      ? state.navigationDataDev
      : state.navigationData;
    var foundItem = data.find((e) => e.path == val.data);
    if (foundItem) {
      let item = _.cloneDeep(foundItem);
      item.badge = val.show;
      Object.assign(foundItem, item);
    }
  },
  updateTabData(state, val) {
    state.updateTab = val;
  },

  // ai
  updatePracticeNudgeInfo(state, val) {
    state.practiceNudgeInfo = val;
  },
  updatePracticeNudgeMessage(state, val) {
    state.currentPracticeMessageFromServer = val;
  },
  getWidgetsList(state, val) {
    state.widgetsList = val;
  },
  joinWaitlist(state, val) {
    let foundItem = state.widgetsList.find((e) => e.toolId == val);
    if (foundItem) {
      foundItem.waitlistJoined = true;
    }
  },
  getTokens(state, val) {
    if (val.success == "false") {
      val.totalTokens = 0;
      val.maxTokens = 0;
    } else {
      if (val.maxTokens != null) {
        state.maxAITokens = val.maxTokens;
      }
      state.totalAITokens = val.totalTokens;
    }
  },
  aiTokensDataLoadedFromServer(state, val) {
    state.aiTokensDataLoadedFromServer = val;
  },

  //pricing models
  updatePricingModels(state, val) {
    state.pricingModels = val;
    // if('userPackages' in val){
    //   console.log('userPackages', val['userPackages']);
    //   state.userPackages = val['userPackages']
    // }
  },
  pricingModelsLoadedFromServer(state, val) {
    state.pricingModelsLoadedFromServer = val;
  },

  feedbackSubmissionInfoLoadedFromServer(state, val) {
    state.feedbackSubmissionInfoLoadedFromServer = val;
  },
  // updateUserPackages(state, val) {
  //   if('packageIds' in val){
  //     console.log('packageIds', val['packageIds']);
  //     // state.userPackages = val['packageIds']
  //   }

  // },
  loadProfileDetails(state, val) {
    state.profileDetails = val;
  },
  //assignments
  updateCurrentAssignmentId(state, val) {
    state.assignmentId = val;
  },
  showStudentAssignmentSolution(state, val) {
    state.showStudentAssignmentSolution = val;
  },
  updateAssignmentPracticeInfo(state, val) {
    state.assignmentPracticeInfo = val;
  },
  loadAssignments(state, val) {
    state.assignments = val;
  },
  copyAssignment(state, val) {
    state.copyAssignment = _.cloneDeep(val);
  },
  updateAssignmentsCount(state, val) {
    let count = 0;
    let assignments = val.items;
    if (!(assignments instanceof Array)) {
      assignments = [];
    }
    for (let assignment of assignments) {
      if (assignment.status != "completed") {
        count++;
      }
    }
    state.assignmentsCount = count;
  },
  decreaseIncompleteAssignmentCount(state) {
    state.assignmentsCount -= 1;
  },
  assignmentsLoadedFromServer(state, val) {
    state.assignmentsLoadedFromServer = val;
  },
  // teacher view
  switchingViewData(state, val) {
    state.isTeacherViewSelected = val;
  },
  loadStudentList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.studentList = val;
  },
  loadSearchedStudentList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.searchedStudentList = val;
  },
  clearSearchedStudentList(state) {
    state.searchedStudentList = [];
  },
  loadStudentPracticeTestReport(state, val) {
    state.studentPracticeTestReport = val;
    state.studentName = val.name;
  },
  getTeacherClassesData(state, val) {
    state.teacherClassList = val;
  },

  loadTeacherAssignments(state, val) {
    if (typeof data != "object") {
      if ("name" in val.data) {
        state.studentName = val.data.name;
      }
      console.log(val.data);
      try {
        state.studentAssignmentActionsAllowed =
          val.data.assignmentActionsAllowed ?? true;
        console.log(
          "state.studentAssignmentActionsAllowed",
          state.studentAssignmentActionsAllowed
        );
      } catch (error) {
        console.log("error", error);
        state.studentAssignmentActionsAllowed = true;
      }

      if ("assignments" in val.data) {
        if (val.isCurrent) {
          state.teacherCurrentAssignmentList = val.data.assignments;
        } else {
          state.teacherPastAssignmentList = val.data.assignments;
        }
      }
    } else {
      if (val.isCurrent) {
        state.teacherCurrentAssignmentList = val.data;
      } else {
        state.teacherPastAssignmentList = val.data;
      }
    }
  },
  resetTeacherAssignments(state, val) {
    state.studentAssignmentActionsAllowed = false;
  },
  clearTeacherAssignments(state) {
    state.teacherCurrentAssignmentList = [];
    state.teacherPastAssignmentList = [];
    state.assignmentReports = [];
  },
  addTeacherAssignment(state, val) {
    if (val.isCurrent) {
      state.teacherCurrentAssignmentList.push(val);
    } else {
      state.teacherPastAssignmentList.push(val);
    }
  },
  updateTeacherAssignment(state, val) {
    if (val.isCurrent) {
      let foundItem = state.teacherCurrentAssignmentList.find(
        (e) => e.assignmentId == val.assignmentId
      );
      if (foundItem) {
        Object.assign(foundItem, val);
      } else {
        let foundIndex = state.teacherPastAssignmentList.findIndex(
          (e) => e.assignmentId == val.assignmentId
        );
        state.teacherPastAssignmentList.splice(foundIndex, 1);
      }
    } else {
      let foundItem = state.teacherPastAssignmentList.find(
        (e) => e.assignmentId == val.assignmentId
      );
      if (foundItem) {
        Object.assign(foundItem, val);
      } else {
        let foundIndex = state.teacherCurrentAssignmentList.findIndex(
          (e) => e.assignmentId == val.assignmentId
        );
        state.teacherCurrentAssignmentList.splice(foundIndex, 1);
      }
    }
  },
  deleteTeacherAssignment(state, val) {
    if (val.isCurrent) {
      let foundIndex = state.teacherCurrentAssignmentList.findIndex(
        (e) => e.assignmentId == val.assignmentId
      );
      state.teacherCurrentAssignmentList.splice(foundIndex, 1);
    } else {
      let foundIndex = state.teacherPastAssignmentList.findIndex(
        (e) => e.assignmentId == val.assignmentId
      );
      state.teacherPastAssignmentList.splice(foundIndex, 1);
    }
  },
  loadTopicWiseReport(state, val) {
    state.topicWiseReports = val;
  },
  loadStudentSkills(state, val) {
    state.studentSkills = val;
    state.studentName = val.name;
  },
  aiResearchSkills(state, val) {
    state.aiResearchSkills = val;
  },
  loadAssignmentReport(state, val) {
    state.assignmentReports = val;
  },
  updateClassName(state, val) {
    state.className = val;
  },
  updateStudentName(state, val) {
    state.studentName = val;
  },
  updateStudentId(state, val) {
    state.studentId = val;
  },

  // Admin Dashboard
  updateOrganizationDetails(state, val) {
    state.organizationDetails = val;
  },
  loadAdminStudentList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.adminStudentList = val;
  },
  loadSearchedAdminStudentList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.searchedAdminStudentList = val;
  },
  clearSearchedAdminStudentList(state) {
    state.searchedAdminStudentList = [];
  },
  getClassList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.classList = val;
  },
  getPackageList(state, val) {
    if (
      _.isEmpty(val.studentPackages) ||
      !(val.studentPackages instanceof Array)
    ) {
      val.studentPackages = [];
    }
    if (_.isEmpty(val.tutorPackages) || !(val.tutorPackages instanceof Array)) {
      val.tutorPackages = [];
    }
    state.packageList = val;
  },
  getTutorList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.tutorList = val;
  },
  loadSearchedTutorList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.searchedTutorList = val;
  },
  clearSearchedTutorList(state) {
    state.searchedTutorList = [];
  },
  loadSearchedClassList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.searchedClassList = val;
  },
  clearSearchedClassList(state) {
    state.searchedClassList = [];
  },
  addStudent(state, val) {
    state.adminStudentList.push(val);
  },
  updateStudent(state, val) {
    let data = state.adminStudentList;
    var foundItem = data.find((e) => e.userId == val.userId);
    if (foundItem) {
      Object.assign(foundItem, val);
    }
    let data2 = state.searchedAdminStudentList;
    var foundItem2 = data2.find((e) => e.userId == val.userId);
    if (foundItem2) {
      Object.assign(foundItem2, val);
    }
  },
  deleteStudent(state, val) {
    let data = state.adminStudentList;
    let foundIndex = data.findIndex((e) => e.userId == val.studentId);
    data.splice(foundIndex, 1);
    let data2 = state.searchedAdminStudentList;
    let foundIndex2 = data2.findIndex((e) => e.userId == val.studentId);
    data2.splice(foundIndex2, 1);
  },

  addTutor(state, val) {
    if (val && "tutor" in val && val.success) {
      state.tutorList.push(val["tutor"]);
    }
  },
  updateTutor(state, val) {
    if (val && "tutor" in val && val.success) {
      let data = state.tutorList;
      var foundItem = data.find((e) => e.tutorId == val["tutor"].tutorId);
      if (foundItem) {
        Object.assign(foundItem, val["tutor"]);
      }
      let data2 = state.searchedTutorList;
      var foundItem2 = data2.find((e) => e.tutorId == val["tutor"].tutorId);
      if (foundItem2) {
        Object.assign(foundItem2, val["tutor"]);
      }
    }
  },
  deleteTutor(state, val) {
    let foundIndex = state.tutorList.findIndex((e) => e.tutorId == val.tutorId);
    state.tutorList.splice(foundIndex, 1);
  },

  addClass(state, val) {
    if (val && "batch" in val && val.success) {
      state.classList.push(val["batch"]);
    }
  },
  updateClass(state, val) {
    if (val && "batch" in val && val.success) {
      let data = state.classList;
      var foundItem = data.find((e) => e.batchId == val["batch"].batchId);
      if (foundItem) {
        Object.assign(foundItem, val["batch"]);
      }
      let data2 = state.searchedClassList;
      var foundItem2 = data2.find((e) => e.batchId == val["batch"].batchId);
      if (foundItem2) {
        Object.assign(foundItem2, val["batch"]);
      }
    }
  },
  deleteClass(state, val) {
    let foundIndex = state.classList.findIndex((e) => e.batchId == val.batchId);
    state.classList.splice(foundIndex, 1);
  },

  //
  updateBreadCrumbs(state, val) {
    state.breadCrumbs = val;
  },
  //White Labeling
  loadCourseDetails(state, val) {
    storeCourseId(val.courseId);
    state.courseId = val.courseId;
    state.courseDetails = val;
    state.appBrowserTitle = val.appBrowserTitle;
    state.hidePoweredByMentoMind = val.hidePoweredByMentoMind;
  },
  loadTags(state, val) {
    state.tagList = val;
  },
  loadNormalTests(state, val) {
    state.normalTestList = val;
  },
  lastTimeStampOfIAmHere(state, val) {
    state.lastTimeStampOfIAmHere = val;
  },
  //ai
  addAIQuestion(state, val) {
    state.aiQuestions.push(val);
  },
  clearAIQuestions(state) {
    state.aiQuestions = [];
  },
  updateAIQuestionsInput(state, val) {
    state.aiQuestionsInput = val;
  },
  loadTopicsList(state, val) {
    state.topicsList = val;
  },
  getCollectionsList(state, val) {
    state.collectionsList = val;
  },
  addCollection(state, val) {
    state.collectionsList.push(val);
  },
  updateCollectionName(state, val) {
    let foundItem = state.collectionsList.find(
      (e) => e.collectionId == val.collectionId
    );
    if (foundItem) {
      foundItem.collectionName = val.collectionName;
    }
  },
  deleteCollection(state, val) {
    let foundIndex = state.collectionsList.findIndex(
      (e) => e.collectionId == val.collectionId
    );
    state.collectionsList.splice(foundIndex, 1);
  },
  updateTeacherTestId(state, val) {
    state.teacherTestId = val;
  },
  shareCollectionLinkOpened(state, val) {
    state.shareCollectionLinkOpened = val;
  },
  linkSnackbar(state, val) {
    state.linkSnackbarState = val;
  },
  updateLinkSnackbarError(state, val) {
    state.linkSnackbarError = val;
  },
  updateTeacherModeDialog(state, val) {
    if (state.config.environment != "prod") state.teacherModeDialog = val;
  },
  getAiPromptsList(state, val) {
    if (val.success == true) {
      state.aiPromptsList = val.messages;
      state.passageTypes = val.passageTypes;
      state.genres = val.genres;
    } else {
      state.passageTypes = [];
      state.aiPromptsList = [];
      state.genres = [];
    }
  },
  resetAiPromptsList(state) {
    state.passageTypes = [];
    state.aiPromptsList = [];
    state.genres = [];
  },
  entitiesLoadedFromServer(state, val) {
    state.entitiesLoadedFromServer = val;
  },
  storeUserInfoIds(state, val) {
    storeUserCurrentDomain(val.domainId);
    storeUserOrganizationId(val.organizationId);
    storeCourseId(val.customerId);
    state.courseId = val.customerId;
  },
  setUserReferral(state, val) {
    storeUserCurrentDomain(val.domainId);
    storeUserOrganizationId(val.organizationId);
    storeCourseId(val.courseId);
    state.courseId = val.courseId;
  },
  //notification
  showNotification(state, val) {
    state.showNotification = val;
  },
  getNotificationsList(state, val) {
    state.notificationsList = val;
  },

  increamentLoader(state, val) {
    state.loaderCount += 1;
  },
  decreamentLoader(state, val) {
    state.loaderCount -= 1;
  },
  resetLoader(state, val) {
    state.loaderCount = 0;
  },

  // parent dashboard
  addChild(state, val) {
    state.studentList.push(val);
  },
  loadChildList(state, val) {
    if (_.isEmpty(val) || !(val instanceof Array)) {
      val = [];
    }
    state.studentList = val;
  },
  updateChildUserId(state, val) {
    state.childUserId = val;
  },

  getFeatureFlagsList(state, val) {
    let featuresList = {};
    for (let item of val) {
      if ("featureName" in item) {
        featuresList[item.featureName] = item.visibleFor;
      }
    }
    state.featureFlagsList = featuresList;
  },

  // Resource Center
  showPdfProgress(state, val) {
    state.showPdfProgress = val;
  },
  clearPdfsItems(state) {
    state.uploadingPdfs = [];
  },
  setPdfItems(state, val) {
    state.uploadingPdfs.push(val);
  },
  updatePdfProgress(state, val) {
    state.uploadingPdfs[val.index].pdfUploadingProgress = val.progress;
  },
  showSuccessPdfSnackbar(state, val) {
    state.showPdfSnackbar = val;
  },
  loadAllResourceCenterFolderContent(state, val) {
    state.resourceCenterFolderContentList.set(val.parentNodeId, val.children);
  },
  addResourceCenterFolderContent(state, val) {
    state.resourceCenterFolderContentList
      .get(state.selectedResourceFolderId)
      .push(val);
  },
  updateResourceCenterFolderContent(state, val) {
    let foundItem = null;
    let contentList = state.resourceCenterFolderContentList.get(
      state.selectedResourceFolderId
    );
    if ("resourceCenterFolderType" in val) {
      foundItem = contentList.find(
        (e) => e.resourceCenterFolderId == val.resourceCenterFolderId
      );
    } else {
      foundItem = contentList.find(
        (e) =>
          e.resourceCenterFolderContentId == val.resourceCenterFolderContentId
      );
    }
    if (foundItem) {
      Object.assign(foundItem, val);
    }
  },
  deleteResourceCenterFolderContent(state, val) {
    let foundIndex = -1;
    let contentList = state.resourceCenterFolderContentList.get(
      state.selectedResourceFolderId
    );
    if ("resourceCenterFolderType" in val) {
      foundIndex = contentList.findIndex(
        (e) => e.resourceCenterFolderId == val.resourceCenterFolderId
      );
    } else {
      foundIndex = contentList.findIndex(
        (e) =>
          e.resourceCenterFolderContentId == val.resourceCenterFolderContentId
      );
    }
    if (foundIndex != -1) {
      contentList.splice(foundIndex, 1);
    }
  },
  setResourceContent(state, val) {
    state.resourceContent = val;
  },
  setResourceFolderId(state, val) {
    state.selectedResourceFolderId = val;
  },
  loadFolderBreadCrumbs(state, val) {
    state.folderBreadCrumbs = val;
  },
  addFolderBreadCrumbs(state, val) {
    state.folderBreadCrumbs.push(val);
  },
  goToFolderBreadCrumbs(state, folderId) {
    const foundIndex = state.folderBreadCrumbs.findIndex(
      (e) => e.folderId == folderId
    );
    state.folderBreadCrumbs = state.folderBreadCrumbs.slice(0, foundIndex + 1);
  },
  isResourceOpenFromLink(state, val) {
    state.isResourceOpenFromLink = val;
  },
  loadAllStudentResourceFoldersAndContents(state, val) {
    state.studentResourceFolderContentList.set(val.parentNodeId, val.children);
  },
  showContentMessage(state, val) {
    state.showContentMessage = val;
  },

  //self help guide
  updateCurrentScreenName(state, val) {
    this.commit("updateCurrentSelfHelpStepId", "01");
    state.currentScreenName = val;
  },
  updateCurrentSelfHelpStepId(state, val) {
    state.currentSelfHelpStepId = val;
  },
  toggleSelfHelpGuide(state, val) {
    this.commit("updateCurrentSelfHelpStepId", "01");
    state.showSelfHelpGuide = val;
  },
  setSelfHelpGuideData(state, val) {
    state.selfHelpGuideData = val;
  },
  loadAIInsights(state, val) {
    state.aiInsights = val;
  },
  updateQuestionAttemptError(state,val){
    state.questionAttemptError = val
  },
  // Question Tools
  loadQuestionBookmarkAndQueryDetails(state, val){
    state.questionBookmark = _.get(val, 'bookmark', {})
  },
  addBookmark(state, val){
    state.questionBookmark = val;
  },
  updateBookmark(state, val){
    state.questionBookmark = val;
  },
  getAllBookmark(state, val){
    state.questionBookmarkList = val;
  }
};
