<template>
    <v-container class="assignment-card pa-8">

        <v-row class=" t-para">
            <v-textarea rows="2" auto-grow v-model="systemPrompt" label="AI Use Case" variant="outlined"></v-textarea>

        </v-row>
        <v-row>
            <!-- <v-textarea  rows="2" auto-grow v-model="skillsInfo" label="Skill Input" variant="outlined"></v-textarea> -->
            <JsonEditorVue v-model="skillsInfo" color="black" mode="text" v-bind="{/* local props & attrs */ }"
                style="z-index: 20000;" theme="dark" class="skillsBlock" />

        </v-row>

        <v-row class="t-h6Reg mt-8">
            <v-textarea rows="2" auto-grow v-model="query" label="Query" variant="outlined"></v-textarea>

        </v-row>


        <v-row class="mt-5">
            <v-btn @click="viewResult()" class="t-btn text-white  activity-btn text-none px-5" :loading="loading">
                View Result
            </v-btn>

        </v-row>


        <v-row  v-if="responses.length > 0">
            <v-carousel show-arrows="hover" v-model="slideModel" hide-delimiters v-if="responses.length > 0"
                class=" mt-8 list-item " height="max-content" color="red">
                <v-carousel-item v-for="(response, i) in responses" :key="i">
                    <v-sheet class="list-item py-3 pa-3">
                        <v-row class="list-item mt-1 ">
                            <v-col cols="12" class="t-h6 my-0 py-0 px-3  text-primaryTextColor">
                                Response {{ response.responseNo }} 
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col cols="12" class="t-h6 my-0 text-primaryTextColor">
                                <v-divider color="white"></v-divider>

                            </v-col>
                        </v-row>
                        <v-row class="list-item mt-6 ">
                            <v-col cols="12" class="t-h6 my-0 py-0 px-3  text-primaryTextColor">
                                Parsed Response :
                            </v-col>
                            <v-list lines="one" class=" list-item text-primaryTextColor ml-n1">
                                <v-list-item v-for="topic in (response.topics ?? []) " :key="topic.topicId">

                                    <v-list-item-title class="t-h6Reg">
                                        {{ topic.topicName }} ({{ topic.topicId }})
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="t-subtitle">
                                        {{ topic.skill }}
                                    </v-list-item-subtitle>

                                </v-list-item>
                            </v-list>
                        </v-row>
                        <v-row id="content-ai" class="list-item  mt-8  text-primaryTextColor">
                            <v-col cols="12" class="t-h6  my-0 py-0">
                                Raw Response :
                            </v-col>
                            <v-col cols="12" class=" text-primaryTextColor">
                                {{
                                    response.content ?? "No Content" }}
                            </v-col>

                        </v-row>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </v-row>

        <v-row justify="space-between" class=" mt-8" align="center"  v-if="responses.length > 0">
            <v-col class="ma-0 pa-0 my-3 my-sm-" cols="12" sm="auto">
                <v-btn width="170" class="t-btn text-white  activity-btn text-none "
                    @click="slideModel = Math.max(slideModel - 1, 0)">Prev Response
                    <template v-slot:prepend>
                        <v-icon>mdi-chevron-left</v-icon>
                    </template></v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 my-3 my-sm-0 "  cols="12" sm="auto">
                <span class="t-h6 text-center">
                    Response {{ slideModel + 1 }} of {{ responses.length }}
                </span>
            </v-col>
            <v-col class="ma-0 pa-0 my-3 my-sm-"  cols="12" sm="auto">
                <v-btn width="170" class="t-btn text-white  activity-btn text-none "
                    @click="slideModel = Math.min(slideModel + 1, responses.length - 1)">
                    Next Response
                    <template v-slot:append>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template></v-btn>
            </v-col>


        </v-row>
    </v-container>
</template>
  
<script>
// import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment';
import _ from "lodash";
import JsonEditorVue from 'json-editor-vue'
import 'vanilla-jsoneditor/themes/jse-theme-dark.css'
import { securePostAPI } from '@/services/apiService';
import axios from 'axios';


export default {
    name: 'MentoAIComponent',
    props: ["assignmentType", "batchId", "studentId"],

    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp, lgAndUp } = useDisplay();
        return { xs, smAndUp, mdAndUp, lgAndUp };
    },
    async created() {
        this.skillsLoaded = false
        await this.$store.dispatch('aiResearchSkills', { userId: this.studentId })
        this.skillsLoaded = true
        this.setFlatSkills()
        this.systemPrompt = `Your name is mento. You are a chatbot helping students acheive their goals. \nThe json data below contains users current skill in different topics. Use this data to answer any related questions . \nHere is the data in json format`
        this.query = `User seeks guidance on improvement or determining their next steps, the output should be a list of first 5 topics in maths subject with their topicIds requiring enhancement. 
Improvement is necessary for topics where their skill level is comparatively lower than others. 
The process begins by selecting topics with 'notStarted' skill levels, 
followed by 'beginner,' 'intermediate,' 'advanced,' and finally 'mastery.' 
If mastery is achieved in all topics, respond with 'You have achieved mastery'`
    },
    components: { JsonEditorVue, },

    computed: {
        ...mapState([
            "config"
        ]),
        studentSkills: {
            get() {
                return this.$store.state.aiResearchSkills
            }
        },
        sortedResponses: {
            get() {
                let x = this.responses
                return x
            }
        }
    },
    data: () => ({
        skillsInfo: [],
        query: "",
        systemPrompt: "",
        type: "flat",
        calType: "chat",
        loading: false,
        responses: [],
        slideModel: 0
    }),
    methods: {
        setNestedSkills() {
            let data = []
            let skills = _.cloneDeep(this.studentSkills.skillDetails)
            for (let item of skills) {
                console.log(data);
                let x = item
                delete x['subjectType']
                data.push(x)
            }
            console.log(data);
            this.skillsInfo = data
        },
        setFlatSkills() {
            // let data = []
            // let skills = _.cloneDeep(this.studentSkills.skillDetails)
            // for (let item of skills) {
            //     console.log(data);
            //     let x = item
            //     delete x['subjectType']
            //     for (let topic of item.topicSkills) {
            //         data.push({
            //             ...topic,
            //             subject: item.subjectName
            //         })
            //     }
            // }
            // console.log(data);
            this.skillsInfo = _.cloneDeep(this.studentSkills)
        },
        radioUpdate(val) {
            console.log(val);
            if (val == "nested") {
                this.setNestedSkills()
            }
            else {
                this.setFlatSkills()
            }
        },
        async viewResult() {
            let self = this

            try {
                let url = "https://44e45hnt3xk7zcqmsggntbfdxq0wekvw.lambda-url.us-east-1.on.aws/skillsai"
                this.response = null
                this.loading = true
                let response = await axios.post(url, {
                    system_prompt: this.systemPrompt,
                    skills_info: this.skillsInfo,
                    query: this.query,
                    functionCall: this.calType == "chat" ? false : true
                })
                this.response = response.data
                this.responses.push({ responseNo: (this.responses.length + 1), ...response.data })
                this.slideModel = this.responses.length - 1
                this.loading = false
            } catch (error) {
                console.log(error);
                this.loading = false
            }
            setTimeout(() => {
                window.scrollTo({
                    top: window.innerHeight,
                    behavior: "smooth"
                });
            }, 200);

        }
    },
    watch: {

    }



}
</script>
  
<style scoped>
.assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border-radius: 12px !important;
}

.main-lg {
    max-width: 1020px;
    margin: 20px auto;
}

.main-md {
    padding-top: 20px;
    padding-left: 1%;
    padding-right: 1%;
}



.list-item {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    border-radius: 10px;
    background: rgb(var(--v-theme-report-subCardBg));

}

.skillsBlock {
    max-height: 300px;
    overflow: auto;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}

.list-item.on-hover {

    /* background: rgb(var(--v-theme-cardBackgroundColor2)); */
    /* 
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */

}
</style>