<template>
  <!-- Overlay for Total Tokens-->
	<v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="showTokenInfo" class="align-center justify-center px-2 mx-auto">
		<v-card elevation="0" class="decision-box d-flex justify-center align-center pa-2 flex-column"
			:width="smAndUp ? 350 : '95%'" height="100%">
			<v-card-text>
				<v-row justify="center" align="center">
					<v-col class="decision-box-img-col" cols="12">
						<v-img class="mx-auto" aspect-ratio="1" width="70" src="@/assets/token.png"></v-img>
					</v-col>
					<v-col cols="12" class="d-flex justify-end align-center mb-0 pb-0">
						<v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-0" density="compact"
							variant="text" color="primaryTextColor" @click="showTokenInfo = false">
						</v-btn>
					</v-col>
					<v-col v-if="aiTokensUsedForBar >= 100" cols="12" color="primaryTextColor" class="decision-box-title text-center pb-0">
						Daily Limit Reached
					</v-col>
					<v-col v-if="aiTokensUsedForBar >= 100" cols="12" class="decision-box-subtitle text-center">
						You can try again tomorrow
					</v-col>
          <v-col v-else cols="12" class="decision-box-subtitle text-center">
            You have {{ aiTokensLeft }} Mento tokens left
          </v-col>
          <v-col cols="12">
            <v-progress-linear height="10" bg-color="grey" v-model="aiTokensUsedForBar" rounded :color="aiTokensUsedForBarColorComputed"></v-progress-linear>
          </v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-overlay>

  <v-tooltip location="bottom">
    <template #activator="{props}">
      <v-progress-circular @click="showTokenInfo=true" v-bind="props" bg-color="iconGrey" :size="40" :width="6" v-model="aiTokensUsedForBar" :color="aiTokensUsedForBarColorComputed" class="token" ref="MentoAIHubScreen--totalTokensIndicator">
        <v-icon size="22" color="white">
            mdi-lightning-bolt
        </v-icon>
        <!-- <div style="background:white; border-radius: 50%" class="pa-1">
          <v-icon size="22" color="iconGrey">
              mdi-lightning-bolt
          </v-icon>
        </div> -->
      </v-progress-circular>
    </template>
    <span>You have {{aiTokensLeft}} Mento tokens left</span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { useDisplay } from "vuetify";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
export default {
  name: 'MentoAITokensComponent',
  setup() {
		// Destructure only the keys we want to use
		const { xs, lg, xl, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();
		return { xs, lg, xl, smAndUp, mdAndUp, lgAndUp, xlAndUp };
	},
  async mounted(){
    await this.$store.dispatch('getTokens', {maxTokens: true});
    console.log("refs are in token", this.$refs)
  },
  data: () => ({
    aiTokensUsedForBarColor: {
      green: 'green',
      orange: 'orange',
      red: 'red'
    },
    showTokenInfo: false,
    
  }),
  computed: {
    ...mapState(["maxAITokens","totalAITokens"]),
    ...mapGetters(['currentHighlightedElements']),
    aiTokensUsedForBar: {
      get() {
          return (this.totalAITokens / this.maxAITokens) * 100;
      }
    }, 
    aiTokensLeft: {
      get() {
          let data = this.maxAITokens - this.totalAITokens;
          if(data> 0){
            return data;
          }
          return 'No';
      }
    },
    aiTokensUsedForBarColorComputed: {
      get() {
          if (this.aiTokensUsedForBar > 75) {
              return this.aiTokensUsedForBarColor.red
          }
          else if (this.aiTokensUsedForBar > 50) {
              return this.aiTokensUsedForBarColor.orange
          }
          else {
              return this.aiTokensUsedForBarColor.green
          }
      }
    },
  },
  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  }
  
  
}
</script>

<style scoped>

.token{
cursor: pointer;
}

.decision-box {
	border-radius: 12px;
	border: 3px solid rgb(var(--v-theme-practice-prompts-border));
	background: rgb(var(--v-theme-practice-prompts-background));
	margin: 0 auto;
	overflow: visible !important;
}

.decision-box-img-col {
	position: absolute;
	top: -45px;
}

.decision-box-title {
	color: rgb(var(--v-theme-primaryTextColor));
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	/* 25px */
	letter-spacing: -0.25px;
}

.decision-box-subtitle {
	color: rgb(var(--v-theme-practice-decision-box-subtitle));
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.decision-box-options {
	width: 221px;
	height: 142px;
	border-radius: 12px;
	background: rgb(var(--v-theme-practice-prompts-option-box));
}

.decision-box-options-row {
	min-width: 125px;
}

.decision-box-options-title {
	color: rgb(var(--v-theme-primaryTextColor));
	text-align: center;
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.17px;
}

</style>