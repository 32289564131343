<template>
    <div class="ReferralPage">

    </div>
</template>
  
<script>
import { mapState } from "vuex";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { storeReferralId, getReferralId, clearReferralId } from "@/services/authService";

export default {
    name: 'ReferralPage',

    async created() {
        this.$store.commit("getLoggedInUserDetails");
        console.log(this.$route.params);
        let referralId = null
        if ("referralId" in this.$route.params) {
            referralId = this.$route.params.referralId
            console.log(this.userLoggedIn, "this.userLoggedIn");
            storeReferralId(referralId)
            // recordAnalyticsEvent(AnalyticsEvents.v2AppLoadedFromReferral);
            if (this.userLoggedIn == 'true') {
                // call api to store user referral
                await this.$store.dispatch("storeReferralCode", {
                    referralId: referralId
                })

                this.$store.commit("pricingModelsLoadedFromServer", false);

                // clearReferralId()
                this.$router.replace('/learn')

            }

            else {
                this.$router.replace('/')
            }
        }

        else {
            this.$router.replace('/learn')

        }


    },
    data: () => ({
        missingToken: false,
        invalidToken: false
    }),

    computed: {
        ...mapState(["userLoggedIn", "courseId"]),

    },

    watch: {

    },

    methods: {
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
  