import store from "@/store";

// Handle Vuetify components and standard DOM elements
function getElement(ref) {
  if (ref) {
    const element = Array.isArray(ref) ? ref[0] : ref; // Take the first item if it's an array
    return element.$el || element; // Use $el for Vuetify components
  }
  return null;
}

function removeClasses(refs, highlightedElement) {
  if (highlightedElement.uniqueId) {
    const elementRef = refs[highlightedElement.uniqueId];
    const domElement = getElement(elementRef);
    if (domElement && Array.isArray(highlightedElement.cssClass)) {
      highlightedElement.cssClass.forEach((cssClass) => {
        if (domElement.classList) {
          domElement.classList.remove(cssClass);
        }
      });
    }
  }
}

function addClasses(refs, highlightedElement) {
  if (highlightedElement.uniqueId) {
    const elementRef = refs[highlightedElement.uniqueId];
    const domElement = getElement(elementRef);
    if (domElement && Array.isArray(highlightedElement.cssClass)) {
      highlightedElement.cssClass.forEach((cssClass) => {
        if (domElement.classList) {
          domElement.classList.add(cssClass);
        }
      });
    }
  }
}

export function updateHighlightedElements(oldVal, newVal, refs) {
  const currentScreenName = store.state?.currentScreenName;
  // Remove classes from old highlighted elements
  oldVal?.highlightedElements.forEach((highlightedElement) => removeClasses(refs, highlightedElement, currentScreenName));
  
  // Add classes to new highlighted elements if the self-help guide is shown
  if (newVal?.showSelfHelpGuide) {
    newVal?.highlightedElements.forEach((highlightedElement) => addClasses(refs, highlightedElement, currentScreenName));
  }
}
