export function breadcrumbHandler(self) {
    let breadcrumbs = []

    let params = self.$route.params


    console.log("handler", self.$route)
    for (let route of self.$route.matched) {
        if ("showInBreadcrumb" in route.meta && !route.meta.showInBreadcrumb) {
            //pass
        }
        // if  showInBreadcrumb is not present then by default add it in breadcrumbs
        else {

            let pageRoute = routeParser(params, route.path)
            breadcrumbs.push({
                title: route.meta.title ?? "",
                disabled: false,
                parentNodeIdKeyinChildNodesMap: route.name,
                pageRoute: pageRoute,
            });
        }
    }
    console.log("handler breadcrumbs", breadcrumbs);

    self.$store.commit("updateBreadCrumbs", breadcrumbs)
}


export function routeParser(params, currentPath) {
    let path = currentPath
    for (const [key, value] of Object.entries(params)) {
        path = path.replace(`:${key}`, value)
    }

    return path
}