<template>

  <v-row class="ma-0 pt-4 px-4" align="center" justify="end" :class="{ heightBread: $vuetify.display.mdAndUp }">
    <v-col cols="auto" class="ml-4 d-flex align-center">
      <MentoAITokensComponent />
      <v-btn append-icon="mdi-bookmark-outline" class="t-btn text-white activity-btn text-none px-3 pr-5 ml-6 mr-2"
        density="default" variant="flat" @click="getMyCollections()" ref="MentoAIHubScreen--myCollectionsBtn">
        <template v-slot:append>
          <v-icon color="primaryTextColor"></v-icon>
        </template>
        My collections
      </v-btn>
      <SelfHelpGuideTogglerComponent class="ml-4" />
    </v-col>
    <v-col cols="auto" class="ma-0 pa-0">
    </v-col>
    <v-divider></v-divider>
  </v-row>
  <v-layout v-if="dataReady" class="mb-0 pb-0">

    <v-container fluid class="h-100 mt-3 main-lg container-width">
      <v-row align="center" justify="start" dense>
        <v-col cols="12">

          <span class="t-h125 text-center mb-1 text-primaryTextColor">Mento AI Hub</span>

        </v-col>
        <v-col cols="12">
          <span class="t-1rem text-center mb-1 test-subtitle">The magic of AI to help {{isUserTeacher ? 'educators' : 'students'}} with differentiated content, assessments, engagement and much more!</span>
        </v-col>
      </v-row>

      <v-row justify="space-between" dense class="mt-6">
        <v-col cols="12" md="6" v-for="item, index in filteredWidgetList" :key="index" class="pa-2">
          <v-card class="px-2 py-1 assignment-card fill-height card-border" :class="[item.isReady ? 'click' : 'disabledOpacity']" @click="openWidget(item.toolId, item.isReady)" :ref="`MentoAIHubScreen__widgets--${item.toolId}`">
            <v-card-text class="d-flex flex-column justify-space-between h-100">
              <v-row align="start" class="widget-card-header flex-grow-0">
                <v-col cols="12" sm="auto" class="d-flex justify-space-between align-center">
                  <v-avatar size="40" color="#3B3A50">
                    <v-img height="30" :src="getImageSrc(item.icon)">
                    </v-img>
                  </v-avatar>
                  <v-tooltip v-if="!item.isReady && !smAndUp" location="start">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-btn class="normal-btn" @click="addToWaitlist(item.waitlistJoined, item.toolId)"
                          :icon="item.waitlistJoined ? 'mdi-bell' : 'mdi-bell-outline'" variant="text"
                          color="primaryTextColor" :disabled="item.waitlistJoined" size="regular" density="compact">
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ item.waitlistJoined ? 'You have joined the waitlist for this feature.' : 'Join the waitlist for this feature.' }}</span>
                  </v-tooltip>
                </v-col>
                <v-col class="">
                  <v-row>
                    <v-col class="t-1rem text-white">
                      <span class="truncate-text">
                        {{ item.title }}
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" size="small" class="coming-soon-chip  coming-soon-chip-text ma-1"
                              v-if="!item.isReady">
                              Coming Soon
                            </v-chip>
                          </template>
                          <span>This feature is coming soon</span>
                        </v-tooltip>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense class=" text-white">
                    <v-col class="truncate-description">
                      {{ item.description }}
                    </v-col>

                  </v-row>

                </v-col>
              </v-row>
              <v-row v-if="!item.isReady && smAndUp" dense class="flex-grow-0">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-tooltip location="start">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-btn class="normal-btn" @click="addToWaitlist(item.waitlistJoined, item.toolId)"
                          :icon="item.waitlistJoined ? 'mdi-bell' : 'mdi-bell-alert-outline'" variant="text"
                          color="primaryTextColor" :disabled="item.waitlistJoined" size="regular" density="compact">
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ item.waitlistJoined ? 'You have joined the waitlist for this feature.' : 'Join the waitlist for this feature.' }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>


        </v-col>

      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapGetters, mapState } from "vuex";
import moment from 'moment';
import _ from "lodash";
import { securePostAPI, invokeLambda, generateStream } from '@/services/apiService';
import axios from 'axios';
import { getHeaders, getApiBaseUrl } from '@/services/authService';
import $ from "jquery";
import MentoAITokensComponent from "@/components/ai/MentoAITokensComponent.vue";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
const marked = require("marked");
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};

export default {
  name: 'MentoAIHub',
  data: () => ({
    currentScreenName: 'MentoAIHubScreen',
    widgetIcon: require("@/assets/englishhub.svg"),
    widgets: [
      {
        'title': 'SAT English practice questions',
        'subtitile': `An AI-powered tool designed to generate SAT English questions efficiently.`,
        'icon': require("@/assets/englishhub.svg"),
        'path': 'enlgish'
      },
      {
        'title': 'SAT English practice questions with custom prompt',
        'subtitile': `An AI-powered tool to generate SAT English questions with custom prompts and images`,
        'icon': require("@/assets/englishprompthub.svg"),
        'call': 'engglishWIth',
        disabled: true
      }
    ],
    dataReady: false,
  }),
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, md, lg, xl, xxl } = useDisplay();
    return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, md, lg, xl, xxl };
  },
  mounted() {
    
    this.dataReady = false
    this.$store.dispatch("getWidgetsList", {});
    
    this.dataReady = true
    recordAnalyticsEvent(AnalyticsEvents.v2AdminAIHubScreenOpen, {});
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
    console.log("refs are ", this.$refs);
  },
  unmounted() {
    this.$store.commit("updateCurrentScreenName", null);
  },
  async created() {
  },
  components: {
    MentoAITokensComponent, 
    SelfHelpGuideTogglerComponent
  },

  computed: {
    ...mapState(["newLearnNodes", "breadCrumbs", "widgetsList", "isUserTeacher","isTeacherViewSelected", "isUserAdmin"]),
    ...mapGetters(['currentHighlightedElements']),
    displayBreadCrumbs: {
      get() {
        let breadCrumbs = [];
        breadCrumbs = this.breadCrumbs;

        return breadCrumbs
      }
    },

    filteredWidgetList:{
      get(){
        let widgets  = this.widgetsList;
        if(!this.isTeacherViewSelected){
          widgets = this.widgetsList.filter((e) => e.visibility == "all" || e.visibility == "student");
        }

        console.log("Widgets",widgets);
        return widgets;
      }
    },
    showSelfHelpGuide: {
      get(){
        return this.$store.state.showSelfHelpGuide;
      },
      set(value){
        this.$store.commit('toggleSelfHelpGuide', value);
      }
    }
  },

  methods: {
    async openWidget(path, isReady) {
      console.log("Path: ",path);
      console.log("IsReady: ",isReady);
      if (isReady) {
        console.log("wwwwwwwwwwww-------------",this.$route.fullPath+"/"+path)
        recordAnalyticsEvent(AnalyticsEvents.v2AdminAIHubScreenToolOpen, {toolId: path});
        this.$router.push(`${this.$route.fullPath}/${path}`);
      }
      else {
        return;
      }
    },
    getMyCollections() {
      recordAnalyticsEvent(AnalyticsEvents.v2AIMyCollectionsButtonClicked, {fromTool: false});
      this.$router.push(`${this.$route.fullPath}/mycollections`);
    },
    getImageSrc(path) {
      return this.$store.state.app_s3url + path;
    },
    addToWaitlist(isWaitlistJoined, toolId) {
      if (!isWaitlistJoined) {
        let foundItem = this.widgetsList.find((e) => e.toolId == toolId);
        if (!foundItem.waitlistJoined || foundItem.waitlistJoined == false)  {
          foundItem.waitlistJoined = true;
        } 
        recordAnalyticsEvent(AnalyticsEvents.v2AdminAIHubScreenToolJoinWaitlist, {toolId: toolId});
        this.$store.dispatch("joinWaitlist", { toolId: toolId });
      }
    },
  },
  watch: {
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  }



}
</script>

<style scoped>
.activity-btn {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 12px;
}

.normal-btn {
  opacity: 1 !important;
}

.assignment-card {
  background-color: rgb(var(--v-theme-reportBgColor));
  border: 2px solid rgb(var(--v-theme-reportBgColor));
  border-radius: 12px;
  white-space: pre-line !important;
}

.click {
  cursor: pointer
}

.disabledOpacity {
  opacity: 0.9;
}

.card-border:hover {
  border: 2px solid #9461FC;
}



.custom-tooltip {
  background-color: #FFDC00 !important;
  /* Your desired background color */
  color: black !important;
  /* Your desired text color */
}

.container-width{
  max-width: 1200px;
}

.main-lg {
  padding-top: 32px;
  /* padding-left: 10%;
  padding-right: 10%; */
}

.main-md {
  padding-top: 32px;
  padding-left: 10%;
  padding-right: 10%;
}

.entity-container {
  padding-left: 8%;
  padding-right: 8%;
  background-color: transparent;
}

.mento-sup {
  color: #FEFEFE;
  font-family: Prompt;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  /* 24px */
}

.entity-container-xl {
  padding-left: 17%;
  padding-right: 17%;
  background-color: transparent;
}

.entity-container-xxl {
  padding-left: 28%;
  padding-right: 28%;
  background-color: transparent;
}

.coming-soon-chip {
  background: #A174FD80;
  border: 1px solid #966DEC
}

.coming-soon-chip-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.64rem !important;
  font-weight: 500 !important;
  color: white;
}

.normal-button {
  border-radius: 200px;
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.active-button {
  border-radius: 200px;
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  color: rgb(var(--v-theme-primaryTextColor)) !important;

  /* width: 164px !important; */
}



.button-text {
  letter-spacing: -0.08px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.truncate-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>

<style>
.active-button.v-btn--disabled .v-btn__overlay {
  display: none;
}
</style>