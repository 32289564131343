<template>
  <!-- Quiz Loader -->
  <v-overlay style="backdrop-filter: blur(12.5px)" persistent v-model="addOnDialog"
    class="align-center justify-center px-2">
    <v-card elevation="0" class="add-on-card justify-center align-center pa-4">
      <v-row class="ma-0 pa-0">
        <v-col class="pa-0" justify="end" align="end"><v-btn icon="mdi-close"
            class="t-btn text-none rounded-info-btn ma-0 pa-0" density="compact" variant="outlined"
            color="primaryTextColor" @click="closeAddOnToken">
          </v-btn></v-col>
      </v-row>
      <v-card-text class="pa-0">
        <v-row justify="center" align="center" class="pa-5 mb-3">
          <v-col cols="12" class="ma-0 pa-0">
            <v-img class="start-quiz-loading-img mx-auto" aspect-ratio="1" width="88" src="@/assets/add_on.png"></v-img>
          </v-col>
          <v-col cols="12" class="add_on-title text-center text-white ma-0 pa-0 mt-3">
            {{ pricingModels.dialogItem.title }}
          </v-col>
          <v-col class="ma-0 pa-0 text-grey t-h6 text-center mt-3" cols="12">
            {{ pricingModels.dialogItem.subtitile }}
          </v-col>
        </v-row>
        <v-row dense class="add_on_feature ma-0 pa-2" :class="{ 'add_not_feature': !selectToken }" align="center"
          justify="center" @click="toggleToken">
          <v-col cols="2" class="ma-0 pa-0" align="center" justify="center">
            <v-avatar size="25" class="text-center" :class="{ 'uncheck-border': !selectToken }"
              :color="selectToken ? 'primaryPurple' : ''">
              <v-icon v-if="selectToken" :size="20" icon="mdi-check" color="fff"></v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="10" class="t-1rem text-white ma-0 pa-0">Purchase Add-On Now<br /><span class="text-grey t-h6">$ {{
            pricingModels.dialogItem.price }}/ {{ pricingModels.dialogItem.duration }}</span></v-col>
        </v-row>
        <v-row class="mt-9">
          <v-col align="center" justify="center">
            <v-btn class="t-btn text-white w-100 continue-btn text-none" density="default" variant="flat"
              @click="purchaseAddOnToken">
              {{ selectToken ? 'Purchase With Add On' : 'Purchase' }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-overlay>
  <v-layout class="mb-0 pb-0" v-if="dataLoaded">
    <v-container fluid :class="lgAndUp ? 'main-lg' : mdAndUp && !lgAndUp ? 'main-md' : ''">
      <v-row class="ma-0 pa-0">
        <v-col class="pa-0" justify="end" align="end"><v-btn icon="mdi-close"
            class="t-btn text-none rounded-info-btn ma-0 pa-0" density="compact" variant="outlined"
            color="primaryTextColor" @click="closePricingPage">
          </v-btn></v-col>
      </v-row>
      <v-card v-if="pricingModels.showQuarnteeMessage" class="message-card" elevation="0">
        <v-card-text class="pa-0 ma-0">
          <v-row class="align-center pa-0 ma-0" justify="space-between">
            <v-col cols="9" class="pa-0 ma-0">
              <v-row v-if="pricingModels.quarnteeMessageTitle?.length>0" class="t-h125 pa-0 ma-0 mb-3">{{ pricingModels.quarnteeMessageTitle }}</v-row>
              <v-row v-if="pricingModels.quarnteeMessageSubTitle?.length>0" class="t-1remReg text-grey pa-0 ma-0 mb-2">{{ pricingModels.quarnteeMessageSubTitle }}</v-row>
              <v-row v-if="pricingModels.quarnteeMessageTnCMessage?.length>0" class="t-1remMed text-primaryPurple pa-0 ma-0">{{ pricingModels.quarnteeMessageTnCMessage }}</v-row>
            </v-col>
            <v-col cols="auto" class="justify-end d-flex pa-0 ma-0 pr-2">
              <v-img v-if="pricingModels.quarnteeMessageIcon?.length>0" class="img-block" :src="getImagSrc(pricingModels.quarnteeMessageIcon)"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- <v-row>
        <v-col cols="12" align="center" justify="center" class="ma-0 pa-0 mb-12">
          <span class="text-white main-title">Upgrade Learning</span>
        </v-col>
       

      </v-row> -->
      <v-row v-if="pricingModels.userPackages?.length>1" class="switch-plan-block mt-4">
          <v-col cols="auto" v-for="model, index in pricingModels.userPackages" :key="index" class="pa-0 ma-0 px-3 py-2" :class="{'selected-plan': selectedIndex == index}"
          @click="selectPlan(index, model)"
          >
            {{ model.packageType }}
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" align="center" justify="center">
        <v-col cols="auto" v-for="model, index in packageItems" :key="index">
          <v-card class="elite-card" :class="{ 'selected-card': selectedPlanIndex == index }"
            @click="selectItem(index, model)" elevation="0">
            <v-card-text class="pa-0">
              <v-container class="px-5 py-6 ma-0">
                <v-row dense align="center" justify="space-between">
                  <v-col class="t-h5">{{ model.name }}</v-col>
                  <!-- <v-spacer></v-spacer> -->
                  <v-col cols="auto" align-self="end" v-if="purchasedPackages.includes(model?.packageId)"
                    class="t-h6 text-primaryPurple">Subscribed</v-col>
                </v-row>
                <v-row dense align="center" class="mt-3">
                  <v-col cols="auto" class=" py-0 price-text  " :class="{
                    'text-red text-decoration-line-through': model.discountedPrice
                  }">
                    {{ getFormattedPrice(model.price, model.currency) }}</v-col>
                  <v-col cols="auto" class="price-text pa-0 ma-0 ml-2 " v-if="model.discountedPrice">{{
                    getFormattedPrice(model.discountedPrice ? model.discountedPrice : model.price, model.currency)
                  }}</v-col>
                  <v-col cols="auto" class="t-h6 text-grey mr-1">{{ model.priceDurationText }}</v-col>


                </v-row>
                <v-row v-if="model.discountedPrice" class="pa-0">
                  <v-col cols="auto" class="py-0 pr-1 t-para text-grey">Save:</v-col>

                  <v-col cols="auto" class="pa-0 t-para text-green">{{ (model.saveText ?? model.discountPercentage) ?? ""
                  }}</v-col>
                </v-row>
                <v-row dense :class="{ 'mt-5': !model.discountedPrice }">
                  <v-col class="t-1remReg mt-5">What’s Included ?</v-col>
                </v-row>


                <v-row dense align="center" class="mt-4" v-for="feature, index in model.features" :key="index">
                  <v-col cols="auto">
                    <v-avatar size="25" class="text-center" color="primaryPurple">
                      <v-icon :size="20" icon="mdi-check" color="fff"></v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col class="t-h6">{{ feature.title }}
                    <span v-if="'subData' in feature">
                      <br /><span class="text-grey">{{ feature.subData }}</span>
                    </span>

                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="mt-5 t-subtitleGrey text-center" v-if="pricingModels.upgradeExistingPackageMessage?.length>0">
        {{ pricingModels.upgradeExistingPackageMessage }}
      </div>

      <v-row class="mt-5" dense>
        <v-col align="center" justify="center">
          <v-btn class="t-btn text-white continue-btn text-none" width="250" density="default" variant="flat"
            :disabled="purchasedPackages.includes(selectedPlan?.packageId) || this.selectedPlanIndex==-1" @click="addOnToken">
            Continue</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import { useDisplay } from "vuetify";
import { mapState } from 'vuex';
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";

export default {
  name: "SubscriptionPricingPage",
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },
  data: () => ({
    breadCrumbs: [],
    dataLoaded: false,
    updatePastChallengeData: {},
    testId: "",
    selectedPlanIndex: -1,
    selectedPlan: null,
    addOnDialog: false,
    formatter: null,
    selectToken: false,
    purchasedPackages: [],
    selectedIndex: -1,
    packageItems: []

  }),
  async created() {
    console.log("called created")
    console.log("child userid", this.childUserId)
    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
    this.$store.commit("showNotification", false);
    const checkSub = await this.$store.dispatch("createCheckSub", {})
    this.purchasedPackages = checkSub;
    this.dataLoaded = true;
    recordAnalyticsEvent(AnalyticsEvents.v2PricingScreenOpen, {
      userRegistrationFlow: localStorage.getItem("newUser") == 'true',
    });
    await this.$store.dispatch("getPricingModels", {courseId: this.courseId })
    this.selectedIndex = 0;
    this.packageItems = this.pricingModels.userPackages[this.selectedIndex].packages
    this.selectedPlanIndex = this.packageItems.length - 1;
    this.selectedPlan = this.packageItems[this.selectedPlanIndex];
  },
  computed: {
    ...mapState(["pricingModels", "pricingModelsLoadedFromServer", "courseId", "childUserId"]),
  },
  watch: {
    pricingModelsLoadedFromServer(val) {
      if (val) {
        this.selectedIndex = 0;
        this.packageItems = this.pricingModels.userPackages[this.selectedIndex].packages
        this.selectedPlanIndex = this.packageItems.length - 1;
        this.selectedPlan = this.packageItems[this.selectedPlanIndex];
      }
    }
  },
  methods: {
    getImagSrc(path) {
      return this.$store.state.app_s3url + path;
    },
    getFormattedPrice(price, currency) {
      const userLocale = navigator.language || navigator.userLanguage || 'en-US'; // Default to 'en-US' if the browser doesn't provide the user's locale.
      const formatter = new Intl.NumberFormat(userLocale, {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: currency // Replace with the appropriate default currency code
      });
      const formattedCurrency = formatter.format(price); // Replace 1000 with your desired number
      return formattedCurrency

    },
    selectPlan(index, model) {
      //Add pinpoint
      this.selectedIndex = index;
      this.packageItems = model.packages;
      this.selectedPlanIndex = this.packageItems.length - 1;
      this.selectedPlan = this.packageItems[this.selectedPlanIndex];
    },
    selectItem(index, model) {
      //Add pinpoint
      recordAnalyticsEvent(AnalyticsEvents.v2PricingScreenPackageSelected, {
        packageId: model.packageId,
        userRegistrationFlow: localStorage.getItem("newUser") == 'true',
      });
      this.selectedPlanIndex = index;
      this.selectedPlan = model;
    },
    async addOnToken() {
      //Add pinpoint
      recordAnalyticsEvent(AnalyticsEvents.v2PricingScreenPackageContinueClicked, {
        packageId: this.selectedPlan.packageId,
        userRegistrationFlow: localStorage.getItem("newUser") == 'true',
      });
      if (this.selectedPlan?.priceType == 'free') {
        // const packageIds = [
        // this.selectedPlan.packageId
        // ];
        // const response = await this.$store.dispatch("createCheckoutSession", { "packages": packageIds })
        // if (response['url']) {
        //   window.open(response['url'], "_self")
        // }
        this.$router.replace('/')
        this.$store.commit("appbarVisible", true);
        this.$store.commit("navbarVisible", true);
        this.$store.commit("showNotification", true);

      } else {
        // this.addOnDialog = true;
        await this.purchaseAddOnToken();
      }
    },
    closePricingPage() {
      //Add pinpoint
      recordAnalyticsEvent(AnalyticsEvents.v2PricingScreenClosed, {
        userRegistrationFlow: localStorage.getItem("newUser") == 'true',
      });
      this.$router.replace('/')
      this.$store.commit("appbarVisible", true);
      this.$store.commit("navbarVisible", true);
      this.$store.commit("showNotification", false);
    },
    toggleToken() {
      this.selectToken = !this.selectToken;
    },
    async closeAddOnToken() {
      // if (this.selectedPlan != null) {
      //   const products = [
      //     {
      //       "price": this.selectedPlan,
      //       "quantity": 1
      //     }
      //   ];
      //   const response = await this.$store.dispatch("createCheckoutSession", { "products": products })
      //   if (response['url']) {
      //     window.open(response['url'], "_self")
      //   }
      this.addOnDialog = false;
      // } else {
      //   this.addOnDialog = false;
      //   this.$router.replace('/')
      // }
    },
    async purchaseAddOnToken() {
      if (this.selectedPlan != null) {
        let reqData = {};
        const packageIds = [
          this.selectedPlan.packageId
        ];
        if (this.selectToken) {
          packageIds.push(this.pricingModels.dialogItem.packageId)
        }
        let userId = this.childUserId ?? null;
        console.log("userid ", this.childUserId, userId)
        const response = await this.$store.dispatch("createCheckoutSession", { "packages": packageIds, "route": this.$route.path, "userId": userId })
        if (response['url']) {
          window.open(response['url'], "_self")
        }
        this.addOnDialog = false;
      }
      // else {
      //   const packageIds = [
      //     this.pricingModels.dialogItem.packageId
      //   ];
      //   const response = await this.$store.dispatch("createCheckoutSession", { "products": packageIds })
      //   if (response['url']) {
      //     window.open(response['url'], "_self")
      //   }
      //   this.addOnDialog = false;
      // }
    }
  },
};
</script>

<style scoped>
.rounded-info-btn {
  border-radius: 50% !important;
}

.main-lg {
  padding-top: 20px;
  /* padding-left: 17%;
  padding-right: 17%; */
}

.main-md {
  padding-top: 30px;
  /* padding-left: 10%;
  padding-right: 10%; */
}

.main-title {
  font-family: Inter;
  font-size: 1.625rem;
  font-weight: 700;
}

.message-card{
  background: rgb(var(--v-theme-background2));
  border-radius: 10px;
  color: white;
  padding: 16px;
  margin: 0 5%;
}

.switch-plan-block{
  width: fit-content;
  background: #2A2941;
  border-radius: 8px;
  color: white;
  padding: 6px;
  margin: 0 auto;
  cursor: pointer;
}

.selected-plan{
  border-radius: 8px;
  background: rgb(var(--v-theme-primaryPurple));
}

.img-block{
  width: 84px !important;
  height: 84px !important;
}

.starter-card {
  border-radius: 10px;
  background: #3f3f58;
  color: white;
  border: 2px solid #3f3f58;
}

.advanced-card {
  border-radius: 10px;
  background: #2a2941;
  color: white;
  border: 2px solid #2a2941;
}

.elite-card {
  border-radius: 10px;
  background: #2a2941;
  color: white;
  border: 2px solid #2a2941;
}

.selected-card {
  border-color: rgb(var(--v-theme-primaryPurple));
}

.most-popular-plan {
  padding: 6px 10px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
}

.price-text {
  font-family: "Inter", sans-serif !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}


.continue-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 12px;
}

.add-on-card {
  width: 360px;
  border-radius: 12px;
  border: 3px solid #ab77ff;
  background: #232238;
}

.add_on-title {
  font-family: Inter;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.add_on_feature {
  border-radius: 12px;
  border: 2px solid #9461fc;
  background: #2d2c42;
}

.add_not_feature {
  border: 2px solid transparent;
}

.uncheck-border {
  border: 2px solid #9461fc;
}
</style>
