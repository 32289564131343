<template>

    <!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
          <FeedbackComponent @closeDialog="reportDialog = false" :test-type="testType" :q-id="currentQ.qId"
            :test-id="testId" :test-instance-id="testInstanceId"></FeedbackComponent>
        </v-dialog> -->

    <!-- Question Details section -->
    <section class="mx-4">
        <v-row class="" dense>
            <v-col cols="auto" sm="auto">

                <v-row align="start">
                    <v-col cols="auto" class="mx-2 mb-5 mt-2" v-if="(question.difficulty??'') != ''">
                        <v-tooltip :text="question.difficulty ?? ''.trim()" location="top" class="text-capitalize">
                            <template v-slot:activator="{ props }">
                                <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity">mdi-podium</v-icon>
                                    <span class="ml-1 qNoClass text-capitalize">
                                        {{ question.difficulty ?? ''.trim() }}
                                    </span>
                                </v-row>
                            </template>
                        </v-tooltip>
                    </v-col>


                    <v-col cols="auto" class="mx-3 mb-5 mt-2" v-if="(question.topicName??'') != ''">
                        <v-tooltip :text="question.topicName ?? ''.trim()" location="top">
                            <template v-slot:activator="{ props }">
                                <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity">mdi-book-open-variant</v-icon>
                                    <span class="ml-1 qNoClass text-truncate text-capitalize"
                                        style="max-width: 200px ;">
                                        {{
                                            question.topicName ?? ''.trim() }} </span>
                                </v-row>
                            </template>
                        </v-tooltip>
                    </v-col>
                </v-row>

            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="auto" sm="1">
                <!-- <v-tooltip :text="feedbackOrQuestionReiewIconTooltipText" location="end">
                <template v-slot:activator="{ props }">
                    <v-btn size="small" v-bind="props" variant="outlined" class="outline-color"
                    @click="showReportDialog()" :icon="feedbackOrQuestionReiewIconName"></v-btn>
                </template>
                </v-tooltip> -->
            </v-col>
        </v-row>
    </section>

    <!-- Question Text section -->
    <section class="mx-4 mb-4">
        <v-row dense class="htmlClassTest" v-if="question.questionDesc != null">
            <div v-html="getMarkDownHtml(question.questionDesc)"></div>
        </v-row>
    </section>

    <section class="mx-4 mb-4" v-if="question.questionTextForSecondColumn != null">
        <v-row dense class="htmlClassTest">
            <div v-html="getMarkDownHtml(question.questionTextForSecondColumn)"></div>
        </v-row>
    </section>


    <!-- Options section -->
    <section class="mx-4" v-for="option, index in ['a', 'b', 'c', 'd']" :key="index">
        <v-row v-if="question[option] != null">
            <v-col class="mb-0 pb-0" cols="12" v-if="option == question.answerSingleChoice">
                <span class="text-green"> Correct Answer </span>
            </v-col>
            <v-col cols="12">
                <v-sheet class="bx" :class="{
                    'green-border': option == question.answerSingleChoice
                }">
                    <v-row class="ma-0 pa-0 pr-3 pl-3" align="center">
                        <v-col class="ma-0 pa-0" cols="auto">
                            <v-avatar elevation="2" color="black" size="20"
                                class="t-subtitle outline-color-grey text-capitalize text-black" variant="outlined">
                                <span class="text-black"> {{ option }}</span>

                            </v-avatar>
                        </v-col>
                        <v-col class="ma-0 pa-0">
                            <div class="htmlClassTest htmlClassTestOption ml-2 py-2 pr-2 w-100"
                                v-html="getMarkDownHtml(question[option])">
                            </div>
                        </v-col>

                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>



    </section>

    <!-- Decimal section -->
    <section class="mx-4" v-if="question.answerInteger != null">
        <v-row dense class="t-1rem mt-4">
            Answer
        </v-row>
        <v-row dense class="htmlClassTest">
            <div v-html="getMarkDownHtml(question.answerInteger)"></div>
        </v-row>
    </section>

    <!-- Solution section -->
    <section class="mx-4" v-if="question.solutionText != null">
        <v-row dense class="t-1rem mt-4">
            Solution
        </v-row>
        <v-row dense class="htmlClassTest">
            <div v-html="getMarkDownHtml(question.solutionText)"></div>
        </v-row>
    </section>



</template>

<script>
// import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { invokeAILambda } from '@/services/apiService';
import { getLoggedInUserCurrentDomain } from "@/services/authService";
import FeedbackComponent from "@/components/Test/TestQuestionPageComponents/FeedbackComponent.vue";
const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
import {
    breadcrumbHandler,
} from "@/services/breadcrumbHandler";

export default {
    name: 'QuestionComponent',
    props: {
        question: {
            type: Object
        }
    },
    data: () => ({
        reportDialog: false,

    }),
    components: {
    },
    setup() {

        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {

        breadcrumbHandler(this);

    },


    computed: {
        ...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
        feedbackOrQuestionReiewIconName: {
            get() {
                var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
                if (this.isQuestionReviewer) {
                    retVal = "mdi-shield-check-outline"; // for internal Question reviewers
                }
                return retVal;
            },
        },
        feedbackOrQuestionReiewIconTooltipText: {
            get() {
                var retVal = "Report a problem" ////"Report a Problem" for users/students
                if (this.isQuestionReviewer) {
                    retVal = "Review Feedback & Corrections"; // for internal Question reviewers
                }
                return retVal;
            },
        },
    }
    ,
    methods: {
        getMarkDownHtml(markdown) {
            console.log(markdown)
            try {
                let out = markdown
                const regex = /\\/gm;
                const escapedString = markdown.replace(regex, "\\\\");

                out = marked.parseInline(escapedString, { renderer });

                out = out.replace(/\\\\+/g, '\\');
                out = out.replace(/\\n/g, '<br>');


                 console.log(out);

                // console.log(out);
                return out;
            } catch (error) {
                return ""
            }

        },
        showReportDialog() {
            // recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
            //   testId: this.currentTestId,
            //   testName: this.startTestInfo.testmetadata.testName,
            //   testInstanceId: this.startTestInfo.testInstanceId,
            //   sectionId: this.currentSection.sectionId,

            // });
            this.reportDialog = true;
        },
    },
    watch: {

    }



}
</script>

<style scoped>
.custom-theme {
    --v-theme-error: blue !important;
    --v-primary-base: #f44336;
    /* Your desired primary color */

}

.green-border {
    border: 2.5px solid green !important;
}

.assignment-card {
    background-color: rgb(var(--v-theme-reportBgColor));
    border-radius: 10px;
    white-space: pre-line !important;
    border: 1px solid #9461FC;

}

.click {
    cursor: pointer
}

.disabledOpacity {}

.card-border:hover {}

.subcard-bg {
    background: rgb(var(--v-theme-report-subCardBg));

}

.solution-text {
    color: rgba(191, 191, 198, 1);
}

.v-expansion-panel-title--active {
    background-color: rgb(var(--v-theme-reportBgColor)) !important;
}

.v-expansion-panel-title__overlay {
    opacity: 1 !important;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}

.qNoClass {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif !important;
}

.main-lg {
    padding-top: 40px;
    padding-left: 10%;
    padding-right: 10%;
}

.main-md {
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.icon-opacity {
    opacity: 0.4;
    /* color: #A7A6B0; */
}

.bx {
    background-color: rgb(var(--v-theme-navColor)) !important;
    border: 1px solid #e7e7e7;
    border-radius: 4px !important;
}

.outline-color-grey {
    border-color: #010101b3 !important;
    border: 1px solid;
    color: #010101b3 !important;

}

.htmlClassTest {
    font-size: 0.875rem !important;
    white-space: pre-line !important;
    color: black !important
}
</style>