<template>
  <v-row
    class="ma-0 pt-4 px-4"
    :class="{ heightBread: $vuetify.display.mdAndUp }"
  >
    <v-col cols="12" class="ma-0 pa-0">
      <v-breadcrumbs :items="breadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn
            v-if="breadCrumbs.length > 0"
            color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
            size="x-small"
            @click="back()"
            icon="mdi-chevron-left"
          >
          </v-btn>
          <v-icon icon="mdi-trophy-outline" color="primary"></v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
  </v-row>
  <v-layout class="mb-0 pb-0" v-if="dataLoaded">
    <v-container fluid class="d-flex flex-column mt-3 mt-md-0 mb-0 pa-2 pb-0">
      <v-row class="mt-0 pt-0">
        <v-col
          class="mt-0 pt-0 justify-center align-center"
          :class="{ 'd-flex flex-column': $vuetify.display.mdAndUp }"
        >
          <v-container fluid class="px-4 mt-0 pt-0">
            <v-row
              v-for="(item, index) in updatePastChallengeData"
              :key="index"
            >
              <v-col cols="12">
                <span class="text-font-8rem font-weight-bold text-start text-white">{{
                  index
                }}</span>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="4"
                lg="3"
                xl="3"
                v-for="(report, i) in item"
                :key="i"
                dense
              >
                <v-sheet
                  height="110"
                  class="d-flex align-center justify-center report-card"
                  @click="onViewReport(report.challengeId)"
                >
                  <v-row class="mx-4 my-5">
                    <v-col cols="6">
                      <div class="font-weight-bold text-result text-white">{{ getChallengeResult(report.players) }}</div>
                      <!-- <div class="text-score mt-4">{{ `${report.players[0].score} | ${report.players[1].score}` }}</div> -->
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-end">
                      <div class="avatar-character">
                        <div class="winner-character">
                          <v-img
                            v-if="report.players[0].winner"
                            src="@/assets/winner.svg"
                          ></v-img>
                        </div>
                        <v-avatar
                          :color="
                            report.players[0].self
                              ? 'primaryPurple'
                              : 'primaryGreen'
                          "
                          size="45"
                        >
                          <v-img v-if="report.challengeType == 'aigenerated' && !report.players[0].self " src="@/assets/mento_win.png"></v-img>
                          <span v-else class="text-font-8rem text-bold">{{
                            getInitials(report.players[0])
                          }}</span>
                        </v-avatar>
                      </div>
                      <!-- <span class="text-font-8rem">VS</span> -->
                      <div class="avatar-character">
                        <div class="winner-character">
                          <v-img
                            v-if="report.players[1].winner"
                            src="@/assets/winner.svg"
                          ></v-img>
                        </div>
                        <v-avatar
                          :color="
                            report.players[1].self
                              ? 'primaryPurple'
                              : 'primaryGreen'
                          "
                          size="45"
                        >
                          <v-img v-if="report.challengeType == 'aigenerated' && !report.players[1].self " src="@/assets/mento_win.png"></v-img>
                          <span v-else class="text-font-8rem text-bold">{{
                            getInitials(report.players[1])
                          }}</span>
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";

export default {
  name: "ChallengeReportPage",
  data: () => ({
    breadCrumbs: [],
    dataLoaded: false,
    updatePastChallengeData: {},
  }),
  components: {},
  async created() {
    recordAnalyticsEvent(AnalyticsEvents.v2PastChallengesScreenOpen, {
    });
    this.creatNodes();
    this.dataLoaded = false;
    this.updatePastChallengeData={};
    this.$store.commit("pastChallengesFromServer", false);
    await this.$store.dispatch("getPastChallenges", {});
    if (this.pastChallengesData.length > 0) {
      const groupedData = {};
      this.pastChallengesData.forEach((item) => {
        let date = new Date(item.challengeDateTimeStamp);
        date = moment(date).format("dddd, MMMM Do");
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(item);
      });
      _.forEach(groupedData,(challenges, datetime)=>{
        this.updatePastChallengeData[datetime] = _.orderBy(challenges, ['challengeDateTimeStamp'], ['desc']);
      })
      this.dataLoaded = true;
    }
  },
  computed: {
    pastChallengesData: {
      get() {
        return this.$store.state.pastChallengesData;
      },
    },
  },

  watch: {},

  methods: {
    creatNodes() {
      this.breadCrumbs = [];

      this.breadCrumbs.push(
        {
          title: "Past Challenges",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: "pastchallenges",
          pageRoute: `/challenge/pastchallenge`,
        }
      );
    },
    getInitials(item) {
      if (item.self) return "Me";
      return item.name.charAt(0).toUpperCase();
    },
    getFirstName(item) {
      if (item.self) return "Me";
      const allNames = item.name.trim().split(" ");
      return allNames[0];
    },
    getChallengeResult(players){
      if(players[0].challengeStatus === 'winnertie'){
        return 'Match tie!';
      }else if(players[0].challengeStatus === 'noresult'){
        return 'No result!';
      }else{
        if(players[0].self){
          if(players[0].challengeStatus === 'winner'){
            return 'You won'
          }else{
            return this.getFirstName(players[1]) + ' won!';
          }
        }else {
          if(players[1].challengeStatus === 'winner'){
            return 'You won!'
          }else{
            return this.getFirstName(players[0]) + ' won!';
          }
        }
      }
    },
    back() {
      this.$router.replace("/challenge");
    },
    onViewReport(challengeId) {
      recordAnalyticsEvent(AnalyticsEvents.v2PastChallengesViewChallengeReport, {
        challengeId : challengeId
    });
      this.$router.push("/challenge/challengereport/" + challengeId);
    },

  },
};
</script>

<style scoped>
.layoutLarge {
  height: calc(100% - 160px);
}

.winner-character {
  height: 8px;
  width: 28px;
  text-align: center;
  margin-bottom: 6px;
}

.avatar-character {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
}

.avatar-character:first-child {
  margin-right: 10px;
  margin-left: unset;
}

.text-font-8rem {
  font-size: 0.8rem;
  font-family: "Inter" !important;
}

.report-card {
  background: rgb(var(--v-theme-reportBgColor));
  border: 0.5px solid rgba(231, 231, 231, 0.25);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  cursor: pointer;
}

.text-result{
  font-family: 'Inter' !important;
  font-size: 18px !important;
  line-height: 21px !important;
}

.text-score{
  font-family: 'Inter' !important;
  font-size: 0.64rem !important;
  line-height: 12px !important;
  color: rgba(255, 255, 255, 0.8);
}
</style>
